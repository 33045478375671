import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup, Checkbox, Select
} from "@material-ui/core";
import {useForm, Controller} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import {Pagination} from "@material-ui/lab";
import axios from "axios";
import {PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";
import {useSelector} from "react-redux";
import NavBar from "../navBar";
import ShippingOrderListTable from "./ShippingOrderListTable";
import InputLabel from "@material-ui/core/InputLabel";
import {MenuItem} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word",
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff",
    },
    table: {
        width: "100%",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
    },
    nameTH: {
        width: "100px",
    },
    idTH: {
        width: "100px",
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
    ShippingOrderListTableSection: {
        '@media (max-width: 480px)': {
            display: 'none'
        }
    },
    ShippingOrderListResponsiveSection: {
        display: 'none',
        '@media (max-width: 480px)': {
            display: 'flex'
        }
    }
}));

const styles = {
    ShippingOrderListRoot: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        gap: "2rem",
        margin: 'auto',
        maxWidth: '1000px',
    },
    ShippingOrderListResponsive: {
        display: "flex",
        flexDirection: "column",
        width: '100%'
    },
}

const ShippingOrderList = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(max-width:600px)");
    const pageSize = 100;

    const partnerInfo = useSelector((state) => state.user);

    const {register, handleSubmit, control} = useForm();
    const [shippingListData, setShippingListData] = useState([]);
    const [selectUPS, setSelectUPS] = useState(false);
    const [selectFedEx, setSelectFedEx] = useState(false);
    const [carrier, setCarrier] = useState("ALL");

    const [page, setPage] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const cacheInput = useRef({
        ref_order_no: "",
        cargo_control_no: "",
        account_base: "",
        order_id: "",
        ref_product_no: "",
        carrier: carrier
    });

    const getData = async ({ref_order_no, cargo_control_no, currentPage, order_id, ref_product_no, carrier}) => {

        let requestURI = `${PARTNER_URI}/shipping/list/order?ref_product_no=${ref_product_no}&ref_order_no=${ref_order_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&order_id=${order_id}&page=${currentPage - 1 < 0 ? 0 : currentPage - 1}`;

        // if ((selectUPS && selectFedEx) || (!selectUPS && !selectFedEx)) {
        //   requestURI = `${PARTNER_URI}/shipping/list/order?ref_product_no=${ref_product_no}&ref_order_no=${ref_order_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&order_id=${order_id}&page=${currentPage - 1 < 0 ? 0 : currentPage - 1}`
        // } else if (selectUPS) {
        //   requestURI = `${PARTNER_URI}/shipping/list/order?account_base=ups&ref_product_no=${ref_product_no}&ref_order_no=${ref_order_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&order_id=${order_id}&page=${currentPage - 1 < 0 ? 0 : currentPage - 1}`;
        // } else if (selectFedEx) {
        //   requestURI = `${PARTNER_URI}/shipping/list/order?account_base=fedex&ref_product_no=${ref_product_no}&ref_order_no=${ref_order_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&order_id=${order_id}&page=${currentPage - 1 < 0 ? 0 : currentPage - 1}`;
        // }
        if (carrier === "ALL") {
            requestURI = `${PARTNER_URI}/shipping/list/order?ref_product_no=${ref_product_no}&ref_order_no=${ref_order_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&order_id=${order_id}&page=${currentPage - 1 < 0 ? 0 : currentPage - 1}`;
        } else {
            requestURI = `${PARTNER_URI}/shipping/list/order?account_base=${carrier}&ref_product_no=${ref_product_no}&ref_order_no=${ref_order_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&order_id=${order_id}&page=${currentPage - 1 < 0 ? 0 : currentPage - 1}`;
        }

        const {data} = await axios.get(requestURI);
        const result = data.result;
        const resultTotalNumber = data.totalNumber;
        setTotalNumber(resultTotalNumber);
        setShippingListData(result);
        try {
        } catch (e) {
            setTotalNumber(0);
            console.log(e);
            setShippingListData([]);
        } finally {
            setIsLoading(false);
        }
    }

    const onSubmit = data => {
        console.log(data);
        const {ref_order_no, cargo_control_no, order_id, ref_product_no} = data;
        cacheInput.current = {
            ...data,
            carrier
        };
        setPage(1);
        getData({ref_order_no, cargo_control_no, order_id, ref_product_no, carrier});
    }
    const handlePageChange = (e, value) => {
        if (value !== page) {
            console.log(value);
            setPage(value);
            const {
                order_id,
                ref_product_no,
                ref_order_no,
                account_base,
                cargo_control_no,
                carrier
            } = cacheInput.current;
            getData({order_id, ref_product_no, ref_order_no, currentPage: value, cargo_control_no, carrier});
        }
    }

    useEffect(() => {
        (async () => {
            await getData({
                ref_order_no: "",
                cargo_control_no: "",
                account_base: "",
                currentPage: 0,
                ref_product_no: "",
                order_id: "",
                carrier: "ALL"
            })
        })();
    }, [partnerInfo.id])

    return (
        <>
            <Box>
                <Box component="form" sx={styles.ShippingOrderListRoot}>
                    <h1>Shipping Order List</h1>
                    <Box display="flex" flexWrap="wrap" sx={{gap: "2rem"}}>
                        <Box flex="45%" width="100%">
                            <TextField id="outlined-basic" label="Ref Order Number"
                                       variant="outlined" {...register("ref_order_no")} fullWidth/>
                        </Box>
                        <Box flex="45%" width="100%">
                            <TextField id="outlined-basic" label="Cargo Control Number"
                                       variant="outlined" {...register("cargo_control_no")} fullWidth/>
                        </Box>
                        <Box flex="30%" width="100%">
                            <TextField id="outlined-basic" label="Order ID" variant="outlined" {...register("order_id")}
                                       fullWidth/>
                        </Box>
                        <Box flex="30%" width="100%">
                            <TextField id="outlined-basic" label="Product Id"
                                       variant="outlined" {...register("ref_product_no")} fullWidth/>
                        </Box>
                        <Box flex="30%" width="100%">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-filled-label">Carrier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={carrier}
                                    label="Carrier"
                                    onChange={(e) => {
                                        setCarrier(e.target.value);
                                    }}
                                >
                                    <MenuItem value={"ALL"}>ALL</MenuItem>
                                    <MenuItem value={"UPS"}>UPS</MenuItem>
                                    <MenuItem value={"CANPAR"}>CANPAR</MenuItem>
                                    <MenuItem value={"FEDEX"}>FEDEX</MenuItem>
                                    <MenuItem value={"CanadaPost"}>CANADA POST</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    {/*<Box mt={2} mb={4}>*/}
                    {/*  <FormGroup>*/}
                    {/*    <FormLabel required color="primary">Account Base</FormLabel>*/}
                    {/*    /!*<Controller control={control} defaultValue="UPS" name="account_base" rules={{ required: true }} render={({ field }) => (*!/*/}
                    {/*    /!*    <RadioGroup*!/*/}
                    {/*    /!*        row*!/*/}
                    {/*    /!*        aria-labelledby="demo-row-radio-buttons-group-label"*!/*/}
                    {/*    /!*        name="row-radio-buttons-group"*!/*/}
                    {/*    /!*        defaultValue="UPS"*!/*/}
                    {/*    /!*        {...field}*!/*/}
                    {/*    /!*    >*!/*/}
                    {/*    /!*      <FormControlLabel value="FedEx" control={<Radio />} label="FedEx" />*!/*/}
                    {/*    /!*      <FormControlLabel value="UPS" control={<Radio />} label="UPS" />*!/*/}
                    {/*    /!*    </RadioGroup>*!/*/}
                    {/*    /!*)}/>*!/*/}
                    {/*    <Box display="flex" flexDirection="row" sx={{ gap: "2rem" }} justifyContent="center">*/}
                    {/*      <FormControlLabel control={<Checkbox />} checked={selectFedEx} onChange={() => {*/}
                    {/*        setSelectFedEx(v => !v);*/}
                    {/*      }} label="FedEx" value="FedEx" />*/}
                    {/*      <FormControlLabel control={<Checkbox />} checked={selectUPS} onChange={() => {*/}
                    {/*        setSelectUPS(v => !v);*/}
                    {/*      }} label="UPS" value="UPS" />*/}
                    {/*    </Box>*/}
                    {/*  </FormGroup>*/}
                    {/*</Box>*/}
                    <Box>
                        <Button
                            variant="contained"
                            component="span"
                            color="primary"
                            style={{
                                textTransform: "unset",
                                fontSize: "1rem",
                                width: "7rem",
                            }}
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Search
                        </Button>
                    </Box>
                </Box>
                <br/>
                {
                    isLoading ? (
                        <>
                            <Box>
                                <h1>Loading...</h1>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box>
                                <h2>Found {totalNumber} results</h2>
                                <hr width={matches ? "100%" : "rem"}/>
                                <Box maxWidth="70%" margin="0 auto" className={classes.ShippingOrderListTableSection}>
                                    <Box mt={2}>
                                        <table className={classes.table}>
                                            <thead>
                                            <tr>
                                                <th className={classes.idTH}>Order ID</th>
                                                <th className={classes.idTH}>Ref Order # / Cargo Control #</th>
                                                {/*<th className={classes.idTH}>Cargo Control Number</th>*/}
                                                <th className={classes.idTH}>Carrier</th>
                                                <th className={classes.idTH}>Total Charge Include Tax</th>
                                                <th className={classes.idTH}>Send From Name/Email/Tel</th>
                                                <th className={classes.idTH}>Send From Address/City/Province/Postal
                                                    Code
                                                </th>
                                                <th className={classes.idTH}>Origin Shipping Item File Link</th>
                                                <th className={classes.idTH}>Create Date</th>
                                                <th className={classes.idTH}>Transaction</th>
                                                <th className={classes.idTH}>Tracking #</th>
                                                <th className={classes.idTH}>Labels</th>
                                            </tr>
                                            </thead>
                                            <tbody className={classes.tbody}>
                                            {shippingListData.map((v, index) => {
                                                return <ShippingOrderListTable key={index}
                                                                               partner_id={partnerInfo.id}
                                                                               account_base={v.account_base}
                                                                               send_from_email={v.send_from_email}
                                                                               send_from_name={v.send_from_name}
                                                                               send_from_postal_code={v.send_from_postal_code}
                                                                               order_id={v.order_id}
                                                                               cargo_control_no={v.cargo_control_no}
                                                                               send_from_tel={v.send_from_tel}
                                                                               ref_order_no={v.ref_order_no}
                                                                               send_from_addr={v.send_from_addr}
                                                                               send_from_city={v.send_from_city}
                                                                               send_from_province={v.send_from_province}
                                                                               total_amount={v.total_amount || 0}
                                                                               origi_shipping_item_file={v.origi_shipping_item_file}
                                                                               currency_code={v.currency_code}
                                                                               date={v.created_at}
                                                                               isCrossBorder={v?.cross_border_shipment_type !== null}
                                                                               service_fee_include_gst={v?.service_fee_include_gst}
                                                                               total_amount_include_tax={v?.total_amount_include_tax}
                                                />;
                                            })}
                                            </tbody>
                                        </table>
                                    </Box>
                                </Box>
                                <br/>
                                <Box className={classes.ShippingOrderListResponsiveSection}>
                                    <Box sx={styles.ShippingOrderListResponsive}>
                                        {shippingListData.map((v, index) => {
                                            return <ShippingOrderListTable key={index}
                                                                           partner_id={partnerInfo.id}
                                                                           account_base={v.account_base}
                                                                           send_from_email={v.send_from_email}
                                                                           send_from_name={v.send_from_name}
                                                                           send_from_postal_code={v.send_from_postal_code}
                                                                           order_id={v.order_id}
                                                                           cargo_control_no={v.cargo_control_no}
                                                                           send_from_tel={v.send_from_tel}
                                                                           ref_order_no={v.ref_order_no}
                                                                           send_from_addr={v.send_from_addr}
                                                                           send_from_city={v.send_from_city}
                                                                           send_from_province={v.send_from_province}
                                                                           total_amount={v.total_amount || 0}
                                                                           origi_shipping_item_file={v.origi_shipping_item_file}
                                                                           currency_code={v.currency_code}
                                                                           date={v.created_at}
                                                                           isCrossBorder={v?.cross_border_shipment_type !== null}
                                                                           service_fee_include_gst={v?.service_fee_include_gst}
                                                                           total_amount_include_tax={v?.total_amount_include_tax}
                                            />;
                                        })}
                                    </Box>
                                </Box>
                                <Box margin="auto" sx={{display: "flex", width: "100%", justifyContent: "center"}}>
                                    <Pagination count={Math.floor(totalNumber / pageSize) + 1}
                                                page={page}
                                                onChange={handlePageChange}
                                                className={classes.pagination}
                                                variant="outlined"
                                                shape="rounded"/>
                                </Box>
                            </Box>
                        </>
                    )
                }
            </Box>
        </>
    );
};

export default ShippingOrderList;
