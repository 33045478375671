import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";

const styles = {
    FooterRoot: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        backgroundColor: '#FFFFFF',
        marginTop: '100px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
        // zIndex: 5000
    },
    FooterContent: {
        display: 'flex',
        height: '50px',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        flexWrap: 'wrap'
    },
    FooterText: {
        fontSize: '14px',
        color: '#000000'
    },
    FooterLink: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '40px'
    },
    FooterChat: {
        backgroundColor: '#D5D5D5',
        // zIndex: 5000,
        width: '50px',
        borderRadius: '50%',
        border: '2px solid #FFFFFF',
        position: 'absolute',
        bottom: '60px',
        right: '20px',
    }
}

export const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <>
            <Box sx={styles.FooterRoot}>
                <Box sx={styles.FooterContent}>
                    <Typography sx={styles.FooterText}>
                        &copy; {currentYear} UU CARGO LTD. - All Rights Reserved
                    </Typography>
                    <Link underline="none" href="https://uucargo.ca">
                        <Typography sx={styles.FooterText}>
                            uucargo.ca
                        </Typography>
                    </Link>
                    <Box sx={styles.FooterLink}>
                        <Link underline="none" href="https://uucargo.ca/privacy-policy/">
                            <Typography sx={styles.FooterText}>
                                Privacy Policy
                            </Typography>
                        </Link>
                        <Link underline="none" href="https://uucargo.ca/terms-of-use/">
                            <Typography sx={styles.FooterText}>
                                Terms
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.FooterChat}>
                <Link href="https://chat.uucargo.ca/index.php/chat/startchat/(department)/1/">
                    <img src="https://chat.uucargo.ca/design/defaulttheme/images/getstatus/offline.svg" alt=""/>
                </Link>
            </Box>
        </>
    )
}