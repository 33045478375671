import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import {Fragment} from "react";

export const TransportationAvailableTasksCost = ({taskDetails, paidPaymentList}) => {
    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Order Cost
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            {
                                                +taskDetails?.orderPrice?.moving_freight_charge > 0 &&
                                                <>
                                                    <Grid item xs={10}>
                                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                            Freight Charge
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{textAlign: 'right'}}>
                                                            $ {(+taskDetails?.orderPrice?.moving_freight_charge)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                +taskDetails?.orderPrice?.moving_pickup_charge > 0 &&
                                                <>
                                                    <Grid item xs={10}>
                                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                            Pickup Charge
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{textAlign: 'right'}}>
                                                            $ {(+taskDetails?.orderPrice?.moving_pickup_charge)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                +taskDetails?.orderPrice?.moving_deliver_charge > 0 &&
                                                <>
                                                    <Grid item xs={10}>
                                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                            {taskDetails?.deliver_addr_type === 'SELF' ? "Operation Charge" : "Delivery Charge"}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{textAlign: 'right'}}>
                                                            $ {(+taskDetails?.orderPrice?.moving_deliver_charge)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                +taskDetails?.orderPrice?.moving_storage_charge > 0 &&
                                                <>
                                                    <Grid item xs={10}>
                                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                            Storage Charge
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{textAlign: 'right'}}>
                                                            $ {(+taskDetails?.orderPrice?.moving_storage_charge)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                +taskDetails?.orderPrice?.moving_palleting_charge > 0 &&
                                                <>
                                                    <Grid item xs={10}>
                                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                            Pallet Charge
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{textAlign: 'right'}}>
                                                            $ {(+taskDetails?.orderPrice?.moving_palleting_charge)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                    Subtotal
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'right'}}>
                                                    $ {(+taskDetails?.orderPrice?.shipping_fee)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={10}>
                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                            Tax
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{textAlign: 'right'}}>
                                            $ {(+taskDetails?.taskTaxCharge)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={10}>
                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                            Total
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{textAlign: 'right'}}>
                                            $ {(+taskDetails?.taskFinalTotal)?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    {
                                        paidPaymentList?.map((payment, index) => (
                                            <Fragment key={index}>
                                                <Grid item xs={10}>
                                                    <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                        {payment?.remark_notes === "Down_Payment" ? "Prepaid Amount" : "Payment"}                                                            </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography style={{textAlign: 'right'}}>
                                                        - $ {payment?.amount.toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={10}>
                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                            Amount To Be Paid
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{textAlign: 'right'}}>
                                            $ {taskDetails?.task_unpaid_amount?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}