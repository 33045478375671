import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {BACKEND_ME_API} from "../utils/apiUrl";
import {getHeaders, errHandling} from "../utils/fetchUtil";

const initialState = {
    role: null,
    isLoading: false,
    email: "",
    activeCard: null,
    firstname: "",
    lastname: "",
    phone: null,
    userType: null,
    dataLoaded: false,
    latlng: {lat: null, lng: null},
    // login: false,
    address: null,
    source: null,
    legalName: null,
    licenseNumber: null,
    expiryDate: null,
    vehicleType: null,
    signIn: false,
    // Address book related param
    addressBook: [],
    addressBookLoaded: false,
    addressBookLoading: false,
    addressBookLoadingErr: "",
    traccarIdentifier: null,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUserInfo: (state, {payload}) => {
            state.isLoading = true;
            state.role = payload.role;
            state.email = payload.email;
            state.activeCard = payload.activeCard;
            state.firstname = payload.firstname;
            state.lastname = payload.lastname;
            state.phone = payload.phone;
            state.userType = payload.userType;
            state.source = payload.source;
            state.address = payload.address;
            state.dataLoaded = true;
            state.legalName = payload.legalName;
            state.licenseNumber = payload.licenseNumber;
            state.expiryDate = payload.expiryDate;
            state.vehicleType = payload.vehicleType;
            state.traccarIdentifier = payload.traccarIdentifier;
            state.isPartner = payload.isPartner
        },

        addLatlng: (state, {payload}) => {
            state.latlng.lat = payload.lat;
            state.latlng.lng = payload.lng;
        },

        gettingUserInfo: (state) => {
            state.isLoading = true;
        },

        deleteUserInfo: (state) => {
            state.role = null;
            state.isLoading = false;
            state.email = "";
            state.activeCard = null;
            state.name = "";
            state.phone = null;
            state.userType = null;
            state.address = null;
            state.dataLoaded = false;
            state.legalName = null;
            state.licenseNumber = null;
            state.expiryDate = null;
            state.signIn = false;
            state.traccarIdentifier = null;
            state.isPartner = true;
        },
        doneUserInfoActions: (state) => {
            state.isLoading = false;
        },
        updateUserInfo: (state, {payload}) => {
            state[payload.field] = payload.value;
        },
        loadAddressBook: (state, {payload}) => {
            state.addressBookLoaded = true;
            state.addressBookLoading = false;
            state.addressBook = payload.value;
        },
    },
});

// Extract the action creators object and the reducer
const {actions, reducer} = userSlice;

export const {
    addUserInfo,
    deleteUserInfo,
    gettingUserInfo,
    doneUserInfoActions,
    addLatlng,
    updateUserInfo,
    loadAddressBook,
} = actions;

// Customized function

// Get residents information from API
export const getUserProfile =
    (noReload = false) =>
        async (dispatch) => {
            try {
                !noReload && dispatch(gettingUserInfo());
                const userProfile = await axios.get(BACKEND_ME_API, {
                    headers: getHeaders(),
                });
                console.log('user profile', userProfile);
                if (userProfile)
                    dispatch(
                        addUserInfo({
                            role: userProfile.data.role,
                            email: userProfile.data.email,
                            activeCard: userProfile.data.card,
                            firstname: userProfile.data.firstname,
                            lastname: userProfile.data.lastname,
                            phone: userProfile.data.phone,
                            userType: userProfile.data.usertype,
                            source: userProfile.data.source,
                            address: userProfile.data.address,
                            legalName: userProfile.data.legal_name,
                            licenseNumber: userProfile.data.license_number,
                            expiryDate: userProfile.data.expiry_date,
                            vehicleType: userProfile.data.vehicle_type,
                            traccarIdentifier: userProfile.data.traccar_identifier,
                        })
                    );
                dispatch(doneUserInfoActions());
            } catch (error) {
                dispatch(doneUserInfoActions());
                throw error;
            }
        };

// Get address book
// export const getAddressBook =
//   (noReload = false) =>
//   async (dispatch, getState) => {
//     try {
//       const { addressBookLoaded, addressBookLoading } = getState().user;
//       const dataNotLoadedAndNoReload =
//         !addressBookLoaded && !addressBookLoading && noReload;
//       if (dataNotLoadedAndNoReload || !noReload) {
//         dispatch(updateUserInfo({ field: "addressBookLoading", value: true }));
//         dispatch(updateUserInfo({ field: "addressBookLoadingErr", value: "" }));
//         const response = await axios.get(BACKEND_GET_ADDRESSBOOK, {
//           headers: getHeaders(),
//         });
//         if (response.data) {
//           console.log(response.data);
//           dispatch(loadAddressBook({ value: response.data }));
//         } else
//           dispatch(
//             updateUserInfo({ field: "addressBookLoading", value: false })
//           );
//       }
//     } catch (error) {
//       errHandling(error, (msg) =>
//         dispatch(updateUserInfo({ field: "addressBookLoadingErr", value: msg }))
//       );
//       dispatch(updateUserInfo({ field: "addressBookLoading", value: false }));
//     }
//   };

export const userReducer = reducer;

// Export selector
