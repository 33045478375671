import {actionType} from "../../utils/Helper";

export const updateShippingPackageQuickQuoteUnit = metric => ({
    type: actionType.UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_UNIT,
    payload: metric
})

export const updateShippingPackageCreateLabelUnit = metric => ({
    type: actionType.UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_UNIT,
    payload: metric
})

export const addShippingPackageQuickQuotePackageList = packageList => ({
    type: actionType.ADD_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST,
    payload: packageList
})

export const addShippingPackageCreateLabelPackageList = packageList => ({
    type: actionType.ADD_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST,
    payload: packageList
})

export const addShippingPackageCrossBorderItemList = itemList => ({
    type: actionType.ADD_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST,
    payload: itemList
})

export const updateShippingPackageQuickQuotePackageList = packageDetail => ({
    type: actionType.UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST,
    payload: packageDetail
})

export const updateShippingPackageCreateLabelPackageList = packageDetail => ({
    type: actionType.UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST,
    payload: packageDetail
})

export const updateShippingPackageCrossBorderItemList = itemDetail => ({
    type: actionType.UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST,
    payload: itemDetail
})

export const initShippingPackageCreateLabelPackageList = packageList => ({
    type: actionType.INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST,
    payload: packageList
})

export const removeShippingPackageQuickQuotePackageList = packageDetail => ({
    type: actionType.REMOVE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST,
    payload: packageDetail
})

export const removeShippingPackageCreateLabelPackageList = packageDetail => ({
    type: actionType.REMOVE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST,
    payload: packageDetail
})

export const removeShippingPackageCrossBorderItemList = itemDetail => ({
    type: actionType.REMOVE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST,
    payload: itemDetail
})

export const duplicateShippingPackageQuickQuotePackageList = packageDetail => ({
    type: actionType.DUPLICATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST,
    payload: packageDetail
})

export const duplicateShippingPackageCreateLabelPackageList = packageDetail => ({
    type: actionType.DUPLICATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST,
    payload: packageDetail
})

export const duplicateShippingPackageCrossBorderItemList = itemDetail => ({
    type: actionType.DUPLICATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST,
    payload: itemDetail
})

export const clearShippingPackageQuickQuotePackageList = () => ({
    type: actionType.CLEAR_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST,
})

export const clearShippingPackageCreateLabelPackageList = () => ({
    type: actionType.CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST,
})

export const clearShippingPackageCrossBorderItemList = () => ({
    type: actionType.CLEAR_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST,
})

export const updateShipFromPackageQuickQuotePackage = location => ({
    type: actionType.UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION,
    payload: location
})

export const updateShipToPackageQuickQuotePackage = location => ({
    type: actionType.UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION,
    payload: location
})

export const updateShipFromPackageCreateLabelPackage = location => ({
    type: actionType.UPDATE_SHIPPING_CREATE_LABEL_SHIP_FROM_LOCATION,
    payload: location
})

export const updateShipToPackageCreateLabelPackage = location => ({
    type: actionType.UPDATE_SHIPPING_CREATE_LABEL_SHIP_TO_LOCATION,
    payload: location
})

export const updateShipFromMissingQuickQuotePackage = missing => ({
    type: actionType.UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION_MISSING,
    payload: missing
})

export const updateShipToMissingQuickQuotePackage = missing => ({
    type: actionType.UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION_MISSING,
    payload: missing
})

export const updateCrossBorderQuickQuotePackage = crossBorder => ({
    type: actionType.UPDATE_SHIPPING_QUICK_QUOTE_CROSS_BORDER,
    payload: crossBorder
})

export const updateCrossBorderCreateLabelPackage = crossBorder => ({
    type: actionType.UPDATE_SHIPPING_CREATE_LABEL_CROSS_BORDER,
    payload: crossBorder
})

export const updateReferenceOrderNumberCreateLabelPackage = referenceOrderNumber => ({
    type: actionType.UPDATE_SHIPPING_CREATE_LABEL_REFERENCE_ORDER_NUMBER,
    payload: referenceOrderNumber
})

export const updateCreateLabelValidation = validation => ({
    type: actionType.UPDATE_SHIPPING_CREATE_LABEL_VALIDATION,
    payload: validation
})

export const updateShippingPackageRateQuickQuote = rate => ({
    type: actionType.UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_RATE,
    payload: rate
})

export const updateShippingPackageRateCreateLabel = rate => ({
    type: actionType.UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_RATE,
    payload: rate
})

export const clearShippingPackageRateCreateLabel = () => ({
    type: actionType.CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_RATE
})

export const selectShippingPackageRateQuickQuote = rate => ({
    type: actionType.SELECT_SHIPPING_PACKAGE_QUICK_QUOTE_RATE,
    payload: rate
})

export const selectShippingPackageRateCreateLabel = rate => ({
    type: actionType.SELECT_SHIPPING_PACKAGE_CREATE_LABEL_RATE,
    payload: rate
})

export const toggleShippingPackageIsGetRate = status => ({
    type: actionType.TOGGLE_SHIPPING_PACKAGE_QUICK_QUOTE_IS_GET_RATE,
    payload: status
})

export const updateShippingPackageCrossBorderRate = rate => ({
    type: actionType.UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_RATE,
    payload: rate
})

export const isCallAllPackageCarrier = index => ({
    type: actionType.IS_CALLED_ALL_PACKAGE_CARRIER,
    payload: index
})

export const clearCallAllPackageCarrier = () => ({
    type: actionType.CLEAR_CALLED_ALL_PACKAGE_CARRIER,
})

export const updateShippingPackageQuickQuoteBatteryDetails = batteryDetails => ({
    type: actionType.UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS,
    payload: batteryDetails
})

export const updateShippingPackageCreateLabelBatteryDetails = batteryDetails => ({
    type: actionType.UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS,
    payload: batteryDetails
})

export const updateShippingPackageQuickQuoteSignatureOptions = signatureOptions => ({
    type: actionType.UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS,
    payload: signatureOptions
})

export const updateShippingPackageCreateLabelSignatureOptions = signatureOptions => ({
    type: actionType?.UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS,
    payload: signatureOptions
})

export const updateShippingPackageInternationalShipmentShipFrom = address => ({
    type: actionType?.UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM,
    payload: address
})

export const updateShippingPackageInternationalShipmentShipTo = address => ({
    type: actionType?.UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO,
    payload: address
})

export const updateShippingPackageReturnLabelStatus = returnLabel => ({
    type: actionType?.UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS,
    payload: returnLabel
})


