import React from "react";
import {Button, makeStyles} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
  },
}));

export default function CsvShipTableItem({ index,
                                           totalChargeWithTaxes,
                                           labelUri,
                                           trackingNumbers
                                         }) {
  const classes = useStyles();

  return (
      <>
        <tr className={classes.listItemWrapper}>
          <td>{index + 1}</td>
          <td>
            <CurrencyFormat value={(+totalChargeWithTaxes?.toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </td>
          <td>
            <Button>
              {" "}
              <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={labelUri || ""}
              >
                Download
              </a>
            </Button>
          </td>
          <td>{trackingNumbers}</td>
        </tr>
      </>
  );
}
