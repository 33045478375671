import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {bottomNavigationActionClasses, Container, Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {useEffect, useState} from "react";
import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from "@mui/material/Button";
import {TabList} from "@mui/joy";
import Tab from "@mui/material/Tab";
import {TabContext} from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import actions from '../Actions';
import {AddressListAdd} from "./AddressListAdd";
import {AddressListEdit} from "./AddressListEdit";
import TextField from "@mui/material/TextField";
import {getAccessToken} from "../../utils/doToken";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    AddressRoot: {
        margin: 'auto',
        paddingBottom: '40px'
    },

    AddressListHeader: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        gap: '10px'
    },

    AddressListHeaderAdd: {
        // width: '10%',
    },

    AddressListHeaderTab: {
        // width: '70%'
    },

    AddressListHeaderButton: {
        justifyContent: 'flex-start',
        borderRadius: '40px'
    },

    AddressListTitle: {
        flexGrow: 1,
        margin: '15px auto',
        width: '100%',
        backgroundColor: '#444444',
        color: '#ffffff',
        borderRadius: '5px'
    },

    AddressListWhite: {
        flexGrow: 1,
        backgroundColor: '#ECF2FF',
        margin: '15px auto',
        borderRadius: '5px',
        width: '100%'
    },

    AddressListDark: {
        flexGrow: 1,
        backgroundColor: '#E5E5E5',
        margin: '15px auto',
        borderRadius: '5px',
        width: '100%'
    },

    AddressListItem: {
        maxHeight: '400px'
    },
    AddressListItemBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        margin: ' 0 10px',
        height: '100%'
    },

    AddressListItemText: {
        textAlign: 'left'
    }

}

export const AddressList = () => {

    const token = getAccessToken("access_token");

    const {id: partnerId, email} = useSelector((state) => state.user);

    const [toastOpen, setToastOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [successMessage, setSuccessMessage] = useState("");

    const [addressList, setAddressList] = useState([]);

    const [tabValue, setTabValue] = useState('one');

    let openModal = useSelector(state => state?.addressList?.addAddress);

    let editModal = useSelector(state => state?.addressList?.editAddress);

    const dispatch = useDispatch();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        dispatch(actions.addressListAction.selectTab(newValue));
    };

    const cbHandleTabChange = (value) => {
        setTabValue(value);
        dispatch(actions.addressListAction.selectTab(value));
    }

    const handleOpenAddress = () => {
        dispatch(actions.addressListAction.addAddress(true));
    }

    const handleEditAddress = (item) => {
        dispatch(actions.addressListAction.editAddress(true));
        dispatch(actions.addressListAction.passAddressInfo(item));
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getAllAddressListSender = async () => {

        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/sender`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    partnerId: partnerId.toString()
                }
            })
            setAddressList(result.data.result);

        } catch (e) {
            console.log(e.response);
        }
    }

    const getAllAddressListDeliver = async () => {

        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/deliver`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    partnerId: partnerId.toString()
                }
            })
            setAddressList(result.data.result);

        } catch (e) {
            console.log(e.response);
        }
    }

    const deleteAddress = async (id) => {
        console.log('delete address id', id);

        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                data: {
                    partnerId: partnerId.toString(),
                    id: id.toString()
                }
            })

            console.log(result);
            handleSuccessMessage();
            setErrorMessage("");
            setSuccessMessage(result.data.message);
            if (tabValue === 'one') {
                getAllAddressListSender();
            } else {
                getAllAddressListDeliver();
            }
        } catch (e) {
            console.log(e.response);
            handleErrorMessage();
            setErrorMessage("fail to delete address");
        }
    }

    const searchAddress = async (searchString) => {
        let requestURL = `${PARTNER_URI}/elasticsearch/addressBook/searchPartnerAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    role: tabValue === 'one' ? 'SENDER' : 'RECEIVER',
                    queryString: searchString
                }
            })

            console.log('result', result?.data);
            setAddressList(result?.data);

        } catch (e) {
           console.log('error', e.response)
        }
    }

    const handleSearch = (event) => {
        const searchString = event.target.value || (+partnerId);
        searchAddress(searchString);
    }

    useEffect(() => {
        partnerId && tabValue === 'one' && getAllAddressListSender();
        partnerId && tabValue === 'two' && getAllAddressListDeliver();
    }, [tabValue])

    console.log('address list', addressList);

    return (
        <Container maxWidth='xl'>
            <Box sx={style.AddressRoot}>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={toastOpen}
                    onClose={handleToastClose}
                    autoHideDuration={6000}
                    message="Submit Transaction"
                >
                    {(() => {
                        if (errorMessage !== "") {
                            return (
                                <Alert
                                    onClose={handleToastClose}
                                    severity="error"
                                    sx={{width: "100%"}}
                                >
                                    Error!
                                    <hr/>
                                    {errorMessage}
                                </Alert>
                            );
                        }
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="success"
                                sx={{width: "100%"}}
                            >
                                {successMessage}
                            </Alert>
                        );
                    })()}
                </Snackbar>
                <Typography variant="h4" style={{marginBottom: '20px'}}>
                    My Address List
                </Typography>
                {!openModal &&
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <Box sx={style.AddressListHeaderTab}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    aria-label="wrapped label tabs example"
                                >
                                    <Tab value="one" label="Sender"/>
                                    <Tab value="two" label="Deliver"/>
                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                <TextField
                                    onInput={handleSearch}
                                    fullWidth
                                    placeholder='Search'
                                    size="small"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            {!editModal &&
                                <Box sx={style.AddressListHeaderAdd}>
                                    <Button sx={style.AddressListHeaderButton} onClick={handleOpenAddress}>
                                        <IconButton>
                                            <AddIcon/>
                                        </IconButton>
                                        Add Address
                                    </Button>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                }
                {openModal && <AddressListAdd
                    getSenderAddressList={getAllAddressListSender}
                    getDeliverAddressList={getAllAddressListDeliver}
                    cbHandleTabChange={cbHandleTabChange}
                />}
                {!openModal &&
                    <Box sx={style.AddressListTitle}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography>
                                    NAME
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    ADDRESS
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>
                                    PHONE
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>
                                    EDIT
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>
                                    DELETE
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {
                    !openModal && addressList?.map((item, index) => {
                        return <Box sx={index % 2 === 0 ? style.AddressListWhite : style.AddressListDark} key={index}>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sx={style.AddressListItem}>
                                    <Box>
                                        <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}}>
                                            {item.name}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Typography style={{wordWrap: 'break-word', whiteSpace: 'pre-line'}}>
                                            {item.address}, {item.city}, {item.province}, {item.country},{item.postal_code}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box>
                                        <Typography>
                                            {item.phone}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box>
                                        <IconButton>
                                            <EditIcon onClick={() => handleEditAddress(item)}/>
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box>
                                        <IconButton onClick={() => deleteAddress(item.id)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    })
                }
                {editModal && <AddressListEdit getSenderAddressList={getAllAddressListSender}
                                               getDeliverAddressList={getAllAddressListDeliver}/>}
            </Box>
        </Container>
    )
}