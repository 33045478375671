import {actionType} from "../../utils/Helper";


export const addCommercialInvoiceList = invoice => ({
    type: actionType.ADD_COMMERCIAL_INVOICE_LIST,
    payload: invoice
})

export const removeCommercialInvoiceList = invoice => ({
    type: actionType.REMOVE_COMMERCIAL_INVOICE_LIST,
    payload: invoice
})

export const clearCommercialInvoiceList = () => ({
    type: actionType.CLEAR_COMMERCIAL_INVOICE_LIST,
})