import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import {Tooltip} from "@material-ui/core";
import ShippingTransactionTable from "./ShippingTransactionTable";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";

const headCells = [
  {
    id: "invoiceNumber",
    numeric: false,
    // disablePadding: true,
    label: "Invoice #",
  },
  {
    id: "trackingNumber",
    numeric: false,
    // disablePadding: true,
    label: "Tracking #",
  },
  {
    id: "dimension",
    numeric: false,
    // disablePadding: false,
    label: "Length / Width / Height (Inch)",
  },
  {
    id: "weight",
    numeric: false,
    // disablePadding: false,
    label: "Weight (LB)",
  },
  {
    id: "price",
    numeric: false,
    // disablePadding: false,
    label: "Price",
  },
  {
    id: "reason",
    numeric: false,
    // disablePadding: false,
    label: "Reason",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
              <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{ fontWeight: "bold" }}
              >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
          ))}
        </TableRow>
      </TableHead>
  );
}

export default function AdjustmentDetails({ transactionList }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, height: "100%",}}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer style={{ maxHeight: 700 }}>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
            >
              <EnhancedTableHead
                  numSelected={transactionList.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={transactionList.length}
              />
              <TableBody>
                {
                  transactionList.map(v => {
                    return (
                        <TableRow
                            hover
                            tabIndex={-1}
                            key={v.id}
                            // selected={isItemSelected}
                        >
                          <TableCell>
                            {v.invoice_no}
                          </TableCell>
                          <TableCell>
                            {v.tracking_no}
                          </TableCell>
                          <TableCell>
                            {v.length} / {v.width} / {v.height}
                          </TableCell>
                          <TableCell>
                            {v.weight}
                          </TableCell>
                          <TableCell>
                            excl. tax:{" "}
                            <CurrencyFormat
                                value={Math.abs(v.shipping_fee_exclude_tax)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={
                                  +v.shipping_fee_exclude_tax >= 0 ? `${v?.currency_code || "C"} $` : `${v?.currency_code || "C"} $-`
                                }
                            />
                            <br />
                            inc. tax:{" "}
                            <CurrencyFormat
                                value={Math.abs(v.shipping_fee_include_tax)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={
                                  +v.shipping_fee_include_tax >= 0 ? `${v?.currency_code || "C"} $` : `${v?.currency_code || "C"} $-`
                                }
                            />
                          </TableCell>
                          <TableCell>
                            {v.invoice_section}
                          </TableCell>
                        </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          {/*<TablePagination*/}
          {/*  // rowsPerPageOptions={[5, 10, 25]}*/}
          {/*    rowsPerPageOptions={[]}*/}
          {/*  component="div"*/}
          {/*  count={10}*/}
          {/*  rowsPerPage={rowsPerPage}*/}
          {/*  page={page}*/}
          {/*  labelRowsPerPage=""*/}
          {/*  onPageChange={handleChangePage}*/}
          {/*  // onRowsPerPageChange={handleChangeRowsPerPage}*/}
          {/*/>*/}
        </Paper>
      </Box>
  );
}
