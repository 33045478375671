import {Container, FormLabel, InputAdornment, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {getAccessToken} from "../../../utils/doToken";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import {PARTNER_URI} from "../../../utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../../utils/consInfo";
import {ShippingOrderList} from "../../HomePage/ShippingOrderList";
import {Pagination} from "@mui/lab";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "16px"
        }
    }
});

export const PackageOrderList = () => {

    const storedToken = getAccessToken("access_token");

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [searchString, setSearchString] = useState(null);
    const [carrier, setCarrier] = useState('carrier');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const handleChange = (event) => {
        console.log(event.target.value);
        setCarrier(event.target.value);
    };

    const handleStartDate = (event) => {
        const inputDate = event.target.value;
        setStartDate(inputDate);
    }

    const handleEndDate = (event) => {
        const inputDate = event.target.value;
        setEndDate(inputDate);
    }

    const getPackageOrderList = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingPackageOrder/searchShippingPackageOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString || "",
                    page: 0,
                    pageSize: PAGE_SIZE,
                    carrier: (carrier !== 'carrier' && carrier !== 'all') ? carrier : null,
                    createDateBefore: endDate,
                    createDateAfter: startDate
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
            setError(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getPackageOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingPackageOrder/searchShippingPackageOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString || "",
                    page: page,
                    pageSize: PAGE_SIZE,
                    carrier: (carrier !== 'carrier' && carrier !== 'all') ? carrier : null,
                    createDateBefore: endDate,
                    createDateAfter: startDate
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setError(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value;
        getPackageOrderListByPage(page);
    };

    const handleSearchShippingOrder = () => {
        getPackageOrderList();
    }

    const handleSearchShippingOrderByKeyDown = (event) => {
        if (event.key === 'Enter') {
            getPackageOrderList();
        }
    }

    useEffect(async () => {
        await getPackageOrderList();
    }, [])

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Shipping Order List
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Carrier
                                </FormLabel>
                                <Select
                                    value={carrier}
                                    size='small'
                                    placeholder='Carrier'
                                    onChange={handleChange}
                                >
                                    <MenuItem disabled value='carrier' sx={{fontSize: '14px'}}>
                                        <em>Carrier</em>
                                    </MenuItem>
                                    <MenuItem value='all' sx={{fontSize: '14px'}}>
                                        All
                                    </MenuItem>
                                    <MenuItem value='ups' sx={{fontSize: '14px'}}>
                                        UPS
                                    </MenuItem>
                                    <MenuItem value='canadaPost' sx={{fontSize: '14px'}}>
                                        Canada Post
                                    </MenuItem>
                                    <MenuItem value='canpar' sx={{fontSize: '14px'}}>
                                        Canpar
                                    </MenuItem>
                                    <MenuItem value='fedex' sx={{fontSize: '14px'}}>
                                        FedEx
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <FormLabel>
                                    Start Date
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    type='date'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={startDate}
                                    onChange={handleStartDate}
                                    size='small'
                                />
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <FormLabel>
                                    End Date
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    type='date'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={endDate}
                                    onChange={handleEndDate}
                                    size='small'
                                />
                            </Box>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <TextField
                                value={searchString}
                                fullWidth
                                size='small'
                                onInput={handleSearchString}
                                onKeyDown={handleSearchShippingOrderByKeyDown}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                classes={{root: classes.customTextField}}
                                // label='Order# / REF# / Tracking# / Name / Email / Address / Phone'
                                placeholder={intl.get('HOME_PAGE.SHIPPING_ORDER.SEARCH_PLACEHOLDER')}
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleSearchShippingOrder}
                                    loading={loading}
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                        Search
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {
                    loading ?
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        error ?
                            <Box sx={styles.ShippingOrderContent}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.ERROR')}
                                </Typography>
                            </Box>
                            :
                            orderData?.length === 0 ?
                                <Box sx={styles.ShippingOrderContent}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        {intl.get('HOME_PAGE.SHIPPING_ORDER.NO_RESULTS')}
                                    </Typography>
                                </Box>
                                :
                                <Box sx={styles.ShippingOrderContent}>
                                    <ShippingOrderList order={orderData}/>
                                    <Pagination
                                        count={lastPage}
                                        page={+currentPage}
                                        size='small'
                                        // defaultPage={6}
                                        onChange={handlePageChange}
                                        // boundaryCount={2}
                                    />
                                </Box>
                }
            </Box>
        </Container>
    )
}