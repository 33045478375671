import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {Provider} from "react-redux";

// import ShipmentManagementPage from "./component/ShipmentManagementPage";

// import OrderManagementPage from "./component/OrderManagementPage";
// import ShipmentDetailPage from "./component/ShipmentDetailPage";
import UserSignIn from "./component/SignIn/userSignIn";

import CsvFFHandler from "./component/CsvFFHandler/csvFFHandler";
import LandingPage from "./component/landingPage";
import MainContainer from "./component/mainContainer";
import store from "./redux/store";
import {getAccessToken, updateTokenFromCookie} from "./utils/doToken";
import "./App.css";
import ShippingListMultipleTrackingNumber from "./component/ShippingList/ShippingListMultipleTrackingNumber";
import ShippingListProduct from "./component/ShippingList/ShippingListProduct";
import ShippingListTracking from "./component/ShippingList/ShippingListTracking";
import Printer from "./component/Printer/Printer";
import ShippingOrderList from "./component/ShippingList/ShippingOrderList";
import PrivateRoute from "./component/PrivateRoute";
import ShippingListTrackingByOrderId from "./component/ShippingList/ShippingListTrackingByOrderId";
import BolListPage from "./component/Hscode/bolListPage";
import HscodePage from "./component/Hscode/hscodePage";
import {createTheme, MuiThemeProvider} from "@material-ui/core";
import UserProfile from "./component/UserProfile/userProfile";
import ChangePassword from "./component/Auth/changePassword";
import HSCodeMainPage from "./component/HSCodeTrainingPage/HSCodeMainPage";
import StartQuote from "./component/Shipping/startQuote";
import OceanShippingPage from "./component/OceanShipping/OceanShippingPage";
import OceanBooking from "./component/OceanBooking/OceanBooking";
import ShippingOrderListByOrderId from "./component/ShippingList/ShippingOrderListByOrderId";
import PartnerInvoicePage from "./component/Invoice/PartnerInvoicePage";
import StartPalletsQuote from "./component/Pallets/startPalletsQuote";
import PalletsListPage from "./component/PalletsList/paletsListPage";
import ShippingExportsTransaction from "./component/ShippingList/ShippingExportsTransaction";
import AllPartnerShippingOrderPaymentList
    from "./component/Shipping/SearchShippingOrders/All3PLShippingOrderList/OrderPaymentList"
import ShippingOrderPaymentDetails from "./component/Shipping/SearchShippingOrders/ShippingOrderPaymentDetails"
import SearchShippingTransactions from "./component/SearchShippingTransactions/SearchShippingTransactions";
import CommercialInvoicePage from "./component/CommercialInvoice/commercialInvoicePage";
import SchedulePickup from "./component/SchedulePickup/SchedulePickup";
import MyScheduledPickups from "./component/SchedulePickup/MyScheduledPickups";
import TrackPackages from "./component/Tracking/TrackPackages";
import ShippingOrderDashBoardByOrderId from "./component/ShippingList/ShippingOrderDashboardByOrderId";
import OrderDashboard from "./component/OrderDashboard/OrderDashboard";
import BillingCenter from "./component/BillingCenter/BilingCenter";
import StartShippingLTL from "./component/ShippingLTL/startShippingLTL";
import {Brokerage} from "./component/Brokerage/Brokerage";
import {MyBoxes} from "./component/MyBoxes/MyBoxes";
import {PackingList} from "./component/PackingList/PackingList";
import {BrokeragePackingList} from "./component/Brokerage/BrokeragePackingList";
import {AddressList} from "./component/AddressList/AddressList";
import {LandingEstimate} from "./component/Landing/LandingEstimate";
import {Landing} from "./component/Landing/Landing";
import ShippingViaExcel from "./component/ShippingViaExcel/csvFFHandler";
import ReactGA from "react-ga4";
import {useGaTracker} from "./shared/Hooks/useGaTracker";
import {Routes} from "./Routes/Routes";
import * as intl from "react-intl-universal";
import enUS from '../src/locales/en-US.json';
import zhCN from '../src/locales/zh-CN.json';
import zhTW from '../src/locales/zh-TW.json';

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffa726",
        },
    },
});

const LOCALES_LIST = [
    {
        label: "English",
        value: "en-US",
    },
    {
        label: "简体中文",
        value: "zh-CN",
    },
    {
        label: "繁體中文",
        value: "zh-TW"
    },
];

const LOCALE_DATA = {
    "en-US": enUS,
    "zh-CN": zhCN,
    "zh-TW": zhTW,
}

// ReactGA.initialize("G-48C69B3M9H");

export default function App() {

    const [initDone, setInitDone] = useState(false);

    const initializeIntl = () => {

        // 1. Get the currentLocale from cookie and browsers settings
        let currentLocale = intl.determineLocale({
            cookieLocaleKey: 'lang',
            localStorageLocaleKey: 'lang'
        })

        console.log('current locale', currentLocale);

        // 2. Fallback to "en-US" if the currentLocale isn't supported in LOCALES_LIST
        if (!LOCALES_LIST.some(item => item.value === currentLocale)) {
            currentLocale = "en-US"
        }

        // 3. Set currentLocale and load locale data
        setCurrentLocale(currentLocale);

        // 4. After loading locale data, start to render
        setInitDone(true);

    }


    const setCurrentLocale = (currentLocale) => {
        intl.init({
            currentLocale,
            locales: LOCALE_DATA,
        });
    };

    useEffect(() => {
        initializeIntl();
        // redirect to https://app.uucargo.ca
        // window.location.href = 'https://app.uucargo.ca';
        updateTokenFromCookie();
    }, [])

    return (
        <div className="App">
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <Router>
                        <MainContainer>
                            <Routes/>
                        </MainContainer>
                    </Router>
                </Provider>
            </MuiThemeProvider>
        </div>
    );
}
