import React, {useRef, useState} from "react";
import {
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Snackbar
} from "@material-ui/core";
import {Link} from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import {Alert} from "@material-ui/lab";
import dayjs from "dayjs";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import LoadingButton from "@mui/lab/LoadingButton";
import Upload from "@mui/icons-material/Upload";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    listItemWrapper: {
        width: "100%",
        margin: "3px auto",
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
        lineHeight: "2rem",
        textAlign: "left"
    },
}));
const getStatusText = (status) => {
    switch (status) {
        case 0:
            return "Not Upload";
        case 1:
            return "PDF Uploaded";
        case 2:
            return "PDF Pushed"
    }
}

export default function CommercialInvoiceTrackingTable({
                                                           label_uri,
                                                           pdfURI,
                                                           documentId,
                                                           docId,
                                                           itemName,
                                                           tracking_no,
                                                           shippingIdNumber,
                                                           length,
                                                           width,
                                                           height,
                                                           weight,
                                                           lengthUnit,
                                                           weightUnit,
                                                           unitPrice,
                                                           quantity,
                                                           currency,
                                                           hscode,
                                                           getDocListByTranId,
                                                           orderId,
                                                           tranId,
                                                           status,
                                                           getTrackingListByTranId
                                                       }) {
    const classes = useStyles();
    const [toastOpen, setToastOpen] = useState(false);
    const [confirmToastOpen, setConfirmToastOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [confirmErrorMessage, setConfirmErrorMessage] = useState("");
    const inputPDFFile = useRef(null);
    const [uploadPDFLoading, setUploadPDFLoading] = useState(false);
    const [confirmPDFLoading, setConfirmPDFLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const uploadPDFToServer = async ({fileObj}) => {
        try {
            if (!fileObj) {
                return;
            }
            setUploadPDFLoading(true);
            const formData = new FormData();
            formData.append("tranId", tranId);
            formData.append("invoice_pdf", fileObj);
            formData.append("orderId", orderId);
            formData.append("trackingNumber", tracking_no);
            formData.append("shippingIdNumber", shippingIdNumber);

            const {data} = await axios.post(`${PARTNER_URI}/commercialInvoice/uploadCommercialInvoiceViaTrackingNumber/ups`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data;",
                }
            })
            console.log(data.result);
            setErrorMessage("");
            handleToastClick();
        } catch (e) {
            console.log(e);
            // setErrorMessage("Error! This CSV file is invalid! Either row errors or you already submit this before!");
            // handleToastClick();
            setErrorMessage(e?.message || e?.error?.message || "Error");
        } finally {
            setUploadPDFLoading(false);
        }
    }

    const confirmPDF = async () => {
        try {
            setConfirmPDFLoading(true);
            setConfirmErrorMessage("");
            const requestData = {
                documentId,
                shipmentIdentifier: shippingIdNumber,
                trackingNumber: tracking_no,
                docId: docId
            }
            const {data} = await axios.post(`${PARTNER_URI}/commercialInvoice/upsPaperlessPushImageToRepo`, requestData);
            console.log(data);
        } catch (e) {
            console.log(e);
            setConfirmErrorMessage(e?.message || "Error!");
        } finally {
            handleConfirmDialogClose();
            getTrackingListByTranId();
            setConfirmPDFLoading(false);
        }
    }

    const onPDFDrop = async (e) => {
        if (!(e.target.files && e.target.files[0])) {
            return;
        }
        // setPdfFileObj(e.target.files[0]);
        // setPDFFileName(e.target.files[0].name);
        await uploadPDFToServer({fileObj: e.target.files[0]});
        await getTrackingListByTranId();
        await getDocListByTranId();
    };

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const confirmPlaceOrder = async (e) => {
        e.preventDefault();
        // console.log(requestBody);
        try {
            setPlaceOrderLoading(true);
            // const { data } = await axios.post(`${PARTNER_URI}/ocean-freight/customize`, requestBody);
            // console.log(data);
            setErrorMessage("");
            handleToastClick();
            handleConfirmDialogClose();
        } catch (e) {
            setErrorMessage(e?.response?.data?.errorMessage || "Error!");
            console.log(e);
        } finally {
            setPlaceOrderLoading(false);
        }
    }

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const handleConfirmDialogOpen = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order">
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert onClose={handleToastClose} severity="error" sx={{width: '100%'}}>
                                Push Image Failed!
                                <hr/>
                                Error: {errorMessage}
                            </Alert>
                        )
                    }
                    return (
                        <Alert onClose={handleToastClose} severity="success" sx={{width: '100%'}}>
                            Submit Success!
                        </Alert>
                    )
                })()}
            </Snackbar>
            {/*Pop-up Toast*/}
            {/*Confirm Delete Tracking Dialog*/}
            <Dialog
                open={confirmDialogOpen}
                onClose={handleConfirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Invoice PDF with Tracking Number
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will associate the tracking to this Document PDF <b>
                        <a href={pdfURI || "#"} target="_blank" rel="noopener noreferrer">{pdfURI ? "Download" : ""}</a>
                    </b>. Are you sure to confirm?
                        <hr/>
                        Click <b>Confirm</b> to confirm.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <Button onClick={confirmPDF} variant="outlined" type="button" disabled={confirmPDFLoading}>
                        {confirmPDFLoading && <CircularProgress size={14}/>}
                        {!confirmPDFLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Confirm Delete Tracking Dialog*/}
            <tr className={classes.listItemWrapper}>
                <td>{tracking_no}</td>
                <td>{itemName}</td>
                <td>{length}&times;{width}&times;{height} ({lengthUnit})</td>
                <td>{weight} ({weightUnit})</td>
                {/*<td>*/}
                {/*    <CurrencyFormat value={unitPrice} displayType={'text'} thousandSeparator={true}*/}
                {/*                    prefix={`${currency} $`}/>*/}
                {/*</td>*/}
                {/*<td>{hscode}</td>*/}
                {/*<td>{quantity}</td>*/}
                <td>
                    <a href={label_uri} target="_blank" rel="noopener noreferrer">{label_uri ? "Download" : ""}</a>
                </td>
                <td>
                    <a href={pdfURI || "#"} target="_blank" rel="noopener noreferrer">{pdfURI ? "Download" : ""}</a>
                </td>
                <td>{getStatusText(status)}</td>
                <td width="150px">
                    <input type="file" style={{display: "none"}} ref={inputPDFFile} onChange={onPDFDrop} accept=".pdf"
                           name="file"/>
                    <LoadingButton
                        variant="contained"
                        component="span"
                        color="primary"
                        style={{
                            textTransform: "unset",
                            fontSize: "1rem",
                        }}
                        onClick={() => {
                            inputPDFFile.current.click();
                        }}
                        endIcon={<Upload/>}
                        loading={uploadPDFLoading}
                        disabled={status === 2}
                    >
                        {
                            !status ? "Upload" : "Replace"
                        }
                    </LoadingButton>
                </td>
                <td width="150px">
                    <LoadingButton
                        variant="contained"
                        component="span"
                        color="secondary"
                        style={{
                            textTransform: "unset",
                            fontSize: "1rem",
                        }}
                        onClick={() => {
                            setConfirmDialogOpen(true);
                        }}
                        endIcon={<CheckCircleOutlineIcon/>}
                        loading={confirmPDFLoading}
                        disabled={status !== 1}
                    >
                        Submit
                    </LoadingButton>
                </td>
            </tr>
        </>
    );
}
