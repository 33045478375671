import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import JlinkLogo from "../../images/Jlink_logo.jpg";
import {getDeliverTime, mapServiceLogo} from "../../utils/Helper";
import {Divider} from "@material-ui/core";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import {LinearProgress} from "@mui/joy";
import {updateLandingClickEstimate, updateLandingClickJoinUs} from "../Actions/landingEstimateAction";
import {useMediaQuery} from "@mui/material";
import intl from "react-intl-universal";

const style = {
    EstimateCostRoot: {
        margin: '50px auto'
    },
    EstimateCostPackageRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px 60px',
        borderRadius: '10px',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    EstimateCostHeading: {
        textAlign: 'left',
        // fontSize: '14px',
        padding: '20px',
        fontWeight: '600',
        '@media (max-width: 480px)': {
            padding: '0 5px',
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostText: {
        textAlign: 'left',
        // fontSize: '14px',
        padding: '20px',
        '@media (max-width: 480px)': {
            padding: 0,
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostText2: {
        textAlign: 'left',
        // fontSize: '14px',
        '@media (max-width: 480px)': {
            padding: 0,
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostPallet: {
        height: '100px',
        padding: '20px 60px 60px',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    EstimateCostImageBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 20px',
        height: '100%',
        '@media (max-width: 480px)': {
            padding: '0 5px',
        },
    },
    EstimateCostBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 20px',
        gap: '5px',
        height: '100%',
        '@media (max-width: 480px)': {
            padding: '5px 0',
        },
    },
    EstimateCostLiner: {
        '--LinearProgress-radius': '6px',
        '--LinearProgress-progressThickness': '12px',
        color: 'green',
        '@media (max-width: 480px)': {
            '--LinearProgress-progressThickness': '6px',
        },
    },
    EstimateCostButtonBox: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: '20px',
        '@media (max-width: 480px)': {
            paddingLeft: 0,
        },
    },
    EstimateCostButtonText: {
        // fontSize: '14px',
        '@media (max-width: 480px)': {
            fontSize: '10px'// for phone and above
        },
    }
}

export const EstimateCost = () => {

    let palletData = useSelector(state => state?.landingEstimate?.landingEstimatePallet);
    let packageData = useSelector(state => state?.landingEstimate?.landingEstimatePackage);
    let estimatePackageCost = useSelector(state => state?.landingEstimate?.landingEstimatePackageCost);
    let estimatePalletCost = useSelector(state => state?.landingEstimate?.landingEstimatePalletCost);
    let estimateType = useSelector(state => state?.landingEstimate?.landingEstimateTypeService);
    let error = useSelector(state => state?.landingEstimate?.landingEstimateCostError);

    const [estimatePackageCostList, setEstimatePackageCostList] = useState([]);
    const [hideOptions, setHideOptions] = useState(false);
    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 480px)');

    useEffect(() => {
        const sortedEstimateCost = [...estimatePackageCost].sort((current, next) => current?.totalCharge?.value - next?.totalCharge?.value);
        setEstimatePackageCostList(sortedEstimateCost)
        estimatePackageCost.length > 3 ? setHideOptions(true) : setHideOptions(false);
    }, [estimatePackageCost])

    // console.log('cost', estimatePackageCostList);

    console.log('[EstimateCost] packageData', packageData);
    console.log('[EstimateCost] palletData', palletData);

    return (
        <Box sx={style.EstimateCostRoot}>
            {estimateType &&
                <Box>
                    {estimateType === 'pallet' ? (!error && estimatePalletCost?.finalTotal !== Infinity ?
                        <Box sx={style.EstimateCostPallet}>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontSize: '18px', fontWeight: '600'}}>
                                            Shipping Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography>
                                            Dimension: 48 x 48 x 60 in
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>
                                            Weight: 400 lb
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>
                                            Quantity: 1
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item md={6} xs={12}>
                                                <Typography>
                                                    Pickup: {palletData?.pickupCity}, {palletData?.pickupProvince?.code}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Typography>
                                                    Deliver: {palletData?.deliverCity}, {palletData?.deliverProvince?.code}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={isMobile ? 6 : 4}>
                                        <Typography sx={style.EstimateCostHeading}>
                                            {intl.get('LANDING_PAGE.CALCULATOR.SERVICE_TYPE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={isMobile ? 6 : 4}>
                                        <Typography sx={style.EstimateCostHeading}>
                                            {intl.get('LANDING_PAGE.CALCULATOR.TOTAL_COST')}
                                        </Typography>
                                    </Grid>
                                    {!isMobile &&
                                        <Grid item xs={4}>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                            <Divider/>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={isMobile ? 6 : 4}>
                                        <Typography sx={style.EstimateCostText}>
                                            Standard
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={isMobile ? 6 : 4}>
                                        <Typography sx={style.EstimateCostText}>
                                            $ {estimatePalletCost?.finalTotal}
                                        </Typography>
                                    </Grid>
                                    {!isMobile &&
                                        <Grid item xs={4}>
                                            <Box sx={style.EstimateCostButtonBox}>
                                                <Button
                                                    variant='contained'
                                                    onClick={() => history.push('/sign-in')}
                                                >
                                                    <Typography sx={style.EstimateCostButtonText}>
                                                        {intl.get('LANDING_PAGE.CALCULATOR.SHIP')}
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    }
                                </Grid>
                                <Divider/>
                            </Box>
                        </Box> : <Box>
                            <Typography>
                                {intl.get('LANDING_PAGE.CALCULATOR.TEXT_1')}
                            </Typography>
                            <Typography>
                                {intl.get('LANDING_PAGE.CALCULATOR.TEXT_2')}
                            </Typography>
                        </Box>) : (estimatePackageCost.length !== 0 ? <Box sx={style.EstimateCostPackageRoot}>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography sx={{fontSize: '18px', fontWeight: '600'}}>
                                        Shipping Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>
                                        Dimension: {packageData?.length} x {packageData?.width} x {packageData?.height} {packageData?.dimensionUnit}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>
                                        Weight: {packageData?.weight} {packageData?.weightUnit}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>
                                        Quantity: {packageData?.quantity}
                                    </Typography>
                                </Grid>
                                <Grid item md={5} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item md={6} xs={12}>
                                            <Typography>
                                                Pickup: {packageData?.pickupProvince?.code}, {packageData?.pickupPostalCode}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography>
                                                Deliver: {packageData?.deliverProvince?.code}, {packageData?.deliverPostalCode}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={style.EstimateCostHeading}>
                                        {intl.get('LANDING_PAGE.CALCULATOR.CARRIER')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={isMobile ? 5 : 4}>
                                    <Typography sx={style.EstimateCostHeading}>
                                        {intl.get('LANDING_PAGE.CALCULATOR.SERVICE_TYPE')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography sx={style.EstimateCostHeading}>
                                        {intl.get('LANDING_PAGE.CALCULATOR.DELIVERY_TIME')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={style.EstimateCostHeading}>
                                        {intl.get('LANDING_PAGE.CALCULATOR.TOTAL_COST')}
                                    </Typography>
                                </Grid>
                                {!isMobile &&
                                    <Grid item xs={1}>
                                    </Grid>
                                }
                            </Grid>
                            <Divider/>
                        </Box>
                        {
                            estimatePackageCostList?.map((item, index) => {
                                return (
                                    <Box key={index}
                                         sx={{
                                             display: `${index > 3 && hideOptions && 'none'}`,
                                         }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                                <Box sx={style.EstimateCostImageBox}>
                                                    <img src={mapServiceLogo(item?.serviceName)}
                                                         width={isMobile ? '20px' : '40px'}/>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={isMobile ? 5 : 4}>
                                                <Box sx={style.EstimateCostBox}>
                                                    <Typography sx={style.EstimateCostText}>
                                                        {item?.serviceName}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box sx={style.EstimateCostBox}>
                                                    {/*{!isMobile && <LinearProgress*/}
                                                    {/*    determinate*/}
                                                    {/*    value={item?.serviceType === 'express' ? '20' : getDeliverTime(item?.expectedDate) === 0 ? '30' : '50'}*/}
                                                    {/*    sx={style.EstimateCostLiner}*/}
                                                    {/*/>}*/}
                                                    <Typography sx={style.EstimateCostText2}>
                                                        {item?.shipRange} business day(s)
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Box sx={style.EstimateCostBox}>
                                                    <Typography sx={style.EstimateCostText}>
                                                        $ {item?.totalChargeWithTaxes.value}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            {!isMobile &&
                                                <Grid item xs={1}>
                                                    <Box sx={style.EstimateCostButtonBox}>
                                                        <Button
                                                            variant='contained'
                                                            onClick={() => history.push('/sign-in')}
                                                        >
                                                            <Typography sx={style.EstimateCostButtonText}>
                                                                {intl.get('LANDING_PAGE.CALCULATOR.SHIP')}
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Divider/>
                                    </Box>
                                )
                            })
                        }
                        <Box>
                            {hideOptions ? <Button onClick={() => setHideOptions(false)}>
                                    {intl.get('LANDING_PAGE.CALCULATOR.MORE')}
                                </Button> :
                                <Button onClick={() => setHideOptions(true)}>
                                    {intl.get('LANDING_PAGE.CALCULATOR.HIDE')}
                                </Button>
                            }
                        </Box>
                    </Box> : <Box>
                        <Typography>
                            {intl.get('LANDING_PAGE.CALCULATOR.TEXT_1')}
                        </Typography>
                        <Typography>
                            {intl.get('LANDING_PAGE.CALCULATOR.TEXT_2')}
                        </Typography>
                    </Box>)}
                    <Typography style={{textAlign: 'right', fontSize: '12px', fontStyle: 'italic'}}>
                        {intl.get('LANDING_PAGE.CALCULATOR.TEXT_3')}
                    </Typography>
                </Box>
            }
        </Box>
    )
}