import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import {
  makeStyles,
  Container,
  Box,
  MobileStepper,
  Button,
} from "@material-ui/core";
import produce from "immer";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import axios from "axios";
import {useLocation, useParams} from "react-router-dom";
import LoadingContainer from "../../shared/loadingContainer";
// import { Alert } from '@material-ui/lab';
// import { defaultShippingItem } from "../../slices";
// import { shipmentTypesDetails } from "../shared/constInfo";
import HscodeAddressInput from "./Components/BolAddressInput/hscodeAddressInput";
import HscodeItems from "./Components/BolItems/bolItems";
import BolDetails from "./Components/BolDetails/bolDetails";
import {BOL_DETAILS, PARTNER_URI} from "../../utils/apiUrl";
import { getHeaders, errHandling } from "../../utils/fetchUtil";
import {useSelector} from "react-redux";

const defaultBolData = {
  shipperName: "",
  shipperAddress: "",
  carrierAgent: "",
  consigneeName: "",
  consigneeAddress: "",
  deliveryAgent: "",
  placeOfAcceptance: "",
  preCarriage: "",
  numberOfOriginal: null,
  payableAt: "",
  excessValueDeclaration: "",
  vessel: "",
  portOfLoading: "",
  portOfDischarge: "",
  placeOfDelivery: "",
  onCarriageTo: "",
  onBoardDate: "",
  freightDetails: "",
  issueDate: "",
  issuePlace: "",
  notifyPartyName: "",
  notifyPartyAddress: "",
  // New Added
  shipCompany: "",
  startPortCode: "",
  endPortCode: "",
  routeCode: "",
  booking_id: "",
  bol_no: "",
  bol_id: "",
  deliveryAgentAddress: "",
  deliveryAgentPhone: "",
  deliveryAgentEmail: "",
  consigneePhone: "",
  shipperPhone: "",
  forwardingAgentName: "",
  forwardingAgentAddress: "",
  forwardingAgentPhone: "",
  forwardingAgentEmail: "",
  status: 0
};

const useQuery = () =>{
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const HscodePage = ({ edit = false }) => {
  // const dispatch = useDispatch();
  const classes = useStyles();
  const { number: bolNumber } = useParams();

  const [success, setSuccessState] = useState(false);
  // const shippingItems = useSelector((state) => state.shipping.shippingItems);

  const [shippingInfo, setShippingInfo] = useState(defaultBolData);

  const [itemList, updateItemList] = useState([]);
  const [fetchingState, setFetchingState] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  // const classes = useStyles();

  const [userInputError, setUserInputError] = useState({
    emtpyError: false,
  });

  const [activeStep, setActiveStep] = React.useState(edit ? 2 : 0);


  const query = useQuery();
  // const isFromOceanPage = query.has("fromOcean") || false;
  const bookingId = query.get("booking_id");
  const bolId = query.get("bol_id");
  const { id: partnerId  } = useSelector((state) => state.user);

  // useEffect(() => {
  //   console.log("Is From Ocean Page: ", isFromOceanPage);
  // }, [isFromOceanPage])

  // From Redux
  // const [oceanShippingData, setOceanShippingData] = useState({});
  // const oceanShippingDataRedux = useSelector((state) => state.oceanShipping);

  // From API
  const [oceanBookingData, setOceanBookingData] = useState({});

  useEffect(() => {
    console.log("Ocean Booking Data", oceanBookingData);
  }, [oceanBookingData]);

  useEffect(() => {
    if (bookingId) {
      (async () => {
        try {
          const { data } = await axios.get(`${PARTNER_URI}/ocean-freight/oceanShipBooking?bookingId=${bookingId}&partnerId=${partnerId}`);
          if (data?.result?.booking_id) {
            const record = data.result;
            setOceanBookingData(record);
            setShippingInfo(v => {
              return {
                ...v,
                vessel: record.voyage,
                portOfLoading: record.start_port_en_name,
                portOfDischarge: record.end_port_en_name,
                onBoardDate: record.etd_date,
                startPortCode: record.start_port_code,
                endPortCode: record.end_port_code,
                routeCode: record.ship_company_route_code,
                booking_id: record.booking_id,
                shipCompany: record.ship_company,
              }

            })
          }

        } catch (e) {
          console.log(e);
        }
      })()
    }

  }, [bookingId, partnerId])

  useEffect(() => {
    if (bolId) {
      (async () => {
        try {
          const { data } = await axios.post(`${PARTNER_URI}/partner/bol/getBolWithPackagesByPartnerId`, {
            "bol_id": bolId,
            "partnerId": partnerId
          });
          if (data?.result?.booking_id) {
            const record = data.result;
            console.log("Existing Record:", record);
            // setOceanBookingData(record);
            setShippingInfo(v => {
              return {
                ...v,
                vessel: record.voyage,
                portOfLoading: record.start_port_en_name,
                portOfDischarge: record.end_port_en_name,
                onBoardDate: record.etd_date,
                startPortCode: record.start_port_code,
                endPortCode: record.end_port_code,
                routeCode: record.ship_company_route_code,
                booking_id: record.booking_id,
                shipCompany: record.ship_company,
                bol_id: record.bol_id,
                bol_no: record.bol_no,
                shipperAddress: record.shipper_full_address,
                consigneeAddress: record.consignee_full_address,
                shipperName: record.shipper_name,
                consigneeName: record.consignee_name,
                notifyPartyName: record.notify_party_name,
                notifyPartyAddress: record.notify_party_full_address,
                carrierAgent: record.carrier_agent,
                deliveryAgent: record.delivery_agent,
                deliveryAgentAddress: record.delivery_agent_full_address,
                deliveryAgentPhone: record.delivery_agent_phone,
                deliveryAgentEmail: record.delivery_agent_email,
                consigneePhone: record.consignee_phone,
                shipperPhone: record.shipper_phone,
                forwardingAgentName: record.forwarding_agent_name,
                forwardingAgentAddress: record.forwarding_agent_full_address,
                forwardingAgentPhone: record.forwarding_agent_phone,
                forwardingAgentEmail: record.forwarding_agent_email,
                status: record.status
              }
            })
            updateItemList(record.packages.map(item => {
              return {
                id: item.id,
                hscode: item.item_hs_code,
                hscodeDescription: item.item_desc,
                packageUnit: item.unit_of_package,
                packageType: item.package_type,
                measurement: item.measurement_cbm,
                weight: item.gross_weight_kg,
                containerType: item.container_type,
                containerNumber: item.container_no,
                containerUnit: item.unit_of_container,
                sealNumber: item.seal_no,
              }
            }));
          }
        } catch (e) {
          console.log(e);
        }
      })()
    }

  }, [bolId, partnerId])

  // useEffect(() => {
  //   if (isFromOceanPage) {
  //     setOceanShippingData(oceanShippingDataRedux)
  //     // If Ocean Shipping Data Redux Exist
  //     if (oceanShippingDataRedux?.endPort) {
  //       setShippingInfo(v => {
  //         return {
  //           ...v,
  //           vessel: oceanShippingDataRedux.voyage,
  //           portOfLoading: oceanShippingDataRedux.startPort,
  //           portOfDischarge: oceanShippingDataRedux.endPort,
  //           onBoardDate: oceanShippingDataRedux.sailingDate,
  //           startPortCode: oceanShippingDataRedux.startPortCode,
  //           endPortCode: oceanShippingDataRedux.endPortCode,
  //           routeCode: oceanShippingDataRedux.routeCode
  //         }
  //
  //       })
  //     }
  //   }
  // }, [isFromOceanPage])
  //
  // useEffect(() => {
  //   console.log("Ocean Shipping Data:", oceanShippingData);
  // }, [oceanShippingData]);

  useEffect(() => {
    if (edit) {
      axios
        .get(`${BOL_DETAILS}?ref_no=${bolNumber}`, {
          headers: getHeaders(),
        })
        .then((response) => {
          if (response.data) {
            const {
              shipper_name,
              shipper_address,
              carrier_agent,
              consignee_name,
              consignee_address,
              delivery_agent,
              place_of_acceptance,
              pre_carriage,
              number_of_original,
              payable_at,
              excess_value_declaration,
              vessel,
              port_of_loading,
              port_of_discharge,
              place_of_delivery,
              on_carriage_to,
              on_board_date,
              freight_details,
              issue_date,
              issue_place,
              notify_party_name,
              notify_party_address,
            } = response.data;
            setShippingInfo({
              shipperName: shipper_name,
              shipperAddress: shipper_address,
              carrierAgent: carrier_agent,
              consigneeName: consignee_name,
              consigneeAddress: consignee_address,
              deliveryAgent: delivery_agent,
              placeOfAcceptance: place_of_acceptance,
              preCarriage: pre_carriage,
              numberOfOriginal: number_of_original,
              payableAt: payable_at,
              excessValueDeclaration: excess_value_declaration,
              vessel: vessel,
              portOfLoading: port_of_loading,
              portOfDischarge: port_of_discharge,
              placeOfDelivery: place_of_delivery,
              onCarriageTo: on_carriage_to,
              onBoardDate: on_board_date,
              freightDetails: freight_details,
              issueDate: issue_date,
              issuePlace: issue_place,
              notifyPartyName: notify_party_name,
              notifyPartyAddress: notify_party_address,
            });
          }

          if (response.data?.item_list) {
            updateItemList(response.data?.item_list);
          }
          setFetchingState(false);
        })
        .catch((err) => {
          errHandling(err, setErrMsg);
          // setPriceErrMsg(err.response.data.message);
          setFetchingState(false);
        });
    }
  }, []);

  useEffect(() => {
    const emptyField =
      shippingInfo.shipperName === "" ||
      shippingInfo.shipperAddress === "" ||
      shippingInfo.carrierAgent === "" ||
      shippingInfo.consigneeName === "" ||
      shippingInfo.consigneeAddress === "" ||
      shippingInfo.deliveryAgent === "" ||
      shippingInfo.placeOfAcceptance === "" ||
      shippingInfo.preCarriage === "" ||
      shippingInfo.numberOfOriginal === null ||
      shippingInfo.payableAt === "" ||
      shippingInfo.onBoardDate === "";

    if (!emptyField) {
      setUserInputError((prev) => {
        return { ...prev, emtpyError: false };
      });
    }
  }, [shippingInfo]);

  const handleItemChange = (fieldName) => (e) => {
    let value = e.target.value;
    setShippingInfo(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };

  const handleItemChangeByValue = (fieldName) => (value) => {
    setShippingInfo(
        produce((draft) => {
          draft[fieldName] = value;
        })
    );
  }

  const clearData = () => {
    setShippingInfo(defaultBolData);
    updateItemList([]);
  };

  const handleNext = () => {
    // if (activeStep === 0) next(false);
    // if (activeStep === 1) {
    //   if (shippingItems.length !== 0) {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     handleSeventhPage();
    //   } else setEmtpyItemList(true);
    // }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container maxWidth="md">
      {activeStep === 0 && (
        <HscodeAddressInput
          shippingInfo={shippingInfo}
          userInputError={userInputError}
          handleItemChange={handleItemChange}
          handleItemChangeByValue={handleItemChangeByValue}
        />
      )}

      {activeStep === 1 && (
        <HscodeItems itemList={itemList} updateItemList={updateItemList} />
      )}

      {activeStep === 2 &&
          <BolDetails
            shippingInfo={shippingInfo}
            itemList={itemList}
            setActiveStep={setActiveStep}
            success={success}
            setSuccessState={setSuccessState}
            clearData={clearData}
            edit={edit}
            refNo={bolNumber || ""}
          />
      }

      {!success && (
        <Box className={classes.stepper} mt={4}>
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === 2}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </Box>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    padding: theme.spacing(2, 4, 3),
    overflow: "hidden",
  },
  input: {
    color: "grey",
    width: "90%",
  },
  label: {
    flexDirection: "column",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
  },
  backButton: {
    marginRight: theme.spacing(4),
    backgroundColor: "white",
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
}));

HscodePage.propTypes = {};

export default HscodePage;
