import React, {useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {orange, red, green} from "@mui/material/colors";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import {useSelector} from "react-redux";
import axios from "axios";
import {PARTNER_URI} from "../../../utils/apiUrl";

const theme = createTheme({
    palette: {
        primary: orange,
        danger: red,
        success: green,
        warning: red,
        secondary: green,
    },
});

const MyBoxesListItem = ({
                             boxId,
                             index,
                             boxName,
                             partnerId,
                             ownerRole,
                             itemName,
                             shipmentTypeId,
                             weightUnit,
                             dimensionUnit,
                             length,
                             width,
                             height,
                             weight,
                             createdAt,
                             updatedAt,
                             setShippingItem,
                             handleClose,
                             changeBoxByIndex,
                             matches,
                             hscode,
                             priceEach,
                             UOM,
                             currency,
                             originCountryCode
                         }) => {
    const [canEdit, setCanEdit] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [requiredErrors, setRequiredErrors] = useState({});
    const [isDeleted, setIsDeleted] = useState(false);
    const [canClick, setCanClick] = useState(false);

    const [newBoxName, setNewBoxName] = useState(boxName);
    const [newItemName, setNewItemName] = useState(itemName);
    const [newWeightUnit, setNewWeightUnit] = useState(weightUnit);
    const [newDimensionUnit, setNewDimensionUnit] = useState(dimensionUnit);
    const [newLength, setNewLength] = useState(length);
    const [newWidth, setNewWidth] = useState(width);
    const [newHeight, setNewHeight] = useState(height);
    const [newWeight, setNewWeight] = useState(weight);

    const id = useSelector((state) => state.user.id);

    // Fills in textbox form
    const addItem = () => {
        clearStatusMessages();
        setShippingItem((prev) => ({
            ...prev,
            description: itemName,
            // looseUnit: looseUnit,
            length: length,
            width: width,
            height: height,
            lengthUnit: dimensionUnit,
            weight: weight,
            weightUnit: weightUnit,
            priceEach: priceEach,
            hscode: hscode,
            UOM: UOM,
            originCountryCode: originCountryCode,
            currency: currency
        }));
        handleClose();
    };

    const cancelEdit = () => {
        clearStatusMessages();
        setCanEdit(false);

        setNewBoxName(boxName);
        setNewItemName(itemName);
        setNewWeightUnit(weightUnit);
        setNewDimensionUnit(dimensionUnit);
        setNewLength(length);
        setNewWidth(width);
        setNewHeight(height);
        setNewWeight(weight);
    };

    const saveChanges = async () => {
        try {
            console.log({
                newBoxName,
                newItemName,
                newWeightUnit,
                newDimensionUnit,
                newLength,
                newWidth,
                newHeight,
                newWeight,
            });

            const formErrors = {
                newBoxName: newBoxName.trim() === "",
                newWeightUnit: newWeightUnit.trim() === "",
                newDimensionUnit: newDimensionUnit.trim() === "",
                newLength:
                    isNaN(newLength) ||
                    (typeof newLength === "string" ? newLength.trim() === "" : false),
                newWidth:
                    isNaN(newWidth) ||
                    (typeof newWidth === "string" ? newWidth.trim() === "" : false),
                newHeight:
                    isNaN(newHeight) ||
                    (typeof newHeight === "string" ? newHeight.trim() === "" : false),
                newWeight:
                    isNaN(newWeight) ||
                    (typeof newWeight === "string" ? newWeight.trim() === "" : false),
            };
            setRequiredErrors(formErrors);
            console.log(formErrors);

            if (
                newBoxName.trim() === "" ||
                newWeightUnit.trim() === "" ||
                newDimensionUnit.trim() === "" ||
                isNaN(newLength) ||
                (typeof newLength === "string" ? newLength.trim() === "" : false) ||
                isNaN(newWidth) ||
                (typeof newWidth === "string" ? newWidth.trim() === "" : false) ||
                isNaN(newHeight) ||
                (typeof newHeight === "string" ? newHeight.trim() === "" : false) ||
                isNaN(newWeight) ||
                (typeof newWeight === "string" ? newWeight.trim() === "" : false)
            ) {
                // Some fields empty
                setErrorMessage("Missing Required Fields");
                return;
            }

            setIsLoading(true);
            clearStatusMessages();
            const body = {
                boxId: boxId,
                partnerId: id,
                boxName: newBoxName,
                itemName: newItemName,
                weightUnit: newWeightUnit,
                dimensionUnit: newDimensionUnit,
                length: newLength,
                width: newWidth,
                height: newHeight,
                weight: newWeight,
            };

            const {data, status} = await axios({
                method: "put",
                url: `${PARTNER_URI}/myboxes/updateMyBoxById`,
                data: body,
            });
            if (status === 200) {
                const newBox = {
                    box_name: newBoxName,
                    item_name: newItemName,
                    weight_unit: newWeightUnit,
                    dimension_unit: newDimensionUnit,
                    length: newLength,
                    width: newWidth,
                    height: newHeight,
                    weight: newWeight,
                };
                changeBoxByIndex(index, newBox);
                setStatusMessage("Successfully saved box");
                setCanEdit(false);
            } else {
                console.log("Error:", data);
                throw new Error();
            }
        } catch (e) {
            console.log(e);
            setErrorMessage("Failed to Save Box");
        } finally {
            setIsLoading(false);
        }
    };

    const clearStatusMessages = () => {
        setStatusMessage("");
        setErrorMessage("");
    };

    const deleteBox = async () => {
        try {
            setIsLoadingDelete(true);
            const body = {
                boxId: boxId,
            };
            const {data, status} = await axios({
                method: "delete",
                url: `${PARTNER_URI}/myboxes/deleteMyBoxById`,
                data: body,
            });
            if (status === 200) {
                setIsDeleted(true);
            } else {
                throw new Error();
            }
        } catch (e) {
            console.log(e);
            setErrorMessage("Failed to Delete Box");
        } finally {
            setIsLoadingDelete(false);
        }
    };

    // var canClick = false; // Initial Mouse over reveals buttons
    const mouseOverBox = () => {
        if (!canClick) {
            setCanClick(true);
        }
    };

    const mouseOutBox = () => {
        setCanClick(false);
    };

    const boxClick = () => {
        if (canClick) {
            addItem();
        } else {
            setCanClick(true);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <ListItem onMouseOver={mouseOverBox} onMouseLeave={mouseOutBox}>
                {isDeleted ? (
                    <Typography
                        sx={{
                            color: "success.main",
                        }}
                    >
                        Box Successfully Deleted
                    </Typography>
                ) : (
                    <Box width="100%">
                        <Typography
                            sx={{
                                color: "green",
                            }}
                        >
                            {statusMessage}
                        </Typography>
                        <Typography
                            sx={{
                                color: "red",
                            }}
                        >
                            {errorMessage}
                        </Typography>
                        <Grid container>
                            {!canEdit ? (
                                <ListItemButton onClick={boxClick}>
                                    <Grid item xs={10}>
                                        <Box>
                                            <Box mb={2}>
                                                <Typography variant="h6">{boxName}</Typography>
                                                <Typography variant="p" sx={{color: "grey"}}>
                                                    {createdAt === updatedAt ? (
                                                        <>Created on: {createdAt}</>
                                                    ) : (
                                                        <>Updated on: {updatedAt}</>
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Typography>Item Name: {itemName}</Typography>
                                            <Typography>
                                                Dimensions: {length} {dimensionUnit} (length) x {width}{" "}
                                                {dimensionUnit} (width) x {height} {dimensionUnit}{" "}
                                                (height)
                                            </Typography>
                                            <Typography>
                                                Weight: {weight} {weightUnit}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </ListItemButton>
                            ) : (
                                <Grid item xs={!canEdit ? 10 : 12}>
                                    <Box>
                                        <TextField
                                            label="My Box Name"
                                            variant="filled"
                                            defaultValue={newBoxName}
                                            onChange={(e) => setNewBoxName(e.target.value)}
                                            fullWidth
                                            sx={{mb: 2}}
                                            error={requiredErrors.newBoxName}
                                            helperText={
                                                requiredErrors.newBoxName ? "Required" : undefined
                                            }
                                        />
                                        <TextField
                                            label="Item Name (Optional)"
                                            variant="filled"
                                            defaultValue={newItemName}
                                            onChange={(e) => setNewItemName(e.target.value)}
                                            fullWidth
                                            sx={{mb: 2}}
                                        />
                                        <Grid container width="100%">
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Length"
                                                    variant="filled"
                                                    defaultValue={newLength}
                                                    onChange={(e) => setNewLength(e.target.value)}
                                                    sx={{width: "100%"}}
                                                    error={isNaN(newLength) || requiredErrors.newLength}
                                                    helperText={
                                                        isNaN(newLength) ? "Requires Number" : (requiredErrors.newLength ? "Required" : undefined)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Width"
                                                    variant="filled"
                                                    defaultValue={newWidth}
                                                    onChange={(e) => setNewWidth(e.target.value)}
                                                    // fullWidth
                                                    sx={{
                                                        width: "100%",
                                                    }}
                                                    error={isNaN(newWidth) || requiredErrors.newWidth}
                                                    helperText={
                                                        isNaN(newWidth) ? "Requires Number" : (requiredErrors.newWidth ? "Required" : undefined)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Height"
                                                    variant="filled"
                                                    defaultValue={newHeight}
                                                    onChange={(e) => setNewHeight(e.target.value)}
                                                    // fullWidth
                                                    sx={{width: "100%"}}
                                                    error={isNaN(newHeight) || requiredErrors.newHeight}
                                                    helperText={
                                                        isNaN(newHeight) ? "Requires Number" : (requiredErrors.newHeight ? "Required" : undefined)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} pl={8}>
                                                <FormControl
                                                    height="100%"
                                                    error={requiredErrors.newDimensionUnit}
                                                >
                                                    <FormLabel>Dimension Unit</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        defaultValue={dimensionUnit || ""}
                                                        onChange={(e) =>
                                                            setNewDimensionUnit(e.target.value)
                                                        }
                                                    >
                                                        <FormControlLabel
                                                            value="cm"
                                                            control={<Radio/>}
                                                            label="cm"
                                                        />
                                                        <FormControlLabel
                                                            value="in"
                                                            control={<Radio/>}
                                                            label="in"
                                                        />
                                                        <FormHelperText>
                                                            {requiredErrors.newDimensionUnit
                                                                ? "Required"
                                                                : ""}
                                                        </FormHelperText>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Weight"
                                                    variant="filled"
                                                    defaultValue={newWeight}
                                                    onChange={(e) => setNewWeight(e.target.value)}
                                                    // fullWidth
                                                    sx={{
                                                        width: "100%",
                                                    }}
                                                    error={isNaN(newWeight) || requiredErrors.newWeight}
                                                    helperText={
                                                        isNaN(newWeight) ? "Requires Number" : (requiredErrors.newWeight ? "Required" : undefined)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} m="auto" pl={8}>
                                                <FormControl
                                                    height="100%"
                                                    error={requiredErrors.newWeightUnit}
                                                >
                                                    <FormLabel>Weight Unit</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        defaultValue={weightUnit || ""}
                                                        onChange={(e) => setNewWeightUnit(e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value="kg"
                                                            control={<Radio/>}
                                                            label="kg"
                                                        />
                                                        <FormControlLabel
                                                            value="lb"
                                                            control={<Radio/>}
                                                            label="lb"
                                                        />
                                                        <FormHelperText>
                                                            {requiredErrors.newWeightUnit ? "Required" : ""}
                                                        </FormHelperText>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            )}
                            {/* Buttons */}
                            {!canEdit ? (
                                <Grid className="btnContainer" item xs={matches ? 12 : 2}>
                                    {canClick ? (
                                        <Box
                                            width="100%"
                                            height="100%"
                                            display="flex"
                                            flexDirection={matches ? "row" : "column"}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <>
                                                <ListItemButton
                                                    sx={{color: "primary.main"}}
                                                    onClick={addItem}
                                                >
                                                    Use it
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{color: "primary.main"}}
                                                    onClick={() => setCanEdit(true)}
                                                >
                                                    Edit
                                                </ListItemButton>
                                            </>
                                        </Box>
                                    ) : undefined}
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={4}>
                                        <Box
                                            width="100%"
                                            height="100%"
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <ListItemButton
                                                sx={{color: "error.main"}}
                                                onClick={cancelEdit}
                                            >
                                                Cancel
                                            </ListItemButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box
                                            width="100%"
                                            height="100%"
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <ListItemButton
                                                sx={{color: "success.main"}}
                                                onClick={saveChanges}
                                            >
                                                {isLoading ? <CircularProgress/> : <>Save</>}
                                            </ListItemButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box
                                            width="100%"
                                            height="100%"
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <ListItemButton
                                                width="100%"
                                                sx={{color: "warning.main"}}
                                                onClick={deleteBox}
                                            >
                                                {isLoadingDelete ? <CircularProgress/> : <>Delete</>}
                                            </ListItemButton>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                )}
            </ListItem>
            <Divider/>
        </ThemeProvider>
    );
};

export default MyBoxesListItem;
