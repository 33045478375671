import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Box,
    Container,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
import PropTypes from "prop-types";
import LooseItemServices from "./components/looseItemServices";
import axios from "axios";
import getProvinceCodeByName, {getUSProvinceCodeByName} from "../../utils/getProvinceCode";
import loadingContainer from "../../shared/loadingContainer";
import LoadingContainer from "../../shared/loadingContainer";
import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import {Alert} from "@material-ui/lab";
import {useSelector} from "react-redux";
import {getTransportationCode} from "../../shared/constInfo";

const LooseItemRates = ({
                            setCompanyName,
                            setServiceName,
                            toSeventh,
                            formatData,
                            deliverLocation,
                            userLocation,
                            shippingItems,
                            accountBaseCombinedName,
                            setPriceText,
                            setFormatData,
                            setAccountBaseCombinedName,
                            setServiceType,
                            setPriceInfo
                        }) => {
    const CURRENT_COMPANY = "UUCARGO";
    const {id: partnerId} = useSelector((state) => state.user);

    const [serviceData, setServiceData] = useState({
        info: []
    });

    const [errorMessage, setErrorMessage] = useState("");

    const classes = useStyles();

    const setAndLoadNext = () => {
        setCompanyName(CURRENT_COMPANY);
        toSeventh();
    };
    const [isLoading, setIsLoading] = useState(false);

    const [isFedExLoading, setIsFedExLoading] = useState(true);
    const [isUPSLoading, setIsUPSLoading] = useState(true);
    const [isCanparLoading, setIsCanparLoading] = useState(true);
    const [isCanadaPostLoading, setIsCanadaPostLoading] = useState(true);

    const getAvailableService = async ({rateData, selectedAccountBase}) => {
        const storedToken = getAccessToken("access_token");
        const {data} = await axios.post(`${PARTNER_URI}/loose-item/3rd-party/partner/rates/all`, {
            accountBaseCombinedName,
            rateData,
            partnerId,
            selectedAccountBase
        }, {
            headers: {Authorization: `Bearer ${storedToken}`},
        });
        return data.result;
    }

    const getRatesByAccountBase = (selectedAccountBase) => {
        try {
            switch (selectedAccountBase) {
                case "ups":
                    setIsUPSLoading(true);
                    break;
                case "fedex":
                    setIsFedExLoading(true);
                    break;
                case "canpar":
                    setIsCanparLoading(true);
                    break;
                case "canadapost":
                    setIsCanadaPostLoading(true)
            }
            const requestBody = {
                rateData: {
                    shipFromAddress: userLocation.address,
                    shipFromAddressTwo: userLocation?.aptInfo || "",
                    shipFromCity: userLocation.city,
                    shipFromProvince: userLocation.country === "CA" ? getProvinceCodeByName(userLocation.province) : getUSProvinceCodeByName(userLocation.province),
                    shipFromPostalCode: userLocation.zipCode,
                    shipFromCountry: userLocation.country?.trim().toLowerCase() === "canada" ? "CA" : userLocation.country,
                    shipFromName: userLocation.name,
                    shipFromPhone: userLocation.phone,
                    shipToAddress: deliverLocation.address,
                    shipToAddressTwo: deliverLocation.aptInfo,
                    shipToCity: deliverLocation.city,
                    shipToPostalCode: deliverLocation.zipCode,
                    shipToProvince: deliverLocation.country === "CA" ? getProvinceCodeByName(deliverLocation.province) : getUSProvinceCodeByName(deliverLocation.province),
                    shipToCountry: deliverLocation.country?.trim().toLowerCase() === "canada" ? "CA" : deliverLocation.country,
                    shipToName: deliverLocation.name,
                    shipToPhone: deliverLocation.phone,
                    shipmentType: formatData.shipmentType,
                    transMode: getTransportationCode(formatData.transMode),
                    packageData: shippingItems.map(v => {
                        return {
                            description: v.description,
                            length: v.length,
                            height: v.height,
                            width: v.width,
                            quantity: v.looseUnit,
                            weight: v.weight,
                            lengthUnit: v.lengthUnit,
                            weightUnit: v.weightUnit,
                            hscode: v.hscode,
                            originCountryCode: v.originCountryCode,
                            currency: v.currency,
                            priceEach: v.priceEach,
                            UOM: v.UOM
                        }
                    })
                }
            };
            (async () => {
                try {
                    // setIsLoading(true);
                    setErrorMessage("");
                    const result = await getAvailableService({rateData: requestBody.rateData, selectedAccountBase});
                    console.log("Available Services Result:", result);
                    if (!result || result?.length === 0) {
                        setErrorMessage("Oops, We can not process your request, please double check the address and shipping item.")
                    } else {
                        setServiceData(prev => {
                            const prevInfo = [...prev.info];
                            const resultMapping = result?.map(v => {
                                const serviceFee = +v?.serviceFee?.value || 0;
                                const serviceFeeTax = +v?.serviceFeeTax?.value || 0;
                                // const shippingFee = +(v?.totalChargeWithTaxes?.value ? v?.totalChargeWithTaxes?.value : v?.totalCharge?.value);
                                const shippingFee = v?.totalCharge?.value ? +(+v?.totalCharge?.value).toFixed(2) : 0;
                                const crossBorderTotal = v?.crossBroaderFee?.grandTotal || 0;
                                return {
                                    companyName: v?.accountBase || "N/A",
                                    service: v?.serviceName || "N/A",
                                    serviceFee: (+serviceFee + +serviceFeeTax) ? +(+serviceFee + +serviceFeeTax).toFixed(2) : 0,
                                    shippingFee: shippingFee,
                                    charge: (shippingFee + serviceFee + crossBorderTotal).toFixed(2) || "",
                                    message: v?.errorMessage || "",
                                    accountBaseCombineName: v?.accountBaseCombineName || "",
                                    serviceType: v?.serviceType || "standard",
                                    shipmentCharges: v?.shipmentCharges,
                                    crossBoarder: {
                                        totalBrokerageFees: v?.crossBroaderFee?.totalBrokerageFees || 0,
                                        totalDutyandTax: v?.crossBroaderFee?.totalDutyandTax || 0,
                                        grandTotal: v?.crossBroaderFee?.grandTotal || 0,
                                        error: v?.crossBroaderFee?.error || ""
                                    },
                                    totalChargeWithTaxes: v?.totalChargeWithTaxes,
                                    totalCharge: v?.totalCharge,
                                    taxes: v?.taxes,
                                    expectedDate: v?.expectedDate
                                }
                            });
                            prevInfo.push(...resultMapping);
                            const sortInfo = prevInfo.sort((a, b) => {
                                // if (a?.charge && b?.charge) {
                                //   return a?.charge - b?.charge;
                                // }
                                // return 1;
                                if (!isFinite(a?.charge) && !isFinite(b?.charge)) {
                                    return 0;
                                }
                                if (!isFinite(a?.charge)) {
                                    return 1;
                                }
                                if (!isFinite(b?.charge)) {
                                    return -1;
                                }
                                return a?.charge - b?.charge;
                            });
                            return {
                                info: sortInfo
                            }
                            // return {
                            //   info: result?.map(v => {
                            //     const serviceFee = +v?.serviceFee?.value || 0;
                            //     const serviceFeeTax = +v?.serviceFeeTax?.value || 0;
                            //     // const shippingFee = +(v?.totalChargeWithTaxes?.value ? v?.totalChargeWithTaxes?.value : v?.totalCharge?.value);
                            //     const shippingFee = +v?.totalCharge?.value;
                            //     const crossBorderTotal = v?.crossBroaderFee?.grandTotal || 0;
                            //     return {
                            //       companyName: v?.accountBase || "N/A",
                            //       service: v?.serviceName || "N/A",
                            //       serviceFee: +serviceFee + +serviceFeeTax,
                            //       shippingFee: shippingFee,
                            //       charge: (shippingFee + serviceFee + crossBorderTotal).toFixed(2) || "",
                            //       message: v?.errorMessage || "",
                            //       accountBaseCombineName: v?.accountBaseCombineName || "",
                            //       serviceType: v?.serviceType || "standard",
                            //       shipmentCharges: v?.shipmentCharges,
                            //       crossBoarder: {
                            //         totalBrokerageFees: v?.crossBroaderFee?.totalBrokerageFees || 0,
                            //         totalDutyandTax: v?.crossBroaderFee?.totalDutyandTax || 0,
                            //         grandTotal: v?.crossBroaderFee?.grandTotal || 0,
                            //         error: v?.crossBroaderFee?.error || ""
                            //       },
                            //       totalChargeWithTaxes: v?.totalChargeWithTaxes,
                            //       totalCharge: v?.totalCharge,
                            //       taxes: v?.taxes,
                            //       expectedDate: v?.expectedDate
                            //     }
                            //   })
                            // }
                        });

                        setIsLoading(false);
                    }
                } catch (e) {
                    // setServiceData({
                    //   info: []
                    // })
                    console.log("getRatesByAccountBase: ", e);
                    setErrorMessage(e.response.data?.error || "Oops, We can not process your request, please double check the address and shipping item.")
                } finally {
                    // setIsLoading(false);
                    // setErrorMessage("");
                    switch (selectedAccountBase) {
                        case "ups":
                            setIsUPSLoading(false);
                            break;
                        case "fedex":
                            setIsFedExLoading(false);
                            break;
                        case "canpar":
                            setIsCanparLoading(false);
                            break;
                        case "canadapost":
                            setIsCanadaPostLoading(false)
                    }
                }
            })();
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        getRatesByAccountBase("ups");
        getRatesByAccountBase("fedex");
        getRatesByAccountBase("canpar");
        getRatesByAccountBase("canadapost");
    }, [])

    useEffect(() => {
        if (!isUPSLoading && !isFedExLoading && !isCanadaPostLoading && !isCanparLoading) {
            setIsLoading(false);
            if (serviceData.info.length > 0) {
                setErrorMessage("");
            }
        }
    }, [isUPSLoading, isFedExLoading, isCanadaPostLoading, isCanparLoading, serviceData])

    // useEffect(() => {
    //   try {
    //     console.log("User Location", userLocation);
    //     console.log("Shipping Item:", shippingItems);
    //     console.log("Account Based Combined Name:", accountBaseCombinedName);
    //     // The semicolon in this request body is required, otherwise page would crash
    //
    //     const requestBody = {
    //       rateData: {
    //         shipFromAddress: userLocation.address,
    //         shipFromAddressTwo: userLocation?.aptInfo || "",
    //         shipFromCity: userLocation.city,
    //         shipFromProvince: userLocation.country === "CA" ? getProvinceCodeByName(userLocation.province) : getUSProvinceCodeByName(userLocation.province),
    //         shipFromPostalCode: userLocation.zipCode,
    //         shipFromCountry: userLocation.country?.trim().toLowerCase() === "canada" ? "CA" : userLocation.country,
    //         shipFromName: userLocation.name,
    //         shipFromPhone: userLocation.phone,
    //         shipToAddress: deliverLocation.address,
    //         shipToAddressTwo: deliverLocation.aptInfo,
    //         shipToCity: deliverLocation.city,
    //         shipToPostalCode: deliverLocation.zipCode,
    //         shipToProvince: deliverLocation.country === "CA" ? getProvinceCodeByName(deliverLocation.province) : getUSProvinceCodeByName(deliverLocation.province),
    //         shipToCountry: deliverLocation.country?.trim().toLowerCase() === "canada" ? "CA" : deliverLocation.country,
    //         shipToName: deliverLocation.name,
    //         shipToPhone: deliverLocation.phone,
    //         shipmentType: formatData.shipmentType,
    //         transMode: getTransportationCode(formatData.transMode),
    //         packageData: shippingItems.map(v => {
    //           return {
    //             description: v.description,
    //             length: v.length,
    //             height: v.height,
    //             width: v.width,
    //             quantity: v.looseUnit,
    //             weight: v.weight,
    //             lengthUnit: v.lengthUnit,
    //             weightUnit: v.weightUnit,
    //             hscode: v.hscode,
    //             originCountryCode: v.originCountryCode,
    //             currency: v.currency,
    //             priceEach: v.priceEach,
    //             UOM: v.UOM
    //           }
    //         })
    //       }
    //     };
    //     console.log(requestBody);
    //     (async () => {
    //       try {
    //         setIsLoading(true);
    //         setErrorMessage("");
    //         const result = await getAvailableService(requestBody);
    //         console.log("Available Services Result:", result);
    //         if (!result || result?.length === 0) {
    //           setErrorMessage("Oops, We can not process your request, please double check the address and shipping item.")
    //         } else {
    //           setServiceData({
    //             info: result?.map(v => {
    //               const serviceFee = +v?.serviceFee?.value || 0;
    //               const serviceFeeTax = +v?.serviceFeeTax?.value || 0;
    //               // const shippingFee = +(v?.totalChargeWithTaxes?.value ? v?.totalChargeWithTaxes?.value : v?.totalCharge?.value);
    //               const shippingFee = +v?.totalCharge?.value;
    //               const crossBorderTotal = v?.crossBroaderFee?.grandTotal || 0;
    //               return {
    //                 companyName: v?.accountBase || "N/A",
    //                 service: v?.serviceName || "N/A",
    //                 serviceFee: +serviceFee + +serviceFeeTax,
    //                 shippingFee: shippingFee,
    //                 charge: (shippingFee + serviceFee + crossBorderTotal).toFixed(2) || "",
    //                 message: v?.errorMessage || "",
    //                 accountBaseCombineName: v?.accountBaseCombineName || "",
    //                 serviceType: v?.serviceType || "standard",
    //                 shipmentCharges: v?.shipmentCharges,
    //                 crossBoarder: {
    //                   totalBrokerageFees: v?.crossBroaderFee?.totalBrokerageFees || 0,
    //                   totalDutyandTax: v?.crossBroaderFee?.totalDutyandTax || 0,
    //                   grandTotal: v?.crossBroaderFee?.grandTotal || 0,
    //                   error: v?.crossBroaderFee?.error || ""
    //                 },
    //                 totalChargeWithTaxes: v?.totalChargeWithTaxes,
    //                 totalCharge: v?.totalCharge,
    //                 taxes: v?.taxes,
    //                 expectedDate: v?.expectedDate
    //               }
    //             }).sort((a, b) => {
    //               return +a?.charge - +b?.charge
    //               // return +b?.charge - +a?.charge;
    //             })
    //           });
    //         }
    //       } catch (e) {
    //         setServiceData({
    //           info: []
    //         })
    //         setErrorMessage(e.response.data?.error || "Oops, We can not process your request, please double check the address and shipping item.")
    //         console.log(e);
    //       } finally {
    //         setIsLoading(false);
    //       }
    //     })();
    //   } catch (e) {
    //     console.log(e);
    //   }
    //
    // }, [deliverLocation, shippingItems, userLocation, accountBaseCombinedName])

    if (isLoading) {
        return (
            <Container className={classes.root}>
                <Box className={classes.title} mb={2}>
                    SHIPPING SERVICE
                </Box>
                <Box display="flex" alignItems="center">
                    <TableContainer>
                        <LoadingContainer/>
                    </TableContainer>
                </Box>
            </Container>
        )
    }

    return (
        <Container className={classes.root}>
            <Box className={classes.title} mb={2}>
                SHIPPING SERVICE
            </Box>
            <Box display={"flex"} alignItems={"center"} flexDirection="column" width="100%">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/*<TableCell align="left"></TableCell>*/}
                                {/*<TableCell align="center" className={classes.smSubTitle}>*/}
                                {/*  Carrier*/}
                                {/*</TableCell>*/}
                                <TableCell align="center" className={classes.smSubTitle}>
                                    Shipping Service
                                </TableCell>
                                <TableCell align="center" className={classes.smSubTitle}>
                                    Estimate Shipping Fee
                                </TableCell>
                                <TableCell align="center" className={classes.smSubTitle}>
                                    Expected Delivered Date
                                </TableCell>
                                <TableCell align="center" className={classes.smSubTitle}>
                                    Message
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {serviceData.info.map((info, index) => {
                            return (
                                <LooseItemServices
                                    setCompanyName={setCompanyName}
                                    setServiceName={setServiceName}
                                    toSeventh={toSeventh}
                                    companyName={info?.companyName || "N/A"}
                                    shippingService={info?.service || "N/A"}
                                    chargeAmount={info?.charge ?? "0"}
                                    setPriceText={setPriceText}
                                    crossBoarderTotal={info?.crossBoarder.grandTotal}
                                    key={index}
                                    message={info?.message || info?.crossBoarder?.error || ""}
                                    setFormatData={setFormatData}
                                    setAccountBaseCombinedName={setAccountBaseCombinedName}
                                    info={info}
                                    serviceType={info.serviceType}
                                    setServiceType={setServiceType}
                                    setPriceInfo={setPriceInfo}
                                    shipmentCharges={info.shipmentCharges}
                                    serviceFee={info.serviceFee}
                                    expectedDate={info.expectedDate}
                                />
                            );
                        })}
                    </Table>
                </TableContainer>
                {
                    errorMessage && (
                        <Box display="flex" width="100%" justifyContent="center" alignItems="center" alignContent="center">
                            <Alert severity="error">Error! {errorMessage}</Alert>
                        </Box>
                    )
                }
            </Box>
        </Container>
    );
};

const useStyles = makeStyles({
    root: {
        backgroundColor: "white",
        width: "100%",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        color: "orange",
        mb: "1vh",
        fontSize: "x-large"
    },
    input: {
        color: "grey",
        width: "90%",
    },
    smSubTitle: {
        color: "darkgreen",
        fontWeight: 600,
        fontSize: "1rem",
        fontFamily: "Montserrat",
    },
});

LooseItemRates.propTypes = {
    toSeventh: PropTypes.func.isRequired,
};

export default LooseItemRates;
