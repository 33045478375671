import React, {useEffect, useRef} from "react";
import { makeStyles } from "@material-ui/core";
import HSCodeSectionListItem from "./HSCodeSectionListItem";
import HSCodeLevelSixItem from "./HSCodeLevelSixItem";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "400px",
  },
  idTH: {
    width: "100px",
  },
}));

export default function HSCodeLevelSixList({ data }) {
  const classes = useStyles();
  const { id } = useSelector((state) => state.user);

  useEffect(() => {
    console.log("Level Six Data", data);
  }, [data])

  return (
      <div className={classes.hscodeListWrapper}>
          <table className={classes.table}>
            <thead>
            <tr>
              <th className={classes.idTH}>HS Code</th>
              <th className={classes.nameTH}>Name</th>
              <th>Simplified Chinese</th>
              <th>Traditional Chinese</th>
              <th>Key words</th>
            </tr>
            </thead>
            <tbody className={classes.tbody}>
            {
              data.map((item) => {
                return <HSCodeLevelSixItem desc={item.desc} desc_cn_big={item.desc_cn_big} desc_cn_gb={item.desc_cn_gb} tags={item.partner_tags} hscode={item.hscode} key={item.id} partnerId={id} section={item.section} level={item.level} parent={item.parent} />
              })
            }
            </tbody>
          </table>
      </div>
  );
}
