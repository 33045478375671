import Grid from "@material-ui/core/Grid";
import {Fragment, useState} from "react";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {Divider} from "@mui/material";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import {movingOrderStatus, movingShippingStatus} from "../../utils/Helper";

export const TransportationClaimTasksEach = ({taskDetails, status}) => {

    const history = useHistory();

    const [view, setView] = useState(false);

    const handleViewOpen = () => {
        setView(true);
    }

    const handleViewClose = () => {
        setView(false);
    }

    const handleViewDetails = (id) => {
        history.push(`/transportation/claimTasks/details/${id}`);
    }

    console.log('[TransportationClaimTasksEach] taskDetails', taskDetails);

    return (
        <Fragment>
            {
                (taskDetails?.task_status_id === -1) ?
                    <>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {taskDetails?.order_number}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {taskDetails?.pickup_address}, {taskDetails?.pickup_area}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {taskDetails?.deliver_address}, {taskDetails?.deliver_area}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {taskDetails?.task_pickedup_at ? dayjs(taskDetails?.task_pickedup_at).format("YYYY-MM-DD") : 'To be determined'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    $ {taskDetails?.price?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                {
                                    taskDetails?.task_remark_notes ?
                                        <Button
                                            size='small'
                                            onClick={view ? handleViewClose : handleViewOpen}
                                        >
                                            <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                                {view ? 'Close' : 'View'}
                                            </Typography>
                                        </Button> : <Typography style={{fontSize: '14px'}}>N/A</Typography>
                                }
                            </Box>
                        </Grid>
                        {
                            view &&
                            <Grid item xs={12}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    Remark Notes: {taskDetails?.task_remark_notes}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {taskDetails?.pickup_address}, {taskDetails?.pickup_area}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {taskDetails?.deliver_address}, {taskDetails?.deliver_area}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {taskDetails?.schedule_pickup ? dayjs(taskDetails?.schedule_pickup).format("YYYY-MM-DD") : 'To be determined'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    $ {taskDetails?.price?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                flexDirection: 'column'
                            }}>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    color: taskDetails?.status_id === 100 ? '#F2BE22' :
                                        taskDetails?.status_id === 1 ? '#1D8B45' : '#454545'
                                }}>
                                    {movingOrderStatus(taskDetails?.status_id)}
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    color: taskDetails?.shipping_status_id === 2 ? '#FB8B24' :
                                        taskDetails?.shipping_status_id === 20 ? '#F2BE22' :
                                            taskDetails?.shipping_status_id === 25 ? '#1D8B45' : '#454545'
                                }}>
                                    {movingShippingStatus(taskDetails?.shipping_status_id)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }}>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => handleViewDetails(taskDetails?.order_id)}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        color: '#1D8B45',
                                        fontSize: '14px'
                                    }}>
                                        Details
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </>
            }
        </Fragment>
    )
}