import {actionType} from "../../utils/Helper";

const initialState = {
    latestOrderOpenIndex: null

}

export const landingPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX:
            console.log('open index', action?.payload)
            return {...state, latestOrderOpenIndex: action?.payload}
        default:
            return state
    }
}