import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {getAccessToken} from "../../../utils/doToken";
import {LABEL_DOWNLOAD_URL_PREFIX, LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE, PARTNER_URI} from "../../../utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {triggerBase64Download} from "common-base64-downloader-react";
import {Link} from "@mui/material";
import {saveAs} from 'file-saver';
import intl from "react-intl-universal";

const style = {
    LTLOrderDashboardDocumentsRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const LTLOrderDashboardDocuments = ({orderData}) => {

    const [invoiceDetail, setInvoiceDetail] = useState(null);
    const [invoiceLoading, setInvoiceLoading] = useState(true);

    const getInvoiceNumber = async () => {
        setInvoiceLoading(true);
        const storedToken = getAccessToken("access_token");
        const requestURI = `${PARTNER_URI}/billingCenter/getInvoiceInfoByInvoiceId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderData?.crm_invoice_no
                }
            })
            console.log('result', result);
            setInvoiceDetail(result?.data);
        } catch (e) {
            console.log('e', e?.response);
        } finally {
            setInvoiceLoading(false);
        }
    }

    const downloadPDFByInvoiceId = async () => {
        try {
            const storedToken = getAccessToken("access_token");
            const requestURL = `${PARTNER_URI}/billingCenter/downloadInvoice`;
            const {data} = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderData?.crm_invoice_no
                }
            })
            const content = data.content;
            const contentType = data["content-type"];
            const pdfContent = `data:${contentType};base64,${content}`;
            const fileName = data.filename?.length > 4 ? data.filename.slice(0, data.filename.length - 4) : data.filename;
            triggerBase64Download(pdfContent, fileName);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleDownInvoice = () => {
        downloadPDFByInvoiceId();
    }

    const handleDownBolLabel = () => {
        const fileURL = `${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${orderData?.bol_doc_uri}`;
        // const fileURL = 'https://storage.googleapis.com/uucargo-resource/tmp/upload/shipping/1714-shipping-package-list-sample_13_.xlsx';
        saveAs(fileURL, 'downloaded-file.pdf');
    }

    const handleDownPODLabel = () => {
        const fileURL = `${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${orderData?.pod_doc_uri}`;
        // const fileURL = 'https://storage.googleapis.com/uucargo-resource/tmp/upload/shipping/1714-shipping-package-list-sample_13_.xlsx';
        saveAs(fileURL, 'downloaded-pod-file.pdf');
    }

    useEffect(() => {
        orderData?.crm_invoice_no ? getInvoiceNumber() : setInvoiceLoading(false);
    }, [orderData]);

    if (invoiceLoading) {
        return (
            <Box sx={style.PackageOrderDashboardDocumentsRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '10px',
                            borderBottom: '1px solid #B2B2B2'
                        }}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.SHIPPING_DOCUMENTS')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box sx={style.LTLOrderDashboardDocumentsRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.SHIPPING_DOCUMENTS')}
                        </Typography>
                    </Box>
                </Grid>
                {
                    ((+orderData?.status_id) === 1 && orderData?.bol_doc_uri && orderData?.bol_doc_uri !== 'null') &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px',
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.BOL_NO')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                textTransform: 'none',
                                fontSize: '14px',
                                color: '#B454545',
                            }}>
                                {orderData?.bol_no}
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px',

                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.BOL_LABEL')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    {
                        ((+orderData?.status_id) === 1 && orderData?.bol_doc_uri && orderData?.bol_doc_uri !== 'null') ?
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleDownBolLabel}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    color: '#1D8B45',
                                }}>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}
                                </Typography>
                            </Button> :
                            <Typography style={{
                                textTransform: 'none',
                                color: '#B454545',
                                fontSize: '14px'
                            }}>
                                {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.NOT_GENERATED')}
                            </Typography>
                    }
                </Grid>

                {/*FOR POD*/}
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px',

                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.POD_LABEL')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    {
                        ((+orderData?.status_id) === 1 && orderData?.pod_doc_uri && orderData?.pod_doc_uri !== 'null') ?
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleDownPODLabel}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    color: '#1D8B45',
                                }}>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}
                                </Typography>
                            </Button> :
                            <Typography style={{
                                textTransform: 'none',
                                color: '#B454545',
                                fontSize: '14px'
                            }}>
                                {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.NOT_GENERATED')}
                            </Typography>
                    }
                </Grid>

                {
                    invoiceDetail &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px',
                                fontSize: '14px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_NO')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                textTransform: 'none',
                                color: '#B454545',
                                fontSize: '14px'
                            }}>
                                {invoiceDetail?.invoiceRefNumber}
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px',
                    }}>
                        <Typography style={{fontSize: '14px'}}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_STATUS')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        textTransform: 'none',
                        color: !orderData?.crm_invoice_no ? '#454545' : invoiceDetail?.status === "2" ? '#1D8B45' : '#FF0303',
                        fontSize: '14px'
                    }}>
                        {
                            !orderData?.crm_invoice_no ? 'Not Billed' : invoiceDetail?.status === "2" ? intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.PAID') : intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.UNPAID')
                        }
                    </Typography>
                </Grid>
                {
                    invoiceDetail &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px',
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_DETAILS')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleDownInvoice}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    color: '#1D8B45',
                                }}>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}
                                </Typography>
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    )
}