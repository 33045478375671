import React, {useEffect, useState} from "react";
import {
    Box,
    IconButton,
    Input,
    // CircularProgress,
    Button,
    TextField,
    Snackbar,
    // useMediaQuery,
    FormControlLabel,
    RadioGroup,
    Radio,
    Dialog,
} from "@material-ui/core";
import {Edit, Check, Cancel} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import ClearIcon from "@material-ui/icons/Clear";
import {useHistory} from "react-router-dom";

import "../../styles.css";

// import axios from 'axios';
import {errHandling, getHeaders, getURLParams} from "../../utils/fetchUtil";
import {
    PROFILE_API,
} from "../../utils/apiUrl";
import {deleteUserInfo, updateUserInfo} from "../../slices/userSlice";

import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {deleteToken, getAccessToken} from "../../utils/doToken";
import {MyQRCode} from "../MyQRCode/MyQRCode";
import {ComponentToPrint} from "../MyQRCode/ComponentToPrint";
import {PrintComponent} from "../MyQRCode/PrintComponent";
import {MyQRCodeDisplay} from "../MyQRCode/MyQRCodeDisplay";

const useStyles = makeStyles((theme) => ({
    itemWrapper: {
        display: "flex",
        paddingBottom: theme.spacing(1),
        alignItems: "center",
        "& > div:nth-child(1)": {
            flexBasis: "40%",
        },
        "& > div:nth-child(2)": {
            flexBasis: "60%",
        },
        "@media (max-width: 600px)": {
            flexDirection: "column",
            alignItems: "start",
        },
    },
    formWrapper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderRadius: 5,
        backgroundColor: "white",
        maxWidth: "720px",
        margin: "0 auto",
    },
    button: {
        fontSize: "12pt",
        // color: "white",
    },
    pkgImg: {
        margin: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    dialogTitle: {
        padding: "1vh 1vw 0.5vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "rgb(141, 173, 155)",
    },
}));

const defaultProfile = {
    firstname: null,
    lastname: null,
    address: null,
    phone: null,
    email: null,
    role: null,
};

const UserProfile = () => {
    // const [confirm, setConfirm] = useState();
    // const [selectedUser, setSelectedUser] = useState();
    //Dummy userdata object for testing
    const userInfo = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    // const matches = useMediaQuery("(max-width:600px)");

    const [sampleData, setSampleData] = useState(defaultProfile);
    const [licenseDialogOpen, setLicenseDialogOpen] = useState(false);
    const [idInfoDialogOpen, setIdInfoDialogOpen] = useState(false);
    const [updateErrMsg, setUpdateErrMsg] = useState("");

    useEffect(() => {
        setSampleData({
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            phone: userInfo.phone,
            email: userInfo.email,
            address: userInfo.address,
        });
    }, [userInfo]);

    const [editValue, setEditValue] = useState({
        firstname: "",
        lastname: "",
        phone: "",
        address: "",
        licenseNum: "",
        vehicleType: null,
        role: "",
    });
    const [editMode, setEditMode] = useState({
        firstname: false,
        lastname: false,
        phone: false,
        address: false,
        licenseNum: false,
        vehicleType: false,
        licenseImg: false,
        role: false,
        deviceIdentifier: false,
    });

    const renderView = (attr) => {
        let value = sampleData[attr];
        return (
            <div>
                {value}{" "}
                <IconButton onClick={() => {
                    changeEditMode(attr)
                }}>
                    <Edit/>
                </IconButton>
            </div>
        );
    };

    const setMode = (attr, value = null) => {
        setEditMode((prev) => {
            const newObj = {...prev};
            newObj[attr] = value !== null ? value : !prev[attr];
            return newObj;
        });
    };

    //**EDIT ADDRESS */
    const handleValueChange = (field) => (e) => {
        setEditValue((prev) => {
            const newObj = {...prev};
            newObj[field] = e.target.value;
            return newObj;
        });
    };
    const changeEditMode = (attr) => {
        setEditValue((prev) => {
            const newObj = {...prev};
            newObj[attr] = sampleData[attr];
            return newObj;
        });
        setMode(attr, true);
    };

    const updateAttr = (attr, putObj, closeOper) => () => {
        axios({
            method: "put",
            url: PROFILE_API,
            data: JSON.stringify({...putObj, email: sampleData.email}),
            headers: {
                ...getHeaders(),
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
            },
        })
            .then((res) => {
                if (attr === "vehicleType")
                    dispatch(
                        updateUserInfo({field: attr, value: res.data.data.vehicle})
                    );
                else
                    dispatch(updateUserInfo({field: attr, value: res.data.data[attr]}));
                closeOper();
            })
            .catch((error) => {
                // console.log(error.message);
                errHandling(error, setUpdateErrMsg);
                closeOper();
            });
    };

    // const updateUserRole = (role, closeOper) => () => {
    //   const remoteRole = role === "User" ? "End User" : "Driver";
    //   axios({
    //     method: "put",
    //     url: getURLParams(BACKEND_CHANGE_ROLE_API(sampleData.email), {
    //       role: remoteRole,
    //     }),
    //     headers: {
    //       ...getHeaders(),
    //       "X-Requested-With": "XMLHttpRequest",
    //     },
    //   })
    //       .then((res) => {
    //         dispatch(updateUserInfo({ field: "role", value: res.data.user.role }));
    //         closeOper();
    //       })
    //       .catch((error) => {
    //         // console.log(error.message);
    //         errHandling(error, setUpdateErrMsg);
    //         closeOper();
    //       });
    // };

    // const updateTraccarIdentifier = (_identifier, closeOper) => () => {
    //   axios({
    //     method: "post",
    //     url: getURLParams(BACKEND_UPDATE_TRACCAR_API, {
    //       identifier: _identifier,
    //     }),
    //     headers: {
    //       ...getHeaders(),
    //       "X-Requested-With": "XMLHttpRequest",
    //     },
    //   })
    //     .then((res) => {
    //       dispatch(
    //         updateUserInfo({
    //           field: "traccarIdentifier",
    //           value: res.data.data.traccar_identifier,
    //         })
    //       );
    //       closeOper();
    //     })
    //     .catch((error) => {
    //       // console.log(error.message);
    //       errHandling(error, setUpdateErrMsg);
    //       closeOper();
    //     });
    // };

    const signOut = (e) => {
        const storedToken = getAccessToken("access_token");
        e.preventDefault();
        try {
            axios.get(`https://admin.uucargo.ca/api/logout`, {
                headers: {Authorization: `Bearer ${storedToken}`},
            });
            //setHide(true)
        } finally {
            deleteToken("access_token");
            dispatch(deleteUserInfo());
            dispatch(updateUserInfo({field: "login", value: false}));
            dispatch(updateUserInfo({field: "signIn", value: false}));
            history.push("/sign-in");
        }
    };

    const renderAddressEditView = () => {
        return (
            <Box display="flex" flexDirection="column">
                <TextField
                    // className={classes.textfield}
                    InputProps={{disableUnderline: true}}
                    onChange={handleValueChange("address")}
                    value={editValue.address}
                    variant="filled"
                    rows={2}
                    label="Text here"
                    multiline
                />
                <Box alignSelf="end">
                    <IconButton onClick={() => setMode("address", false)}>
                        <Cancel/>
                    </IconButton>
                    <IconButton
                        onClick={updateAttr("address", {address: editValue.address}, () =>
                            setMode("address", false)
                        )}
                    >
                        <Check/>
                    </IconButton>
                </Box>
            </Box>
        );
    };

    const renderSimpleEditView = (attr) => {
        const newUpdateObj = {};
        newUpdateObj[attr] = editValue[attr];
        return (
            <div>
                <Input
                    type="text"
                    value={editValue[attr]}
                    onChange={handleValueChange(attr)}
                />
                <IconButton onClick={() => setMode(attr, false)}>
                    <Cancel/>
                </IconButton>
                <IconButton
                    onClick={updateAttr(attr, newUpdateObj, () => setMode(attr, false))}
                >
                    <Check/>
                </IconButton>
            </div>
        );
    };

    // const renderTypeEditView = (options) => {
    //   return (
    //     <div>
    //       <CustomInput
    //         onChange={handleValueChange("vehicleType")}
    //         options={options}
    //         value={editValue.vehicleType}
    //         vehicleType
    //       />
    //       <IconButton onClick={() => setMode("vehicleType", false)}>
    //         <Cancel />
    //       </IconButton>
    //       <IconButton
    //         onClick={updateAttr(
    //           "vehicleType",
    //           { vehicle: editValue.vehicleType },
    //           () => setMode("vehicleType", false)
    //         )}
    //       >
    //         <Check />
    //       </IconButton>
    //     </div>
    //   );
    // };

    // const renderRadioEditView = () => {
    //   return (
    //     <Box display="flex" alignItems="center">
    //       <RadioGroup
    //         row
    //         aria-label="role"
    //         name="role"
    //         value={editValue.role}
    //         onChange={handleValueChange("role")}
    //       >
    //         <FormControlLabel
    //           value="User"
    //           control={<Radio color="primary" />}
    //           label="User"
    //         />
    //         <FormControlLabel
    //           value="Driver"
    //           control={<Radio color="primary" />}
    //           label="Driver"
    //         />
    //       </RadioGroup>
    //       <Box ml={1} pb={1}>
    //         <IconButton onClick={() => setMode("role", false)}>
    //           <Cancel />
    //         </IconButton>
    //         <IconButton
    //           onClick={updateUserRole(editValue.role, () =>
    //             setMode("role", false)
    //           )}
    //         >
    //           <Check />
    //         </IconButton>
    //       </Box>
    //     </Box>
    //   );
    // };

    // const onDrop = (image) => {
    //   setMode("licenseImg", false);
    // };

    // const renderImgEditView = () => {
    //   return (
    //     <Box width="100%">
    //       <ImageUploader
    //         withIcon={true}
    //         fileContainerStyle={{ width: "60%", height: "40%" }}
    //         buttonText="Choose Images"
    //         onChange={onDrop}
    //         label="Max file size: 10mb. Accepted image types: .JPG, .PNG"
    //         imgExtension={[".jpg", ".png"]}
    //         maxFileSize={10242880}
    //         fileTypeError=" - Image extension is not supported"
    //         singleImage
    //       />
    //     </Box>
    //   );
    // };

    // const handleBarClose = () => {
    //   setUpdateErrMsg("");
    // };

    return (
        <Box ml="1vw" mr="1vw">
            <br/>
            <Box className={classes.formWrapper}>
                <Box className={classes.itemWrapper}>
                    <div className="subtitle">First Name</div>
                    <div className="bodytext">
                        {editMode.firstname
                            ? renderSimpleEditView("firstname")
                            : renderView("firstname")}
                    </div>
                </Box>
                <Box className={classes.itemWrapper}>
                    <div className="subtitle">Last Name</div>
                    <div className="bodytext">
                        {editMode.lastname
                            ? renderSimpleEditView("lastname")
                            : renderView("lastname")}
                    </div>
                </Box>
                <Box className={classes.itemWrapper}>
                    <div className="subtitle">Address</div>
                    <Box className="bodytext" width="100%">
                        {editMode.address ? renderAddressEditView() : renderView("address")}
                    </Box>
                </Box>
                <Box className={classes.itemWrapper}>
                    <div className="subtitle">Phone</div>
                    <div className="bodytext">
                        {editMode.phone
                            ? renderSimpleEditView("phone")
                            : renderView("phone")}
                    </div>
                </Box>
                <Box className={classes.itemWrapper}>
                    <div className="subtitle">E-mail</div>
                    <div className="bodytext">{sampleData.email}</div>
                </Box>
            </Box>
            <br/>
            <Box className={classes.formWrapper}>
                <Box className={classes.itemWrapper}>
                    <div className="subtitle">My QR Code</div>
                    <Box alignSelf="center" pt={1}>
                        <MyQRCodeDisplay />
                        <PrintComponent />
                    </Box>
                </Box>
            </Box>
            <br/>
            <Box className={classes.formWrapper}>
                <Box className={classes.itemWrapper}>
                    <div className="subtitle">Change Password</div>
                    <Box alignSelf="center" pt={1}>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={() => history.push("/change-password")}
                        >
                            Change
                        </Button>
                    </Box>
                </Box>
            </Box>
            <br/>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Box alignSelf="center" pt={1}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={signOut}
                    >
                        Sign Out
                    </Button>
                </Box>
            </Box>
            {/*<IndividualUser cardStatus={userInfo.activeCard} />*/}
            {/*<Snackbar*/}
            {/*    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}*/}
            {/*    open={Boolean(updateErrMsg)}*/}
            {/*    onClose={handleBarClose}*/}
            {/*    message={updateErrMsg}*/}
            {/*    autoHideDuration={3000}*/}
            {/*/>*/}
            {/*<LicenseInfoForm*/}
            {/*    dialogOpen={licenseDialogOpen}*/}
            {/*    setDialogOpen={setLicenseDialogOpen}*/}
            {/*    email={sampleData.email}*/}
            {/*    update={Boolean(userInfo.licenseNumber)}*/}
            {/*/>*/}
            {/*<Dialog*/}
            {/*    maxWidth="sm"*/}
            {/*    fullWidth*/}
            {/*    open={idInfoDialogOpen}*/}
            {/*    onClose={() => {*/}
            {/*      setIdInfoDialogOpen(false);*/}
            {/*    }}*/}
            {/*>*/}
            {/*  <div className={classes.dialogTitle}>*/}
            {/*    <Box fontSize="1.5rem"></Box>*/}
            {/*    <IconButton*/}
            {/*        // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}*/}
            {/*        style={{ fill: "green" }}*/}
            {/*        className={classes.closeBtn}*/}
            {/*        onClick={() => {*/}
            {/*          setIdInfoDialogOpen(false);*/}
            {/*        }}*/}
            {/*    >*/}
            {/*      <ClearIcon />*/}
            {/*    </IconButton>*/}
            {/*  </div>*/}
            {/*  <Box m={2} fontSize="1.2rem" mb={4}>*/}
            {/*    Register or update your device identifier of the traccar client.*/}
            {/*    <br />*/}
            {/*    <br />*/}
            {/*    For more information, please check{" "}*/}
            {/*    <a*/}
            {/*        target="_blank"*/}
            {/*        rel="noopener noreferrer"*/}
            {/*        href="https://uucargo.ca/support/#gps-app"*/}
            {/*    >*/}
            {/*      here*/}
            {/*    </a>*/}
            {/*    {"."}*/}
            {/*  </Box>*/}
            {/*</Dialog>*/}
        </Box>
    );
};

export default UserProfile;
