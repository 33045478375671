import React, { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Typography, Grid, Box, Popper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import parse from "autosuggest-highlight/parse";

import throttle from "lodash/throttle";
// import PoweredByGoogle from "../../../images/powered_by_google_on_white.png";
import PoweredByGoogle from "../../../../images/powered_by_google_on_white.png";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  textfield: {
    width: "100%",
    backgroundColor: "white",
    padding: "0.4px",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: "0.4vw",
    paddingBottom: "0.4vw",
  },
  errorText: {
    border: "1px solid red",
    borderRadius: "5px",
  },
  imgCenter: {
    display: "block",
    margin: "auto",
  },
  paperRoot: {
    width: "80% !important",
  },
}));

export default function GoogleMaps({
  type,
  handleChange,
  setProvinceValue,
  addressValue,
  cityValue,
  setCityValue,
  setCityValueUpperLevel,
  setZipcodeValueUpperLevel,
  setCountryValue,
  zipCodeValue,
  setZipcodeValue,
  estimate,
  customLabel = false,
  label,
  filter = false,
  checkError = false,
  setPlaceId,
  readOnly= false,
  value,
  setValue
}) {
  const classes = useStyles();
  // const [value, setValue] = React.useState(
  //   type === "street" || type === "postal" ? "" : null
  // );
  const [inputValue, setInputValue] = React.useState(value);
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  // const autocompleteService = { current: null };
  const autocompleteService = useRef(null);
  const getDetailService = useRef(null);

  const googleApiKey = process.env.REACT_APP_API_KEY;

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/json??key=${googleApiKey}&language=en&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  useEffect(() => {
    if (filter) {
      if (type !== "city") return;

      if (value !== null) {
        setInputValue("");
      }
    }
  }, [value, filter, type]);

  // Load cityValue to value everytime the cityValue changes
  useEffect(() => {
    if (type === "city" && cityValue) {
      setValue(cityValue.city);
    }
  }, [cityValue, type]);

  // Load zipCode value to value everytime the zipCode value changes
  useEffect(() => {
    if (type === "postal" && zipCodeValue) {
      setValue(zipCodeValue.zipCode);
    }
  }, [zipCodeValue, type]);

  // Load street value to value everytime the address value changes
  useEffect(() => {
    if (type === "street" && addressValue) setValue(addressValue || null);
  }, [addressValue, type]);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService?.current?.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google?.maps?.places) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const title = (_type) => {
    if (_type === "city") return "City *";
    if (_type === "postal") return "Postal / Zip Code *";
    return "Add a street address *";
  };

  const ListboxComponent = React.forwardRef(function ListboxComponent(
    { ...rest },
    ref
  ) {
    return (
      <Box>
        <ul ref={ref} {...rest} />
        <hr />
        <Box mb={1.5}>
          <img
            src={PoweredByGoogle}
            alt="Powered by Google"
            width="120px"
            className={classes.imgCenter}
            // style={{
            //   borderRadius: "5px",
            // }}
          />
        </Box>
      </Box>
    );
  });

  const PopperCus = styled(Popper)({
    "& .MuiAutocomplete-paper": {
      width: "80%",
    },
  });

  const classTypes = (_estimate, _checkError, _value, _classes) => {
    let classes = "";
    if (_estimate) classes += `${_classes.textfield}`;
    console.log("Address value:", _value);
    if (_checkError && (!_value || _value?.trim() === "")) classes += ` ${_classes.errorText}`;
    return classes;
  };

  return (
    <Autocomplete
      //   style={{ width: 300 }}
      getOptionLabel={(option) => {
        if (typeof option === "string") return option;
        if (type === "street")
          return option.types[0] === "street_address" ||
            option.types[0] === "premise"
            ? option.structured_formatting.main_text
            : "";
        if (type === "city")
          return option.types[0] === "locality"
            ? option.structured_formatting.main_text
            : "";
        if (type === "postal")
          return option.types[0] === "postal_code"
            ? option.structured_formatting.main_text
            : "";
        return "";
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      ListboxComponent={ListboxComponent}
      freeSolo={type === "street" || type === "postal"}
      includeInputInList
      filterSelectedOptions
      value={value}
      PopperComponent={({ style, ...props }) => (
        <PopperCus
          {...props}
          placement="bottom-start"
          style={{ ...style }} // width is passed in 'style' prop
        />
      )}
      // PopperComponent={PopperMy}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        // setValue(newValue);
        if (!newValue) {
          if (type === "street" || type === "postal") handleChange("");
          return;
        }
        if (type === "street") {
          if (!getDetailService.current && window.google) {
            getDetailService.current = new window.google.maps.Geocoder();
          }
          if (!getDetailService.current) {
            return;
          }
          getDetailService.current
            ?.geocode({
              placeId: newValue.place_id,
              language: "en",
            })
            .then(({ results }) => {
              setPlaceId(newValue.place_id);
              let streetValue = "";
              const addressObj = results[0].address_components;
              console.log("Address Obj:", addressObj);
              const predictStreetNumber = addressObj.find(
                (ele) => ele.types[0] === "street_number"
              );
              const predictRoute = addressObj.find(
                (ele) => ele.types[0] === "route"
              );

              if (predictStreetNumber)
                streetValue += predictStreetNumber.long_name;
              if (predictRoute)
                streetValue += predictStreetNumber
                  ? ` ${predictRoute.long_name}`
                  : predictRoute.long_name;

              if (streetValue) {
                setValue(streetValue);
                handleChange(streetValue);
              }
              console.log("Address Object in google:", addressObj);

              const predictCity = addressObj.find(
                (ele) => ele.types[0] === "locality"
              );
              const predictProvince = addressObj.find(
                (ele) => ele.types[0] === "administrative_area_level_1"
              );
              const predictZipcode = addressObj.find(
                (ele) => ele.types[0] === "postal_code"
              );
              const predictCountry = addressObj.find(
                  (ele) => ele.types[0] === "country"
              );
              setProvinceValue(predictProvince?.short_name || "");
              setCityValue({ city: predictCity?.short_name || "" });
              setCityValueUpperLevel(predictCity?.long_name || "");
              setZipcodeValue({ zipCode: predictZipcode?.long_name || "" });
              setZipcodeValueUpperLevel(predictCity?.long_name || "");
              setCountryValue(predictCountry?.short_name || "");
              // Update city and province value if the user input and select the address
            });
        }
        if (type === "city") {
          // console.log(newValue);
          // setValue(newValue);

          if (!getDetailService.current && window.google) {
            getDetailService.current = new window.google.maps.Geocoder();
          }
          if (!getDetailService.current) {
            return;
          }
          getDetailService.current
            ?.geocode({
              placeId: newValue.place_id,
            })
            .then(({ results }) => {
              console.log("Current Place Results: ", results);
              const addressObj = results[0].address_components;

              const predictCity = addressObj.find(
                (ele) => ele.types[0] === "locality"
              );
              const predictCountry = addressObj.find(
                  (ele) => ele.types[0] === "country"
              );

              if (predictCity) {
                setValue(predictCity.long_name);
                handleChange(predictCity.long_name);
              }

              const predictProvince = addressObj.find(
                (ele) => ele.types[0] === "administrative_area_level_1"
              );

              if (predictProvince) {
                console.log("predictProvince: ", predictProvince);
                setProvinceValue(predictProvince.short_name);
              }
              if (predictCountry) {
                setCountryValue(predictCountry.short_name);
              }
            });
        }
        if (type === "postal") {
          setValue(newValue);
          handleChange(
            newValue.types[0] === "postal_code"
              ? newValue.structured_formatting.main_text
              : ""
          );
        }
      }}
      disabled={readOnly}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        handleChange(newInputValue || "");
        setValue(newInputValue);
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        params.inputProps.autoComplete = "new-password";
        return (
          <TextField
            {...params}
            // error={checkError && !value}
            label={customLabel ? label : title(type)}
            className={classTypes(estimate, checkError, value, classes)}
            InputProps={
              estimate
                ? {
                    ...params.InputProps,
                    autoComplete: "off",
                    disableUnderline: true,
                  }
                : { ...params.InputProps, autoComplete: "off" }
            }
          />
        );
      }}
      renderOption={(option) => {
        const matches =
          option?.structured_formatting?.main_text_matched_substrings || [];
        const parts = parse(
          option?.structured_formatting?.main_text || "",
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option?.structured_formatting?.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
      onBlur={() => {
        if (filter && type === "city") {
          setValue("");
        }
      }}
    />
  );
}
