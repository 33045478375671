import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";

export const LTLCrossBorderDashboardItemList = ({itemList}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            border: '1px solid #B2B2B2',
            borderRadius: '5px',
            padding: '15px'
        }}>
            <Typography style={{fontWeight: '600'}}>
                Customs Items
            </Typography>
            <Divider/>
            {
                itemList?.map((item, index) => (
                    <Box key={index}>
                        <Typography style={{fontSize: '14px', color: '#1D8B45', fontWeight: '600', textAlign: 'left'}}>
                            {item?.item_name} * {item?.quantity}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            {item?.weight} {item?.weight_unit} per unit | $ {item?.unit_price} per unit | {item?.hs_code}
                        </Typography>
                    </Box>
                ))
            }
        </Box>
    )
}