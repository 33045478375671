import icon_1 from '../../images/icons/Icon_01.png';
import icon_2 from '../../images/icons/Icon_02.png';
import icon_3 from '../../images/icons/Icon_03.png';
import icon_4 from '../../images/icons/Icon_04.png';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LandingImage from '../../images/UUC_PA_Landing.png';
import Grid from "@material-ui/core/Grid";
import {useMediaQuery} from "@mui/material";
import intl from "react-intl-universal";

const styles = {
    LandingIntroRoot: {
        width: '50%',
        padding: '45px 30px 30px',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        '@media (max-width: 480px)': {
            width: "100%",
            padding: '30px 0',
        },
    },
    LandingIntroImage: {
        width: '100%'
    },
    LandingService: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '20px',
        '@media (max-width: 480px)': {
            gap: '10px', // for phone and above
        },
    },
    LandingServiceBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    LandingServiceBoxHeading: {
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '16px',
        '@media (max-width: 480px)': {
            fontSize: '14px', // for phone and above
        },
    },
    LandingServiceBoxText: {
        textAlign: 'left',
        fontSize: '14px',
        '@media (max-width: 480px)': {
            fontSize: '12px', // for phone and above
        },
    },

}

export const LandingIntro = () => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    return (
        <Box sx={styles.LandingIntroRoot}>
            <Box sx={styles.LandingIntroImage}>
                <img src={LandingImage} alt="landing image" style={styles.LandingIntroImage}/>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Box sx={styles.LandingService}>
                            <img src={icon_1} alt="icon 1" width={isMobile ? '50px' : '100px'}/>
                            <Box sx={styles.LandingServiceBox}>
                                <Typography sx={styles.LandingServiceBoxHeading}>
                                    {intl.get('LANDING_PAGE.INTRO.TITLE_1')}
                                </Typography>
                                <Typography sx={styles.LandingServiceBoxText}>
                                    {intl.get('LANDING_PAGE.INTRO.TEXT_1')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={styles.LandingService}>
                            <img src={icon_2} alt="icon 2" width={isMobile ? '50px' : '100px'}/>
                            <Box sx={styles.LandingServiceBox}>
                                <Typography sx={styles.LandingServiceBoxHeading}>
                                    {intl.get('LANDING_PAGE.INTRO.TITLE_2')}
                                </Typography>
                                <Typography sx={styles.LandingServiceBoxText}>
                                    {intl.get('LANDING_PAGE.INTRO.TEXT_2')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={styles.LandingService}>
                            <img src={icon_3} alt="icon 3" width={isMobile ? '50px' : '100px'}/>
                            <Box sx={styles.LandingServiceBox}>
                                <Typography sx={styles.LandingServiceBoxHeading}>
                                    {intl.get('LANDING_PAGE.INTRO.TITLE_3')}
                                </Typography>
                                <Typography sx={styles.LandingServiceBoxText}>
                                    {intl.get('LANDING_PAGE.INTRO.TEXT_3')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={styles.LandingService}>
                            <img src={icon_4} alt="icon 4" width={isMobile ? '50px' : '100px'}/>
                            <Box sx={styles.LandingServiceBox}>
                                <Typography sx={styles.LandingServiceBoxHeading}>
                                    {intl.get('LANDING_PAGE.INTRO.TITLE_4')}
                                </Typography>
                                <Typography sx={styles.LandingServiceBoxText}>
                                    {intl.get('LANDING_PAGE.INTRO.TEXT_4')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}