import {actionType} from "../../utils/Helper";

export const updateShippingLTLQuickQuoteUnit = metric => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_UNIT,
    payload: metric
})

export const updateShippingLTLCreateLabelUnit = metric => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_UNIT,
    payload: metric
})

export const initShippingLTLCreateLabelPalletList = palletList => ({
    type: actionType.INIT_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST,
    payload: palletList
})

export const addShippingLTLQuickQuotePalletList = palletList => ({
    type: actionType.ADD_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST,
    payload: palletList
})

export const addShippingLTLCreateLabelPalletList = palletList => ({
    type: actionType.ADD_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST,
    payload: palletList
})

export const updateShippingLTLQuickQuotePalletList = pallet => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST,
    payload: pallet
})

export const updateShippingLTLCreateLabelPalletList = pallet => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST,
    payload: pallet
})

export const removeShippingLTLQuickQuotePalletList = pallet => ({
    type: actionType.REMOVE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST,
    payload: pallet
})

export const removeShippingLTLCreateLabelPalletList = pallet => ({
    type: actionType.REMOVE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST,
    payload: pallet
})

export const duplicateShippingLTLQuickQuotePalletList = pallet => ({
    type: actionType.DUPLICATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST,
    payload: pallet
})

export const duplicateShippingLTLCreateLabelPalletList = pallet => ({
    type: actionType.DUPLICATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST,
    payload: pallet
})

export const clearShippingLTLQuickQuotePalletList = () => ({
    type: actionType.CLEAR_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST,
})

export const clearShippingLTLCreateLabelPalletList = () => ({
    type: actionType.CLEAR_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST,
})

export const updateShippingLTLQuickQuoteMoveFrom = moveFrom => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION,
    payload: moveFrom
})

export const updateShippingLTLCreateLabelMoveFrom = moveFrom => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_FROM_LOCATION,
    payload: moveFrom
})

export const updateShippingLTLQuickQuoteMoveTo = moveTo => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION,
    payload: moveTo
})

export const updateShippingLTLCreateLabelMoveTo = moveTo => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_TO_LOCATION,
    payload: moveTo
})

export const updateShippingLTLQuickQuoteMoveFromMissing = missing => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION_MISSING,
    payload: missing
})

export const updateShippingLTLQuickQuoteMoveToMissing = missing => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION_MISSING,
    payload: missing
})

export const updateCrossBorderQuickQuoteLTL = crossBorder => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_CROSS_BORDER,
    payload: crossBorder
})

export const updateShippingLTLQuickQuoteRate = rate => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_RATE,
    payload: rate
})

export const selectShippingLTLQuickQuoteRate = rate => ({
    type: actionType.SELECT_SHIPPING_LTL_QUICK_QUOTE_RATE,
    payload: rate
})

export const updateShippingLTLQuickQuoteMoveFromAddition = moveFrom => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_ADDITIONAL_LOCATION,
    payload: moveFrom
})

export const updateShippingLTLQuickQuoteMoveToAddition = moveTo => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_ADDITIONAL_LOCATION,
    payload: moveTo
})

export const updateShippingLTLQuickQuoteShipperInfo = shipperInfo => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIPPER_INFO,
    payload: shipperInfo
})

export const updateShippingLTLQuickQuoteConsigneeInfo = consigneeInfo => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_CONSIGNEE_INFO,
    payload: consigneeInfo
})

export const updateShippingLTLQuickQuoteShipPickupDateTime = dateAndTime => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIP_AND_PICKUP_DATA_TIME,
    payload: dateAndTime
})

export const updateShippingLTLQuickQuotePlaceOrderValidate = validation => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_PLACE_ORDER_VALIDATE,
    payload: validation
})

export const updateShippingLTLQuickQuoteSpecialService = specialService => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE,
    payload: specialService
})

export const updateShippingLTLCreateLabelSpecialService = specialService => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_SPECIAL_SERVICE,
    payload: specialService
})

export const saveShippingLTLQuickQuoteSpecialService = specialService => ({
    type: actionType.SAVE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE,
    payload: specialService
})

export const updateShippingLTLQuickQuoteReferenceNumber = referenceNumber => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_REFERENCE_NUMBER,
    payload: referenceNumber
})

export const updateShippingLTLQuickQuoteRemark = remark => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_REMARK,
    payload: remark
})

export const updateShippingLTLCreateLabelValidation = validation => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_VALIDATION,
    payload: validation
})

export const updateShippingLTLCreateLabelRate = rate => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_RATE,
    payload: rate
})

export const selectShippingLTLCreateLabelRate = rate => ({
    type: actionType.SELECT_SHIPPING_LTL_CREATE_LABEL_RATE,
    payload: rate
})

export const clearShippingLTLCreateLabelRate = () => ({
    type: actionType.CLEAR_SHIPPING_LTL_CREATE_LABEL_RATE
})

export const updateCallAllLTLCarrier = index => ({
    type: actionType.UPDATE_CALLED_ALL_LTL_CARRIER,
    payload: index
})

export const clearCallAllLTLCarrier = () => ({
    type: actionType?.CLEAR_CALLED_ALL_LTL_CARRIER
})

export const updateShippingLTLCreateLabelReferenceNumber = referenceNumber => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER,
    payload: referenceNumber
})

export const clearShippingLTLCreateLabelReferenceNumber = () => ({
    type: actionType.CLEAR_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER
})

export const updateShippingLTLCreateLabelComment = comment => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_COMMENT,
    payload: comment
})

export const clearShippingLTLCreateLabelComment = () => ({
    type: actionType.CLEAR_SHIPPING_LTL_CREATE_LABEL_COMMENT
})

export const updateShippingLTLCreateLabelDateAndTime = dateAndTime => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME,
    payload: dateAndTime
})

export const clearShippingLTLCreateLabelDateAndTime = () => ({
    type: actionType.CLEAR_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME
})

export const addShippingLTLCrossBorderItemList = itemList => ({
    type: actionType.ADD_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST,
    payload: itemList
})

export const updateShippingLTLCrossBorderItemList = itemList => ({
    type: actionType.UPDATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST,
    payload: itemList
})

export const removeShippingLTLCrossBorderItemList = itemList => ({
    type: actionType.REMOVE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST,
    payload: itemList
})

export const duplicateShippingLTLCrossBorderItemList = itemList => ({
    type: actionType.DUPLICATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST,
    payload: itemList
})

export const clearShippingLTLCrossBorderItemList = itemList => ({
    type: actionType.CLEAR_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST
})

export const updateShippingLTLCrossBorderRate = rate => ({
    type: actionType.UPDATE_SHIPPING_LTL_CROSS_BORDER_RATE,
    payload: rate
})

export const updateShippingLTLCrossBorderTransAndType = crossBorder => ({
    type: actionType.UPDATE_SHIPPING_LTL_CROSS_BORDER_TRANS_AND_TYPE,
    payload: crossBorder
})

export const updateShippingLTLQuickQuoteBatteryDetails = batteryDetails => ({
    type: actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_BATTERY_DETAILS,
    payload: batteryDetails
})

export const updateShippingLTLCreateLabelBatteryDetails = batteryDetails => ({
    type: actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_BATTERY_DETAILS,
    payload: batteryDetails
})