import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import { Alert } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAccessToken } from "../../utils/doToken";
import axios from "axios";
import { PARTNER_URI } from "../../utils/apiUrl";
import TrackPackageItem from "../Tracking/TrackPackageItem";
import Box from "@mui/material/Box";
import { fontWeight } from "@mui/system";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
    textAlign: "left",
  },
  trackingInfoWrapper:{
      fontWeight:900
  },
  trackingNumbers: {
    '&:hover': {
      background: "#fcbf49",
    },
    cursor: "pointer",
    textDecoration: "underline",
    color: "blue"
  }
}));



// //test sample data
// const sampleData = [
//   {
//     trackingResponse: {
//       activity: [
//         {
//           description: "Voided Information Received ",
//           address: {
//             address: "",
//             city: "",
//             province: "",
//             country: "",
//           },
//           date: "2023-01-04T16:39:03.000Z",
//         },
//         {
//           description:
//             "Shipper created a label, UPS has not received the package yet. ",
//           address: {
//             address: "",
//             city: "",
//             province: "",
//             country: "",
//           },
//           date: "2023-01-04T16:38:48.000Z",
//         },
//       ],
//       status: "On the Way",
//       originalResponse: {
//         trackResponse: {
//           shipment: [
//             {
//               inquiryNumber: "1Z3A046E2094548187",
//               package: [
//                 {
//                   trackingNumber: "1Z3A046E2094548187",
//                   deliveryDate: [],
//                   activity: [
//                     {
//                       location: {
//                         address: {
//                           countryCode: "CA",
//                           country: "CA",
//                         },
//                       },
//                       status: {
//                         type: "MV",
//                         description: "Voided Information Received ",
//                         code: "VP",
//                         statusCode: "007",
//                       },
//                       date: "20230104",
//                       time: "163903",
//                     },
//                     {
//                       location: {
//                         address: {
//                           countryCode: "CA",
//                           country: "CA",
//                         },
//                       },
//                       status: {
//                         type: "M",
//                         description:
//                           "Shipper created a label, UPS has not received the package yet. ",
//                         code: "MP",
//                         statusCode: "003",
//                       },
//                       date: "20230104",
//                       time: "163848",
//                     },
//                   ],
//                   packageCount: 3,
//                 },
//               ],
//               userRelation: ["SHIPPER"],
//             },
//           ],
//         },
//       },
//     },
//     accountBase: "ups",
//     trackingNumber: "1Z3A046E2094548187",
//     transaction: {
//       tran_id: 4150,
//       order_id: 2657,
//       partner_id: 1,
//       ref_order_no: "package-list-shippping-sample_9_.xlsx",
//       shipping_no: null,
//       ref_product_no: "UUCTEST222",
//       account_base: "ups",
//       amount_gross: 51.84,
//       amount: 58.58,
//       tranacted_at: "2023-01-05 00:38:47",
//       tran_status: -2,
//       ref_other_no: "custID_034",
//       created_at: "2023-01-04 16:38:47",
//       updated_at: "2023-01-04 16:39:03",
//       account_name: "3A046E",
//       send_from_name: "UUCARGO",
//       send_from_email: "",
//       send_from_tel: "7781231234",
//       send_from_addr: "6751 Westminster Highway",
//       send_from_city: "Richmond",
//       send_from_province: "BC",
//       send_from_postal_code: "V7C4V4",
//       send_from_country: "CA",
//       send_to_name: "SHOP NYRA",
//       send_to_email: "",
//       send_to_tel: "6476805469",
//       send_to_addr: "79 HAVAGAL CRESCENT",
//       send_to_city: "MARKHAM",
//       send_to_province: "ON",
//       send_to_postal_code: "L3P7G6",
//       send_to_country: "CA",
//       label_zip_uri: null,
//       cargo_control_no: "",
//       cancelled_at: "2023-01-04 16:39:03",
//       shipping_id_no: "1Z3A046E2094548187",
//       parts_of: 1,
//       owner_role: 3,
//       label_pdf_uri:
//         "uucargo-resource/shipping-label/batch/package-list-shippping-sample_9_.xlsx/UUCTEST222_QZJB.pdf",
//       currency_code: "CAD",
//       tran_tracking_nos:
//         "1Z3A046E2094548187,\n1Z3A046E2090634993,\n1Z3A046E2093740407",
//     },
//   },
//   {
//     trackingResponse: {
//       activity: [
//         {
//           description: "Voided Information Received ",
//           address: {
//             address: "",
//             city: "",
//             province: "",
//             country: "",
//           },
//           date: "2023-01-04T16:39:03.000Z",
//         },
//         {
//           description:
//             "Shipper created a label, UPS has not received the package yet. ",
//           address: {
//             address: "",
//             city: "",
//             province: "",
//             country: "",
//           },
//           date: "2023-01-04T16:38:48.000Z",
//         },
//       ],
//       status: "On the Way",
//       originalResponse: {
//         trackResponse: {
//           shipment: [
//             {
//               inquiryNumber: "1Z3A046E2090634993",
//               package: [
//                 {
//                   trackingNumber: "1Z3A046E2090634993",
//                   deliveryDate: [],
//                   activity: [
//                     {
//                       location: {
//                         address: {
//                           countryCode: "CA",
//                           country: "CA",
//                         },
//                       },
//                       status: {
//                         type: "MV",
//                         description: "Voided Information Received ",
//                         code: "VP",
//                         statusCode: "007",
//                       },
//                       date: "20230104",
//                       time: "163903",
//                     },
//                     {
//                       location: {
//                         address: {
//                           countryCode: "CA",
//                           country: "CA",
//                         },
//                       },
//                       status: {
//                         type: "M",
//                         description:
//                           "Shipper created a label, UPS has not received the package yet. ",
//                         code: "MP",
//                         statusCode: "003",
//                       },
//                       date: "20230104",
//                       time: "163848",
//                     },
//                   ],
//                   packageCount: 3,
//                 },
//               ],
//               userRelation: ["SHIPPER"],
//             },
//           ],
//         },
//       },
//     },
//     accountBase: "ups",
//     trackingNumber: "1Z3A046E2090634993",
//     transaction: {
//       tran_id: 4150,
//       order_id: 2657,
//       partner_id: 1,
//       ref_order_no: "package-list-shippping-sample_9_.xlsx",
//       shipping_no: null,
//       ref_product_no: "UUCTEST222",
//       account_base: "ups",
//       amount_gross: 51.84,
//       amount: 58.58,
//       tranacted_at: "2023-01-05 00:38:47",
//       tran_status: -2,
//       ref_other_no: "custID_034",
//       created_at: "2023-01-04 16:38:47",
//       updated_at: "2023-01-04 16:39:03",
//       account_name: "3A046E",
//       send_from_name: "UUCARGO",
//       send_from_email: "",
//       send_from_tel: "7781231234",
//       send_from_addr: "6751 Westminster Highway",
//       send_from_city: "Richmond",
//       send_from_province: "BC",
//       send_from_postal_code: "V7C4V4",
//       send_from_country: "CA",
//       send_to_name: "SHOP NYRA",
//       send_to_email: "",
//       send_to_tel: "6476805469",
//       send_to_addr: "79 HAVAGAL CRESCENT",
//       send_to_city: "MARKHAM",
//       send_to_province: "ON",
//       send_to_postal_code: "L3P7G6",
//       send_to_country: "CA",
//       label_zip_uri: null,
//       cargo_control_no: "",
//       cancelled_at: "2023-01-04 16:39:03",
//       shipping_id_no: "1Z3A046E2094548187",
//       parts_of: 1,
//       owner_role: 3,
//       label_pdf_uri:
//         "uucargo-resource/shipping-label/batch/package-list-shippping-sample_9_.xlsx/UUCTEST222_QZJB.pdf",
//       currency_code: "CAD",
//       tran_tracking_nos:
//         "1Z3A046E2094548187,\n1Z3A046E2090634993,\n1Z3A046E2093740407",
//     },
//   },
//   {
//     trackingResponse: {
//       activity: [
//         {
//           description: "Voided Information Received ",
//           address: {
//             address: "",
//             city: "",
//             province: "",
//             country: "",
//           },
//           date: "2023-01-04T16:39:03.000Z",
//         },
//         {
//           description:
//             "Shipper created a label, UPS has not received the package yet. ",
//           address: {
//             address: "",
//             city: "",
//             province: "",
//             country: "",
//           },
//           date: "2023-01-04T16:38:48.000Z",
//         },
//       ],
//       status: "On the Way",
//       originalResponse: {
//         trackResponse: {
//           shipment: [
//             {
//               inquiryNumber: "1Z3A046E2093740407",
//               package: [
//                 {
//                   trackingNumber: "1Z3A046E2093740407",
//                   deliveryDate: [],
//                   activity: [
//                     {
//                       location: {
//                         address: {
//                           countryCode: "CA",
//                           country: "CA",
//                         },
//                       },
//                       status: {
//                         type: "MV",
//                         description: "Voided Information Received ",
//                         code: "VP",
//                         statusCode: "007",
//                       },
//                       date: "20230104",
//                       time: "163903",
//                     },
//                     {
//                       location: {
//                         address: {
//                           countryCode: "CA",
//                           country: "CA",
//                         },
//                       },
//                       status: {
//                         type: "M",
//                         description:
//                           "Shipper created a label, UPS has not received the package yet. ",
//                         code: "MP",
//                         statusCode: "003",
//                       },
//                       date: "20230104",
//                       time: "163848",
//                     },
//                   ],
//                   packageCount: 3,
//                 },
//               ],
//               userRelation: ["SHIPPER"],
//             },
//           ],
//         },
//       },
//     },
//     accountBase: "ups",
//     trackingNumber: "1Z3A046E2093740407",
//     transaction: {
//       tran_id: 4150,
//       order_id: 2657,
//       partner_id: 1,
//       ref_order_no: "package-list-shippping-sample_9_.xlsx",
//       shipping_no: null,
//       ref_product_no: "UUCTEST222",
//       account_base: "ups",
//       amount_gross: 51.84,
//       amount: 58.58,
//       tranacted_at: "2023-01-05 00:38:47",
//       tran_status: -2,
//       ref_other_no: "custID_034",
//       created_at: "2023-01-04 16:38:47",
//       updated_at: "2023-01-04 16:39:03",
//       account_name: "3A046E",
//       send_from_name: "UUCARGO",
//       send_from_email: "",
//       send_from_tel: "7781231234",
//       send_from_addr: "6751 Westminster Highway",
//       send_from_city: "Richmond",
//       send_from_province: "BC",
//       send_from_postal_code: "V7C4V4",
//       send_from_country: "CA",
//       send_to_name: "SHOP NYRA",
//       send_to_email: "",
//       send_to_tel: "6476805469",
//       send_to_addr: "79 HAVAGAL CRESCENT",
//       send_to_city: "MARKHAM",
//       send_to_province: "ON",
//       send_to_postal_code: "L3P7G6",
//       send_to_country: "CA",
//       label_zip_uri: null,
//       cargo_control_no: "",
//       cancelled_at: "2023-01-04 16:39:03",
//       shipping_id_no: "1Z3A046E2094548187",
//       parts_of: 1,
//       owner_role: 3,
//       label_pdf_uri:
//         "uucargo-resource/shipping-label/batch/package-list-shippping-sample_9_.xlsx/UUCTEST222_QZJB.pdf",
//       currency_code: "CAD",
//       tran_tracking_nos:
//         "1Z3A046E2094548187,\n1Z3A046E2090634993,\n1Z3A046E2093740407",
//     },
//   },
// ];

export default function ShippingListTrackingTable({
  tran_id,
  order_id,
  ref_order_no,
  cargo_control_no,
  ref_product_no,
  ref_other_no,
  account_base,
  gross_price,
  net_price,
  zip_label_uri,
  label_pdf_uri,
  date,
  send_to_name,
  send_to_email,
  send_to_tel,
  send_to_addr,
  send_to_city,
  send_to_postal_code,
  send_to_province,
  send_to_country,
  currency_code,
  transactionNumbers,
  tran_status,
  shipping_id_no,
  shipping_fee_exclude_tax,
  service_fee_exclude_tax,
  service_fee_include_gst,
  shipping_fee_include_tax,
}) {
  const classes = useStyles();

  const getLabelURL = (pdf, zip) => {
    if (pdf) {
      return pdf;
    }
    if (zip) {
      return zip;
    }
    return "";
  };

  const [cancelTrackingNumberText, setCancelTrackingNumberText] = useState("");
  const [
    cancelTrackingNumberErrorMessage,
    setCancelTrackingNumberErrorMessage,
  ] = useState("");
  const [cancelLoading, setCancelLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] =
    useState(false);
  const [trackedPackagesList, setTrackedPackagesList] = useState([]);

  const getTrackedPackageList = async () => {
    try {
      if (transactionNumbers?.trim() === "") {
        return;
      }

      const storedToken = getAccessToken("access_token");
      const { data, status } = await axios({
        method: "get",
        url: `${PARTNER_URI}/loose-item/3rd-party/multipleTrackingForWeb?trackingNumbers=${transactionNumbers}`,
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      const trackingList = data?.filter((v) => v?.trackingResponse !== null);

      if (status === 200) {
        console.log("Success:", data);
        setTrackedPackagesList(trackingList);
      } else {
        console.log("Error:", data);
        throw new Error();
      }
    } catch (e) {
      setErrorMessage("Failed to Schedule Pickup. Try again another time.");
    }
  };

  useEffect(() => {
    getTrackedPackageList();

  }, []);

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  //handle tracking information dialog open and close
  const handleTrackingInfo = () => {
    setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
  };

  const handleTrackingInfoClose = (event, reason)=>{
    if (reason && reason == "backdropClick") {
      setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
    }
  }

  const cancelOrderByTransactionId = async () => {
    try {
      setCancelLoading(true);
      const storedToken = getAccessToken("access_token");
      const { data } = await axios.delete(
        `${PARTNER_URI}/loose-item/3rd-party/internal/cancel-shipping`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
          data: {
            trackingNumber: shipping_id_no,
          },
        }
      );
      console.log(data);
      setCancelTrackingNumberErrorMessage("");
      setIsCancelled(true);
    } catch (e) {
      console.log(e);
      setCancelTrackingNumberErrorMessage(
        e?.response?.data?.errorMessage || "Error!"
      );
    } finally {
      setCancelLoading(false);
    }
  };

  const confirmCancelOrder = async (e) => {
    e.preventDefault();
    await cancelOrderByTransactionId();
    handleToastClick();
    handleDialogClose();
  };

  const excludeTax = +(
    +shipping_fee_exclude_tax + +service_fee_exclude_tax
  ).toFixed(2);
  const includeTax = +(
    +shipping_fee_include_tax + +service_fee_include_gst
  ).toFixed(2);

  // Cancel Tracking Order
  const onCancelOrder = () => {
    handleDialogOpen();
  };

  return (
    <>
      {/*Pop-up Toast*/}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={3000}
        message="Cancel Order"
      >
        {(() => {
          if (cancelTrackingNumberErrorMessage !== "") {
            return (
              <Alert
                onClose={handleToastClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Transaction can't be cancelled!
                <hr />
                Error: {cancelTrackingNumberErrorMessage}
              </Alert>
            );
          }
          return (
            <Alert
              onClose={handleToastClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Transaction has been cancelled!
            </Alert>
          );
        })()}
      </Snackbar>
      {/*Pop-up Toast*/}

      {/*Confirm Delete Tracking Dialog*/}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel Tracking Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This process will cancel ALL the trackings related to this
            Transaction <b>{transactionNumbers}</b>. Are you sure to cancel?
            <hr />
            Click <b>Confirm</b> to cancel this shipping.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="outlined"
            autoFocus
            type="button"
          >
            No
          </Button>
          <Button
            onClick={confirmCancelOrder}
            variant="outlined"
            type="button"
            disabled={cancelLoading}
          >
            {cancelLoading && <CircularProgress size={14} />}
            {!cancelLoading && "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      {/*Confirm Delete Tracking Dialog*/}

      {/*Trakcing Information Dialog*/}
      <Dialog
        open={trackingInfoDialogIsOpen}
        onClose={handleTrackingInfoClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <DialogTitle id="alert-dialog-title" >Tracking Details</DialogTitle>
        <DialogContent>
          { trackedPackagesList.length === 0 ? <Box>No Trackng Information Found</Box> :
          trackedPackagesList.map((trackedPackage, index) => {
            return (
              <TrackPackageItem
                key={index}
                activity={
                  trackedPackage.trackingResponse
                    ? trackedPackage.trackingResponse.activity
                    : undefined
                }
                trackingNo={trackedPackage.trackingNumber}
                courier={trackedPackage.accountBase}
                deliverStatus={trackedPackage.trackingResponse.status}
                transaction={trackedPackage.transaction}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleTrackingInfo}
            variant="outlined"
            autoFocus
            type="button"
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>

      <tr className={classes.listItemWrapper}>
        <td width="7%">
          <Link to={`/loose-item/3rd-party/shipping/list/product/${tran_id}`}>
            {tran_id}
          </Link>
        </td>
        <td width="7%">{order_id}</td>
        <td width="5%">
          Ref Order #: {ref_order_no}
          <br />
          Ref Product #: {ref_product_no}
          <br />
          Ref Other #: {ref_other_no}
        </td>
        {/*<td>{ref_order_no}</td>*/}
        {/*<td>{ref_product_no}</td>*/}
        {/*<td>{ref_other_no}</td>*/}

        {/*<td>{`${send_to_name ? send_to_name : "N/A"} / ${send_to_email ? send_to_email : "N/A"} / ${send_to_tel ? send_to_tel : "N/A"}`}</td>*/}
        {/*<td>{`${send_to_addr ? send_to_addr : "N/A"} / ${send_to_city ? send_to_city : "N/A"} / ${send_to_province ? send_to_province : "N/A"} / ${send_to_country ? send_to_country : "N/A"} / ${send_to_postal_code ? send_to_postal_code : "N/A"}`}</td>*/}
        <td width="20%">
          {/*{`${send_to_name ? send_to_name : "N/A"} ${send_to_email ? send_to_email : "N/A"}, ${send_to_tel ? send_to_tel : "N/A"}`}*/}
          {send_to_name ? send_to_name : "N/A"}
          <br />
          {send_to_tel ? send_to_tel : "N/A"},{" "}
          {send_to_email ? send_to_email : "N/A"}
          <br />
          {send_to_addr}
          <br />
          {send_to_city}, {send_to_province}, {send_to_country},{" "}
          {send_to_postal_code}
        </td>
        {/*<td>{account_base}</td>*/}
        <td>
          excl. tax:{" "}
          <CurrencyFormat
            value={Math.abs(excludeTax)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={
              +excludeTax >= 0 ? `${currency_code} $` : `${currency_code} $-`
            }
          />
          <br />
          inc. tax:{" "}
          <CurrencyFormat
            value={Math.abs(includeTax)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={
              +includeTax >= 0 ? `${currency_code} $` : `${currency_code} $-`
            }
          />
        </td>
        {/*<td>*/}
        {/*  <CurrencyFormat value={net_price} displayType={'text'} thousandSeparator={true} prefix={`${currency_code} $`} />*/}
        {/*</td>*/}
        {/*<Tooltip*/}
        {/*  title="Click to check the tracking information"*/}
        {/*  placement="left"*/}
        {/*>*/}
        <td width="15%">
          {account_base}
          <br />

          {transactionNumbers}

          <br />
          <a
            href={getLabelURL(label_pdf_uri, zip_label_uri)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getLabelURL(label_pdf_uri, zip_label_uri) ? "Label" : ""}
          </a>
          &nbsp; &nbsp; &nbsp;
          <span onClick={handleTrackingInfo} className={classes.trackingNumbers}>Trackings Info</span>
        </td>
        {/*</Tooltip>*/}
        {/*<td>*/}
        {/*  <a href={getLabelURL(label_pdf_uri, zip_label_uri)} target="_blank" rel="noopener noreferrer">*/}
        {/*    {getLabelURL(label_pdf_uri, zip_label_uri) ? "Download" : ""}*/}
        {/*  </a>*/}
        {/*</td>*/}
        {/*<td>*/}
        {/*  <a href={zip_label_uri} target="_blank" rel="noopener noreferrer">*/}
        {/*    {zip_label_uri ? "Download" : ""}*/}
        {/*  </a>*/}
        {/*</td>*/}
        {/*<td>*/}
        {/*  <a href={label_pdf_uri} target="_blank" rel="noopener noreferrer">*/}
        {/*    {label_pdf_uri ? "Download" : ""}*/}
        {/*  </a>*/}
        {/*</td>*/}
        <td>{dayjs(date).format("YYYY-MM-DD HH:mm")}</td>
        <td>
          {
            // tran_status && <td>
            //     {
            //       !isCancelled ? (
            //           <button onClick={() => {
            //             onCancelOrder();
            //           }}>Cancel</button>
            //       ) : "Cancelled"
            //     }
            //   </td>
            (() => {
              if (+tran_status === 1 || +tran_status === 0) {
                return (
                  <button
                    onClick={() => {
                      onCancelOrder();
                    }}
                  >
                    Cancel
                  </button>
                );
              }
              if (+tran_status === 2) {
                return "Delivered";
              }
              return "Cancelled";
            })()
          }
        </td>
      </tr>
    </>
  );
}
