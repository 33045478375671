import React, {useState} from 'react';
import { DateRange } from 'react-date-range';
import {addDays, format, isSameDay, isWeekend} from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import dayjs from "dayjs"; // theme css file


const DateRangeCalendar = ({ dateRange, setDateRange, afterDateChange, availableDates }) => {

  const customDayContent = (day) => {
    let extraDot = null;
    // if (isWeekend(day)) {
    //   extraDot = (
    //       <div
    //           style={{
    //             position: "absolute",
    //             bottom: 7,
    //             right: 5,
    //             color: "red"
    //           }}
    //       >$</div>
    //   )
    // }
    for (const availableDate of availableDates) {
      if (isSameDay(availableDate, day)) {
        extraDot = (
            <div
                style={{
                  height: "5px",
                  width: "5px",
                  borderRadius: "100%",
                  background: "orange",
                  position: "absolute",
                  top: 2,
                  right: 2,
                }}
            />
        )
        break;
      }
    }

    return (
        <div>
          {extraDot}
          <span>{format(day, "d")}</span>
        </div>
    )
  }

  return (
      <DateRange
          editableDateInputs
          onChange={item => {
            setDateRange([item.selection]);
            console.log("Item:", item);
            // Pass Start Date and End Date
            const startDate = dayjs(item.selection.startDate);
            const endDate = dayjs(item.selection.endDate);
            afterDateChange(startDate, endDate);
          }}
          moveRangeOnFirstSelection={false}
          ranges={dateRange}
          direction="horizontal"
          minDate={new Date()}
          maxDate={addDays(new Date(), 31)}
          dayContentRenderer={customDayContent}
      />
  );
};



export default DateRangeCalendar;
