import React, {useState, useEffect, useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getShipmentItems, getPickUpDetails } from '../../actions/shipmentAction';
import {useHistory, useLocation} from "react-router-dom";
// import Slide from 'react-reveal';
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  Snackbar,
  Input, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClearIcon from "@material-ui/icons/Clear";
import HelpIcon from "@material-ui/icons/Help";
import EventIcon from "@material-ui/icons/Event";

import PropTypes from "prop-types";
import { format } from "date-fns";
import axios from "axios";
import produce from "immer";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
// import NewCredit from '../CreditCardSetup/newCredit'
import { getHeaders } from "../../utils/fetchUtil";
import {
  BACKEND_ROUTE_URI, LABEL_DOWNLOAD_URL_PREFIX,
  PARTNER_URI
  // BACKEND_ORDERS_API,
  // // BACKEND_PACKAGES_API,
  // BACKEND_PRICE_ESTIMATE_API,
  // LIST_CREDIT_CARD_API,
  // BACKEND_ADDON_INFO_API,
  // CRM_ORDER_WITHOUT_PRICE,
  // BACKEND_ADD_TO_ADDRESSBOOK,
} from "../../utils/apiUrl";
// import { CustomInput } from "../shared";
import CustomInput from "../../shared/customInput";
// import LoadingContainer from "../shared/loadingContainer";
import loadingContainer from "../../shared/loadingContainer";
import { redirectHome } from "../../utils/redirect";
import { errHandling } from "../../utils/fetchUtil";
import { CardImages } from "../../images/cards";
// import { clearInitOrder } from "../../slices";
import { clearInitOrder } from "../../slices/ordersSlice";
// import AddCreditCardPage from "../Profiles/addCreditCardPage";
// import AddCreditCardPage from "../ProfilesBak/addCreditCardPage";
// import PriceModule from "../shared/OrderDetail/priceModule";
// import InsuranceIcon from "../Images/icons/insuranceIcon";
import ReviewOrderHeader from "./components/reviewOrderHeader";
import LoadingDock from "../../images/icons/LoadingDock.png";
import getProvinceCode, {getUSProvinceCodeByName} from "../../utils/getProvinceCode";
import {getAccessToken} from "../../utils/doToken";
import InsuranceIcon from "../../images/icons/insuranceIcon";
import {ADDRESS_TYPE_FBA, ADDRESS_TYPE_RESIDENTIAL, ADDRESS_TYPE_WAREHOUSE} from "../../shared/constInfo";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ShippingOrderListTable from "../ShippingList/ShippingOrderListTable";
import QuoteTable from "./components/quoteTable";
import AddQuote from "./components/addQuote";
import LoadingButton from "@mui/lab/LoadingButton";
// import { getAddressBook } from "../../slices";

// Compare number with limit
const numberExceed = (num, limit) => {
  return parseFloat(num) > limit;
};

// Different Text for different place order condition
const placeOrderText = (error) => {
  return error ? "Submit" : "Confirm Order";
};

const accountOptionsValue = {
  "UPS-Richmond-BC": "ups-richmond-bc",
  "UPS-Toronto-ON": "ups-toronto-on",
  "UPS-Montreal-QC": "ups-montreal-qc",
  "FEDEX-Richmond-BC": "fedex-richmond-bc",
  "FEDEX-Toronto-ON": "fedex-toronto-on",
  "FEDEX-Montreal-QC": "fedex-montreal-qc",
  "FEDEX-Calgary-AB": "fedex-calgary-ab",
  "UPS-Calgary-AB": "ups-calgary-ab",
  "UPS-UUC-Brokerage": "ups-uuc-brokerage"
};

function BACKEND_ADDON_INFO_API (id) {
  return `${BACKEND_ROUTE_URI}/extraoptions/${id}`;
}

const useQuery = () =>{
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ReviewAndConfirm = ({
  toFirst,
  toFourth,
  toFifth,
  toConfirm,
  pallets,
  pickUpDetails,
  setPickupDetails,
  distance,
  isLooseItem,
  selectedLooseItem,
  companyName,
  serviceName,
  accountBaseCombinedName,
  priceText,
  cargoControlNumber,
  refOrderNumber,
  shippingItems,
  isAcceptQuote,
  setIsAcceptQuote
}) => {
  const dispatch = useDispatch();
  // const pallets = useSelector((state) => state.shipping.shippingItems);
  // const pickUpDetails = useSelector((state) => state.shipping.pickUpDetails);
  const userEmail = useSelector((state) => state.user.email);
  const firstname = useSelector((state) => state.user.firstname);
  const lastname = useSelector((state) => state.user.lastname);

  const query = useQuery();
  const requestId = query.get("requestId");
  const edit = pickUpDetails.requestId !== "";

  // const cardStatus = useSelector((state) => state.user.activeCard);
  const userPhone = useSelector((state) => state.user.phone);
  const id = useSelector((state) => state.user.id);
  const [extraMsg, setExtraMsg] = useState("");
  const [routeError, setRouteError] = useState(true);
  const [sampleLabel, setSampleLabel] = useState("");

  const [fetchingPrice, setFetchingPriceState] = useState(true);
  const [creatingOrder, setCreatingStatus] = useState(false);
  const [shippingPrice, setShippingPrice] = useState({});

  const [priceErrMsg, setPriceErrMsg] = useState("");
  const [cardErrMsg, setCardErrMsg] = useState("");

  const [cardLoading, setCardLoading] = useState(true);
  const [cardInfo, setCardInfo] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [reload, setReload] = useState(false);
  // const [sendingEmail, setSendingStatus] = useState(false);

  const [emailSentSuccess, setEmailSentSuccessState] = useState(false);
  const [emailSentFail, setEmailSentFailState] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [cancelToastOpen, setCancelToastOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [quoteList, setQuoteList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [generateQuoteLoading, setGenerateQuoteLoading] = useState(false);
  const [isAcceptUUCargoQuote, setIsAcceptUUCargoQuote] = useState(false);

  // const [pickupServiceOption, setPickupServiceOption] = useState(ADDRESS_TYPE_RESIDENTIAL);
  // const [deliverServiceOption, setDeliverServiceOption] = useState(ADDRESS_TYPE_RESIDENTIAL);
  // const [pickupDate, setPickupDate] = useState(null);

  useEffect(() => {
    console.log("pickup details: ", pickUpDetails);
  }, [pickUpDetails])

  useEffect(() => {
    console.log("pallets items: ", shippingItems);
  }, [shippingItems]);


  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleCancelToastClick = () => {
    setCancelToastOpen(true);
  };

  const handleCancelToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCancelToastOpen(false);
  };

  const handleCancelDialogOpen = () => {
    setCancelDialogOpen(true);
  };

  const handleCancelDialogClose = () => {
    setCancelDialogOpen(false);
  };

  // Error indication to check the fee numbers when there are more than two packages
  const [extraFeeError, setExtraFeeError] = useState({
    pickupDock: false,
    deliverDock: false,
    appointment: false,
  });

  const [orderAddons, setOrderAddons] = useState({
    insurance: false,
    insuranceFee: null,
    pickupDock: null,
    pickupDockFee: "0",
    fixPickupDockFee: null,
    pickupTime: false,
    pickupTimeStart: "09:00",
    pickupTimeEnd: "12:00",
    deliverDock: null,
    deliverDockFee: "0",
    fixDeliverDockFee: null,
    deliverTime: false,
    deliverTimeStart: "09:00",
    deliverTimeEnd: "12:00",
    deliveryAppointment: false,
    appointmentFee: "0",
    appoinmentPhone: pickUpDetails.deliver.phone,
    invoice: false,
  });

  const [fullName, setFullName] = useState(`${firstname} ${lastname}`);
  const [inputEmail, setInputEmail] = useState(userEmail);
  const [phone, setPhone] = useState(userPhone || "");

  // const [personalEmtpy, setPersonalEmpty] = useState(false);

  const history = useHistory();
  // const location = useLocation();

  useEffect(() => {
    console.log("Pick up Details:", pickUpDetails);
  }, [pickUpDetails])

  const refreshList = useCallback(async() => {
    if (requestId) {
      const { data: quoteList } = await axios.get(`${PARTNER_URI}/partnerPallets/getQuotesListForShippingQuoteRequest/${requestId}`);
      console.log("Quotes List: ", quoteList);
      setQuoteList(quoteList);
      const isAcceptUUCargoQuoteRequest = await axios.get(`${PARTNER_URI}/partnerPallets/checkIsRequestAcceptUUCargoQuote/${requestId}`);
      setIsAcceptUUCargoQuote(isAcceptUUCargoQuoteRequest.data.result);

      // // If one of the quote is accept, set isAcceptQuote, only Name and Phone can be edited.
      // for (const oneQuote of quoteList) {
      //   if (oneQuote.quote_status_name === "Accepted") {
      //     setIsAcceptQuote(true);
      //   }
      // }
      const { data: companyList } = await axios.get(`${PARTNER_URI}/partnerPallets/getShippingCompanyList`);
      console.log("Company List: ", companyList);
      setCompanyList(companyList);
    }
  }, [requestId])

  useEffect(() => {
    (async () => {
      await refreshList();
    })();

  }, [refreshList]);


  const handlePlaceOrder = async (e) => {
    handleConfirmDialogOpen();
  }

  const handleCancelOrder = async (e) => {
    handleCancelDialogOpen();
  }

  const regenerateUUCargoQuote = async (e) => {
    try {
      e.preventDefault();
      const requestBody = {
        partnerId: id,
        requestId
      }
      setGenerateQuoteLoading(true);
      const { data } = await axios.post(`${PARTNER_URI}/partnerPallets/autoGenerateQuoteByUUCargo`, requestBody, {
        headers: {
          Authorization: `Bearer ${getAccessToken("access_token")}`
        }
      });
      console.log(data);
    } catch (e) {
      console.log(e);
    } finally {
      setGenerateQuoteLoading(false);
      refreshList();
    }
  }

  const confirmCancelOrder = async (e) => {
    e.preventDefault();
    const requestBody = {
      partnerId: id,
      requestId
    }
    try {
      setCancelOrderLoading(true);
      const { data } = await axios.post(`${PARTNER_URI}/partnerPallets/cancelShippingQuoteRequest`, requestBody, {
        headers: {
          Authorization: `Bearer ${getAccessToken("access_token")}`
        }
      });
      console.log(data);
      setErrorMessage("");
      handleCancelToastClick();
      handleCancelDialogClose();
      // Go Back to home page after 2 second
      // setTimeout(() => {
      //   history.push("/home");
      //
      // }, 2000);
    } catch (e) {
      setErrorMessage(e?.response?.data?.errorMessage || "Error!");
      console.log(e);
    } finally {
      setCancelOrderLoading(false);
    }
  }

  const confirmPlaceOrder = async (e) => {
    e.preventDefault();
    const requestBody = {
      partnerId: id,
      pickupAddressType: pickUpDetails.pickUp.service,
      pickupAddress: pickUpDetails.pickUp.address,
      pickupCity: pickUpDetails.pickUp.city,
      pickupProvince: pickUpDetails.pickUp.country === "US" ? getUSProvinceCodeByName(pickUpDetails.pickUp.province) : getProvinceCode(pickUpDetails.pickUp.province),
      pickupCountry: pickUpDetails.pickUp.country,
      pickupPostalCode: pickUpDetails.pickUp.zipCode.replace(/\s*/g, ""),
      pickupPhone: pickUpDetails.pickUp.phone,
      senderName: pickUpDetails.pickUp.name,
      deliverAddress: pickUpDetails.deliver.address,
      deliverCity: pickUpDetails.deliver.city,
      deliverProvince: pickUpDetails.deliver.country === "US" ? getUSProvinceCodeByName(pickUpDetails.deliver.province) : getProvinceCode(pickUpDetails.deliver.province),
      deliverCountry: pickUpDetails.deliver.country,
      deliverPostalCode: pickUpDetails.deliver.zipCode.replace(/\s*/g, ""),
      deliverName: pickUpDetails.deliver.name,
      deliverPhone: pickUpDetails.deliver.phone,
      deliverAddressType: pickUpDetails.deliver.service,
      schedulePickupDate: pickUpDetails.schedulePickupDate ? pickUpDetails.schedulePickupDate?.toDate() : null,
      packageData: shippingItems.map(v => {
        return {
          shipmentTypeId: v.packagingType,
          length: v.length,
          height: v.height,
          width: v.width,
          quantity: v.looseUnit,
          weight: v.weight,
          description: v?.description,
          lengthUnit: v.lengthUnit.toUpperCase(),
          weightUnit: v.weightUnit.toUpperCase()
        }
      })
    }
    console.log(requestBody);
    try {
      setPlaceOrderLoading(true);
      const { data } = await axios.post(`${PARTNER_URI}/partnerPallets/createShippingQuoteRequest`, requestBody, {
        headers: {
          Authorization: `Bearer ${getAccessToken("access_token")}`
        }
      });
      console.log(data);
      setErrorMessage("");
      handleToastClick();
      handleConfirmDialogClose();
      // Go Back to home page after 2 second
      setTimeout(() => {
        history.push("/home");

      }, 2000);
    } catch (e) {
      setErrorMessage(e?.response?.data?.errorMessage || "Error!");
      console.log(e);
    } finally {
      setPlaceOrderLoading(false);
    }
  }

  const confirmSaveOrder = async (e) => {
    e.preventDefault();
    const requestBody = {
      partnerId: id,
      requestId,
      pickupAddressType: pickUpDetails.pickUp.service,
      pickupAddress: pickUpDetails.pickUp.address,
      pickupCity: pickUpDetails.pickUp.city,
      pickupProvince: pickUpDetails.pickUp.country === "US" ? getUSProvinceCodeByName(pickUpDetails.pickUp.province) : getProvinceCode(pickUpDetails.pickUp.province),
      pickupCountry: pickUpDetails.pickUp.country,
      pickupPostalCode: pickUpDetails.pickUp.zipCode.replace(/\s*/g, ""),
      pickupPhone: pickUpDetails.pickUp.phone,
      senderName: pickUpDetails.pickUp.name,
      deliverAddress: pickUpDetails.deliver.address,
      deliverCity: pickUpDetails.deliver.city,
      deliverProvince: pickUpDetails.deliver.country === "US" ? getUSProvinceCodeByName(pickUpDetails.deliver.province) : getProvinceCode(pickUpDetails.deliver.province),
      deliverCountry: pickUpDetails.deliver.country,
      deliverPostalCode: pickUpDetails.deliver.zipCode.replace(/\s*/g, ""),
      deliverName: pickUpDetails.deliver.name,
      deliverPhone: pickUpDetails.deliver.phone,
      deliverAddressType: pickUpDetails.deliver.service,
      schedulePickupDate: pickUpDetails.schedulePickupDate ? pickUpDetails.schedulePickupDate?.toDate() : null,
      packageData: shippingItems.map(v => {
        return {
          packageId: v.packageID,
          shipmentTypeId: v.packagingType,
          length: v.length,
          height: v.height,
          width: v.width,
          quantity: v.looseUnit,
          weight: v.weight,
          description: v?.description,
          lengthUnit: v.lengthUnit.toUpperCase(),
          weightUnit: v.weightUnit.toUpperCase()
        }
      })
    }
    console.log(requestBody);
    try {
      setPlaceOrderLoading(true);
      const { data } = await axios.post(`${PARTNER_URI}/partnerPallets/editShippingQuoteByPartner`, requestBody, {
        headers: {
          Authorization: `Bearer ${getAccessToken("access_token")}`
        }
      });
      console.log(data);
      setErrorMessage("");
      handleToastClick();
      handleConfirmDialogClose();
      // Go Back to home page after 2 second
      // setTimeout(() => {
      //   history.push("/home");
      //
      // }, 2000);
    } catch (e) {
      setErrorMessage(e?.response?.data?.errorMessage || "Error!");
      console.log(e);
    } finally {
      setPlaceOrderLoading(false);
    }
  }

  useEffect(() => {
    const moreThanOne = moreThanOnePallet(pallets);
    if (numberExceed(orderAddons.pickupDockFee, 0) && moreThanOne) {
      setExtraFeeError((prev) => {
        return {
          ...prev,
          pickupDock: true,
        };
      });
    } else
      setExtraFeeError((prev) => {
        return {
          ...prev,
          pickupDock: false,
        };
      });

    if (numberExceed(orderAddons.deliverDockFee, 0) && moreThanOne) {
      setExtraFeeError((prev) => {
        return {
          ...prev,
          deliverDock: true,
        };
      });
    } else
      setExtraFeeError((prev) => {
        return {
          ...prev,
          deliverDock: false,
        };
      });

    if (numberExceed(orderAddons.appointmentFee, 0) && moreThanOne) {
      setExtraFeeError((prev) => {
        return {
          ...prev,
          appointment: true,
        };
      });
    } else
      setExtraFeeError((prev) => {
        return {
          ...prev,
          appointment: false,
        };
      });
  }, [orderAddons, pallets, setExtraFeeError]);

  useEffect(() => {
    const postData = {
      google_map_distance: distance?.replace(/,/g, "") || "",
      pickup_area: `${pickUpDetails.pickUp.city}, ${pickUpDetails.pickUp.province}`,
      deliver_area: `${pickUpDetails.deliver.city}, ${pickUpDetails.deliver.province}`,
      goods: pallets.map((pallet) => {
        return {
          shipment_type_id: pallet.shipmentType,
          quantity:
            pallet.shipmentType === "ftruck" ||
            pallet.shipmentType === "ftrailer"
              ? "1"
              : pallet.quantity?.toString(),
        };
      }),
    };

    // axios({
    //   method: "post",
    //   url: BACKEND_PRICE_ESTIMATE_API,
    //   data: JSON.stringify(postData),
    //   headers: {
    //     ...getHeaders(),
    //     "Content-Type": "application/json",
    //     "X-Requested-With": "XMLHttpRequest",
    //   },
    // })
    //   .then((response) => {
    //     setRouteError(false);
    //     setFetchingPriceState(false);
    //     setShippingPrice(response.data || {});
    //   })
    //   .catch((err) => {
    //     if (
    //       err.response?.data?.code !== "2005" &&
    //       err.response?.data?.code !== "2000"
    //     ) {
    //       errHandling(err, setPriceErrMsg);
    //     }
    //     setFetchingPriceState(false);
    //   });
  }, []);

  // Fetches data from backend/api/me to display userprofile data
  // useEffect(() => {
  //   if (cardStatus === "active") {
  //     axios
  //       .get(LIST_CREDIT_CARD_API, { headers: getHeaders() })
  //       .then((response) => {
  //         setCardLoading(false);
  //         setCardInfo(response.data[0]);
  //         setReload(false);
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //         errHandling(err, setCardErrMsg);
  //         setCardLoading(false);
  //         setReload(false);
  //       });
  //   }
  // }, [cardStatus]);

  // Fetch the fix dock usage fee
  // useEffect(() => {
  //   if (
  //     orderAddons.pickupDock === "No" &&
  //     orderAddons.fixPickupDockFee === null
  //   ) {
  //     axios
  //       .get(BACKEND_ADDON_INFO_API("1"), { headers: getHeaders() })
  //       .then((response) => {
  //         // setCardLoading(false);
  //         // setCardInfo(response.data[0]);
  //         setOrderAddons(
  //           produce((draft) => {
  //             draft.fixPickupDockFee = response.data.value;
  //           })
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //         // errHandling(err, setCardErrMsg);
  //         // setCardLoading(false);
  //       });
  //   }
  // }, [orderAddons.pickupDock, orderAddons.fixPickupDockFee]);

  // Fetch the fix dock usage fee
  // useEffect(() => {
  //   if (
  //     orderAddons.deliverDock === "No" &&
  //     orderAddons.fixDeliverDockFee === null
  //   ) {
  //     axios
  //       .get(BACKEND_ADDON_INFO_API("2"), { headers: getHeaders() })
  //       .then((response) => {
  //         // setCardLoading(false);
  //         // setCardInfo(response.data[0]);
  //         setOrderAddons(
  //           produce((draft) => {
  //             draft.fixDeliverDockFee = response.data.value;
  //           })
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //         // errHandling(err, setCardErrMsg);
  //         // setCardLoading(false);
  //       });
  //   }
  // }, [orderAddons.deliverDock, orderAddons.fixDeliverDockFee]);

  // useEffect(() => {
  //   if (cardStatus === "active" && reload) {
  //     axios
  //       .get(LIST_CREDIT_CARD_API, { headers: getHeaders() })
  //       .then((response) => {
  //         setCardLoading(false);
  //         setCardInfo(response.data[0]);
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //         errHandling(err, setCardErrMsg);
  //         setCardLoading(false);
  //       });
  //   }
  // }, [cardStatus, reload]);

  const classes = useStyles();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const [pallets, setPallets] = useState(shippingItems)
  // const [pickUpDetails, setPickUpDetails] = useState(initPickUpDetails)

  // Change the toggle bottom
  const handleItemChange = (fieldName) => (e) => {
    e.preventDefault();
    let value = e.target.value;
    if (
      fieldName === "insurance" ||
      fieldName === "pickupTime" ||
      fieldName === "deliverTime" ||
      fieldName === "deliveryAppointment" ||
      fieldName === "invoice"
    ) {
      value = e.target.checked;
    }

    setOrderAddons(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };

  const example = (data) => (
      <img
          src={`data:image/jpeg;base64,${data}`}
          alt="label"
          width={700}
          height="auto"
      />
  );

  // const handleAddInsurance = (e) => {
  //   handleItemChange("insurance")(e);
  //   if (e.target.checked)
  //     axios
  //       .get(BACKEND_ADDON_INFO_API("10"), { headers: getHeaders() })
  //       .then((response) => {
  //         // setCardLoading(false);
  //         setOrderAddons(
  //           produce((draft) => {
  //             draft.insuranceFee = response.data.value;
  //           })
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //         // errHandling(err, setCardErrMsg);
  //         // setCardLoading(false);
  //       });
  // };

  // Checker for the input address
  const getEmptyStatus = (_formatData) => {
    const { pickUp, deliver } = _formatData;
    const pickUpEmpty =
      // pickUp.zipCode.trim() === "" ||
      pickUp.address.trim() === "" ||
      pickUp.city.trim() === "" ||
      pickUp.province.trim() === "";
    const deliverEmpty =
      // deliver.zipCode.trim() === "" ||
      deliver.address.trim() === "" ||
      deliver.city.trim() === "" ||
      deliver.province.trim() === "";
    if (pickUpEmpty || deliverEmpty) return true;
  };

  const getPickUpEmptyStatus = (_formatData) => {
    const { pickUp } = _formatData;
    const pickUpEmpty =
      // pickUp.zipCode.trim() === "" ||
      pickUp.address.trim() === "" ||
      pickUp.city.trim() === "" ||
      pickUp.province.trim() === "";
    return pickUpEmpty;
  };

  const getDeliverEmptyStatus = (_formatData) => {
    const { deliver } = _formatData;
    const deliverEmpty =
      // deliver.zipCode.trim() === "" ||
      deliver.address.trim() === "" ||
      deliver.city.trim() === "" ||
      deliver.province.trim() === "";
    return deliverEmpty;
  };

  const saveAndReturn = () => {
    localStorage.setItem(
      "shippingData",
      JSON.stringify({ pickUpDetails: pickUpDetails, shippingItems: pallets })
    );
    dispatch(clearInitOrder());
    redirectHome(history);
  };

  const handleChange = (event) => {
    setExtraMsg(event.target.value);
  };

  const getDockFee = (dock, dockFee, fixDockFee) => {
    if (dock === null) return null;
    if (dock === "Yes") return dockFee || "0";
    return fixDockFee;
  };

  const moreThanOnePallet = (_pallets) => {
    return _pallets.length > 1;
  };

  return (
    <Box width="100%">

      {/*Pop-up Toast*/}
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={toastOpen}
          onClose={handleToastClose}
          autoHideDuration={3000}
          message="Cancel Order">
        {(() => {
          if (errorMessage !== "") {
            return (
                <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                  Place Order Failed!
                  <hr />
                  Error: {errorMessage}
                </Alert>
            )
          }
          return (
              <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                { !edit ? "Place Order Success!" : "Save Success!"}
              </Alert>
          )
        })()}

      </Snackbar>
      {/*Pop-up Toast*/}

      {/*Pop-up Toast For Cancel */}
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={cancelToastOpen}
          onClose={handleCancelToastClose}
          autoHideDuration={3000}
          message="Cancel Order">
        {(() => {
          if (errorMessage !== "") {
            return (
                <Alert onClose={handleCancelToastClose} severity="error" sx={{ width: '100%' }}>
                  Cancel Request Failed!
                  <hr />
                  Error: {errorMessage}
                </Alert>
            )
          }
          return (
              <Alert onClose={handleCancelToastClose} severity="success" sx={{ width: '100%' }}>
                Cancel Request Success!
              </Alert>
          )
        })()}

      </Snackbar>
      {/*Pop-up Toast*/}


      {/*Confirm Place Order Dialog*/}
      <Dialog
          open={confirmDialogOpen}
          onClose={handleConfirmDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Place Order
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              edit ? "This process will save your Shipping Order. Are you sure to continue?"
                  : "This process will place your Shipping Order. Are you sure to continue?"
            }
            <hr />
            {
              edit ? <>Click <b>Confirm</b> to save your order</>
                  : <>Click <b>Confirm</b> to place an order</>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button" >No</Button>
          <Button onClick={edit ? confirmSaveOrder : confirmPlaceOrder} variant="outlined" type="button" disabled={placeOrderLoading} >
            {placeOrderLoading && <CircularProgress size={14} />}
            {!placeOrderLoading && "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      {/*Confirm Place Order Dialog*/}

      {/* Cancel Place Order Dialog*/}
      <Dialog
          open={cancelDialogOpen}
          onClose={handleCancelDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Cancel Request
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This process will cancel your Shipping Request. Are you sure to continue?
            <hr />
            Click <b>Confirm</b> to cancel your order
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialogClose} variant="outlined" autoFocus type="button" >No</Button>
          <Button onClick={confirmCancelOrder} variant="outlined" type="button" disabled={cancelOrderLoading} >
            {cancelOrderLoading && <CircularProgress size={14} />}
            {!cancelOrderLoading && "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Cancel Place Order Dialog*/}


      <Box
        className={matches ? classes.smRootContainer : classes.rootContainer}
        width="100%"
      >
        <h2 className="orange-title">Shipping Summary:</h2>
        <ReviewOrderHeader
          pickupEmpty={getPickUpEmptyStatus(pickUpDetails)}
          deliverEmpty={getDeliverEmptyStatus(pickUpDetails)}
          pickUpDetails={pickUpDetails}
          setPickupDetails={setPickupDetails}
          toFirst={toFirst}
          toFourth={toFourth}
          pallets={pallets}
          accountBaseCombinedName={accountBaseCombinedName}
          priceText={priceText}
          requestStatus={pickUpDetails.status}
          edit={edit}
          isAcceptQuote={isAcceptQuote}
        />
        <Box flex="45%" width="100%" >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Schedule Pickup Date"
                value={pickUpDetails.schedulePickupDate}
                disabled={pickUpDetails.status === -1 || isAcceptQuote}
                onChange={(value) => {
                  setPickupDetails(prev => {
                    return {
                      ...prev,
                      schedulePickupDate: value
                    }
                  })
                }}
                renderInput={(params) => <TextField {...params} id="outlined-basic" label="Schedule Pickup Date (Optional) mm-dd-yyyyy" variant="outlined" fullWidth />}
            />
          </LocalizationProvider>
        </Box>

        <br />
        <>
          <Box
              display="flex"
              justifyContent="space-between"
              mt={2}
              mb={1}
              alignItems="flex-start"
          >
            <Box fontSize="1rem" mr={5} visibility="hidden">
              <Button onClick={saveAndReturn} className={classes.textButton}>
                Save & <br />
                Go to home
              </Button>
            </Box>
            {
              (() => {
                if (!(pickUpDetails.status === -1)) {
                  return (
                      edit ? (
                          <>
                            <Box pt={1} display="flex" sx={{ gap: "2rem" }}>
                              <LoadingButton
                                  color="primary"
                                  variant="contained"
                                  className={matches ? classes.smButton : classes.button}
                                  onClick={regenerateUUCargoQuote}
                                  loading={generateQuoteLoading}
                                  disabled={isAcceptUUCargoQuote}
                                  style={{
                                    textTransform: "unset",
                                    fontSize: "1rem",
                                    height: "3rem",
                                  }}
                              >Re-generate Quotes</LoadingButton>
                              <Button
                                  color="primary"
                                  variant="contained"
                                  className={matches ? classes.smButton : classes.button}
                                  onClick={handleCancelOrder}
                                  style={{
                                    textTransform: "unset",
                                    fontSize: "1rem",
                                    height: "3rem",
                                  }}
                              >Cancel</Button>
                              <Button
                                  color="primary"
                                  variant="contained"
                                  className={matches ? classes.smButton : classes.button}
                                  onClick={handlePlaceOrder}
                                  style={{
                                    textTransform: "unset",
                                    fontSize: "1rem",
                                    height: "3rem",
                                  }}
                              >
                                {creatingOrder ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    "Save"
                                )}
                              </Button>
                              {creatingOrder && <Box textAlign="center">In Progress...</Box>}
                            </Box>
                          </>
                      )
                      :
                      <>
                        <Box pt={1} display="flex" sx={{ gap: "2rem" }} width="100%" justifyContent="center" alignItems="center">
                          <Button
                              color="primary"
                              variant="contained"
                              className={matches ? classes.smButton : classes.button}
                              onClick={handlePlaceOrder}
                              style={{
                                textTransform: "unset",
                                fontSize: "1rem",
                                height: "3rem",
                              }}
                          >
                            {creatingOrder ? (
                                <CircularProgress size={24} />
                            ) : (
                                "Submit"
                            )}
                          </Button>
                          {creatingOrder && <Box textAlign="center">In Progress...</Box>}
                        </Box>
                      </>
                  )
                }
              })()
            }
          </Box>
        </>
        <hr />
        {
          edit && quoteList?.length > 0 && (
              <>
                <br />
                <Box textAlign="center">
                  <h2>Quotes For This Request</h2>
                </Box>
                <Box width="100%">
                  <table className={classes.table}>
                    <thead>
                    <tr>
                      <th className={classes.idTH}>ID</th>
                      <th className={classes.idTH}>Company Name</th>
                      <th className={classes.idTH}>Standard Amount</th>
                      <th className={classes.idTH}>Service Fee</th>
                      <th className={classes.idTH}>Additional Fee</th>
                      <th className={classes.idTH}>Tax</th>
                      <th className={classes.idTH}>Discount</th>
                      <th className={classes.idTH}>Amount</th>
                      <th className={classes.idTH}>Expired Date</th>
                      <th className={classes.idTH}>Quotes URI</th>
                      <th className={classes.idTH}>Status</th>
                    </tr>
                    </thead>
                    <tbody className={classes.tbody}>
                    {quoteList.map((v, index) => {
                      return <QuoteTable key={index}
                                         quotesURI={v.origi_quotes_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.origi_quotes_uri}` : ""}
                                         grossAmount={v.total_amount_gross}
                                         amount={v.total_amount}
                                         statusName={v.quote_status_name}
                                         quoteId={v.quote_id}
                                         expiredDate={v.expired_date}
                                         currencyCode={v.currency_code}
                                         businessName={v.business_name}
                                         standardAmount={v.amount_standard_exclude_tax}
                                         tax={v.taxes}
                                         serviceFee={v.service_fee}
                                         additionalFee={v.additional_fee}
                                         finalAmount={v.final_amount} remarkNote={v.remark_notes}
                                         status={v.quote_status}
                                         refreshList={refreshList}
                                         discount={v.discount}
                                         totalAmount={v.total_amount}
                      />;
                    })}
                    </tbody>
                  </table>

                </Box>
              </>
            )
        }
        {/*<br />*/}
        {/*<hr />*/}
        {/*<br />*/}
        {/*<Box width="100%">*/}
        {/*  <AddQuote companyList={companyList} />*/}
        {/*</Box>*/}



        <Box>
          <Collapse in={orderAddons.insurance}>
            <Box
                display="flex"
                ml={2}
                mr={2}
                mb={2}
                mt={1}
                justifyContent="space-between"
                alignItems="center"
            >
              {/* <Box>Residential fee</Box> */}
              <Box>Insurance fee</Box>
              <Box component="span" fontSize="1.2rem">
                {`C$ 
                ${orderAddons.insuranceFee ? orderAddons.insuranceFee : ""}`}
              </Box>
              {/* <Box component="span" fontSize="1.2rem">
                  C$ 2.00
                </Box> */}
            </Box>
          </Collapse>
        </Box>
        {/*<Box*/}
        {/*  pt={1}*/}
        {/*  className={*/}
        {/*    orderAddons.deliverDock === "Yes" ||*/}
        {/*    orderAddons.deliverDock === "No"*/}
        {/*      ? classes.feeWrapper*/}
        {/*      : ""*/}
        {/*  }*/}
        {/*>*/}
        {/*  <Box*/}
        {/*    component="span"*/}
        {/*    color="rgba(0,0,0,0.8)"*/}
        {/*    className="bodytext"*/}
        {/*    flexBasis="200px"*/}
        {/*  >*/}
        {/*    <img*/}
        {/*      src={LoadingDock}*/}
        {/*      alt="Loading Dock"*/}
        {/*      width="40px"*/}
        {/*      align="bottom"*/}
        {/*      style={{*/}
        {/*        backgroundColor: "seagreen",*/}
        {/*      }}*/}
        {/*    />{" "}*/}
        {/*    Is there a loading dock at the deliver place *?{" "}*/}
        {/*    <a*/}
        {/*      target="_blank"*/}
        {/*      rel="noopener noreferrer"*/}
        {/*      href="https://www.uucargo.ca/support/"*/}
        {/*    >*/}
        {/*      <HelpIcon style={{ color: "grey" }} />*/}
        {/*    </a>*/}
        {/*  </Box>*/}
        {/*  <Box display="flex" justifyContent="end" mt={1}>*/}
        {/*    <RadioGroup*/}
        {/*      row*/}
        {/*      aria-label="dock"*/}
        {/*      name="dock"*/}
        {/*      value={orderAddons.deliverDock}*/}
        {/*      onChange={handleItemChange("deliverDock")}*/}
        {/*      className={orderAddons.deliverDock ? "" : classes.radioWrapper}*/}
        {/*    >*/}
        {/*      <FormControlLabel*/}
        {/*        value="No"*/}
        {/*        control={<Radio color="primary" />}*/}
        {/*        label="No"*/}
        {/*      />*/}
        {/*      <FormControlLabel*/}
        {/*        value="Yes"*/}
        {/*        control={<Radio color="primary" />}*/}
        {/*        label="Yes"*/}
        {/*      />*/}
        {/*    </RadioGroup>*/}
        {/*  </Box>*/}
        {/*  <Collapse*/}
        {/*    in={*/}
        {/*      orderAddons.deliverDock === "Yes" ||*/}
        {/*      orderAddons.deliverDock === "No"*/}
        {/*    }*/}
        {/*  >*/}
        {/*    {orderAddons.deliverDock === "Yes" ? (*/}
        {/*      <>*/}
        {/*        <Box*/}
        {/*          display="flex"*/}
        {/*          ml={2}*/}
        {/*          mr={2}*/}
        {/*          mb={2}*/}
        {/*          justifyContent="space-between"*/}
        {/*          alignItems="center"*/}
        {/*        >*/}
        {/*          <Box mb={0.5}>Usage fee</Box>*/}
        {/*          <Box width="10rem">*/}
        {/*            <CustomInput*/}
        {/*              value={orderAddons.deliverDockFee}*/}
        {/*              onChange={handleItemChange("deliverDockFee")}*/}
        {/*              type="currency"*/}
        {/*              error={extraFeeError.deliverDock}*/}
        {/*            />*/}
        {/*          </Box>*/}
        {/*        </Box>*/}
        {/*        <Box*/}
        {/*          pl={2}*/}
        {/*          pr={1}*/}
        {/*          color="red"*/}
        {/*          display={extraFeeError.deliverDock ? "block" : "none"}*/}
        {/*        >*/}
        {/*          Usage fee cannot be none-zero if the order has more than one*/}
        {/*          package.*/}
        {/*        </Box>*/}
        {/*      </>*/}
        {/*    ) : (*/}
        {/*      <Box*/}
        {/*        display="flex"*/}
        {/*        ml={2}*/}
        {/*        mr={2}*/}
        {/*        mb={2}*/}
        {/*        mt={1}*/}
        {/*        justifyContent="space-between"*/}
        {/*        alignItems="center"*/}
        {/*      >*/}
        {/*        <Box>Loading dock usage fee</Box>*/}
        {/*        <Box component="span" fontSize="1.2rem">{`C$ ${*/}
        {/*          orderAddons.fixDeliverDockFee*/}
        {/*            ? orderAddons.fixDeliverDockFee*/}
        {/*            : ""*/}
        {/*        }`}</Box>*/}
        {/*      </Box>*/}
        {/*    )}*/}
        {/*  </Collapse>*/}
        {/*</Box>*/}

        <Box pt="1vh" width="100%">
          {getEmptyStatus(pickUpDetails) ? (
            <Alert severity="error" classes={{ root: classes.alertRoot }}>
              Please enter all the address information.
            </Alert>
          ) : (
            ""
          )}
        </Box>

        <Dialog
          maxWidth="sm"
          fullWidth
          open={dialogOpen}
          onClose={() => {
            setReload(true);
            setDialogOpen(false);
          }}
        >
          <div className={classes.dialogTitle}>
            <Box fontSize="1.5rem"></Box>
            <IconButton
              // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
              style={{ fill: "green" }}
              className={classes.closeBtn}
              onClick={() => {
                setReload(true);
                setDialogOpen(false);
              }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          {/*<AddCreditCardPage addCard={true} popUp={true} />*/}
        </Dialog>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={emailSentSuccess}
        onClose={() => setEmailSentSuccessState(false)}
        message="Send email successfully"
        autoHideDuration={3000}
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={emailSentFail}
        onClose={() => setEmailSentFailState(false)}
        message="Failed to send email"
        autoHideDuration={3000}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rootContainer: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: "0 auto",
    width: "40vw",
    minWidth: "1300px",
    paddingLeft: "2vw",
    paddingRight: "2vw",
    textAlign: "left"
  },
  smRootContainer: {
    width: "100%",
    paddingLeft: "3vw",
    paddingRight: "3vw",
    textAlign: "left"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: 5,
    fontSize: "1.2rem",
    backgroundColor: "#ffa726",
    width: "14rem",
  },
  smButton: {
    borderRadius: 5,
    fontSize: "1rem",
    backgroundColor: "#ffa726",
    width: "12rem",
  },
  textfield: {
    width: "100%",
    backgroundColor: "white",
    // border: "1px solid white",
  },
  dockerFee: {
    width: "4rem",
  },
  feeWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    // backgroundColor: "#FEEAAE",
    backgroundColor: "white",
  },

  infoWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    backgroundColor: "#FEEAAE",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  summaryBodyText: {
    fontSize: "13pt",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  textButton: {
    color: "blue",
  },
  bodyText: {
    fontSize: "1rem",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  timePicker: {
    width: 160,
  },
  divider: {
    borderBottom: "2px solid rgba(0,0,0,0.3)",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  radioWrapper: {
    border: "1px solid red",
    paddingLeft: theme.spacing(1),
    borderRadius: "5px",
  },
  table: {
    width: "100%",
    // "border-spacing": "2rem 1rem"
  },
  th: {
    fontSize: "18px"
  },
  idTH: {
    fontSize: "16px"
  }
}));

ReviewAndConfirm.propTypes = {
  toFirst: PropTypes.func.isRequired,
  toFourth: PropTypes.func.isRequired,
  toConfirm: PropTypes.func.isRequired,
  pickUpDetails: PropTypes.object,
  pallets: PropTypes.array,
};

// const mapStateToProps = state => ({
//     shippingItems: state.shippingItems
// })

// export default connect(mapStateToProps, {getShipmentItems, getPickUpDetails})(ReviewAndConfirm);
export default ReviewAndConfirm;
