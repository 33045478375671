import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import {useMediaQuery} from "@mui/material";
import qrCodeImage from "../../images/uucargo-paypal-qrcode.png";
import wechatPay from "../../images/wechatPay.svg";
import Typography from "@mui/material/Typography";
import {calTransactionFee} from "../../utils/Helper";
import intl from "react-intl-universal";

const styles = {
    BillingPaymentPayPalRoot: {
        border: '1px solid #000000',
        display: 'flex',
        borderRadius: '10px',
        width: '100%'
    }
}

export const BillingPaymentPayPal = ({totalAmount, selectInvoice}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    console.log('select invoice', selectInvoice);

    return (
        <Box sx={styles.BillingPaymentPayPalRoot}>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <Box sx=
                             {{
                                 display: 'flex',
                                 padding: '5px 15px',
                                 justifyContent: isMobile ? 'center' : 'flex-start',
                                 alignItems: 'center',
                                 height: '100%'
                             }}
                    >
                        <img src={qrCodeImage} alt="qrCodeLogo" width={140}/>
                    </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src='https://storage.googleapis.com/uucargo-resource/image/icon/paypal.svg'
                                 alt="paypalLogo" width={35}/>
                            <Typography style={{fontSize: '14px'}}>
                                {intl.get('BILLING_CENTER.PAYMENT.PAY_PAL.PAY_PAL')}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                {intl.getHTML('BILLING_CENTER.PAYMENT.PAY_PAL.STEP_1', {totalAmount: (totalAmount + +calTransactionFee(totalAmount)).toFixed(2)})}
                            </Typography>
                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                {intl.get('BILLING_CENTER.PAYMENT.PAY_PAL.MESSAGE')}
                            </Typography>
                            {
                                selectInvoice?.map((each, index) => {
                                    return (
                                        <Typography key={index} style={{textAlign: 'left', fontSize: '14px'}}>
                                            {each?.invoiceRefNumber}
                                        </Typography>
                                    )
                                })
                            }
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                {intl.getHTML('BILLING_CENTER.PAYMENT.PAY_PAL.STEP_2')}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}