import {actionType} from "../../utils/Helper";

const selectTab = tabValue => ({
    type: actionType.ADDRESS_LIST_SELECTED_TAB,
    payload: tabValue
})

const addAddress = (openModel) => ({
    type: actionType.ADDRESS_LIST_ADD,
    payload: openModel
})

const editAddress = (editModel) => ({
    type: actionType.ADDRESS_LIST_EDIT,
    payload: editModel
})

const passAddressInfo = (addressInfo) => ({
    type: actionType.ADDRESS_LIST_PASS_ADDRESS_INFO,
    payload: addressInfo
})

export default {
    selectTab,
    addAddress,
    editAddress,
    passAddressInfo
}