import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {getAccessToken} from "../../../utils/doToken";
import axios from "axios";
import {PARTNER_URI} from "../../../utils/apiUrl";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Divider, Snackbar} from "@mui/material";
import {Fragment} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    PackageOrderDashboardPickupAppointmentRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const PackageOrderDashboardPickupAppointment = () => {

    const history = useHistory();
    const {order_id: orderId} = useParams();

    const [appointmentList, setAppointmentList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getAppointmentList = async () => {
        try {
            const storedToken = getAccessToken("access_token");
            const {data} = await axios.get(`${PARTNER_URI}/packagesPickupAppointment/getPickupAppointmentListByOrderId`, {
                    params: {
                        orderId
                    },
                    headers: {
                        authorization: `Bearer ${storedToken}`,
                    },
                }
            );
            // console.log("appointmentList", data);
            setAppointmentList(data);

        } catch (e) {
            console.log(e);
        }
    }

    const cancelPickup = async (confirm_appoint_no) => {
        console.log("confirmAppointNo:", confirm_appoint_no);
        setIsLoading(true);
        try {
            const storedToken = getAccessToken("access_token");
            const {data, status} = await axios({
                method: "delete",
                url: `${PARTNER_URI}/packagesPickupAppointment/cancelPickupAppointmentPartner`,
                params: {
                    appointmentNumber: confirm_appoint_no,
                },
                headers: {
                    authorization: `Bearer ${storedToken}`,
                },
            });
            if (status === 200) {
                console.log("Success:", data);
                setErrorMessage('');
                setSuccessMessage("Successfully Cancelled Pickup");
                handleSuccessMessage();
                getAppointmentList();
            } else {
                console.log("Error:", data);
                throw new Error();
            }
        } catch (e) {
            setErrorMessage("Failed to Cancel Pickup. Try again another time.");
            handleErrorMessage();
        } finally {
            setIsLoading(false);
        }
    };

    const isPickupDatePassed = (appointDate) => {
        const scheduledPickupDate = new Date(appointDate);
        return scheduledPickupDate < currentDate;
    }

    useEffect(() => {
        getAppointmentList();
    }, []);

    console.log('appointment', appointmentList);

    return (
        <Box sx={style.PackageOrderDashboardPickupAppointmentRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.PICKUP_APPOINTMENT.PICKUP_APPOINTMENT')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '0 20px'}}>
                        {
                            appointmentList?.length > 0 ?
                                appointmentList?.map((element, index) => (
                                    <Grid container spacing={1} key={index}>
                                        <Grid item md={4} xs={6}>
                                            <Box>
                                                <Typography
                                                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                                                    {intl.get('ORDER_DASHBOARD.PICKUP_APPOINTMENT.CONFIRMATION_NO')}
                                                </Typography>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {element?.confirm_appoint_no}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4} xs={6}>
                                            <Box>
                                                <Typography
                                                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                                                    {intl.get('ORDER_DASHBOARD.PICKUP_APPOINTMENT.APPOINTMENT_DATE')}
                                                </Typography>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {element?.appoint_date}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Box>
                                                <Typography
                                                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                                                    {intl.get('ORDER_DASHBOARD.PICKUP_APPOINTMENT.TRACKING_NO')}
                                                </Typography>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {element?.tracking_nos}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Box>
                                                <Typography
                                                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                                                    {intl.get('ORDER_DASHBOARD.PICKUP_APPOINTMENT.PICKUP_ADDRESS')}
                                                </Typography>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {element?.pickup_addr}
                                                </Typography>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {element?.pickup_city}, {element?.pickup_province}
                                                </Typography>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {element?.pickup_country}, {element?.pickup_postal_code}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Box>
                                                <Typography
                                                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                                                    {intl.get('ORDER_DASHBOARD.PICKUP_APPOINTMENT.CONTACT_INFORMATION')}
                                                </Typography>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {element?.pickup_name}
                                                </Typography>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {element?.pickup_tel}
                                                </Typography>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {element?.pickup_email}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-end',
                                                height: '100%'
                                            }}>
                                                {
                                                    element?.status === 1 ?
                                                        <LoadingButton
                                                            variant='outlined'
                                                            sx={{
                                                                border: '2px solid #B2B2B2',
                                                                "&:hover": {
                                                                    border: '2px solid #B2B2B2',
                                                                    filter: 'brightness(0.9)'
                                                                }
                                                            }}
                                                            onClick={() => cancelPickup(element?.confirm_appoint_no)}
                                                            loading={isLoading}
                                                            disabled={isPickupDatePassed(element?.appoint_date)}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    textAlign: 'left',
                                                                    fontSize: '14px',
                                                                    fontWeight: '600',
                                                                    textTransform: 'none',
                                                                    color: isPickupDatePassed(element?.appoint_date) ? '#B2B2B2' : '#B2B2B2'
                                                                }}>
                                                                {intl.get('ORDER_DASHBOARD.PICKUP_APPOINTMENT.CANCEL')}
                                                            </Typography>
                                                        </LoadingButton>
                                                        :
                                                        <Typography
                                                            style={{
                                                                textAlign: 'left',
                                                                fontSize: '14px',
                                                                fontWeight: '600',
                                                                textTransform: 'none',
                                                                color: '#FE0000'
                                                            }}>
                                                            {intl.get('ORDER_DASHBOARD.PICKUP_APPOINTMENT.CANCELLED')}
                                                        </Typography>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                )) :
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px'
                                }}>
                                    <Typography>
                                        {intl.get('ORDER_DASHBOARD.PICKUP_APPOINTMENT.NO_PICKUP')}
                                    </Typography>
                                </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Button
                            variant='outlined'
                            sx={{
                                border: '2px solid #1D8B45',
                                "&:hover": {
                                    border: '2px solid #1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={() => history.push('/schedulepickup')}
                        >
                            <Typography style={{
                                textTransform: 'none',
                                fontSize: '14px',
                                color: '#1D8B45',
                            }}>
                                {intl.get('ORDER_DASHBOARD.PICKUP_APPOINTMENT.SCHEDULE_NOW')}
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}