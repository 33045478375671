import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

export const TransportationClaimTasksDataAndTime = ({taskDetails}) => {
    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Claimed Order Date and Time
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={3} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Pickup Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {taskDetails?.schedule_pickup ? dayjs(taskDetails?.schedule_pickup).format("YYYY-MM-DD") : 'To be determined'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Pickup Time
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {taskDetails?.schedule_pickup ? dayjs(taskDetails?.schedule_pickup).format("HH:mm") : 'To be determined'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Delivery Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {taskDetails?.schedule_deliver ? dayjs(taskDetails?.schedule_deliver).format("YYYY-MM-DD") : 'To be determined'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Delivery Time
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {taskDetails?.schedule_deliver ? dayjs(taskDetails?.schedule_deliver).format("HH:mm") : 'To be determined'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}