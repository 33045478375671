import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {BACKEND_ME_API, BACKEND_GET_ADDRESSBOOK} from "../utils/apiUrl";
import {getHeaders, errHandling} from "../utils/fetchUtils";
import {getPartnerInfoByPartnerId, verifyIsPartner} from "../utils/verifyIsPartner";
import {deleteToken} from "../utils/doToken";

const initialState = {
    role: null,
    isLoading: false,
    email: "",
    activeCard: null,
    firstname: "",
    lastname: "",
    phone: null,
    userType: null,
    dataLoaded: false,
    latlng: {lat: null, lng: null},
    // login: false,
    id: null,
    address: null,
    source: null,
    legalName: null,
    licenseNumber: null,
    expiryDate: null,
    vehicleType: null,
    signIn: false,
    // Address book related param
    addressBook: [],
    addressBookLoaded: false,
    addressBookLoading: false,
    addressBookLoadingErr: "",
    traccarIdentifier: null,
    isPartner: true,

    backendUserId: null,
    isAdmin: false,
    companyName: ""
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUserInfo: (state, {payload}) => {
            state.isLoading = true;
            state.id = payload.id;
            state.role = payload.role;
            state.email = payload.email;
            state.activeCard = payload.activeCard;
            state.firstname = payload.firstname;
            state.lastname = payload.lastname;
            state.phone = payload.phone;
            state.userType = payload.userType;
            state.source = payload.source;
            state.address = payload.address;
            state.dataLoaded = true;
            state.legalName = payload.legalName;
            state.licenseNumber = payload.licenseNumber;
            state.expiryDate = payload.expiryDate;
            state.vehicleType = payload.vehicleType;
            state.traccarIdentifier = payload.traccarIdentifier;
            state.isPartner = payload.isPartner;

            state.backendUserId = payload.backendUserId;
            state.isAdmin = payload?.isAdmin || false;
            state.companyName = payload?.companyName || "";
        },

        addLatlng: (state, {payload}) => {
            state.latlng.lat = payload.lat;
            state.latlng.lng = payload.lng;
        },

        gettingUserInfo: (state) => {
            state.isLoading = true;
        },

        deleteUserInfo: (state) => {
            state.role = null;
            state.isLoading = false;
            state.email = "";
            state.activeCard = null;
            state.name = "";
            state.phone = null;
            state.userType = null;
            state.address = null;
            state.dataLoaded = false;
            state.legalName = null;
            state.licenseNumber = null;
            state.expiryDate = null;
            state.signIn = false;
            state.traccarIdentifier = null;
            state.isPartner = false;
            state.backendUserId = null;
            state.isAdmin = false;
            state.companyName = "";
        },
        doneUserInfoActions: (state) => {
            state.isLoading = false;
        },
        updateUserInfo: (state, {payload}) => {
            state[payload.field] = payload.value;
        },
        loadAddressBook: (state, {payload}) => {
            state.addressBookLoaded = true;
            state.addressBookLoading = false;
            state.addressBook = payload.value;
        },
    },
});

// Extract the action creators object and the reducer
const {actions, reducer} = userSlice;

export const {
    addUserInfo,
    deleteUserInfo,
    gettingUserInfo,
    doneUserInfoActions,
    addLatlng,
    updateUserInfo,
    loadAddressBook,
} = actions;

// Customized function

// Get residents information from API
export const getUserProfile =
    (noReload = false) =>
        async (dispatch) => {
            try {
                !noReload && dispatch(gettingUserInfo());
                const userProfile = await axios.get(BACKEND_ME_API, {
                    headers: getHeaders(),
                });
                console.log("userProfile", userProfile);
                if (userProfile) {
                    const isPartner = await verifyIsPartner();
                    console.log("isPartner: ", isPartner);
                    const partnerInfo = await getPartnerInfoByPartnerId();
                    console.log("partnerInfo", partnerInfo);
                    console.log("Is Admin: ", partnerInfo?.admin_user_id === userProfile.data.id);
                    console.log("company name: ", partnerInfo?.business_name);

                    // todo check
                    const isMovingPartner = partnerInfo?.partner_op_status === 1;
                    if (!isMovingPartner) {
                        window.location.href = 'https://app.uucargo.ca';
                    }

                    dispatch(
                        addUserInfo({
                            id: partnerInfo.partner_id,
                            role: userProfile.data.role,
                            email: userProfile.data.email,
                            activeCard: userProfile.data.card,
                            firstname: userProfile.data.firstname,
                            lastname: userProfile.data.lastname,
                            phone: userProfile.data.phone,
                            userType: userProfile.data.usertype,
                            source: userProfile.data.source,
                            address: userProfile.data.address,
                            legalName: userProfile.data.legal_name,
                            licenseNumber: userProfile.data.license_number,
                            expiryDate: userProfile.data.expiry_date,
                            vehicleType: userProfile.data.vehicle_type,
                            traccarIdentifier: userProfile.data.traccar_identifier,
                            isPartner: isPartner,
                            companyName: partnerInfo.business_name,

                            // For Backend User Profile
                            backendUserId: userProfile.data.id,
                            isAdmin: partnerInfo.admin_user_id === userProfile.data.id

                        })
                    );
                }
                dispatch(doneUserInfoActions());
            } catch (error) {
                // Remove token if error
                deleteToken("access_token");
                dispatch(doneUserInfoActions());
            }
        };

export const userReducer = reducer;

// Export selector
