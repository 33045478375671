import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  Snackbar,
} from "@material-ui/core";




import { useForm, Controller } from "react-hook-form";
// import ShippingListTrackingTable from "./ShippingListTrackingTable";
import SearchShippingTransactionTableItem from "./SearchShippingTransactionTableItem";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import { PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX } from "../../utils/apiUrl";
import { useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { separateTaxes } from "../../utils/calculateTax";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../utils/doToken";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "80%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  listItemWrapper: {
    textAlign: "left",
  },
}));

const SearchShippingTransactions = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const pageSize = 100;

  // const partnerInfo = getPartnerInfo();
  const partnerInfo = useSelector((state) => state.user);
  console.log(partnerInfo);

  const { register, handleSubmit, control } = useForm();
  const [shippingListData, setShippingListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectUPS, setSelectUPS] = useState(false);
  const [selectFedEx, setSelectFedEx] = useState(false);
  const cacheInput = useRef({});
  const [transactionStatus, setTransactionStatus] = useState(1);
  const [dateRange, setDateRange] = useState([
    {
      startDate: dayjs(new Date()).subtract(30, "days").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(30, "days").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  // Download Data State
  const [transactions, setTransactions] = useState([
    [
      "Order ID",
      "Cargo Control Number",
      "Ref Order No",
      "Tran ID",
      "Account Base",
      "Client Number",
      "Product ID",
      "Exclude Tax",
      "Include Tax",
      "GST",
      "HST",
      "QST",
      "Date",
      "Tracking #",
      "Labels",
    ],
  ]);
  const [toastOpen, setToastOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [searchError, setSearchError] = useState(false);

  const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] =
    useState(false);


  const csvLink = useRef();

  const getLabelURL = (pdf, zip) => {
    if (pdf) {
      return pdf;
    }
    if (zip) {
      return zip;
    }
    return "";
  };

  const getTransactionData = async () => {
    try {
      let {
        startOrderId,
        endOrderId,
        refOrderNumber,
        refProductNumber,
        transactionStatus,
        startTransactionDate,
        endTransactionDate,
      } = cacheInput.current;
      let requestURI;
      startTransactionDate = dayjs(startTransactionDate).format("YYYY-MM-DD");
      endTransactionDate = dayjs(endTransactionDate).format("YYYY-MM-DD");
      if (transactionStatus === 2) {
        requestURI = `${PARTNER_URI}/shipping/list/exportAllTransactionsByPartnerId?partner_id=${partnerInfo.id}&startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
        if ((selectUPS && selectFedEx) || (!selectUPS && !selectFedEx)) {
          requestURI = `${PARTNER_URI}/shipping/list/exportAllTransactionsByPartnerId?partner_id=${partnerInfo.id}&startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
        } else if (selectUPS) {
          requestURI = `${PARTNER_URI}/shipping/list/exportAllTransactionsByPartnerId?partner_id=${partnerInfo.id}&startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&account_base=UPS&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
        } else if (selectFedEx) {
          requestURI = `${PARTNER_URI}/shipping/list/exportAllTransactionsByPartnerId?partner_id=${partnerInfo.id}&startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&account_base=FedEx&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
        }
      } else {
        requestURI = `${PARTNER_URI}/shipping/list/exportAllTransactionsByPartnerId?partner_id=${partnerInfo.id}&startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&tranStatus=${transactionStatus}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
        if ((selectUPS && selectFedEx) || (!selectUPS && !selectFedEx)) {
          requestURI = `${PARTNER_URI}/shipping/list/exportAllTransactionsByPartnerId?partner_id=${partnerInfo.id}&startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&tranStatus=${transactionStatus}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
        } else if (selectUPS) {
          requestURI = `${PARTNER_URI}/shipping/list/exportAllTransactionsByPartnerId?partner_id=${partnerInfo.id}&startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&account_base=UPS&tranStatus=${transactionStatus}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
        } else if (selectFedEx) {
          requestURI = `${PARTNER_URI}/shipping/list/exportAllTransactionsByPartnerId?partner_id=${partnerInfo.id}&startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&account_base=FedEx&tranStatus=${transactionStatus}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
        }
      }
      const { data } = await axios.get(requestURI);
      const result = data.result;
      const resultArray = [
        [
          "Order ID",
          "Cargo Control Number",
          "Ref Order No",
          "Tran ID",
          "Account Base",
          "Client Number",
          "Product ID",
          "Exclude Tax",
          "Include Tax",
          "GST",
          "HST",
          "QST",
          "Date",
          "Tracking #",
          "Labels",
        ],
      ];
      for (const column of result) {
        const columnData = [];
        columnData.push(column.order_id);
        columnData.push(column.cargo_control_no);
        columnData.push(column.ref_order_no);
        columnData.push(column.tran_id);
        columnData.push(column.account_base);
        // Client ID
        columnData.push(column.ref_other_no);
        // Product ID
        columnData.push(column.ref_product_no);
        // Swap Price
        columnData.push(column.amount_gross);
        columnData.push(column.amount);

        const taxesObject = separateTaxes({
          excludeTaxAmount: column.amount_gross,
          includeTaxAmount: column.amount,
        });
        // // GST
        // columnData.push(taxesObject.gstTax);
        // // PST
        // columnData.push(taxesObject.pstTax);
        // columnData.push(taxesObject["GST/HST"]);
        columnData.push(taxesObject.gstTax);
        columnData.push(taxesObject.hstTax);
        columnData.push(taxesObject.qstTax);

        columnData.push(column.tranacted_at);
        columnData.push(column.tran_tracking_nos);
        columnData.push(
          getLabelURL(column.label_pdf_uri, column.label_zip_uri)
            ? `https://storage.googleapis.com/${getLabelURL(
                column.label_pdf_uri,
                column.label_zip_uri
              )}`
            : "N/A"
        );

        resultArray.push(columnData);
      }
      setTransactions(resultArray);
      console.log(resultArray);
      csvLink.current.link.click();
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    console.log(data);
    if (!data.search && data.search.trim() === "") {
      console.log("Empty search");
      setSearchError(true);
      return;
    }
    setSearchError(false);
    setIsLoading(true);
    try {
      const partnerId = partnerInfo.id;
      // const result = await axios.get(
      //   PARTNER_URI + "/shipping/transactions/searchShippingTransactions",
      //   {
      //     params: {
      //       query_string: data.search,
      //       partnerId: partnerId,
      //     },
      //   }
      // );
      const storedToken = getAccessToken("access_token");
      const result = await axios.get(`${PARTNER_URI}/elasticsearch/shippingPackageOrder/searchShippingPackageOrder`, {
        params: {
          queryString: data.search
        },
        headers: {
          Authorization: `Bearer ${storedToken}`
        },
      })
      if (result.status === 200) {
        const newOrderList = result.data.result;
        console.log(result.data);
        setShippingListData(newOrderList);
        setTotalNumber(newOrderList.length);
      } else {
        setErrorMessage("Failed to search Partner Shipping List");
        setToastOpen(true);
      }
    } catch (e) {
      setErrorMessage("Failed to search Partner Shipping List");
      setToastOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };


  return (
    <>
      <Box>
        {/*Pop-up Toast*/}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={toastOpen}
          onClose={handleToastClose}
          autoHideDuration={3000}
          message="Submit Transaction"
        >
          {(() => {
            if (errorMessage !== "") {
              return (
                <Alert
                  onClose={handleToastClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Error!
                  <hr />
                  {errorMessage}
                </Alert>
              );
            }
            return (
              <Alert
                onClose={handleToastClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {successMessage}
              </Alert>
            );
          })()}
        </Snackbar>
        <Box
          component="form"
          width={"1000px"}
          margin="0 auto"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            gap: "2rem",
            alignItems: "center",
            alignContent: "center",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <h1>Search Tracking</h1>
          <Box flex="45%" width="100%">
            <TextField
              id="outlined-basic"
              label="Search by name, address, order ID, order #, tracking # and reference #"
              variant="outlined"
              {...register("search")}
              fullWidth
              error={searchError}
              helperText={searchError ? "Empty Search" : undefined}
              // variant="standard"
            />
          </Box>
          <Box display="flex" sx={{ gap: "3rem" }}>
            <LoadingButton
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "7rem",
              }}
              loading={isLoading}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Search
            </LoadingButton>
          </Box>
        </Box>

        <br />
        {isLoading ? (
          <>
            <Box>
              <h1>Loading...</h1>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignContent="center"
                sx={{ gap: "2rem" }}
              >
                <h2>Found {totalNumber} results</h2>
                <Box
                  display={totalNumber === 0 ? "none" : "flex"}
                  justifyContent="center"
                  alignContent="center"
                  flexBasis={0}
                >
                  <Button
                    onClick={getTransactionData}
                    variant="contained"
                    color="secondary"
                    style={{
                      textTransform: "unset",
                      fontSize: "1rem",
                      display: "flex",
                      padding: "0.5rem",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    Download
                  </Button>
                  <CSVLink
                    data={transactions}
                    filename={`Trans-${partnerInfo.id}-${dayjs(
                      dateRange[0].startDate
                    ).format("YYYY-MM-DD")}-${dayjs(
                      dateRange[0].endDate
                    ).format("YYYY-MM-DD")}.csv`}
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  />
                  {/*<button onClick={getTransactionDataExcel}>Download</button>*/}
                </Box>
              </Box>
              <Box width="100%">
                <hr />
                <Box mt={2} display="flex" justifyContent="center">
                  <table className={classes.table}>
                    <thead>
                      <tr className={classes.listItemWrapper}>
                        <th className={classes.idTH}>Transaction ID</th>
                        <th className={classes.idTH}>Order ID</th>
                        {/*<th className={classes.nameTH}>Ref Order Number</th>*/}
                        {/*<th className={classes.nameTH}>Ref Product ID</th>*/}
                        {/*<th className={classes.nameTH}>Ref Other Number</th>*/}
                        <th className={classes.nameTH}>Ref #</th>
                        <th className={classes.nameTH}>Send To</th>
                        {/*<th className={classes.nameTH}>Send To Name/Email/Tel</th>*/}
                        {/*<th className={classes.nameTH}>Send To Address/City/Province/Country/Postal Code</th>*/}
                        {/*<th className={classes.nameTH}>Exclude Tax</th>*/}
                        {/*<th className={classes.nameTH}>Include Tax</th>*/}
                        <th className={classes.nameTH}>Price</th>
                        <th className={classes.nameTH}>Carrier / Tracking #</th>
                        {/*<th className={classes.nameTH}>ZIP Label URI</th>*/}
                        {/*<th className={classes.nameTH}>Label PDF URI</th>*/}
                        <th className={classes.nameTH}>Date</th>
                        <th className={classes.nameTH}>Status</th>
                      </tr>
                    </thead>
                    <tbody className={classes.tbody}>
                      {shippingListData.map((v, index) => {
                        return (
                          <SearchShippingTransactionTableItem
                            key={index}
                            account_base={v.account_base}
                            date={v.tranacted_at}
                            gross_price={v?.amount_gross}
                            net_price={v?.amount}
                            order_id={v?.order_id}
                            ref_product_no={v.ref_product_no}
                            ref_other_no={v?.ref_other_no}
                            ref_order_no={v?.ref_order_no}
                            tran_id={v.tran_id}
                            label_pdf_uri={
                              v?.label_pdf_uri
                                ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_pdf_uri}`
                                : ""
                            }
                            zip_label_uri={
                              v?.label_zip_uri
                                ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_zip_uri}`
                                : ""
                            }
                            send_to_addr={v.send_to_addr}
                            send_to_city={v.send_to_city}
                            send_to_province={v.send_to_province}
                            send_to_country={v.send_to_country}
                            send_to_postal_code={v.send_to_postal_code}
                            send_to_name={v.send_to_name}
                            send_to_tel={v.send_to_tel}
                            send_to_email={v.send_to_email}
                            currency_code={v?.currency_code || "CAD"}
                            transactionNumbers={v.tran_tracking_nos}
                            tran_status={v.tran_status}
                            shipping_id_no={v.shipping_id_no}
                            shipping_fee_exclude_tax={
                              v?.shipping_fee_exclude_tax
                            }
                            service_fee_exclude_tax={v?.service_fee_exclude_tax}
                            shipping_fee_include_tax={
                              v?.shipping_fee_include_tax
                            }
                            service_fee_include_gst={v?.service_fee_include_gst}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
              </Box>
              <br />
              {/* <Box margin="auto" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                      <Pagination count={Math.floor(totalNumber / pageSize) + 1}
                                  page={page}
                                  onChange={handlePageChange}
                                  className={classes.pagination}
                                  variant="outlined"
                                  shape="rounded" />
                    </Box> */}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default SearchShippingTransactions;
