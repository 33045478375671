import React from "react";
import { makeStyles } from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
  },
}));

export default function CsvRateTableItem({ data }) {
  const classes = useStyles();

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>{data.line}</td>
        <td>{data.productId}</td>
        <td>
          <CurrencyFormat value={(data.totalPrice)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </td>
        <td>
          <CurrencyFormat value={(data.totalPriceWithTaxes)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </td>
        <td>{data.error || ""}</td>
      </tr>
    </>
  );
}
