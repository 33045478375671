import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import {TopUpContent} from "./TopUpContent";
import {TopUpPromo} from "./TopUpPromo";
import {TopUpDetail} from "./TopUpDetail";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import {useEffect, useState} from "react";
import {TopUpConfirm} from "./TopUpConfirm";
import {value} from "lodash/seq";
import intl from "react-intl-universal";
import {useSelector} from "react-redux";

const styles = {
    TopUpRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    TopUpHeading: {
        fontSize: '25px',
        fontWeight: '600',
        textAlign: 'left',
        marginTop: '10px'
    }
}

export const TopUp = () => {

    const {id: partnerId} = useSelector((state) => state.user);

    const storedToken = getAccessToken("access_token");
    const [extraReturn, setExtraReturn] = useState(null);
    const [amount, setAmount] = useState(50);
    const [selectPayment, setSelectPayment] = useState('email');
    const [referenceNumber, setReferenceNumber] = useState('');
    const [selectCardId, setSelectCardId] = useState(null);
    const [coupon, setCoupon] = useState();
    const [selectOther, setSelectOther] = useState('');
    const [isConfirmAmount, setIsConfirmAmount] = useState(false);

    const cbIsConfirmAmount = (value) => {
        setIsConfirmAmount(value);
    }

    const cbReferenceNumber = (value) => {
        setReferenceNumber(value);
    }

    const cbHandleAmount = (value) => {
        setAmount(value);
    }

    const cbHandlePayment = (value) => {
        setSelectPayment(value);
    }

    const cbSelectCreditCard = (value) => {
        setSelectCardId(value);
    }

    const cbHandleCoupon = (value) => {
        setCoupon(value);
    }

    const cbSelectOther = (value) => {
        setSelectOther(value);
    }

    const verifyPartnerCanGetAnExtraReturn = async () => {
        const requestURL = `${PARTNER_URI}/billingCenter/canGetExtraReturnForPartner`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    amount: amount
                }
            })
            console.log('result', result.data);
            setExtraReturn(result.data);
        } catch (e) {
            console.log('error', e.response)
        }
    }

    useEffect(() => {
        verifyPartnerCanGetAnExtraReturn();
    }, [amount]);

    return (
        <Container maxWidth="xl">
            <Box sx={styles.TopUpRoot}>
                <Typography style={styles.TopUpHeading}>
                    {intl.get('BILLING_CENTER.TOP_UP_PAGE.TOP_UP_CREDIT')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                        <TopUpContent
                            cbHandleAmount={cbHandleAmount}
                            cbHandlePayment={cbHandlePayment}
                            cbReferenceNumber={cbReferenceNumber}
                            cbSelectCreditCard={cbSelectCreditCard}
                            cbSelectOther={cbSelectOther}
                            cbIsConfirmAmount={cbIsConfirmAmount}
                            coupon={coupon}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <TopUpPromo cbHandleCoupon={cbHandleCoupon} amount={amount}/>
                            </Grid>
                            {
                                (isConfirmAmount && (amount >= 50 || partnerId === 1)) &&
                                <Grid item xs={12}>
                                    <TopUpDetail
                                        extraReturn={extraReturn}
                                        amount={amount}
                                        payMethod={selectPayment}
                                        coupon={coupon}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {
                        (isConfirmAmount && (amount >= 50 || partnerId === 1)) &&
                        <TopUpConfirm
                            referenceNumber={referenceNumber}
                            selectPayment={selectPayment}
                            amount={amount}
                            selectCardId={selectCardId}
                            coupon={coupon}
                            selectOther={selectOther}
                        />
                    }
                </Grid>
            </Box>
        </Container>
    )
}