import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {getAccessToken} from "../../utils/doToken";
import {PARTNER_URI} from "../../utils/apiUrl";
import Box from "@mui/material/Box";
import {mapLTLLogo} from "../../utils/Helper";
import {Divider, Link} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from "@mui/material/IconButton";
import {format} from "date-fns";
import Button from "@mui/material/Button";
import {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";

const styles = {
    QuoteLTLEachTitle: {
        fontSize: '16px',
        textAlign: 'left'
    },
    QuoteLTLEachSection: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        position: 'relative'
    },
    QuoteLTLEachSectionImage: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    QuoteLTLEachAction: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        width: '180px',
        backgroundColor: '#FFFFFF',
        borderRadius: '5px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        left: '-150%',
        top: '100%',
        zIndex: 10
    }
}

export const QuoteLTLEach = ({quote, handleSelectQuoteId, selectQuoteId}) => {

    // console.log('quote', quote);

    const history = useHistory();

    const [openAction, setOpenAction] = useState(false);

    const iconButtonRef = useRef(null);

    const handleOpenAction = () => {
        handleSelectQuoteId(quote?.request_id);
        setOpenAction(!openAction);
    }

    const handleViewDetails = () => {
        history.push(`/LTLQuoteDetails/${quote?.request_id}`);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (iconButtonRef.current && !iconButtonRef.current.contains(event.target)) {
                setOpenAction(false);
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (selectQuoteId === quote?.request_id) {
            setOpenAction(true);
        } else {
            setOpenAction(false);
        }
    }, [selectQuoteId])

    return (
        <>
            <Grid container spacing={4}>
                <Grid item md={5}>
                    <Grid container spacing={0}>
                        <Grid item md={4}>
                            <Box sx={styles.QuoteLTLEachSection}>
                                <Link onClick={handleViewDetails} sx={{textDecoration: 'none'}}>
                                    <Typography style={styles.QuoteLTLEachTitle}>
                                        {quote?.ref_quote_no}
                                    </Typography>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box sx={styles.QuoteLTLEachSection}>
                                <Typography style={styles.QuoteLTLEachTitle}>
                                    {quote?.expired_date}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box sx={styles.QuoteLTLEachSectionImage}>
                                <Box>
                                    <img src={mapLTLLogo(quote?.account_base)} alt="logo" width={30}/>
                                </Box>
                                <Typography style={styles.QuoteLTLEachTitle}>
                                    {quote?.account_base === 'dayandross' ? 'DAY && ROSS' : quote?.account_base?.toUpperCase()}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={7}>
                    <Grid container spacing={0}>
                        <Grid item md={4}>
                            <Box sx={styles.QuoteLTLEachSection}>
                                <Typography style={styles.QuoteLTLEachTitle}>
                                    {quote?.pickup_city.toUpperCase()} {quote?.pickup_province}, {quote?.pickup_country}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box sx={styles.QuoteLTLEachSection}>
                                <Typography style={styles.QuoteLTLEachTitle}>
                                    {quote?.deliver_city.toUpperCase()} {quote?.deliver_province}, {quote?.deliver_country}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box sx={styles.QuoteLTLEachSection}>
                                <Typography style={styles.QuoteLTLEachTitle}>
                                    $ {quote?.final_amount} (CAD)
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={1}>
                            <Box sx={styles.QuoteLTLEachSection}>
                                <IconButton ref={iconButtonRef}>
                                    <MoreVertIcon onClick={handleOpenAction}/>
                                </IconButton>
                                {(quote?.request_id === selectQuoteId && openAction) &&
                                    <Box sx={styles.QuoteLTLEachAction}>
                                        <Button
                                            onClick={handleViewDetails}
                                        >
                                            View Details
                                        </Button>
                                        {/*<Button*/}
                                        {/*    color='success'*/}
                                        {/*>*/}
                                        {/*    Create Shipment*/}
                                        {/*</Button>*/}
                                        {/*<Button*/}
                                        {/*    color='error'*/}
                                        {/*>*/}
                                        {/*    Delete Quote*/}
                                        {/*</Button>*/}
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider/>
        </>
    )
}