import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import {Divider} from "@mui/material";
import {calTransactionFee} from "../../utils/Helper";
import {TopUpConfirm} from "./TopUpConfirm";
import intl from "react-intl-universal";

const styles = {
    TopUpDetailRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '60px',
        display: 'flex',
        flexDirection: 'column',
    },
    TopUpDetailTitle: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: '14px'
    },
    TopUpDetailHeading: {
        fontWeight: '600',
        textAlign: 'left',
        fontSize: '16px'
    },
    TopUpDetailText: {
        textAlign: 'left',
        fontSize: '14px'
    },
}

export const TopUpDetail = ({extraReturn, amount, payMethod, coupon}) => {

    console.log('extra return', extraReturn);

    console.log('payment method', payMethod);

    return (
        <Box sx={styles.TopUpDetailRoot}>
            <Typography sx={styles.TopUpDetailHeading}>
                {intl.get('BILLING_CENTER.TOP_UP_PAGE.SUMMARY')}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <Typography style={styles.TopUpDetailText}>
                        {intl.get('BILLING_CENTER.TOP_UP_PAGE.SUBTOTAL')}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={styles.TopUpDetailText}>
                        CAD $ {(+amount)?.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography style={styles.TopUpDetailText}>
                        {intl.get('BILLING_CENTER.TOP_UP_PAGE.TRANSACTION')}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={styles.TopUpDetailText}>
                        CAD $ {(payMethod === 'credit' || payMethod === 'paypal') ? calTransactionFee(amount) : '0.00'}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography style={styles.TopUpDetailTitle}>
                        {intl.get('BILLING_CENTER.TOP_UP_PAGE.TOTAL_COST')}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={styles.TopUpDetailTitle}>
                        CAD
                        $ {(payMethod === 'credit' || payMethod === 'paypal') ? (amount + +calTransactionFee(amount))?.toFixed(2) : amount?.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={7}>
                    <Typography style={styles.TopUpDetailText}>
                        {intl.get('BILLING_CENTER.TOP_UP_PAGE.SUBTOTAL')}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={styles.TopUpDetailText}>
                        CAD $ {amount.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography style={styles.TopUpDetailText}>
                        {intl.get('BILLING_CENTER.TOP_UP_PAGE.CREDIT')}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={styles.TopUpDetailText}>
                        CAD $ {(extraReturn?.extraAmount)?.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography style={styles.TopUpDetailText}>
                        {intl.get('BILLING_CENTER.TOP_UP_PAGE.COUPON')}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={styles.TopUpDetailText}>
                        CAD $ {(coupon?.couponAmount)?.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography style={styles.TopUpDetailTitle}>
                        {intl.get('BILLING_CENTER.TOP_UP_PAGE.TOTAL_CREDITS')}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={styles.TopUpDetailTitle}>
                        CAD $ {(extraReturn?.extraAmount + amount + coupon?.couponAmount)?.toFixed(2)}
                    </Typography>
                </Grid>
                {/*<TopUpConfirm />*/}
            </Grid>
        </Box>
    )
}