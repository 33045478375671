import React, {useRef, useState} from "react";
import { makeStyles, IconButton, Input } from "@material-ui/core";
import { Edit, Check, Cancel } from "@material-ui/icons";
import {
  selectHSCode,
  addHSCodeList,
  removeHSCodeList,
} from "../../redux/HSCode/HSCodeListSlice";
import { useDispatch } from "react-redux";
import {
  GET_HSCODE_DETAIL,
  UPDATE_HSCODE_SECTION,
  UPDATE_HSCODE_DETAIL, TRAIN_HSCODE, GET_TAGS_BY_PARTNER_ID_HS_CODE,
} from "../../utils/apiUrl";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
  },
  tdEdit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export default function HSCodeLevelSixItem({ hscode, desc, desc_cn_gb, desc_cn_big, partnerId, section, parent, level }) {

  const classes = useStyles();

  const [editMode, setEditMode] = useState(false);
  const [updateTagValue, setUpdateTagValue] = useState("");
  // const cacheTags = useRef(tags);
  const getTagsByPartnerIdAndHSCode = async (e) => {
    try {
      const { data } = await axios.get(`${GET_TAGS_BY_PARTNER_ID_HS_CODE}?partnerId=${partnerId}&hscode=${hscode}`);
      console.log(data);
      const tags = data.tags;
      setUpdateTagValue(tags);
    } catch (e) {
      console.log(e);
      setUpdateTagValue("");
    }
  }

  const updateTag = async (e) => {
    setEditMode(false);
    try {
      const { data } = axios.put(`${TRAIN_HSCODE}`, {
        // hscode,
        // tags: cacheTags.current,
        // partnerId: partnerId
        partnerHscodeObj: {
          partnerId,
          partnerTags: updateTagValue,
          hscode,
          section,
          desc,
          parent,
          level,
          desc_cn_gb,
          desc_cn_big
        }
      });
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  }

  const tagEditView = () => {
    return (
        <div>
          <Input
              type="text"
              onChange={(e) => {
                setUpdateTagValue(e.target.value)
              }}
              value={updateTagValue}
          />
          <IconButton onClick={() => {
            setEditMode(false);
          }}>
            <Cancel />
          </IconButton>
          <IconButton onClick={updateTag}>
            <Check />
          </IconButton>
        </div>
    )
  }

  return <tr className={classes.listItemWrapper}>
    <td>{hscode}</td>
    <td>{desc}</td>
    <td>{desc_cn_gb}</td>
    <td>{desc_cn_big}</td>
    {/*<td>{tags}</td>*/}
    <td>
      <div className={classes.tdEdit}>
        {(() => {
          return editMode
              ? tagEditView()
              : (
                  <td>
                    <div
                        onClick={async (e) => {
                          await getTagsByPartnerIdAndHSCode(e);
                          setEditMode(true);
                        }}
                    >
                      <IconButton
                          onClick={() => {
                            setEditMode(true);
                          }}
                      >
                        <Edit />
                          </IconButton>
                    </div>
                  </td>
              )
        })()}
      </div>
    </td>
  </tr>
}
