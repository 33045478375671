import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {
    extractFullAddress,
    extractFullAddressComponent,
    isValidateEmail,
    isValidatePhone,
    isValidatePhoneWorldWide
} from "../../utils/Helper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {value} from "lodash/seq";
import {useDispatch, useSelector} from "react-redux";
import {
    updateShippingLTLQuickQuoteConsigneeInfo,
    updateShippingLTLQuickQuoteMoveFromAddition,
    updateShippingLTLQuickQuoteMoveToAddition, updateShippingLTLQuickQuoteReferenceNumber,
    updateShippingLTLQuickQuoteShipperInfo,
    updateShippingLTLQuickQuoteShipPickupDateTime,
    updateShippingLTLQuickQuoteSpecialService
} from "../Actions/shippingLTLAction";
import {Checkbox, FormControlLabel} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {getAccessToken} from "../../utils/doToken";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {Stack} from "@mui/joy";
import Button from "@mui/material/Button";
import addressIcon from "../../images/Address.png";
import Modal from "@mui/material/Modal";
import HomeIcon from "@mui/icons-material/Home";
import {useHistory} from "react-router-dom";
import intl from "react-intl-universal";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../shared/constInfo";

const styles = {
    ShippingLTLQuickQuoteSelectRateHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingLTLQuickQuoteSelectRateSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingLTLQuickQuoteAddressErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingLTLQuickQuoteAddressWarnText: {
        fontSize: '12px',
        color: "#B2B2B2"
    },
    ShippingLTLQuickQuoteSelectRateText: {
        textAlign: 'left',
        fontSize: '14px'
    },
    ShippingLTLAddressModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    ShippingLTLAddressModalSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingLTLAddressModalText: {
        fontSize: '14px'
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingLTLQuickQuoteSelectRate = ({confirm}) => {

    const classes = useStyles();
    const token = getAccessToken("access_token");
    const {id: partnerId, email} = useSelector((state) => state.user);

    let specialService = useSelector(state => state?.shippingLTL?.quickQuoteSpecialService);
    let validation = useSelector(state => state?.shippingLTL?.quickQuotePlaceOrderValidation);
    let moveFrom = useSelector(state => state?.shippingLTL?.quickQuoteMoveFrom);
    let moveTo = useSelector(state => state?.shippingLTL?.quickQuoteMoveTo);

    const currentDate = new Date().toISOString().split('T')[0];

    const dispatch = useDispatch();

    const history = useHistory();

    const [searchMovingFromAddressList, setSearchMovingFromAddressList] = useState([]);
    const [searchMovingToAddressList, setSearchMovingToAddressList] = useState([]);
    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [shipperInfo, setShipperInfo] = useState({
        name: null,
        phone: null,
        email: null,
        company: null,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        }
    });
    const [consigneeInfo, setConsigneeInfo] = useState({
        name: null,
        phone: null,
        email: null,
        company: null,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        }
    });
    const [shipFrom, setShipFrom] = useState({
        address: null,
        address2: null,
        postalCode: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
    });
    const [shipTo, setShipTo] = useState({
        address: null,
        address2: null,
        postalCode: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
    });

    const [shipDataAndTime, setShipDateAndTime] = useState({
        shipDate: null,
        pickupDate: null,
        availablePickupTime: '',
        earliestPickupTime: '',
        latestPickupTime: '',
    });

    const [referenceNumber, setReferenceNumber] = useState('');

    const [openShipperModal, setOpenShipperModal] = useState(false);
    const [shipperAddressList, setShipperAddressList] = useState([]);
    const [openConsigneeModal, setOpenConsigneeModal] = useState(false);
    const [consigneeAddressList, setConsigneeAddressList] = useState([]);
    const [matchShipFrom, setMatchShopFrom] = useState(true);
    const [matchShipTo, setMatchShopTo] = useState(true);
    const [matchCodeShipFrom, setMatchCodeShipFrom] = useState('');
    const [matchCodeShipTo, setMatchCodeShipTo] = useState('');

    const handleOpenShipperModal = () => setOpenShipperModal(true);
    const handleCloseShipperModal = () => setOpenShipperModal(false);

    const handleOpenConsigneeModal = () => setOpenConsigneeModal(true);
    const handleCloseConsigneeModal = () => setOpenConsigneeModal(false)

    const handleReferenceNumber = (event) => {
        setReferenceNumber(event?.target?.value);
    }

    const handleNewAddress = () => {
        history.push('/settings/address-list')
    }

    const getNextWeekday = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 48 * 60 * 60 * 1000); // Adding two day

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        return tomorrow.toISOString().split('T')[0];
    };

    const getMinDate = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000); // Adding two day

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        return tomorrow.toISOString().split('T')[0];
    };

    useEffect(() => {
        const nextWeekday = getNextWeekday();
        setShipDateAndTime(prevState => ({
            ...prevState,
            shipDate: nextWeekday,
            pickupDate: nextWeekday,
            availablePickupTime: "12:00",
            earliestPickupTime: "11:00",
            latestPickupTime: "16:00"
        }));
        // console.log('next week day', nextWeekday);
        // console.log('current date', currentDate);
    }, [])

    const searchAddress = async (searchString, roleType) => {
        let requestURL = `${PARTNER_URI}/elasticsearch/addressBook/searchPartnerAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    role: roleType,
                    queryString: searchString
                }
            })

            console.log('result', result?.data);
            const searchAddressResult = result?.data;
            // setSearchNameList(result?.data);
            return searchAddressResult;

        } catch (e) {
            console.log('error', e.response)
        }
    }

    const googleMapsGoogleAddress = async (inputValue, city) => {
        let requestURL = `${PARTNER_URI}/googleMaps/googleAddress`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                }
            })
            const {data: {data: {predictions}}} = result;

            console.log('google address predictions', predictions);
            console.log('city', city);

            const filterPredictions = predictions.filter(address => {
                    const addressComponent = extractFullAddressComponent(address?.addressComponent);
                    console.log('address component', addressComponent);
                    if (city.toLowerCase() === addressComponent?.city.toLowerCase() ||
                        city.toLowerCase() === addressComponent?.sublocality.toLowerCase() ||
                        city.toLowerCase() === addressComponent?.administrative_area_level_3.toLowerCase()
                    ) {
                        return address
                    }
                }
            )

            const updatedPredictions = filterPredictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })

            return updatedPredictions;
        } catch (e) {
            console.log(e.response)
        }

    }

    const googleMapsPlaceAutocomplete = async (inputValue, types, city) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: types
                }
            })
            const {data: {data: {predictions}}} = result;

            console.log('predictions', predictions);
            // console.log('city', city);

            const filterPredictions = predictions.filter(address => address?.structured_formatting?.secondary_text.includes(city));
            const updatedPredictions = filterPredictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })

            return updatedPredictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromAddress = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'address', moveFrom?.city);
        const predictionsUpdated = await googleMapsGoogleAddress(value, moveFrom?.city);
        console.log('update prediction', predictionsUpdated);
        setMovingFromAddressPredictions(predictionsUpdated);
    }

    const handleChangeShipFromAddress = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            if (address?.city)
                setShipFrom(prevState => ({
                    ...prevState,
                    address: `${address?.streetNumber} ${address?.streetName}`,
                    postalCode: address?.zip?.replace(/\s*/g, ""),
                    displayAddress: value
                }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleShipFromAddress2 = (event) => {
        setShipFrom(prevState => ({
            ...prevState,
            address2: event.target.value
        }))
    }

    const handleShipFromPostalCode = (event) => {
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: event.target.value
        }))
    }

    const handleInputShipToAddress = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'address', moveTo?.city);
        const predictionsUpdated = await googleMapsGoogleAddress(value, moveTo?.city);
        console.log('update prediction', predictionsUpdated);
        setMovingToAddressPredictions(predictionsUpdated);
    }

    const handleChangeShipToAddress = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setShipTo(prevState => ({
                ...prevState,
                address: `${address?.streetNumber} ${address?.streetName}`,
                postalCode: address?.zip?.replace(/\s*/g, ""),
                displayAddress: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleShipToAddress2 = (event) => {
        setShipTo(prevState => ({
            ...prevState,
            address2: event.target.value
        }))
    }

    const handleShipToPostalCode = (event) => {
        setShipTo(prevState => ({
            ...prevState,
            postalCode: event.target.value
        }))
    }

    const handleShipperName = (event) => {
        setShipperInfo(prevState => ({
            ...prevState,
            name: event.target.value
        }))
    }

    const handleInputChangeShipperName = async (event, value) => {
        console.log('value', value);
        setShipperInfo(prevState => ({
            ...prevState,
            name: value,
            addressList: {
                ...prevState.addressList,
                name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'SENDER');
            const filterResult = result.filter(item => item?.name?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveFrom?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingFromAddressList(filterResult);
        }
    }

    const handleChangeShipperName = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipperInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipFrom(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                name: ""
            }))
        }
    }

    const handleChangeShipperPhone = (value) => {
        // console.log('shipper phone', value);
        setShipperInfo(prevState => ({
            ...prevState,
            phone: value
        }))
    }

    const handleShipperPhone = (event) => {
        setShipperInfo(prevState => ({
            ...prevState,
            phone: event.target.value
        }))
    }

    const handleInputShipperPhone = (event) => {
        // console.log('phone input', event?.target?.value);
        setShipperInfo(prevState => ({
            ...prevState,
            phone: event?.target?.value
        }))
    }

    // const handleInputChangeShipperPhone = async (event, value) => {
    //     console.log('value', value);
    //     setShipperInfo(prevState => ({
    //         ...prevState,
    //         phone: value,
    //         addressList: {
    //             ...prevState.addressList,
    //             phone: value,
    //         }
    //     }))
    //     if (value) {
    //         const result = await searchAddress(value, 'SENDER');
    //         const filterResult = result.filter(item => item?.phone?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveFrom?.city?.toLowerCase());
    //         console.log('filter data', filterResult);
    //         setSearchMovingFromAddressList(filterResult);
    //     }
    // }

    // const handleChangeShipperPhone = (event, value) => {
    //     console.log('value', value);
    //     if (value) {
    //         setShipperInfo(prevState => ({
    //             ...prevState,
    //             name: value?.name,
    //             phone: value?.phone,
    //             email: value?.address_email,
    //             company: value?.company_name,
    //             addressList: value
    //         }))
    //         setShipFrom(prevState => ({
    //             ...prevState,
    //             address: value?.address,
    //             postalCode: value?.postal_code?.replace(/\s*/g, ""),
    //             displayAddress: {
    //                 description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
    //                 placeId: null,
    //                 text: value?.address
    //             }
    //         }))
    //     } else {
    //         setShipFrom(prevState => ({
    //             ...prevState,
    //             phone: ""
    //         }))
    //     }
    // }

    const handleShipperEmail = (event) => {
        setShipperInfo(prevState => ({
            ...prevState,
            email: event.target.value
        }))
    }

    const handleInputChangeShipperEmail = async (event, value) => {
        console.log('value', value);
        setShipperInfo(prevState => ({
            ...prevState,
            email: value,
            addressList: {
                ...prevState.addressList,
                address_email: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'SENDER');
            const filterResult = result.filter(item => item?.address_email?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveFrom?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingFromAddressList(filterResult);
        }
    }

    const handleChangeShipperEmail = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipperInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipFrom(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                email: ""
            }))
        }
    }

    const handleShipperCompany = (event) => {
        setShipperInfo(prevState => ({
            ...prevState,
            company: event.target.value
        }))
    }

    const handleInputChangeShipperCompany = async (event, value) => {
        console.log('value', value);
        setShipperInfo(prevState => ({
            ...prevState,
            company: value,
            addressList: {
                ...prevState.addressList,
                company_name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'SENDER');
            const filterResult = result.filter(item => item?.company_name?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveFrom?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingFromAddressList(filterResult);
        }
    }

    const handleChangeShipperCompany = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipperInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipFrom(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                company: ""
            }))
        }
    }

    const handleInputChangeConsigneeName = async (event, value) => {
        console.log('value', value);
        setConsigneeInfo(prevState => ({
            ...prevState,
            name: value,
            addressList: {
                ...prevState.addressList,
                name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'RECEIVER');
            const filterResult = result.filter(item => item?.name?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveTo?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingToAddressList(filterResult);
        }
    }

    const handleChangeConsigneeName = (event, value) => {
        console.log('value', value);
        if (value) {
            setConsigneeInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipTo(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                name: ""
            }))
        }
    }

    const handleConsigneeName = (event) => {
        setConsigneeInfo(prevState => ({
            ...prevState,
            name: event.target.value
        }))
    }

    const handleInputChangeConsigneePhone = async (event, value) => {
        console.log('value', value);
        setConsigneeInfo(prevState => ({
            ...prevState,
            phone: value,
            addressList: {
                ...prevState.addressList,
                phone: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'RECEIVER');
            const filterResult = result.filter(item => item?.phone?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveTo?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingToAddressList(filterResult);
        }
    }

    const handleChangeConsigneePhone = (value) => {
        // console.log('shipper phone', value);
        setConsigneeInfo(prevState => ({
            ...prevState,
            phone: value
        }))
    }

    const handleInputConsigneePhone = (event) => {
        // console.log('phone input', event?.target?.value);
        setConsigneeInfo(prevState => ({
            ...prevState,
            phone: event?.target?.value
        }))
    }

    // const handleChangeConsigneePhone = (event, value) => {
    //     console.log('value', value);
    //     if (value) {
    //         setConsigneeInfo(prevState => ({
    //             ...prevState,
    //             name: value?.name,
    //             phone: value?.phone,
    //             email: value?.address_email,
    //             company: value?.company_name,
    //             addressList: value
    //         }))
    //         setShipTo(prevState => ({
    //             ...prevState,
    //             address: value?.address,
    //             postalCode: value?.postal_code?.replace(/\s*/g, ""),
    //             displayAddress: {
    //                 description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
    //                 placeId: null,
    //                 text: value?.address
    //             }
    //         }))
    //     } else {
    //         setShipTo(prevState => ({
    //             ...prevState,
    //             phone: ""
    //         }))
    //     }
    // }
    //
    // const handleConsigneePhone = (event) => {
    //     setConsigneeInfo(prevState => ({
    //         ...prevState,
    //         phone: event.target.value
    //     }))
    // }

    const handleInputChangeConsigneeEmail = async (event, value) => {
        console.log('value', value);
        setConsigneeInfo(prevState => ({
            ...prevState,
            email: value,
            addressList: {
                ...prevState.addressList,
                address_email: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'RECEIVER');
            const filterResult = result.filter(item => item?.address_email?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveTo?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingToAddressList(filterResult);
        }
    }

    const handleChangeConsigneeEmail = (event, value) => {
        console.log('value', value);
        if (value) {
            setConsigneeInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipTo(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                email: ""
            }))
        }
    }

    const handleConsigneeEmail = (event) => {
        setConsigneeInfo(prevState => ({
            ...prevState,
            email: event.target.value
        }))
    }

    const handleInputChangeConsigneeCompany = async (event, value) => {
        console.log('value', value);
        setConsigneeInfo(prevState => ({
            ...prevState,
            company: value,
            addressList: {
                ...prevState.addressList,
                company_name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value, 'RECEIVER');
            const filterResult = result.filter(item => item?.company_name?.toLowerCase().includes(value.toLowerCase()) && item?.city?.toLowerCase() === moveTo?.city?.toLowerCase());
            console.log('filter data', filterResult);
            setSearchMovingToAddressList(filterResult);
        }
    }

    const handleChangeConsigneeCompany = (event, value) => {
        console.log('value', value);
        if (value) {
            setConsigneeInfo(prevState => ({
                ...prevState,
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
            setShipTo(prevState => ({
                ...prevState,
                address: value?.address,
                address2: value?.address_2,
                postalCode: value?.postal_code?.replace(/\s*/g, ""),
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                }
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                company: ""
            }))
        }
    }

    const handleConsigneeCompany = (event) => {
        setConsigneeInfo(prevState => ({
            ...prevState,
            company: event.target.value
        }))
    }

    const handleShipDate = (event) => {
        const inputDate = event.target.value;
        if (inputDate <= currentDate) {
            setShipDateAndTime(prevState => ({
                ...prevState,
                shipDate: currentDate
            }));
        } else {
            setShipDateAndTime(prevState => ({
                ...prevState,
                shipDate: inputDate
            }))
        }
    }

    const handlePickupDate = (event) => {
        const inputDate = event.target.value;
        if (inputDate <= currentDate) {
            setShipDateAndTime(prevState => ({
                ...prevState,
                pickupDate: currentDate,
                shipDate: currentDate
            }));
        } else {
            setShipDateAndTime(prevState => ({
                ...prevState,
                pickupDate: inputDate,
                shipDate: inputDate
            }))
        }
    }

    const handlePickupAvailableTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            availablePickupTime: inputTime
        }))
    }

    const handlePickupEarliestTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            earliestPickupTime: inputTime
        }))
    }

    const handlePickupLatestTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            latestPickupTime: inputTime
        }))
    }

    const getShipFromAddress = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/sender`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    partnerId: partnerId.toString()
                }
            })

            const addressList = result?.data?.result;
            const filterAddressList = addressList?.filter(address => address?.city === moveFrom?.city && address?.province === moveFrom?.province?.code);

            // console.log('filter address', filterAddressList);

            setShipperAddressList(filterAddressList);

        } catch (e) {
            console.log(e.response);
        }
    }

    const getShipToAddress = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/deliver`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    partnerId: partnerId.toString()
                }
            })
            const addressList = result?.data?.result;
            const filterAddressList = addressList?.filter(address => address?.city === moveTo?.city && address?.province === moveTo?.province?.code);

            setConsigneeAddressList(filterAddressList);

        } catch (e) {
            console.log(e.response);
        }
    }

    const handleSelectShipFromAddress = (address) => {
        setShipperInfo(prevState => ({
            ...prevState,
            name: address?.name,
            phone: address?.phone,
            email: address?.address_email,
            company: address?.company_name,
            addressList: address
        }))
        setShipFrom(prevState => ({
            ...prevState,
            address: address?.address,
            address2: address?.address_2,
            postalCode: address?.postal_code?.replace(/\s*/g, ""),
            displayAddress: {
                description: `${address?.address}, ${address?.city}, ${address?.province}, ${address?.country}`,
                placeId: null,
                text: address?.address
            }
        }))
        handleCloseShipperModal();
    }

    const handleSelectShipToAddress = (address) => {
        // console.log('select address', address);
        setConsigneeInfo(prevState => ({
            ...prevState,
            name: address?.name,
            phone: address?.phone,
            email: address?.address_email,
            company: address?.company_name,
            addressList: address
        }))
        setShipTo(prevState => ({
            ...prevState,
            address: address?.address,
            address2: address?.address_2,
            postalCode: address?.postal_code?.replace(/\s*/g, ""),
            displayAddress: {
                description: `${address?.address}, ${address?.city}, ${address?.province}, ${address?.country}`,
                placeId: null,
                text: address?.address
            }
        }))
        handleCloseConsigneeModal();
    }

    const verifyAddressAndPostalCode = async (address, city) => {
        // step 1 get place id from address using google autocomplete api
        const predictions = await googleMapsPlaceAutocomplete(address, 'address', city);
        console.log('predictions', predictions);

        // step 2 get address details from place id using google place details api
        const place = await googleMapsPlaceDetails(predictions[0]?.placeId);
        console.log('place', place);

        // get postal code from address
        const googleAddress = extractFullAddress(place);
        console.log('address', googleAddress);

        // compare postal code
        return googleAddress?.zip?.replace(/\s*/g, "");
    }

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteMoveFromAddition(shipFrom));
    }, [shipFrom]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteMoveToAddition(shipTo));
    }, [shipTo]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteShipperInfo(shipperInfo));
    }, [shipperInfo]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteConsigneeInfo(consigneeInfo));
    }, [consigneeInfo]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteShipPickupDateTime(shipDataAndTime));
    }, [shipDataAndTime]);

    useEffect(() => {
        dispatch(updateShippingLTLQuickQuoteReferenceNumber(referenceNumber));
    }, [referenceNumber])

    useEffect(async () => {
        if (shipFrom?.address && shipFrom?.postalCode) {
            const result = await verifyAddressAndPostalCode(shipFrom?.address, moveFrom?.city);
            setMatchCodeShipFrom(result);
            if (result) {
                if (result === shipFrom?.postalCode?.replace(/\s*/g, "")) {
                    setMatchShopFrom(true);
                } else {
                    setMatchShopFrom(false);
                }
            } else {
                setMatchShopFrom(true);
            }
        } else {
            setMatchShopFrom(true);
        }
    }, [shipFrom]);

    useEffect(async () => {
        if (shipTo?.address && shipTo?.postalCode) {
            const result = await verifyAddressAndPostalCode(shipTo?.address, moveTo?.city);
            setMatchCodeShipTo(result);
            if (result) {
                if (result === shipTo?.postalCode?.replace(/\s*/g, "")) {
                    setMatchShopTo(true);
                } else {
                    setMatchShopTo(false);
                }
            } else {
                setMatchShopTo(true);
            }
        } else {
            setMatchShopTo(true);
        }
    }, [shipTo])

    // console.log('ship from address', shipFrom);
    // console.log('validation', validation);

    // console.log('move from', moveFrom);
    // console.log('shipper', shipperInfo);
    // console.log('ship from address', shipFrom);
    // console.log('consignee', consigneeInfo);
    //
    // console.log('ship date and time', shipDataAndTime);

    // console.log('reference number', referenceNumber);
    // console.log('moving from prediction', movingFromAddressPredictions);

    console.log('ship to match', matchShipTo);

    const customInputStyle = {
        fontSize: '16px',
        /* Add more inline styles as needed */
    };

    return (
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <Typography sx={styles.ShippingLTLQuickQuoteSelectRateHeading}>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.REFERENCE')}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('SHIPPING_LTL_QUICK_QUOTE.REFERENCE')}
                    </InputLabel>
                    <TextField
                        value={referenceNumber}
                        variant="outlined"
                        size='small'
                        onInput={handleReferenceNumber}
                        disabled={confirm}
                        // placeholder='Recommended'
                    />
                </Box>
            </Grid>
            <Grid item md={12} xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Typography sx={styles.ShippingLTLQuickQuoteSelectRateHeading}>
                                {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                            </Typography>
                            <Stack direction='row' spacing={0} alignItems='center'>
                                <Button
                                    onClick={() => {
                                        handleOpenShipperModal();
                                        getShipFromAddress();
                                    }}
                                    startIcon={<img src={addressIcon} width='35px'/>}>
                                    <Typography style={{
                                        fontSize: '14px',
                                        textAlign: 'left',
                                        color: '#609966',
                                        fontWeight: '600',
                                    }}>
                                        {intl.get('SELECT_ADDRESS.ADDRESSES')}
                                    </Typography>
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.ADDRESS')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                disabled={confirm}
                                value={shipFrom?.displayAddress}
                                options={movingFromAddressPredictions}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.text}
                                onInputChange={handleInputShipFromAddress}
                                onChange={handleChangeShipFromAddress}
                                fullWidth
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    variant='outlined'
                                    className={classes.smallInput}
                                />}
                                renderOption={(option) => (
                                    <Typography
                                        style={{fontSize: '12px'}}
                                    >
                                        {/*<Typography>*/}
                                        {option?.description}
                                        {/*</Typography>*/}
                                    </Typography>
                                )}
                            />
                            <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                {
                                    validation === null ? "" : validation?.shipFromAddressValidate ? "" : intl.get('SHIPPING_INFORMATION.REQUIRED')
                                }
                            </Typography>
                            <Typography style={styles.ShippingLTLQuickQuoteAddressWarnText}>
                                {
                                    specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) && intl.get('SHIPPING_INFORMATION.RESIDENCY_ADDRESS')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel>
                                {intl.get('SHIPPING_INFORMATION.ADDRESS_2')}
                            </InputLabel>
                            <TextField
                                value={shipFrom?.address2}
                                fullWidth
                                variant="outlined"
                                onInput={handleShipFromAddress2}
                                sx={{padding: '2px'}}
                                size='small'
                                disabled={confirm}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel>
                                {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                            </InputLabel>
                            <TextField
                                value={shipFrom?.postalCode}
                                fullWidth
                                variant="outlined"
                                onInput={handleShipFromPostalCode}
                                sx={{padding: '2px'}}
                                size='small'
                                disabled={confirm}
                            />
                            <Typography style={styles.ShippingLTLQuickQuoteAddressWarnText}>
                                {
                                    !matchShipFrom && `Please using recommended postal code ${matchCodeShipFrom}. Otherwise, it likely not place order.`
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.NAME')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                disabled={confirm}
                                value={shipperInfo?.addressList}
                                fullWidth
                                options={searchMovingFromAddressList}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.name}
                                onInputChange={handleInputChangeShipperName}
                                onChange={handleChangeShipperName}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.name} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                                    </Typography>
                                )}
                            />
                            <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                {
                                    validation === null ? "" :
                                        validation?.shipperInfoMissing?.includes('name') ? intl.get('SHIPPING_INFORMATION.REQUIRED')
                                            : ""
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.PHONE')}
                            </InputLabel>
                            <TextField
                                value={shipperInfo?.phone}
                                size='small'
                                fullWidth
                                variant="outlined"
                                onInput={handleInputShipperPhone}
                            />
                            <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                {
                                    validation === null ? "" :
                                        isValidatePhone(shipperInfo?.phone) ? '' :
                                            intl.get('SHIPPING_INFORMATION.VALID_PHONE')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel>
                                {intl.get('SHIPPING_INFORMATION.COMPANY')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                disabled={confirm}
                                value={shipperInfo?.addressList}
                                fullWidth
                                options={searchMovingFromAddressList}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.company_name}
                                onInputChange={handleInputChangeShipperCompany}
                                onChange={handleChangeShipperCompany}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.company_name} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                                    </Typography>
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel>
                                {intl.get('SHIPPING_INFORMATION.EMAIL')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                disabled={confirm}
                                value={shipperInfo?.addressList}
                                fullWidth
                                options={searchMovingFromAddressList}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.address_email}
                                onInputChange={handleInputChangeShipperEmail}
                                onChange={handleChangeShipperEmail}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                    placeholder={intl.get('SHIPPING_INFORMATION.RECOMMEND')}
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.address_email} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                                    </Typography>
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Typography sx={styles.ShippingLTLQuickQuoteSelectRateHeading}>
                                {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                            </Typography>
                            <Stack direction='row' spacing={0} alignItems='center'>
                                <Button
                                    onClick={() => {
                                        handleOpenConsigneeModal();
                                        getShipToAddress();
                                    }}
                                    startIcon={<img src={addressIcon} width='35px'/>}>
                                    <Typography style={{
                                        fontSize: '14px',
                                        textAlign: 'left',
                                        color: '#609966',
                                        fontWeight: '600',
                                    }}>
                                        {intl.get('SELECT_ADDRESS.ADDRESSES')}
                                    </Typography>
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.ADDRESS')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                disabled={confirm}
                                value={shipTo?.displayAddress}
                                options={movingToAddressPredictions}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.text}
                                onInputChange={handleInputShipToAddress}
                                onChange={handleChangeShipToAddress}
                                fullWidth
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    variant='outlined'
                                    className={classes.smallInput}
                                />}
                                renderOption={(option) => (
                                    <Typography
                                        style={{fontSize: '12px'}}
                                    >
                                        {/*<Typography>*/}
                                        {option?.description}
                                        {/*</Typography>*/}
                                    </Typography>
                                )}
                            />
                            <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                {
                                    validation === null ? "" : validation?.shipToAddressValidate ? "" : intl.get('SHIPPING_INFORMATION.REQUIRED')
                                }
                            </Typography>
                            <Typography style={styles.ShippingLTLQuickQuoteAddressWarnText}>
                                {
                                    specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) && intl.get('SHIPPING_INFORMATION.RESIDENCY_ADDRESS')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel>
                                {intl.get('SHIPPING_INFORMATION.ADDRESS_2')}
                            </InputLabel>
                            <TextField
                                value={shipTo?.address2}
                                fullWidth
                                variant="outlined"
                                onInput={handleShipToAddress2}
                                sx={{padding: '2px'}}
                                size='small'
                                disabled={confirm}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel>
                                {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                            </InputLabel>
                            <TextField
                                value={shipTo?.postalCode}
                                fullWidth
                                variant="outlined"
                                onInput={handleShipToPostalCode}
                                sx={{padding: '2px'}}
                                size='small'
                                disabled={confirm}
                            />
                            <Typography style={styles.ShippingLTLQuickQuoteAddressWarnText}>
                                {
                                    !matchShipTo && `Please using recommend postal code ${matchCodeShipTo}. Otherwise, it likely not place order.`
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.NAME')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                disabled={confirm}
                                value={consigneeInfo?.addressList}
                                fullWidth
                                options={searchMovingToAddressList}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.name}
                                onInputChange={handleInputChangeConsigneeName}
                                onChange={handleChangeConsigneeName}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.name} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                                    </Typography>
                                )}
                            />
                            <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                {
                                    validation === null ? "" :
                                        validation?.consigneeInfoMissing?.includes('name') ? intl.get('SHIPPING_INFORMATION.REQUIRED')
                                            : ""
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel required>
                                {intl.get('SHIPPING_INFORMATION.PHONE')}
                            </InputLabel>
                            <TextField
                                value={consigneeInfo?.phone}
                                size='small'
                                fullWidth
                                variant="outlined"
                                onInput={handleInputConsigneePhone}
                            />
                            <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                                {
                                    validation === null ? "" :
                                        isValidatePhone(consigneeInfo?.phone) ? '' :
                                            intl.get('SHIPPING_INFORMATION.VALID_PHONE')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel>
                                {intl.get('SHIPPING_INFORMATION.COMPANY')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                disabled={confirm}
                                value={consigneeInfo?.addressList}
                                fullWidth
                                options={searchMovingToAddressList}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.company_name}
                                onInputChange={handleInputChangeConsigneeCompany}
                                onChange={handleChangeConsigneeCompany}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.company_name} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                                    </Typography>
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                            <InputLabel>
                                {intl.get('SHIPPING_INFORMATION.EMAIL')}
                            </InputLabel>
                            <Autocomplete
                                freeSolo
                                disabled={confirm}
                                value={consigneeInfo?.addressList}
                                fullWidth
                                options={searchMovingToAddressList}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.address_email}
                                onInputChange={handleInputChangeConsigneeEmail}
                                onChange={handleChangeConsigneeEmail}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                    placeholder={intl.get('SHIPPING_INFORMATION.RECOMMEND')}
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option?.address_email} | {`${option?.address_2}-`}{option?.address}, {option?.city}, {option?.province}, {option?.country}
                                    </Typography>
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Typography sx={styles.ShippingLTLQuickQuoteSelectRateHeading}>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.PICKUP_DATE_TIME')}
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('PICKUP_DATE_TIME.DATE')}
                    </InputLabel>
                    <TextField
                        fullWidth
                        disabled={confirm}
                        type='date'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDataAndTime?.pickupDate}
                        onChange={handlePickupDate}
                        inputProps={{
                            min: getMinDate(),
                        }}
                        size='small'
                    />
                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                        {/*{*/}
                        {/*    moveFromMissing.includes('city') ? "This is required field." : ''*/}
                        {/*}*/}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('PICKUP_DATE_TIME.EARLIEST_TIME')}
                    </InputLabel>
                    <TextField
                        fullWidth
                        disabled={confirm}
                        type='time'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDataAndTime?.earliestPickupTime}
                        onChange={handlePickupEarliestTime}
                        // inputProps={{
                        //     min: currentDate,
                        // }}
                        size='small'
                    />
                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                        {/*{*/}
                        {/*    moveFromMissing.includes('city') ? "This is required field." : ''*/}
                        {/*}*/}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <InputLabel>
                        {intl.get('PICKUP_DATE_TIME.LATEST_TIME')}
                    </InputLabel>
                    <TextField
                        fullWidth
                        disabled={confirm}
                        type='time'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDataAndTime?.latestPickupTime}
                        onChange={handlePickupLatestTime}
                        // inputProps={{
                        //     min: currentDate,
                        // }}
                        size='small'
                    />
                    <Typography style={styles.ShippingLTLQuickQuoteAddressErrorText}>
                        {/*{*/}
                        {/*    moveFromMissing.includes('city') ? "This is required field." : ''*/}
                        {/*}*/}
                    </Typography>
                </Box>
            </Grid>
            <Modal
                open={openShipperModal}
                onClose={handleCloseShipperModal}
            >
                <Box sx={styles.ShippingLTLAddressModalBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', minWidth: 400}}>
                                <Typography style={{fontSize: '20px'}}>
                                    {intl.get('SELECT_ADDRESS.ADDRESS_LIST')}
                                </Typography>
                                <Button
                                    color='success'
                                    onClick={handleNewAddress}
                                >
                                    {intl.get('SELECT_ADDRESS.ADD_NEW')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                    }}>
                        {
                            shipperAddressList?.length > 0 ? shipperAddressList.map((address, index) =>
                                    (
                                        <Box
                                            sx={styles.ShippingLTLAddressModalSelectBox}
                                            key={index}
                                            onClick={() => handleSelectShipFromAddress(address)}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                                        <HomeIcon color='success'/>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <Typography
                                                            style={styles?.ShippingLTLAddressModalText}>
                                                            {address?.name}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingLTLAddressModalText}>
                                                            {address?.address_2 && `${address?.address_2} - `}{address?.address}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingLTLAddressModalText}>
                                                            {address?.city}, {address?.province}, {address?.country} {address?.postal_code}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingLTLAddressModalText}>
                                                            {address?.address_email}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingLTLAddressModalText}>
                                                            {address?.phone}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                {/*<Grid item xs={2}>*/}
                                                {/*    <Box sx={{display: 'flex', flexDirection: 'column'}}>*/}
                                                {/*        <Button>*/}
                                                {/*            */}
                                                {/*        </Button>*/}
                                                {/*    </Box>*/}
                                                {/*</Grid>*/}
                                            </Grid>
                                        </Box>

                                    ))
                                : <Grid item xs={12}>
                                    <Typography>
                                        {intl.get('SELECT_ADDRESS.NO_ADDRESS')}
                                    </Typography>
                                </Grid>
                        }
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openConsigneeModal}
                onClose={handleCloseConsigneeModal}
            >
                <Box sx={styles.ShippingLTLAddressModalBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', minWidth: 400}}>
                                <Typography style={{fontSize: '20px'}}>
                                    {intl.get('SELECT_ADDRESS.ADDRESS_LIST')}
                                </Typography>
                                <Button
                                    color='success'
                                    onClick={handleNewAddress}
                                >
                                    {intl.get('SELECT_ADDRESS.ADD_NEW')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                    }}>
                        {
                            consigneeAddressList?.length > 0 ? consigneeAddressList.map((address, index) =>
                                    (
                                        <Box
                                            sx={styles.ShippingLTLAddressModalSelectBox}
                                            key={index}
                                            onClick={() => handleSelectShipToAddress(address)}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                                        <HomeIcon color='success'/>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <Typography
                                                            style={styles?.ShippingLTLAddressModalText}>
                                                            {address?.name}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingLTLAddressModalText}>
                                                            {address?.address_2 && `${address?.address_2} - `}{address?.address}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingLTLAddressModalText}>
                                                            {address?.city}, {address?.province}, {address?.country} {address?.postal_code}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingLTLAddressModalText}>
                                                            {address?.address_email}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingLTLAddressModalText}>
                                                            {address?.phone}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                {/*<Grid item xs={2}>*/}
                                                {/*    <Box sx={{display: 'flex', flexDirection: 'column'}}>*/}
                                                {/*        <Button>*/}
                                                {/*            */}
                                                {/*        </Button>*/}
                                                {/*    </Box>*/}
                                                {/*</Grid>*/}
                                            </Grid>
                                        </Box>

                                    ))
                                : <Grid item xs={12}>
                                    <Typography>
                                        {intl.get('SELECT_ADDRESS.NO_ADDRESS')}
                                    </Typography>
                                </Grid>
                        }
                    </Box>
                </Box>
            </Modal>
        </Grid>
    )
}