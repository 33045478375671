import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import {TransportationAvailableTasksEach} from "./TransportationAvailableTasksEach";
import {Pagination} from "@mui/lab";

const styles = {
    ShippingPackageCreateLabelRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },
    ShippingPackageCreateLabelRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelRowRight: {
        display: 'flex',
        justifyContent: 'flex-end'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}

export const TransportationAvailableTasksList = ({availableTasks, currentPage, lastPage, handlePageChange}) => {

    const pageChange = (event, value) => {
        handlePageChange(value);
    }

    return (
        <Box style={styles.ShippingPackageCreateLabelRoot}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography>
                        Pickup Area
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>
                        Deliver Area
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>
                        Pickup Date
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>
                        Deliver Distance
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>
                        Cost
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>
                        Action
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {
                    availableTasks?.map((task, index) => <TransportationAvailableTasksEach key={index}
                                                                                           taskDetails={task}/>)
                }
                {/*<Pagination*/}
                {/*    count={lastPage}*/}
                {/*    page={+currentPage}*/}
                {/*    size='small'*/}
                {/*    // defaultPage={6}*/}
                {/*    onChange={pageChange}*/}
                {/*/>*/}
            </Grid>
        </Box>
    )
}