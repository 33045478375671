import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import LoadingContainer from "../shared/loadingContainer";
import {getUserProfile, updateUserInfo} from "../redux/userSlice";
import {deleteToken, getAccessToken} from "../utils/doToken";
import MenuBar from "./MenuBar";
import {Box} from "@material-ui/core";
import {SideBar} from "./SideBar";
import {Footer} from "./Footer";
import Drawer from "@mui/material/Drawer";
import {Snackbar, useMediaQuery} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";
import {deleteUserInfo} from "../slices/userSlice";
import {getWelcome} from "../utils/Helper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SafariShareIcon from "../images/icons/safariShare";
import Cookies from "js-cookie";

const drawerWidth = 240;

const MainContainer = ({children}) => {
    //   const classes = useStyles();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(true);
    const [windowsOpen, setWindowsOpen] = useState(false);
    const [iosOpen, setIosOpen] = useState(false);
    const {signIn, isLoading, dataLoaded, isPartner} = useSelector((state) => state.user);
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [curToken, setCurToken] = useState(Boolean(getAccessToken("access_token")));

    const signOut = (e) => {
        const storedToken = getAccessToken("access_token");
        try {
            axios.get(`https://admin.uucargo.ca/api/logout`, {
                headers: {Authorization: `Bearer ${storedToken}`},
            });
            //setHide(true)
        } finally {
            deleteToken("access_token");
            dispatch(deleteUserInfo());
            dispatch(updateUserInfo({field: "login", value: false}));
            dispatch(updateUserInfo({field: "signIn", value: false}));
        }
    };

    const cbHandleOpen = () => {
        setOpen(!open);
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setWindowsOpen(false);
    };

    const handleIosClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setIosOpen(false);
    };

    useEffect(() => {
        const isToken = Boolean(getAccessToken("access_token"));
        setCurToken(isToken);
    });

    useEffect(() => {
        const cachesClear = async () => {
            if (caches) {
                const names = await caches.keys();
                await Promise.all(names.map((name) => caches.delete(name)));
            }
        };
        cachesClear();
    }, []);

    useEffect(() => {
        if (curToken) dispatch(updateUserInfo({field: "signIn", value: true}));
        else dispatch(updateUserInfo({field: "signIn", value: false}));
    }, [curToken, dispatch]);

    useEffect(() => {
        const loadUsingPrevToken = !dataLoaded && signIn;
        // const loadAfterSignIn = !dataLoaded && login;

        if (loadUsingPrevToken) {
            const token = getAccessToken("access_token");
            token && dispatch(getUserProfile());
        }
    }, [dataLoaded, dispatch, signIn]);

    useEffect(() => {
        isMobile ? setOpen(false) : setOpen(true);
    }, [isMobile])

    useEffect(() => {
        const isInstallApp = Cookies.get('installApp');
        console.log('is install app', isInstallApp);
        // Detects if device is on iOS
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        };
        // Detects if device is in standalone mode
        const isInStandaloneMode = () =>
            "standalone" in window.navigator && window.navigator.standalone;

        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode() && !isInstallApp) {
            setIosOpen(true);
        }
        // Initialize deferredPrompt for use later to show browser install prompt.
        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            setDeferredPrompt(e);
            // Update UI notify the user they can install the PWA
            // showInstallPromotion();
            // Optionally, send analytics event that PWA install promo was shown.
            !isInstallApp && setWindowsOpen(true);
        });

        if (!isInstallApp) {
            Cookies.set('installApp', 'yes', {expires: 30});
        }
    }, []);

    useEffect(() => {
        if (!isPartner && signIn && curToken) {
            deleteToken("access_token");
        }
    }, [isPartner, signIn, curToken])

    console.log('[MainContainer] current Token', curToken);

    return (
        <Box sx={{display: 'flex', position: 'relative', minHeight: '100vh'}}>
            <MenuBar
                deferredPrompt={deferredPrompt}
                setDeferredPrompt={setDeferredPrompt}
                openMenu={open}
                cbHandleOpen={cbHandleOpen}
            />
            {
                (signIn && open && isPartner) &&
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            marginTop: '75px',
                            height: '85vh',
                            maxHeight: '100vh'
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                    open={true}
                >
                    <SideBar cbHandleOpen={cbHandleOpen}/>
                </Drawer>
            }
            {(!isMobile || !open) &&
                <Box component="main" sx={{flexGrow: 1, margin: "100px 0"}}>
                    {isLoading && signIn ? <LoadingContainer/> : children}
                </Box>
            }
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={windowsOpen}
                onClose={handleClose}
                message="Add to home screen"
                action={
                    <React.Fragment>
                        <Button
                            color="secondary"
                            size="small"
                            onClick={() => {
                                deferredPrompt.prompt();
                                setDeferredPrompt(null);
                                handleClose();
                            }}
                        >
                            Add
                        </Button>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={iosOpen}
                onClose={handleIosClose}
                message={
                    <span>
            Install this webapp: tab <SafariShareIcon/> and then click add to
            home screen
          </span>
                }
                // message="Add to home screen: tab <SafariShareIcon/> below arrow"
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleIosClose}
                        >
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            />
            <Footer/>
        </Box>
    );
};

MainContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
        .isRequired,
};

export default MainContainer;
