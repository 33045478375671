import React, { useRef, useState} from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

const getStatusText = (status) => {
  switch (status) {
    case 0:
      return "Initial";
    case 1:
      return "Active";
    case 2:
      return "Pause";
    case 3:
      return "Cancelled By Owner"
    case 4:
      return "Cancelled By Operator"
    default:
      return ""
  }
}

export default function PalletRequestTableLanding({ requestId,
                                              senderName,
                                              pickupAddress,
                                              pickupCity,
                                              pickupProvince,
                                              pickupCountry,
                                              pickupPostalCode,
                                              deliverName,
                                              deliverAddress,
                                              deliverCity,
                                              deliverProvince,
                                              deliverCountry,
                                              deliverPostalCode,
                                              pickupAddressType,
                                              deliverAddressType,
                                              scheduleDate,
                                              status
}) {
  const classes = useStyles();

  return (
      <>
        <tr className={classes.listItemWrapper}>
          <td>
            <Link to={`/LTLs/review-confirm?requestId=${requestId}`}>{requestId}</Link>
          </td>
          <td>
            {senderName}
            <br />
            {pickupAddress}
            <br />
            {pickupCity}, {pickupProvince}, {pickupCountry}
            <br />
            {pickupPostalCode}
          </td>
          <td>
            {deliverName}
            <br />
            {deliverAddress}
            <br />
            {deliverCity}, {deliverProvince}, {deliverCountry}
            <br />
            {deliverPostalCode}
          </td>
          <td>
            {pickupAddressType} / {deliverAddressType}
          </td>
          <td>
            {scheduleDate? dayjs(scheduleDate).format("YYYY-MM-DD") : (
                <b style={{ visibility: "hidden" }} >YYYY-MM-DD</b>
            )}
          </td>
          <td>{getStatusText(status)}</td>
        </tr>
      </>
  );
}
