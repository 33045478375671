import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {Divider, Snackbar} from "@mui/material";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useState, Fragment} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import React from 'react';
import {getAccessToken} from "../../utils/doToken";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TransportationClaimTasksCost = ({
                                                 taskDetails,
                                                 underReviewPaymentList,
                                                 getOrderDetails,
                                                 paidPaymentList
                                             }) => {

    const storedToken = getAccessToken("access_token");

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [paymentConfirmLoading, setPaymentConfirmLoading] = useState(false);
    const [paymentConfirmDialog, setPaymentConfirmDialog] = useState(false);
    const [paymentDeclineLoading, setPaymentDeclineLoading] = useState(false);
    const [paymentDeclineDialog, setPaymentDeclineDialog] = useState(false);
    const [confirmPayment, setConfirmPayment] = useState();
    const [declinePayment, setDeclinePayment] = useState();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const confirmApprovePayment = async () => {
        setPaymentConfirmLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/partnerApproveSenderPayment`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    paymentId: confirmPayment?.id,
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully approve payment confirmation.');
            handleConfirmPaymentDialogClose();
            setTimeout(() => {
                getOrderDetails();
            }, 2000);
        } catch (e) {
            handleErrorMessage();
            setErrorMessage("Fail to approve payment confirmation.");
        } finally {
            setPaymentConfirmLoading(false);
        }
    }

    const confirmDeclinePayment = async () => {
        setPaymentDeclineLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/partnerDeclineSenderPayment`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    paymentId: declinePayment?.id,
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully declined payment confirmation.');
            handleDeclinePaymentDialogClose(false);
            setTimeout(() => {
                getOrderDetails();
            }, 2000);
        } catch (e) {
            handleErrorMessage();
            setErrorMessage("Fail to declined payment confirmation.");
        } finally {
            setPaymentDeclineLoading(false);
        }
    }

    const handleConfirmPayment = () => {
        confirmApprovePayment();
    }

    const handleConfirmPaymentDialogOpen = () => {
        setPaymentConfirmDialog(true);
    }

    const handleConfirmPaymentDialogClose = () => {
        setPaymentConfirmDialog(false);
    }

    const handleDeclinePayment = () => {
        confirmDeclinePayment();
    }

    const handleDeclinePaymentDialogOpen = () => {
        setPaymentDeclineDialog(true);
    }

    const handleDeclinePaymentDialogClose = () => {
        setPaymentDeclineDialog(false);
    }

    console.log('[TransportationClaimTasksCost] - paidPaymentList', paidPaymentList);

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Claimed Order Cost
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            {
                                                +taskDetails?.orderPrice?.moving_freight_charge > 0 &&
                                                <>
                                                    <Grid item xs={10}>
                                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                            Freight Charge
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{textAlign: 'right'}}>
                                                            $ {(+taskDetails?.orderPrice?.moving_freight_charge)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                +taskDetails?.orderPrice?.moving_pickup_charge > 0 &&
                                                <>
                                                    <Grid item xs={10}>
                                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                            Pickup Charge
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{textAlign: 'right'}}>
                                                            $ {(+taskDetails?.orderPrice?.moving_pickup_charge)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                +taskDetails?.orderPrice?.moving_deliver_charge > 0 &&
                                                <>
                                                    <Grid item xs={10}>
                                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                            {taskDetails?.deliver_addr_type === 'SELF' ? "Operation Charge" : "Delivery Charge"}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{textAlign: 'right'}}>
                                                            $ {(+taskDetails?.orderPrice?.moving_deliver_charge)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                +taskDetails?.orderPrice?.moving_storage_charge > 0 &&
                                                <>
                                                    <Grid item xs={10}>
                                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                            Storage Charge
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{textAlign: 'right'}}>
                                                            $ {(+taskDetails?.orderPrice?.moving_storage_charge)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                +taskDetails?.orderPrice?.moving_palleting_charge > 0 &&
                                                <>
                                                    <Grid item xs={10}>
                                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                            Pallet Charge
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography style={{textAlign: 'right'}}>
                                                            $ {(+taskDetails?.orderPrice?.moving_palleting_charge)?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                    Subtotal
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'right'}}>
                                                    $ {(+taskDetails?.orderPrice?.shipping_fee)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        (+taskDetails?.taskShippingFee - +taskDetails?.orderPrice?.shipping_fee) !== 0 &&
                                        <>
                                            <Grid item xs={10}>
                                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                    Adjustment Amount
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'right'}}>
                                                    $ {(+taskDetails?.taskShippingFee - +taskDetails?.orderPrice?.shipping_fee)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                    Adjusted Subtotal
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'right'}}>
                                                    $ {(+taskDetails?.taskShippingFee)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={10}>
                                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                    Tax
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'right'}}>
                                                    $ {(+taskDetails?.taskTaxCharge)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={10}>
                                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                    Total
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'right'}}>
                                                    $ {(+taskDetails?.taskFinalTotal)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            {
                                                paidPaymentList?.map((payment, index) => (
                                                    <Fragment key={index}>
                                                        <Grid item xs={10}>
                                                            <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                                {payment?.remark_notes === "Down_Payment" ? "Prepaid Amount" : "Payment"}                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography style={{textAlign: 'right'}}>
                                                                - $ {payment?.amount.toFixed(2)}
                                                            </Typography>
                                                        </Grid>
                                                    </Fragment>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={10}>
                                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                    Amount To Be Paid
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'right'}}>
                                                    $ {taskDetails?.task_unpaid_amount?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                underReviewPaymentList?.length > 0 &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Payment Under Review
                                        </Typography>
                                    </Grid>
                                    {
                                        underReviewPaymentList?.map((payment, index) => (
                                            <Fragment key={index}>
                                                <Grid item xs={2}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%'
                                                    }}>
                                                        <Typography style={{textAlign: 'left'}}>
                                                            {dayjs(payment?.created_at).format('YYYY-MM-DD')}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%'
                                                    }}>
                                                        <Typography style={{textAlign: 'left'}}>
                                                            {payment?.remark_notes === "Down_Payment" ? "Prepaid Amount" : "Payment"}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%'
                                                    }}>
                                                        <Typography style={{textAlign: 'left'}}>
                                                            $ {payment?.amount.toFixed(2)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%'
                                                    }}>
                                                        <Typography style={{textAlign: 'left'}}>
                                                            {payment?.type}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                    }}>
                                                        <Button
                                                            variant='contained'
                                                            sx={{
                                                                backgroundColor: '#1D8B45',
                                                                "&:hover": {
                                                                    backgroundColor: '#1D8B45',
                                                                    filter: 'brightness(0.9)'
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                handleConfirmPaymentDialogOpen();
                                                                setConfirmPayment(payment);
                                                            }}
                                                            disabled={paymentConfirmDialog || paymentDeclineDialog}
                                                        >
                                                            <Typography style={{
                                                                textAlign: 'left',
                                                                textTransform: 'none',
                                                            }}>
                                                                Confirm
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                    }}>
                                                        <Button
                                                            variant='contained'
                                                            sx={{
                                                                backgroundColor: '#454545',
                                                                "&:hover": {
                                                                    backgroundColor: '#454545',
                                                                    filter: 'brightness(0.9)'
                                                                }
                                                            }}
                                                            onClick={() => {
                                                                handleDeclinePaymentDialogOpen();
                                                                setDeclinePayment(payment);
                                                            }}
                                                            disabled={paymentConfirmDialog || paymentDeclineDialog}
                                                        >
                                                            <Typography style={{
                                                                textAlign: 'left',
                                                                textTransform: 'none',
                                                            }}>
                                                                Decline
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {
                                                        (paymentConfirmDialog && payment?.id === confirmPayment?.id) &&
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography style={{textAlign: 'left'}}>
                                                                    Are you sure you want to confirm the
                                                                    payment?
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end',
                                                                    gap: '10px'
                                                                }}>
                                                                    <Button
                                                                        variant='contained'
                                                                        sx={{
                                                                            backgroundColor: '#454545',
                                                                            "&:hover": {
                                                                                backgroundColor: '#454545',
                                                                                filter: 'brightness(0.9)'
                                                                            }
                                                                        }}
                                                                        // size='small'
                                                                        onClick={handleConfirmPaymentDialogClose}>
                                                                        <Typography
                                                                            sx={{textTransform: 'none'}}>
                                                                            Cancel
                                                                        </Typography>
                                                                    </Button>
                                                                    <LoadingButton
                                                                        variant='contained'
                                                                        sx={{
                                                                            backgroundColor: '#1D8B45',
                                                                            "&:hover": {
                                                                                backgroundColor: '#1D8B45',
                                                                                filter: 'brightness(0.9)'
                                                                            }
                                                                        }}
                                                                        // size='small'
                                                                        onClick={handleConfirmPayment}
                                                                        loading={paymentConfirmLoading}
                                                                    >
                                                                        <Typography
                                                                            sx={{textTransform: 'none'}}>
                                                                            Confirm
                                                                        </Typography>
                                                                    </LoadingButton>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {
                                                        (paymentDeclineDialog && payment?.id === declinePayment?.id) &&
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography style={{textAlign: 'left'}}>
                                                                    Are you sure you want to decline the
                                                                    payment?
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end',
                                                                    gap: '10px'
                                                                }}>
                                                                    <Button
                                                                        variant='contained'
                                                                        sx={{
                                                                            backgroundColor: '#454545',
                                                                            "&:hover": {
                                                                                backgroundColor: '#454545',
                                                                                filter: 'brightness(0.9)'
                                                                            }
                                                                        }}
                                                                        // size='small'
                                                                        onClick={handleDeclinePaymentDialogClose}>
                                                                        <Typography
                                                                            sx={{textTransform: 'none'}}>
                                                                            Cancel
                                                                        </Typography>
                                                                    </Button>
                                                                    <LoadingButton
                                                                        variant='contained'
                                                                        sx={{
                                                                            backgroundColor: '#1D8B45',
                                                                            "&:hover": {
                                                                                backgroundColor: '#1D8B45',
                                                                                filter: 'brightness(0.9)'
                                                                            }
                                                                        }}
                                                                        // size='small'
                                                                        onClick={handleDeclinePayment}
                                                                        loading={paymentDeclineLoading}
                                                                    >
                                                                        <Typography
                                                                            sx={{textTransform: 'none'}}>
                                                                            Confirm
                                                                        </Typography>
                                                                    </LoadingButton>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    <Divider/>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}