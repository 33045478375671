import {actionType} from "../../utils/Helper";

const initialState = {
    trackingNumber: null,
    trackingNumberBOL: null
}


export const trackingPackageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_TRACKING_NUMBER:
            return {...state, trackingNumber: action?.payload}
        case actionType.UPDATE_TRACKING_NUMBER_BOL:
            return {...state, trackingNumberBOL: action?.payload}
        default:
            return state
    }
}