import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ReactComponent as SuccessSubmitLogo} from "../../../images/crossborderIcon/SuccessSubmit.svg";
import intl from "react-intl-universal";
import Button from "@mui/material/Button";
import {useHistory, useParams} from "react-router-dom";
import {Divider} from "@mui/material";

const style = {
    LTLOrderDashboardCrossBorderRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const LTLOrderDashboardCrossBorder = ({orderData}) => {

    const history = useHistory();

    const {order_id: orderId} = useParams();

    console.log('[LTLOrderDashboardCrossBorder] order data', orderData);

    if (orderData?.bolInfo?.consignee_country === orderData?.bolInfo?.shipper_country) {
        return (
            <Box sx={style.LTLOrderDashboardCrossBorderRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '10px',
                            borderBottom: '1px solid #B2B2B2'
                        }}>
                            <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                                Cross Border Information
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '150px',
                            padding: '10px',
                            flexDirection: 'column'
                        }}>
                            <Typography style={{fontSize: '16px', fontWeight: '600', color: '#B2B2B2'}}>
                                Only Available For Cross Border Order
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box sx={style.LTLOrderDashboardCrossBorderRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                            Cross Border Information
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {
                        orderData?.commecialInvoices?.length > 0 ? <Box sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '150px',
                                padding: '10px',
                                flexDirection: 'column',
                                gap: '20px'
                            }}>
                                <SuccessSubmitLogo width="75px" height="75px"/>
                                <Typography style={{fontSize: '14px'}}>
                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.INVOICE_SUBMITTED_MESSAGE')}
                                </Typography>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => history.push(`/ltl-cross-border-dashboard/${orderId}`)}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        color: '#1D8B45',
                                    }}>
                                        {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.VIEW_DETAILS')}
                                    </Typography>
                                </Button>
                            </Box>
                            :
                            <Box sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '150px',
                                padding: '10px',
                                flexDirection: 'column',
                                gap: '20px'
                            }}>
                                <Typography style={{fontSize: '14px'}}>
                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.INVOICE_PROVIDED_MESSAGE')}
                                </Typography>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => history.push(`/ltl-cross-border-dashboard/${orderId}`)}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        color: '#1D8B45',
                                    }}>
                                        {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.EDIT')}
                                    </Typography>
                                </Button>
                            </Box>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 10px',
                    }}>
                        <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                            {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.PRODUCT_LIST')}
                        </Typography>
                    </Box>
                    {
                        orderData?.packingList?.map((element, index) => (
                            <Box sx={{padding: '5px 10px'}} key={index}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.NAME')}: {element?.item_name}
                                </Typography>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.UNIT_WEIGHT')}: {element?.weight} {element?.weight_unit}
                                </Typography>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.UNIT_PRICE')}: $ {element?.unit_price} {element?.currency_code}
                                </Typography>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.HS_CODE')}: {element?.hs_code}
                                </Typography>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('ORDER_DASHBOARD.CROSS_BORDER_INFORMATION.QUANTITY')}: {element?.quantity}
                                </Typography>
                                <Divider/>
                            </Box>
                        ))
                    }
                </Grid>
            </Grid>
        </Box>
    )
}