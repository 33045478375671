import {Container, Divider, MenuItem, Select, Snackbar, Stack, Switch} from "@mui/material";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {styled} from "@mui/material/styles";
import MuiAlert from "@mui/material/Alert";
import {internationalShipmentTypeList, transportationListToCodeMap} from "../../shared/constInfo";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import IconButton from "@mui/material/IconButton";
import addPackage from "../../images/Create Label.png";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import {useEffect, useState} from "react";
import {generateRandomString} from "../../utils/Helper";
import {
    addShippingLTLCrossBorderItemList,
    updateShippingLTLCrossBorderRate,
    updateShippingLTLCrossBorderTransAndType
} from "../Actions/shippingLTLAction";
import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import {ShippingLTLCreateLabelCrossBorderItem} from "./ShippingLTLCreateLabelCrossBorderItem";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCrossBorderRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },

    ShippingPackageCrossBorderHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },

    ShippingPackageCrossBorderSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 20,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const requiredProperties = ["quantity", "weight", "weightUnit", "UOM", "currency", "priceEach", "hscode", "originCountryCode"];
const validateRequiredProperties = ["quantity", "weight", "priceEach"];

export const ShippingLTLCreateLabelCrossBorder = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    let shipFrom = useSelector(state => state?.shippingLTL?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingLTL?.createLabelShipTo);
    let itemListRedux = useSelector(state => state?.shippingLTL?.crossBorderItemList);

    const [validation, setValidation] = useState(true);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [crossBorderFee, setCrossBorderFee] = useState(null);
    const [loading, setLoading] = useState(false);
    const [itemId, setItemId] = useState(1);
    const [metric, setMetric] = useState(false);
    const [crossBorder, setCrossBorder] = useState({
        transMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
    });

    const TransportationModeList = Object.entries(transportationListToCodeMap).map(([key, value]) => [key, value]);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleTranMode = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            transMode: event.target.value
        }))
    }

    const handleShipmentType = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            shipmentType: event.target.value
        }))
    }

    const handleMetric = (event) => {
        setMetric(event.target.checked);
    }

    const handleAddItem = () => {

        const itemId = generateRandomString(8);
        const newItem = {
            description: `item-${itemId}`,
            sku: '',
            weight: '',
            weightUnit: metric ? 'kg' : 'lb',
            UOM: 'BOX',
            hscode: null,
            originCountryCode: 'CN',
            currency: 'CAD',
            priceEach: '',
            quantity: '1',
            itemId: itemId
        }
        dispatch(addShippingLTLCrossBorderItemList(newItem));
        setItemId(prevState => prevState + 1);
    }

    const handleBack = () => {
        history.push('/LTL-create-label/rate-list');
    }

    const handleOrderDetails = () => {
        history.push('/LTL-create-label/order-details');
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const hasMissingValues = itemListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = itemListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));

        itemListRedux?.length === 0 && setErrorMessage(prevState => prevState + intl.get('CROSS_BORDER.VALIDATION.ITEM_LIST_EMPTY'));
        hasMissingValues && setErrorMessage(prevState => prevState + intl.get('CROSS_BORDER.VALIDATION.ITEM_LIST_MISSING'));
        hasValidateValues && setErrorMessage(prevState => prevState + intl.get('CROSS_BORDER.VALIDATION.ITEM_LIST_ERROR'));

        if (!hasMissingValues && !hasValidateValues && itemListRedux.length !== 0) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const getCrossBorderFee = async () => {

        setLoading(true);
        let requestURL = `${PARTNER_URI}/brokerage/ltl/landedCostByServiceName`;
        const token = getAccessToken("access_token");

        let data = {
            serviceName: "csa",
            importCountryCode: shipTo?.country,
            importProvince: shipTo?.province?.code,
            exportCountryCode: shipFrom?.country,
            transModes: crossBorder?.transMode,
            shipmentType: crossBorder?.shipmentType,
            shipmentItems: itemListRedux
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('result', result);
            if (result?.data?.error?.errors?.length > 0) {
                setErrorMessage(`Fail to get cross border fees. ${result?.data?.error?.errors[0]?.message}`);
                handleErrorMessage();
            } else {
                setErrorMessage('');
                setSuccessMessage('Successfully get cross border fees.');
                handleSuccessMessage();
                setCrossBorderFee(result?.data);
            }
            setLoading(false);
        } catch (e) {
            console.log('error', e.response);
            setLoading(false);
            setErrorMessage('Fail to get cross border fees.');
            handleErrorMessage();
        }
    }

    const handleGetCrossBorderRate = () => {
        const validationResult = validate();
        setValidation(validationResult);
        if (validationResult) {
            setCrossBorderFee(null);
            getCrossBorderFee();
        }
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    useEffect(() => {
        dispatch(updateShippingLTLCrossBorderRate(crossBorderFee));
    }, [crossBorderFee]);

    useEffect(() => {
        dispatch(updateShippingLTLCrossBorderTransAndType(crossBorder));
    }, [crossBorder])

    if (!shipFrom?.country) {
        return (
            <Container maxWidth='xl'>
                <Box style={styles.ShippingPackageCrossBorderRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCrossBorderHeading}>
                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CROSS_BORDER')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                You've lost all the data. Please return to the home page and create a new shipment.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                <Button
                                    variant='contained'
                                    onClick={() => history.replace('/home')}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Go Back Home
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box style={styles.ShippingPackageCrossBorderRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCrossBorderHeading}>
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CROSS_BORDER')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={styles.ShippingPackageCrossBorderRoot}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <Typography style={styles.ShippingPackageCrossBorderSubHeading}>
                                            {intl.get('CROSS_BORDER.PRODUCT_DETAILS')}
                                        </Typography>
                                        <Box sx={{display: 'flex'}}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <AntSwitch
                                                    checked={metric}
                                                    onChange={handleMetric}
                                                />
                                                <Typography>
                                                    {intl.get('CROSS_BORDER.METRIC')}
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <IconButton
                                                    sx={{color: '#609966'}}
                                                    onClick={handleAddItem}
                                                >
                                                    <img src={addPackage} width='35px'/>
                                                </IconButton>
                                                <Typography>
                                                    {intl.get('CROSS_BORDER.ADD_NEW_BTN')}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Grid>
                                {
                                    itemListRedux?.length > 0 ? itemListRedux?.map((eachItem, index) =>
                                        <Grid item xs={12} key={index}>
                                            <ShippingLTLCreateLabelCrossBorderItem
                                                itemInfo={eachItem}
                                                index={index}
                                                metric={metric}
                                                validation={validation}
                                            />
                                        </Grid>
                                    ) : <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid #B7B7B7',
                                            padding: '20px',
                                            borderRadius: '10px'
                                        }}>
                                            <Typography
                                                style={{
                                                    fontSize: '20px',
                                                    fontWeight: '600',
                                                    color: '#7F8487',
                                                    marginBottom: '10px'
                                                }}>
                                                {intl.get('CROSS_BORDER.EMPTY_ITEM_LIST')}
                                            </Typography>
                                            <IconButton
                                                sx={{color: '#609966'}}
                                                onClick={handleAddItem}
                                            >
                                                <img src={addPackage} width='35px'/>
                                            </IconButton>
                                            <Typography style={{fontSize: '12px', fontWeight: '600', color: '#609966'}}>
                                                {intl.get('CROSS_BORDER.ADD_NEW_TEXT')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                            <InputLabel required>
                                {intl.get('CROSS_BORDER.TRANSPORTATION_MODE')}
                            </InputLabel>
                            <FormControl variant="outlined" fullWidth>
                                <Select
                                    value={crossBorder?.transMode}
                                    onChange={handleTranMode}
                                    size='small'
                                >
                                    {
                                        TransportationModeList.map(value => {
                                            return <MenuItem value={value[0]}>{value[1]}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                            <InputLabel required>
                                {intl.get('CROSS_BORDER.SHIPMENT_TYPE')}
                            </InputLabel>
                            <FormControl variant="outlined" fullWidth>
                                <Select
                                    value={crossBorder?.shipmentType}
                                    onChange={handleShipmentType}
                                    size='small'
                                >
                                    {internationalShipmentTypeList.map(value => {
                                        return <MenuItem value={value}>{value}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px'
                }}>
                    <Button
                        variant='contained'
                        onClick={handleBack}
                        sx={{
                            backgroundColor: '#F2BE22',
                            "&:hover": {
                                backgroundColor: '#F2BE22',
                                filter: 'brightness(0.9)'
                            }
                        }}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.BACK_TO_RATE')}
                        </Typography>
                    </Button>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        loading={loading}
                        onClick={handleGetCrossBorderRate}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.GET_CROSS_BORDER_RATE')}
                        </Typography>
                    </LoadingButton>
                </Box>
                {
                    loading ?
                        <Box>
                            <CircularProgress/>
                        </Box>
                        :
                        crossBorderFee &&
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCrossBorderSubHeading}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.COST_DETAILS')}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <Typography style={{textAlign: 'right'}}>
                                        Total Brokerage Fees
                                    </Typography>
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <Typography style={{textAlign: 'right'}}>
                                        $ {crossBorderFee?.totalBrokerageFees?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <Typography style={{textAlign: 'right'}}>
                                        Total Duty And Taxes
                                    </Typography>
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <Typography style={{textAlign: 'right'}}>
                                        $ {crossBorderFee?.totalDutyandTax?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <Typography style={{textAlign: 'right', fontSize: '16px', fontWeight: '600'}}>
                                        Total
                                    </Typography>
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <Typography style={{textAlign: 'right', fontSize: '16px', fontWeight: '600'}}>
                                        $ {crossBorderFee?.grandTotal?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '10px'
                            }}>
                                <Button
                                    variant='contained'
                                    onClick={handleOrderDetails}
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.ORDER_DETAILS')}
                                    </Typography>
                                </Button>
                            </Box>
                        </>
                }
            </Box>
        </Container>
    )
}