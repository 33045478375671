import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BusinessIcon from "@material-ui/icons/Business";
import {Tab, Tabs, useMediaQuery} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import Button from "@mui/material/Button";
import {EstimatePackage} from "./EstimatePackage";
import {EstimatePallet} from "./EstimatePallet";
import {useDispatch, useSelector} from "react-redux";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {
    clearLandingEstimatePackageCost,
    clearLandingEstimatePalletCost, updateLandingClickEstimate,
    updateLandingEstimateCostError,
    updateLandingEstimateCostLoading,
    updateLandingEstimateDeliverPostalCodeValid,
    updateLandingEstimatePackageCost,
    updateLandingEstimatePackageMissing,
    updateLandingEstimatePalletCost,
    updateLandingEstimatePalletMissing,
    updateLandingEstimatePickupPostalCode,
    updateLandingEstimatePickupPostalCodeValid,
    updateLandingEstimateTypeService
} from "../Actions/landingEstimateAction";
import {
    getLocation,
    getMissingKeysForLandingPackage,
    getMissingKeysForLandingPallet,
    isValidateEmail, isValidPostalCode, isValidZipCode, loadAsyncScript
} from "../../utils/Helper";
import LoadingContainer from "../../shared/loadingContainer";
import {EstimateCost} from "./EstimateCost";
import {Divider} from '@material-ui/core';
import TextField from "@mui/material/TextField";
import intl from "react-intl-universal";

const styles = {
    EstimateRoot: {
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: '500px',
        // width: '500px',
        width: '50%',
        margin: '0 auto',
        gap: '10px',
        padding: '30px',
        '@media (max-width: 1024px)': {
            width: '100%', // for tablets and above
        },
        '@media (max-width: 480px)': {
            width: '100%',
            padding: 0// for phone and above
        },
    },

    EstimateContent: {
        backgroundColor: '#FFFFFF',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        borderRadius: '10px',
        padding: '30px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    },

    EstimateContentTabs: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    EstimateContentTabEach: {
        width: '100%'
    },
    EstimateContentButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    EstimateWarningText: {
        fontSize: '12px',
        color: '#FF0303'
    },
    EstimateHeading: {
        textAlign: 'left',
        fontSize: '40px',
        fontWeight: '600',
        padding: '10px 0',
        color: 'green',
        '@media (max-width: 480px)': {
            fontSize: '25px',
        },
    },
    EstimateText: {
        textAlign: 'left',
        fontSize: '14px',
    },
    EstimateIntro: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    EstimateButton: {
        color: '#FFFFFF',
        backgroundColor: 'green',
        width: '60%',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    }
}

export const Estimate = () => {

    const dispatch = useDispatch();

    let packageData = useSelector(state => state?.landingEstimate?.landingEstimatePackage);
    let palletData = useSelector(state => state?.landingEstimate?.landingEstimatePallet);

    console.log('redux store pallet Data', palletData);

    const [currentLocation, setCurrentLocation] = useState();
    const [showEmail, setShowEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [shipmentType, setShipmentType] = useState('package');
    const [estimateType, setEstimateType] = useState('');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width: 480px)');

    const handleTabChange = (event, newValue) => {
        setShipmentType(newValue)
        setEmail('');
        setIsValidEmail(true);
        setIsEmailEmpty(false);
        setShowEmail(false);
    }

    const validateEmail = () => {
        if (email) {
            setIsEmailEmpty(false);
            const isValid = isValidateEmail(email);
            if (isValid) {

                setIsValidEmail(true);
                return true;
            } else {
                setIsValidEmail(false);
                return false;
            }
        } else {
            setIsEmailEmpty(true);
            return false
        }
    }

    const validatePickupPostalCode = () => {
        if (packageData?.pickupCountry?.code === 'CA') {
            const result = isValidPostalCode(packageData?.pickupPostalCode);
            console.log('pick up postal code', result);
            dispatch(updateLandingEstimatePickupPostalCodeValid(result));
            return result;

        } else {
            const result = isValidZipCode(packageData?.pickupPostalCode);
            console.log('pick up postal code', result);
            dispatch(updateLandingEstimatePickupPostalCodeValid(result));
            return result;

        }
    }

    const validateDeliverPostalCode = () => {
        if (packageData?.deliverCountry?.code === 'CA') {
            const result = isValidPostalCode(packageData?.deliverPostalCode);
            console.log('deliver postal code', result);
            dispatch(updateLandingEstimateDeliverPostalCodeValid(result));
            return result;

        } else {
            const result = isValidZipCode(packageData?.deliverPostalCode);
            console.log('deliver postal code', result);
            dispatch(updateLandingEstimateDeliverPostalCodeValid(result));
            return result;

        }
    }

    const validatePostalCode = () => {
        const pickUpPostalCode = validatePickupPostalCode();
        const deliverPostalCode = validateDeliverPostalCode();
        return (pickUpPostalCode && deliverPostalCode);
    }

    const addEmail = () => {
        let requestURL = `${PARTNER_URI}/collectEmail/addEmail`;
        axios.request({
            method: 'post',
            url: requestURL,
            data: {
                senderEmail: email
            }
        }).then((response) => {
            console.log(JSON.stringify(response.data))
        }).catch((error) => {
            console.log(error)
        })
    }

    const partnerGetRatingByAccountBaseWithoutToken = async (accountBase) => {
        setIsLoading(true);
        dispatch(updateLandingEstimateCostLoading(true));
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/rates/landing`;
        const data = {
            rateData: {
                shipFromAddress: "",
                shipFromAddressTwo: "",
                shipFromCity: "",
                shipFromProvince: packageData?.pickupProvince?.code,
                shipFromPostalCode: packageData?.pickupPostalCode.replace(/\s+/g, '').toUpperCase(),
                shipFromCountry: packageData?.pickupCountry?.code,
                shipFromName: "",
                shipFromPhone: "",
                shipToAddress: "",
                shipToAddressTwo: "",
                shipToCity: "",
                shipToPostalCode: packageData?.deliverPostalCode.replace(/\s+/g, '').toUpperCase(),
                shipToProvince: packageData?.deliverProvince?.code,
                shipToCountry: packageData?.deliverCountry?.code,
                shipToName: "",
                shipToPhone: "",
                packageData: [
                    {
                        length: packageData?.length,
                        width: packageData?.width,
                        height: packageData?.height,
                        weight: packageData?.weight,
                        quantity: packageData?.quantity,
                        lengthUnit: packageData?.dimensionUnit,
                        weightUnit: packageData?.weightUnit
                    }
                ]
            },
            selectedAccountBase: accountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            const ratingResult = result.data.result;
            console.log(`package rate ${accountBase}`, result.data.result);
            result.data.result.length > 0 && dispatch(updateLandingEstimatePackageCost(ratingResult));
            setIsLoading(false);
            dispatch(updateLandingEstimateCostLoading(false));
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            dispatch(updateLandingEstimateCostLoading(false));
        }
    }

    const partnerGetRatingByPallet = async () => {
        setIsLoading(true);
        dispatch(updateLandingEstimateCostLoading(true));
        let requestURL = `${PARTNER_URI}/shippingLTL/LTLShippingRatingForLanding`;
        const data = {
            shipperAddress: "",
            shipperAddressType: "warehouse",
            shipperCity: palletData?.pickupCity,
            shipperProvince: palletData?.pickupProvince?.code,
            shipperPostalCode: palletData?.pickupPostalCode,
            shipperCountry: palletData?.pickupProvince?.countryCode,
            consigneeAddress: "",
            consigneeAddressType: "warehouse",
            consigneeCity: palletData?.deliverCity,
            consigneeProvince: palletData?.deliverProvince?.code,
            consigneePostalCode: palletData?.deliverPostalCode,
            consigneeCountry: palletData?.deliverProvince?.countryCode,
            packageData: [
                {
                    length: palletData?.packagingType?.length,
                    height: palletData?.packagingType?.height,
                    width: palletData?.packagingType?.width,
                    quantity: palletData?.quantity,
                    weight: "400",
                    description: "",
                    lengthUnit: "in",
                    weightUnit: "lb",
                    hscode: "",
                    originCountryCode: "",
                    currency: "",
                    priceEach: "",
                    UOM: ""
                }
            ]
        }
        console.log('api data', data)

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            // console.log('result', result);
            const lowestCostElement = result?.data?.reduce((minObj, obj) => {
                return obj.finalTotal < minObj.finalTotal ? obj : minObj;
            }, {finalTotal: Infinity})

            // console.log('lowest', lowestCostElement);

            dispatch(updateLandingEstimatePalletCost(lowestCostElement));
            setError(false);
            dispatch(updateLandingEstimateCostError(false));
            setIsLoading(false);
            dispatch(updateLandingEstimateCostLoading(false));
        } catch (e) {
            console.log(e.response);
            setError(true);
            dispatch(updateLandingEstimateCostError(true));
            setIsLoading(false);
            dispatch(updateLandingEstimateCostLoading(false));
        }
    }

    const getPackageEstimateCost = () => {
        const emailValidation = validateEmail();
        const postalCodeValidation = validatePostalCode();
        const missingKeys = getMissingKeysForLandingPackage(packageData);
        dispatch(updateLandingEstimatePackageMissing(missingKeys));
        if (missingKeys.length === 0 && emailValidation && postalCodeValidation) {
            addEmail();
            setEstimateType('package');
            dispatch(updateLandingEstimateTypeService('package'));
            dispatch(clearLandingEstimatePackageCost());
            if (packageData?.pickupCountry.code === packageData?.deliverCountry.code) {
                partnerGetRatingByAccountBaseWithoutToken('ups');
                partnerGetRatingByAccountBaseWithoutToken('canadapost');
                partnerGetRatingByAccountBaseWithoutToken('fedex');
                partnerGetRatingByAccountBaseWithoutToken('canpar');
            } else {
                partnerGetRatingByAccountBaseWithoutToken('ups');
            }
        } else {
            console.log('show error')
        }
    }

    const getPalletEstimateCost = () => {
        const emailValidation = validateEmail();
        const missingKeys = getMissingKeysForLandingPallet(palletData);
        dispatch(updateLandingEstimatePalletMissing(missingKeys));
        if (missingKeys.length === 0 && emailValidation) {
            addEmail();
            setEstimateType('pallet');
            dispatch(updateLandingEstimateTypeService('pallet'));
            dispatch(clearLandingEstimatePalletCost());
            partnerGetRatingByPallet();
        } else {
            console.log('show error')
        }

    }

    const handleEstimateButton = () => {

        if (shipmentType === 'package') {
            getPackageEstimateCost();
        } else {
            getPalletEstimateCost();
        }

    }

    const estimateSection = useRef(null);

    const scrollDown = (ref) => {
        // console.log(ref.current.offsetTop)
        window.scrollTo({
            top: ref.current.offsetTop - 100,
            behavior: 'smooth',
        });
    };

    const getCurrentLocation = async () => {
        const location = await getLocation();
        console.log(location.coords);
        const geocoder = new window.google.maps.Geocoder();
        const latlng = {lat: location.coords.latitude, lng: location.coords.longitude}
        geocoder.geocode({location: latlng}, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    const addressComponents = results[0].address_components;
                    console.log(results[0].address_components)
                    let address = "";
                    let city = "";
                    let province = "";
                    let country = "";
                    let postalCode = "";
                    for (let i = 0; i < addressComponents.length; i++) {
                        const component = addressComponents[i];
                        const componentType = component.types[0];

                        switch (componentType) {
                            case "street_number":
                                address += component.long_name + " ";
                                break;
                            case "route":
                                address += component.long_name;
                                break;
                            case "locality":
                                city = component.long_name;
                                break;
                            case "administrative_area_level_1":
                                province = {code: component.short_name, name: component.long_name};
                                break;
                            case "country":
                                country = {code: component.short_name, name: component.long_name};
                                break;
                            case "postal_code":
                                postalCode = component.long_name;
                                break;
                        }
                    }
                    setCurrentLocation({
                        address: address,
                        city: city,
                        province: province,
                        country: country,
                        postalCode: postalCode
                    })
                } else {
                    console.log("No results found");
                }
            } else {
                console.log("Geocoder failed due to: " + status);
            }
        });
    }

    // init map script
    const initMapScript = () => {
        console.log('estimate load google api');
        // if script already loaded
        console.log('estimate', window.google);
        if (window.google && window.google.maps) {
            console.log('include');
            return Promise.resolve();
        }
        const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places&v=weekly`;
        return loadAsyncScript(src)
    }

    useEffect(() => {
        initMapScript().then(() => {
            return getCurrentLocation();
        })
    }, []);

    // useEffect(() => {
    //     scrollDown(estimateSection)
    // }, [isLoading])

    useEffect(() => {
        const missingKeys = getMissingKeysForLandingPackage(packageData);
        console.log('missing', missingKeys)
        if (shipmentType === 'package') {
            missingKeys.length === 0 ? setShowEmail(true) : setShowEmail(false);
        }
    }, [packageData])

    useEffect(() => {
        const missingKeys = getMissingKeysForLandingPallet(palletData);
        console.log('missing', missingKeys)
        if (shipmentType === 'pallets') {
            missingKeys.length === 0 ? setShowEmail(true) : setShowEmail(false);
        }
    }, [palletData])

    console.log('pallet', palletData);

    return (
        <Box sx={styles.EstimateRoot}>
            <Box sx={styles.EstimateIntro}>
                <Typography sx={styles.EstimateHeading}>
                    {intl.get('LANDING_PAGE.CALCULATOR.CALCULATOR')}
                </Typography>
                <Typography sx={styles.EstimateText}>
                    {intl.get('LANDING_PAGE.CALCULATOR.TEXT')}
                </Typography>
            </Box>
            <Box sx={styles.EstimateContent}>
                {/*{isLoading ? <LoadingContainer/> : <EstimateCost estimateType={estimateType} error={error}/>}*/}
                <Box sx={styles.EstimateContentTabs}>
                    <Box sx={styles.EstimateContentTabEach}>
                        <Tabs
                            value={shipmentType}
                            onChange={handleTabChange}
                            centered
                            variant="fullWidth"
                        >
                            <Tab value="package" label={intl.get('LANDING_PAGE.CALCULATOR.PACKAGE')}
                                 sx={{width: '100%'}}/>
                            <Tab value="pallets" label={intl.get('LANDING_PAGE.CALCULATOR.PALLET')}
                                 sx={{width: '100%'}}/>
                        </Tabs>
                    </Box>
                </Box>
                {shipmentType === 'package' ? <EstimatePackage curentLocation={currentLocation}/> :
                    <EstimatePallet currentLocation={currentLocation}/>}
                {showEmail &&
                    <Box>
                        <TextField
                            value={email}
                            label={intl.get('LANDING_PAGE.CALCULATOR.EMAIL')}
                            required
                            fullWidth
                            variant="standard"
                            onInput={e => setEmail(e.target.value)}
                        />
                        <Typography sx={styles.EstimateWarningText}>
                            {isEmailEmpty ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : !isValidEmail ? intl.get('LANDING_PAGE.CALCULATOR.INVALID_EMAIL') : ""}
                        </Typography>
                    </Box>
                }
                <Box sx={styles.EstimateContentButton}>
                    {isLoading ?
                        <LoadingContainer/>
                        :
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleEstimateButton();
                                dispatch(updateLandingClickEstimate(true));
                            }}
                            sx={styles.EstimateButton}
                        >
                            <Typography>
                                {intl.get('LANDING_PAGE.CALCULATOR.ESTIMATE')}
                            </Typography>
                        </Button>
                    }
                </Box>
            </Box>
            <Divider sx={{marginTop: '1px', width: '1px'}}/>
        </Box>
    )
}