import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import {visuallyHidden} from "@mui/utils";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import {Tooltip} from "@material-ui/core";
import ShippingTransactionTable from "./ShippingTransactionTable";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";
import LoadingButton from "@mui/lab/LoadingButton";
import PickupAppointmentDetailsCard from "./PickupAppointmentDetailsCard";
import PickupAppointmentRow from "./PickupAppointmentRow";

const headCells = [
    {
        id: "confirmationNumber",
        numeric: false,
        // disablePadding: true,
        label: "Confirmation #",
    },
    {
        id: "appointmentDate",
        numeric: false,
        // disablePadding: true,
        label: "Date",
    },
    {
        id: "address",
        numeric: false,
        // disablePadding: false,
        label: "Address",
    },
    {
        id: "contact",
        numeric: false,
        // disablePadding: false,
        label: "Contact",
    },
    {
        id: "packageInfo",
        numeric: false,
        // disablePadding: false,
        label: "Package Info",
    },
    {
        id: "status",
        numeric: false,
        // disablePadding: false,
        label: "Status",
    },
    {
        id: "cancelButton",
        numeric: false,
        label: ""
    }
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{fontWeight: "bold"}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function PickupAppointmentDetails({appointments}) {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    return (
        <Box sx={{width: "100%", height: "100%"}}>
            <Paper sx={{width: "100%", mb: 2, height: "100%",}}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer style={{maxHeight: 700}}>
                    <Table
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                    >
                        <EnhancedTableHead
                            numSelected={appointments.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={appointments.length}
                        />
                        <TableBody>
                            {
                                appointments.map(v => {
                                    // {
                                    //   "appoint_id": 65,
                                    //     "partner_id": 113,
                                    //     "owner_role": 3,
                                    //     "confirm_appoint_no": "CPU6430680",
                                    //     "account_base": "FedEx",
                                    //     "account_number": "740561073",
                                    //     "pickup_name": "UUCargo",
                                    //     "pickup_email": "aaa@gmail.com",
                                    //     "pickup_tel": "6049981888",
                                    //     "pickup_addr": "6751 Westminster Hwy",
                                    //     "pickup_city": "Richmond",
                                    //     "pickup_province": "BC",
                                    //     "pickup_postal_code": "V7C4V4",
                                    //     "pickup_country": "CA",
                                    //     "appoint_date": "2023-03-16",
                                    //     "appoint_time_start": "00:10:00",
                                    //     "appoint_time_end": "00:16:00",
                                    //     "number_of_count": 3,
                                    //     "estimate_weight": 33,
                                    //     "estimate_volume": 1,
                                    //     "weight_unit": "LB",
                                    //     "volume_unit": "CBM",
                                    //     "pickup_label_uri": "",
                                    //     "tracking_nos": "794951699629, 794951393872, 794951393666",
                                    //     "status": 1,
                                    //     "created_at": "2023-03-09 11:16:26",
                                    //     "updated_at": "2023-03-09 11:16:26"
                                    // }
                                    const {
                                        appoint_id,
                                        confirm_appoint_no,
                                        account_base,
                                        pickup_name,
                                        pickup_email,
                                        pickup_tel,
                                        pickup_addr,
                                        pickup_city,
                                        pickup_province,
                                        pickup_postal_code,
                                        pickup_country,
                                        appoint_date,
                                        appoint_time_start,
                                        appoint_time_end,
                                        number_of_count,
                                        estimate_weight,
                                        weight_unit,
                                        tracking_nos,
                                        status,
                                        created_at,
                                    } = v;

                                    return (
                                        <PickupAppointmentRow
                                            appoint_date={appoint_date}
                                            appoint_id={appoint_id}
                                            appoint_time_end={appoint_time_end}
                                            appoint_time_start={appoint_time_start}
                                            confirm_appoint_no={confirm_appoint_no}
                                            account_base={account_base}
                                            pickup_name={pickup_name}
                                            pickup_email={pickup_email}
                                            pickup_tel={pickup_tel}
                                            pickup_addr={pickup_addr}
                                            pickup_city={pickup_city}
                                            pickup_province={pickup_province}
                                            pickup_postal_code={pickup_postal_code}
                                            pickup_country={pickup_country}
                                            number_of_count={number_of_count}
                                            estimate_weight={estimate_weight}
                                            weight_unit={weight_unit}
                                            tracking_nos={tracking_nos}
                                            status={status}
                                            created_at={created_at}
                                        />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}
