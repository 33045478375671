import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {ShippingPackageQuickQuoteLocation} from "./ShippingPackageQuickQuoteLocation";
import {ShippingPackageQuickQuotePackageInformation} from "./ShippingPackageQuickQuotePackageInformation";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {Snackbar} from "@mui/material";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {getMissingKeysForQuickQuoteLocation} from "../../utils/Helper";
import {deleteToken, getAccessToken} from "../../utils/doToken";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {
    toggleShippingPackageIsGetRate,
    updateShipFromMissingQuickQuotePackage, updateShippingPackageRateQuickQuote,
    updateShipToMissingQuickQuotePackage
} from "../Actions/shippingPackageAction";
import {
    selectShippingLTLQuickQuoteRate,
    updateShippingLTLQuickQuotePlaceOrderValidate,
    updateShippingLTLQuickQuoteRate
} from "../Actions/shippingLTLAction";
import Button from "@mui/material/Button";
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const requiredProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight"];

export const ShippingPackageQuickQuoteGetRate = ({getIsCallAllCarrier}) => {

    const dispatch = useDispatch();

    let packageListRedux = useSelector(state => state?.shippingPackage?.quickQuotePackageList);
    let shipFrom = useSelector(state => state?.shippingPackage?.quickQuoteShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.quickQuoteShipTo);
    let batteryDetails = useSelector(state => state?.shippingPackage?.quickQuoteBatteryDetails);
    let signatureOptions = useSelector(state => state?.shippingPackage?.quickQuoteSignatureOptions);

    const [isGetRate, setIsGetRate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isCallAllCarrier, setIsCallAllCarrier] = useState(0);
    const [isCallAllCarrierLoading, setIsCallAllCarrierLoading] = useState(false);
    const [rate, setRate] = useState([]);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleError = (errorCode) => {
        switch (errorCode) {
            case 400:
                setErrorMessage("Bad Request: The server cannot process the request.");
                break;
            case 401:
                setErrorMessage("Unauthorized: Authentication is required to access the resource.");
                break;
            case 404:
                setErrorMessage("Not Found: The requested resource could not be found.");
                break;
            case 500:
                setErrorMessage("Internal Server Error: The server encountered an unexpected condition.");
                break;
            default:
                setErrorMessage("Unknown Error: An unknown error occurred.");
                break;
        }
    }

    const token = getAccessToken("access_token");

    const getShippingPackageRate = async (selectedAccountBase) => {
        setLoading(true);

        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/rates/all`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        console.log('package data', packageData);

        let data = {
            rateData: {
                shipFromAddress: shipFrom?.displayAddress?.text,
                shipFromAddressTwo: "",
                shipFromCity: shipFrom?.city,
                shipFromProvince: shipFrom?.province?.code,
                shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
                shipFromCountry: shipFrom?.country,
                shipFromName: "",
                shipFromPhone: "",
                shipToAddress: shipTo?.displayAddress?.text,
                shipToAddressTwo: "",
                shipToCity: shipTo?.city,
                shipToPostalCode: shipTo?.postalCode.replace(/\s+/g, '').toUpperCase(),
                shipToProvince: shipTo?.province?.code,
                shipToCountry: shipTo?.country,
                shipToName: "",
                shipToPhone: "",
                shipmentSpecialServices: {
                    returnShipment: false
                },
                packageData: packageData,
            },
            selectedAccountBase: selectedAccountBase
        }

        console.log('data', data.rateData);

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });
            const rateData = result?.data?.result;

            const updatedRateData = rateData.map((each) => ({
                ...each,
                errorAccountBase: selectedAccountBase?.toUpperCase()
            }));

            // console.log('rate data', rateData);

            setRate(prevState => [...prevState, ...updatedRateData]);
            setLoading(false);
            setIsGetRate(true);
            setErrorMessage('');
        } catch (e) {
            setLoading(false);
            console.log(e.response.status)
            if (e.response.status === 403) {
                setErrorMessage(`${e.response.data.error}. Please Please contact op@uucargo.ca.`);
            } else if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            }
            handleErrorMessage();
        } finally {
            setIsCallAllCarrier(prevState => prevState + 1);
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const shipFromMissing = getMissingKeysForQuickQuoteLocation(shipFrom);
        const shipToMissing = getMissingKeysForQuickQuoteLocation(shipTo);
        const hasMissingValues = packageListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = packageListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));
        const hasOverweightObject = packageListRedux.some(obj => {
            if (obj.weightUnit === 'kg') {
                const weightInLbs = obj.weight * 2.20462; // Convert kg to lbs
                return weightInLbs > 150;
            } else {
                return obj.weight > 150;
            }
        });
        const hasOverlengthItemName = packageListRedux?.some(obj => {
            return obj?.description?.length > 30;
        })

        dispatch(updateShipFromMissingQuickQuotePackage(shipFromMissing));
        dispatch(updateShipToMissingQuickQuotePackage(shipToMissing));

        packageListRedux?.length === 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_EMPTY'));
        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_MISSING'));
        shipToMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_TO_MISSING'));
        hasMissingValues && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_MISSING'));
        hasValidateValues && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_ERROR'));
        hasOverweightObject && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.OVER_WEIGHT'));
        hasOverlengthItemName && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.OVER_CHARACTER'));

        if (shipFromMissing.length === 0 &&
            shipToMissing.length === 0 &&
            packageListRedux.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            !hasOverweightObject &&
            !hasOverlengthItemName
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleEdit = () => {
        setIsGetRate(false);
        setIsCallAllCarrier(0);
    }

    const handleGetRate = async () => {

        const validationResult = validate();
        if (validationResult) {
            setRate([]);
            try {
                // if (shipFrom?.country?.code === shipTo?.country?.code) {
                //     console.log('same')
                await getShippingPackageRate('canpar');
                await getShippingPackageRate('ups');
                await getShippingPackageRate('fedex');
                await getShippingPackageRate('canadapost');
                // } else {
                //     console.log('diff')
                //     await getShippingPackageRate('ups');
                // }

            } catch (e) {
                console.log('error ', e.response);
            } finally {
                console.log('call rating api finished');
                setIsGetRate(true);
            }
        }
    }

    useEffect(() => {

        // const filteredRate = [...rate].filter(element => !element.hasOwnProperty("errorMessage"));

        // Step 1: Sort the array based on 'total'
        const sortedRate = [...rate].sort((a, b) => (a?.finalTotalAmount?.value || Infinity) - (b?.finalTotalAmount?.value || Infinity));
        // console.log('sorted rate', sortedRate);

        // Step 2: Find the 'FedEx Ground' object
        const fedexIndex = sortedRate.findIndex((item) => item.serviceName === 'Fedex Ground');

        if (fedexIndex !== -1 && fedexIndex !== 0) {
            // Step 4: Calculate the new 'total' for 'lowest'
            const lowestTotal = sortedRate[0]?.finalTotalAmount?.value * 1.1;
            const fedexTotal = sortedRate[fedexIndex]?.finalTotalAmount?.value;

            // Step 4: Check if 'FedEx Ground' is smaller than the lowest object
            if (fedexTotal < lowestTotal) {
                // Step 5: Swap positions of 'FedEx Ground' and the lowest object
                const updatedData = [...sortedRate];
                const temp = updatedData[fedexIndex];
                updatedData.splice(fedexIndex, 1);
                updatedData.unshift(temp);
                dispatch(updateShippingPackageRateQuickQuote(updatedData));
            } else {
                dispatch(updateShippingPackageRateQuickQuote(sortedRate));
            }
        } else {
            dispatch(updateShippingPackageRateQuickQuote(sortedRate));
        }
    }, [rate]);

    useEffect(() => {
        dispatch(toggleShippingPackageIsGetRate(isGetRate));
    }, [isGetRate])

    console.log('package list redux', packageListRedux);
    console.log('battery details', batteryDetails);
    // console.log('ship from', shipFrom);
    // console.log('ship to', shipTo);

    // console.log('rate', rate);

    useEffect(() => {
        getIsCallAllCarrier(isCallAllCarrier);
        // if (shipFrom?.country?.code === shipTo?.country?.code) {
        if (isCallAllCarrier === 4) {
            setIsCallAllCarrierLoading(false);
        } else {
            setIsCallAllCarrierLoading(true);
        }
        // } else {
        //     if (isCallAllCarrier === 1) {
        //         setIsCallAllCarrierLoading(false);
        //     } else {
        //         setIsCallAllCarrierLoading(true);
        //     }
        // }
    }, [isCallAllCarrier]);

    return (

        <Grid container spacing={4}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12} md={12}>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                    {
                        isGetRate ?
                            <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#F2BE22',
                                    "&:hover": {
                                        backgroundColor: '#F2BE22',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                size='large'
                                onClick={handleEdit}
                                loading={isCallAllCarrierLoading}
                            >
                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.START_OVER')}
                            </LoadingButton>
                            :
                            <LoadingButton
                                variant='contained'
                                color='success'
                                size='large'
                                loading={loading}
                                onClick={handleGetRate}
                                disabled={packageListRedux.length === 0 ? true : false}
                            >
                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.GET_RATES')}
                            </LoadingButton>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}