import React, { useState } from "react";
// import { useDispatch } from "react-redux";
import {
  // TextField,
  IconButton,
  Button,
  Box,
  Collapse,
} from "@material-ui/core";
// import { Paper } from '@material-ui/core'
// import orange from '@material-ui/core/colors/orange';
import { Cancel, ExpandLess } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { shipmentTypesDetails } from "../../../shared/constInfo";
// import Fade from 'react-reveal';
import PropTypes from "prop-types";
// import { deleteShippingItem } from "../../../slices";
import ShippingItemInput from "./shippingItemInput";

import { ordinal_suffix_of } from "../../../utils/addOrdinalSuffix";

// import nextId from "react-id-generator";

const ShippingItemEditor = ({
  item,
  setShippingItems,
  index,
  itemInfo,
  setSelectedLooseItem,
  setPalletOrContainer,

}) => {
  //   const dispatch = useDispatch();

  const [editState, setEditState] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  // const [showError, setShowErrorState] = useState(false);
  const classes = useStyles();

  //get called on the index of the current item selected and deletes it
  const deleteItem = (packageID) => (e) => {
    setShippingItems((prev) => {
      return prev.filter((_pallet) => _pallet.packageID !== packageID);
    });
    // setPalletOrContainer("All")
  };

  const getPackagingTypeName = (packageTypeId) => {
    switch (packageTypeId) {
      case "2":
        return `48" x 48"`;
      case "3":
        return "Oversize";
      case "4":
        return "Overweight";
      default:
        return `48" x 40"`;
    }
  }

  return (
    <Box className={editState ? classes.mainContainer : ""} mb={2}>
      <Box key={item.packageID} className={classes.itemContent}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={deleteItem(item.packageID)}>
            <Cancel />
          </IconButton>
          <div className={classes.itemTextContent}>
            <Box component="span" fontWeight="700">
              {ordinal_suffix_of(index + 1)} Package:
            </Box>{" "}
            {/*{item.description} - {item.length}&times;{item.width}&times;{item.height} ({item.lengthUnit}) - {item.weight} ({item.weightUnit}) &times; {item.looseUnit} - <b>{getPackagingTypeName(item.packagingType)}</b>*/}
            {item.description} - {item.length}&times;{item.width}&times;{item.height} ({item.lengthUnit}) - {item.weight} ({item.weightUnit})
            <br />
            &nbsp;&nbsp;&nbsp;<b>&times; {item.looseUnit}</b>
          </div>
        </Box>
        <Box id="item-content2" className="item-content-subtitle" height="100%">
          {editState ? (
            <IconButton
              // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
              style={{ fill: "green" }}
              onClick={() => {
                setEditState(false);
                setErrorMsg("");
              }}
            >
              <ExpandLess />
            </IconButton>
          ) : (
            // <div id={pallet.key}>
            <Button
              color="primary"
              style={{ fontSize: "1.2rem" }}
              onClick={() => {
                setEditState(true);
                setErrorMsg("");
              }}
            >
              Edit
            </Button>
          )}
        </Box>
      </Box>
      <Collapse in={editState}>
        <ShippingItemInput
            edit={true}
            item={itemInfo}
            onCancel={() => {
              setEditState(false);
              setErrorMsg("");
            }}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            setShippingItems={setShippingItems}
            defaultPackageId={item.packageID}
            small
            editShippingItem={item}
        />
      </Collapse>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    color: "grey",
    width: "90%",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  mainContainer: {
    border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 20,
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
  itemContent: {
    fontFamily: "Montserrat",
    backgroundColor: "white",
    // fontWeight: 600,
    borderRadius: "20px",
    // borderBottomLeftRadius: '20px',
    boxShadow: "0 10px 20px rgba(255, 255, 255, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // font-family: "Montserrat";
    // background-color:white;
    // justify-content: center;
    // border-top-right-radius: 20px;
    // border-bottom-right-radius: 20px;
    // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1)
  },
  itemTextContent: {
    color: "rgb(40, 116, 40)",
    fontWeight: "300",
    fontSize: "1.2rem",
  },
}));

ShippingItemEditor.propTypes = {
  item: PropTypes.object.isRequired,
  edit: PropTypes.object,
  onCancel: PropTypes.func,
  setShippingItems: PropTypes.func.isRequired,
};

export default ShippingItemEditor;
