import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
// import { getShipmentItems, getPickUpDetails } from '../../actions/shipmentAction';
import {useHistory} from "react-router-dom";
// import Slide from 'react-reveal';
import {
    TextField,
    Button,
    Box,
    CircularProgress,
    Dialog,
    IconButton,
    Switch,
    RadioGroup,
    FormControlLabel,
    Radio,
    Collapse,
    Snackbar,
    Input, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography,
} from "@material-ui/core";
// import ReCAPTCHA from "react-google-recaptcha";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClearIcon from "@material-ui/icons/Clear";
import HelpIcon from "@material-ui/icons/Help";
import EventIcon from "@material-ui/icons/Event";

import PropTypes from "prop-types";
import {format} from "date-fns";
import axios from "axios";
import produce from "immer";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
// import NewCredit from '../CreditCardSetup/newCredit'
import {getHeaders} from "../../utils/fetchUtil";
import {
    PARTNER_URI
    // BACKEND_ORDERS_API,
    // // BACKEND_PACKAGES_API,
    // BACKEND_PRICE_ESTIMATE_API,
    // LIST_CREDIT_CARD_API,
    // BACKEND_ADDON_INFO_API,
    // CRM_ORDER_WITHOUT_PRICE,
    // BACKEND_ADD_TO_ADDRESSBOOK,
} from "../../utils/apiUrl";
// import { CustomInput } from "../shared";
import CustomInput from "../../shared/customInput";
// import LoadingContainer from "../shared/loadingContainer";
import loadingContainer from "../../shared/loadingContainer";
import {redirectHome} from "../../utils/redirect";
import {errHandling} from "../../utils/fetchUtil";
import {CardImages} from "../../images/cards";
// import { clearInitOrder } from "../../slices";
import {clearInitOrder} from "../../slices/ordersSlice";
// import AddCreditCardPage from "../Profiles/addCreditCardPage";
// import AddCreditCardPage from "../ProfilesBak/addCreditCardPage";
// import PriceModule from "../shared/OrderDetail/priceModule";
// import InsuranceIcon from "../Images/icons/insuranceIcon";
import ReviewOrderHeader from "./components/reviewOrderHeader";
import LoadingDock from "../../images/icons/LoadingDock.png";
import getProvinceCode, {getUSProvinceCodeByName} from "../../utils/getProvinceCode";
import {getAccessToken} from "../../utils/doToken";
import {getTransportationCode} from "../../shared/constInfo";
import {Link} from "@mui/joy";
// import { getAddressBook } from "../../slices";

// Compare number with limit
const numberExceed = (num, limit) => {
    return parseFloat(num) > limit;
};

// Different Text for different place order condition
const placeOrderText = (error) => {
    return error ? "Submit" : "Confirm Order";
};

const accountOptionsValue = {
    "UPS-Richmond-BC": "ups-richmond-bc",
    "UPS-Toronto-ON": "ups-toronto-on",
    "UPS-Montreal-QC": "ups-montreal-qc",
    "FEDEX-Richmond-BC": "fedex-richmond-bc",
    "FEDEX-Toronto-ON": "fedex-toronto-on",
    "FEDEX-Montreal-QC": "fedex-montreal-qc",
    "FEDEX-Calgary-AB": "fedex-calgary-ab",
    "UPS-Calgary-AB": "ups-calgary-ab",
    "UPS-UUC-Brokerage": "ups-uuc-brokerage"
};

const ReviewAndConfirm = ({
                              toFirst,
                              toFourth,
                              toFifth,
                              toConfirm,
                              pallets,
                              pickUpDetails,
                              distance,
                              isLooseItem,
                              selectedLooseItem,
                              companyName,
                              serviceName,
                              accountBaseCombinedName,
                              priceText,
                              cargoControlNumber,
                              refOrderNumber,
                              shippingItems,
                              serviceType,
                              priceInfo
                          }) => {
    const dispatch = useDispatch();
    // const pallets = useSelector((state) => state.shipping.shippingItems);
    // const pickUpDetails = useSelector((state) => state.shipping.pickUpDetails);
    const userEmail = useSelector((state) => state.user.email);
    const firstname = useSelector((state) => state.user.firstname);
    const lastname = useSelector((state) => state.user.lastname);
    // const cardStatus = useSelector((state) => state.user.activeCard);
    const userPhone = useSelector((state) => state.user.phone);
    const id = useSelector((state) => state.user.id);
    const [extraMsg, setExtraMsg] = useState("");
    const [routeError, setRouteError] = useState(true);
    const [sampleLabel, setSampleLabel] = useState("");

    const [fetchingPrice, setFetchingPriceState] = useState(true);
    const [creatingOrder, setCreatingStatus] = useState(false);
    const [shippingPrice, setShippingPrice] = useState({});

    const [priceErrMsg, setPriceErrMsg] = useState("");
    const [cardErrMsg, setCardErrMsg] = useState("");

    const [cardLoading, setCardLoading] = useState(true);
    const [cardInfo, setCardInfo] = useState(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const [isNotBuyShipping, setIsNotBuyShipping] = useState(false);
    // const [sendingEmail, setSendingStatus] = useState(false);

    const [emailSentSuccess, setEmailSentSuccessState] = useState(false);
    const [emailSentFail, setEmailSentFailState] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dialogEmail, setDialogEmail] = useState(false);
    const [dialogDisclosure, setDialogDisclosure] = useState(false);

    // const [recaptchaValue, setRecaptchaValue] = useState(undefined);

    useEffect(() => {
        console.log("service name: ", serviceName);
    }, [serviceName])

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const handleConfirmDialogOpen = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleDialogEmailOpen = () => {
        setDialogEmail(true);
    };

    const handleDialogEmailClose = () => {
        setDialogEmail(false);
    };

    const handleDialogDisclosureClose = () => {
        setDialogDisclosure(false);
    };

    const handleDialog = async (e) => {
        if (pickUpDetails.deliver.email === "" || pickUpDetails.pickUp.email === "") {
            handleDialogEmailOpen();
        } else {
            handlePlaceOrder();
        }

    }
    // Error indication to check the fee numbers when there are more than two packages
    const [extraFeeError, setExtraFeeError] = useState({
        pickupDock: false,
        deliverDock: false,
        appointment: false,
    });

    const [orderAddons, setOrderAddons] = useState({
        insurance: false,
        insuranceFee: null,
        pickupDock: null,
        pickupDockFee: "0",
        fixPickupDockFee: null,
        pickupTime: false,
        pickupTimeStart: "09:00",
        pickupTimeEnd: "12:00",
        deliverDock: null,
        deliverDockFee: "0",
        fixDeliverDockFee: null,
        deliverTime: false,
        deliverTimeStart: "09:00",
        deliverTimeEnd: "12:00",
        deliveryAppointment: false,
        appointmentFee: "0",
        appoinmentPhone: pickUpDetails.deliver.phone,
        invoice: false,
    });

    const [fullName, setFullName] = useState(`${firstname} ${lastname}`);
    const [inputEmail, setInputEmail] = useState(userEmail);
    const [phone, setPhone] = useState(userPhone || "");

    // const [personalEmtpy, setPersonalEmpty] = useState(false);

    const history = useHistory();
    // const location = useLocation();

    useEffect(() => {
        console.log("Pick up Details:", pickUpDetails);
    }, [pickUpDetails])

    const handlePlaceOrder = async (e) => {
        handleConfirmDialogOpen();
    }

    const handleGetSampleLabel = async (e) => {
        const requestBody = {
            accountBaseCombinedName: accountBaseCombinedName?.toLowerCase(),
            cargoControlNumber,
            partnerId: id,
            refOrderNumber,
            forLabel: true,
            shipFromAddressData: {
                address: pickUpDetails.pickUp.address,
                addressLineTwo: pickUpDetails.pickUp.aptInfo,
                city: pickUpDetails.pickUp.city,
                name: pickUpDetails.pickUp.name,
                phone: pickUpDetails.pickUp.phone,
                province: pickUpDetails.pickUp.country === "US" ? getUSProvinceCodeByName(pickUpDetails.pickUp.province) : getProvinceCode(pickUpDetails.pickUp.province),
                zipCode: pickUpDetails.pickUp.zipCode.replace(/\s*/g, ""),
                country: pickUpDetails.pickUp.country.toLowerCase() === "canada" ? "CA" : pickUpDetails.pickUp.country
            },
            shipData: {
                deliver_address: pickUpDetails.deliver.address,
                deliver_address_two: pickUpDetails.deliver.aptInfo,
                deliver_city: pickUpDetails.deliver.city,
                deliver_postalCode: pickUpDetails.deliver.zipCode.replace(/\s*/g, ""),
                deliver_province: pickUpDetails.deliver.country === "US" ? getUSProvinceCodeByName(pickUpDetails.deliver.province) : getProvinceCode(pickUpDetails.deliver.province),
                deliver_country: pickUpDetails.deliver.country.toLowerCase() === "canada" ? "CA" : pickUpDetails.deliver.country,
                deliver_name: pickUpDetails.deliver.name,
                delivery_phone: pickUpDetails.deliver.phone,
                service_type: serviceType,
                // product_id: refOrderNumber,
                // productNumber: refOrderNumber,
                client_id: `${id}`,
                package_data: shippingItems.map(v => {
                    return {
                        length: v.length,
                        height: v.height,
                        width: v.width,
                        quantity: v.looseUnit,
                        weight: v.weight,
                        description: v.description,
                        lengthUnit: v.lengthUnit,
                        weightUnit: v.weightUnit
                    }
                })
            }
        }
        console.log(requestBody);
        try {
            const {data} = await axios.post(`${PARTNER_URI}/loose-item/3rd-party/partner/sample-label`, requestBody, {
                headers: {
                    authorization: `BEARER ${getAccessToken("access_token")}`
                }
            });
            console.log(data);
            const labelPNG = data?.packageResults?.[0].ShippingLabel?.GraphicImage || data?.packageResults?.[0].shippingLabel?.base64Encoded;
            if (!labelPNG) {
                setSampleLabel("");
                return;
            }
            setSampleLabel(labelPNG);

        } catch (e) {
            console.log(e);
        }
    }

    const confirmPlaceOrder = async (e) => {
        e.preventDefault();
        const requestBody = {
            "account_base_combine_name": accountBaseCombinedName.toLowerCase(),
            service_type: serviceType,
            user_email: userEmail,
            pickup_address: pickUpDetails.pickUp.address,
            pickup_area: pickUpDetails.pickUp.city,
            deliver_address: pickUpDetails.deliver.address,
            deliver_area: pickUpDetails.deliver.city,
            cargo_control_no: cargoControlNumber || "",
            ref_order_no: refOrderNumber || "",
            deliver_address_two: pickUpDetails.deliver.aptInfo,
            "schedule_pickup": "2021-09-27 09:29",
            "pickup_address_obj": {
                "address": pickUpDetails.pickUp.address,
                addressLineTwo: pickUpDetails.pickUp.aptInfo,
                "city": pickUpDetails.pickUp.city,
                "postal_code": pickUpDetails.pickUp.zipCode.replace(/\s*/g, ""),
                "province": pickUpDetails.pickUp.country === "US" ? getUSProvinceCodeByName(pickUpDetails.pickUp.province) : getProvinceCode(pickUpDetails.pickUp.province),
                "companyName": pickUpDetails.pickUp.companyName || "",
                "country": pickUpDetails.pickUp.country,
                "name": pickUpDetails.pickUp.name,
                "email": pickUpDetails.pickUp?.email || ""
            },
            "pickup_phone": pickUpDetails.pickUp.phone,
            "pickup_email": pickUpDetails.pickUp?.email || "",
            "deliver_address_obj": {
                "address": pickUpDetails.deliver.address,
                "city": pickUpDetails.deliver.city,
                addressLineTwo: pickUpDetails.deliver.aptInfo,
                "postal_code": pickUpDetails.deliver.zipCode.replace(/\s*/g, ""),
                "province": pickUpDetails.deliver.country === "US" ? getUSProvinceCodeByName(pickUpDetails.deliver.province) : getProvinceCode(pickUpDetails.deliver.province),
                "companyName": pickUpDetails.deliver.companyName || "",
                "country": pickUpDetails.deliver.country,
                "name": pickUpDetails.deliver.name,
                "email": pickUpDetails.deliver?.email || ""
            },
            "delivery_phone": pickUpDetails.deliver.phone,
            deliver_email: pickUpDetails.deliver?.email || "",
            "shipment_type_id": 40,
            shipmentType: pickUpDetails.deliver.country === pickUpDetails.pickUp.country ? null : pickUpDetails.shipmentType,
            transMode: pickUpDetails.deliver.country === pickUpDetails.pickUp.country ? null : getTransportationCode(pickUpDetails.transMode),
            package_data: shippingItems.map(v => {
                return {
                    length: v.length,
                    height: v.height,
                    width: v.width,
                    quantity: v.looseUnit,
                    weight: v.weight,
                    description: v?.description,
                    lengthUnit: v.lengthUnit,
                    weightUnit: v.weightUnit,
                    hscode: v.hscode,
                    originCountryCode: v.originCountryCode,
                    currency: v.currency,
                    priceEach: v.priceEach,
                    UOM: v.UOM
                }
            })
            // product_id: refOrderNumber,
            // shipFromAddressData: {
            //   address: pickUpDetails.pickUp.address,
            //   addressLineTwo: pickUpDetails.pickUp.aptInfo,
            //   city: pickUpDetails.pickUp.city,
            //   name: pickUpDetails.pickUp.name,
            //   phone: pickUpDetails.pickUp.phone,
            //   province: getProvinceCode(pickUpDetails.pickUp.province),
            //   zipCode: pickUpDetails.pickUp.zipCode.replace(/\s*/g, "")
            // },
            // shipData: {
            //   deliver_address: pickUpDetails.deliver.address,
            //   deliver_address_two: pickUpDetails.deliver.aptInfo,
            //   deliver_city: pickUpDetails.deliver.city,
            //   deliver_postalCode: pickUpDetails.deliver.zipCode.replace(/\s*/g, ""),
            //   deliver_province: getProvinceCode(pickUpDetails.deliver.province),
            //   deliver_country: pickUpDetails.deliver.country,
            //   deliver_name: pickUpDetails.deliver.name,
            //   delivery_phone: pickUpDetails.deliver.phone,
            //   service_type: serviceName === "UPS Standard" ? "standard" : "express",
            //   product_id: shippingItems[0]?.description || refOrderNumber,
            //   client_id: `${id}`,
        }
        console.log(requestBody);
        try {
            setPlaceOrderLoading(true);
            const {data} = await axios.post(`${PARTNER_URI}/loose-item/3rd-party/partner/shipping`, requestBody, {
                headers: {
                    Authorization: `Bearer ${getAccessToken("access_token")}`
                }
            });
            console.log(data);

            const shippingResData = data.shippingResData;
            if (shippingResData?.length > 0 && shippingResData[0].tranStatus === -1) {
                setErrorMessage(shippingResData[0]?.tranMessage || "Error!");
                handleToastClick();
                handleConfirmDialogClose();
                return;
            }
            const shippingOrderData = data.shippingOrderData;
            setErrorMessage("");
            handleToastClick();
            handleConfirmDialogClose();
            const isCrossBorder = data.isCrossBorder;
            const orderId = shippingOrderData.orderId
            const pageRoute = isCrossBorder ? `/commercial-invoice/${orderId}` : "/home";
            // // Go Back to home page after 2 second
            setTimeout(() => {
                history.push(pageRoute);

            }, 2000);
        } catch (e) {
            if (e?.response.status === 403) {
                setErrorMessage("Your account can not place shipping order now, please contact op@uucargo.ca!");
                setIsNotBuyShipping(true);
            } else {
                setErrorMessage(e?.response?.data?.errorMessage || "Error!");
            }
            handleToastClick();
            handleConfirmDialogClose();
            console.log(e);
        } finally {
            setPlaceOrderLoading(false);
        }
    }

    useEffect(() => {
        const moreThanOne = moreThanOnePallet(pallets);
        if (numberExceed(orderAddons.pickupDockFee, 0) && moreThanOne) {
            setExtraFeeError((prev) => {
                return {
                    ...prev,
                    pickupDock: true,
                };
            });
        } else
            setExtraFeeError((prev) => {
                return {
                    ...prev,
                    pickupDock: false,
                };
            });

        if (numberExceed(orderAddons.deliverDockFee, 0) && moreThanOne) {
            setExtraFeeError((prev) => {
                return {
                    ...prev,
                    deliverDock: true,
                };
            });
        } else
            setExtraFeeError((prev) => {
                return {
                    ...prev,
                    deliverDock: false,
                };
            });

        if (numberExceed(orderAddons.appointmentFee, 0) && moreThanOne) {
            setExtraFeeError((prev) => {
                return {
                    ...prev,
                    appointment: true,
                };
            });
        } else
            setExtraFeeError((prev) => {
                return {
                    ...prev,
                    appointment: false,
                };
            });
    }, [orderAddons, pallets, setExtraFeeError]);

    useEffect(() => {
        const postData = {
            google_map_distance: distance?.replace(/,/g, "") || "",
            pickup_area: `${pickUpDetails.pickUp.city}, ${pickUpDetails.pickUp.province}`,
            deliver_area: `${pickUpDetails.deliver.city}, ${pickUpDetails.deliver.province}`,
            goods: pallets.map((pallet) => {
                return {
                    shipment_type_id: pallet.shipmentType,
                    quantity:
                        pallet.shipmentType === "ftruck" ||
                        pallet.shipmentType === "ftrailer"
                            ? "1"
                            : pallet.quantity?.toString(),
                };
            }),
        };

        // axios({
        //   method: "post",
        //   url: BACKEND_PRICE_ESTIMATE_API,
        //   data: JSON.stringify(postData),
        //   headers: {
        //     ...getHeaders(),
        //     "Content-Type": "application/json",
        //     "X-Requested-With": "XMLHttpRequest",
        //   },
        // })
        //   .then((response) => {
        //     setRouteError(false);
        //     setFetchingPriceState(false);
        //     setShippingPrice(response.data || {});
        //   })
        //   .catch((err) => {
        //     if (
        //       err.response?.data?.code !== "2005" &&
        //       err.response?.data?.code !== "2000"
        //     ) {
        //       errHandling(err, setPriceErrMsg);
        //     }
        //     setFetchingPriceState(false);
        //   });
    }, []);

    // Fetches data from backend/api/me to display userprofile data
    // useEffect(() => {
    //   if (cardStatus === "active") {
    //     axios
    //       .get(LIST_CREDIT_CARD_API, { headers: getHeaders() })
    //       .then((response) => {
    //         setCardLoading(false);
    //         setCardInfo(response.data[0]);
    //         setReload(false);
    //       })
    //       .catch((err) => {
    //         // console.log(err);
    //         errHandling(err, setCardErrMsg);
    //         setCardLoading(false);
    //         setReload(false);
    //       });
    //   }
    // }, [cardStatus]);

    // Fetch the fix dock usage fee
    // useEffect(() => {
    //   if (
    //     orderAddons.pickupDock === "No" &&
    //     orderAddons.fixPickupDockFee === null
    //   ) {
    //     axios
    //       .get(BACKEND_ADDON_INFO_API("1"), { headers: getHeaders() })
    //       .then((response) => {
    //         // setCardLoading(false);
    //         // setCardInfo(response.data[0]);
    //         setOrderAddons(
    //           produce((draft) => {
    //             draft.fixPickupDockFee = response.data.value;
    //           })
    //         );
    //       })
    //       .catch((err) => {
    //         console.log(err.message);
    //         // errHandling(err, setCardErrMsg);
    //         // setCardLoading(false);
    //       });
    //   }
    // }, [orderAddons.pickupDock, orderAddons.fixPickupDockFee]);

    // Fetch the fix dock usage fee
    // useEffect(() => {
    //   if (
    //     orderAddons.deliverDock === "No" &&
    //     orderAddons.fixDeliverDockFee === null
    //   ) {
    //     axios
    //       .get(BACKEND_ADDON_INFO_API("2"), { headers: getHeaders() })
    //       .then((response) => {
    //         // setCardLoading(false);
    //         // setCardInfo(response.data[0]);
    //         setOrderAddons(
    //           produce((draft) => {
    //             draft.fixDeliverDockFee = response.data.value;
    //           })
    //         );
    //       })
    //       .catch((err) => {
    //         console.log(err.message);
    //         // errHandling(err, setCardErrMsg);
    //         // setCardLoading(false);
    //       });
    //   }
    // }, [orderAddons.deliverDock, orderAddons.fixDeliverDockFee]);

    // useEffect(() => {
    //   if (cardStatus === "active" && reload) {
    //     axios
    //       .get(LIST_CREDIT_CARD_API, { headers: getHeaders() })
    //       .then((response) => {
    //         setCardLoading(false);
    //         setCardInfo(response.data[0]);
    //       })
    //       .catch((err) => {
    //         // console.log(err);
    //         errHandling(err, setCardErrMsg);
    //         setCardLoading(false);
    //       });
    //   }
    // }, [cardStatus, reload]);

    const classes = useStyles();
    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    // const [pallets, setPallets] = useState(shippingItems)
    // const [pickUpDetails, setPickUpDetails] = useState(initPickUpDetails)

    // Change the toggle bottom
    const handleItemChange = (fieldName) => (e) => {
        e.preventDefault();
        let value = e.target.value;
        if (
            fieldName === "insurance" ||
            fieldName === "pickupTime" ||
            fieldName === "deliverTime" ||
            fieldName === "deliveryAppointment" ||
            fieldName === "invoice"
        ) {
            value = e.target.checked;
        }

        setOrderAddons(
            produce((draft) => {
                draft[fieldName] = value;
            })
        );
    };

    const example = (data) => (
        <img
            src={`data:image/jpeg;base64,${data}`}
            alt="label"
            width={700}
            height="auto"
        />
    );

    // const handleAddInsurance = (e) => {
    //   handleItemChange("insurance")(e);
    //   if (e.target.checked)
    //     axios
    //       .get(BACKEND_ADDON_INFO_API("10"), { headers: getHeaders() })
    //       .then((response) => {
    //         // setCardLoading(false);
    //         setOrderAddons(
    //           produce((draft) => {
    //             draft.insuranceFee = response.data.value;
    //           })
    //         );
    //       })
    //       .catch((err) => {
    //         console.log(err.message);
    //         // errHandling(err, setCardErrMsg);
    //         // setCardLoading(false);
    //       });
    // };

    // Checker for the input address
    const getEmptyStatus = (_formatData) => {
        const {pickUp, deliver} = _formatData;
        const pickUpEmpty =
            // pickUp.zipCode.trim() === "" ||
            pickUp.address.trim() === "" ||
            pickUp.city.trim() === "" ||
            pickUp.province.trim() === "";
        const deliverEmpty =
            // deliver.zipCode.trim() === "" ||
            deliver.address.trim() === "" ||
            deliver.city.trim() === "" ||
            deliver.province.trim() === "";
        if (pickUpEmpty || deliverEmpty) return true;
    };

    const getPickUpEmptyStatus = (_formatData) => {
        const {pickUp} = _formatData;
        const pickUpEmpty =
            // pickUp.zipCode.trim() === "" ||
            pickUp.address.trim() === "" ||
            pickUp.city.trim() === "" ||
            pickUp.province.trim() === "";
        return pickUpEmpty;
    };

    const getDeliverEmptyStatus = (_formatData) => {
        const {deliver} = _formatData;
        const deliverEmpty =
            // deliver.zipCode.trim() === "" ||
            deliver.address.trim() === "" ||
            deliver.city.trim() === "" ||
            deliver.province.trim() === "";
        return deliverEmpty;
    };

    const saveAndReturn = () => {
        localStorage.setItem(
            "shippingData",
            JSON.stringify({pickUpDetails: pickUpDetails, shippingItems: pallets})
        );
        dispatch(clearInitOrder());
        redirectHome(history);
    };

    const handleChange = (event) => {
        setExtraMsg(event.target.value);
    };

    const getDockFee = (dock, dockFee, fixDockFee) => {
        if (dock === null) return null;
        if (dock === "Yes") return dockFee || "0";
        return fixDockFee;
    };

    const moreThanOnePallet = (_pallets) => {
        return _pallets.length > 1;
    };

    return (
        <Box width="100%">
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Place Order Failed!
                                <hr/>
                                Error: {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            Place Order Success!
                        </Alert>
                    );
                })()}
            </Snackbar>
            {/*Pop-up Toast*/}
            {/*Confirm Place Order Dialog*/}
            <Dialog
                open={confirmDialogOpen}
                onClose={handleConfirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Place Order</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will place your Shipping Order. Are you sure to
                        continue?
                        <hr/>
                        Click <b>Confirm</b> to place an order
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmDialogClose}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        No
                    </Button>
                    <Button
                        onClick={confirmPlaceOrder}
                        variant="outlined"
                        type="button"
                        disabled={placeOrderLoading}
                    >
                        {placeOrderLoading && <CircularProgress size={14}/>}
                        {!placeOrderLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Confirm Place Order Dialog*/}
            {/*Confirm Place Order Without email address*/}
            <Dialog
                open={dialogEmail}
                onClose={handleDialogEmailClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Place Order</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="secondary">
                        It is recommended to provide an email address. Are you sure you want to continue and place your
                        order?
                        <hr/>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Click <b>Confirm</b> to continue
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogEmailClose}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => {
                            handleDialogEmailClose();
                            handlePlaceOrder();
                        }}
                        variant="outlined"
                        type="button"
                        disabled={placeOrderLoading}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogDisclosure}
                onClose={handleDialogDisclosureClose}
            >
                <DialogContent>
                    <DialogContentText>
                        <span style={{color: 'green'}}> A shipping adjustment </span> occurs when an incorrect shipping
                        label is generated due to
                        inaccurate
                        shipment information. This causes the shipping carrier to readjust the shipping information to
                        ensure the package reaches its final destination and the correct shipping costs are paid.
                    </DialogContentText>
                    <DialogContentText>
                        If there is a difference between what they measure and what you declared on UUCargo, you may be
                        charged extra according to the changes in shipping costs. If the parcel is bigger/heavier than
                        declared, you will be charged to make up for the difference. This works the other way too; if
                        the parcel is smaller/lighter than declared, you will receive an appropriate partial refund.
                    </DialogContentText>
                    <DialogContentText>
                        <span style={{color: 'green', textDecoration: 'underline green'}}>*The bill from the carriers determines the final price for a package.</span> We
                        will return credit to
                        your account balance or charge an additional fee from your payment method depending on the bill.
                    </DialogContentText>
                    <DialogContentText>
                        Should you have any questions or concerns regarding shipping adjustments or billing, our
                        customer support team is available to assist you. We value your business and are committed to
                        providing a reliable and cost-effective shipping experience with UUCargo.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Button
                            className={classes.DisclosureButton}
                            onClick={handleDialogDisclosureClose}
                        >
                            Accept
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            {/*Confirm Place Order Dialog*/}
            <div
                className={matches ? classes.smRootContainer : classes.rootContainer}
            >
                <h2 className="orange-title">Shipping Summary:</h2>
                <ReviewOrderHeader
                    pickupEmpty={getPickUpEmptyStatus(pickUpDetails)}
                    deliverEmpty={getDeliverEmptyStatus(pickUpDetails)}
                    pickUpDetails={pickUpDetails}
                    toFirst={toFirst}
                    toFourth={toFourth}
                    pallets={pallets}
                    accountBaseCombinedName={accountBaseCombinedName}
                    priceText={priceText}
                    priceInfo={priceInfo}
                    selectedLooseItem={selectedLooseItem}
                    toFifth={toFifth}
                    serviceName={serviceName}
                />
                <hr/>
                <Box>
                    <Typography>
                        *This may not be your final price.
                        <Link
                            sx={{color: 'green', textDecoration: 'green'}}
                            onClick={() => setDialogDisclosure(true)}
                        >
                            Learn more
                        </Link>
                    </Typography>
                </Box>
                {!isLooseItem && (
                    <Box className={classes.summaryHeader} mt={2} fontWeight="700">
                        Delivery Services
                    </Box>
                )}
                {!isLooseItem && (
                    <Box
                        pt={1}
                        className={
                            orderAddons.deliverDock === "Yes" ||
                            orderAddons.deliverDock === "No"
                                ? classes.feeWrapper
                                : ""
                        }
                    >
                        <Box
                            component="span"
                            color="rgba(0,0,0,0.8)"
                            className="bodytext"
                            flexBasis="200px"
                        >
                            <img
                                src={LoadingDock}
                                alt="Loading Dock"
                                width="40px"
                                align="bottom"
                                style={{
                                    backgroundColor: "seagreen",
                                }}
                            />{" "}
                            Is there a loading dock at the deliver place *?{" "}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.uucargo.ca/support/"
                            >
                                <HelpIcon style={{color: "grey"}}/>
                            </a>
                        </Box>
                        <Box display="flex" justifyContent="end" mt={1}>
                            <RadioGroup
                                row
                                aria-label="dock"
                                name="dock"
                                value={orderAddons.deliverDock}
                                onChange={handleItemChange("deliverDock")}
                                className={orderAddons.deliverDock ? "" : classes.radioWrapper}
                            >
                                <FormControlLabel
                                    value="No"
                                    control={<Radio color="primary"/>}
                                    label="No"
                                />
                                <FormControlLabel
                                    value="Yes"
                                    control={<Radio color="primary"/>}
                                    label="Yes"
                                />
                            </RadioGroup>
                        </Box>
                        <Collapse
                            in={
                                orderAddons.deliverDock === "Yes" ||
                                orderAddons.deliverDock === "No"
                            }
                        >
                            {orderAddons.deliverDock === "Yes" ? (
                                <>
                                    <Box
                                        display="flex"
                                        ml={2}
                                        mr={2}
                                        mb={2}
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box mb={0.5}>Usage fee</Box>
                                        <Box width="10rem">
                                            <CustomInput
                                                value={orderAddons.deliverDockFee}
                                                onChange={handleItemChange("deliverDockFee")}
                                                type="currency"
                                                error={extraFeeError.deliverDock}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        pl={2}
                                        pr={1}
                                        color="red"
                                        display={extraFeeError.deliverDock ? "block" : "none"}
                                    >
                                        Usage fee cannot be none-zero if the order has more than one
                                        package.
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    display="flex"
                                    ml={2}
                                    mr={2}
                                    mb={2}
                                    mt={1}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box>Loading dock usage fee</Box>
                                    <Box component="span" fontSize="1.2rem">{`C$ ${
                                        orderAddons.fixDeliverDockFee
                                            ? orderAddons.fixDeliverDockFee
                                            : ""
                                    }`}</Box>
                                </Box>
                            )}
                        </Collapse>
                    </Box>
                )}
                <Box pt="1vh" width="100%">
                    {getEmptyStatus(pickUpDetails) ? (
                        <Alert severity="error" classes={{root: classes.alertRoot}}>
                            Please enter all the address information.
                        </Alert>
                    ) : (
                        ""
                    )}
                </Box>
                {/* <Box mb={2} width="100%" display="flex" justifyContent="center">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_API_RECAPTCHA_KEY}
            onChange={(e) => setRecaptchaValue(e)}
          />
        </Box> */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    mt={2}
                    mb={1}
                    alignItems="flex-start"
                >
                    <Box fontSize="1rem" mr={5}>
                        <Button onClick={saveAndReturn} className={classes.textButton}>
                            Save & <br/>
                            Go to home
                        </Button>
                    </Box>
                    <Box pt={1} display="flex" sx={{gap: "2rem"}}>
                        <Button
                            color="primary"
                            variant="contained"
                            className={matches ? classes.smButton : classes.button}
                            onClick={(e) => {
                                handleGetSampleLabel(e);
                            }}
                            style={{
                                textTransform: "unset",
                                fontSize: "1rem",
                                height: "3rem",
                            }}
                        >
                            Get Sample Label
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            className={matches ? classes.smButton : classes.button}
                            onClick={handleDialog}
                            style={{
                                textTransform: "unset",
                                fontSize: "1rem",
                                height: "3rem",
                            }}
                            // disabled={!recaptchaValue}
                        >
                            {creatingOrder ? <CircularProgress size={24}/> : "Place Order"}
                        </Button>
                        {creatingOrder && <Box textAlign="center">Placing order...</Box>}
                    </Box>
                </Box>
                {
                    isNotBuyShipping && <Box width="100%">
                        <Alert severity="error">Your account can not place shipping order now, please contact
                            op@uucargo.ca.</Alert>
                    </Box>
                }
                <Box>
                    {sampleLabel && (
                        <>
                            <Box mb={2}>Label size: 4" x 6"</Box>
                            <Box>{example(sampleLabel)}</Box>
                        </>
                    )}
                </Box>
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={dialogOpen}
                    onClose={() => {
                        setReload(true);
                        setDialogOpen(false);
                    }}
                >
                    <div className={classes.dialogTitle}>
                        <Box fontSize="1.5rem"></Box>
                        <IconButton
                            // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                            style={{fill: "green"}}
                            className={classes.closeBtn}
                            onClick={() => {
                                setReload(true);
                                setDialogOpen(false);
                            }}
                        >
                            <ClearIcon/>
                        </IconButton>
                    </div>
                    {/*<AddCreditCardPage addCard={true} popUp={true} />*/}
                </Dialog>
            </div>
            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                open={emailSentSuccess}
                onClose={() => setEmailSentSuccessState(false)}
                message="Send email successfully"
                autoHideDuration={3000}
            />
            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                open={emailSentFail}
                onClose={() => setEmailSentFailState(false)}
                message="Failed to send email"
                autoHideDuration={3000}
            />
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    rootContainer: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // flexDirection: 'column',
        margin: "0 auto",
        width: "40vw",
        minWidth: "500px",
        paddingLeft: "3vw",
        paddingRight: "3vw",
        textAlign: "left"
    },
    smRootContainer: {
        width: "100%",
        paddingLeft: "3vw",
        paddingRight: "3vw",
        textAlign: "left"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    button: {
        borderRadius: 5,
        fontSize: "1.2rem",
        backgroundColor: "#ffa726",
        width: "14rem",
    },
    smButton: {
        borderRadius: 5,
        fontSize: "1rem",
        backgroundColor: "#ffa726",
        width: "12rem",
    },
    textfield: {
        width: "100%",
        backgroundColor: "white",
        // border: "1px solid white",
    },
    dockerFee: {
        width: "4rem",
    },
    feeWrapper: {
        // border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: 5,
        marginBottom: theme.spacing(2),
        // backgroundColor: "#FEEAAE",
        backgroundColor: "white",
    },

    infoWrapper: {
        // border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: 5,
        marginBottom: theme.spacing(2),
        backgroundColor: "#FEEAAE",
    },
    summaryHeader: {
        fontSize: "16pt",
        color: "rgb(141, 173, 155)",
        fontFamily: "Montserrat",
        // borderBottom: '1px, solid rgb(141, 173, 155)',
    },
    summaryBodyText: {
        fontSize: "13pt",
        color: "seagreen",
        fontFamily: "Montserrat",
    },
    BodyText: {
        fontSize: "1.1rem",
        color: "rgba(0,0,0,0.8)",
        fontFamily: "Montserrat",
        fontWeight: "400",
    },
    textButton: {
        color: "blue",
    },
    bodyText: {
        fontSize: "1rem",
        color: "seagreen",
        fontFamily: "Montserrat",
    },
    dialogTitle: {
        padding: "1vh 1vw 0.5vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "rgb(141, 173, 155)",
    },
    timePicker: {
        width: 160,
    },
    divider: {
        borderBottom: "2px solid rgba(0,0,0,0.3)",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    radioWrapper: {
        border: "1px solid red",
        paddingLeft: theme.spacing(1),
        borderRadius: "5px",
    },
    DisclosureButton: {
        backgroundColor: 'green',
        color: '#FFFFFF',
        padding: '0 40px',
        margin: '0 0 20px',
        "&:hover": {
            backgroundColor: 'green',
            color: '#FFFFFF',
            filter: 'brightness(85%)'
        },
    }
}));

ReviewAndConfirm.propTypes = {
    toFirst: PropTypes.func.isRequired,
    toFourth: PropTypes.func.isRequired,
    toConfirm: PropTypes.func.isRequired,
    pickUpDetails: PropTypes.object,
    pallets: PropTypes.array,
};

// const mapStateToProps = state => ({
//     shippingItems: state.shippingItems
// })

// export default connect(mapStateToProps, {getShipmentItems, getPickUpDetails})(ReviewAndConfirm);
export default ReviewAndConfirm;
