import React, {useRef} from "react";
import ReactToPrint from "react-to-print";

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div   style={{display: "none", justifyContent: "center", alignItems: "center"}}>
      <img  ref={ref} src="https://storage.googleapis.com/uucargo-resource/shipping-label/batch/26-TLFD.xlsx/FD001565425/FD001565425-20.png" alt="img" />
    </div>
  );
});


const Printer = () => {

  const componentRef = useRef();

  return (
    <div>
      <h1>Print Label Page</h1>

      <div style={{display: "flex", flexDirection: "row"}}>
        <br />
        <br />
        <br />
        <div>
          <ComponentToPrint ref={componentRef} />
        </div>
      </div>
      <ReactToPrint
        trigger={() => <button>Button 1</button>}
        content={() => componentRef.current}
      />
      <br />
      <ReactToPrint
        trigger={() => <button>Button 2</button>}
        content={() => componentRef.current}
      />
    </div>
  );
};

export default Printer;
