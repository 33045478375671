import React, {useEffect, useRef, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Alert,
    Autocomplete,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    MenuItem,
    Select,
} from "@mui/material";
import {Redirect, useHistory} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {green, orange, red} from "@mui/material/colors";
import {Controller, useForm} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import {useSelector} from "react-redux";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import {
    americaProvinceList,
    canadaProvincesList,
    countryList,
    internationalShipmentTypeList,
    transportationList
} from "../../shared/constInfo";
import {getAccessToken} from "../../utils/doToken";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/en";
import getProvinceCodeByName, {getCanadaProvinceNameByCode, getUSProvinceCodeByName} from "../../utils/getProvinceCode";
import {makeStyles} from "@material-ui/core/styles";
import TrackingInfoTableItem from "./TrackingInfoTableItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomInput from "../../shared/customInput";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Snackbar,
    Tooltip
} from "@material-ui/core";
import {
    addDeliverAddressBookList,
    addSenderAddressBookList,
    deleteAddressBookByPartnerIdAndAddressBookId,
    getSenderAddressBookList
} from "../../utils/addressBookData";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import {parseISO, isBefore} from 'date-fns';
import DialogActions from "@mui/material/DialogActions";
import {useThrottle} from "../../utils/CustomHook";
import intl from "react-intl-universal";
// import { useThrottle } from "@uidotdev/usehooks";

dayjs.extend(utc);
dayjs.extend(timezone);

const theme = createTheme({
    palette: {
        primary: orange,
        danger: red,
        success: green,
        warning: red,
        secondary: green,
    },
});

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

//* Address Autocomplete
function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = {current: null};
// const autocompleteService = useRef(null);

const disableWeekends = (date) => {
    return date.day() === 0 || date.day() === 6 || isTodayDisabled(date);
};

// If Date is same day as today, disable date (return true) if past 11AM
const isTodayDisabled = (date) => {
    const today = dayjs();
    if (
        date.year() === today.year() &&
        date.month() === today.month() &&
        date.date() === today.date()
    ) {
        return (today.hour() === 13 && today.minute() >= 0) || today.hour() > 13;
    } else {
        return false;
    }
};

const getNextAvailableDateAfterSevenDays = () => {
    var date = dayjs().add(7, "day"); // 7 days from today
    var count = 0;
    while (count < 7) {
        if (!disableWeekends(date)) {
            return date;
        }
        date = date.add(1, "days");
        count++;
    }
    // Infinite loop? Something went wrong
    console.log("Potential Infinite Loop. Something went wrong");
    throw new Error("Potential Infinite Loop");
};

const getNextNonWeekendDay = () => {
    var date = dayjs();
    var count = 0;
    while (count < 7) {
        if (!disableWeekends(date)) {
            return date;
        }
        date = date.add(1, "days");
        count++;
    }
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_API_KEY;
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%"
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word"
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff"
    },
    table: {
        width: "100%"
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)"
        }
    },
    nameTH: {
        width: "100px"
    },
    idTH: {
        width: "100px"
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2)
        },
        display: "flex",
        justifyContent: "center"
    }
}));
const SchedulePickup = () => {
    const id = useSelector((state) => state.user.id);
    const userEmail = useSelector((state) => state.user.email);
    const classes = useStyles();
    const [valueTab, setValueTab] = useState(0);
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    // Google Audocomplete
    const [value, setValue] = React.useState(null);
    const [valueDetailed, setValueDetailed] = React.useState(null);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);
    const [pickupTime, setPickupTime] = useState(null);
    const loaded = React.useRef(false);

    let history = useHistory();

    const getDetailService = useRef(null);
    const [accountBase, setAccountBase] = useState("UPS");
    const [serviceType, setServiceType] = useState("standard");
    const [pickupPoint, setPickupPoint] = useState("FRONT");
    const [province, setProvince] = useState("");
    const [country, setCountry] = useState("CA");
    const [provinceList, setProvinceList] = useState(country !== "CA" ? americaProvinceList : canadaProvincesList);
    const [trackingList, setTrackingList] = useState([]);
    const [trackingListSearch, setTrackingListSearch] = useState([]);
    const [trackingNumbers, setTrackingNumbers] = useState("");
    const [openAddressBook, setOpenAddressBook] = useState(false);
    const [addressBookList, setAddressBookList] = useState([]);
    const [isSaveAddress, setIsSaveAddress] = useState(false);
    const {register, handleSubmit, control, setValue: setFormValue, getValues, formState: {errors}} = useForm({
        shouldUseNativeValidation: true,
        defaultValues: {
            numOfPieces: 1,
            totalWeight: 1,
            totalVolume: 1,
            isResidential: false,
            pickupDate: getNextAvailableDateAfterSevenDays(),
            pickupDateEarly: getNextAvailableDateAfterSevenDays()
                .hour(10)
                .minute(0)
                .second(0)
                .millisecond(0), // 10:00AM
            pickupDateLate: getNextAvailableDateAfterSevenDays()
                .hour(16)
                .minute(0)
                .second(0)
                .millisecond(0), // 4:00PM
        },
    });

    const [sendEmailOpen, setSendEmailOpen] = useState(false);
    const [sendEmailLoading, setSendEmailLoading] = useState(false);
    const [apiBody, setApiBody] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [totalVolume, setTotalVolume] = useState(1);
    const [totalWeight, setTotalWeight] = useState(1);
    const [trackingNumberError, setTrackingNumberError] = useState([]);

    const throttledValue = useThrottle(trackingNumbers, 3000);

    const verifyTrackingNumbers = async () => {
        let requestURL = `${PARTNER_URI}/packagesPickupAppointment/verifyTrackingNumberForPickup`;
        const storedToken = getAccessToken("access_token");

        const data = {
            trackingNumbers: throttledValue,
            carrier: accountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: data,
            })

            console.log('result', result);

            const errors = result?.data?.errors;
            const validTrackingNumbers = result?.data?.validTrackingNumbers;

            if (errors?.length > 0) {
                console.log('error');
                setTotalWeight(1);
                setTotalVolume(1);
                setTrackingNumberError(errors);

            } else if (validTrackingNumbers?.length > 0) {
                console.log('valid');
                setTotalWeight(result?.data?.totalWeight);
                setTotalVolume(result?.data?.totalWeight);
                setTrackingNumberError([]);
            } else {
                console.log('empty');
                setTotalWeight(1);
                setTotalVolume(1);
                setTrackingNumberError([]);
            }
        } catch (e) {
            console.log('error', e.response);
            setTotalWeight(1);
            setTotalVolume(1);
            setTrackingNumberError([]);
        }
    }

    const handleSendEmailDialogClose = () => {
        setSendEmailOpen(false);
    };

    const handleSendEmailDialogOpen = () => {
        setSendEmailOpen(true);
    };

    const pickupTimeEarlyRule = (value) => {
        const selectedTime = new Date(value);
        const cutoffTime = new Date();
        cutoffTime.setHours(10);
        cutoffTime.setMinutes(0);
        cutoffTime.setSeconds(0);
        cutoffTime.setMilliseconds(0);
        return selectedTime >= cutoffTime || "Pickup time must be after 10:00 AM";
    };

    const validateLatestPickUptime = (value) => {
        if (value > "16:00") {
            return "Pickup time must be after 4:00 PM";
        }
    };

    const [toastOpen, setToastOpen] = useState(false);
    const handleToastClick = () => {
        setToastOpen(true);
    };
    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const handleOpenSenderAddressBook = async () => {
        setAddressBookList(await getSenderAddressBookList({partnerId: id}));
        setOpenAddressBook(true);
    };

    const handleCloseSenderAddressBook = () => {
        setOpenAddressBook(false);
    };

    const handleSaveAddress = () => {
        if (!isFullInformation()) {
            setIsSaveAddress(false);
            return;
        }
        setIsSaveAddress((v) => !v);
    };

    const isEmpty = (word) => {
        if (typeof word !== "string") {
            return false;
        }
        return word?.trim() !== "";
    }

    const isFullInformation = () => {
        return isEmpty(value)
            && isEmpty(province)
            && isEmpty(getValues("name"))
            && isEmpty(getValues("city"))
            && isEmpty(getValues("postalCode"))
            && isEmpty(getValues("phone"))
            && isEmpty(province)
            && isEmpty(country)
    }

    if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector("head"),
                "google-maps"
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        []
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({input: inputValue}, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const inchToCmVolume = (inches) => {
        return inches * 16.3871;
    };

    const handleSchedulePickupRequest = async () => {
        setSendEmailLoading(true);
        let requestURL = `${PARTNER_URI}/packagesPickupAppointment/canparPickup`;
        const storedToken = getAccessToken("access_token");

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    authorization: `Bearer ${storedToken}`,
                },
                data: apiBody
            })
            console.log(result);
            setErrorMessage('');
            setStatusMessage('Successfully Send Pickup Request');
            // handleSuccessMessage();
            setSendEmailLoading(false);
            handleSendEmailDialogClose();
        } catch (e) {
            console.log('e', e.response);
            setErrorMessage('Failed to Send Pickup Request. Try again another time.');
            // handleErrorMessage();
            setSendEmailLoading(false);
        }
    }

    const FedexPickupTime = async (data) => {

        let requestURL = `${PARTNER_URI}/packagesPickupAppointment/fedexGetPickupAvailabilityByPostalCodeAndCountry`;

        let req = {
            postalCode: data?.postalCode,
            country: data?.country,
            pickupDate: data.pickupDate.format("YYYYMMDD")
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: req
            })
            console.log('pickup time', result);
            setPickupTime(prevState => ({
                ...prevState,
                defaultReadyTime: result?.data?.defaultReadyTime,
                defaultLatestTimeOptions: result?.data?.defaultLatestTimeOptions
            }))
        } catch (e) {
            console.log(e.response)
        }
    }

    const onSubmit = async (data) => {
        clearStatusMessages();
        console.log(data);
        console.log('value', value);
        const formErrorsObj = {
            // address:
            //   valueDetailed === undefined ||
            //   valueDetailed === null ||
            //   valueDetailed === "",
            address:
                value === undefined ||
                value === null ||
                value === "",
            city: data.city === undefined || data.city === null || data.city === "",
            name: data.name === undefined || data.name === null || data.name === "",
            companyName:
                data.companyName === undefined ||
                data.companyName === null ||
                data.companyName === "",
            email:
                data.email === undefined ||
                data.email === null ||
                data.email === "" ||
                !/.+\@.+\..+/.exec(data.email), // simplistic email validation regex
            country:
                country === undefined ||
                country === null ||
                country === "",
            courier:
                accountBase === undefined ||
                accountBase === null ||
                accountBase === "",
            // numOfPieces:
            //   data.numOfPieces === undefined ||
            //   data.numOfPieces === null ||
            //   isNaN(data.numOfPieces) ||
            //   data.numOfPieces === "",
            // totalWeight:
            //   data.totalWeight === undefined ||
            //   data.totalWeight === null ||
            //   isNaN(data.totalWeight) ||
            //   data.totalWeight === "",
            totalVolume:
                data.totalVolume === undefined ||
                data.totalVolume === null ||
                isNaN(data.totalVolume) ||
                data.totalVolume === "",
            pickupDate:
                data.pickupDate === undefined ||
                data.pickupDate === null ||
                data.pickupDate === "",
            pickupDateEarly:
                data.pickupDateEarly === undefined ||
                data.pickupDateEarly === null ||
                data.pickupDateEarly === "",
            pickupDateLate:
                data.pickupDateLate === undefined ||
                data.pickupDateLate === null ||
                data.pickupDateLate === "",
            postalCode:
                data.postalCode === undefined ||
                data.postalCode === null ||
                data.postalCode === "",
            // province:
            //   data.province === undefined ||
            //   data.province === null ||
            //   data.province === "",
            province:
                province === undefined ||
                province === null ||
                province === "",
            phone:
                data.phone === undefined || data.phone === null || data.phone === "",
            trackingNumbers:
                trackingNumbers === undefined || trackingNumbers === null || trackingNumbers?.trim() === "",
            invalidEarlyAndLateDateTime:
                data.pickupDateEarly !== null
                    ? !data.pickupDateEarly.isBefore(data.pickupDateLate)
                    : true,
            invalidEarlyDateTime:
                data.pickupDateEarly !== null
                    ? parseInt(data.pickupDateEarly.format("HHmm")) < 700
                    : true,
            invalidLateDateTime:
                data.pickupDateLate !== null
                    ? parseInt(data.pickupDateLate.format("HHmm")) > 1930
                    : true,
            invalidEarlyDateTimeCanadaPost:
                accountBase !== "CanadaPost"
                    ? false
                    : data.pickupDateEarly !== null
                        ? parseInt(data.pickupDateEarly.format("HHmm")) < 1200 || parseInt(data.pickupDateEarly.format("HHmm")) > 1600
                        : true,
            invalidEarlyDateTimeIntervalCanadaPost:
                accountBase !== "CanadaPost"
                    ? false
                    : data.pickupDateEarly !== null
                        ? (parseInt(data.pickupDateEarly.format('m')) !== 0 && parseInt(data.pickupDateEarly.format('m')) % 15 !== 0)
                        : true,
            invalidLateDateTimeIntervalCanadaPost:
                accountBase !== "CanadaPost"
                    ? false
                    : data.pickupDateLate !== null
                        ? (parseInt(data.pickupDateLate.format('m')) !== 0 && parseInt(data.pickupDateLate.format('m')) % 15 !== 0)
                        : true,
        };
        console.log("Form errors:", formErrorsObj);
        setFormErrors(formErrorsObj);

        // If any trues found in formErrorsObj (errors), return
        for (const key in formErrorsObj) {
            if (formErrorsObj[key]) {
                setErrorMessage("Missing/Invalid Required Fields");
                return;
            }
        }

        if (trackingNumberError?.length > 0) {
            setErrorMessage("Invalid Tracking Numbers");
            return;
        }
        console.log("Data:", data);
        console.log("Address Data:", valueDetailed);

        // const trackingNumbers = processTrackingNumbers(data.trackingNumbers);

        /**
         * {
    "pickupDateInfo": {
        "readyTime": "0500",
        "closeTime": "1700",
        "pickupDate": "20221216"
    },
    "pickupAddress": {
        "address": "6751 Westminster Hwy",
        "city": "Richmond",
        "province": "BC",
        "country": "CA",
        "postalCode": "V7C4V4",
        "isResidential": false,
        "pickupPoint": "Front Door"
    },
    "contact": {
        "name": "Luke",
        "companyName": "UUCARGO",
        "phone": "7787787788",
        "email": "op@uucargo.ca",
        "phoneExtension": ""
    },
    "trackingNumbers": "D999999920000104349001, D999999920000104349002, D999999920000104349003",
    "accountBase": "canpar"
}
         */
        const body = {
            // ...data,
            pickupDateInfo: {
                readyTime: data.pickupDateEarly.format("HHmm"),
                closeTime: data.pickupDateLate.format("HHmm"),
                pickupDate: data.pickupDate.format("YYYYMMDD"),
            },
            pickupAddress: {
                // address: `${valueDetailed.street_number} ${valueDetailed.route}`,
                address: value,
                // city: valueDetailed.locality,
                city: data.city,
                province: country === "CA" ? getProvinceCodeByName(province) : getUSProvinceCodeByName(province),
                // province: province,
                // province:
                //   canadaProvincNameToCodeMap[
                //     valueDetailed.administrative_area_level_1
                //   ] || valueDetailed.administrative_area_level_1,
                // country: "CA" || valueDetailed.country,
                country: country || "CA",
                // postalCode: valueDetailed.postal_code.replace(" ", ""),
                postalCode: data.postalCode.replace(/\s*/g, ""),
                pickupPoint: pickupPoint,
                isResidential: data.isResidential,
            },
            contact: {
                name: data.name,
                companyName: data.companyName,
                phone: data.phone,
                email: data.email,
                phoneExtension: data.ext || "",
            },
            totalVolume:
                data.totalVolumeUnit === "cm"
                    ? parseFloat(data.totalVolume)
                    : inchToCmVolume(parseFloat(data.totalVolume)),
            // partnerId: id,
            // pickupDate: data.pickupDate
            //   // .tz("Canada/Pacific")
            //   // .format("YYYY-MM-DD"),
            //   .format("YYYYMMDD"),
            // pickupDateEarly: data.pickupDateEarly
            //   // .tz("Canada/Pacific")
            //   // .format("HH:mm:ss"),
            //   .format("HHmm"),
            // pickupDateLate: data.pickupDateLate
            //   // .tz("Canada/Pacific")
            //   // .format("HH:mm:ss"),
            //   .format("HHmm"),
            trackingNumbers: trackingNumbers,
            accountBase: accountBase,
        };
        console.log("Body:", body);
        setApiBody(body);
        if (accountBase === 'Canpar') {
            handleSendEmailDialogOpen();
        } else {
            setIsLoading(true);
            try {
                const storedToken = getAccessToken("access_token");
                const {data, status} = await axios({
                    method: "post",
                    url: `${PARTNER_URI}/packagesPickupAppointment/createPickupAppointmentPartner`,
                    data: body,
                    headers: {
                        authorization: `Bearer ${storedToken}`,
                    },
                });
                if (status === 200) {
                    console.log("Success:", data);
                    setTimeout(() => {
                        history.push("/MyScheduledPickups")
                    }, 3000);

                    var countDown = 3;
                    setStatusMessage(
                        `Successfully Scheduled Pickup. Redirecting in ${countDown} seconds`
                    );
                    var myInterval = setInterval(() => {
                        if (countDown > 0) {
                            countDown--;
                            setStatusMessage(
                                `Successfully Scheduled Pickup. Redirecting in ${countDown} seconds`
                            );
                        } else {
                            clearInterval(myInterval)
                        }
                    }, 1000)
                } else {
                    console.log("Error:", data);
                    throw new Error();
                }
            } catch (e) {
                console.log(e.response);
                setErrorMessage("Failed to Schedule Pickup. Try again another time.");
                if (accountBase === 'FedEx') {
                    FedexPickupTime(data);
                }
            } finally {
                setIsLoading(false);
            }
        }

    };

    const clearStatusMessages = () => {
        setStatusMessage("");
        setErrorMessage("");
    };

    const processTrackingNumbers = (trackingNumberString) => {
        const results = trackingNumberString.split(",");
        return results;
    };

    const handleChange = (event, newValue) => {
        if (newValue === 1) {
            history.push("/MyScheduledPickups")
            return;
        }
        setValueTab(newValue);
    };

    useEffect(() => {
        console.log("Address Change: ", value);
    }, [value])

    useEffect(() => {
        if (country === "CA") {
            setProvinceList(canadaProvincesList);
        }
        if (country === "US") {
            setProvinceList(americaProvinceList);
        }
    }, [country])

    useEffect(() => {
        (async () => {
            const storedToken = getAccessToken("access_token");
            const {data} = await axios.get(`${PARTNER_URI}/packagesPickupAppointment/getRecentTrackingRecordsList?accountBase=${accountBase}`, {
                headers: {
                    authorization: `Bearer ${storedToken}`,
                },
            });
            console.log("tracking list: ", data);
            setTrackingList(data);
            setTrackingListSearch(data);
        })();
    }, [accountBase])

    useEffect(() => {
        if (isSaveAddress) {
            (async () => {
                await addSenderAddressBookList({
                    partnerId: id,
                    address: value,
                    address_2: "",
                    country: country,
                    province: country === "CA" ? getProvinceCodeByName(province) : getUSProvinceCodeByName(province),
                    name: getValues("name"),
                    city: getValues("city"),
                    email: userEmail,
                    postalCode: getValues("postalCode"),
                    phone: getValues("phone"),
                    addressEmail: getValues("email"),
                })
                handleToastClick();
            })()
        }
    }, [isSaveAddress])

    const handleSearchTracking = event => {
        const input = event.target.value;
        setSearchInput(input);
    }

    useEffect(() => {
        const filteredTrackingList = trackingList?.filter(trackingItem => {
            return (
                trackingItem?.tracking_no?.toUpperCase()?.includes(searchInput.toUpperCase()) ||
                trackingItem?.item_name?.toUpperCase()?.includes(searchInput.toUpperCase())
            )
        });
        console.log('filtered tracking list', filteredTrackingList);
        setTrackingListSearch(filteredTrackingList);

    }, [searchInput]);

    useEffect(() => {
        verifyTrackingNumbers();
    }, [throttledValue])

    console.log('pickup time', pickupTime);

    return (
        <ThemeProvider theme={theme}>
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Save Address Failed!
                                <hr/>
                                Error: {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            Save Addresses Success!
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={sendEmailOpen}
                onClose={handleSendEmailDialogClose}
            >
                <DialogTitle>
                    {intl.get('SCHEDULE_PICKUP.SEND_REQUEST_PICKUP_TITLE')}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography>
                            {intl.get('SCHEDULE_PICKUP.SEND_REQUEST_PICKUP_TEXT')}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSendEmailDialogClose}>
                        {intl.get('SCHEDULE_PICKUP.SEND_REQUEST_NO')}
                    </Button>
                    <LoadingButton
                        onClick={handleSchedulePickupRequest}
                        loading={sendEmailLoading}
                    >
                        {intl.get('SCHEDULE_PICKUP.SEND_REQUEST_CONFIRM')}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {/*Pop-up Toast*/}
            <Dialog
                open={openAddressBook}
                onClose={handleCloseSenderAddressBook}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    <Box textAlign="center">Address Book</Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        sx={{gap: "1rem"}}
                    >
                        {addressBookList.map((item) => (
                            <Box display="flex" mb={2} key={item.id} width="100%">
                                <Box
                                    p={1}
                                    width="100%"
                                    component="button"
                                    sx={{border: "1px dashed grey"}}
                                    className={classes.addressLine}
                                    onClick={() => {
                                        // setFormatData({
                                        //   phone: item.phone,
                                        //   address: item.address,
                                        //   zipCode: item.postal_code,
                                        //   city: item.city,
                                        //   province: item.province,
                                        // });
                                        // setSenderAddressInputValue(item.address);
                                        // setSenderPostalCodeInputValue(item.postal_code);
                                        // setSenderCityInputValue(item.city);
                                        // setSenderProvinceInputValue(item.province);
                                        // setOpenSenderAddressBook(false);
                                        // setFormatData((v) => {
                                        //   return {
                                        //     ...v,
                                        //     pickUp: {
                                        //       phone: item.phone,
                                        //       address: item.address,
                                        //       zipCode: item.postal_code,
                                        //       city: item.city,
                                        //       aptInfo: item.address_2,
                                        //       province: getProvinceCodeByName(item.province),
                                        //       name: item.name,
                                        //       country: item.country,
                                        //       email: item.address_email
                                        //     },
                                        //   };
                                        // });
                                        setValue(item.address);
                                        setFormValue("city", item.city || "");
                                        setFormValue("province", getCanadaProvinceNameByCode(item.province) || "");
                                        setProvince(getCanadaProvinceNameByCode(item.province) || "");
                                        setCountry(item.country || "");
                                        setFormValue("postalCode", item.postal_code || "");
                                        setFormValue("country", item.country || "");
                                        setFormValue("email", item.address_email);
                                        setFormValue("phone", item.phone);
                                        setFormValue("name", item.name);
                                        handleCloseSenderAddressBook();
                                    }}
                                >
                                    <h4>{intl.get('SCHEDULE_PICKUP.NAME')}: {item.name}</h4>
                                    <h4>{intl.get('SCHEDULE_PICKUP.PHONE')}: {item.phone}</h4>
                                    <h4>{intl.get('SCHEDULE_PICKUP.EMAIL')}: {item.address_email !== "" ? item.address_email : "N/A"}</h4>
                                    <h4>
                                        {intl.get('SCHEDULE_PICKUP.ADDRESS')}: {item.address}, {item.city}, {item.province},{" "}
                                        {item.country}, {item.postal_code}
                                    </h4>
                                </Box>
                                <IconButton
                                    onClick={async () => {
                                        // Remove this address by item id
                                        const itemId = item.id;
                                        setAddressBookList((currentAddressBookList) =>
                                            currentAddressBookList.filter(
                                                (address) => address.id !== itemId
                                            )
                                        );
                                        await deleteAddressBookByPartnerIdAndAddressBookId({
                                            partnerId: id,
                                            id: itemId,
                                        });
                                        setAddressBookList(
                                            await getSenderAddressBookList({partnerId: id})
                                        );
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>
            {isSuccess ? (
                <>
                    {/* <BrowserRouter> */}
                    {/* <Switch> */}
                    {/* <Route exact path="/"> */}
                    <Redirect to="/MyScheduledPickups"/>
                    {/* </Route> */}
                    {/* </Switch> */}
                    {/* </BrowserRouter> */}
                </>
            ) : undefined}
            <Box
                sx={{width: "100%", textAlign: "start"}}
                display="flex"
                justifyContent="center"
            >
                <Box sx={{width: "50%"}}>
                    <Typography
                        variant="h4"
                        sx={{
                            mt: 2,
                        }}
                    >
                        {intl.get('SCHEDULE_PICKUP.SCHEDULE_A_PICKUP')}
                        <Tooltip title={intl.get('SCHEDULE_PICKUP.REDIRECT_TO_PICKUP')} placement="top">
                            <IconButton
                                // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                                onClick={() => {
                                    window.open("https://uucargo.ca/support/pickup-appointment-for-parcels/", "_blank")
                                }}
                            >
                                <HelpIcon color="info" sx={{fontSize: 40}}/>
                            </IconButton>
                        </Tooltip>
                    </Typography>
                    <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                        <Tabs
                            value={valueTab}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab color="primary.main" label={intl.get('SCHEDULE_PICKUP.PACKAGE')} {...a11yProps(0)} />
                            <Tab color="primary.main"
                                 label={intl.get('SCHEDULE_PICKUP.MY_SCHEDULE_PICKUPS')} {...a11yProps(1)} />
                            {/* <Tab label="Item Two" {...a11yProps(1)} /> */}
                        </Tabs>
                    </Box>
                    <TabPanel value={valueTab} index={0}>
                        <Box noValidate autoComplete="off">
                            <Box
                                p={2}
                                mb={2}
                                sx={{
                                    backgroundColor: "white",
                                    textAlign: "start",
                                }}
                            >
                                <Typography>1. {intl.get('SCHEDULE_PICKUP.SELECT_CARRIER')}</Typography>
                            </Box>
                            <Box mb={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">
                                        {intl.get('SCHEDULE_PICKUP.CARRIER')}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Carrier"
                                        defaultValue={"UPS"}
                                        onChange={e => {
                                            setAccountBase(e.target.value);
                                            setPickupTime(null);
                                            setSearchInput("");
                                            setTrackingNumbers("");
                                        }}
                                        error={formErrors.courier}
                                    >
                                        <MenuItem value={"UPS"}>UPS</MenuItem>
                                        <MenuItem value={"Canpar"}>Canpar</MenuItem>
                                        <MenuItem value={"FedEx"}>FedEx</MenuItem>
                                        {/*<MenuItem value={"fedex"}>Fedex</MenuItem>*/}
                                        <MenuItem value={"CanadaPost"}>CanadaPost</MenuItem> // add CanadaPost
                                    </Select>
                                    <FormHelperText
                                        sx={{
                                            color: "error.main",
                                        }}
                                    >
                                        {formErrors.courier ? intl.get('SCHEDULE_PICKUP.REQUIRED') : undefined}
                                    </FormHelperText>
                                </FormControl>
                                <br/>
                                <br/>
                                {/*<FormControl fullWidth size="small">*/}
                                {/*    <InputLabel id="demo-simple-select-label">*/}
                                {/*        Service Type (Only For FedEx)*/}
                                {/*    </InputLabel>*/}
                                {/*    <Select*/}
                                {/*        labelId="demo-simple-select-label"*/}
                                {/*        id="demo-simple-select"*/}
                                {/*        label="Service Type (Only For FedEx)"*/}
                                {/*        defaultValue={"standard"}*/}
                                {/*        onChange={e => {*/}
                                {/*            setServiceType(e.target.value);*/}
                                {/*        }*/}
                                {/*        }*/}
                                {/*    >*/}
                                {/*        <MenuItem value={"standard"}>Standard</MenuItem>*/}
                                {/*        <MenuItem value={"express"}>Express</MenuItem>*/}
                                {/*    </Select>*/}
                                {/*    <FormHelperText*/}
                                {/*        sx={{*/}
                                {/*            color: "error.main",*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        {formErrors.courier ? "Required" : undefined}*/}
                                {/*    </FormHelperText>*/}
                                {/*</FormControl>*/}
                            </Box>
                        </Box>
                        <Box
                            p={2}
                            mb={2}
                            sx={{
                                backgroundColor: "white",
                                textAlign: "start",
                            }}
                        >
                            <Typography>2. {intl.get('SCHEDULE_PICKUP.SHIPPING_LABELS')}</Typography>
                        </Box>
                        <Box>
                            <FormControl fullWidth>
                                <TextField
                                    value={searchInput}
                                    size='small'
                                    label={intl.get('SCHEDULE_PICKUP.SEARCH_TRACKING')}
                                    onInput={handleSearchTracking}
                                />
                            </FormControl>
                        </Box>
                        <Box mb={2}>
                            <Accordion className={classes.accordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        {intl.get('SCHEDULE_PICKUP.ADD_YOUR_EXISTING_TRACKING')}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography maxHeight="400px" sx={{overflow: "scroll"}}>
                                        <table className={classes.table}>
                                            <thead>
                                            <tr className={classes.listItemWrapper}>
                                                <th className={classes.idTH}>{intl.get('SCHEDULE_PICKUP.ORDER_REF_PRODUCT')}</th>
                                                {/*<th className={classes.nameTH}>Ref Product #</th>*/}
                                                <th className={classes.nameTH}>{intl.get('SCHEDULE_PICKUP.SEND_TO')}</th>
                                                <th className={classes.nameTH}>{intl.get('SCHEDULE_PICKUP.CARRIER_TRACKING')}</th>
                                                {/*<th className={classes.nameTH}>Account Base</th>*/}
                                                {/*<th className={classes.nameTH}>Label</th>*/}
                                                <th className={classes.nameTH}>{intl.get('SCHEDULE_PICKUP.DATE')}</th>
                                                <th className={classes.nameTH}></th>
                                            </tr>
                                            </thead>
                                            <tbody className={classes.tbody}>
                                            {trackingListSearch?.map((v, index) => {
                                                return (
                                                    <TrackingInfoTableItem
                                                        key={index}
                                                        accountBase={v.account_base}
                                                        onClick={(e) => {
                                                            if (trackingNumbers?.trim() === "") {
                                                                setTrackingNumbers(v.tracking_no);
                                                            } else {
                                                                setTrackingNumbers(prevState => {
                                                                    return prevState.concat(`\n${v.tracking_no}`);
                                                                })
                                                            }
                                                        }}
                                                        orderId={v.order_id}
                                                        trackingNumber={v.tracking_no}
                                                        date={v.created_at}
                                                        sendToEmail={v.send_to_email}
                                                        refProductNumber={v.ref_product_no}
                                                        sendToAddress={v.send_to_addr}
                                                        sendToCity={v.send_to_city}
                                                        sendToCountry={v.send_to_country}
                                                        sendToName={v.send_to_name}
                                                        sendToPhone={v.send_to_tel}
                                                        sendToProvince={v.send_to_province}
                                                        sendToPostalCode={v.send_to_postal_code}
                                                        labelURI={v?.label_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_uri}` : ""}
                                                    />
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <Box mb={2} noValidate autoComplete="off">
                            <FormControl fullWidth>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    {intl.get('SCHEDULE_PICKUP.ENTER_TRACKING_LABEL')}
                                </FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    placeholder={intl.get('SCHEDULE_PICKUP.ENTER_TRACKING_TEXT')}
                                    value={trackingNumbers}
                                    onChange={e => {
                                        setTrackingNumbers(e.target.value);
                                    }}
                                    error={formErrors.trackingNumbers || trackingNumberError?.length}
                                    helperText={
                                        formErrors.trackingNumbers
                                            ? intl.get('SCHEDULE_PICKUP.REQUIRED')
                                            : trackingNumberError?.length ?
                                                trackingNumberError?.map((error) => `${error?.trackingNumber}: ${error?.message}`).join('\n') : ""
                                    }
                                />
                            </FormControl>
                        </Box>
                        <Box
                            p={2}
                            mb={2}
                            sx={{
                                backgroundColor: "white",
                                textAlign: "start",
                            }}
                        >
                            <Typography>3. {intl.get('SCHEDULE_PICKUP.VOLUME_WEIGHT')}</Typography>
                        </Box>
                        <Box mb={2} noValidate autoComplete="off">
                            <Grid container rowSpacing={2}>
                                <Grid item xs={6}>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        {intl.get('SCHEDULE_PICKUP.VOLUME')}
                                    </FormLabel>
                                    <TextField
                                        disabled={true}
                                        value={totalVolume}
                                        fullWidth
                                        variant='outlined'
                                        type='number'
                                        size='small'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    in
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                min: 1
                                            },
                                            style: {fontSize: 14}
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        {intl.get('SCHEDULE_PICKUP.WEIGHT')}
                                    </FormLabel>
                                    <TextField
                                        disabled={true}
                                        value={totalWeight}
                                        fullWidth
                                        variant='outlined'
                                        type='number'
                                        size='small'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    lb
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                min: 1
                                            },
                                            style: {fontSize: 14}
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            p={2}
                            mb={2}
                            sx={{
                                backgroundColor: "white",
                                textAlign: "start",
                            }}
                        >
                            <Typography>
                                4. {intl.get('SCHEDULE_PICKUP.INFORMATION_LOCATION')}
                                <Tooltip title={intl.get('SCHEDULE_PICKUP.ADDRESS_BOOK')} placement="right">
                                    <Button
                                        // variant="contained"
                                        // component="span"
                                        // color="primary"
                                        style={{
                                            // textTransform: "unset",
                                            fontSize: "1rem",
                                            // width: "10rem",
                                            cursor: "pointer",
                                            padding: 0,
                                            border: "none",
                                            background: "none"
                                        }}
                                        onClick={async () => {
                                            await handleOpenSenderAddressBook()
                                        }}
                                    >
                                        <img
                                            src="/book.png"
                                            style={{width: "30px", height: "30px"}}
                                            alt="image"
                                        />
                                        {/* Address Book */}
                                    </Button>
                                </Tooltip>
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <FormControl fullWidth>
                                <Grid container rowSpacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            sx={{
                                                width: "100%",
                                            }}
                                            id="outlined-multiline-static"
                                            InputLabelProps={{shrink: true}}
                                            label={intl.get('SCHEDULE_PICKUP.NAME')}
                                            {...register("name")}
                                            error={formErrors.name}
                                            helperText={formErrors.name ? intl.get('SCHEDULE_PICKUP.REQUIRED') : undefined}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            sx={{
                                                width: "100%",
                                            }}
                                            id="outlined-multiline-static"
                                            label={intl.get('SCHEDULE_PICKUP.COMPANY_NAME')}
                                            InputLabelProps={{shrink: true}}
                                            {...register("companyName")}
                                            error={formErrors.companyName}
                                            helperText={
                                                formErrors.companyName ? intl.get('SCHEDULE_PICKUP.REQUIRED') : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            sx={{
                                                width: "100%",
                                            }}
                                            id="outlined-multiline-static"
                                            label={intl.get('SCHEDULE_PICKUP.EMAIL')}
                                            InputLabelProps={{shrink: true}}
                                            {...register("email")}
                                            error={formErrors.email}
                                            helperText={
                                                formErrors.email
                                                    ? intl.get('SCHEDULE_PICKUP.REQUIRED_INVALID_EMAIL')
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            size="small"
                                            sx={{
                                                width: "100%",
                                            }}
                                            id="outlined-multiline-static"
                                            label={intl.get('SCHEDULE_PICKUP.PHONE')}
                                            InputLabelProps={{shrink: true}}
                                            {...register("phone")}
                                            error={formErrors.phone}
                                            helperText={formErrors.phone ? intl.get('SCHEDULE_PICKUP.REQUIRED') : undefined}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            size="small"
                                            sx={{
                                                width: "100%",
                                            }}
                                            id="outlined-multiline-static"
                                            label="Ext. (Optional)"
                                            {...register("ext")}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <TextField
                      size="small"
                      sx={{
                        width: "100%",
                      }}
                      id="outlined-multiline-static"
                      label="Address"
                      {...register("address")}
                      error={formErrors.address}
                      helperText={formErrors.address ? "Required" : undefined}
                    /> */}
                                        <Autocomplete
                                            id="google-map-demo"
                                            sx={{width: "100%"}}
                                            getOptionLabel={(option) => {
                                                return typeof option === "string" ? option : option?.structured_formatting?.main_text
                                            }
                                            }
                                            freeSolo
                                            filterOptions={(x) => x}
                                            options={options}
                                            autoComplete
                                            includeInputInList
                                            filterSelectedOptions
                                            value={value}
                                            onChange={(event, newValue) => {
                                                console.log('new value', newValue);
                                                // setOptions(newValue ? [newValue, ...options] : options);
                                                setOptions([newValue, ...options]);
                                                // setValue(newValue);
                                                if (!newValue) {
                                                    setValue("");
                                                    return;
                                                }
                                                if (!getDetailService.current && window.google) {
                                                    getDetailService.current = new window.google.maps.Geocoder();
                                                }
                                                if (!getDetailService.current) {
                                                    return;
                                                }
                                                setValue(newValue?.structured_formatting?.main_text);
                                                console.log(`New Address: ${newValue?.structured_formatting?.main_text}`)
                                                getDetailService.current
                                                    ?.geocode({
                                                        placeId: newValue.place_id,
                                                        language: "en",
                                                    })
                                                    .then(({results}) => {
                                                        // setPlaceId(newValue.place_id);
                                                        let streetValue = "";
                                                        const addressObj = results[0].address_components;
                                                        console.log("Address Obj:", addressObj);
                                                        const predictStreetNumber = addressObj.find(
                                                            (ele) => ele.types[0] === "street_number"
                                                        );
                                                        const predictRoute = addressObj.find(
                                                            (ele) => ele.types[0] === "route"
                                                        );

                                                        // if (predictStreetNumber)
                                                        //   streetValue += predictStreetNumber.long_name;
                                                        // if (predictRoute)
                                                        //   streetValue += predictStreetNumber
                                                        //       ? ` ${predictRoute.long_name}`
                                                        //       : predictRoute.long_name;
                                                        //
                                                        // if (streetValue) {
                                                        //   setValue(streetValue);
                                                        // }
                                                        console.log("Address Object in google:", addressObj);

                                                        const predictCity = addressObj.find(
                                                            (ele) => ele.types[0] === "locality"
                                                        );
                                                        const predictProvince = addressObj.find(
                                                            (ele) => ele.types[0] === "administrative_area_level_1"
                                                        );
                                                        const predictZipcode = addressObj.find(
                                                            (ele) => ele.types[0] === "postal_code"
                                                        );
                                                        const predictCountry = addressObj.find(
                                                            (ele) => ele.types[0] === "country"
                                                        );
                                                        const predictAddressLineTwo = addressObj.find(
                                                            (ele) => ele.types[0] === "subpremise"
                                                        );
                                                        setFormValue("city", predictCity?.short_name || "");
                                                        setFormValue("province", predictProvince?.long_name || "");
                                                        console.log("province: ", predictProvince?.long_name)
                                                        setProvince(predictProvince?.long_name || "");
                                                        setCountry(predictCountry?.short_name || "");
                                                        setFormValue("postalCode", predictZipcode?.long_name || "");
                                                        setFormValue("country", predictCountry?.short_name || "");
                                                        // Update city and province value if the user input and select the address
                                                    });
                                            }}
                                            // onChange={async (event, newValue) => {
                                            //   setOptions(newValue ? [newValue, ...options] : options);
                                            //   console.log("newValue:", newValue);
                                            //   if (!newValue || !newValue?.structured_formatting) {
                                            //     setValue("");
                                            //     return;
                                            //   }
                                            //   setValue(newValue?.structured_formatting?.main_text);
                                            //
                                            //   if (!getDetailService.current && window.google) {
                                            //     getDetailService.current =
                                            //       new window.google.maps.Geocoder();
                                            //   }
                                            //   if (!getDetailService.current) {
                                            //     return;
                                            //   }
                                            //   const results = await getDetailService.current?.geocode(
                                            //     {
                                            //       placeId: newValue.place_id,
                                            //       language: "en",
                                            //     }
                                            //   );
                                            //   const addressComponents =
                                            //     results.results[0].address_components;
                                            //   console.log("addressComponents: ", addressComponents);
                                            //   const addressObject = {};
                                            //   addressComponents.map((component) => {
                                            //     addressObject[component.types[0]] =
                                            //       component["long_name"];
                                            //   });
                                            //
                                            //   console.log({ ...newValue, ...addressObject });
                                            //   // setValueDetailed({ ...newValue, ...addressObject });
                                            // }}
                                            onInputChange={(event, newInputValue, reason) => {
                                                console.log('new input value', newInputValue);
                                                setValue(newInputValue);
                                                if (reason === "input") {
                                                    setInputValue(newInputValue);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    size="small"
                                                    error={formErrors.address}
                                                    helperText={
                                                        formErrors.address ? intl.get('SCHEDULE_PICKUP.REQUIRED') : undefined
                                                    }
                                                    {...params}
                                                    label={intl.get('SCHEDULE_PICKUP.ADDRESS')}
                                                    fullWidth
                                                    value={value}
                                                />
                                            )}
                                            renderOption={(props, option) => {
                                                if (!option?.structured_formatting) {
                                                    return <li {...props}></li>
                                                }
                                                const matches =
                                                    option.structured_formatting
                                                        .main_text_matched_substrings;
                                                const parts = parse(
                                                    option.structured_formatting.main_text,
                                                    matches.map((match) => [
                                                        match.offset,
                                                        match.offset + match.length,
                                                    ])
                                                );

                                                return (
                                                    <li {...props}>
                                                        <Grid container alignItems="center">
                                                            <Grid item>
                                                                <Box
                                                                    component={LocationOnIcon}
                                                                    sx={{color: "text.secondary", mr: 2}}
                                                                />
                                                            </Grid>
                                                            <Grid item xs>
                                                                {parts.map((part, index) => (
                                                                    <span
                                                                        key={index}
                                                                        style={{
                                                                            fontWeight: part.highlight ? 700 : 400,
                                                                        }}
                                                                    >
                                                                        {part.text}
                                                                    </span>
                                                                ))}
                                                                <Typography
                                                                    variant="body2"
                                                                    color="text.secondary"
                                                                >
                                                                    {option.structured_formatting.secondary_text}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </li>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            sx={{
                                                width: "100%",
                                            }}
                                            id="outlined-multiline-static"
                                            label={intl.get('SCHEDULE_PICKUP.POSTAL_CODE')}
                                            {...register("postalCode")}
                                            error={formErrors.postalCode}
                                            helperText={
                                                formErrors.postalCode ? intl.get('SCHEDULE_PICKUP.REQUIRED') : undefined
                                            }
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            size="small"
                                            sx={{
                                                width: "100%",
                                            }}
                                            id="outlined-multiline-static"
                                            label={intl.get('SCHEDULE_PICKUP.CITY')}
                                            {...register("city")}
                                            error={formErrors.city}
                                            helperText={formErrors.city ? intl.get('SCHEDULE_PICKUP.REQUIRED') : undefined}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">
                                                {intl.get('SCHEDULE_PICKUP.PROVINCE')}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Select Carrier"
                                                value={province}
                                                onChange={v => {
                                                    setProvince(v.target.value)
                                                }
                                                }
                                                error={formErrors.province}
                                            >
                                                {provinceList.map((provinceValue, index) => {
                                                    return (
                                                        <MenuItem key={index} value={provinceValue}>
                                                            {provinceValue}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <FormHelperText
                                                sx={{
                                                    color: "error.main",
                                                }}
                                            >
                                                {formErrors.province ? intl.get('SCHEDULE_PICKUP.REQUIRED') : undefined}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">
                                                {intl.get('SCHEDULE_PICKUP.COUNTRY')}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Country"
                                                value={country}
                                                onChange={v => {
                                                    setCountry(v.target.value)
                                                }
                                                }
                                                error={formErrors.country}
                                            >
                                                {countryList.map((countryValue, index) => {
                                                    return (
                                                        <MenuItem key={index} value={countryValue}>
                                                            {countryValue}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <FormHelperText
                                                sx={{
                                                    color: "error.main",
                                                }}
                                            >
                                                {formErrors.province ? intl.get('SCHEDULE_PICKUP.REQUIRED') : undefined}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/*<TextField*/}
                                        {/*    size="small"*/}
                                        {/*    sx={{*/}
                                        {/*      width: "100%",*/}
                                        {/*    }}*/}
                                        {/*    id="outlined-multiline-static"*/}
                                        {/*    label="Pickup Point (Optional) e.g. Front Door"*/}
                                        {/*    {...register("pickupPoint")}*/}
                                        {/*    error={formErrors.pickupPoint}*/}
                                        {/*    helperText={*/}
                                        {/*      formErrors.pickupPoint ? "Required" : undefined*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">
                                                {intl.get('SCHEDULE_PICKUP.PICKUP_POINT')}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Pickup Point"
                                                defaultValue={"FRONT"}
                                                onChange={e => {
                                                    setPickupPoint(e.target.value);
                                                }}
                                                // error={formErrors.courier}
                                            >
                                                <MenuItem value={"FRONT"}>Front Door</MenuItem>
                                                <MenuItem value={"REAR"}>Rear Door</MenuItem>
                                                <MenuItem value={"SIDE"}>Side Door</MenuItem>
                                                <MenuItem value={"NONE"}>None</MenuItem>
                                            </Select>
                                            <FormHelperText
                                                sx={{
                                                    color: "error.main",
                                                }}
                                            >
                                                {formErrors.courier ? intl.get('SCHEDULE_PICKUP.REQUIRED') : undefined}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox {...register("isResidential")} />}
                                                label={intl.get('SCHEDULE_PICKUP.IS_RESIDENTIAL')}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={isSaveAddress}
                                            onClick={handleSaveAddress}
                                            disabled={!isFullInformation()}
                                        />
                                    }
                                    disabled={!isFullInformation()}
                                    label={intl.get('SCHEDULE_PICKUP.SAVE_TO_ADDRESS_BOOK')}
                                />
                            </FormGroup>
                        </Box>
                        <Box
                            p={2}
                            mb={2}
                            sx={{
                                backgroundColor: "white",
                                textAlign: "start",
                            }}
                        >
                            <Typography>5. {intl.get('SCHEDULE_PICKUP.DATE_TIME')}</Typography>
                        </Box>
                        <Box mb={2} noValidate autoComplete="off">
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth size="small">
                                        <Controller
                                            name="pickupDate"
                                            control={control}
                                            // defaultValue="yes"
                                            render={({field: {onChange, value}}) => (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label={intl.get('SCHEDULE_PICKUP.PICKUP_DATE')}
                                                        inputFormat="MM/DD/YYYY"
                                                        value={value}
                                                        onChange={onChange}
                                                        disablePast
                                                        shouldDisableDate={disableWeekends}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        error={formErrors.pickupDate}
                                                        helperText={
                                                            formErrors.pickupDate
                                                                ? "Required Valid Date"
                                                                : undefined
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth size="small">
                                        <Controller
                                            name="pickupDateEarly"
                                            control={control}
                                            // defaultValue="yes"
                                            render={({field: {onChange, value}}) => (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker
                                                        label={intl.get('SCHEDULE_PICKUP.EARLIEST_PICKUP_TIME')}
                                                        value={value}
                                                        onChange={onChange}
                                                        maxTime={dayjs().hour(16).minute(0).second(0)}
                                                        minTime={dayjs().hour(7).minute(0).second(0)}
                                                        minutesStep={15}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        error={true}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        />
                                        <FormHelperText
                                            sx={{
                                                color: "error.main",
                                            }}
                                        >
                                            {formErrors.pickupDateEarly
                                                ? "Invalid Time"
                                                : formErrors.invalidEarlyAndLateDateTime
                                                    ? "Earliest time must be before latest time"
                                                    : formErrors.invalidEarlyDateTime
                                                        ? "Earliest time must be after 10:00AM"
                                                        : formErrors.invalidEarlyDateTimeCanadaPost
                                                            ? "For CanadaPost, the earliest pickup time must be between 12:00 PM and 4:00 PM"
                                                            : formErrors.invalidEarlyDateTimeIntervalCanadaPost
                                                                ? "For CanadaPost, all time is in 15-minute intervals. Please note that minutes should be either 00, 15, 30, or 45"
                                                                : undefined}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth size="small">
                                        <Controller
                                            name="pickupDateLate"
                                            control={control}
                                            render={({field: {onChange, value}}) => (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <TimePicker
                                                        label={intl.get('SCHEDULE_PICKUP.LATEST_PICKUP_TIME')}
                                                        value={value}
                                                        onChange={onChange}
                                                        maxTime={dayjs().hour(19).minute(30).second(0)}
                                                        minTime={dayjs().hour(10).minute(0).second(0)}
                                                        minutesStep={15}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        />
                                        <FormHelperText
                                            sx={{
                                                color: "error.main",
                                            }}
                                        >
                                            {formErrors.pickupDateLate
                                                ? "Invalid Time"
                                                : formErrors.invalidLateDateTime
                                                    ? "Latest time must be before 5:30PM"
                                                    : formErrors.invalidLateDateTimeIntervalCanadaPost
                                                        ? "For CanadaPost, all time is in 15-minute intervals. Please note that minutes should be either 00, 15, 30, or 45"
                                                        : undefined}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                        {accountBase === "CanadaPost" && <Box>
                            <Typography style={{
                                fontSize: '12px',
                                fontStyle: 'italic'
                            }}>
                                {intl.get('SCHEDULE_PICKUP.CANADA_POST_NOTE')}
                            </Typography>
                        </Box>}
                        {
                            (accountBase === 'FedEx' && pickupTime) && <Box>
                                <Typography style={{
                                    fontSize: '12px',
                                    fontStyle: 'italic'
                                }}>
                                    {/*Note: The earliest pickup time is {pickupTime?.defaultReadyTime} and the latest pickup*/}
                                    {/*time is {pickupTime?.defaultLatestTimeOptions} based on the provide postal code. It is*/}
                                    {/*strongly recommended to use the default pickup time to ensure successful scheduling.*/}
                                    {/*Failure to do so may result in pickup scheduling issues.*/}
                                    {intl.get('SCHEDULE_PICKUP.FEDEX_NOTE', {
                                        defaultReadyTime: pickupTime?.defaultReadyTime,
                                        defaultLatestTimeOptions: pickupTime?.defaultLatestTimeOptions
                                    })}
                                </Typography>
                            </Box>
                        }
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                mb: 20,
                            }}
                        >
                            <Box
                                mb={2}
                                width="100%"
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <LoadingButton
                                    loading={isLoading}
                                    variant="outlined"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {intl.get('SCHEDULE_PICKUP.SUBMIT')}
                                </LoadingButton>
                            </Box>
                            {errorMessage !== "" ? (
                                    <Alert severity="error">{errorMessage}</Alert>
                                ) : // <Typography
                                //   sx={{
                                //     color: "error.main",
                                //     textAlign: "center",
                                //   }}
                                // >

                                // </Typography>
                                undefined}
                            {statusMessage !== "" ? (
                                    <Alert severity="success">{statusMessage}</Alert>
                                ) : // <Typography
                                //   sx={{
                                //     color: "success.main",
                                //     textAlign: "center",
                                //   }}
                                // >
                                //   {statusMessage}
                                // </Typography>
                                undefined}
                        </Box>
                    </TabPanel>
                    {/* <TabPanel value={valueTab} index={1}>
            Item Two
          </TabPanel> */}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default SchedulePickup;
