import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@material-ui/core/Grid";
import * as React from 'react';
import MuiAlert from "@mui/material/Alert";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {Snackbar} from "@mui/material";
import {getAccessToken} from "../../utils/doToken";
import {useHistory} from "react-router-dom";
import {ReactComponent as HelpIcon} from "../../images/helpIcon.svg";
import intl from "react-intl-universal";

const styles = {
    TopUpEmailTransferRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    TopUpEmailTransferTitle: {
        fontWeight: '600',
        textAlign: 'left'
    },
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TopUpEmailTransfer = ({cbReferenceNumber}) => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");

    const [referenceNumber, setReferenceNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [show, setShow] = useState(false);
    const handleOpenShow = () => setShow(true);
    const handleCloseShow = () => setShow(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleReferenceNumber = (event) => {
        setReferenceNumber(event.target.value);
    }

    const TopUpViaEmailReferenceNumber = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/billingCenter/partnerTopUpByEmailReferenceNumber`;

        const data = {
            referenceNumber: referenceNumber,
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: data
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successful Top Up');
            handleSuccessMessage();
            setLoading(false);
            setTimeout(() => {
                history.push("/billing-center");
            }, 2000);
        } catch (e) {
            console.log(e.response)
            setErrorMessage(e?.response?.data?.error);
            handleErrorMessage();
            setLoading(false);
        }
    }

    const handleEmailTopUp = () => {
        TopUpViaEmailReferenceNumber();
    }

    useEffect(() => {
        cbReferenceNumber(referenceNumber);
    }, [referenceNumber])

    return (
        <Grid container spacing={4} alignItems="center" justifyContent="center">
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item md={9} xs={12}>
                <Box sx={styles.TopUpEmailTransferRoot}>
                    <Box
                        sx={{display: 'flex', gap: '5px', position: 'relative'}}
                        onMouseEnter={handleOpenShow}
                        onMouseLeave={handleCloseShow}
                    >
                        <Typography style={styles.TopUpEmailTransferTitle}>
                            {intl.get('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.EMAIL_TRANSFER')}
                        </Typography>
                        <HelpIcon width={20} height={20}/>
                        {show &&
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#1D8B45',
                                    width: '220px',
                                    // height: '150px',
                                    padding: '20px',
                                    borderRadius: '5px',
                                    color: '#ffffff',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    top: '100%',
                                    left: '35%',
                                    zIndex: 1000
                                }}
                            >
                                {intl.get('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.EMAIL_TRANSFER_NOTE')}
                            </Typography>
                        }
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                            {intl.getHTML('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.STEP_1')}
                        </Typography>
                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                            {intl.getHTML('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.E_TRANSFER_TOP_UP')}
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                            {intl.getHTML('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.STEP_2_TOP_UP')}
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <InputLabel style={{
                            textAlign: 'left',
                            fontSize: '14px'
                        }}>
                            {intl.getHTML('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.REFERENCE_NUMBER')}
                        </InputLabel>
                        <TextField
                            size='small'
                            onChange={handleReferenceNumber}
                            sx={{width: '300px'}}
                        />
                    </Box>
                </Box>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>*/}
            {/*        <Button*/}
            {/*            variant='contained'*/}
            {/*            sx={{*/}
            {/*                backgroundColor: '#F2BE22',*/}
            {/*                "&:hover": {*/}
            {/*                    backgroundColor: '#F2BE22',*/}
            {/*                    filter: 'brightness(0.9)'*/}
            {/*                }*/}
            {/*            }}*/}
            {/*            onClick={() => history.push('/billing-center')}*/}
            {/*        >*/}
            {/*            <Typography style={{textTransform: 'none', fontSize: '14px'}}>*/}
            {/*                Back to Billing Center*/}
            {/*            </Typography>*/}
            {/*        </Button>*/}
            {/*        <LoadingButton*/}
            {/*            variant='contained'*/}
            {/*            sx={{*/}
            {/*                backgroundColor: '#1D8B45',*/}
            {/*                "&:hover": {*/}
            {/*                    backgroundColor: '#1D8B45',*/}
            {/*                    filter: 'brightness(0.9)'*/}
            {/*                }*/}
            {/*            }}*/}
            {/*            onClick={handleEmailTopUp}*/}
            {/*            loading={loading}*/}
            {/*            disabled={referenceNumber ? false : true}*/}
            {/*        >*/}
            {/*            <Typography style={{textTransform: 'none', fontSize: '14px'}}>*/}
            {/*                Confirm*/}
            {/*            </Typography>*/}
            {/*        </LoadingButton>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}
        </Grid>
    )
}

// <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
//     <Button>
//         <Typography>
//             Back to Billing Center
//         </Typography>
//     </Button>
//     <LoadingButton>
//         <Typography>
//             Confirm
//         </Typography>
//     </LoadingButton>
// </Box>