import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import intl from "react-intl-universal";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@mui/material/TextField";
import {useSelector} from "react-redux";
import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import {FormLabel, MenuItem, Select} from "@mui/material";
import {InputAdornment} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {calculatePallet} from "../../utils/Helper";

const styles = {
    ShippingLTLQuickQuotePalletEachSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },

    ShippingLTLQuickQuotePalletEachActionSection: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },

    ShippingLTLQuickQuotePalletEachActionSectionMobile: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'flex-end',
    },
    ShippingLTLQuickQuotePalletEachSectionRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelPackageEachWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "0px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

const palletType = [
    {name: '48"x40"', length: '48', width: '40'},
    {name: '48"x48"', length: '48', width: '48'},
    {name: 'Custom', length: null, width: null}
]

const palletTypeMetric = [
    {name: '122cmx102cm', length: '122', width: '102'},
    {name: '122cmx122cm', length: '122', width: '122'},
    {name: 'Custom', length: null, width: null}
]

export const PalletCalculator = ({taskDetails}) => {

    const classes = useStyles();

    const metric = useSelector(state => state?.shippingLTL?.quickQuoteUnit);
    const [pallet, setPallet] = useState({
        packagingType: metric ? {name: '122cmx102cm', length: '122', width: '102'} : {
            name: '48"x40"',
            length: '48',
            width: '40'
        },
        length: metric ? '122' : '48',
        width: metric ? '102' : '40',
        height: metric ? '203' : '80',
        weight: metric ? '1360' : '3000',
        lengthUnit: metric ? 'cm' : 'in',
        weightUnit: metric ? 'kg' : 'lb',
    });
    const [palletQuantity, setPalletQuantity] = useState(0);

    const handlePackageType = (event, value) => {
        if (value) {
            if (value?.name === 'Custom') {
                setPallet(prevState => ({
                    ...prevState,
                    packagingType: value
                }))
            } else {
                setPallet(prevState => ({
                    ...prevState,
                    packagingType: value,
                    length: value?.length,
                    width: value?.width,
                    height: metric ? '203' : '80'
                }))
            }
        }
    }

    const handleLength = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setPallet(prevState => ({
                ...prevState,
                length: value
            }))
        }
    }

    const handleWidth = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setPallet(prevState => ({
                ...prevState,
                width: value
            }))
        }
    }

    const handleHeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            if (pallet?.packagingType?.name === 'Custom') {
                setPallet(prevState => ({
                    ...prevState,
                    height: value
                }))
            } else {
                const height = metric ? '229' : '90';
                setPallet(prevState => ({
                    ...prevState,
                    height: value > (+height) ? height : value
                }))
            }
        }
    }

    const handleWeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            const weight = metric ? '1360' : '3000';
            setPallet(prevState => ({
                ...prevState,
                weight: value > (+weight) ? weight : value
            }))
        }
    }

    const handleCalculatePallet = () => {
        const palletQuantity = calculatePallet(pallet?.length, pallet?.width, pallet?.height, pallet?.weight, taskDetails?.boxVolumes, taskDetails?.boxWeights);
        console.log('[PalletCalculator] pallet quantity', palletQuantity);
        setPalletQuantity(palletQuantity);
    }

    console.log('[PalletCalculator] pallet', pallet);

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        {intl.get('ADD_PALLET.PALLET_TYPE')}
                    </FormLabel>
                    <Autocomplete
                        value={pallet?.packagingType}
                        fullWidth
                        options={metric ? palletTypeMetric : palletType}
                        getOptionLabel={option => option.name}
                        onChange={handlePackageType}
                        renderInput={(params) => <TextField
                            variant="outlined"
                            {...params}
                            className={classes.smallInput}
                        />}
                    />
                </FormControl>
            </Grid>
            <Grid item md={9} xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.LENGTH')}
                            </InputLabel>
                            <TextField
                                disabled={(pallet?.packagingType?.name !== 'Custom')}
                                value={pallet?.length}
                                onInput={handleLength}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder='Length'
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {pallet?.lengthUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                                error={pallet?.length <= 0}
                                helperText={pallet?.length <= 0 && 'greater then 0'}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.WIDTH')}
                            </InputLabel>
                            <TextField
                                disabled={(pallet?.packagingType?.name !== 'Custom')}
                                value={pallet?.width}
                                onInput={handleWidth}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder='Width'
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {pallet?.lengthUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                                error={pallet?.width <= 0}
                                helperText={pallet?.width <= 0 && 'greater then 0'}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.HEIGHT')}
                            </InputLabel>
                            <TextField
                                value={pallet?.height}
                                onInput={handleHeight}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder='Height'
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {pallet?.lengthUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                                error={pallet?.height <= 0}
                                helperText={pallet?.height <= 0 && 'greater then 0'}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={styles.ShippingLTLQuickQuotePalletEachSection}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PALLET.WEIGHT')}
                            </InputLabel>
                            <TextField
                                value={pallet?.weight}
                                onInput={handleWeight}
                                fullWidth
                                variant='outlined'
                                type='number'
                                size='small'
                                placeholder='Weight Capacity'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {pallet?.weightUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                                error={pallet?.weight <= 0}
                                helperText={pallet?.weight <= 0 && 'greater then 0'}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleCalculatePallet}
                        disabled={pallet?.length <= 0 || pallet?.width <= 0 || pallet?.height <= 0 || pallet?.weight <= 0}
                    >
                        <Typography sx={{textTransform: 'none'}}>
                            Calculate
                        </Typography>
                    </Button>
                </Box>
            </Grid>
            {
                palletQuantity > 0 &&
                <Grid item xs={12}>
                    <Typography>
                        Result: {palletQuantity} pallet
                    </Typography>
                </Grid>
            }
        </Grid>
    )
}
