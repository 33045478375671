import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import {ReactComponent as CrossBorderLogo} from "../../images/crossborderIcon/CrossBorder.svg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {LTLCrossBorderDashboardItemList} from "./LTLCrossBorderDashboardItemList";
import {LTLCrossBorderDashboardInvoiceList} from "./LTLCrossBorderDashboardInvoiceList";
import {useRef, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLCrossBorderDashboardInvoice = ({orderData, getOrderData}) => {

    const storedToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [invoiceList, setInvoiceList] = useState([]);
    const [isCreate, setIsCreate] = useState(false);
    const inputPDFFile = useRef(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const openCreateInvoice = () => {
        setIsCreate(true);
    }

    const closeCreateInvoice = () => {
        setIsCreate(false);
    }

    const addInvoiceList = (invoice) => {
        console.log('invoice data', invoice);
        setInvoiceList(prevState => [...prevState, invoice]);
    }

    const removeInvoiceList = (invoice) => {
        const invoiceIdToFind = invoice?.invoiceId;
        const filterInvoiceList = invoiceList?.filter(each => each?.invoiceId !== invoiceIdToFind);
        setInvoiceList(filterInvoiceList);
    }

    const clearInvoiceList = () => {
        setInvoiceList([]);
    }

    const uploadPDFToServer = async ({fileObj}) => {
        // console.log('file object', fileObj);
        setLoading(true);
        if (!fileObj) {
            return;
        }
        const formData = new FormData();
        formData.append('invoices', fileObj);
        formData.append('orderId', orderData?.id);
        const requestURL = `${PARTNER_URI}/shippingLTL/uploadLTLCommercialInvoice`;

        try {

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                    Authorization: `Bearer ${storedToken}`,
                },
                data: formData,
            })

            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully upload invoices');
            handleSuccessMessage();
            setTimeout(() => {
                getOrderData();
                // window.location.reload();
            }, 2000);
        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to upload invoices');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }

    }

    const onPDFDrop = async (e) => {
        if (!(e.target.files && e.target.files[0])) {
            return;
        }
        await uploadPDFToServer({fileObj: e.target.files[0]});
    };

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px'
        }}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        height: '125px',
                        alignItems: 'center'
                    }}>
                        <CrossBorderLogo width="100%" height="100%"/>
                    </Box>
                </Grid>
                <Grid item md={7} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <Typography style={{fontSize: '16px'}}>
                                1.
                            </Typography>
                            <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                                <span style={{fontWeight: '600'}}>Create or Upload Invoice:</span> Create an invoice
                                using our system or upload your own commercial invoice.
                            </Typography>
                        </Box>
                        {/*<Box sx={{*/}
                        {/*    display: 'flex',*/}
                        {/*    gap: '5px'*/}
                        {/*}}>*/}
                        {/*    <Typography style={{fontSize: '14px'}}>*/}
                        {/*        2.*/}
                        {/*    </Typography>*/}
                        {/*    <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                        {/*        <span style={{fontWeight: '600'}}>Attach Invoice:</span> If you've created an*/}
                        {/*        invoice, click 'Upload Invoice' to attach it to the corresponding package*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        {/*<Box sx={{*/}
                        {/*    display: 'flex',*/}
                        {/*    gap: '5px'*/}
                        {/*}}>*/}
                        {/*    <Typography style={{fontSize: '14px'}}>*/}
                        {/*        3.*/}
                        {/*    </Typography>*/}
                        {/*    <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                        {/*        <span style={{fontWeight: '600'}}>Submit Invoice:</span> Ensure you've successfully*/}
                        {/*        submitted your invoice in the "Package" Section.*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        <Box sx={{
                            display: 'flex',
                            gap: '5px'
                        }}>
                            <Typography style={{fontSize: '16px'}}>
                                2.
                            </Typography>
                            <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                                <span style={{fontWeight: '600'}}>Prepare for Shipment:</span> Print the invoice
                                forms, place them in a small bag, and attach it next to the shipping
                                label on your pallet.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                        gap: '20px'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={openCreateInvoice}
                            // disabled={invoiceStatus}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                Create Invoice
                            </Typography>
                        </Button>
                        <input type='file' style={{display: 'none'}} ref={inputPDFFile} onChange={onPDFDrop}
                               accept=".pdf" name='file'/>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#F2BE22',
                                "&:hover": {
                                    backgroundColor: '#F2BE22',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={() => {
                                inputPDFFile.current.click()
                            }}
                            loading={loading}
                            // disabled={invoiceList?.length > 0}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                Upload Invoice
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
                <Grid item md={9} xs={12}>
                    <LTLCrossBorderDashboardInvoiceList
                        orderData={orderData}
                        invoiceList={invoiceList}
                        isCreate={isCreate}
                        closeCreateInvoice={closeCreateInvoice}
                        addInvoiceList={addInvoiceList}
                        removeInvoiceList={removeInvoiceList}
                        clearInvoiceList={clearInvoiceList}
                        getOrderData={getOrderData}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <LTLCrossBorderDashboardItemList itemList={orderData?.packingList}/>
                </Grid>
            </Grid>
        </Box>
    )
}