import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {getAccessToken} from "../../../utils/doToken";
import axios from "axios";
import {PARTNER_URI} from "../../../utils/apiUrl";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Divider, Link} from "@mui/material";
import intl from "react-intl-universal";

const style = {
    PackageOrderDashboardAdjustmentOrderRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const PackageOrderDashboardAdjustOrder = () => {

    const {order_id: orderId} = useParams();

    const [trackingList, setTrackingList] = useState([]);

    const getAdjustmentOrder = async () => {
        const storedToken = getAccessToken("access_token");
        try {
            const result = await axios({
                method: "get",
                url: `${PARTNER_URI}/invoices/getAdjustOrdersByShippingOrderId`,
                params: {
                    orderId: orderId,
                },
                headers: {
                    authorization: `Bearer ${storedToken}`,
                },
            });

            const {data} = result;
            console.log('data', data);
            const updatedTrackingList = [];
            if (data[0]) {
                data?.forEach(element => {
                    let object = {
                        trackingNumber: element?.tracking_no,
                        weight: element?.weight,
                        weightUnit: element?.weight_unit,
                        length: element?.length,
                        height: element?.height,
                        width: element?.width,
                        dimensionUnit: element?.dimension_unit,
                        adjustmentAmount: element?.shipping_fee_include_tax,
                        adjustmentOrder: element?.order_id
                    }
                    updatedTrackingList.push(object);
                })
            }
            setTrackingList(updatedTrackingList);

        } catch (e) {
            console.log('error', e.response);
        }
    }

    useEffect(() => {
        getAdjustmentOrder();
    }, [])

    if (trackingList?.length === 0) {
        return (
            <>
            </>
        )
    }

    console.log('adjustment tracking list', trackingList);

    return (
        <Box sx={style.PackageOrderDashboardAdjustmentOrderRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.ADJUSTMENT_ORDERS.ADJUSTMENT_ORDERS')}
                        </Typography>
                    </Box>
                </Grid>
                {
                    trackingList?.map((element, index) => (
                        <Grid item xs={12} key={index}>
                            <Box sx={{padding: '5px 10px'}}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('ORDER_DASHBOARD.ADJUSTMENT_ORDERS.ADJUSTMENT_ORDER')}: <Link
                                    href={`/adjustOrderDashboard/${element.adjustmentOrder}`}
                                    underline="hover"
                                    sx={{
                                        color: '#1D8B45',
                                    }}>{element?.adjustmentOrder}
                                </Link>
                                </Typography>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('ORDER_DASHBOARD.ADJUSTMENT_ORDERS.ADJUSTMENT_AMOUNT')}: $ {element?.adjustmentAmount}
                                </Typography>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('ORDER_DASHBOARD.ADJUSTMENT_ORDERS.ADJUSTMENT_AMOUNT')}: {element?.trackingNumber}
                                </Typography>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('ORDER_DASHBOARD.ADJUSTMENT_ORDERS.PACKAGE_DIMENSION')}: {element?.length} x {element?.width} x {element?.height} {element?.dimensionUnit}
                                </Typography>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('ORDER_DASHBOARD.ADJUSTMENT_ORDERS.PACKAGE_WEIGHT')}: {element?.weight} {element?.weightUnit}
                                </Typography>
                                <Divider/>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
}