import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {mapLTLAdditionalService, mapLTLLogo} from "../../../utils/Helper";
import {Divider, useMediaQuery} from "@mui/material";
import {Fragment} from "react";
import intl from "react-intl-universal";

const style = {
    LTLOrderDashboardSummary: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const LTLOrderDashboardSummary = ({orderData, specialServices}) => {

    console.log('order data', orderData);
    const isMobile = useMediaQuery('(max-width: 480px)');

    return (
        <Box sx={style.LTLOrderDashboardSummary}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px 20px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '16px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.LTL_SUMMARY')} <span
                            style={{color: '#1D8B45'}}> {intl.get('ORDER_DASHBOARD.ORDER_NO')}: {orderData?.order_number}</span>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={4}>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    <Typography><span
                                        style={{fontWeight: '600'}}>{intl.get('ORDER_DASHBOARD.PROCEED_DATE')}: </span>
                                    </Typography>
                                    <Typography>
                                        {dayjs(orderData?.created_at)?.format('YYYY-MM-DD')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    {/*<Typography><span*/}
                                    {/*    style={{fontWeight: '600'}}>Carrier: </span>*/}
                                    {/*</Typography>*/}
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}>
                                        <img src={mapLTLLogo(orderData?.account_base_combine_name?.toLowerCase())}
                                             alt="logo"
                                             width='40px'/>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.SHIPPING_FROM')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {orderData?.pickup_company}
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {orderData?.pickup_address}, {orderData?.pickup_area}, {orderData?.pickup_postal_code}, {orderData?.send_from_country}
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {orderData?.pickup_phone}
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {orderData?.pickup_email}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.SHIPPING_TO')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.deliver_company}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.deliver_address_line2} {orderData?.deliver_address}, {orderData?.deliver_area}, {orderData?.deliver_postal_code}, {orderData?.send_to_country}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.deliver_phone}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.deliver_email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.PALLET_DETAILS')}
                                        </Typography>
                                    </Grid>
                                    {!isMobile &&
                                        <>
                                            <Grid item xs={4}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {intl.get('ORDER_DASHBOARD.NAME')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {intl.get('ORDER_DASHBOARD.DIMENSION')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    {intl.get('ORDER_DASHBOARD.WEIGHT')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                                    {intl.get('ORDER_DASHBOARD.QUANTITY')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        orderData?.packages?.map((element, index) => (
                                            <Fragment key={index}>
                                                <Grid item md={4} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.NAME')}: `}{element?.item_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.DIMENSION')}: `}{element?.length}x{element?.width}x{element?.height} {element?.dimension_unit?.toLowerCase()}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.WEIGHT')}: `}{element?.weight} {element?.weight_unit?.toLowerCase()}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                                        {isMobile && `${intl.get('ORDER_DASHBOARD.QUANTITY')}: `}{element?.number}
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                            {
                                specialServices?.length > 0 &&
                                <Grid item md={6} xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                {intl.get('ORDER_DASHBOARD.SPECIAL_SERVICE')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{display: 'flex', gap: '20px', flexWrap: 'wrap'}}>
                                                {
                                                    specialServices?.map((element, index) => (
                                                        <Typography key={index} style={{fontSize: '14px'}}>
                                                            {mapLTLAdditionalService(element.serviceCode)}
                                                        </Typography>
                                                    ))
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (orderData?.packages?.length > 0 && orderData?.packages[0]?.battery_material) &&
                                <Grid item md={6} xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                {intl.get('ORDER_DASHBOARD.BATTERY')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {intl.get('ORDER_DASHBOARD.BATTERY_MATERIAL')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderData?.packages[0]?.battery_material === 'LITHIUM_METAL' ? 'Lithium Metal' : 'Lithium Ion'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {intl.get('ORDER_DASHBOARD.BATTERY_PACKING')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {orderData?.packages[0]?.battery_packing === 'CONTAINED_IN_EQUIPMENT' ? 'Contained in equipment' : 'Packed with equipment'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.ESTIMATE_SHIPPING_FEE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                            {intl.get('ORDER_DASHBOARD.SHIPPING_FEE')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                            $ {orderData?.total?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                            {intl.get('ORDER_DASHBOARD.TAX')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                            $ {orderData?.taxes?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                    {orderData?.total_brokerage_charge > 0 &&
                                        <>
                                            <Grid item xs={6}>
                                                <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                                    {intl.get('ORDER_DASHBOARD.BROKERAGE_FEE')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                                    $ {orderData?.total_brokerage_charge?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right', fontWeight: '600'}}>
                                            {intl.get('ORDER_DASHBOARD.Total')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{fontSize: '14px', textAlign: 'right'}}>
                                            $ {orderData?.final_total?.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            height: '100%',
                                            gap: '5px'
                                        }}>
                                            <Typography><span
                                                style={{fontWeight: '600'}}>{intl.get('ORDER_DASHBOARD.PICKUP_DATE')}: </span>
                                            </Typography>
                                            <Typography>
                                                {dayjs(orderData?.scheduled_pickup_date)?.format('YYYY-MM-DD')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}