import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Checkbox,
    Container,
    Divider, FormControlLabel,
    MenuItem,
    Select,
    Snackbar
} from "@mui/material";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Button, useMediaQuery} from "@material-ui/core";
import {useEffect, useState} from "react";
import {ShippingPackageCreateLabelPackageDetails} from "./ShippingPackageCreateLabelPackageDetails";
import {useDispatch, useSelector} from "react-redux";
import {ShippingPackageCreateLabelShipFrom} from "./ShippingPackageCreateLabelShipFrom";
import {ShippingPackageCreateLabelShipTo} from "./ShippingPackageCreateLabelShipTo";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {internationalShipmentTypeList, transportationListToCodeMap} from "../../shared/constInfo";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {getCountryCode, getMissingKeysForCreateLabelLocation, isValidatePhone} from "../../utils/Helper";
import {
    clearCallAllPackageCarrier,
    clearShippingPackageRateCreateLabel,
    isCallAllPackageCarrier,
    updateCreateLabelValidation,
    updateCrossBorderCreateLabelPackage,
    updateReferenceOrderNumberCreateLabelPackage,
    updateShipFromPackageCreateLabelPackage,
    updateShippingPackageRateCreateLabel, updateShippingPackageReturnLabelStatus, updateShipToPackageCreateLabelPackage
} from "../Actions/shippingPackageAction";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {deleteToken, getAccessToken} from "../../utils/doToken";
import getProvinceCodeByName, {getCountryCodeByName} from "../../utils/getProvinceCode";
import {useHistory} from "react-router-dom";
import TextField from "@mui/material/TextField";
import intl from "react-intl-universal";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {ShippingPackageCreateLabelShippingOptions} from "./ShippingPackageCreateLabelShippingOptions";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import packageIcon from "../../images/icons/packageIcon.png";
import PublicIcon from "@mui/icons-material/Public";
import ltlIcon from "../../images/icons/ltlIcon.png";
import brokerageIcon from "../../images/icons/brokerageIcon.png";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },
    ShippingPackageCreateLabelRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelRowRight: {
        display: 'flex',
        justifyContent: 'flex-end'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    border: '1px solid #609966',
    borderRadius: '25px',
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        width: '100%',
        backgroundColor: 'transparent',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        color: '#000000',
        // border: '1px solid #609966',
        // borderRadius: '25px',
        '&.Mui-selected': {
            color: '#ffffff',
            backgroundColor: '#609966',
            borderRadius: '25px'
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

const requiredProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight"];
const crossRequiredProperties = ["UOM", "currency", "priceEach", "hscode", "originCountryCode"];

export const ShippingPackageCreateLabel = (props) => {

    const shipFromHistory = props?.location?.state?.shipFrom;
    const shipToHistory = props?.location?.state?.shipTo;
    const packageListHistory = props?.location?.state?.packageList;
    const batteryDetailsHistory = props?.location?.state?.batteryDetails;
    const signatureOptionsHistory = props?.location?.state?.signatureOptions;
    // console.log('ship from history', shipFromHistory);
    // console.log('ship to history', shipToHistory);
    console.log('battery details history', batteryDetailsHistory);

    let packageListRedux = useSelector(state => state?.shippingPackage?.createLabelPackageList);
    let shipFrom = useSelector(state => state?.shippingPackage?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.createLabelShipTo);
    let referenceOrderNumberRedux = useSelector(state => state?.shippingPackage?.createLabelReferenceOrderNumber);
    let returnLabelRedux = useSelector(state => state?.shippingPackage?.returnLabel);
    let batteryDetails = useSelector(state => state?.shippingPackage?.createLabelBatteryDetails);
    let signatureOptions = useSelector(state => state?.shippingPackage?.createLabelSignatureOptions);

    const dispatch = useDispatch();
    const history = useHistory();
    const {id: partnerId, email} = useSelector((state) => state.user);

    const isMobile = useMediaQuery('(max-width: 600px)');
    const [tabValue, setTabValue] = useState('createLabel');
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [crossBorder, setCrossBorder] = useState({
        tranMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
    });
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [rate, setRate] = useState([]);
    const [referenceOrderNumber, setReferenceOrderNumber] = useState('');
    const [isCalledAllCarrier, setIsCalledAllCarrier] = useState(0);
    const [addressVerifyDialog, setAddressVerifyDialog] = useState(false);
    const [addressErrorMessageShipFrom, setAddressErrorMessageShiFrom] = useState('');
    const [addressErrorMessageShipTo, setAddressErrorMessageShipTo] = useState('');
    const [verifyAddressShipFrom, setVerifyAddressShipFrom] = useState(null);
    const [verifyAddressShipTo, setVerifyAddressShipTo] = useState(null);
    const [verifyAddressAPI, setVerifyAddressAPI] = useState(false);
    const [shipFromOriginal, setShipFromOriginal] = useState();
    const [shipToOriginal, setShipToOriginal] = useState();
    const [returnLabel, setReturnLabel] = useState(false);

    const TransportationModeList = Object.entries(transportationListToCodeMap).map(([key, value]) => [key, value]);

    const handleAddressVerifyDialogOpen = () => {
        setAddressVerifyDialog(true);
    }

    const handleAddressVerifyDialogClose = () => {
        setAddressVerifyDialog(false);
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    // const toggleOpen = () => {
    //     setAccordionOpen(!accordionOpen);
    // }

    const handleTranMode = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            tranMode: event.target.value
        }))
    }

    const handleShipmentType = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            shipmentType: event.target.value
        }))
    }

    const handleReferenceOrderNumber = (event) => {
        setReferenceOrderNumber(event?.target?.value);
        dispatch(updateReferenceOrderNumberCreateLabelPackage(event?.target?.value));
    }

    const handleReturnLabel = event => {
        setReturnLabel(event?.target?.checked);
        dispatch(updateShippingPackageReturnLabelStatus(event?.target?.checked));
    }

    const CrossBorderHasMissingValues = () => {

        if (shipFrom?.country === shipTo?.country) {
            return false
        } else {
            const result = packageListRedux.some(object => crossRequiredProperties.some((property) => !object[property]));
            console.log('result', result);
            return result
        }
    }

    const token = getAccessToken("access_token");

    const getShippingPackageRate = async (selectedAccountBase) => {

        setLoading(true);

        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/rates/all`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let data = {
            rateData: {
                shipFromAddress: shipFrom?.displayAddress?.text,
                shipFromAddressTwo: shipFrom?.address2,
                shipFromCity: shipFrom?.city,
                shipFromProvince: shipFrom?.province?.code,
                shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
                shipFromCountry: shipFrom?.country,
                shipFromName: shipFrom?.name,
                shipFromPhone: shipFrom?.phone,
                shipToAddress: shipTo?.displayAddress?.text,
                shipToAddressTwo: shipTo?.address2,
                shipToCity: shipTo?.city,
                shipToPostalCode: shipTo?.postalCode.replace(/\s+/g, '').toUpperCase(),
                shipToProvince: shipTo?.province?.code,
                shipToCountry: shipTo?.country,
                shipToName: shipTo?.name,
                shipToPhone: shipTo?.phone,
                shipmentSpecialServices: {
                    returnShipment: returnLabel
                },
                packageData: packageData,
            },
            selectedAccountBase: selectedAccountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });

            const rateData = result?.data?.result;
            console.log('rate data', rateData);

            const updatedRateData = rateData.map((each) => ({
                ...each,
                errorAccountBase: selectedAccountBase?.toUpperCase()
            }));

            // setRate(prevState => [...prevState, ...rateData]);
            setLoading(false);
            // setIsGetRate(true);
            // setRate(prevState => [...prevState, ...rateData]);
            dispatch(updateShippingPackageRateCreateLabel(updatedRateData));

            history.push('/shipping-package/create-label/rate-list');

            setErrorMessage('');
        } catch (e) {
            setLoading(false);
            console.log('error', e.response);
            if (e.message === 'Timeout') {
                console.error('API request timed out');
                // Handle timeout error here
            } else if (e.response.status === 403) {
                setErrorMessage(`${e.response.data.error}. Please contact op@uucargo.ca.`);
            } else if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            }
            handleErrorMessage();
        } finally {
            dispatch(isCallAllPackageCarrier(1));
        }
    }

    const getShippingPackageRateRecommend = async (selectedAccountBase) => {
        setLoading(true);

        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/rates/all`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let data = {
            rateData: {
                shipFromAddress: verifyAddressShipFrom?.address || shipFrom?.displayAddress?.text,
                shipFromAddressTwo: verifyAddressShipFrom?.address2 || shipFrom?.address2,
                shipFromCity: verifyAddressShipFrom?.city || shipFrom?.city,
                shipFromProvince: verifyAddressShipFrom?.province || shipFrom?.province?.code,
                shipFromPostalCode: verifyAddressShipFrom?.postalCode.replace(/\s+/g, '').toUpperCase() || shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
                shipFromCountry: verifyAddressShipFrom?.country || shipFrom?.country,
                shipFromName: shipFrom?.name,
                shipFromPhone: shipFrom?.phone,
                shipToAddress: verifyAddressShipTo?.address || shipTo?.displayAddress?.text,
                shipToAddressTwo: verifyAddressShipTo?.address2 || shipTo?.address2,
                shipToCity: verifyAddressShipTo?.city || shipTo?.city,
                shipToPostalCode: verifyAddressShipTo?.postalCode.replace(/\s+/g, '').toUpperCase() || shipTo?.postalCode?.replace(/\s+/g, '').toUpperCase(),
                shipToProvince: verifyAddressShipTo?.province || shipTo?.province?.code,
                shipToCountry: verifyAddressShipTo?.country || shipTo?.country,
                shipToName: shipTo?.name,
                shipToPhone: shipTo?.phone,
                shipmentSpecialServices: {
                    returnShipment: returnLabel
                },
                packageData: packageData,
            },
            selectedAccountBase: selectedAccountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });
            const rateData = result?.data?.result;
            console.log('rate data', rateData);

            const updatedRateData = rateData.map((each) => ({
                ...each,
                errorAccountBase: selectedAccountBase?.toUpperCase()
            }));

            // setRate(prevState => [...prevState, ...rateData]);
            setLoading(false);
            // setIsGetRate(true);
            // setRate(prevState => [...prevState, ...rateData]);
            dispatch(updateShippingPackageRateCreateLabel(updatedRateData));

            history.push('/shipping-package/create-label/rate-list');

            setErrorMessage('');
        } catch (e) {
            setLoading(false);
            console.log('error', e.response);
            if (e.response.status === 403) {
                setErrorMessage(`${e.response.data.error}. Please contact op@uucargo.ca.`);
            } else if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            }
            handleErrorMessage();
        } finally {
            dispatch(isCallAllPackageCarrier(1));
        }
    }

    const addAddressShipFrom = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/sender`;
        let data = {
            partnerId: partnerId.toString(),
            address: shipFrom?.displayAddress?.text,
            address_2: shipFrom?.address2,
            city: shipFrom?.city,
            email: shipFrom?.email,
            province: shipFrom?.province?.code,
            postalCode: shipFrom?.postalCode,
            country: shipFrom?.country,
            phone: shipFrom?.phone,
            name: shipFrom?.name,
            addressEmail: shipFrom?.email,
            companyName: shipFrom?.company
        }

        try {
            const result = await axios({
                method: 'put',
                url: requestURL,
                data: data
            });
            console.log(result);
            console.log('successfully add ship from address')
        } catch (e) {
            console.log(e);
        }
    }

    const addAddressShipTo = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/deliver`;
        let data = {
            partnerId: partnerId.toString(),
            address: shipTo?.displayAddress?.text,
            address_2: shipTo?.address2,
            city: shipTo?.city,
            email: shipTo?.email,
            province: shipTo?.province?.code,
            postalCode: shipTo?.postalCode,
            country: shipTo?.country,
            phone: shipTo?.phone,
            name: shipTo?.name,
            addressEmail: shipTo?.email,
            companyName: shipTo?.company
        }

        try {
            const result = await axios({
                method: 'put',
                url: requestURL,
                data: data
            });
            console.log(result);
            console.log('successfully add ship to address')
        } catch (e) {
            console.log(e);
        }
    }

    const verifyAddress = async (address, address2, city, province, country, postalCode) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/verifyAddress/forWeb`;
        let data = {
            addressLines: [address, address2],
            city: city,
            province: province,
            postalCode: postalCode,
            country: country
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            console.log('result verify address', result?.data);
            setVerifyAddressAPI(false);
            return result?.data
        } catch (e) {
            console.log('error', e?.response);
            setVerifyAddressAPI(true);
            return false
        } finally {
            setLoading(false);
        }
    }

    const hasOverweightObject = packageListRedux.some(obj => {
        if (obj.weightUnit === 'kg') {
            const weightInLbs = obj.weight * 2.20462; // Convert kg to lbs
            return weightInLbs > 150;
        } else {
            return obj.weight > 150;
        }
    });

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const shipFromMissing = getMissingKeysForCreateLabelLocation({
            name: shipFrom?.name,
            phone: shipFrom?.phone,
            address: shipFrom?.address,
            city: shipFrom?.city,
            province: shipFrom?.province?.code,
            country: shipFrom?.country,
            postalCode: shipFrom?.postalCode
        });
        const shipToMissing = getMissingKeysForCreateLabelLocation({
            name: shipTo?.name,
            phone: shipTo?.phone,
            address: shipTo?.address,
            city: shipTo?.city,
            province: shipTo?.province?.code,
            country: shipTo?.country,
            postalCode: shipTo?.postalCode
        });
        const hasMissingValues = packageListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = packageListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));
        // const hasMissingValuesCrossBorder = CrossBorderHasMissingValues();
        const shipFromPhoneValidate = isValidatePhone(shipFrom?.phone);
        const shipToPhoneValidate = isValidatePhone(shipTo?.phone);
        const hasOverweightObject = packageListRedux.some(obj => {
            if (obj.weightUnit === 'kg') {
                const weightInLbs = obj.weight * 2.20462; // Convert kg to lbs
                return weightInLbs > 150;
            } else {
                return obj.weight > 150;
            }
        });
        const hasOverlengthItemName = packageListRedux?.some(obj => {
            return obj?.description?.length > 30;
        })

        const validation = {
            shipFromMissing,
            shipToMissing,
            shipFromPhone: shipFromPhoneValidate,
            shipToPhone: shipToPhoneValidate
        }
        console.log('validation', validation);
        dispatch(updateCreateLabelValidation(validation));

        packageListRedux?.length === 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_EMPTY'));
        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_MISSING'));
        shipToMissing?.length !== 0 && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_TO_MISSING'));
        !shipFromPhoneValidate && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_FROM_PHONE'));
        !shipToPhoneValidate && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.SHIP_TO_PHONE'));
        hasMissingValues && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_MISSING'));
        hasValidateValues && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.PACKAGE_LIST_ERROR'));
        // hasMissingValuesCrossBorder && setErrorMessage(prevState => prevState + ' Your package list has missing one or more required fields in cross border section.');
        hasOverweightObject && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.OVER_WEIGHT'));
        hasOverlengthItemName && setErrorMessage(prevState => prevState + intl.get('ADD_PACKAGE.VALIDATION.OVER_CHARACTER'))

        if (shipFromMissing.length === 0 &&
            shipToMissing.length === 0 &&
            packageListRedux.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            // !hasMissingValuesCrossBorder &&
            shipFromPhoneValidate &&
            shipToPhoneValidate &&
            !hasOverweightObject &&
            !hasOverlengthItemName
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleAddressVerification = async () => {
        const verifyAddressResultShiFrom = await verifyAddress(shipFrom?.address, shipFrom?.address2, shipFrom?.city, shipFrom?.province?.code, shipFrom?.country, shipFrom?.postalCode);
        const verifyAddressResultShiTo = await verifyAddress(shipTo?.address, shipTo?.address2, shipTo?.city, shipTo?.province?.code, shipTo?.country, shipTo?.postalCode);
        let result = true;
        // console.log('verifyAddressResultShiFrom', verifyAddressResultShiFrom);
        if (verifyAddressResultShiFrom) {
            if (verifyAddressResultShiFrom?.customerMessages?.length > 0) {
                console.log('error length')
                setAddressVerifyDialog(true);
                setAddressErrorMessageShiFrom('Your ship from address is invalid.');
                setVerifyAddressShipFrom(null);
                result = false;
            } else if (
                (!verifyAddressResultShiFrom?.correction?.addressMatch ||
                    !verifyAddressResultShiFrom?.correction?.cityMatch ||
                    !verifyAddressResultShiFrom?.correction?.provinceMatch ||
                    !verifyAddressResultShiFrom?.correction?.countryMatch ||
                    !verifyAddressResultShiFrom?.correction?.postalCodeMatch) &&
                verifyAddressResultShiFrom?.missingKeys?.length === 0
            ) {
                console.log('match')
                setAddressVerifyDialog(true);
                setVerifyAddressShipFrom(prevState => ({
                    ...prevState,
                    address: verifyAddressResultShiFrom?.streetLinesToken[0],
                    address2: verifyAddressResultShiFrom?.streetLinesToken[1],
                    city: verifyAddressResultShiFrom?.city,
                    province: verifyAddressResultShiFrom?.stateOrProvinceCode,
                    country: verifyAddressResultShiFrom?.countryCode,
                    postalCode: verifyAddressResultShiFrom?.postalCode
                }));
                setAddressErrorMessageShiFrom('');
                result = false;
            } else {
                setAddressErrorMessageShiFrom('');
                setVerifyAddressShipFrom(null);
            }
        }

        if (verifyAddressResultShiTo) {
            if (verifyAddressResultShiTo?.customerMessages?.length > 0) {
                setAddressVerifyDialog(true);
                setAddressErrorMessageShipTo('Your ship to address is invalid.');
                setVerifyAddressShipTo(null);
                result = false;
            } else if (
                (!verifyAddressResultShiTo?.correction?.addressMatch ||
                    !verifyAddressResultShiTo?.correction?.cityMatch ||
                    !verifyAddressResultShiTo?.correction?.provinceMatch ||
                    !verifyAddressResultShiTo?.correction?.countryMatch ||
                    !verifyAddressResultShiTo?.correction?.postalCodeMatch) &&
                verifyAddressResultShiTo?.missingKeys?.length === 0
            ) {
                setAddressVerifyDialog(true);
                setVerifyAddressShipTo(prevState => ({
                    ...prevState,
                    address: verifyAddressResultShiTo?.streetLinesToken[0],
                    address2: verifyAddressResultShiTo?.streetLinesToken[1],
                    city: verifyAddressResultShiTo?.city,
                    province: verifyAddressResultShiTo?.stateOrProvinceCode,
                    country: verifyAddressResultShiTo?.countryCode,
                    postalCode: verifyAddressResultShiTo?.postalCode
                }));
                setAddressErrorMessageShipTo('');
                result = false;
            } else {
                setAddressErrorMessageShipTo('');
                setVerifyAddressShipTo(null);
            }
        }

        if (result) {
            console.log('call api');
            getRating();
        }
    }

    const getRating = () => {
        dispatch(clearShippingPackageRateCreateLabel());
        dispatch(clearCallAllPackageCarrier());
        try {
            // if (shipFrom?.country === shipTo?.country) {
            //     console.log('same')
            getShippingPackageRate('canpar');
            getShippingPackageRate('ups');
            getShippingPackageRate('fedex');
            getShippingPackageRate('canadapost');
            // } else {
            //     console.log('diff')
            //     getShippingPackageRate('ups');
            // }
        } catch (e) {
            console.log('error ', e.response);
        } finally {
            // console.log('save address');
            // console.log('ship from', shipFrom);
            if (shipFrom.saveAddress) {
                addAddressShipFrom();
            }
            if (shipTo.saveAddress) {
                addAddressShipTo();
            }
        }
    }

    const handleGetRate = () => {
        const validationResult = validate();
        if (validationResult) {
            setShipFromOriginal(shipFrom);
            setShipToOriginal(shipTo)
            handleAddressVerification();
        }
    }

    const handleRecommendAddress = () => {
        dispatch(clearShippingPackageRateCreateLabel());
        dispatch(clearCallAllPackageCarrier());
        dispatch(updateShipFromPackageCreateLabelPackage({
            postalCode: verifyAddressShipFrom?.postalCode || shipFrom?.postalCode,
            province: {
                code: verifyAddressShipFrom?.province || shipFrom?.province?.code,
                name: verifyAddressShipFrom?.province || shipFrom?.province?.code,
            },
            city: verifyAddressShipFrom?.city || shipFrom?.city,
            address: verifyAddressShipFrom?.address || shipFrom?.address,
            address2: verifyAddressShipFrom?.address2 || shipFrom?.address2,
            country: verifyAddressShipFrom?.country || shipFrom?.country,
            displayAddress: {
                description: null,
                placeId: null,
                text: verifyAddressShipFrom?.address || shipFrom?.address
            },
            name: shipFrom?.name,
            phone: shipFrom?.phone,
            email: shipFrom?.email,
            company: shipFrom?.company,
            saveAddress: shipFrom?.saveAddress,
            addressList: {
                name: shipFrom?.name,
                phone: shipFrom?.phone,
                email: null,
                address_email: shipFrom?.email,
                company_name: shipFrom?.company,
                city: verifyAddressShipFrom?.city || shipFrom?.city,
                province: verifyAddressShipFrom?.province || shipFrom?.province?.code,
                country: verifyAddressShipFrom?.country || shipFrom?.country
            }
        }));
        dispatch(updateShipToPackageCreateLabelPackage({
            postalCode: verifyAddressShipTo?.postalCode || shipTo?.postalCode,
            province: {
                code: verifyAddressShipTo?.province || shipTo?.province?.code,
                name: verifyAddressShipTo?.province || shipTo?.province?.code
            },
            city: verifyAddressShipTo?.city || shipTo?.city,
            address: verifyAddressShipTo?.address || shipTo?.address,
            address2: verifyAddressShipTo?.address2 || shipTo?.address2,
            country: verifyAddressShipTo?.country || shipTo?.country,
            displayAddress: {
                description: null,
                placeId: null,
                text: verifyAddressShipTo?.address || shipTo?.address
            },
            name: shipTo?.name,
            phone: shipTo?.phone,
            email: shipTo?.email,
            company: shipTo?.company,
            saveAddress: shipTo?.saveAddress,
            addressList: {
                name: shipTo?.name,
                phone: shipTo?.phone,
                email: null,
                address_email: shipTo?.email,
                company_name: shipTo?.company,
                city: verifyAddressShipTo?.city || shipTo?.city,
                province: verifyAddressShipTo?.province || shipTo?.province?.code,
                country: verifyAddressShipTo?.country || shipTo?.country
            }
        }));
        try {
            // if (shipFrom?.country === shipTo?.country) {
            //     console.log('same')
            getShippingPackageRateRecommend('canpar');
            getShippingPackageRateRecommend('ups');
            getShippingPackageRateRecommend('fedex');
            getShippingPackageRateRecommend('canadapost');
            // } else {
            //     console.log('diff')
            //     getShippingPackageRateRecommend('ups');
            // }
        } catch (e) {
            console.log('error ', e.response);
        } finally {
            // console.log('save address');
            // console.log('ship from', shipFrom);
            if (shipFrom.saveAddress) {
                addAddressShipFrom();
            }
            if (shipTo.saveAddress) {
                addAddressShipTo();
            }
        }
    }

    useEffect(() => {
        const shipFromString = JSON.stringify(shipFrom);
        const shipToString = JSON.stringify(shipTo);
        const shipFromOriginalString = JSON.stringify(shipFromOriginal);
        const shipToOriginalString = JSON.stringify(shipToOriginal);
        if (shipFromString !== shipFromOriginalString || shipToString !== shipToOriginalString) {
            setAddressVerifyDialog(false);
        }
    }, [shipFrom, shipTo])

    // useEffect(() => {
    //     const filteredRate = [...rate].filter(element => !element.hasOwnProperty("errorMessage"));
    //
    //     const sortedRate = filteredRate.sort((a, b) => (a?.finalTotalAmount?.value || 0) - (b?.finalTotalAmount?.value || 0));
    //     // console.log('sorted rate', sortedRate);
    //     dispatch(updateShippingPackageRateCreateLabel(sortedRate));
    // }, [rate]);

    // console.log('ship from', shipFrom);
    // console.log('ship to', shipTo);

    useEffect(() => {
        setReferenceOrderNumber(referenceOrderNumberRedux);
        setReturnLabel(returnLabelRedux);
    }, []);

    useEffect(() => {
        dispatch(updateCrossBorderCreateLabelPackage(crossBorder));
    }, [crossBorder])

    useEffect(() => {
        if (tabValue === 'uploadExcel') {
            history.push('/shipping-package/import-shipment')
        } else {
            return;
        }
    }, [tabValue]);

    // useEffect(() => {
    //     dispatch(isCallAllPackageCarrier(isCalledAllCarrier));
    // },[isCalledAllCarrier])

    // console.log('reference order number', referenceOrderNumber);
    // console.log('reference order number redux', referenceOrderNumberRedux);
    // console.log('package', packageListRedux);
    // console.log('verify address ship from', verifyAddressShipFrom);
    // console.log('verify address ship to', verifyAddressShipTo);
    // console.log('battery details', batteryDetails);
    // console.log('return label', returnLabel);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box style={styles.ShippingPackageCreateLabelRoot}>
                <Box sx={styles.ShippingPackageCreateLabelSection}>
                    <Typography style={styles.ShippingPackageCreateLabelHeading}>
                        {intl.get('QUICK_QUOTE_NAV.SELECT_TYPE')}
                    </Typography>
                    <Box sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked
                            />
                            <img src={packageIcon} alt="packageIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.PACKAGE')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/shipping-package/international-shipment')}
                            />
                            <PublicIcon sx={{
                                fontSize: '40px'
                            }}/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.INTERNATION')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/LTL-quick-quote')}
                            />
                            <img src={ltlIcon} alt="ltlIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.LTL')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/brokerage/estimate')}
                            />
                            <img src={brokerageIcon} alt="brokerageIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.BROKERAGE')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{borderBottom: '1px solid #609966', borderRadius: '0 25px'}}>
                    <Box sx={{width: isMobile ? '300px' : '600px'}}>
                        <StyledTabs
                            value={tabValue}
                            onChange={handleChangeTab}
                            variant='fullWidth'
                        >
                            <StyledTab label={intl.get('CREATE_LABEL_NAV.PACKAGE')} value="createLabel"/>
                            <StyledTab label={intl.get('CREATE_LABEL_NAV.EXCEL')} value="uploadExcel"/>
                        </StyledTabs>
                    </Box>
                </Box>
                <ShippingPackageCreateLabelShipFrom shipFromHistory={shipFromHistory}/>
                <ShippingPackageCreateLabelShipTo shipToHistory={shipToHistory}/>
                <ShippingPackageCreateLabelPackageDetails packageListRedux={packageListRedux}
                                                          packageListHistory={packageListHistory}/>
                <ShippingPackageCreateLabelShippingOptions batteryDetailsHistory={batteryDetailsHistory}
                                                           signatureOptionsHistory={signatureOptionsHistory}/>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box sx={styles.ShippingPackageCreateLabelSection}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelHeading}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.REFERENCE')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={styles.ShippingPackageCreateLabelSectionBox}>
                                        <InputLabel>{intl.get('SHIPPING_PACKAGE_CREATE_LABEL.REFERENCE')}</InputLabel>
                                        <TextField
                                            value={referenceOrderNumber}
                                            // fullWidth
                                            variant='outlined'
                                            onInput={handleReferenceOrderNumber}
                                            size='small'
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={styles.ShippingPackageCreateLabelSection}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelHeading}>
                                        Return Label
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={styles.ShippingPackageCreateLabelSectionBox}>
                                        <InputLabel>Return labels are for North American shipments only.</InputLabel>
                                        <FormControlLabel
                                            value="start"
                                            control={<Checkbox
                                                checked={returnLabel}
                                                onChange={handleReturnLabel}
                                                color="success"
                                            />}
                                            label={
                                                <Typography style={{textAlign: 'left'}}>
                                                    Return Label
                                                </Typography>
                                            }
                                            labelPlacement="end"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                {
                    addressVerifyDialog ? <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontWeight: '600',
                                        color: '#1D8B45',
                                    }}>
                                        Verify Your Shipping Address
                                    </Typography>
                                </Grid>
                                {
                                    (addressErrorMessageShipFrom || addressErrorMessageShipTo) ?
                                        <>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    While your address did not fully match with Canada Post AddressComplete,
                                                    it’s possible your shipment can still proceed normally.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    gap: '10px',
                                                }}>
                                                    <LoadingButton
                                                        onClick={getRating}
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        autoFocus
                                                        type="button"
                                                        loading={loading}
                                                    >
                                                        Continue Your Address
                                                    </LoadingButton>
                                                    {/*<LoadingButton*/}
                                                    {/*    onClick={handleAddressVerifyDialogClose}*/}
                                                    {/*    variant='contained'*/}
                                                    {/*    sx={{*/}
                                                    {/*        backgroundColor: '#1D8B45',*/}
                                                    {/*        "&:hover": {*/}
                                                    {/*            backgroundColor: '#1D8B45',*/}
                                                    {/*            filter: 'brightness(0.9)'*/}
                                                    {/*        }*/}
                                                    {/*    }}*/}
                                                    {/*    autoFocus type="button"*/}
                                                    {/*    loading={loading}*/}
                                                    {/*>*/}
                                                    {/*    Edit Your Address*/}
                                                    {/*</LoadingButton>*/}
                                                </Box>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Based on Canada Post AddressComplete, we've detected some discrepancies
                                                    in the address you provided. For accurate shipping rates and timely
                                                    delivery, consider our recommended address.
                                                </Typography>
                                            </Grid>
                                            {
                                                verifyAddressShipFrom &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <Typography style={{
                                                                fontWeight: '600'
                                                            }}>
                                                                Ship From Address
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Box>
                                                            <Typography>
                                                                Original Address
                                                            </Typography>
                                                            <Typography>
                                                                {shipFrom?.address2} {shipFrom?.displayAddress?.text}
                                                            </Typography>
                                                            <Typography>
                                                                {shipFrom?.city} {shipFrom?.province?.code}
                                                            </Typography>
                                                            <Typography>
                                                                {shipFrom?.country} {shipFrom?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Box>
                                                            <Typography>
                                                                Recommend Address
                                                            </Typography>
                                                            <Typography>
                                                                {verifyAddressShipFrom?.address2} {verifyAddressShipFrom?.address}
                                                            </Typography>
                                                            <Typography>
                                                                {verifyAddressShipFrom?.city} {verifyAddressShipFrom?.province}
                                                            </Typography>
                                                            <Typography>
                                                                {verifyAddressShipFrom?.country} {verifyAddressShipFrom?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                verifyAddressShipTo &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <Typography style={{
                                                                fontWeight: '600'
                                                            }}>
                                                                Ship To Address
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Box>
                                                            <Typography>
                                                                Original Address
                                                            </Typography>
                                                            <Typography>
                                                                {shipTo?.address2} {shipTo?.displayAddress?.text}
                                                            </Typography>
                                                            <Typography>
                                                                {shipTo?.city} {shipTo?.province?.code}
                                                            </Typography>
                                                            <Typography>
                                                                {shipTo?.country} {shipTo?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Box>
                                                            <Typography>
                                                                Recommend Address
                                                            </Typography>
                                                            <Typography>
                                                                {verifyAddressShipTo?.address2} {verifyAddressShipTo?.address}
                                                            </Typography>
                                                            <Typography>
                                                                {verifyAddressShipTo?.city} {verifyAddressShipTo?.province}
                                                            </Typography>
                                                            <Typography>
                                                                {verifyAddressShipTo?.country} {verifyAddressShipTo?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    gap: '10px'
                                                }}>
                                                    <LoadingButton
                                                        onClick={getRating}
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#454545',
                                                            "&:hover": {
                                                                backgroundColor: '#454545',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        autoFocus
                                                        type="button"
                                                        loading={loading}
                                                    >
                                                        Continue Your Address
                                                    </LoadingButton>
                                                    <LoadingButton
                                                        onClick={handleRecommendAddress}
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        autoFocus type="button"
                                                        loading={loading}
                                                    >
                                                        Use Recommend Address
                                                    </LoadingButton>
                                                </Box>
                                            </Grid>
                                        </>

                                }
                            </Grid>
                        </>
                        :
                        <Box sx={styles.ShippingPackageCreateLabelRowRight}>
                            <LoadingButton
                                variant='contained'
                                color='success'
                                size='large'
                                loading={loading}
                                onClick={handleGetRate}
                            >
                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.GET_RATE')}
                            </LoadingButton>
                        </Box>
                }
            </Box>
        </Container>
    )
}