import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import trackingDelivered from "../../images/tracking_delivered.png";
import trackingInTransit from "../../images/tracking_in_transit.png";
import trackingReady from "../../images/tracking_ready.png";
import dayjs from "dayjs";

const style = {
    TrackingLTLEachRoot: {
        padding: '0 20px',
    }
}

export const TrackingLTLEach = ({trackingData}) => {

    return (
        <Box sx={style.TrackingLTLEachRoot}>
            <Grid container spacing={2}>
                {trackingData?.trackingResponse?.activity?.map((element, index) => (
                    <Grid item xs={12} key={index}>
                        <Box sx={{
                            padding: '0 10px',
                        }}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        {dayjs(element?.date).format("YYYY-MM-DD HH:mm")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left', fontWeight: '600'}}>
                                        {element?.description}
                                    </Typography>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        {element?.address?.address}
                                    </Typography>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        {element?.address?.city}, {element?.address?.province}, {element?.address?.country}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}