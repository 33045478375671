import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {MenuItem, Select} from "@mui/material";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {getCountryCode} from "../../utils/getCountryCode";
import {UMOList, UOMList} from "../../shared/constInfo";
import axios from "axios";
import {HSCODE_SEARCH} from "../../utils/apiUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

export const BrokerageItemEdit = ({count, getDataFromItem, boxInformation}) => {

    const [currency, setCurrency] = useState('CAD')

    const [lengthUnit, setLengthUnit] = useState('in')

    const [weightUnit, setWeightUnit] = useState('lb')

    const [originCountry, setOriginCountry] = useState('CN')

    const [UOM, setUOM] = useState('BOX')

    const {register, handleSubmit, errors, reset, formState} = useForm();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [HSCodeList, setHSCodeList] = useState([])

    const [edit, setEdit] = useState(true)

    const [description, setDescription] = useState('');

    const [quantity, setQuantity] = useState('');

    const [priceEach, setPriceEach] = useState('');

    const [length, setLength] = useState('');

    const [width, setWidth] = useState('');

    const [height, setHeight] = useState('');

    const [weight, setWeight] = useState('');

    const [HScode, setHScode] = useState('');

    const [toastOpen, setToastOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const onSubmit = () => {

        let newData = {
            description: description,
            quantity: quantity,
            priceEach: priceEach,
            length: (length !== undefined && length !== "") ? length : "0",
            width: (width !== undefined && width !== "") ? width : "0",
            height: (height !== undefined && height !== "") ? height : "0",
            weight: weight,
            currency: currency,
            lengthUnit: lengthUnit !== undefined ? lengthUnit : 'in',
            weightUnit: weightUnit !== undefined ? weightUnit : 'lb',
            originCountryCode: originCountry,
            UOM: UOM,
            hscode: HScode
        }

        console.log('new data',newData)

        const allKeysHaveValue = Object.values(newData).every(value => value);
        if (allKeysHaveValue) {
            getDataFromItem(newData, count);
            resetValue();
        } else {
            setErrorMessage("Failed to add item. You are missing some fields. All required fields are required to be filled in.");
            setToastOpen(true);
        }
    }

    const resetValue = () => {
        setDescription('');
        setQuantity('');
        setPriceEach('');
        setLength('');
        setWidth('');
        setWeight('');
        setHeight('');
        setHScode('');
    }

    const searchHSCode = async (value) => {
        try {
            const {data} = await axios.get(`${HSCODE_SEARCH}?query_string=${value}`);
            // console.log("searchLevelSixSection", data);

            const hits = data.hits;
            const levelSixRecords = hits.filter(hit => {
                return hit["_source"].level === "6";
            })
            const filterResult = levelSixRecords?.map(record => {
                return record["_source"]
            })
            setHSCodeList(filterResult)
            // console.log(filterResult);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        setDescription(boxInformation?.item_name);
        setPriceEach(boxInformation?.unit_price);
        setLength(boxInformation?.length);
        setWidth(boxInformation?.width);
        setWeight(boxInformation?.weight);
        setHeight(boxInformation?.height);
        setCurrency(boxInformation.currency_code);
        setOriginCountry(boxInformation?.origi_country);
        setLengthUnit(boxInformation.dimension_unit);
        setWeightUnit(boxInformation?.weight_unit);
        setUOM(boxInformation?.uom);
        setHScode(boxInformation?.hs_code)
    }, [boxInformation])

    console.log(boxInformation)

    return (
        <Box sx={{backgroundColor: '#ffffff', padding: '10px', borderRadius: '10px'}}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                <TextField
                    value={description}
                    sx={{margin: '10px'}}
                    label="Description"
                    required
                    fullWidth
                    variant="standard"
                    onInput={e => setDescription(e.target.value)}
                />
                <TextField
                    value={quantity}
                    sx={{margin: '10px', minWidth: '200px'}}
                    label="Quantity"
                    fullWidth
                    required
                    variant="standard"
                    onInput={e => setQuantity(e.target.value)}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'none',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <TextField
                    value={priceEach}
                    sx={{margin: '10px'}}
                    label="Price"
                    required
                    variant="standard"
                    onInput={e => setPriceEach(e.target.value)}
                    fullWidth
                />
                <FormControl variant="standard" fullWidth sx={{margin: '10px'}}>
                    <InputLabel>Currency*</InputLabel>
                    <Select
                        value={currency}
                        label="Currency"
                        onChange={e => setCurrency(e.target.value)}
                    >
                        <MenuItem value={'CAD'}>CAD</MenuItem>
                        <MenuItem value={'USD'}>USD</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                <TextField
                    value={length}
                    sx={{margin: '10px'}}
                    label="Length"
                    fullWidth
                    variant="standard"
                    onInput={e => setLength(e.target.value)}
                />
                <TextField
                    value={width}
                    sx={{margin: '10px'}}
                    label="Width"
                    variant="standard"
                    fullWidth
                    onInput={e => setWidth(e.target.value)}
                />
                <TextField
                    value={height}
                    sx={{margin: '10px', minWidth: '100px'}}
                    label="Height"
                    fullWidth
                    variant="standard"
                    onInput={e => setHeight(e.target.value)}
                />
                <FormControl variant="standard" sx={{margin: '10px'}} fullWidth>
                    <InputLabel>Length Unit*</InputLabel>
                    <Select
                        value={lengthUnit ? lengthUnit : 'in'}
                        label="length unit"
                        onChange={e => setLengthUnit(e.target.value)}
                    >
                        <MenuItem value={'cm'}>cm</MenuItem>
                        <MenuItem value={'in'}>in</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                <TextField
                    value={weight}
                    sx={{margin: '10px'}}
                    label="Weight"
                    fullWidth
                    variant="standard"
                    required
                    onInput={e => setWeight(e.target.value)}
                />
                <FormControl variant="standard" sx={{margin: '10px'}} fullWidth>
                    <InputLabel>Weight Unit*</InputLabel>
                    <Select
                        value={weightUnit ? weightUnit : 'lb'}
                        label="weight unit"
                        onChange={e => setWeightUnit(e.target.value)}
                    >
                        <MenuItem value={'lb'}>lb</MenuItem>
                        <MenuItem value={'kg'}>kg</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                <FormControl sx={{margin: '10px'}} fullWidth variant="standard">
                    <InputLabel>Origin Country*</InputLabel>
                    <Select
                        value={originCountry}
                        label="origin country"
                        onChange={e => setOriginCountry(e.target.value)}
                    >
                        {
                            getCountryCode().map(v => {
                                return <MenuItem value={v.code}>{v.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl sx={{margin: '10px'}} fullWidth variant="standard">
                    <InputLabel>Unit of Measure*</InputLabel>
                    <Select
                        value={UOM}
                        label="unit of measure"
                        onChange={e => setUOM(e.target.value)}
                    >
                        {UOMList.map(v => {
                            return <MenuItem value={v}>{v}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'none',
                    alignItems: 'center',
                    margin: '0 10px'
                }}>
                <Autocomplete
                    disablePortal
                    options={HSCodeList}
                    fullWidth
                    freeSolo
                    filterOptions={(options, state) => options}
                    getOptionLabel={(option) => `${option.hscode}`}
                    renderOption={(option) => <span><b>{option.hscode}</b> | {option.desc}</span>}
                    onInputChange={async (e, value) => {
                        setHScode(value);
                        searchHSCode(value);

                    }}
                    renderInput={(params) => <TextField
                        multiline
                        id="outlined-basic"
                        label='Serach HSCode'
                        variant="standard"
                        fullWidth
                        {...params}
                    />}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'none',
                    alignItems: 'center',
                }}
            >
                <TextField
                    value={HScode}
                    sx={{margin: '10px'}}
                    label="HS Code"
                    variant="standard"
                    required
                    onInput={e => setHScode(e.target.value)}
                />
            </Box>
            <Box>
                <Button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                >
                    {isSubmitting ? 'Adding...' : 'Add'}
                </Button>
            </Box>
        </Box>
    )
}
