import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar, Select,
} from "@material-ui/core";
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, Controller } from "react-hook-form";
import Upload from '@mui/icons-material/Upload';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Alert } from "@material-ui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {MenuItem} from "@mui/material";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import axios from "axios";
import {PARTNER_URI} from "../../../utils/apiUrl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%"
  },
  unTrackingTable: {
    "border-spacing": "2rem 1rem"
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
    "white-space": "nowrap"
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const currencyList = [
  {
    label: "CAD"
  },
  {
    label: "USD"
  }
]

const AddQuote = ({ companyList }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");


  // const partnerInfo = getPartnerInfo();
  const { id } = useSelector((state) => state.user);
  const [errorMessage, setErrorMessage] = useState("");
  const [amount, setAmount] = useState(0);
  const [amountGross, setAmountGross] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("CAD");
  const [successMessage, setSuccessMessage] = useState("");
  const [expiredDate, setExpiredDate] = useState(dayjs().add(7, "days"));
  const [companyId, setCompanyId] = useState(null);
  const [remarkNotes, setRemarkNotes] = useState("");
  const inputFile = useRef(null);
  const [fileObj, setFileObj] = useState(null);
  const [inputFileName, setInputFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const onDrop = (e) => {
    if (!(e.target.files && e.target.files[0])) {
      return;
    }
    setFileObj(e.target.files[0]);
    setInputFileName(e.target.files[0].name);
  };

  const onSubmit = async (e) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("requestId", "");
      formData.append("companyId", companyId);
      formData.append("expiredDate", expiredDate.toDate());
      formData.append("currencyCode", currencyCode);
      formData.append("totalAmountGross", amountGross);
      formData.append("totalAmount", amount);
      formData.append("remarkNotes", remarkNotes);
      formData.append("quoteFile", fileObj);
      const { data } = await axios.post(`${PARTNER_URI}/partnerPallets/quoteShippingQuoteRequest`, formData, {
        headers: {
          "Content-Type": "multipart/form-data;",
          "X-Requested-With": "XMLHttpRequest",
        }
      })
      // console.log(data.result);
      // setShippingListData(data.result);
      setErrorMessage("");
      setSuccessMessage("Transaction CSV upload success!");
      handleToastClick();
    } catch (e) {

    } finally {
      setIsLoading(false);
    }
  }

  return (
      <Box paddingY="2rem">
        {/*Pop-up Toast*/}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={toastOpen}
            onClose={handleToastClose}
            autoHideDuration={3000}
            message="Submit Transaction">
          {(() => {
            if (errorMessage !== "") {
              return (
                  <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                    Error!
                    <hr />
                    {errorMessage}
                  </Alert>
              )
            }
            return (
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                  {successMessage}
                </Alert>
            )
          })()}

        </Snackbar>
        <Box>
          <Box component="form" width={"1000px"} margin="0 auto" sx={{
            display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
            alignItems: "center", alignContent: "center"
          }}>
            <h2>Create Quote</h2>
            <Box flex="45%" width="100%">
              {/*<TextField id="outlined-basic" label="Company Name" variant="outlined" fullWidth />*/}
              <Autocomplete
                  getOptionLabel={(option) => option.business_name}
                  options={companyList}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label="Company Name"
                          fullWidth
                          variant="outlined"
                      />
                  )}
                  onChange={(e, value) => {
                    console.log("Company:", value);
                    setCompanyId(value.id);
                  }}
              />
            </Box>

            <TextField id="outlined-basic" label="Amount Gross" variant="outlined" fullWidth type="number" error={amountGross > amount}  value={amountGross} onChange={(e) => {
              setAmountGross(e.target.value);
            }}
                       InputProps={{
                         inputProps: {
                           min: 0 }}}
            />
          <TextField id="outlined-basic" label="Amount Net" variant="outlined" value={amount}
                     error={amountGross > amount}
                     onChange={(e) => {
                       setAmount(e.target.value);
                     }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
                     fullWidth type="number" />
            <Box flex="45%" width="100%">
              {/*<Autocomplete*/}
              {/*    getOptionLabel={(option) => option.label}*/}
              {/*    options={currencyList}*/}
              {/*    renderInput={(params) => (*/}
              {/*        <TextField*/}
              {/*            {...params}*/}
              {/*            label="Currency"*/}
              {/*            fullWidth*/}
              {/*            variant="outlined"*/}
              {/*        />*/}
              {/*    )}*/}
              {/*    defaultValue={currencyCode}*/}
              {/*    onChange={(e, value) => {*/}
              {/*      console.log(value);*/}
              {/*      setCurrencyCode(value.label);*/}
              {/*    }}*/}
              {/*/>*/}
              <TextField
                  variant="outlined"
                  fullWidth
                  select
                  value={currencyCode}
                  label="Currency"
                  onChange={(e) => {
                    setCurrencyCode(e.target.value);
                  }}
                  className={classes.textfield}
                  classes={{ root: classes.selectRoot }}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                <MenuItem value="CAD">CAD</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </TextField>
            </Box>
            <Box flex="45%" width="100%" >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Expired Date"
                    value={expiredDate}
                    onChange={setExpiredDate}
                    renderInput={(params) => <TextField {...params} id="outlined-basic" label="Expired Date" variant="outlined" fullWidth />}
                />
              </LocalizationProvider>
            </Box>
            <TextField id="outlined-basic" label="Remark Notes (Optional)" variant="outlined" onChange={(e) => {
              setRemarkNotes(e.target.value);
            }} fullWidth />
            <input type="file" style={{ display: "none" }} ref={inputFile} onChange={onDrop} name="file" />
            <Button
                variant="contained"
                component="span"
                color="primary"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                }}
                onClick={() => {
                  inputFile.current.click();
                }}
                endIcon={<Upload />}
            >
              Upload Original File
            </Button>
            {inputFileName && (
                <Box className={classes.buttonFilesWrapper} mt={3}>
                  <Box display="flex" alignItems="center">
                    <InsertDriveFileIcon />
                    {inputFileName}
                  </Box>
                </Box>
            )}
          </Box>
          <Box display="flex" sx={{ gap: "3rem" }}>
            <LoadingButton
                variant="contained"
                component="span"
                color="primary"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                  width: "7rem",
                }}
                loading={isLoading}
                type="submit"
                onClick={onSubmit}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
  )
}

export default AddQuote;