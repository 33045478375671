import {actionType} from "../../utils/Helper";

const initialState = {
    commercialInvoiceList: [],

}

export const commercialInvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_COMMERCIAL_INVOICE_LIST:
            console.log('add commercial invoice reducers', action.payload);
            return {...state, commercialInvoiceList: [...state.commercialInvoiceList, action?.payload]}
        case actionType.REMOVE_COMMERCIAL_INVOICE_LIST:
            console.log('remove commercial invoice reducers', action.payload);
            const invoiceIdToFind = action?.payload?.invoiceId;
            const filterInvoiceList = state?.commercialInvoiceList?.filter(each => each?.invoiceId !== invoiceIdToFind);
            return {...state, commercialInvoiceList: filterInvoiceList}
        case actionType?.CLEAR_COMMERCIAL_INVOICE_LIST:
            return {...state, commercialInvoiceList: []}
        default:
            return state
    }
}