import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Container from "@material-ui/core/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import {getAccessToken} from "../../utils/doToken";

import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import OrderDashboardStyle from "./OrderDashboardStyle";
import OrderSummaryTab from "./OrderSummaryTab";
import {useLocation, useParams} from "react-router-dom";
import ShippingTransactionCard from "./ShippingTransactionCard";
import CrossBoarderCard from "./CrossBoarderCard";
import EmptyCard from "./EmptyCard";
import AdjustmentDetailsCard from "./AdjustmentDetailsCard";
import {SideBar} from "../SideBar";
import PickupAppointmentDetailsCard from "./PickupAppointmentDetailsCard";

const drawerWidth = 240;

const useQuery = () => {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function OrderDashboard() {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const classes = OrderDashboardStyle();
    const storedToken = getAccessToken("access_token");
    const [isCrossBorder, setIsCrossBorder] = useState(false);

    const query = useQuery();

    const {order_id: orderId} = useParams();

    useEffect(() => {
        // getUnpaidListData();
    }, []);

    const drawer = (
        <div>
            <Toolbar/>
            <Divider/>
            <List>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemText primary={`My Dashboard`}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemText primary={`INVOICE`}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Container maxWidth="xl">
            {/* {Billing Dashboard  Summary Card} */}
            <OrderSummaryTab classes={classes} orderId={orderId} setIsCrossBorder={setIsCrossBorder}/>
            <Grid container spacing={2}>
                <ShippingTransactionCard classes={classes} orderId={orderId}/>
                {
                    isCrossBorder ? <CrossBoarderCard classes={classes} orderId={orderId}/> :
                        <EmptyCard classes={classes} title={"Cross Border Commercial Invoice"}
                                   content={"Only Available For Cross Border Order"}/>
                }
                <PickupAppointmentDetailsCard classes={classes} orderId={orderId}/>
                <AdjustmentDetailsCard classes={classes} orderId={orderId}/>
            </Grid>
        </Container>
    );
}

export default OrderDashboard;
