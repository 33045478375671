import React, {useEffect, useState} from "react";
import {Check, Cancel} from "@material-ui/icons";
import {
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
  Typography,
  Tooltip,
  IconButton,
  Select,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions, CircularProgress, Snackbar
} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import {Accordion, AccordionDetails, AccordionSummary, MenuItem} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import Upload from "@mui/icons-material/Upload";
import LoadingButton from "@mui/lab/LoadingButton";
import getProvinceCode, {getUSProvinceCodeByName} from "../../../utils/getProvinceCode";
import axios from "axios";
import {PARTNER_URI} from "../../../utils/apiUrl";
import {getAccessToken} from "../../../utils/doToken";
import {useSelector} from "react-redux";
import {Alert} from "@material-ui/lab";
import {useHistory} from "react-router-dom";
import {getTransportationCode} from "../../../shared/constInfo";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
  HSCodetextfield: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    // paddingTop: "0.4vw",
    // paddingBottom: "0.4vw",
  },
  accordion: {
    width: "100%"
  },
  accordionSummaryRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  menuPaper: {
    maxWidth: 150,
  },
  input: {
    color: "grey",
    width: "90%",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
  smSubTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Montserrat",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  warningText: {
    color: "red",
    fontWeight: "bold",
  },
}));
const ShippingLTLCustomRequest = ({  pickUpDetails, shippingItems, }) => {
  const classes = useStyles();
  const id = useSelector((state) => state.user.id);
  const [pickupLoading, setPickupLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [isPlaceOrderSuccess, setIsPlaceOrderSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const confirmCreateBOLRequest = async (e) => {
    e.preventDefault();
    const requestBody = {
      partnerId: id,
      pickupAddressType: pickUpDetails.pickUp.service,
      pickupAddress: pickUpDetails.pickUp.address,
      pickupCity: pickUpDetails.pickUp.city,
      pickupProvince: pickUpDetails.pickUp.country === "US" ? getUSProvinceCodeByName(pickUpDetails.pickUp.province) : getProvinceCode(pickUpDetails.pickUp.province),
      pickupCountry: pickUpDetails.pickUp.country,
      pickupPostalCode: pickUpDetails.pickUp.zipCode.replace(/\s*/g, ""),
      pickupPhone: pickUpDetails.pickUp.phone,
      senderName: pickUpDetails.pickUp.name,
      deliverAddress: pickUpDetails.deliver.address,
      deliverCity: pickUpDetails.deliver.city,
      deliverProvince: pickUpDetails.deliver.country === "US" ? getUSProvinceCodeByName(pickUpDetails.deliver.province) : getProvinceCode(pickUpDetails.deliver.province),
      deliverCountry: pickUpDetails.deliver.country,
      deliverPostalCode: pickUpDetails.deliver.zipCode.replace(/\s*/g, ""),
      deliverName: pickUpDetails.deliver.name,
      deliverPhone: pickUpDetails.deliver.phone,
      deliverAddressType: pickUpDetails.deliver.service,
      schedulePickupDate: pickUpDetails.schedulePickupDate ? pickUpDetails.schedulePickupDate?.toDate() : null,
      packageData: shippingItems.map(v => {
        return {
          shipmentTypeId: v.packagingType,
          length: v.length,
          height: v.height,
          width: v.width,
          quantity: v.looseUnit,
          weight: v.weight,
          description: v?.description,
          lengthUnit: v.lengthUnit.toUpperCase(),
          weightUnit: v.weightUnit.toUpperCase()
        }
      })
    }
    console.log(requestBody);
    try {
      setPlaceOrderLoading(true);
      const { data } = await axios.post(`${PARTNER_URI}/partnerPallets/createShippingQuoteRequest`, requestBody, {
        headers: {
          Authorization: `Bearer ${getAccessToken("access_token")}`
        }
      });
      console.log(data);
      setErrorMessage("");
      handleToastClick();
      handleConfirmDialogClose();
      // Go Back to home page after 2 second
      setTimeout(() => {
        history.push("/home");

      }, 2000);
    } catch (e) {
      setErrorMessage(e?.response?.data?.errorMessage || "Error!");
      console.log(e);
    } finally {
      setPlaceOrderLoading(false);
    }
  }

  const confirmPlaceOrder = async (e) => {
    e.preventDefault();
    const requestBody = {
      partnerId: id,
      pickupAddressType: pickUpDetails.pickUp.service,
      pickupAddress: pickUpDetails.pickUp.address,
      pickupCity: pickUpDetails.pickUp.city,
      pickupProvince: pickUpDetails.pickUp.country === "US" ? getUSProvinceCodeByName(pickUpDetails.pickUp.province) : getProvinceCode(pickUpDetails.pickUp.province),
      pickupCountry: pickUpDetails.pickUp.country,
      pickupPostalCode: pickUpDetails.pickUp.zipCode.replace(/\s*/g, ""),
      pickupPhone: pickUpDetails.pickUp.phone,
      senderName: pickUpDetails.pickUp.name,
      deliverAddress: pickUpDetails.deliver.address,
      deliverCity: pickUpDetails.deliver.city,
      deliverProvince: pickUpDetails.deliver.country === "US" ? getUSProvinceCodeByName(pickUpDetails.deliver.province) : getProvinceCode(pickUpDetails.deliver.province),
      deliverCountry: pickUpDetails.deliver.country,
      deliverPostalCode: pickUpDetails.deliver.zipCode.replace(/\s*/g, ""),
      deliverName: pickUpDetails.deliver.name,
      deliverPhone: pickUpDetails.deliver.phone,
      deliverAddressType: pickUpDetails.deliver.service,
      schedulePickupDate: pickUpDetails.schedulePickupDate ? pickUpDetails.schedulePickupDate?.toDate() : null,
      packageData: shippingItems.map(v => {
        return {
          length: v.length,
          height: v.height,
          width: v.width,
          quantity: v.looseUnit,
          weight: v.weight,
          description: v?.description,
          lengthUnit: v.lengthUnit,
          weightUnit: v.weightUnit,
          hscode: v.hscode,
          originCountryCode: v.originCountryCode,
          currency: v.currency,
          priceEach: v.priceEach,
          UOM: v.UOM
        }
      }),
      shipperName: pickUpDetails.pickUp.name,
      shipperPhone: pickUpDetails.pickUp.phone,
      shipperCompanyName: pickUpDetails.pickUp?.companyName || "",
      shipperEmail: pickUpDetails.pickUp?.email || "",
      shipperAddress: pickUpDetails.pickUp.address,
      shipperAddressType: pickUpDetails.pickUp.service,
      shipperCity: pickUpDetails.pickUp.city,
      shipperProvince: pickUpDetails.pickUp.country === "US" ? getUSProvinceCodeByName(pickUpDetails.pickUp.province) : getProvinceCode(pickUpDetails.pickUp.province),
      shipperPostalCode: pickUpDetails.pickUp.zipCode.replace(/\s*/g, ""),
      shipperCountry: pickUpDetails.pickUp.country,

      consigneeName: pickUpDetails.deliver.name,
      consigneePhone: pickUpDetails.deliver.phone,
      consigneeCompanyName: pickUpDetails.deliver?.companyName || "",
      consigneeEmail: pickUpDetails.deliver?.email || "",
      consigneeAddress: pickUpDetails.deliver.address,
      consigneeAddressType: pickUpDetails.deliver.service,
      consigneeCity: pickUpDetails.deliver.city,
      consigneeProvince: pickUpDetails.deliver.country === "US" ? getUSProvinceCodeByName(pickUpDetails.deliver.province) : getProvinceCode(pickUpDetails.deliver.province),
      consigneePostalCode: pickUpDetails.deliver.zipCode.replace(/\s*/g, ""),
      consigneeCountry: pickUpDetails.deliver.country,

      shipToName: pickUpDetails.shipTo.name,
      shipToPhone: pickUpDetails.shipTo.phone,
      shipToCompanyName: pickUpDetails.shipTo?.companyName || "",
      shipToEmail: pickUpDetails.shipTo?.email || "",
      shipToAddress: pickUpDetails.shipTo.address,
      shipToAddressType: pickUpDetails.shipTo.service,
      shipToCity: pickUpDetails.shipTo.city,
      shipToProvince: pickUpDetails.shipTo.country === "US" ? getUSProvinceCodeByName(pickUpDetails.shipTo.province) : getProvinceCode(pickUpDetails.shipTo.province),
      shipToPostalCode: pickUpDetails.shipTo.zipCode.replace(/\s*/g, ""),
      shipToCountry: pickUpDetails.shipTo.country,

      soldToName: pickUpDetails.soldTo.name,
      soldToPhone: pickUpDetails.soldTo.phone,
      soldToCompanyName: pickUpDetails.soldTo?.companyName || "",
      soldToEmail: pickUpDetails.soldTo?.email || "",
      soldToAddress: pickUpDetails.soldTo.address,
      soldToAddressType: pickUpDetails.soldTo.service,
      soldToCity: pickUpDetails.soldTo.city,
      soldToProvince: pickUpDetails.soldTo.country === "US" ? getUSProvinceCodeByName(pickUpDetails.shipTo.province) : getProvinceCode(pickUpDetails.shipTo.province),
      soldToPostalCode: pickUpDetails.soldTo.zipCode.replace(/\s*/g, ""),
      soldToCountry: pickUpDetails.soldTo.country,
      shipDate: dayjs(pickUpDetails.shipDate).format("YYYY-MM-DD"),
      pickupLocationCloseTime: dayjs(pickUpDetails.latestPickupTime).format("YYYY-MM-DD HH:mm"),
      pickupLocationOpenTime: dayjs(pickUpDetails.earliestPickupTime).format("YYYY-MM-DD HH:mm"),
      pickupAvailableTime: dayjs(pickUpDetails.availablePickupTime).format("YYYY-MM-DD HH:mm"),
      pickupDate: dayjs(pickUpDetails.pickupDate).format("YYYY-MM-DD"),
      transMode: getTransportationCode(pickUpDetails.transMode),
      shipmentType: pickUpDetails.shipmentType,
      pickupInstruction: "",

    }
    console.log(requestBody);
    try {
      setPlaceOrderLoading(true);
      const { data } = await axios.post(`${PARTNER_URI}/shippingLTL/LTLRequestQuote`, requestBody, {
        headers: {
          Authorization: `Bearer ${getAccessToken("access_token")}`
        }
      });
      console.log(data);
      setErrorMessage("");
      handleToastClick();
      // handleConfirmDialogClose();
      setIsPlaceOrderSuccess(true);
      // Go Back to home page after 2 second
      // setTimeout(() => {
      //   history.push("/home");
      //
      // }, 2000);
    } catch (e) {
      setErrorMessage(e?.response?.data?.errorMessage || "Error!");
      console.log(e);
    } finally {
      setPlaceOrderLoading(false);
    }
  }

  const getServiceIconByAccountBaseAndService = (serviceName) => {
    const width = "100"
    const height= "100"
    switch (serviceName?.toLowerCase()) {
      case "abf":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/abfFreight.png" alt={serviceName} width={width} height={height} />;
      case "j-link": case "jlink":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/jlink.jpeg" alt={serviceName} width={width} height={height} />;
      case "purolator":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/purolator_logo.png" alt={serviceName} width={width} height={height} />;
    }
  }

  return (
      <>
        {/*Pop-up Toast*/}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={toastOpen}
            onClose={handleToastClose}
            autoHideDuration={3000}
            message="Cancel Order">
          {(() => {
            if (errorMessage !== "") {
              return (
                  <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                    Send Request Failed!
                    <hr />
                    Error: {errorMessage}
                  </Alert>
              )
            }
            return (
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                  Send Request Success!
                </Alert>
            )
          })()}

        </Snackbar>
        {/*Pop-up Toast*/}

        {/*Confirm Place Order Dialog*/}
        <Dialog
            open={confirmDialogOpen}
            onClose={handleConfirmDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Custom Your LTL Request
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {
                isPlaceOrderSuccess ? (
                    <>
                      Thank you! We already received your request, one of our operator would contact you soon.
                    </>
                ) : (
                    <>
                      This process will customize your LTL Request. Are you sure to continue?
                      <hr />
                      Click <b>Confirm</b> to send a request
                    </>
                )
              }
              {/*This process will place your LTL Shipping Order. Are you sure to continue?*/}
              {/*<hr />*/}
              {/*Click <b>Confirm</b> to place an order*/}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {
              isPlaceOrderSuccess ? (
                  <>
                    <Button onClick={handleConfirmDialogClose} variant="outlined" type="button" >Got It</Button>
                    <Button onClick={() => {
                      handleConfirmDialogClose();
                      history.push("/home");
                    }
                    } variant="outlined" autoFocus type="button" >GO TO HOME PAGE</Button>
                  </>
              ) : (
                  <>
                    <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button" >No</Button>
                    <Button onClick={confirmPlaceOrder} variant="outlined" type="button" disabled={placeOrderLoading} >
                      {placeOrderLoading && <CircularProgress size={14} />}
                      {!placeOrderLoading && "Confirm"}
                    </Button>
                  </>
              )
            }
            {/*<Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button" >No</Button>*/}
            {/*<Button onClick={confirmPlaceOrder} variant="outlined" type="button" disabled={placeOrderLoading} >*/}
            {/*  {placeOrderLoading && <CircularProgress size={14} />}*/}
            {/*  {!placeOrderLoading && "Confirm"}*/}
            {/*</Button>*/}
          </DialogActions>
        </Dialog>
        {/*Confirm Place Order Dialog*/}
        {/*<LoadingButton*/}
        {/*    variant="contained"*/}
        {/*    component="span"*/}
        {/*    color="primary"*/}
        {/*    style={{*/}
        {/*      width: "150px"*/}
        {/*    }}*/}
        {/*    onClick={() => {*/}
        {/*      handleConfirmDialogOpen();*/}
        {/*    }}*/}
        {/*    loading={pickupLoading}*/}
        {/*    disabled={message && message !== ""}*/}
        {/*>*/}
        {/*  Send Request*/}
        {/*</LoadingButton>*/}
        <Box justifyContent="center" alignContent="center" alignItems="center">
          <LoadingButton onClick={() => {
            handleConfirmDialogOpen()
          }} style={{
            borderRadius: 35
          }} color="primary">
            Contact Us if you are not satisfied with the price
          </LoadingButton>
        </Box>
      </>

  )
}

export default ShippingLTLCustomRequest;
