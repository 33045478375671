// action types
import {value} from "lodash/seq";

import UPSLogo from "../images/UPSLogo.png";
import FedexLogo from "../images/FedExLogo.png";
import CanadaPostLogo from "../images/CanadaPostLogo.png";
import CanparLogo from "../images/CanparLogo.png";
import JLinkLogo from "../images/J-Link.png";
import CSALogo from "../images/CSA.png";
import ProlatorLogo from "../images/PurolatorLogo.png";
import DayAndRossLogo from "../images/Day&Ross.png";
import VitranLogo from "../images/Vitran.png";
import TForceLogo from "../images/TForce.png";
import {ReactComponent as CanadaIcon} from "../images/homepageIcon/CA.svg";
import {ReactComponent as USIcon} from "../images/homepageIcon/USA.svg";
import Cookies from "js-cookie";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";

export const actionType = {
    'ADDRESS_LIST_SELECTED_TAB': 'ADDRESS_LIST_SELECTED_TAB',
    'ADDRESS_LIST_ADD': 'ADDRESS_LIST_ADD',
    'ADDRESS_LIST_EDIT': 'ADDRESS_LIST_EDIT',
    'ADDRESS_LIST_PASS_ADDRESS_INFO': 'ADDRESS_LIST_PASS_ADDRESS_INFO',
    'LANDING_ESTIMATE_UPDATE_PACKAGE_DATA': 'LANDING_ESTIMATE_UPDATE_PACKAGE_DATA',
    'LANDING_ESTIMATE_UPDATE_PALLET_DATA': 'LANDING_ESTIMATE_UPDATE_PALLET_DATA',
    'LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING': 'LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING',
    'LANDING_ESTIMATE_UPDATE_PALLET_MISSING': 'LANDING_ESTIMATE_UPDATE_PALLET_MISSING',
    'LANDING_ESTIMATE_UPDATE_PACKAGE_COST': 'LANDING_ESTIMATE_UPDATE_PACKAGE_COST',
    'LANDING_ESTIMATE_UPDATE_PALLET_COST': 'LANDING_ESTIMATE_UPDATE_PALLET_COST',
    'LANDING_ESTIMATE_CLEAR_PACKAGE_COST': 'LANDING_ESTIMATE_CLEAR_PACKAGE_COST',
    'LANDING_ESTIMATE_CLEAR_PALLET_COST': 'LANDING_ESTIMATE_CLEAR_PALLET_COST',
    'LANDING_UPDATE_CLICK_SIGN_IN': 'LANDING_UPDATE_CLICK_SIGN_IN',
    'LANDING_UPDATE_CLICK_JOIN_US': 'LANDING_UPDATE_CLICK_JOIN_US',
    'LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION': 'LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION',
    'LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION': 'LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION',
    'LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX': 'LANDING_PAGE_LATEST_ORDER_UPDATE_OPEN_INDEX',
    'LANDING_ESTIMATE_UPDATE_TYPE_SERVICE': 'LANDING_ESTIMATE_UPDATE_TYPE_SERVICE',
    'LANDING_ESTIMATE_UPDATE_COST_ERROR': 'LANDING_ESTIMATE_UPDATE_COST_ERROR',
    'LANDING_ESTIMATE_UPDATE_COST_LOADING': 'LANDING_ESTIMATE_UPDATE_COST_LOADING',
    'LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE': 'LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE',
    'LANDING_ESTIMATE_CLEAR_TYPE_SERVICE': 'LANDING_ESTIMATE_CLEAR_TYPE_SERVICE',
    'COMMERCIAL_INVOICE_UPDATE_TRACKING_NO_LIST': 'COMMERCIAL_INVOICE_UPDATE_TRACKING_NO_LIST',
    'UPDATE_COMMERCIAL_INVOICE_TAB_VALUE': 'UPDATE_COMMERCIAL_INVOICE_TAB_VALUE',
    'UPDATE_ACTION_INDEX_IN_CROSS_BORDER_INVOICE': 'UPDATE_ACTION_INDEX_IN_CROSS_BORDER_INVOICE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_UNIT': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_UNIT',
    'ADD_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'ADD_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'REMOVE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'REMOVE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'DUPLICATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'DUPLICATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'CLEAR_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST': 'CLEAR_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION_MISSING': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION_MISSING',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION_MISSING': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION_MISSING',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CROSS_BORDER': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CROSS_BORDER',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_RATE': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_RATE',
    'SELECT_SHIPPING_LTL_QUICK_QUOTE_RATE': 'SELECT_SHIPPING_LTL_QUICK_QUOTE_RATE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_ADDITIONAL_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_ADDITIONAL_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_ADDITIONAL_LOCATION': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_ADDITIONAL_LOCATION',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIPPER_INFO': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIPPER_INFO',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CONSIGNEE_INFO': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_CONSIGNEE_INFO',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIP_AND_PICKUP_DATA_TIME': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIP_AND_PICKUP_DATA_TIME',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PLACE_ORDER_VALIDATE': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_PLACE_ORDER_VALIDATE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REMARK': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REMARK',
    'SAVE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE': 'SAVE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REFERENCE_NUMBER': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_REFERENCE_NUMBER',

    'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_FROM_LOCATION': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_FROM_LOCATION',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_TO_LOCATION': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_TO_LOCATION',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_UNIT': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_UNIT',
    'INIT_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'INIT_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'ADD_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'ADD_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'REMOVE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'REMOVE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'DUPLICATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'DUPLICATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_SPECIAL_SERVICE': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_SPECIAL_SERVICE',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_VALIDATION': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_VALIDATION',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_RATE': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_RATE',
    'SELECT_SHIPPING_LTL_CREATE_LABEL_RATE': 'SELECT_SHIPPING_LTL_CREATE_LABEL_RATE',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_RATE': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_RATE',
    'UPDATE_CALLED_ALL_LTL_CARRIER': 'UPDATE_CALLED_ALL_LTL_CARRIER',
    'CLEAR_CALLED_ALL_LTL_CARRIER': 'CLEAR_CALLED_ALL_LTL_CARRIER',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_COMMENT': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_COMMENT',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_COMMENT': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_COMMENT',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME',
    'CLEAR_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME': 'CLEAR_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME',
    'ADD_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'ADD_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'UPDATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'REMOVE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'REMOVE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'DUPLICATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'DUPLICATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'CLEAR_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST': 'CLEAR_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_LTL_CROSS_BORDER_RATE': 'UPDATE_SHIPPING_LTL_CROSS_BORDER_RATE',
    'UPDATE_SHIPPING_LTL_CROSS_BORDER_TRANS_AND_TYPE': 'UPDATE_SHIPPING_LTL_CROSS_BORDER_TRANS_AND_TYPE',
    'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_UNIT': 'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_UNIT',
    'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_UNIT': 'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_UNIT',
    'ADD_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'ADD_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'ADD_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'ADD_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'ADD_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'ADD_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'INIT_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'REMOVE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'REMOVE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'REMOVE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'REMOVE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'REMOVE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'REMOVE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'DUPLICATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'DUPLICATE_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'DUPLICATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'DUPLICATE_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'DUPLICATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'DUPLICATE_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'CLEAR_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST': 'CLEAR_SHIPPING_PACKAGE_QUICK_QUOTE_PACKAGE_LIST',
    'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST': 'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_PACKAGE_LIST',
    'CLEAR_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST': 'CLEAR_SHIPPING_PACKAGE_CROSS_BORDER_ITEM_LIST',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION',
    'UPDATE_SHIPPING_CREATE_LABEL_SHIP_FROM_LOCATION': 'UPDATE_SHIPPING_CREATE_LABEL_SHIP_FROM_LOCATION',
    'UPDATE_SHIPPING_CREATE_LABEL_SHIP_TO_LOCATION': 'UPDATE_SHIPPING_CREATE_LABEL_SHIP_TO_LOCATION',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION_MISSING': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_FROM_LOCATION_MISSING',
    'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION_MISSING': 'UPDATE_SHIPPING_QUICK_QUOTE_SHIP_TO_LOCATION_MISSING',
    'UPDATE_SHIPPING_QUICK_QUOTE_CROSS_BORDER': 'UPDATE_SHIPPING_QUICK_QUOTE_CROSS_BORDER',
    'UPDATE_SHIPPING_CREATE_LABEL_CROSS_BORDER': 'UPDATE_SHIPPING_CREATE_LABEL_CROSS_BORDER',
    'UPDATE_SHIPPING_CREATE_LABEL_REFERENCE_ORDER_NUMBER': 'UPDATE_SHIPPING_CREATE_LABEL_REFERENCE_ORDER_NUMBER',
    'UPDATE_SHIPPING_CREATE_LABEL_VALIDATION': 'UPDATE_SHIPPING_CREATE_LABEL_VALIDATION',
    'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_RATE': 'UPDATE_SHIPPING_PACKAGE_QUICK_QUOTE_RATE',
    'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_RATE': 'UPDATE_SHIPPING_PACKAGE_CREATE_LABEL_RATE',
    'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_RATE': 'CLEAR_SHIPPING_PACKAGE_CREATE_LABEL_RATE',
    'SELECT_SHIPPING_PACKAGE_QUICK_QUOTE_RATE': 'SELECT_SHIPPING_PACKAGE_QUICK_QUOTE_RATE',
    'SELECT_SHIPPING_PACKAGE_CREATE_LABEL_RATE': 'SELECT_SHIPPING_PACKAGE_CREATE_LABEL_RATE',
    'TOGGLE_SHIPPING_PACKAGE_QUICK_QUOTE_IS_GET_RATE': 'TOGGLE_SHIPPING_PACKAGE_QUICK_QUOTE_IS_GET_RATE',
    'UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS': 'UPDATE_SHIPPING_PACKAGE_RETURN_LABEL_STATUS',
    'UPDATE_TRACKING_NUMBER': 'UPDATE_TRACKING_NUMBER',
    'UPDATE_TRACKING_NUMBER_BOL': 'UPDATE_TRACKING_NUMBER_BOL',
    'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_RATE': 'UPDATE_SHIPPING_PACKAGE_CROSS_BORDER_RATE',
    'ADD_COMMERCIAL_INVOICE_LIST': 'ADD_COMMERCIAL_INVOICE_LIST',
    'REMOVE_COMMERCIAL_INVOICE_LIST': 'REMOVE_COMMERCIAL_INVOICE_LIST',
    'CLEAR_COMMERCIAL_INVOICE_LIST': 'CLEAR_COMMERCIAL_INVOICE_LIST',
    'IS_CALLED_ALL_PACKAGE_CARRIER': 'IS_CALLED_ALL_PACKAGE_CARRIER',
    'CLEAR_CALLED_ALL_PACKAGE_CARRIER': 'CLEAR_CALLED_ALL_PACKAGE_CARRIER',
    'UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS': 'UPDATE_SHIPPING_QUICK_QUOTE_BATTERY_DETAILS',
    'UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS': 'UPDATE_SHIPPING_CREATE_LABEL_BATTERY_DETAILS',
    'UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS': 'UPDATE_SHIPPING_QUICK_QUOTE_SIGNATURE_OPTIONS',
    'UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS': 'UPDATE_SHIPPING_CREATE_LABEL_SIGNATURE_OPTIONS',
    'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM': 'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_FROM',
    'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO': 'UPDATE_SHIPPING_PACKAGE_INTERNATIONAL_SHIPMENT_SHIP_TO',
    'UPDATE_SHIPPING_LTL_QUICK_QUOTE_BATTERY_DETAILS': 'UPDATE_SHIPPING_LTL_QUICK_QUOTE_BATTERY_DETAILS',
    'UPDATE_SHIPPING_LTL_CREATE_LABEL_BATTERY_DETAILS': 'UPDATE_SHIPPING_LTL_CREATE_LABEL_BATTERY_DETAILS',
}

export const generateRandomString = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export const isValidPostalCode = (postalCode) => {
    const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/i;
    return regex.test(postalCode);
}

export const isValidZipCode = (zipCode) => {
    const regex = /^\d{5}(?:[-\s]\d{4})?$/;
    return regex.test(zipCode);
}

export const getLocation = async () => {
    try {
        const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        console.log("Latitude: " + position.coords.latitude);
        console.log("Longitude: " + position.coords.longitude);
        return position
    } catch (error) {
        console.log("Error retrieving location: " + error.message);
    }
}

// load google map api
export const loadAsyncScript = src => {
    console.log('load google api in helper');
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    })
}

export const extractAddress = place => {
    console.log(place)
    const address = {
        streetNumber: "",
        streetName: "",
        city: "",
        state: "",
        zip: "",
        country: "",
    }

    if (!Array.isArray(place?.address_components)) {
        return address;
    }

    place.address_components.forEach(component => {
        const types = component.types;
        const value = component.long_name;

        if (types.includes("street_number")) {
            address.streetNumber = value;
        }

        if (types.includes("route")) {
            address.streetName = value;
        }

        if (types.includes("locality")) {
            address.city = value;
        }

        if (types.includes("administrative_area_level_1")) {
            address.state = value;
        }

        if (types.includes("postal_code")) {
            address.zip = value;
        }

        if (types.includes("country")) {
            address.country = value;
        }
    });
    return address;
}

export const extractFullAddress = place => {
    console.log('extract full address', place);
    const address = {
        streetNumber: "",
        streetName: "",
        city: "",
        state: {},
        zip: "",
        country: {},
        sublocality: "",
        administrative_area_level_3: ""
    }

    if (!Array.isArray(place?.address_components)) {
        return address;
    }

    const isSublocality = place?.types?.includes("sublocality");

    place.address_components.forEach(component => {
        const types = component.types;
        const value = component.long_name;
        const code = component.short_name;

        if (types.includes("street_number")) {
            address.streetNumber = value;
        }

        if (types.includes("route")) {
            address.streetName = value;
        }

        if (types.includes("locality")) {
            address.city = value;
        }

        if (types.includes("sublocality")) {
            address.sublocality = value;
        }

        if (types.includes("administrative_area_level_3")) {
            address.administrative_area_level_3 = value;
        }

        if (types.includes("administrative_area_level_1")) {
            address.state = {
                name: value,
                code
            }
        }

        if (types.includes("postal_code")) {
            address.zip = value;
        }

        if (types.includes("country")) {
            address.country = {
                name: value,
                code
            }
        }
    });
    return address;
}

export const extractFullAddressComponent = place => {
    console.log('extract full address component', place);
    const address = {
        streetNumber: "",
        streetName: "",
        city: "",
        state: {},
        zip: "",
        country: {},
        sublocality: "",
        administrative_area_level_3: ""
    }

    if (!Array.isArray(place)) {
        return address;
    }

    const isSublocality = place?.types?.includes("sublocality");

    place.forEach(component => {
        const types = component.types;
        const value = component.long_name;
        const code = component.short_name;

        if (types.includes("street_number")) {
            address.streetNumber = value;
        }

        if (types.includes("route")) {
            address.streetName = value;
        }

        if (types.includes("locality")) {
            address.city = value;
        }

        if (types.includes("sublocality")) {
            address.sublocality = value;
        }

        if (types.includes("administrative_area_level_3")) {
            address.administrative_area_level_3 = value;
        }

        if (types.includes("administrative_area_level_1")) {
            address.state = {
                name: value,
                code
            }
        }

        if (types.includes("postal_code")) {
            address.zip = value;
        }

        if (types.includes("country")) {
            address.country = {
                name: value,
                code
            }
        }
    });
    return address;
}

// get valid country from given province/state
export const getCountryFromProvince = (province) => {
    const validStates = {
        "CA": ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"],
        "US": ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
    };
    const country = Object.keys(validStates).find((key) => {
        return validStates[key].indexOf(province) > -1;
    });
    return country || null;
}

// validate the string if contain char other than english
export const isAlphaString = (str) => {
    const regexString = /[^a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
    console.log(str, str.match(regexString));
    return str.match(regexString) === null;
}

// validate email
export const isValidateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
}

// validate phone number
export const isValidatePhone = (value) => {
    const cleanedString = value?.replace(/[^a-zA-Z0-9]/g, '');
    const phoneNumberRegex = /^\d{10,15}$/;
    return phoneNumberRegex.test(cleanedString);
}

// validate phone number worldwide
export const isValidatePhoneWorldWide = (value) => {
    const cleanedString = value.replace(/[^a-zA-Z0-9]/g, '');
    const phoneNumberRegex = /^\d{10,15}$/;
    return phoneNumberRegex.test(cleanedString);
}

// validate postal code
export const isValidPostalCodeByCountry = (country, postalCode) => {
    if (postalCode === null || postalCode === "" || postalCode === undefined) {
        return true;
    }
    const cleanedString = postalCode?.replace(/[^a-zA-Z0-9]/g, '');
    if (country.toLowerCase() === "ca") {
        // Canadian postal code format: A1A1A1
        const canadianPostalCodePattern = /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/;
        return canadianPostalCodePattern.test(cleanedString);
    } else if (country.toLowerCase() === "us") {
        // U.S. ZIP code format: 12345 or 12345-6789
        const usZipCodePattern = /^\d{5}(-\d{4})?$/;
        return usZipCodePattern.test(cleanedString);
    }

    // If the country is neither Canada nor the US, return false
    return false;
}

// positive integer
export const isPositiveInteger = (value) => {
    return /^[1-9]\d*$/.test(value);
}

export const isValidatePassword = (value) => {
    const passwordRegex = /^.{6,20}$/;
    return passwordRegex.test(value);
}

// check missing key for landing package
export const getMissingKeysForBecomePartner = (obj) => {
    const requiredKeys = ['contactEmail', 'companyPhone', 'password', 'companyName', 'firstName', 'lastName', 'companyEmail'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for landing package
export const getMissingKeysForLandingPackage = (obj) => {
    const requiredKeys = ['quantity', 'weight', 'weightUnit', 'length', 'width', 'height', 'dimensionUnit', 'pickupCountry', 'pickupProvince', 'pickupPostalCode', 'deliverCountry', 'deliverProvince', 'deliverPostalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for landing pallet
export const getMissingKeysForLandingPallet = (obj) => {
    const requiredKeys = ['quantity', 'packagingType', 'pickupCity', 'pickupProvince', 'deliverCity', 'deliverProvince'];
    const requiredKeysAdditional = ['quantity', 'packagingType', 'pickupCity', 'pickupProvince', 'pickupPostalCode', 'deliverCity', 'deliverProvince', 'deliverPostalCode'];
     if (obj?.deliverProvince?.countryCode?.toLowerCase() === 'us' || obj?.pickupProvince?.countryCode?.toLowerCase() === 'us') {
         const missingKeys = requiredKeysAdditional.filter(key => {
             return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
         });
         return missingKeys;
     } else {
         const missingKeys = requiredKeys.filter(key => {
             return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
         });
         return missingKeys;
     }
}

// check missing key for quick quote pallet address
export const getMissingKeysForQuickQuotePalletAddress = (obj) => {
    const requiredKeys = ['city', 'province'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for commercial invoice
export const getMissingKeysForCommercialInvoice = (obj) => {
    const requiredKeys = ['date', 'invoiceNo', 'purchaseOrder', 'terms', 'reason'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for quick quote contact info
export const getMissingKeysForQuickQuotePalletContact = (obj) => {
    const requiredKeys = ['name', 'phone'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for quick quote contact info
export const getMissingKeysForQuickQuoteLocation = (obj) => {
    const requiredKeys = ['city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for create label contact info
export const getMissingKeysForCreateLabelLocation = (obj) => {
    const requiredKeys = ['name', 'phone', 'address', 'city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for create label contact info
export const getMissingKeysForCreateLTLLabelLocation = (obj) => {
    const requiredKeys = ['name', 'phone', 'email', 'address', 'city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const getMissingKeysForProfileUserInfo = (obj) => {
    const requiredKeys = ['firstName', 'lastName', 'phone'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const getMissingKeysForProfileCompanyInfo = (obj) => {
    const requiredKeys = ['companyName', 'companyPhone', 'companyType', 'address', 'city', 'province', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const getDeliverTime = (date) => {

    if (date === null || date === "") {
        return -2;
    } else {
        const now = new Date();
        const nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const end = new Date(date);
        const endDateOnly = new Date(end.getFullYear(), end.getMonth(), end.getDate());

        if (endDateOnly < nowDateOnly) {
            return -1;
        } else {
            const diff = Math.abs(endDateOnly - nowDateOnly);
            const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
            return diffDays;
        }
    }
}

export const compareArrays = (array1, array2) => {
    // Check if the arrays have the same length
    if (array1.length !== array2.length) {
        return false;
    }

    // Iterate through the elements of the arrays
    for (let i = 0; i < array1.length; i++) {
        // Compare the values of each element
        if (array1[i] !== array2[i]) {
            return false;
        }
    }

    // All elements are equal
    return true;
}

export const calTransactionFee = (amount) => {
    const processingFee = (amount * 0.029 + 0.3) / 0.971;
    return processingFee.toFixed(2);
}

export const countries = [{code: 'CA', name: 'Canada'}, {code: 'US', name: 'United States'},];

export const provinces = {
    CA: [
        {code: 'AB', name: 'Alberta'},
        {code: 'BC', name: 'British Columbia'},
        {code: 'MB', name: 'Manitoba'},
        {code: 'NB', name: 'New Brunswick'},
        {code: 'NL', name: 'Newfoundland and Labrador'},
        {code: 'NS', name: 'Nova Scotia'},
        {code: 'NT', name: 'Northwest Territories'},
        {code: 'NU', name: 'Nunavut'},
        {code: 'ON', name: 'Ontario'},
        {code: 'PE', name: 'Prince Edward Island'},
        {code: 'QC', name: 'Quebec'},
        {code: 'SK', name: 'Saskatchewan'},
        {code: 'YT', name: 'Yukon'},
    ],
    US: [
        {name: 'Alabama', code: 'AL'},
        {name: 'Alaska', code: 'AK'},
        {name: 'Arizona', code: 'AZ'},
        {name: 'Arkansas', code: 'AR'},
        {name: 'California', code: 'CA'},
        {name: 'Colorado', code: 'CO'},
        {name: 'Connecticut', code: 'CT'},
        {name: 'Delaware', code: 'DE'},
        {name: 'District of Columbia', code: 'DC'},
        {name: 'Florida', code: 'FL'},
        {name: 'Georgia', code: 'GA'},
        {name: 'Hawaii', code: 'HI'},
        {name: 'Idaho', code: 'ID'},
        {name: 'Illinois', code: 'IL'},
        {name: 'Indiana', code: 'IN'},
        {name: 'Iowa', code: 'IA'},
        {name: 'Kansas', code: 'KS'},
        {name: 'Kentucky', code: 'KY'},
        {name: 'Louisiana', code: 'LA'},
        {name: 'Maine', code: 'ME'},
        {name: 'Maryland', code: 'MD'},
        {name: 'Massachusetts', code: 'MA'},
        {name: 'Michigan', code: 'MI'},
        {name: 'Minnesota', code: 'MN'},
        {name: 'Mississippi', code: 'MS'},
        {name: 'Missouri', code: 'MO'},
        {name: 'Montana', code: 'MT'},
        {name: 'Nebraska', code: 'NE'},
        {name: 'Nevada', code: 'NV'},
        {name: 'New Hampshire', code: 'NH'},
        {name: 'New Jersey', code: 'NJ'},
        {name: 'New Mexico', code: 'NM'},
        {name: 'New York', code: 'NY'},
        {name: 'North Carolina', code: 'NC'},
        {name: 'North Dakota', code: 'ND'},
        {name: 'Ohio', code: 'OH'},
        {name: 'Oklahoma', code: 'OK'},
        {name: 'Oregon', code: 'OR'},
        {name: 'Pennsylvania', code: 'PA'},
        {name: 'Rhode Island', code: 'RI'},
        {name: 'South Carolina', code: 'SC'},
        {name: 'South Dakota', code: 'SD'},
        {name: 'Tennessee', code: 'TN'},
        {name: 'Texas', code: 'TX'},
        {name: 'Utah', code: 'UT'},
        {name: 'Vermont', code: 'VT'},
        {name: 'Virginia', code: 'VA'},
        {name: 'Washington', code: 'WA'},
        {name: 'West Virginia', code: 'WV'},
        {name: 'Wisconsin', code: 'WI'},
        {name: 'Wyoming', code: 'WY'}
    ]
}

export const provinces_states = [
    {countryCode: 'CA', country: 'Canada', code: 'AB', name: 'Alberta'},
    {countryCode: 'CA', country: 'Canada', code: 'BC', name: 'British Columbia'},
    {countryCode: 'CA', country: 'Canada', code: 'MB', name: 'Manitoba'},
    {countryCode: 'CA', country: 'Canada', code: 'NB', name: 'New Brunswick'},
    {countryCode: 'CA', country: 'Canada', code: 'NL', name: 'Newfoundland and Labrador'},
    {countryCode: 'CA', country: 'Canada', code: 'NS', name: 'Nova Scotia'},
    {countryCode: 'CA', country: 'Canada', code: 'NT', name: 'Northwest Territories'},
    {countryCode: 'CA', country: 'Canada', code: 'NU', name: 'Nunavut'},
    {countryCode: 'CA', country: 'Canada', code: 'ON', name: 'Ontario'},
    {countryCode: 'CA', country: 'Canada', code: 'PE', name: 'Prince Edward Island'},
    {countryCode: 'CA', country: 'Canada', code: 'QC', name: 'Quebec'},
    {countryCode: 'CA', country: 'Canada', code: 'SK', name: 'Saskatchewan'},
    {countryCode: 'CA', country: 'Canada', code: 'YT', name: 'Yukon'},
    {countryCode: 'US', country: 'United States', name: 'Alabama', code: 'AL'},
    {countryCode: 'US', country: 'United States', name: 'Alaska', code: 'AK'},
    {countryCode: 'US', country: 'United States', name: 'Arizona', code: 'AZ'},
    {countryCode: 'US', country: 'United States', name: 'Arkansas', code: 'AR'},
    {countryCode: 'US', country: 'United States', name: 'California', code: 'CA'},
    {countryCode: 'US', country: 'United States', name: 'Colorado', code: 'CO'},
    {countryCode: 'US', country: 'United States', name: 'Connecticut', code: 'CT'},
    {countryCode: 'US', country: 'United States', name: 'Delaware', code: 'DE'},
    {countryCode: 'US', country: 'United States', name: 'District of Columbia', code: 'DC'},
    {countryCode: 'US', country: 'United States', name: 'Florida', code: 'FL'},
    {countryCode: 'US', country: 'United States', name: 'Georgia', code: 'GA'},
    {countryCode: 'US', country: 'United States', name: 'Hawaii', code: 'HI'},
    {countryCode: 'US', country: 'United States', name: 'Idaho', code: 'ID'},
    {countryCode: 'US', country: 'United States', name: 'Illinois', code: 'IL'},
    {countryCode: 'US', country: 'United States', name: 'Indiana', code: 'IN'},
    {countryCode: 'US', country: 'United States', name: 'Iowa', code: 'IA'},
    {countryCode: 'US', country: 'United States', name: 'Kansas', code: 'KS'},
    {countryCode: 'US', country: 'United States', name: 'Kentucky', code: 'KY'},
    {countryCode: 'US', country: 'United States', name: 'Louisiana', code: 'LA'},
    {countryCode: 'US', country: 'United States', name: 'Maine', code: 'ME'},
    {countryCode: 'US', country: 'United States', name: 'Maryland', code: 'MD'},
    {countryCode: 'US', country: 'United States', name: 'Massachusetts', code: 'MA'},
    {countryCode: 'US', country: 'United States', name: 'Michigan', code: 'MI'},
    {countryCode: 'US', country: 'United States', name: 'Minnesota', code: 'MN'},
    {countryCode: 'US', country: 'United States', name: 'Mississippi', code: 'MS'},
    {countryCode: 'US', country: 'United States', name: 'Missouri', code: 'MO'},
    {countryCode: 'US', country: 'United States', name: 'Montana', code: 'MT'},
    {countryCode: 'US', country: 'United States', name: 'Nebraska', code: 'NE'},
    {countryCode: 'US', country: 'United States', name: 'Nevada', code: 'NV'},
    {countryCode: 'US', country: 'United States', name: 'New Hampshire', code: 'NH'},
    {countryCode: 'US', country: 'United States', name: 'New Jersey', code: 'NJ'},
    {countryCode: 'US', country: 'United States', name: 'New Mexico', code: 'NM'},
    {countryCode: 'US', country: 'United States', name: 'New York', code: 'NY'},
    {countryCode: 'US', country: 'United States', name: 'North Carolina', code: 'NC'},
    {countryCode: 'US', country: 'United States', name: 'North Dakota', code: 'ND'},
    {countryCode: 'US', country: 'United States', name: 'Ohio', code: 'OH'},
    {countryCode: 'US', country: 'United States', name: 'Oklahoma', code: 'OK'},
    {countryCode: 'US', country: 'United States', name: 'Oregon', code: 'OR'},
    {countryCode: 'US', country: 'United States', name: 'Pennsylvania', code: 'PA'},
    {countryCode: 'US', country: 'United States', name: 'Rhode Island', code: 'RI'},
    {countryCode: 'US', country: 'United States', name: 'South Carolina', code: 'SC'},
    {countryCode: 'US', country: 'United States', name: 'South Dakota', code: 'SD'},
    {countryCode: 'US', country: 'United States', name: 'Tennessee', code: 'TN'},
    {countryCode: 'US', country: 'United States', name: 'Texas', code: 'TX'},
    {countryCode: 'US', country: 'United States', name: 'Utah', code: 'UT'},
    {countryCode: 'US', country: 'United States', name: 'Vermont', code: 'VT'},
    {countryCode: 'US', country: 'United States', name: 'Virginia', code: 'VA'},
    {countryCode: 'US', country: 'United States', name: 'Washington', code: 'WA'},
    {countryCode: 'US', country: 'United States', name: 'West Virginia', code: 'WV'},
    {countryCode: 'US', country: 'United States', name: 'Wisconsin', code: 'WI'},
    {countryCode: 'US', country: 'United States', name: 'Wyoming', code: 'WY'}
]

// map logos
export const mapServiceLogo = (serviceName) => {
    const service = serviceLogo.filter(item => item.serviceName === serviceName);
    // console.log(service);
    return service[0]?.url
}

export const serviceLogo = [
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/ups-ground-icon.png",
        serviceName: "UPS Standard"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/ups-express-icon.png",
        serviceName: "UPS Super Express"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/ups-express-icon.png",
        serviceName: "UPS Worldwide Expedited"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/ups-express-icon.png",
        serviceName: "UPS Worldwide Saver"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/ups-express-icon.png",
        serviceName: "UPS Express Saver"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/canadapost.png",
        serviceName: "Canada Post Regular Parcel"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/canadapost.png",
        serviceName: "Canada Post Expedited Parcel"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/canpar-ground.png",
        serviceName: "Canpar Ground"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/canpar-express.png",
        serviceName: "Canpar Select"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/fedex-ground-icon.png",
        serviceName: "Fedex Ground"
    },
    {
        url: "https://storage.googleapis.com/uucargo-resource/image/icon/fedex-express-icon.png",
        serviceName: "Fedex Express Saver"
    }
]

export const mapCarrierLogo = (serviceName) => {
    const service = carrierLogo.filter(item => item.serviceName === serviceName);
    // console.log(service);
    return service[0]?.url
}

export const mapLTLLogo = (serviceName) => {
    const service = LTLLogo.filter(item => item.serviceName === serviceName);
    // console.log(service);
    return service[0]?.url
}

export const mapCountryIcon = (code) => {
    const countryIcon = CountryIcon.filter(item => item.code === code);
    // console.log(countryIcon);
    return countryIcon[0]?.component
}

export const carrierLogo = [
    {
        url: UPSLogo,
        serviceName: "UPS"
    },
    {
        url: CanadaPostLogo,
        serviceName: "CANADAPOST"
    },
    {
        url: FedexLogo,
        serviceName: "FEDEX"
    },
    {
        url: CanparLogo,
        serviceName: "CANPAR"
    },
]

export const LTLLogo = [
    {
        url: JLinkLogo,
        serviceName: "j-link"
    },
    {
        url: CSALogo,
        serviceName: "csa"
    },
    {
        url: ProlatorLogo,
        serviceName: "purolator"
    },
    {
        url: DayAndRossLogo,
        serviceName: "dayandross"
    },
    {
        url: VitranLogo,
        serviceName: "vitran"
    },
    {
        url: TForceLogo,
        serviceName: "tforce"
    }
]

export const CountryIcon = [
    {
        code: 'CA',
        component: <CanadaIcon width='20' height='20'/>
    },
    {
        code: 'US',
        component: <USIcon width='20' height='20'/>
    }
]

export const stringToImage = (text) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set the canvas size based on the length of the text
    const font = '16px Arial';
    const textWidth = ctx.measureText(text).width;
    canvas.width = textWidth + 10;
    canvas.height = 20;

    // Draw the text on the canvas
    ctx.font = font;
    ctx.fillText(text, 5, 15);

    // Create an image from the canvas
    const img = new Image();
    img.src = canvas.toDataURL('image/png');
    return img;
}

export const serviceName = ['j-link', 'purolator', 'csa', 'dayAndRoss'];

export const mapLTLAdditionalService = (serviceCode) => {
    const service = LTLAdditionalService.filter(item => item.serviceCode === serviceCode);
    // console.log(service);
    return service[0]?.serviceName;
}

export const LTLAdditionalService = [
    {serviceCode: 'APTFGT', serviceName: 'Appointment Delivery'},
    {serviceCode: 'DANGEROUS', serviceName: 'Dangerous Goods'},
    {serviceCode: 'INSDLY', serviceName: 'Inside Delivery'},
    {serviceCode: 'PRESDL', serviceName: 'Private Resident Delivery'},
    {serviceCode: 'PRESPU', serviceName: 'Private Resident Pickup'},
    {serviceCode: 'PROTEC', serviceName: 'Protected Service'},
    {serviceCode: 'TLGDEL', serviceName: 'Tailgate Delivery'},
    {serviceCode: 'TLGGP', serviceName: 'Tailgate Pickup'},
    {serviceCode: 'INSFEE', serviceName: 'Insurance/Declared Value'},
]

export const countryListAllIsoData = [
    {"code": "AF", "code3": "AFG", "name": "Afghanistan", "number": "004"},
    {"code": "AL", "code3": "ALB", "name": "Albania", "number": "008"},
    {"code": "DZ", "code3": "DZA", "name": "Algeria", "number": "012"},
    {"code": "AS", "code3": "ASM", "name": "American Samoa", "number": "016"},
    {"code": "AD", "code3": "AND", "name": "Andorra", "number": "020"},
    {"code": "AO", "code3": "AGO", "name": "Angola", "number": "024"},
    {"code": "AI", "code3": "AIA", "name": "Anguilla", "number": "660"},
    {"code": "AQ", "code3": "ATA", "name": "Antarctica", "number": "010"},
    {"code": "AG", "code3": "ATG", "name": "Antigua and Barbuda", "number": "028"},
    {"code": "AR", "code3": "ARG", "name": "Argentina", "number": "032"},
    {"code": "AM", "code3": "ARM", "name": "Armenia", "number": "051"},
    {"code": "AW", "code3": "ABW", "name": "Aruba", "number": "533"},
    {"code": "AU", "code3": "AUS", "name": "Australia", "number": "036"},
    {"code": "AT", "code3": "AUT", "name": "Austria", "number": "040"},
    {"code": "AZ", "code3": "AZE", "name": "Azerbaijan", "number": "031"},
    {"code": "BS", "code3": "BHS", "name": "Bahamas (the)", "number": "044"},
    {"code": "BH", "code3": "BHR", "name": "Bahrain", "number": "048"},
    {"code": "BD", "code3": "BGD", "name": "Bangladesh", "number": "050"},
    {"code": "BB", "code3": "BRB", "name": "Barbados", "number": "052"},
    {"code": "BY", "code3": "BLR", "name": "Belarus", "number": "112"},
    {"code": "BE", "code3": "BEL", "name": "Belgium", "number": "056"},
    {"code": "BZ", "code3": "BLZ", "name": "Belize", "number": "084"},
    {"code": "BJ", "code3": "BEN", "name": "Benin", "number": "204"},
    {"code": "BM", "code3": "BMU", "name": "Bermuda", "number": "060"},
    {"code": "BT", "code3": "BTN", "name": "Bhutan", "number": "064"},
    {"code": "BO", "code3": "BOL", "name": "Bolivia (Plurinational State of)", "number": "068"},
    {"code": "BQ", "code3": "BES", "name": "Bonaire, Sint Eustatius and Saba", "number": "535"},
    {"code": "BA", "code3": "BIH", "name": "Bosnia and Herzegovina", "number": "070"},
    {"code": "BW", "code3": "BWA", "name": "Botswana", "number": "072"},
    {"code": "BV", "code3": "BVT", "name": "Bouvet Island", "number": "074"},
    {"code": "BR", "code3": "BRA", "name": "Brazil", "number": "076"},
    {"code": "IO", "code3": "IOT", "name": "British Indian Ocean Territory (the)", "number": "086"},
    {"code": "BN", "code3": "BRN", "name": "Brunei Darussalam", "number": "096"},
    {"code": "BG", "code3": "BGR", "name": "Bulgaria", "number": "100"},
    {"code": "BF", "code3": "BFA", "name": "Burkina Faso", "number": "854"},
    {"code": "BI", "code3": "BDI", "name": "Burundi", "number": "108"},
    {"code": "CV", "code3": "CPV", "name": "Cabo Verde", "number": "132"},
    {"code": "KH", "code3": "KHM", "name": "Cambodia", "number": "116"},
    {"code": "CM", "code3": "CMR", "name": "Cameroon", "number": "120"},
    {"code": "CA", "code3": "CAN", "name": "Canada", "number": "124"},
    {"code": "KY", "code3": "CYM", "name": "Cayman Islands (the)", "number": "136"},
    {"code": "CF", "code3": "CAF", "name": "Central African Republic (the)", "number": "140"},
    {"code": "TD", "code3": "TCD", "name": "Chad", "number": "148"},
    {"code": "CL", "code3": "CHL", "name": "Chile", "number": "152"},
    {"code": "CN", "code3": "CHN", "name": "China", "number": "156"},
    {"code": "CX", "code3": "CXR", "name": "Christmas Island", "number": "162"},
    {"code": "CC", "code3": "CCK", "name": "Cocos (Keeling) Islands (the)", "number": "166"},
    {"code": "CO", "code3": "COL", "name": "Colombia", "number": "170"},
    {"code": "KM", "code3": "COM", "name": "Comoros (the)", "number": "174"},
    {"code": "CD", "code3": "COD", "name": "Congo (the Democratic Republic of the)", "number": "180"},
    {"code": "CG", "code3": "COG", "name": "Congo (the)", "number": "178"},
    {"code": "CK", "code3": "COK", "name": "Cook Islands (the)", "number": "184"},
    {"code": "CR", "code3": "CRI", "name": "Costa Rica", "number": "188"},
    {"code": "HR", "code3": "HRV", "name": "Croatia", "number": "191"},
    {"code": "CU", "code3": "CUB", "name": "Cuba", "number": "192"},
    {"code": "CW", "code3": "CUW", "name": "Curaçao", "number": "531"},
    {"code": "CY", "code3": "CYP", "name": "Cyprus", "number": "196"},
    {"code": "CZ", "code3": "CZE", "name": "Czechia", "number": "203"},
    {"code": "CI", "code3": "CIV", "name": "Côte d'Ivoire", "number": "384"},
    {"code": "DK", "code3": "DNK", "name": "Denmark", "number": "208"},
    {"code": "DJ", "code3": "DJI", "name": "Djibouti", "number": "262"},
    {"code": "DM", "code3": "DMA", "name": "Dominica", "number": "212"},
    {"code": "DO", "code3": "DOM", "name": "Dominican Republic (the)", "number": "214"},
    {"code": "EC", "code3": "ECU", "name": "Ecuador", "number": "218"},
    {"code": "EG", "code3": "EGY", "name": "Egypt", "number": "818"},
    {"code": "SV", "code3": "SLV", "name": "El Salvador", "number": "222"},
    {"code": "GQ", "code3": "GNQ", "name": "Equatorial Guinea", "number": "226"},
    {"code": "ER", "code3": "ERI", "name": "Eritrea", "number": "232"},
    {"code": "EE", "code3": "EST", "name": "Estonia", "number": "233"},
    {"code": "SZ", "code3": "SWZ", "name": "Eswatini", "number": "748"},
    {"code": "ET", "code3": "ETH", "name": "Ethiopia", "number": "231"},
    {"code": "FK", "code3": "FLK", "name": "Falkland Islands (the) [Malvinas]", "number": "238"},
    {"code": "FO", "code3": "FRO", "name": "Faroe Islands (the)", "number": "234"},
    {"code": "FJ", "code3": "FJI", "name": "Fiji", "number": "242"},
    {"code": "FI", "code3": "FIN", "name": "Finland", "number": "246"},
    {"code": "FR", "code3": "FRA", "name": "France", "number": "250"},
    {"code": "GF", "code3": "GUF", "name": "French Guiana", "number": "254"},
    {"code": "PF", "code3": "PYF", "name": "French Polynesia", "number": "258"},
    {"code": "TF", "code3": "ATF", "name": "French Southern Territories (the)", "number": "260"},
    {"code": "GA", "code3": "GAB", "name": "Gabon", "number": "266"},
    {"code": "GM", "code3": "GMB", "name": "Gambia (the)", "number": "270"},
    {"code": "GE", "code3": "GEO", "name": "Georgia", "number": "268"},
    {"code": "DE", "code3": "DEU", "name": "Germany", "number": "276"},
    {"code": "GH", "code3": "GHA", "name": "Ghana", "number": "288"},
    {"code": "GI", "code3": "GIB", "name": "Gibraltar", "number": "292"},
    {"code": "GR", "code3": "GRC", "name": "Greece", "number": "300"},
    {"code": "GL", "code3": "GRL", "name": "Greenland", "number": "304"},
    {"code": "GD", "code3": "GRD", "name": "Grenada", "number": "308"},
    {"code": "GP", "code3": "GLP", "name": "Guadeloupe", "number": "312"},
    {"code": "GU", "code3": "GUM", "name": "Guam", "number": "316"},
    {"code": "GT", "code3": "GTM", "name": "Guatemala", "number": "320"},
    {"code": "GG", "code3": "GGY", "name": "Guernsey", "number": "831"},
    {"code": "GN", "code3": "GIN", "name": "Guinea", "number": "324"},
    {"code": "GW", "code3": "GNB", "name": "Guinea-Bissau", "number": "624"},
    {"code": "GY", "code3": "GUY", "name": "Guyana", "number": "328"},
    {"code": "HT", "code3": "HTI", "name": "Haiti", "number": "332"},
    {"code": "HM", "code3": "HMD", "name": "Heard Island and McDonald Islands", "number": "334"},
    {"code": "VA", "code3": "VAT", "name": "Holy See (the)", "number": "336"},
    {"code": "HN", "code3": "HND", "name": "Honduras", "number": "340"},
    {"code": "HK", "code3": "HKG", "name": "Hong Kong", "number": "344"},
    {"code": "HU", "code3": "HUN", "name": "Hungary", "number": "348"},
    {"code": "IS", "code3": "ISL", "name": "Iceland", "number": "352"},
    {"code": "IN", "code3": "IND", "name": "India", "number": "356"},
    {"code": "ID", "code3": "IDN", "name": "Indonesia", "number": "360"},
    {"code": "IR", "code3": "IRN", "name": "Iran (Islamic Republic of)", "number": "364"},
    {"code": "IQ", "code3": "IRQ", "name": "Iraq", "number": "368"},
    {"code": "IE", "code3": "IRL", "name": "Ireland", "number": "372"},
    {"code": "IM", "code3": "IMN", "name": "Isle of Man", "number": "833"},
    {"code": "IL", "code3": "ISR", "name": "Israel", "number": "376"},
    {"code": "IT", "code3": "ITA", "name": "Italy", "number": "380"},
    {"code": "JM", "code3": "JAM", "name": "Jamaica", "number": "388"},
    {"code": "JP", "code3": "JPN", "name": "Japan", "number": "392"},
    {"code": "JE", "code3": "JEY", "name": "Jersey", "number": "832"},
    {"code": "JO", "code3": "JOR", "name": "Jordan", "number": "400"},
    {"code": "KZ", "code3": "KAZ", "name": "Kazakhstan", "number": "398"},
    {"code": "KE", "code3": "KEN", "name": "Kenya", "number": "404"},
    {"code": "KI", "code3": "KIR", "name": "Kiribati", "number": "296"},
    {"code": "KP", "code3": "PRK", "name": "Korea (the Democratic People's Republic of)", "number": "408"},
    {"code": "KR", "code3": "KOR", "name": "Korea (the Republic of)", "number": "410"},
    {"code": "KW", "code3": "KWT", "name": "Kuwait", "number": "414"},
    {"code": "KG", "code3": "KGZ", "name": "Kyrgyzstan", "number": "417"},
    {"code": "LA", "code3": "LAO", "name": "Lao People's Democratic Republic (the)", "number": "418"},
    {"code": "LV", "code3": "LVA", "name": "Latvia", "number": "428"},
    {"code": "LB", "code3": "LBN", "name": "Lebanon", "number": "422"},
    {"code": "LS", "code3": "LSO", "name": "Lesotho", "number": "426"},
    {"code": "LR", "code3": "LBR", "name": "Liberia", "number": "430"},
    {"code": "LY", "code3": "LBY", "name": "Libya", "number": "434"},
    {"code": "LI", "code3": "LIE", "name": "Liechtenstein", "number": "438"},
    {"code": "LT", "code3": "LTU", "name": "Lithuania", "number": "440"},
    {"code": "LU", "code3": "LUX", "name": "Luxembourg", "number": "442"},
    {"code": "MO", "code3": "MAC", "name": "Macao", "number": "446"},
    {"code": "MG", "code3": "MDG", "name": "Madagascar", "number": "450"},
    {"code": "MW", "code3": "MWI", "name": "Malawi", "number": "454"},
    {"code": "MY", "code3": "MYS", "name": "Malaysia", "number": "458"},
    {"code": "MV", "code3": "MDV", "name": "Maldives", "number": "462"},
    {"code": "ML", "code3": "MLI", "name": "Mali", "number": "466"},
    {"code": "MT", "code3": "MLT", "name": "Malta", "number": "470"},
    {"code": "MH", "code3": "MHL", "name": "Marshall Islands (the)", "number": "584"},
    {"code": "MQ", "code3": "MTQ", "name": "Martinique", "number": "474"},
    {"code": "MR", "code3": "MRT", "name": "Mauritania", "number": "478"},
    {"code": "MU", "code3": "MUS", "name": "Mauritius", "number": "480"},
    {"code": "YT", "code3": "MYT", "name": "Mayotte", "number": "175"},
    {"code": "MX", "code3": "MEX", "name": "Mexico", "number": "484"},
    {"code": "FM", "code3": "FSM", "name": "Micronesia (Federated States of)", "number": "583"},
    {"code": "MD", "code3": "MDA", "name": "Moldova (the Republic of)", "number": "498"},
    {"code": "MC", "code3": "MCO", "name": "Monaco", "number": "492"},
    {"code": "MN", "code3": "MNG", "name": "Mongolia", "number": "496"},
    {"code": "ME", "code3": "MNE", "name": "Montenegro", "number": "499"},
    {"code": "MS", "code3": "MSR", "name": "Montserrat", "number": "500"},
    {"code": "MA", "code3": "MAR", "name": "Morocco", "number": "504"},
    {"code": "MZ", "code3": "MOZ", "name": "Mozambique", "number": "508"},
    {"code": "MM", "code3": "MMR", "name": "Myanmar", "number": "104"},
    {"code": "NA", "code3": "NAM", "name": "Namibia", "number": "516"},
    {"code": "NR", "code3": "NRU", "name": "Nauru", "number": "520"},
    {"code": "NP", "code3": "NPL", "name": "Nepal", "number": "524"},
    {"code": "NL", "code3": "NLD", "name": "Netherlands (the)", "number": "528"},
    {"code": "NC", "code3": "NCL", "name": "New Caledonia", "number": "540"},
    {"code": "NZ", "code3": "NZL", "name": "New Zealand", "number": "554"},
    {"code": "NI", "code3": "NIC", "name": "Nicaragua", "number": "558"},
    {"code": "NE", "code3": "NER", "name": "Niger (the)", "number": "562"},
    {"code": "NG", "code3": "NGA", "name": "Nigeria", "number": "566"},
    {"code": "NU", "code3": "NIU", "name": "Niue", "number": "570"},
    {"code": "NF", "code3": "NFK", "name": "Norfolk Island", "number": "574"},
    {"code": "MP", "code3": "MNP", "name": "Northern Mariana Islands (the)", "number": "580"},
    {"code": "NO", "code3": "NOR", "name": "Norway", "number": "578"},
    {"code": "OM", "code3": "OMN", "name": "Oman", "number": "512"},
    {"code": "PK", "code3": "PAK", "name": "Pakistan", "number": "586"},
    {"code": "PW", "code3": "PLW", "name": "Palau", "number": "585"},
    {"code": "PS", "code3": "PSE", "name": "Palestine, State of", "number": "275"},
    {"code": "PA", "code3": "PAN", "name": "Panama", "number": "591"},
    {"code": "PG", "code3": "PNG", "name": "Papua New Guinea", "number": "598"},
    {"code": "PY", "code3": "PRY", "name": "Paraguay", "number": "600"},
    {"code": "PE", "code3": "PER", "name": "Peru", "number": "604"},
    {"code": "PH", "code3": "PHL", "name": "Philippines (the)", "number": "608"},
    {"code": "PN", "code3": "PCN", "name": "Pitcairn", "number": "612"},
    {"code": "PL", "code3": "POL", "name": "Poland", "number": "616"},
    {"code": "PT", "code3": "PRT", "name": "Portugal", "number": "620"},
    {"code": "PR", "code3": "PRI", "name": "Puerto Rico", "number": "630"},
    {"code": "QA", "code3": "QAT", "name": "Qatar", "number": "634"},
    {"code": "MK", "code3": "MKD", "name": "Republic of North Macedonia", "number": "807"},
    {"code": "RO", "code3": "ROU", "name": "Romania", "number": "642"},
    {"code": "RU", "code3": "RUS", "name": "Russian Federation (the)", "number": "643"},
    {"code": "RW", "code3": "RWA", "name": "Rwanda", "number": "646"},
    {"code": "RE", "code3": "REU", "name": "Réunion", "number": "638"},
    {"code": "BL", "code3": "BLM", "name": "Saint Barthélemy", "number": "652"},
    {"code": "SH", "code3": "SHN", "name": "Saint Helena, Ascension and Tristan da Cunha", "number": "654"},
    {"code": "KN", "code3": "KNA", "name": "Saint Kitts and Nevis", "number": "659"},
    {"code": "LC", "code3": "LCA", "name": "Saint Lucia", "number": "662"},
    {"code": "MF", "code3": "MAF", "name": "Saint Martin (French part)", "number": "663"},
    {"code": "PM", "code3": "SPM", "name": "Saint Pierre and Miquelon", "number": "666"},
    {"code": "VC", "code3": "VCT", "name": "Saint Vincent and the Grenadines", "number": "670"},
    {"code": "WS", "code3": "WSM", "name": "Samoa", "number": "882"},
    {"code": "SM", "code3": "SMR", "name": "San Marino", "number": "674"},
    {"code": "ST", "code3": "STP", "name": "Sao Tome and Principe", "number": "678"},
    {"code": "SA", "code3": "SAU", "name": "Saudi Arabia", "number": "682"},
    {"code": "SN", "code3": "SEN", "name": "Senegal", "number": "686"},
    {"code": "RS", "code3": "SRB", "name": "Serbia", "number": "688"},
    {"code": "SC", "code3": "SYC", "name": "Seychelles", "number": "690"},
    {"code": "SL", "code3": "SLE", "name": "Sierra Leone", "number": "694"},
    {"code": "SG", "code3": "SGP", "name": "Singapore", "number": "702"},
    {"code": "SX", "code3": "SXM", "name": "Sint Maarten (Dutch part)", "number": "534"},
    {"code": "SK", "code3": "SVK", "name": "Slovakia", "number": "703"},
    {"code": "SI", "code3": "SVN", "name": "Slovenia", "number": "705"},
    {"code": "SB", "code3": "SLB", "name": "Solomon Islands", "number": "090"},
    {"code": "SO", "code3": "SOM", "name": "Somalia", "number": "706"},
    {"code": "ZA", "code3": "ZAF", "name": "South Africa", "number": "710"},
    {"code": "GS", "code3": "SGS", "name": "South Georgia and the South Sandwich Islands", "number": "239"},
    {"code": "SS", "code3": "SSD", "name": "South Sudan", "number": "728"},
    {"code": "ES", "code3": "ESP", "name": "Spain", "number": "724"},
    {"code": "LK", "code3": "LKA", "name": "Sri Lanka", "number": "144"},
    {"code": "SD", "code3": "SDN", "name": "Sudan (the)", "number": "729"},
    {"code": "SR", "code3": "SUR", "name": "Suriname", "number": "740"},
    {"code": "SJ", "code3": "SJM", "name": "Svalbard and Jan Mayen", "number": "744"},
    {"code": "SE", "code3": "SWE", "name": "Sweden", "number": "752"},
    {"code": "CH", "code3": "CHE", "name": "Switzerland", "number": "756"},
    {"code": "SY", "code3": "SYR", "name": "Syrian Arab Republic", "number": "760"},
    {"code": "TW", "code3": "TWN", "name": "Taiwan", "number": "158"},
    {"code": "TJ", "code3": "TJK", "name": "Tajikistan", "number": "762"},
    {"code": "TZ", "code3": "TZA", "name": "Tanzania, United Republic of", "number": "834"},
    {"code": "TH", "code3": "THA", "name": "Thailand", "number": "764"},
    {"code": "TL", "code3": "TLS", "name": "Timor-Leste", "number": "626"},
    {"code": "TG", "code3": "TGO", "name": "Togo", "number": "768"},
    {"code": "TK", "code3": "TKL", "name": "Tokelau", "number": "772"},
    {"code": "TO", "code3": "TON", "name": "Tonga", "number": "776"},
    {"code": "TT", "code3": "TTO", "name": "Trinidad and Tobago", "number": "780"},
    {"code": "TN", "code3": "TUN", "name": "Tunisia", "number": "788"},
    {"code": "TR", "code3": "TUR", "name": "Turkey", "number": "792"},
    {"code": "TM", "code3": "TKM", "name": "Turkmenistan", "number": "795"},
    {"code": "TC", "code3": "TCA", "name": "Turks and Caicos Islands (the)", "number": "796"},
    {"code": "TV", "code3": "TUV", "name": "Tuvalu", "number": "798"},
    {"code": "UG", "code3": "UGA", "name": "Uganda", "number": "800"},
    {"code": "UA", "code3": "UKR", "name": "Ukraine", "number": "804"},
    {"code": "AE", "code3": "ARE", "name": "United Arab Emirates (the)", "number": "784"},
    {
        "code": "GB",
        "code3": "GBR",
        "name": "United Kingdom of Great Britain and Northern Ireland (the)",
        "number": "826"
    },
    {"code": "UM", "code3": "UMI", "name": "United States Minor Outlying Islands (the)", "number": "581"},
    {"code": "US", "code3": "USA", "name": "United States of America (the)", "number": "840"},
    {"code": "UY", "code3": "URY", "name": "Uruguay", "number": "858"},
    {"code": "UZ", "code3": "UZB", "name": "Uzbekistan", "number": "860"},
    {"code": "VU", "code3": "VUT", "name": "Vanuatu", "number": "548"},
    {"code": "VE", "code3": "VEN", "name": "Venezuela (Bolivarian Republic of)", "number": "862"},
    {"code": "VN", "code3": "VNM", "name": "Viet Nam", "number": "704"},
    {"code": "VG", "code3": "VGB", "name": "Virgin Islands (British)", "number": "092"},
    {"code": "VI", "code3": "VIR", "name": "Virgin Islands (U.S.)", "number": "850"},
    {"code": "WF", "code3": "WLF", "name": "Wallis and Futuna", "number": "876"},
    {"code": "EH", "code3": "ESH", "name": "Western Sahara", "number": "732"},
    {"code": "YE", "code3": "YEM", "name": "Yemen", "number": "887"},
    {"code": "ZM", "code3": "ZMB", "name": "Zambia", "number": "894"},
    {"code": "ZW", "code3": "ZWE", "name": "Zimbabwe", "number": "716"},
    {"code": "AX", "code3": "ALA", "name": "Åland Islands", "number": "248"}
];

export const getCountryCode = (countryName) => {
    const normalizedCountryName = countryName?.toLowerCase();
    console.log('normal name', normalizedCountryName);

    for (const countryData of countryListAllIsoData) {
        const normalizedCountryDataName = countryData.name?.toLowerCase();
        console.log('nornam name lower', normalizedCountryDataName);

        if (normalizedCountryDataName === normalizedCountryName) {
            return countryData?.code;
        }
    }
    return countryName?.toUpperCase();
}

export const getWelcome = () => {
    const isWelcome = Cookies.get('welcome');
    if (isWelcome) {
        return false;
    } else {
        return true;
    }
}

export const convertCMToIN = (value, decimal= 0) => {
    const convertValue = (value / 2.54).toFixed(decimal);
    return convertValue;
}

export const convertINToCM = (value, decimal = 0) => {
    const convertValue = (value * 2.54).toFixed(decimal);
    return convertValue;
}

export const convertKGToLB = (value, decimal = 0) => {
    const convertValue = (value * 2.20462).toFixed(decimal);
    return convertValue;
}

export const convertLBToKG = (value, decimal = 0) => {
    const convertValue = (value / 2.20462).toFixed(decimal);
    return convertValue;
}



export const convertCubicInToCubicMeter = (value) => {
    const convertValue = (value / 61023.744095).toFixed(2);
    return convertValue;
}

export const mapSpecialService = (code) => {
    switch (code) {
        case 'APTFGT':
            return 'Appointment Delivery';
            break
        case 'PRESDL':
            return 'Private Resident Delivery';
            break
        case 'PRESPU':
            return 'Private Resident Pickup';
            break
        case 'PROTEC':
            return 'Protected Service';
            break
        case 'TLGDEL':
            return 'Taligate Delivery';
            break
        case 'TLGPU':
            return 'Taligate Pickup';
            break
        default:
            return code
    }
}

export const RefreshPageNotification = () => {

    const handleBeforeUnload = (event) => {
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <></>
    );
}

export const movingShippingStatus = (shippingStatus) => {
    switch (shippingStatus) {
        case 1:
            return 'Initial';
            break;
        case 2:
            return 'Processing';
            break;
        case 3:
            return 'Pickup Confirmed';
            break;
        case 4:
            return 'Deliver Confirmed';
            break;
        case 20:
            return 'Waiting Approved';
            break;
        case 25:
            return 'Confirmed';
            break;
        case 30:
            return 'Pickup Unconfirmed';
            break;
        case 40:
            return 'Deliver Unconfirmed';
            break;
        default:
            return 'Invalid';
    }
}

export const movingOrderStatus = (orderStatus) => {
    switch (orderStatus) {
        case 0:
            return 'Initial';
            break;
        case 100:
            return 'Paid Deposit';
            break;
        case 1:
            return 'Payment Received';
            break;
        case 2:
            return 'Paused';
            break;
        case 3:
            return 'Refunded';
            break;
        case 4:
            return 'Refunded';
            break;
        case -1:
            return 'Cancelled';
            break;
        default:
            return 'Invalid';
    }
}


export const calculatePallet = (palletLength, palletWidth, palletHeight, weightCapacity, totalVolume, totalWeight) => {
    // console.log('[calculatePallet] palletLength', palletLength);
    // console.log('[calculatePallet] palletWidth', palletWidth);
    // console.log('[calculatePallet] palletHeight', palletHeight);

    const palletVolume = +palletLength * +palletWidth * +palletHeight;
    const palletQuantityByVolume = +totalVolume / +palletVolume;
    const palletQuantityByWeight = +totalWeight / +weightCapacity;
    const quantity = (palletQuantityByVolume > palletQuantityByWeight) ? palletQuantityByVolume : palletQuantityByWeight;
    // console.log('[calculatePallet] quantity', quantity);
    return (quantity >= 0 && quantity <= 0.5) ? 0.5 : Math.ceil(quantity * 2) / 2;
}

export const exchangeDimensions = (items) => {
    return items.map(item => {
        let { length, width } = item;
        if (parseInt(length) < parseInt(width)) {
            // Swap length and width
            return {
                ...item,
                length: width,
                width: length
            };
        }
        return item;
    });
};