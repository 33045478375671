import React, {useRef, useState} from "react";
import {IconButton, makeStyles, Tooltip} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import {CSVLink} from "react-csv";
import xlsx from "json-as-xlsx";
import CurrencyFormat from "react-currency-format";
import {separateTaxes} from "../../utils/calculateTax";
import dayjs from "dayjs";
import PublicIcon from "@mui/icons-material/Public";
import {saveAs} from 'file-saver';
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {Accordion, AccordionDetails, AccordionSummary, Divider} from "@mui/material";
import Button from "@mui/material/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getAccessToken} from "../../utils/doToken";

const useStyles = makeStyles((theme) => ({
    listItemWrapper: {
        width: "100%",
        margin: "3px auto",
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
        lineHeight: "2rem",
        '@media (max-width: 480px)': {
            display: 'none'
        }
    },
    ShippingOrderListResponsiveSection: {
        display: 'none',
        '@media (max-width: 480px)': {
            display: 'flex'
        }
    }
}));

const styles = {
    ShippingOrderListTableResponsive: {
        width: '100%',
        padding: '5px'
    },
    ShippingOrderListTableResponsiveText: {
        fontSize: '14px',
        textAlign: 'left'
    },
    ShippingOrderListTableResponsiveTitle: {
        fontSize: '14px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingOrderListResponsiveButtonBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0
    },
    ShippingOrderListResponsiveButton: {
        fontSize: '12px'
    }
}

export default function ShippingOrderListTable({
                                                   order_id,
                                                   partner_id,
                                                   ref_order_no,
                                                   cargo_control_no,
                                                   account_base,
                                                   total_amount,
                                                   send_from_name,
                                                   send_from_email,
                                                   send_from_tel,
                                                   send_from_addr,
                                                   send_from_city,
                                                   send_from_postal_code,
                                                   send_from_province,
                                                   origi_shipping_item_file,
                                                   currency_code,
                                                   date,
                                                   isCrossBorder,
                                                   total_amount_include_tax,
                                                   service_fee_include_gst
                                               }) {
    const classes = useStyles();
    // const [transactions, setTransactions] = useState([
    //     ["Order ID", "Cargo Control Number", "Ref Order No", "Tran ID", "Account Base", "Client Number", "Product ID", "Exclude Tax", "Include Tax", "GST", "HST", "QST", "Date", "Tracking #", "Labels"],
    // ]);
    const [transactions, setTransactions] = useState([
        [
            "Order ID",
            "Cargo Control Number",
            "Ref Order No",
            "Tran ID",
            "Carrier",
            "Client Number",
            "Product ID",
            "Exclude Tax",
            "Include Tax",
            "GST",
            "HST",
            "QST",
            "Total Brokerage Charge",
            "Total Duties Charge",
            "Total Duties Tax Charge",
            "Service Fee Exclude Tax",
            "Service Fee Include Tax",
            "Shipping Total Tax",

            "Freight Charge",
            "SurCharge",
            "Fuel Charge",
            "Collect Charge",
            "Residential Charge",
            "Rural Charge",
            "Extended Area Charge",
            "Extra Care Charge",
            "Handling Charge",
            "Over Size Charge",
            "Over Weight Charge",
            "COD Charge",
            "COS Charge",
            "Dangerous Charge",
            "Surcharge Details",
            "Date",
            "Tracking #",
            "Labels",

            "Send From Name",
            // "Send From Company",
            "Send From Address",
            "Send From City",
            "Send From Province",
            "Send From Country",
            "Send From Postal Code",

            "Send To Name",
            // "Send To Company",
            "Send To Phone",
            "Send To Email",
            "Send To Address",
            "Send To City",
            "Send To Province",
            "Send To Country",
            "Send To Postal Code"
        ],
    ]);
    const [trackings, setTrackings] = useState([
        // ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Order Total Amount", "Transaction Amount", "Currency", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
        ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
    ])
    const history = useHistory();

    const excelFields = {
        "Order ID": "",
        "Cargo Control Number": "",
        "Ref Order No": "",
        "Tran ID": "",
        "Account Base": "",
        "Client ID": "",
        "Product ID": "",
        "Net Price": "",
        "Date": "",
        "Labels": "",
    }

    const sampleJson = [{
        "Order ID": "1",
        "Cargo Control Number": "1",
        "Ref Order No": "1",
        "Tran ID": "1",
        "Account Base": "1",
        "Client ID": "1",
        "Product ID": "1",
        "Net Price": "1",
        "Date": "1",
        "Label PDF": "1"
    }]

    const csvLink = useRef();
    const csvTrackingLink = useRef();
    const getTransactionData = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getTransactionsByOrderId?partner_id=${partner_id}&order_id=${order_id}&account_base=${account_base}`);
            const result = data.result;
            // const resultArray = [["Order ID", "Cargo Control Number", "Ref Order No", "Tran ID", "Account Base", "Client Number", "Product ID", "Exclude Tax", "Include Tax", "GST", "HST", "QST", "Date", "Tracking #", "Labels"],];
            const resultArray = [
                [
                    "Order ID",
                    "Cargo Control Number",
                    "Ref Order No",
                    "Tran ID",
                    "Carrier",
                    "Client Number",
                    "Product ID",
                    "Exclude Tax",
                    "Include Tax",
                    "GST",
                    "HST",
                    "QST",
                    "Total Brokerage Charge",
                    "Total Duties Charge",
                    "Total Duties Tax Charge",
                    "Service Fee Exclude Tax",
                    "Service Fee Include Tax",
                    "Shipping Total Tax",

                    "Freight Charge",
                    "SurCharge",
                    "Fuel Charge",
                    "Collect Charge",
                    "Residential Charge",
                    "Rural Charge",
                    "Extended Area Charge",
                    "Extra Care Charge",
                    "Handling Charge",
                    "Over Size Charge",
                    "Over Weight Charge",
                    "COD Charge",
                    "COS Charge",
                    "Dangerous Charge",
                    "Surcharge Details",
                    "Date",
                    "Tracking #",
                    "Labels",

                    "Send From Name",
                    // "Send From Company",
                    "Send From Address",
                    "Send From City",
                    "Send From Province",
                    "Send From Country",
                    "Send From Postal Code",

                    "Send To Name",
                    // "Send To Company",
                    "Send To Phone",
                    "Send To Email",
                    "Send To Address",
                    "Send To City",
                    "Send To Province",
                    "Send To Country",
                    "Send To Postal Code"
                ],
            ];
            for (const column of result) {
                const columnData = [];
                columnData.push(column.order_id);
                columnData.push(column.cargo_control_no);
                columnData.push(column.ref_order_no);
                columnData.push(column.tran_id);
                columnData.push(column.account_base);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                // Swap Price
                columnData.push(column.amount_gross);
                columnData.push(column.amount);

                const taxesObject = separateTaxes({
                    excludeTaxAmount: column.amount_gross,
                    includeTaxAmount: column.amount
                })
                // // GST
                // columnData.push(taxesObject.gstTax);
                // // PST
                // columnData.push(taxesObject.pstTax);
                // columnData.push(taxesObject["GST/HST"]);
                columnData.push(taxesObject.gstTax);
                columnData.push(taxesObject.hstTax);
                columnData.push(taxesObject.qstTax);

                columnData.push(column.total_brokerage_charge);
                columnData.push(column.total_duties_charge);
                columnData.push(column.total_duties_taxes_charge);
                columnData.push(column.service_fee_exclude_tax);
                columnData.push(column.service_fee_include_gst);
                columnData.push(column.shipping_total_tax);

                columnData.push(column.freight_charge);
                columnData.push(column.sur_charge);
                columnData.push(column.fuel_surcharge);
                columnData.push(column.collect_charge);
                columnData.push(column.residential_charge);
                columnData.push(column.rural_charge);
                columnData.push(column.extended_area_charge);
                columnData.push(column.extra_care_charge);
                columnData.push(column.handling_charge);
                columnData.push(column.over_size_charge);
                columnData.push(column.over_weight_charge);
                columnData.push(column.cod_charge);
                columnData.push(column.cos_charge);
                columnData.push(column.dangerous_charge);
                columnData.push(column?.surcharge_details || "");

                columnData.push(column.tranacted_at);
                columnData.push(column.tran_tracking_nos);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");

                // Send From Info
                columnData.push(column.send_from_name);
                columnData.push(column.send_from_addr);
                columnData.push(column.send_from_city);
                columnData.push(column.send_from_province);
                columnData.push(column.send_from_country);
                columnData.push(column.send_from_postal_code);

                // Send To Info
                columnData.push(column.send_to_name);
                columnData.push(column.send_to_tel);
                columnData.push(column.send_to_email);
                columnData.push(column.send_to_addr);
                columnData.push(column.send_to_city);
                columnData.push(column.send_to_province);
                columnData.push(column.send_to_country);
                columnData.push(column.send_to_postal_code);

                resultArray.push(columnData);
            }
            setTransactions(resultArray);
            console.log(resultArray);
            csvLink.current.link.click()
        } catch (e) {
            console.log(e);
        }
    }

    const getTrackingsData = async () => {
        try {
            const {data} = await axios.post(`${PARTNER_URI}/shipping/list/order/trackings`, {
                partnerId: partner_id,
                orderId: order_id,
            });
            const result = data.result;
            const resultArray = [];
            for (const column of result) {
                const columnData = [];
                columnData.push(order_id);
                columnData.push(ref_order_no);
                columnData.push(cargo_control_no);
                columnData.push(account_base);
                columnData.push(column.tracking_no);
                // columnData.push(column.total_amount);
                // columnData.push(column.amount);
                // columnData.push(column.currency_code);
                columnData.push(column.tran_id);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");
                columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
                columnData.push(column.tranacted_at);

                resultArray.push(columnData);
            }
            setTrackings(v => {
                const temp = [...v];
                temp.push(...resultArray);
                return temp;
            });
            console.log(resultArray);
            csvTrackingLink.current.link.click()
        } catch (e) {
            console.log(e);
        }
    }

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const getOrderLabels = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getLabelZipByOrderId?partner_id=${partner_id}&order_id=${order_id}&account_base=${account_base}`);
            const result = data.result;
            return result?.map(column => getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A").filter(v => v !== "N/A");
        } catch (e) {
            console.log(e);
        }
    }

    // const saveZip = (filename, urls) => {
    //   if(!urls) return;
    //
    //   const zip = new JSZip();
    //   const folder = zip.folder("files"); // folder name where all files will be placed in
    //
    //   urls.forEach((url) => {
    //     const blobPromise = fetch(url).then((r) => {
    //       if (r.status === 200) return r.blob();
    //       return Promise.reject(new Error(r.statusText));
    //     });
    //     const name = url.substring(url.lastIndexOf("/") + 1);
    //     folder.file(name, blobPromise);
    //   });
    //
    //   zip.generateAsync({ type: "blob" }).then((blob) => saveAs(blob, filename));
    //
    // };

    const downloadLabelIntoZip = async () => {
        try {
            const accessToken = getAccessToken("access_token");
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getLabelZipByOrderId?partner_id=${partner_id}&order_id=${order_id}&account_base=${account_base}`, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const blobData = new Blob([data], {
                type: "application/zip"
            });
            saveAs(blobData, `${partner_id}-${order_id}.zip`);
            // console.log("labels: ", labels);
            // saveZip("my_project_files_to_download.zip", labels);
        } catch (e) {
            console.log(e);
        }
    }

    const getTransactionDataExcel = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getTransactionsByOrderId?partner_id=${partner_id}&order_id=${order_id}&account_base=${account_base}`);
            const result = data.result;
            const resultArray = [];
            for (const column of result) {
                const columnData = {...excelFields};
                columnData["Order ID"] = order_id;
                columnData["Cargo Control Number"] = column.cargo_control_no;
                columnData["Ref Order No"] = ref_order_no;
                columnData["Tran ID"] = column.tran_id;
                columnData["Account Base"] = account_base;
                columnData["Client ID"] = column.ref_other_no;
                columnData["Product ID"] = column.ref_product_no;
                columnData["Net Price"] = column.amount;
                columnData["Date"] = column.tranacted_at;
                // columnData["Label PDF"] = column.label_pdf_uri ? `=HYPERLINK("https://storage.googleapis.com/${column.label_pdf_uri}")` : "N/A";
                // columnData["ZIP Label"] = column.label_zip_uri ? `=HYPERLINK("https://storage.googleapis.com/${column.label_zip_uri}")` : "N/A";
                columnData["Labels"] = getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A";
                resultArray.push(columnData);
            }
            console.log(resultArray);
            const excelBody = [
                {
                    sheet: "Transactions",
                    columns: [
                        {
                            label: "Order ID",
                            value: "orderId"
                        },
                        {
                            label: "Cargo Control Number",
                            value: "cargoControlNumber"
                        },
                        {
                            label: "Ref Order No",
                            value: "refOrderNumber"
                        },
                        {
                            label: "Tran ID",
                            value: "tranId"
                        },
                        {
                            label: "Account Base",
                            value: "accountBase"
                        },
                        {
                            label: "Client ID",
                            value: "clientId",
                        },
                        {
                            label: "Product ID",
                            value: "productId"
                        },
                        {
                            label: "Net Price",
                            value: "netPrice"
                        },
                        {
                            label: "Date",
                            value: "date"
                        },
                        {
                            label: "Labels",
                            value: "labels"
                        }
                    ],
                    content: resultArray.map(v => {
                        return {
                            orderId: v["Order ID"],
                            cargoControlNumber: v["Cargo Control Number"],
                            refOrderNumber: v["Ref Order No"],
                            tranId: v["Tran ID"],
                            accountBase: v["Account Base"],
                            clientId: v["Client ID"],
                            productId: v["Product ID"],
                            netPrice: v["Net Price"],
                            date: v["Date"],
                            labels: v["Labels"]
                        }
                    })
                }
            ]
            let settings = {
                fileName: `${order_id}-${ref_order_no}`,
            }
            // excelLink.current.link.click()
            xlsx(excelBody, settings)
        } catch (e) {
            console.log(e);
        }

    }

    const goToCrossBorderPage = () => {
        history.push(`/commercial-invoice/${order_id}`);
    }

    return (
        <>
            <tr className={classes.listItemWrapper}>
                <td>
                    {/*<Link to={`/loose-item/3rd-party/shipping/trans/${order_id}?account_base=${account_base}`}>{order_id}</Link>*/}
                    {
                        isCrossBorder && (
                            <Tooltip title="Edit Commercial Invoices For Cross-Boarder Order" placement="top">
                                <IconButton color="primary" aria-label="Cross Border" onClick={goToCrossBorderPage}>
                                    <PublicIcon/>
                                </IconButton>
                            </Tooltip>
                        )
                    }
                    <Link to={`/loose-item/order-dashboard/${order_id}`}>{order_id}</Link>
                </td>
                <td width="10%">
                    {ref_order_no}
                    <br/>
                    {cargo_control_no}
                </td>
                {/*<td>{cargo_control_no}</td>*/}
                <td>{account_base}</td>
                <td width="15%">
                    {/*<CurrencyFormat value={(total_amount || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currency_code} $`} />*/}
                    {/*<CurrencyFormat value={(Math.abs(total_amount || 0))} displayType={'text'} thousandSeparator={true} prefix={(+total_amount || 0) >= 0 ? `${currency_code} $` : `${currency_code} $-`} />*/}
                    <CurrencyFormat
                        value={(Math.abs((total_amount_include_tax + service_fee_include_gst) || 0).toFixed(2))}
                        displayType={'text'} thousandSeparator={true}
                        prefix={((total_amount_include_tax + service_fee_include_gst) || 0) >= 0 ? `${currency_code} $` : `${currency_code} $-`}/>
                </td>
                <td>{`${send_from_name ? send_from_name : "N/A"} / ${send_from_email ? send_from_email : "N/A"} / ${send_from_tel ? send_from_tel : "N/A"}`}</td>
                <td>{`${send_from_addr ? send_from_addr : "N/A"} / ${send_from_city ? send_from_city : "N/A"} / ${send_from_province ? send_from_province : "N/A"} / ${send_from_postal_code ? send_from_postal_code : "N/A"}`}</td>
                <td>
                    <a href={origi_shipping_item_file ? `${LABEL_DOWNLOAD_URL_PREFIX}${origi_shipping_item_file}` : ""}
                       target="_blank" rel="noopener noreferrer">
                        {origi_shipping_item_file ? origi_shipping_item_file.split("uucargo-resource/upload/shipping/")[1] : ""}
                    </a>
                </td>
                <td>{dayjs(date).format("YYYY-MM-DD HH:mm")}</td>
                <td>
                    <div>
                        <button onClick={getTransactionData}>Download</button>
                        <CSVLink
                            data={transactions}
                            filename={`${order_id}-${ref_order_no}-transactions.csv`}
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                        {/*<button onClick={getTransactionDataExcel}>Download</button>*/}
                        {/*<Box hidden>*/}
                        {/*  <button ref={excelLink} />*/}
                        {/*</Box>*/}
                    </div>
                </td>
                <td>
                    <div>
                        <button onClick={getTrackingsData}>Download</button>
                        <CSVLink
                            data={trackings}
                            filename={`${order_id}-${ref_order_no}-trackings.csv`}
                            className='hidden'
                            ref={csvTrackingLink}
                            target='_blank'
                        />
                        {/*<button onClick={getTransactionDataExcel}>Download</button>*/}
                        {/*<Box hidden>*/}
                        {/*  <button ref={excelLink} />*/}
                        {/*</Box>*/}
                    </div>
                </td>
                <td>
                    <div>
                        <button onClick={downloadLabelIntoZip}>Download</button>
                    </div>
                </td>
            </tr>
            <Box className={classes.ShippingOrderListResponsiveSection}>
                <Box sx={styles.ShippingOrderListTableResponsive}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Grid item xs={6}>
                                <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                    Order ID
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={styles.ShippingOrderListTableResponsiveText}>
                                    <Link to={`/loose-item/order-dashboard/${order_id}`}>{order_id}</Link>
                                </Typography>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                        Ref Order # / Cargo Control #
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveText}>
                                        {ref_order_no} / {cargo_control_no}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                        Carrier
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveText}>
                                        {account_base}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                        Total Charge Include Tax
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveText}>
                                        <CurrencyFormat
                                            value={(Math.abs((total_amount_include_tax + service_fee_include_gst) || 0).toFixed(2))}
                                            displayType={'text'} thousandSeparator={true}
                                            prefix={((total_amount_include_tax + service_fee_include_gst) || 0) >= 0 ? `${currency_code} $` : `${currency_code} $-`}/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                        Send From Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveText}>
                                        {`${send_from_name ? send_from_name : "N/A"} / ${send_from_email ? send_from_email : "N/A"} / ${send_from_tel ? send_from_tel : "N/A"}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                        Send From Address
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveText}>
                                        {`${send_from_addr ? send_from_addr : "N/A"} / ${send_from_city ? send_from_city : "N/A"} / ${send_from_province ? send_from_province : "N/A"} / ${send_from_postal_code ? send_from_postal_code : "N/A"}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                        Origin Shipping Item File Link
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveText}>
                                        <a href={origi_shipping_item_file ? `${LABEL_DOWNLOAD_URL_PREFIX}${origi_shipping_item_file}` : ""}
                                           target="_blank" rel="noopener noreferrer">
                                            {origi_shipping_item_file ? origi_shipping_item_file.split("uucargo-resource/upload/shipping/")[1] : ""}
                                        </a>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                        Create Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveText}>
                                        {dayjs(date).format("YYYY-MM-DD HH:mm")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                        Transaction
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={styles.ShippingOrderListResponsiveButtonBox}>
                                        <Button
                                            onClick={getTransactionData}
                                            sx={styles.ShippingOrderListResponsiveButton}
                                        >
                                            Download
                                        </Button>
                                        <CSVLink
                                            data={transactions}
                                            filename={`${order_id}-${ref_order_no}-transactions.csv`}
                                            className='hidden'
                                            ref={csvLink}
                                            target='_blank'
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                        Tracking #
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={styles.ShippingOrderListResponsiveButtonBox}>
                                        <Button
                                            onClick={getTrackingsData}
                                            sx={styles.ShippingOrderListResponsiveButton}
                                        >
                                            Download
                                        </Button>
                                        <CSVLink
                                            data={trackings}
                                            filename={`${order_id}-${ref_order_no}-trackings.csv`}
                                            className='hidden'
                                            ref={csvTrackingLink}
                                            target='_blank'
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={styles.ShippingOrderListTableResponsiveTitle}>
                                        Labels
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={styles.ShippingOrderListResponsiveButtonBox}>
                                        <Button
                                            onClick={downloadLabelIntoZip}
                                            sx={styles.ShippingOrderListResponsiveButton}
                                        >
                                            Download
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
        </>
    );
}
