import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import addPackage from "../../images/Create Label.png";
import {CommercialInvoiceFormList} from "./CommercialInvoiceFormList";
import {useParams} from "react-router-dom";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {generateRandomString} from "../../utils/Helper";
import {useDispatch, useSelector} from "react-redux";
import {CommercialInvoiceFormAdd} from "./CommercialInvoiceFormAdd";
import {getAccessToken} from "../../utils/doToken";
import {addCommericalInvoiceList, clearCommercialInvoiceList} from "../Actions/commercialInvoiceAction";

export const CommercialInvoiceForm = ({shippingListData}) => {

    let invoiceListRedux = useSelector(state => state?.commercialInvoice?.commercialInvoiceList);
    const dispatch = useDispatch();

    // console.log('shipping data', shippingListData);

    const {order_id} = useParams();
    const partnerInfo = useSelector((state) => state.user);

    // const [invoiceList, setInvoiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [trackingList, setTrackingList] = useState([]);
    const [trackingNumberList, setTrackingNumberList] = useState([]);
    const [invoiceStatus, setInvoiceStatus] = useState(false);
    const [isAddInvoice, setIsAddInvoice] = useState(false);
    const [itemList, setItemList] = useState([]);

    const handleAddInvoiceOpen = () => {
        setIsAddInvoice(true);
    }

    const handleAddInvoiceClose = () => {
        setIsAddInvoice(false);
    }

    const cbHandleAddInvoice = (invoiceData) => {
        // setInvoiceList(prevState => [...prevState, invoiceData]);
        handleAddInvoiceClose();
    }

    const getTrackingList = async () => {
        setLoading(true);
        let requestURI = `${PARTNER_URI}/shipping/list/getTrackingViewByOrderId`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                params: {
                    order_id: order_id,
                    partner_id: partnerInfo?.id
                }
            })

            const {data} = results;

            console.log('get tracking list', data);

            const allInvoiceStatus = data.every(element => element?.doc_status === 2);

            console.log('all invoice status', allInvoiceStatus);

            setInvoiceStatus(allInvoiceStatus);

            const updatedTrackingList = [];

            data.forEach(element => {
                if (element.doc_status !== 2) {
                    const randomId = generateRandomString(8);
                    let object = {
                        trackingId: randomId,
                        isChecked: true,
                        trackingNumber: element?.tracking_no,
                        tranId: element?.tran_id,
                        shippingIdNumber: element?.shipping_id_no,
                        description: element?.item_name,
                        unitPrice: element?.unit_price,
                        quantity: 1,
                        UOM: element?.uom,
                        weight: element?.weight,
                        hsCode: element?.hs_code,
                        originalCountry: element?.origi_country,
                        currency: element?.currency_code,
                        weightUnit: element?.weight_unit,
                        length: element?.length,
                        height: element?.height,
                        width: element?.width,
                        dimensionUnit: element?.dimension_unit
                    }
                    updatedTrackingList.push(object);
                }
            })
            console.log('update tracking list', updatedTrackingList);
            setTrackingList(updatedTrackingList);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e)
        }
    }

    const getItemList = async () => {
        let requestURI = `${PARTNER_URI}/packingList/getPackingListAndPackingListDetailByShippingOrderIdAndPartnerId`;
        const token = getAccessToken("access_token");
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    shippingOrderId: order_id,
                    currency: 'CAD'
                }
            })

            const {data: {packingListDetails}} = results;
            console.log('get packing list', packingListDetails);

            const updatedPackingList = [];

            packingListDetails.forEach(element => {
                let object = {
                    packingId: element?.id,
                    isChecked: true,
                    description: element?.item_name,
                    unitPrice: element?.unit_price,
                    quantity: 1,
                    UOM: element?.uom,
                    weight: element?.weight,
                    hsCode: element?.hs_code,
                    originalCountry: element?.origi_country,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                }
                updatedPackingList.push(object);
            })
            setItemList(updatedPackingList);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        dispatch(clearCommercialInvoiceList());
        getTrackingList();
        getItemList();
    }, []);

    return (
        <Box sx={{
            display: 'flex',
            marginTop: '20px'
        }}>
            <Grid container spacing={2}>
                {
                    trackingList?.length === 0 ?
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                                You have submitted all required commercial invoice.
                            </Typography>
                        </Grid>
                        :
                        isAddInvoice ? <CommercialInvoiceFormAdd shippingListData={shippingListData}
                                                                 cbHandleAddInvoice={cbHandleAddInvoice}
                                                                 handleAddInvoiceClose={handleAddInvoiceClose}
                                                                 trackingList={trackingList}
                                                                 invoiceList={invoiceListRedux}
                                                                 itemList={itemList}/> :
                            invoiceListRedux?.length > 0 ?
                                <CommercialInvoiceFormList invoiceList={invoiceListRedux}
                                                           handleAddInvoiceOpen={handleAddInvoiceOpen}/>
                                :
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '1px solid #B7B7B7',
                                        padding: '20px',
                                        borderRadius: '10px'
                                    }}>
                                        <Typography
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                color: '#7F8487',
                                                marginBottom: '10px'
                                            }}>
                                            Your invoice list is empty
                                        </Typography>
                                        <IconButton
                                            sx={{color: '#609966'}}
                                            onClick={handleAddInvoiceOpen}
                                            // disabled={isGetRate}
                                        >
                                            <img src={addPackage} width='35px'/>
                                        </IconButton>
                                        <Typography style={{fontSize: '12px', fontWeight: '600', color: '#609966'}}>
                                            Add new invoice
                                        </Typography>
                                    </Box>
                                </Grid>

                }
            </Grid>
        </Box>
    )
}