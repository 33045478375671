import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    getMissingKeysForBecomePartner,
    isValidateEmail,
    isValidatePassword,
    isValidatePhone,
    provinces_states
} from "../../utils/Helper";
import {Checkbox, InputAdornment, Link, Snackbar, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {HelpOutline} from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import PartnerCoupons from "../../images/partner_coupons.png";
import Button from "@mui/material/Button";
import LoadingContainer from "../../shared/loadingContainer";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {updateLandingClickJoinUs} from "../Actions/landingEstimateAction";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as React from 'react';
import MuiAlert from "@mui/material/Alert";
import {getAccessToken} from "../../utils/doToken";
import MuiPhoneNumber from "material-ui-phone-number-2";
import {MuiTelInput} from 'mui-tel-input'
import intl from "react-intl-universal";

const businessTypeOption = [
    "E-Commerce",
    "Logistic Transportation",
    "Warehouse",
    "Solution Provider",
    "Other"
]

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    UserSignUpRoot: {
        // width: '500px',
        // maxWidth: '500px',
        width: '50%',
        margin: '0 auto 20px',
        '@media (max-width: 1024px)': {
            width: '100%', // for tablets and above
        },
        '@media (max-width: 480px)': {
            width: '100%', // for phone and above
        },
    },
    UserSignUpContainer: {
        gap: '10px',
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    UserSignUpIntro: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    UserSignUpHeading: {
        textAlign: 'left',
        fontSize: '40px',
        fontWeight: '600',
        padding: '10px 0',
        color: 'green',
        '@media (max-width: 480px)': {
            fontSize: '25px', // for phone and above
        },

    },
    UserSignUpText: {
        textAlign: 'left',
        fontSize: '14px',
    },
    UserSignUpContent: {
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '30px',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    },
    UserSignUpWarningText: {
        fontSize: '12px',
        color: '#FF0303'
    },
    UserSignUpResultTextSuccess: {
        fontSize: '14px',
        color: '#539165'
    },
    UserSignUpResultTextFail: {
        fontSize: '14px',
        color: '#FF0303'
    },
    UserSignUpCoupon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 0
    },
    UserSignUpCouponImage: {
        height: '500px'
    },
    UserSignUpButtonBox: {
        width: '100%',
    },
    UserSignUpButton: {
        width: '80%',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
    UserSignUpHaveAccount: {
        display: 'flex',
        gap: '5px',
        justifyContent: 'center'
    },
    UserSignUpPrivacyAndTerms: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px'
    },
    UserSignUpCheckbox: {
        padding: 0,
        color: 'green',
        '&.Mui-checked': {
            color: 'green'
        }
    },
    UserSignUpPrivacyAndTermsText: {
        textAlign: 'left',
        fontSize: '14px'
    },
    UserSignUpPrivacyAndTermsLink: {
        color: 'green',
        textDecoration: 'underline green'
    }
}

export const UserSignUp = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [sendEmail, setSendEmail] = useState();
    const [sendStatus, setSendStatus] = useState(false);

    const [companyEmail, setCompanyEmail] = useState();
    const [companyName, setCompanyName] = useState();
    const [companyPhone, setCompanyPhone] = useState();
    const [postalCode, setPostalCode] = useState();
    const [province, setProvince] = useState();
    const [contactName, setContactName] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();

    const [contactEmail, setContactEmail] = useState();
    const [password, setPassword] = useState();
    const [companyType, setCompanyType] = useState();
    const [coupon, setCoupon] = useState();

    const [form, setForm] = useState({});

    const [missingKeys, setMissingKeys] = useState([]);

    const [validationContactEmail, setValidationContactEmail] = useState(true);
    const [validationCompanyEmail, setValidationCompanyEmail] = useState(true);
    const [validationPhone, setValidationPhone] = useState(true);
    const [validationPassword, setValidationPassword] = useState(true);

    const [openModal, setOpenModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [registerStatus, setRegisterStatus] = useState(false);

    const [check, setCheck] = useState(null);

    const isMobile = useMediaQuery('(max-width: 480px)');
    const history = useHistory();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleModalOpen = () => {
        setOpenModal(true);
    }

    const handleModalClose = () => {
        setOpenModal(false);
    }

    const handleCompanyEmail = event => {
        const value = event.target.value;
        setCompanyEmail(value);
        setForm(prevState => ({
            ...prevState, companyEmail: value
        }))
    }

    const handleCompanyName = event => {
        const value = event.target.value;
        setCompanyName(value);
        setForm(prevState => ({
            ...prevState, companyName: value
        }))
    }

    const handleCompanyPhone = value => {
        setCompanyPhone(value);
        setForm(prevState => ({
            ...prevState, companyPhone: value
        }))
    }

    const handlePostalCode = event => {
        const value = event.target.value;
        setPostalCode(value);
        setForm(prevState => ({
            ...prevState, postalCode: value
        }))
    }

    const handleProvince = (event, value) => {
        setProvince(value);
        setForm(prevState => ({
            ...prevState, province: value
        }))
    }

    const handleContactName = event => {
        const value = event.target.value;
        setContactName(value);
        setForm(prevState => ({
            ...prevState, contactName: value
        }))
    }

    const handleFirstName = event => {
        const value = event.target.value;
        setFirstName(value);
        setForm(prevState => ({
            ...prevState, firstName: value
        }))
    }

    const handleLastName = event => {
        const value = event.target.value;
        setLastName(value);
        setForm(prevState => ({
            ...prevState, lastName: value
        }))
    }

    const handleContactEmail = event => {
        const value = event.target.value.trim();
        setContactEmail(value);
        setForm(prevState => ({
            ...prevState, contactEmail: value
        }))
    }

    const handlePassword = event => {
        const value = event.target.value.trim();
        setPassword(value);
        setForm(prevState => ({
            ...prevState, password: value
        }))
    }

    const handleBusinessType = (event, value) => {
        setCompanyType(value);
        setForm(prevState => ({
            ...prevState, companyType: value
        }))
    }

    const handleCoupon = event => {
        const value = event.target.value;
        setCoupon(value);
        setForm(prevState => ({
            ...prevState, coupon: value
        }))
    }

    const handleCheck = () => {
        setCheck(!check);
    }

    const registerPartner = async () => {
        setIsLoading(true);
        let requestURL = `${PARTNER_URI}/login_middleware/registerPartner`;

        const data = {
            companyName: companyName,
            companyEmail: companyEmail,
            companyPhone: companyPhone,
            contactPhone: companyPhone,
            contactFirstName: firstName,
            contactLastName: lastName,
            contactEmail: contactEmail?.trim(),
            password: password?.trim(),
            address: "",
            city: "",
            province: province?.name,
            postalCode: postalCode,
            companyType: companyType,
            coupons: coupon,
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log(result.data);
            setRegisterStatus(true);
            setIsLoading(false);
            handleSuccessMessage();
            setErrorMessage('');
            setSuccessMessage(intl.get('LANDING_PAGE.SIGN_UP.SUCCESS_MESSAGE'))

        } catch (e) {
            console.log(e.response);
            setRegisterStatus(false);
            const errorCode = e?.response?.data?.code
            console.log(errorCode);
            if (errorCode === 401) {
                setErrorMessage(intl.get('LANDING_PAGE.SIGN_UP.ERROR_MESSAGE_1'))
            } else if (errorCode === 403) {
                setErrorMessage(intl.get('LANDING_PAGE.SIGN_UP.ERROR_MESSAGE_3'))
            } else {
                setErrorMessage(intl.get('LANDING_PAGE.SIGN_UP.ERROR_MESSAGE_2'))
            }
            handleErrorMessage();
            setIsLoading(false);
        }
    }

    //
    // const sendRegisterPartnerEmail = async () => {
    //     setIsLoading(true);
    //     let requestURL = `${PARTNER_URI}/partnerLandingService/sendRegisterPartnerEmail`;
    //     const data = {
    //         name: contactName,
    //         phone: phone,
    //         email: email,
    //         businessName: businessName,
    //         companyType: companyType,
    //         coupons: coupon,
    //         city: "",
    //         province: location?.name,
    //         country: location?.country
    //     }
    //
    //     try {
    //         const result = await axios({
    //             method: 'post',
    //             url: requestURL,
    //             data: data
    //         })
    //         console.log(result.data)
    //         setIsLoading(false);
    //         setSendEmail("Your application has been sent successfully. We will contact you soon.");
    //         setSendStatus(true);
    //
    //     } catch (e) {
    //         console.log(e);
    //         setIsLoading(false);
    //         setSendEmail('Your application was not sent successfully. Please try again later.');
    //         setSendStatus(false);
    //     }
    // }

    const validateContactEmail = () => {
        const result = isValidateEmail(contactEmail);
        setValidationContactEmail(result);
        return result;
    }

    const validateCompanyEmail = () => {
        const result = isValidateEmail(companyEmail);
        setValidationCompanyEmail(result);
        return result;
    }

    const validatePhone = () => {
        const result = isValidatePhone(companyPhone);
        setValidationPhone(result);
        return result;
    }

    const validatePassword = () => {
        const result = isValidatePassword(password);
        setValidationPassword(result);
        return result;
    }

    const handleSubmit = () => {
        console.log('form', form);
        const missingKeys = getMissingKeysForBecomePartner(form);
        setMissingKeys(missingKeys);
        const contactEmailCheck = validateContactEmail();
        const companyEmailCheck = validateCompanyEmail();
        const phoneCheck = validatePhone();
        const passwordCheck = validatePassword();
        check === null && setCheck(false);
        if (companyEmailCheck && contactEmailCheck && passwordCheck && phoneCheck && missingKeys.length === 0 && check) {
            registerPartner();
        }
    }

    let isClickJoinUs = useSelector(state => state?.landingEstimate?.landingIsClickJoinUs);
    const dispatch = useDispatch();

    useEffect(() => {
        if (getAccessToken("access_token")) {
            history.push("/home");
        }
    }, []);

    console.log('company phone', companyPhone);
    return (
        <Box sx={styles.UserSignUpRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.UserSignUpContainer}>
                <Box sx={styles.UserSignUpIntro}>
                    <Typography sx={styles.UserSignUpHeading}>
                        {intl.get('LANDING_PAGE.SIGN_UP.CREATE')}
                    </Typography>
                    <Typography sx={styles.UserSignUpText}>
                        {intl.get('LANDING_PAGE.SIGN_UP.TEXT')}
                    </Typography>
                </Box>
                {registerStatus ?
                    <Box sx={styles.UserSignUpButtonBox}>
                        <Button
                            variant="contained"
                            sx={styles.UserSignUpButton}
                            onClick={() => history.push('/sign-in')}
                        >
                            {intl.get('LANDING_PAGE.SIGN_UP.SIGN_IN_ACCOUNT')}
                        </Button>
                    </Box>
                    :
                    <Box sx={styles.UserSignUpContent}>
                        <Box>
                            <TextField
                                value={contactEmail}
                                label={intl.get('LANDING_PAGE.SIGN_UP.EMAIL')}
                                required
                                fullWidth
                                variant="outlined"
                                onInput={handleContactEmail}
                            />
                            <Typography sx={styles.UserSignUpWarningText}>
                                {missingKeys.includes('contactEmail') ?
                                    intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : !validationContactEmail ?
                                        intl.get('LANDING_PAGE.SIGN_UP.INVALID_EMAIL') : ''}
                            </Typography>
                        </Box>
                        <Box>
                            <TextField
                                value={password}
                                label={intl.get('LANDING_PAGE.SIGN_UP.PASSWORD')}
                                type={showPassword ? 'text' : 'password'}
                                required
                                fullWidth
                                variant="outlined"
                                onInput={handlePassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Typography sx={styles.UserSignUpWarningText}>
                                {missingKeys.includes('password') ?
                                    intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : !validationPassword ?
                                        intl.get('LANDING_PAGE.SIGN_UP.INVALID_PASSWORD') : ''}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', width: '100%', gap: '10px'}}>
                            <Box sx={{width: '100%'}}>
                                <TextField
                                    value={firstName}
                                    label={intl.get('LANDING_PAGE.SIGN_UP.FIRST_NAME')}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    onInput={handleFirstName}
                                />
                                <Typography sx={styles.UserSignUpWarningText}>
                                    {missingKeys.includes('firstName') ? intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : ''}
                                </Typography>
                            </Box>
                            <Box sx={{width: '100%'}}>
                                <TextField
                                    value={lastName}
                                    label={intl.get('LANDING_PAGE.SIGN_UP.LAST_NAME')}
                                    required
                                    fullWidth
                                    variant="outlined"
                                    onInput={handleLastName}
                                />
                                <Typography sx={styles.UserSignUpWarningText}>
                                    {missingKeys.includes('lastName') ? intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : ''}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <TextField
                                value={companyName}
                                label={intl.get('LANDING_PAGE.SIGN_UP.COMPANY_NAME')}
                                required
                                fullWidth
                                variant="outlined"
                                onInput={handleCompanyName}
                            />
                            <Typography sx={styles.UserSignUpWarningText}>
                                {missingKeys.includes('companyName') ? intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : ''}
                            </Typography>
                        </Box>
                        <Box>
                            {/*<TextField*/}
                            {/*    value={companyPhone}*/}
                            {/*    label="Company Phone"*/}
                            {/*    required*/}
                            {/*    fullWidth*/}
                            {/*    variant="outlined"*/}
                            {/*    onInput={handleCompanyPhone}*/}
                            {/*/>*/}
                            <MuiTelInput
                                value={companyPhone}
                                fullWidth
                                label={intl.get('LANDING_PAGE.SIGN_UP.COMPANY_PHONE')}
                                defaultCountry="CA"
                                onChange={handleCompanyPhone}
                            />
                            <Typography sx={styles.UserSignUpWarningText}>
                                {missingKeys.includes('companyPhone') ?
                                    intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : !validationPhone ?
                                        intl.get('LANDING_PAGE.SIGN_UP.INVALID_PHONE') : ''}
                            </Typography>
                        </Box>
                        <Box>
                            <TextField
                                value={companyEmail}
                                label={intl.get('LANDING_PAGE.SIGN_UP.COMPANY_EMAIL')}
                                fullWidth
                                required
                                variant="outlined"
                                onInput={handleCompanyEmail}
                            />
                            <Typography sx={styles.UserSignUpWarningText}>
                                {missingKeys.includes('companyEmail') ?
                                    intl.get('LANDING_PAGE.SIGN_UP.REQUIRED') : !validationCompanyEmail ?
                                        intl.get('LANDING_PAGE.SIGN_UP.INVALID_EMAIL') : ''}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', width: '100%', gap: '10px', alignItems: 'flex-end'}}>
                            <Box sx={{width: '100%'}}>
                                <Autocomplete
                                    value={province}
                                    groupBy={(option) => option.country}
                                    fullWidth
                                    options={provinces_states}
                                    getOptionLabel={(option) => option.code}
                                    onChange={handleProvince}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        {...params}
                                        label={intl.get('LANDING_PAGE.SIGN_UP.PROVINCE')}
                                    />}
                                    renderOption={(option) => (
                                        <div>
                                            {option.name}
                                        </div>
                                    )}
                                />
                            </Box>
                            <Box sx={{width: '100%'}}>
                                <TextField
                                    value={postalCode}
                                    label={intl.get('LANDING_PAGE.SIGN_UP.POSTAL_CODE')}
                                    fullWidth
                                    variant="outlined"
                                    onInput={handlePostalCode}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Autocomplete
                                value={companyType}
                                fullWidth
                                options={businessTypeOption}
                                onChange={handleBusinessType}
                                renderInput={(params) => <TextField
                                    variant="outlined"
                                    {...params}
                                    label={intl.get('LANDING_PAGE.SIGN_UP.TYPE')}
                                />}
                            />
                        </Box>
                        <Box>
                            <TextField
                                value={coupon}
                                label={intl.get('LANDING_PAGE.SIGN_UP.COUPONS')}
                                placeholder="20APMA or 10APMA"
                                fullWidth
                                variant="outlined"
                                onInput={handleCoupon}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleModalOpen}>
                                                <HelpOutline/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <Modal
                            open={openModal}
                            onClose={handleModalClose}
                        >
                            <Box sx={styles.UserSignUpCoupon}>
                                <img src={PartnerCoupons} alt="partner coupons" style={styles.UserSignUpCouponImage}/>
                            </Box>
                        </Modal>
                        <Box>
                            <Box sx={styles.UserSignUpPrivacyAndTerms}>
                                <Checkbox
                                    checked={check}
                                    sx={styles.UserSignUpCheckbox}
                                    onChange={handleCheck}
                                />
                                <Typography sx={styles.UserSignUpPrivacyAndTermsText}>
                                    {intl.get('LANDING_PAGE.SIGN_UP.AGREEMENT')} <Link
                                    sx={styles.UserSignUpPrivacyAndTermsLink}
                                    href={'https://uucargo.ca/terms-of-use/'}
                                    target="_blank" rel="noopener noreferrer"
                                >{intl.get('LANDING_PAGE.SIGN_UP.TERMS')}
                                </Link> {intl.get('LANDING_PAGE.SIGN_UP.AND')} <Link
                                    sx={styles.UserSignUpPrivacyAndTermsLink}
                                    href={'https://uucargo.ca/privacy-policy/'}
                                    target="_blank" rel="noopener noreferrer"
                                >{intl.get('LANDING_PAGE.SIGN_UP.PRIVATE_POLICY')}</Link>.
                                </Typography>
                            </Box>
                            <Typography sx={styles.UserSignUpWarningText}>
                                {check === null ? '' : check ? '' : intl.get('LANDING_PAGE.SIGN_UP.AGREEMENT_WARNING')}
                            </Typography>
                        </Box>
                        <Box sx={styles.UserSignUpButtonBox}>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={styles.UserSignUpButton}
                            >
                                {intl.get('LANDING_PAGE.SIGN_UP.CREATE_ACCOUNT')}
                            </Button>
                            {/*{*/}
                            {/*    isLoading ? <LoadingContainer/> :*/}
                            {/*        <Typography*/}
                            {/*            sx={sendStatus ? styles.UserSignUpResultTextSuccess : styles.UserSignUpResultTextFail}>{sendEmail}*/}
                            {/*        </Typography>*/}
                            {/*}*/}
                        </Box>
                    </Box>
                }
                <Box sx={styles.UserSignUpHaveAccount}>
                    <Typography
                        style={{fontSize: '14px'}}
                    >
                        {intl.get('LANDING_PAGE.SIGN_UP.HAVE_ACCOUNT')}
                    </Typography>
                    <Typography
                        style={{fontSize: '14px', color: 'green', cursor: 'pointer', fontWeight: '600'}}
                        onClick={() => {
                            // handleOpenSignup();
                            history.push('/sign-in')
                            // dispatch(updateLandingClickJoinUs(true))
                        }}
                    >
                        {intl.get('LANDING_PAGE.SIGN_UP.SIGN_IN')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}