import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton,
    makeStyles,
    Snackbar, Table, TableBody, TableCell, TableHead, TableRow,
    Tooltip,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import TrackPackageItem from "../Tracking/TrackPackageItem";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from "@material-ui/core/Collapse";
import {Typography} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    listItemWrapper: {
        width: "100%",
        margin: "3px auto",
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue",
        },
        lineHeight: "2rem",
        textAlign: "left",
    },
    trackingInfoWrapper: {
        fontWeight: 900
    },
    trackingNumbers: {
        '&:hover': {
            background: "#fcbf49",
        },
        cursor: "pointer",
        textDecoration: "underline",
        color: "blue"
    }
}));

export default function ShippingTransactionTable({
                                                     tran_id,
                                                     order_id,
                                                     ref_order_no,
                                                     cargo_control_no,
                                                     ref_product_no,
                                                     ref_other_no,
                                                     account_base,
                                                     gross_price,
                                                     net_price,
                                                     zip_label_uri,
                                                     label_pdf_uri,
                                                     date,
                                                     send_to_name,
                                                     send_to_email,
                                                     send_to_tel,
                                                     send_to_addr,
                                                     send_to_city,
                                                     send_to_postal_code,
                                                     send_to_province,
                                                     send_to_country,
                                                     currency_code,
                                                     transactionNumbers,
                                                     tran_status,
                                                     shipping_id_no,
                                                     shipping_fee_exclude_tax,
                                                     service_fee_exclude_tax,
                                                     service_fee_include_gst,
                                                     shipping_fee_include_tax,
                                                 }) {
    const classes = useStyles();

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    };

    const [cancelTrackingNumberText, setCancelTrackingNumberText] = useState("");
    const [
        cancelTrackingNumberErrorMessage,
        setCancelTrackingNumberErrorMessage,
    ] = useState("");
    const [cancelLoading, setCancelLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] =
        useState(false);
    const [trackedPackagesList, setTrackedPackagesList] = useState([]);
    const [trackingPackageDetailList, setTrackingPackageDetailList] = useState([]);
    const [trackingExpand, setTrackingExpand] = useState(false);


    console.log('transcation number', transactionNumbers);

    const getTrackedPackageList = async () => {
        try {
            if (transactionNumbers?.trim() === "") {
                return;
            }

            const storedToken = getAccessToken("access_token");
            const {data, status} = await axios({
                method: "get",
                url: `${PARTNER_URI}/loose-item/3rd-party/multipleTrackingForWeb?trackingNumbers=${transactionNumbers}`,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            const trackingList = data?.filter((v) => v?.trackingResponse !== null);

            if (status === 200) {
                console.log("Success:", data);
                setTrackedPackagesList(trackingList);
            } else {
                console.log("Error:", data);
                throw new Error();
            }
        } catch (e) {
            setErrorMessage("Failed to Schedule Pickup. Try again another time.");
        }
    };

    const getTrackingPackageDetailList = async () => {
        try {
            const storedToken = getAccessToken("access_token");
            const {data} = await axios({
                method: "GET",
                url: `${PARTNER_URI}/shipping/list/getValidTrackingListByTranId/${tran_id}`,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(data);
            setTrackingPackageDetailList(data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getTrackedPackageList();
        getTrackingPackageDetailList();
    }, []);

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    //handle tracking information dialog open and close
    const handleTrackingInfo = () => {
        setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
    };

    const handleTrackingInfoClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
        }
    }

    const cancelOrderByTransactionId = async () => {
        try {
            setCancelLoading(true);
            const storedToken = getAccessToken("access_token");
            const {data} = await axios.delete(
                `${PARTNER_URI}/loose-item/3rd-party/internal/cancel-shipping`,
                {
                    headers: {Authorization: `Bearer ${storedToken}`},
                    data: {
                        trackingNumber: shipping_id_no,
                    },
                }
            );
            console.log(data);
            setCancelTrackingNumberErrorMessage("");
            setIsCancelled(true);
        } catch (e) {
            console.log(e);
            setCancelTrackingNumberErrorMessage(
                e?.response?.data?.errorMessage || "Error!"
            );
        } finally {
            setCancelLoading(false);
        }
    };

    const confirmCancelOrder = async (e) => {
        e.preventDefault();
        await cancelOrderByTransactionId();
        handleToastClick();
        handleDialogClose();
    };

    const excludeTax = +(
        +shipping_fee_exclude_tax + +service_fee_exclude_tax
    ).toFixed(2);
    const includeTax = +(
        +shipping_fee_include_tax + +service_fee_include_gst
    ).toFixed(2);

    // Cancel Tracking Order
    const onCancelOrder = () => {
        handleDialogOpen();
    };

    return (
        <>
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order"
            >
                {(() => {
                    if (cancelTrackingNumberErrorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Transaction can't be cancelled!
                                <hr/>
                                Error: {cancelTrackingNumberErrorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            Transaction has been cancelled!
                        </Alert>
                    );
                })()}
            </Snackbar>
            {/*Pop-up Toast*/}
            {/*Confirm Delete Tracking Dialog*/}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Cancel Tracking Order</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will cancel ALL the trackings related to this
                        Transaction <b>{transactionNumbers}</b>. Are you sure to cancel?
                        <hr/>
                        Click <b>Confirm</b> to cancel this shipping.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        No
                    </Button>
                    <Button
                        onClick={confirmCancelOrder}
                        variant="outlined"
                        type="button"
                        disabled={cancelLoading}
                    >
                        {cancelLoading && <CircularProgress size={14}/>}
                        {!cancelLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Confirm Delete Tracking Dialog*/}
            {/*Trakcing Information Dialog*/}
            <Dialog
                open={trackingInfoDialogIsOpen}
                onClose={handleTrackingInfoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Tracking Details</DialogTitle>
                <DialogContent>
                    {trackedPackagesList.length === 0 ? <Box>No Trackng Information Found</Box> :
                        trackedPackagesList.map((trackedPackage, index) => {
                            return (
                                <TrackPackageItem
                                    key={index}
                                    activity={
                                        trackedPackage.trackingResponse
                                            ? trackedPackage.trackingResponse.activity
                                            : undefined
                                    }
                                    trackingNo={trackedPackage.trackingNumber}
                                    courier={trackedPackage.accountBase}
                                    deliverStatus={trackedPackage.trackingResponse.status}
                                    transaction={trackedPackage.transaction}
                                />
                            );
                        })}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleTrackingInfo}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
            <TableRow hover
                      tabIndex={-1}
                      key={tran_id}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setTrackingExpand(!trackingExpand)}
                    >
                        {trackingExpand ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell align="left">
                    <Link to={`/loose-item/3rd-party/shipping/list/product/${tran_id}`}>
                        {tran_id}
                    </Link>
                </TableCell>
                {/*<TableCell align="left">*/}
                {/*  {ref_order_no}*/}
                {/*</TableCell>*/}
                <TableCell align="left">
                    {/*{`${send_to_name ? send_to_name : "N/A"} ${send_to_email ? send_to_email : "N/A"}, ${send_to_tel ? send_to_tel : "N/A"}`}*/}
                    {send_to_name ? send_to_name : "N/A"}
                    <br/>
                    {send_to_tel ? send_to_tel : "N/A"},{" "}
                    {send_to_email ? send_to_email : "N/A"}
                    <br/>
                    {send_to_addr}
                    <br/>
                    {send_to_city}, {send_to_province}, {send_to_country},{" "}
                    {send_to_postal_code}
                </TableCell>
                <TableCell align="left">
                    excl. tax:{" "}
                    <CurrencyFormat
                        value={Math.abs(excludeTax)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={
                            +excludeTax >= 0 ? `${currency_code} $` : `${currency_code} $-`
                        }
                    />
                    <br/>
                    inc. tax:{" "}
                    <CurrencyFormat
                        value={Math.abs(includeTax)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={
                            +includeTax >= 0 ? `${currency_code} $` : `${currency_code} $-`
                        }
                    />
                </TableCell>
                {/*<td>*/}
                {/*  <CurrencyFormat value={net_price} displayType={'text'} thousandSeparator={true} prefix={`${currency_code} $`} />*/}
                {/*</td>*/}
                <TableCell align="left">
                    {account_base}
                    <br/>
                    {/*<Tooltip*/}
                    {/*    title="Click to check the tracking information"*/}
                    {/*    placement="left"*/}
                    {/*>*/}
                    <span>
                {transactionNumbers}
              </span>
                    {/*</Tooltip>*/}
                    <br/>
                    <a
                        href={getLabelURL(label_pdf_uri, zip_label_uri)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {getLabelURL(label_pdf_uri, zip_label_uri) ? "Label" : ""}
                    </a>
                    &nbsp; &nbsp; &nbsp;
                    <span onClick={handleTrackingInfo} className={classes.trackingNumbers}>Trackings Info</span>
                </TableCell>
                <TableCell align="left">
                    {
                        // tran_status && <td>
                        //     {
                        //       !isCancelled ? (
                        //           <button onClick={() => {
                        //             onCancelOrder();
                        //           }}>Cancel</button>
                        //       ) : "Cancelled"
                        //     }
                        //   </td>
                        (() => {
                            if (+tran_status === 1 || +tran_status === 0) {
                                return (
                                    <button
                                        onClick={() => {
                                            onCancelOrder();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                );
                            }
                            if (+tran_status === 2) {
                                return "Delivered";
                            }
                            return "Cancelled";
                        })()
                    }
                </TableCell>
            </TableRow>
            <TableRow style={{width: "100%",}}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0,}} colSpan={12}>
                    <Collapse in={trackingExpand} timeout="auto" unmountOnExit>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Trackings
                            </Typography>
                            <Table size="small" width="100%">
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" style={{fontWeight: "bold"}}>Name</TableCell>
                                        <TableCell component="th" style={{fontWeight: "bold"}}>Tracking #</TableCell>
                                        <TableCell component="th" style={{fontWeight: "bold"}}>Length / Width / Height
                                            (Inch)</TableCell>
                                        <TableCell component="th" style={{fontWeight: "bold"}}>Weight (LB)</TableCell>
                                        <TableCell component="th" style={{fontWeight: "bold"}}>HS Code / Price Each (If
                                            applicant)</TableCell>
                                        <TableCell component="th" style={{fontWeight: "bold"}}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trackingPackageDetailList.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">
                                                {row.item_name || row?.item_description}
                                            </TableCell>
                                            <TableCell>
                                                <a href={row?.label_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${row.label_uri}` : "#"}
                                                   target="_blank" rel="noopener noreferrer">{row.tracking_no}</a>
                                            </TableCell>
                                            <TableCell>{row.length} / {row.width} / {row.height}</TableCell>
                                            <TableCell>{row.weight}</TableCell>
                                            <TableCell>
                                                {row.hs_code || "N/A"} &nbsp;
                                                / &nbsp;
                                                <CurrencyFormat value={Math.abs((+row?.unit_price || 0).toFixed(2))}
                                                                displayType={'text'} thousandSeparator={true}
                                                                prefix={+row?.unit_price >= 0 ? `${row.currency_code} $` : `${row.currency_code} $-`}/>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    (() => {
                                                        if (+row.tn_status === 1 || +row.tn_status === 0) {
                                                            return "On The Way";
                                                        }
                                                        if (+row.tn_status === 2) {
                                                            return "Delivered";
                                                        }
                                                        return "Cancelled";
                                                    })()
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
