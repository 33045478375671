import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Container from "@material-ui/core/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import {getAccessToken} from "../../utils/doToken";
import BillingCenterStyles from "./BillingCenterStyle";
import BillingSummary from "./BillingSummary";
import UnpaidInvoicesCard from "./UnpaidInvoicesCard";
import PaidInvoiceCard from "./PaidInvoiceCard";
import NotBilledOrdersCard from "./NotBilledOrdersCard";
import TopupInvoiceCard from "./TopupInvoiceCard";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {SideBar} from "../SideBar";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import logo from "../../images/logo.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {styled, useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";

function BillingCenter(props) {
    const {window} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [unpaidInvoiceList, setUnpaidInvoiceList] = useState([]);
    const [unpaidTotalChargeIncludeTax, setUnpaidTotalChargeIncludeTax] = useState(0);

    const classes = BillingCenterStyles();
    const storedToken = getAccessToken("access_token");

    const { id: partnerId } = useSelector((state) => state.user);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const getUnpaidListData = async () => {
        let requestURI = `${PARTNER_URI}/billingCenter/getNotPaidInvoiceListFromCrmForThirdParty`;

        try {
            const {data} = await axios({
                method: "get",
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log("unpaid invoice list", data.invoiceList);
            setUnpaidInvoiceList(data.invoiceList);
            setUnpaidTotalChargeIncludeTax(data.totalChargeIncludeTax);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getUnpaidListData();
    }, []);

    const drawer = (
        <div>
            <Toolbar/>
            <Divider/>
            <List>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemText primary={`My Dashboard`}/>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemText primary={`INVOICE`}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline/>
            <Container maxWidth="xl">
                {/* {Billing Dashboard  Summary Card} */}
                <BillingSummary storedToken={storedToken} classes={classes}
                                unpaidTotalChargeIncludeTax={unpaidTotalChargeIncludeTax} isForAdmin={partnerId === 1}
                />
                <Grid container spacing={2}>
                    <NotBilledOrdersCard classes={classes}/>
                    <UnpaidInvoicesCard storedToken={storedToken} classes={classes}
                                        unpaidInvoiceList={unpaidInvoiceList}/>
                    <PaidInvoiceCard classes={classes}/>
                    <TopupInvoiceCard classes={classes}/>
                </Grid>
            </Container>

            {/*</Box>*/}
        </Box>
    );
}

BillingCenter.propTypes =
    {
        /**
         * Injected by the documentation to work in an iframe.
         * You won't need it on your project.
         */
        window: PropTypes.func,
    }
;

export default BillingCenter;
