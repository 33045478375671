import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useEffect, useState} from "react";
import {
    updateShippingLTLQuickQuoteMoveFrom, updateShippingLTLQuickQuoteRemark,
    updateShippingLTLQuickQuoteSpecialService
} from "../Actions/shippingLTLAction";
import {useDispatch, useSelector} from "react-redux";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import intl from "react-intl-universal";
import {useThrottle} from "../../utils/CustomHook";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../shared/constInfo";

const styles = {
    ShippingLTLQuickQuoteSelectRateHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingLTLQuickQuoteSelectRateSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingLTLQuickQuoteAddressErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingLTLQuickQuoteSelectRateText: {
        textAlign: 'left',
        fontSize: '14px'
    }
}

export const ShippingLTLQuickQuoteAdditionalService = ({isGetRate}) => {

    const [specialService, setSpecialService] = useState([]);

    const dispatch = useDispatch();

    const addOrRemoveSpecialService = (serviceName) => {
        const hasServiceCode = specialService.some(service => service.serviceCode === serviceName);

        if (hasServiceCode) {
            // Remove the element with serviceCode 'serviceName'
            const updatedServices = specialService.filter(service => service.serviceCode !== serviceName);
            setSpecialService(updatedServices);
        } else {
            // Add the element with serviceCode 'serviceName'
            const updatedServices = [...specialService, {serviceCode: serviceName}];
            setSpecialService(updatedServices);
        }
    }

    const addSpecialService = (serviceName) => {
        const hasServiceCode = specialService.some(service => service.serviceCode === serviceName);
        if (!hasServiceCode) {
            // Add the element with serviceCode 'serviceName'
            const updatedServices = [...specialService, {serviceCode: serviceName}];
            setSpecialService(updatedServices);
        }
    }

    const handleAppointmentDelivery = () => {
        addOrRemoveSpecialService('APTFGT');
    }

    const handleProtectedService = () => {
        addOrRemoveSpecialService('PROTEC');
    }

    const handlePrivateResidenceDelivery = () => {
        addOrRemoveSpecialService('PRESDL');
        // addSpecialService('TLGDEL');
    }

    const handlePrivateResidencePickup = () => {
        addOrRemoveSpecialService('PRESPU');
        // addSpecialService('TLGPU');
    }

    const handleTailgateDelivery = () => {
        addOrRemoveSpecialService('TLGDEL');
    }

    const handleTailgatePickup = () => {
        addOrRemoveSpecialService('TLGPU');
    }

    useEffect(() => {

        if (specialService.some(service => service.serviceCode === 'PRESPU')) {
            addSpecialService('TLGPU');
        }

        if (specialService.some(service => service.serviceCode === 'PRESDL')) {
            addSpecialService('TLGDEL');
        }

        dispatch(updateShippingLTLQuickQuoteSpecialService(specialService));
    }, [specialService]);

    console.log('[ShippingLTLQuickQuoteAdditionalService] specialService', specialService);

    return (
        <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
                <Typography sx={styles.ShippingLTLQuickQuoteSelectRateHeading}>
                    {intl.get('SHIPPING_LTL_QUICK_QUOTE.ADDITIONAL_SERVICE')}
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Typography sx={styles.ShippingLTLQuickQuoteSelectRateText}>
                    {intl.get('ADDITIONAL_SERVICE.MESSAGE')}
                </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "PRESPU")}
                            onChange={handlePrivateResidencePickup}
                            disabled={isGetRate}
                        />}
                        label={
                            <Typography>
                                {intl.get('ADDITIONAL_SERVICE.PRIVATE_RESIDENCE_PICKUP')}
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "TLGPU")}
                            onChange={handleTailgatePickup}
                            disabled={isGetRate || specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE)}
                        />}
                        label={
                            <Typography>
                                {intl.get('ADDITIONAL_SERVICE.TAILGATE_PICKUP')}
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "PROTEC")}
                            onChange={handleProtectedService}
                            disabled={isGetRate}
                        />}
                        label={
                            <Typography>
                                {intl.get('ADDITIONAL_SERVICE.PROTECTED_SERVICE')}
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "PRESDL")}
                            onChange={handlePrivateResidenceDelivery}
                            disabled={isGetRate}
                        />}
                        label={
                            <Typography>
                                {intl.get('ADDITIONAL_SERVICE.PRIVATE_RESIDENCE_DELIVERY')}
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "TLGDEL")}
                            onChange={handleTailgateDelivery}
                            disabled={isGetRate || specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE)}
                        />}
                        label={
                            <Typography>
                                {intl.get('ADDITIONAL_SERVICE.TAILGATE_DELIVERY')}
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "APTFGT")}
                            onChange={handleAppointmentDelivery}
                            disabled={isGetRate}
                        />}
                        label={
                            <Typography>
                                {intl.get('ADDITIONAL_SERVICE.APPOINTMENT_DELIVERY')}
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                </Box>
            </Grid>
        </Grid>
    )
}