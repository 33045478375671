import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";

export const TransportationClaimTasksContact = ({taskDetails}) => {
    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Claimed Order Contact
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Pickup Contact
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{textAlign: 'left'}}>
                                            Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {taskDetails?.sender}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{textAlign: 'left'}}>
                                            Phone:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {taskDetails?.pickup_phone}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{textAlign: 'left'}}>
                                            Email:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {taskDetails?.pickup_email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Delivery Contact
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{textAlign: 'left'}}>
                                            Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {taskDetails?.deliver_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{textAlign: 'left'}}>
                                            Phone:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {taskDetails?.deliver_phone}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{textAlign: 'left'}}>
                                            Email:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {taskDetails?.deliver_email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}