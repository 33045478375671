import React, { useState, useEffect } from "react";
// import {createMuiTheme } from '@material-ui/core/';
import { useDispatch } from "react-redux";
import {
  // TextField,
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
  RadioGroup,
  FormControlLabel,
  Radio, FormControl, Select,
  // Chip,
} from "@material-ui/core";
// import { Paper } from '@material-ui/core'
// import orange from '@material-ui/core/colors/orange';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";

// import Fade from 'react-reveal';
import PropTypes from "prop-types";
import produce from "immer";
import { v4 as uuidv4 } from "uuid";

import CustomInput from "../../../shared/customInput";
// import {
//   // addShippingItem,
//   defaultShippingItem,
//   // updateShippingItem,
// } from "../../../slices";
import {defaultShippingItem, defaultShippingPallets} from "../../../slices/shippingSlice";
import { shipmentTypesDetails, shipmentTypes } from "../../../shared/constInfo";
import { inputChecker } from "../../../utils/inputChecker";

import { clearInitOrder } from "../../../slices/ordersSlice";
import InputLabel from "@material-ui/core/InputLabel";
import {MenuItem} from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const getTabNumber = (typeID) => {
  if (!typeID || !shipmentTypesDetails[typeID]) return 0;
  if (parseInt(typeID) < 8) return 0;
  if (shipmentTypesDetails[typeID].label === "flatbed") return 2;
  return 1;
};

const ShippingItemInput = ({
  edit = false,
  item,
  onCancel,
  errorMsg,
  setErrorMsg,
  small = false,
  guest = false,
  changeItem,
  setShippingItems,
  setEmtpyItemList,
  selectedLooseItem,
  setSelectedLooseItem,
  palletContainerLoose,
  setPalletContainerLoose,
  defaultPackageId,
  editShippingItem
}) => {
  const dispatch = useDispatch();

  const [shippingItem, setShippingItem] = useState(editShippingItem || defaultShippingPallets);

  const [weightUnitText, setWeightUnitText] = useState("LB");
  const [lengthUnitText, setLengthUnitText] = useState("IN");
  const [maxHeight, setMaxHeight] = useState(72);
  const [maxWeight, setMaxWeight] = useState(1200);

  useEffect(() => {
    handleItemChangeByValue("length")(48);
    handleItemChangeByValue("width")(48);
  }, [])

  useEffect(() => {
    if (shippingItem.weightUnit === "kg") {
      setWeightUnitText("KG");
      switch (shippingItem.packagingType) {
        case "1":
          setMaxWeight(817);
          break;
        case "2":
          setMaxWeight(817);
          break;
        case "3":
          setMaxWeight(817);
          break;
        case "4":
          setMaxWeight(1360);
          break;
        default:
          setMaxWeight(0);
      }
      return;
    }
    setWeightUnitText("LB");
    switch (shippingItem.packagingType) {
      case "1":
        setMaxWeight(1800);
        break;
      case "2":
        setMaxWeight(1800);
        break;
      case "3":
        setMaxWeight(1800);
        break;
      case "4":
        setMaxWeight(3000);
        break;
      default:
        setMaxWeight(0);
    }
  }, [shippingItem.packagingType, shippingItem.weightUnit]);

  useEffect(() => {
    if (shippingItem.lengthUnit === "cm") {
      setLengthUnitText("CM");
      switch (shippingItem.packagingType) {
        case "1":
          handleItemChangeByValue("length")(122);
          handleItemChangeByValue("width")(102);
          break;
        case "2":
          handleItemChangeByValue("length")(122);
          handleItemChangeByValue("width")(122);
          break;
        // case "3":
        //   handleItemChangeByValue("length")(122);
        //   handleItemChangeByValue("width")(122);
        //   break;
        // default:
        //   handleItemChangeByValue("length")(122);
        //   handleItemChangeByValue("width")(122);
        default:
          break;
      }
      setMaxHeight(183);
      return;
    }
    setLengthUnitText("In");
    switch (shippingItem.packagingType) {
      case "1":
        handleItemChangeByValue("length")(48);
        handleItemChangeByValue("width")(40);
        break;
      case "2":
        handleItemChangeByValue("length")(48);
        handleItemChangeByValue("width")(48);
        break;
      // case "3":
      //   handleItemChangeByValue("length")(48);
      //   handleItemChangeByValue("width")(48);
      //   break;
      // default:
      //   handleItemChangeByValue("length")(48);
      //   handleItemChangeByValue("width")(48);
      default:
        break;
    }
    if (shippingItem.packagingType !== "5") {
      setMaxHeight(72);
    } else {
      setMaxHeight(0);
    }
  }, [shippingItem.packagingType, shippingItem.lengthUnit]);

  useEffect(() => {
    console.log("Default Shipping Item: ", shippingItem);
  }, [shippingItem]);


  const [selectedType, setSelectedType] = useState(21);

  const [userInputError, setUserInputError] = useState({
    emtpyError: false,
    heightError: "",
    weightError: "",
    quantityError: "",
    lengthError: "",
    widthError: "",
    unitError: "",
    looseUnitError: ""
  });
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const [containerGroup, setContainerGroup] = useState("Normal");

  useEffect(() => {
    if (item) {
      setShippingItem(item);
      setSelectedType(item?.shipmentType || "");
      if (item?.shipmentType) {
        setValue(getTabNumber(item.shipmentType));
        setContainerGroup(
          item.shipmentType !== "16" &&
            item.shipmentType !== "17" &&
            item.shipmentType !== "18"
            ? "Normal"
            : "Small"
        );
      }
    }
  }, [item]);

  const handleRadioButtonChange = (e) => {
    setContainerGroup(e.target.value);
    setSelectedType("");
  };

  const handleChange = (event, newValue) => {
    setShippingItem(defaultShippingPallets);
    if (newValue === 2) {
      // const typeValue = "12";
      const typeValue = "21";
      setSelectedType(typeValue);
      // setShippingItem(
      //   produce((draft) => {
      //     draft.length = shipmentTypesDetails[typeValue].length;
      //     draft.width = shipmentTypesDetails[typeValue].width;
      //   })
      // );
    } else setSelectedType("");
    setValue(newValue);
  };

  // const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const emptyField =
      shippingItem.description === null ||
      shippingItem.height === null ||
      shippingItem.length === null ||
      shippingItem.width === null ||
      shippingItem.weight === null ||
      // shippingItem.quantity === null ||
      selectedType === "";

    if (!emptyField) {
      setUserInputError((prev) => {
        return { ...prev, emtpyError: false };
      });
    }

    let weightLimit;
    if (selectedType)
      weightLimit = shipmentTypesDetails[selectedType]?.max_weight || null;

    let heightLimit;
    if (selectedType)
      heightLimit = shipmentTypesDetails[selectedType]?.max_height || null;

    const hasError =
      Boolean(inputChecker(shippingItem.height, heightLimit)) ||
      Boolean(inputChecker(shippingItem.weight, weightLimit)) ||
      // Boolean(inputChecker(shippingItem.quantity)) ||
      Boolean(inputChecker(shippingItem.length)) ||
      Boolean(inputChecker(shippingItem.width));

    setUserInputError((prev) => {
      return {
        ...prev,
        heightError: inputChecker(shippingItem.height, heightLimit),
        weightError: inputChecker(shippingItem.weight, weightLimit),
        // quantityError: inputChecker(shippingItem.quantity),
        lengthError: inputChecker(shippingItem.length),
        widthError: inputChecker(shippingItem.width),
      };
    });

    if (!emptyField && !hasError) {
      setErrorMsg("");
    }
  }, [shippingItem, selectedType, setErrorMsg, setUserInputError]);

  const handleItemChangeByValue = (fieldName) => (value) => {
    setShippingItem(
        produce((draft) => {
          draft[fieldName] = value;
        })
    );
  }

  const handleItemChange = (fieldName) => (e) => {
    let value = e.target.value;
    if (fieldName === "looseUnit") {
      value = parseInt(value);
      // if (value === "" || isNaN(value) || value <= 1) {
      //   value = 1;
      // }
    }

    if (fieldName === "hazardous" || fieldName === "tempSensitive") {
      value = e.target.checked;
    }
    if (fieldName === "quantity") value = parseInt(value);
    e.preventDefault();
    setShippingItem(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };

  // const handleNameChange = (fieldName) => (value) => {
  //   setShippingItem(
  //     produce((draft) => {
  //       draft[fieldName] = value;
  //     })
  //   );
  // };

  //setSampleData({...sampleData, address: editAddress})
  const handleListItemSelect = (e) => {
    const typeValue = e.target.value;
    setSelectedType(typeValue);
    setShippingItem(
      produce((draft) => {
        draft.length = shipmentTypesDetails[typeValue].length || 48;
        draft.width = shipmentTypesDetails[typeValue].width || 48;
        if (value === 1) draft.height = shipmentTypesDetails[typeValue].height;
        // draft.weight = shipmentTypesDetails[typeValue].weight;
        draft.quantity = draft.quantity || null;
        if (typeValue === "6" || typeValue === "7") {
          draft.quantity = 1;
        }
      })
    );
  };

  // Disable the input area based on the type chosen
  const disableInput = (_selectedType, _shipmentTypesDetails, _field) => {
    if (!_selectedType) return true;
    return Boolean(_shipmentTypesDetails[_selectedType][_field]);
  };

  //Checks if pallet weight,height and num pallets is not a number and if they aren't, return some error
  //Sets the Redux dispatch pallet state
  const handlePalletData = (itemType) => {
    const emptyField =
      shippingItem.description === "" ||
      shippingItem.height === null ||
      shippingItem.length === null ||
      shippingItem.width === null ||
      shippingItem.weight === null ||
      shippingItem.looseUnit === null ||
      shippingItem.looseUnit <= 0 ||
      // shippingItem.quantity === null ||
      selectedType === "";

    console.log("Description", shippingItem.description);
    console.log("Height", shippingItem.height);
    console.log("Length", shippingItem.length);
    console.log("Width", shippingItem.width);
    console.log("Weight", shippingItem.weight);
    console.log("Loose Unit", shippingItem.looseUnit);

    if (emptyField) {

      setErrorMsg("Fields with * cannot be empty. All Numbers should be non negative");
      setUserInputError((prev) => {
        return { ...prev, emtpyError: true };
      });
    } else if (
      userInputError.heightError === "" &&
      userInputError.weightError === "" &&
      userInputError.quantityError === "" &&
      userInputError.lengthError === "" &&
      userInputError.widthError === ""
    ) {
      const newItem = {
        ...shippingItem,
        shipmentType: selectedType,
        packageID: defaultPackageId || uuidv4(),
        color: "orange",
      };
      if (edit) {
        // dispatch(updateShippingItem({ newItem }));
        // setShippingItems(
        //   produce((draft) => {
        //     const index = draft.findIndex(
        //       (item) => item.packageID === newItem.packageID
        //     );
        //     if (index !== -1) draft[index] = newItem;
        //   })
        // );
        // console.log(shippingItem);
        setShippingItems(prevItems => {
          console.log("Prev Item:", prevItems);
          const prevCopy = [...prevItems];
          for (let index = 0; index < prevCopy.length; index++) {
            console.log("index: ", prevCopy[index]);
            console.log("new item", newItem);
            if (prevCopy[index].packageID === newItem.packageID) {
              prevCopy[index] = newItem;
              console.log("Prev Copy:", prevCopy);
              return prevCopy;
            }
          }
          return prevCopy;
        });
        setErrorMsg("");
        onCancel();
      } else if (guest) {
        setErrorMsg("");
        changeItem(newItem);
      } else {
        // dispatch(addShippingItem({ newItem }));
        setShippingItems(
          produce((draft) => {
            draft.push(newItem);
          })
        );
        if (itemType === "LooseItem") {
          setSelectedLooseItem(itemType);
          setPalletContainerLoose(itemType)
        } else if (itemType === "Container") {
          setPalletContainerLoose(itemType);
        } else {
          setPalletContainerLoose(itemType);
        }

        setEmtpyItemList(false);
        setShippingItem(defaultShippingPallets);
        if (selectedType === "12") {
          const typeValue = "12";
          setSelectedType(typeValue);
          setShippingItem(
            produce((draft) => {
              draft.length = shipmentTypesDetails[typeValue].length;
              draft.width = shipmentTypesDetails[typeValue].width;
            })
          );
        }
        setErrorMsg("");
        dispatch(clearInitOrder());
      }
    }
  };

  const tabPanelDisableCondition = () => {
    switch(palletContainerLoose) {
      case "LooseItem" :
        return false;
      case "Pallet" :
        return false;
      case "Container":
        return false;
      case "All":
        return false;
      default:
        return true;
    }
  }

  // const numberExceed = (num, limit) => {
  //   if (parseInt(num) > limit) return true;
  //   return false;
  // };

  // const handleDelete = () => {
  //   console.info("You clicked the delete icon.");
  // };

  return (
    <Box>
      <Box className={classes.root} ml={1} mr={1}>
        {/* <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        > */}
        <Tabs
          value={value}
          // onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {/*<Tab label="Pallets"  {...a11yProps(0)} />*/}
          {/*<Tab label="Containers"  {...a11yProps(1)} />*/}
          <Tab label="PALLETS"  {...a11yProps(0)} />
        </Tabs>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        // heigh="100%"
        m={!guest ? "0" : "0 3vw"}
      >
        <Box>
          {/*<TabPanel value={value} index={0} disabled={selectedLooseItem}>*/}
          {/*  <Box display="flex" flexDirection="column">*/}
          {/*    <Box display="flex" flexDirection="column">*/}
          {/*      <Box*/}
          {/*        display="flex"*/}
          {/*        flexDirection="column"*/}
          {/*        width={matches ? "60vw" : "30vw"}*/}
          {/*        mr="1.5vw"*/}
          {/*        mb="2vh"*/}
          {/*        minWidth={matches ? "" : "360px"}*/}
          {/*      >*/}
          {/*        <Box pb={1} pt={1}>*/}
          {/*          <div className={classes.subTitle}>Item name *</div>*/}
          {/*          <CustomInput*/}
          {/*            // className={classes.textfield}*/}
          {/*            value={shippingItem.description}*/}
          {/*            onChange={handleItemChange("description")}*/}
          {/*            disabled={selectedLooseItem}*/}
          {/*          />*/}
          {/*          /!* <ItemSelect*/}
          {/*            value={shippingItem.description}*/}
          {/*            handleChange={handleNameChange("description")}*/}
          {/*          /> *!/*/}
          {/*        </Box>*/}
          {/*        <Box>*/}

          {/*          {selectedLooseItem ? (*/}
          {/*            <Box></Box>*/}
          {/*          ) : (*/}
          {/*            <Box>*/}
          {/*              <div className={classes.subTitle}>Packaging type *</div>*/}
          {/*              {" "}*/}
          {/*              <CustomInput*/}
          {/*                onChange={handleListItemSelect}*/}
          {/*                options={shipmentTypes.pallet}*/}
          {/*                value={selectedType}*/}
          {/*                disabled={selectedLooseItem}*/}
          {/*              />*/}
          {/*            </Box>*/}
          {/*          )}*/}
          {/*        </Box>*/}
          {/*      </Box>*/}
          {/*      <Box display="flex" flexDirection="column">*/}
          {/*        <Box display="flex" mb={1}>*/}
          {/*          <Box width={matches ? "" : "15vw"} mr={1} flexGrow="1">*/}
          {/*            <div*/}
          {/*              className={*/}
          {/*                small && matches*/}
          {/*                  ? classes.smSubTitle*/}
          {/*                  : classes.subTitle*/}
          {/*              }*/}
          {/*            >*/}
          {/*              Length **/}
          {/*            </div>*/}
          {/*            <CustomInput*/}
          {/*              // className={classes.textfield}*/}
          {/*              value={shippingItem.length}*/}
          {/*              onChange={handleItemChange("length")}*/}
          {/*              disabled={disableInput(*/}
          {/*                selectedType,*/}
          {/*                shipmentTypesDetails,*/}
          {/*                "length"*/}
          {/*              )}*/}
          {/*              type="size"*/}
          {/*            />*/}
          {/*          </Box>*/}
          {/*          <Box width={matches ? "" : "15vw"} flexGrow="1">*/}
          {/*            <div*/}
          {/*              className={*/}
          {/*                small && matches*/}
          {/*                  ? classes.smSubTitle*/}
          {/*                  : classes.subTitle*/}
          {/*              }*/}
          {/*            >*/}
          {/*              Width **/}
          {/*            </div>*/}
          {/*            <CustomInput*/}
          {/*              value={shippingItem.width}*/}
          {/*              onChange={handleItemChange("width")}*/}
          {/*              disabled={disableInput(*/}
          {/*                selectedType,*/}
          {/*                shipmentTypesDetails,*/}
          {/*                "width"*/}
          {/*              )}*/}
          {/*              type="size"*/}
          {/*            />*/}
          {/*          </Box>*/}
          {/*        </Box>*/}
          {/*        <Box display="flex">*/}
          {/*          <Box width={matches ? "" : "15vw"} mr={1} flexGrow="1">*/}
          {/*            <div*/}
          {/*              className={*/}
          {/*                small && matches*/}
          {/*                  ? classes.smSubTitle*/}
          {/*                  : classes.subTitle*/}
          {/*              }*/}
          {/*            >*/}
          {/*              Height **/}
          {/*            </div>*/}
          {/*            <CustomInput*/}
          {/*              value={shippingItem.height}*/}
          {/*              onChange={handleItemChange("height")}*/}
          {/*              // disabled={disableInput(selectedType, shipmentTypesDetails, 'height')}*/}
          {/*              disabled={!selectedType}*/}
          {/*              error={*/}
          {/*                userInputError.heightError ||*/}
          {/*                (userInputError.emtpyError &&*/}
          {/*                  shippingItem.height === null)*/}
          {/*              }*/}
          {/*              onKeyDown={(evt) => {*/}
          {/*                (evt.key === "e" ||*/}
          {/*                  evt.key === "-" ||*/}
          {/*                  evt.key === "E") &&*/}
          {/*                  evt.preventDefault();*/}
          {/*              }}*/}
          {/*              type="size"*/}
          {/*              placeholderValue={*/}
          {/*                selectedType && parseInt(selectedType) < 5*/}
          {/*                  ? `Maximum: 72`*/}
          {/*                  : ""*/}
          {/*              }*/}
          {/*            />*/}
          {/*            <Box*/}
          {/*              pl={1}*/}
          {/*              color="red"*/}
          {/*              visibility={*/}
          {/*                userInputError.heightError ? "visible" : "hidden"*/}
          {/*              }*/}
          {/*            >*/}
          {/*              {userInputError.heightError}*/}
          {/*            </Box>*/}
          {/*          </Box>*/}
          {/*          <Box width={matches ? "" : "15vw"} flexGrow="1">*/}
          {/*            <div*/}
          {/*              className={*/}
          {/*                small && matches*/}
          {/*                  ? classes.smSubTitle*/}
          {/*                  : classes.subTitle*/}
          {/*              }*/}
          {/*            >*/}
          {/*              Weight **/}
          {/*            </div>*/}
          {/*            <CustomInput*/}
          {/*              // className={classes.textfield}*/}
          {/*              value={shippingItem.weight}*/}
          {/*              onChange={handleItemChange("weight")}*/}
          {/*              // disabled={disableInput(selectedType, shipmentTypesDetails, 'weight')}*/}
          {/*              disabled={!selectedType}*/}
          {/*              error={*/}
          {/*                userInputError.weightError ||*/}
          {/*                (userInputError.emtpyError &&*/}
          {/*                  shippingItem.weight === null)*/}
          {/*              }*/}
          {/*              type="weight"*/}
          {/*              weightLabel="lbs"*/}
          {/*              onKeyDown={(evt) => {*/}
          {/*                (evt.key === "e" ||*/}
          {/*                  evt.key === "-" ||*/}
          {/*                  evt.key === "E") &&*/}
          {/*                  evt.preventDefault();*/}
          {/*              }}*/}
          {/*              placeholderValue={*/}
          {/*                selectedType && parseInt(selectedType) < 5*/}
          {/*                  ? `Maximum: ${*/}
          {/*                      selectedType === "4" ? "3000" : "1200"*/}
          {/*                    }`*/}
          {/*                  : ""*/}
          {/*              }*/}
          {/*            />*/}
          {/*            <Box*/}
          {/*              pl={1}*/}
          {/*              color="red"*/}
          {/*              visibility={*/}
          {/*                userInputError.weightError ? "visible" : "hidden"*/}
          {/*              }*/}
          {/*            >*/}
          {/*              {userInputError.weightError}*/}
          {/*            </Box>*/}
          {/*          </Box>*/}
          {/*        </Box>*/}
          {/*      </Box>*/}
          {/*    </Box>*/}
          {/*    <Box*/}
          {/*      width={matches ? "60vw" : "21vw"}*/}
          {/*      mb={2}*/}
          {/*      mt={1}*/}
          {/*      minWidth={matches ? "" : "360px"}*/}
          {/*    >*/}
          {/*      <div className={classes.subTitle}>*/}
          {/*        {selectedType === "6" || selectedType === "7"*/}
          {/*          ? "Number of units *"*/}
          {/*          : "Number of pallets *"}*/}
          {/*      </div>*/}
          {/*      <CustomInput*/}
          {/*        // className={classes.textfield}*/}
          {/*        value={shippingItem.quantity}*/}
          {/*        onChange={handleItemChange("quantity")}*/}
          {/*        disabled={*/}
          {/*          selectedType === "6" || selectedType === "7" || selectedLooseItem*/}
          {/*        }*/}
          {/*        type="number"*/}
          {/*      />*/}
          {/*    </Box>*/}
          {/*    <Box width="100%">*/}
          {/*      {errorMsg ? (*/}
          {/*        <Alert severity="error" classes={{ root: classes.alertRoot }}>*/}
          {/*          {errorMsg}*/}
          {/*        </Alert>*/}
          {/*      ) : (*/}
          {/*        ""*/}
          {/*      )}*/}
          {/*    </Box>*/}
          {/*    <Box mb={2} mt={2} display="flex" justifyContent="center">*/}
          {/*      {edit && (*/}
          {/*        <Box mr={2}>*/}
          {/*          <Button*/}
          {/*            variant="outlined"*/}
          {/*            color="default"*/}
          {/*            onClick={onCancel}*/}
          {/*          >*/}
          {/*            CANCEL*/}
          {/*          </Button>*/}
          {/*        </Box>*/}
          {/*      )}*/}

          {/*      <Box ml={2}>*/}
          {/*        <Button*/}
          {/*          variant="contained"*/}
          {/*          color="primary"*/}
          {/*          onClick={() => handlePalletData("Pallet")}*/}
          {/*          style={{*/}
          {/*            textTransform: "unset",*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          {edit || guest ? "UPDATE" : "Add to the list"}*/}
          {/*        </Button>*/}
          {/*      </Box>*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*</TabPanel>*/}

          {/*<TabPanel value={value} index={1} disable={selectedLooseItem}>*/}
          {/*  <Box display="flex" flexDirection="column">*/}
          {/*    <Box display="flex" flexDirection="column" mb={2}>*/}
          {/*      <Box*/}
          {/*        display="flex"*/}
          {/*        flexDirection="column"*/}
          {/*        width={matches ? "60vw" : "30vw"}*/}
          {/*        mr="1.5vw"*/}
          {/*        mb="2vh"*/}
          {/*        minWidth={matches ? "" : "360px"}*/}
          {/*      >*/}
          {/*        <Box mt={1}>*/}
          {/*          <RadioGroup*/}
          {/*            row*/}
          {/*            aria-label="role"*/}
          {/*            name="role"*/}
          {/*            value={containerGroup}*/}
          {/*            onChange={handleRadioButtonChange}*/}
          {/*          >*/}
          {/*            <FormControlLabel*/}
          {/*              value="Normal"*/}
          {/*              control={<Radio color="primary" />}*/}
          {/*              label="Normal"*/}
          {/*            />*/}
          {/*            <FormControlLabel*/}
          {/*              value="Small"*/}
          {/*              control={<Radio color="primary" />}*/}
          {/*              label="Small"*/}
          {/*            />*/}
          {/*          </RadioGroup>*/}
          {/*        </Box>*/}
          {/*        <Box pb={1} pt={1}>*/}
          {/*          <div className={classes.subTitle}>Item name *</div>*/}
          {/*          <CustomInput*/}
          {/*            // className={classes.textfield}*/}
          {/*            value={shippingItem.description}*/}
          {/*            onChange={handleItemChange("description")}*/}
          {/*          />*/}
          {/*        </Box>*/}
          {/*        <Box>*/}
          {/*          <div className={classes.subTitle}>Container type *</div>*/}
          {/*          <CustomInput*/}
          {/*            onChange={handleListItemSelect}*/}
          {/*            options={*/}
          {/*              containerGroup === "Normal"*/}
          {/*                ? shipmentTypes.container*/}
          {/*                : shipmentTypes.smallContainer*/}
          {/*            }*/}
          {/*            value={selectedType}*/}
          {/*          />*/}
          {/*        </Box>*/}
          {/*      </Box>*/}
          {/*      <Box display="flex" flexDirection="column">*/}
          {/*        <Box display="flex" mb={1}>*/}
          {/*          <Box width={matches ? "" : "15vw"} mr={1} flexGrow="1">*/}
          {/*            <div*/}
          {/*              className={*/}
          {/*                small && matches*/}
          {/*                  ? classes.smSubTitle*/}
          {/*                  : classes.subTitle*/}
          {/*              }*/}
          {/*            >*/}
          {/*              Length **/}
          {/*            </div>*/}
          {/*            <CustomInput*/}
          {/*              // className={classes.textfield}*/}
          {/*              value={shippingItem.length}*/}
          {/*              onChange={handleItemChange("length")}*/}
          {/*              disabled={disableInput(*/}
          {/*                selectedType,*/}
          {/*                shipmentTypesDetails,*/}
          {/*                "length"*/}
          {/*              )}*/}
          {/*              type="bigSize"*/}
          {/*            />*/}
          {/*          </Box>*/}
          {/*          <Box width={matches ? "" : "15vw"} flexGrow="1">*/}
          {/*            <div*/}
          {/*              className={*/}
          {/*                small && matches*/}
          {/*                  ? classes.smSubTitle*/}
          {/*                  : classes.subTitle*/}
          {/*              }*/}
          {/*            >*/}
          {/*              Width **/}
          {/*            </div>*/}
          {/*            <CustomInput*/}
          {/*              value={shippingItem.width}*/}
          {/*              onChange={handleItemChange("width")}*/}
          {/*              disabled={disableInput(*/}
          {/*                selectedType,*/}
          {/*                shipmentTypesDetails,*/}
          {/*                "width"*/}
          {/*              )}*/}
          {/*              type="bigSize"*/}
          {/*            />*/}
          {/*          </Box>*/}
          {/*        </Box>*/}
          {/*        <Box display="flex">*/}
          {/*          <Box width={matches ? "" : "15vw"} mr={1} flexGrow="1">*/}
          {/*            <div*/}
          {/*              className={*/}
          {/*                small && matches*/}
          {/*                  ? classes.smSubTitle*/}
          {/*                  : classes.subTitle*/}
          {/*              }*/}
          {/*            >*/}
          {/*              Height **/}
          {/*            </div>*/}
          {/*            <CustomInput*/}
          {/*              value={shippingItem.height}*/}
          {/*              onChange={handleItemChange("height")}*/}
          {/*              // disabled={disableInput(selectedType, shipmentTypesDetails, 'height')}*/}
          {/*              disabled={disableInput(*/}
          {/*                selectedType,*/}
          {/*                shipmentTypesDetails,*/}
          {/*                "height"*/}
          {/*              )}*/}
          {/*              error={*/}
          {/*                userInputError.heightError ||*/}
          {/*                (userInputError.emtpyError &&*/}
          {/*                  shippingItem.height === null)*/}
          {/*              }*/}
          {/*              onKeyDown={(evt) => {*/}
          {/*                (evt.key === "e" ||*/}
          {/*                  evt.key === "-" ||*/}
          {/*                  evt.key === "E") &&*/}
          {/*                  evt.preventDefault();*/}
          {/*              }}*/}
          {/*              type="bigSize"*/}
          {/*              placeholderValue={*/}
          {/*                selectedType &&*/}
          {/*                shipmentTypesDetails[selectedType].max_height*/}
          {/*                  ? `Maximum: ${shipmentTypesDetails[selectedType].max_height}`*/}
          {/*                  : ""*/}
          {/*              }*/}
          {/*            />*/}
          {/*            <Box*/}
          {/*              pl={1}*/}
          {/*              color="red"*/}
          {/*              visibility={*/}
          {/*                userInputError.heightError ? "visible" : "hidden"*/}
          {/*              }*/}
          {/*            >*/}
          {/*              {userInputError.heightError}*/}
          {/*            </Box>*/}
          {/*          </Box>*/}
          {/*          <Box width={matches ? "" : "15vw"} flexGrow="1">*/}
          {/*            <div*/}
          {/*              className={*/}
          {/*                small && matches*/}
          {/*                  ? classes.smSubTitle*/}
          {/*                  : classes.subTitle*/}
          {/*              }*/}
          {/*            >*/}
          {/*              Weight **/}
          {/*            </div>*/}
          {/*            <CustomInput*/}
          {/*              // className={classes.textfield}*/}
          {/*              value={shippingItem.weight}*/}
          {/*              onChange={handleItemChange("weight")}*/}
          {/*              // disabled={disableInput(selectedType, shipmentTypesDetails, 'weight')}*/}
          {/*              disabled={!selectedType}*/}
          {/*              error={*/}
          {/*                userInputError.weightError ||*/}
          {/*                (userInputError.emtpyError &&*/}
          {/*                  shippingItem.weight === null)*/}
          {/*              }*/}
          {/*              type="weight"*/}
          {/*              weightLabel={*/}
          {/*                containerGroup === "Normal" ? "tons" : "lbs"*/}
          {/*              }*/}
          {/*              onKeyDown={(evt) => {*/}
          {/*                (evt.key === "e" ||*/}
          {/*                  evt.key === "-" ||*/}
          {/*                  evt.key === "E") &&*/}
          {/*                  evt.preventDefault();*/}
          {/*              }}*/}
          {/*              placeholderValue={*/}
          {/*                selectedType &&*/}
          {/*                shipmentTypesDetails[selectedType].max_weight*/}
          {/*                  ? `Maximum: ${shipmentTypesDetails[selectedType].max_weight}`*/}
          {/*                  : ""*/}
          {/*              }*/}
          {/*            />*/}
          {/*            <Box*/}
          {/*              pl={1}*/}
          {/*              color="red"*/}
          {/*              visibility={*/}
          {/*                userInputError.weightError ? "visible" : "hidden"*/}
          {/*              }*/}
          {/*            >*/}
          {/*              {userInputError.weightError}*/}
          {/*            </Box>*/}
          {/*          </Box>*/}
          {/*        </Box>*/}
          {/*      </Box>*/}
          {/*    </Box>*/}
          {/*    <Box*/}
          {/*      width={matches ? "60vw" : "21vw"}*/}
          {/*      mb={2}*/}
          {/*      mt={1}*/}
          {/*      minWidth={matches ? "" : "360px"}*/}
          {/*    >*/}
          {/*      <div className={classes.subTitle}>Number of units *</div>*/}
          {/*      <CustomInput*/}
          {/*        // className={classes.textfield}*/}
          {/*        value={shippingItem.quantity}*/}
          {/*        onChange={handleItemChange("quantity")}*/}
          {/*        disabled={selectedType === "6" || selectedType === "7"}*/}
          {/*        type="number"*/}
          {/*        defaultValue={1}*/}
          {/*      />*/}
          {/*    </Box>*/}
          {/*    <Box width="100%">*/}
          {/*      {errorMsg ? (*/}
          {/*        <Alert severity="error" classes={{ root: classes.alertRoot }}>*/}
          {/*          {errorMsg}*/}
          {/*        </Alert>*/}
          {/*      ) : (*/}
          {/*        ""*/}
          {/*      )}*/}
          {/*    </Box>*/}
          {/*    <Box mb={2} mt={2} display="flex" justifyContent="center">*/}
          {/*      {edit && (*/}
          {/*        <Box mr={2}>*/}
          {/*          <Button*/}
          {/*            variant="outlined"*/}
          {/*            color="default"*/}
          {/*            onClick={onCancel}*/}
          {/*          >*/}
          {/*            CANCEL*/}
          {/*          </Button>*/}
          {/*        </Box>*/}
          {/*      )}*/}

          {/*      <Box ml={2}>*/}
          {/*        <Button*/}
          {/*          variant="contained"*/}
          {/*          color="primary"*/}
          {/*          onClick={() => handlePalletData("Container")}*/}
          {/*        >*/}
          {/*          {edit || guest ? "UPDATE" : "ADD"}*/}
          {/*        </Button>*/}
          {/*      </Box>*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*</TabPanel>*/}

          <TabPanel value={value} index={0}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column" pb={1} mt={1}>
                  <Box display="flex">
                    <Box minWidth={"18vw"} mr={1} flexGrow="1">
                      <div className={
                        small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                      }>Item name / SKU *</div>
                      <CustomInput
                          // className={classes.textfield}
                          value={shippingItem.description}
                          onChange={handleItemChange("description")}
                      />
                    </Box>
                    <Box minWidth={"18vw"} flexGrow="1" >
                      <div
                          className={
                            small && matches
                                ? classes.smSubTitle
                                : classes.subTitle
                          }
                      >
                        Quantity *
                      </div>
                      <CustomInput
                          value={shippingItem.looseUnit}
                          onChange={handleItemChange("looseUnit")}
                          // disabled={disableInput(selectedType, shipmentTypesDetails, 'height')}
                          // disabled={!selectedType}
                          error={
                              userInputError.looseUnitError ||
                              (userInputError.emtpyError &&
                                  shippingItem.looseUnit <= 0)
                          }
                          onKeyDown={(evt) => {
                            (evt.key === "e" ||
                                evt.key === "-" ||
                                evt.key === "E") &&
                            evt.preventDefault();
                          }}
                          inputProps={{ min: 0 }}
                          type="number"
                          placeholderValue={
                            selectedType &&
                            shipmentTypesDetails[selectedType].max_height
                                ? `Maximum: ${shipmentTypesDetails[selectedType].max_height}`
                                : ""
                          }
                      />
                      <Box
                          pl={1}
                          color="red"
                          visibility={
                            userInputError.heightError ? "visible" : "hidden"
                          }
                      >
                        {userInputError.heightError}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <div className={classes.subTitle}>Packaging type *</div>{" "}
                    {/*<CustomInput*/}
                    {/*    // onChange={handleListItemSelect}*/}
                    {/*    options={shipmentTypes.pallet}*/}
                    {/*    value={shippingItem.packagingType}*/}
                    {/*    // disabled={selectedLooseItem}*/}
                    {/*    type="select"*/}
                    {/*    onChange={() => {}}*/}
                    {/*/>*/}
                    <Select
                        fullWidth
                        value={shippingItem.packagingType}
                        label="Unit"
                        onChange={handleItemChange("packagingType")}
                        // className={classes.textfield}
                        id="outlined-basic" className={classes.selectField}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      <MenuItem value="1">48" x 40"</MenuItem>
                      <MenuItem value="2">48" x 48"</MenuItem>
                      <MenuItem value="3">Oversize</MenuItem>
                      <MenuItem value="4">Overweight</MenuItem>
                      <MenuItem value="5">Custom</MenuItem>
                    </Select>
                  </Box>
                  <Box display="flex" mb={1}>
                    <Box width={"10vw"} mr={1} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                        style={{ visibility: "hidden" }}
                      >
                        {/* Length */}
                        Dimensions (L&#215;W&#215;H) *
                      </div>
                      <CustomInput
                        // className={classes.textfield}
                        value={shippingItem.length}
                        onChange={handleItemChange("length")}
                        // disabled={disableInput(
                        //   selectedType,
                        //   shipmentTypesDetails,
                        //   "length"
                        // )}
                          placeholderValue="Length"
                        type="weight"
                        disabled={shippingItem.packagingType === "1" || shippingItem.packagingType === "2"}
                      />
                    </Box>
                    <Box width={"10vw"} mr={1} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                      >
                        {/*Width ({lengthUnitText}) **/}
                        Dimensions (L&#215;W&#215;H)*
                      </div>
                      <CustomInput
                        value={shippingItem.width}
                        onChange={handleItemChange("width")}
                        // disabled={disableInput(
                        //   selectedType,
                        //   shipmentTypesDetails,
                        //   "width"
                        // )}
                          placeholderValue="Width"
                        type="weight"
                        disabled={shippingItem.packagingType === "1" || shippingItem.packagingType === "2"}
                        className={classes.dimensionsStyle}
                      />
                    </Box>
                    <Box width={"10vw"} mr={1} flexGrow="1">
                      <div
                          className={
                            small && matches
                                ? classes.smSubTitle
                                : classes.subTitle
                          }
                          style={{ visibility: "hidden" }}
                      >
                        {/* Height */}
                        Dimensions (L&#215;W&#215;H)*
                      </div>
                      <CustomInput
                          value={shippingItem.height}
                          onChange={handleItemChange("height")}
                          // disabled={disableInput(selectedType, shipmentTypesDetails, 'height')}
                          // disabled={!selectedType}
                          error={
                              userInputError.heightError ||
                              (userInputError.emtpyError &&
                                  shippingItem.height === null)
                          }
                          onKeyDown={(evt) => {
                            (evt.key === "e" ||
                                evt.key === "-" ||
                                evt.key === "E") &&
                            evt.preventDefault();
                          }}
                          type="weight"
                          placeholderValue={`Height${maxHeight !== 0 ? ` (Maximum ${maxHeight})` : ""}`}
                      />
                      <Box
                          pl={1}
                          color="red"
                          visibility={
                            userInputError.heightError ? "visible" : "hidden"
                          }
                      >
                        {userInputError.heightError}
                      </Box>
                    </Box>
                    <Box width={"5vw"} flexGrow="1" display="flex">
                        <Box display="flex" flexDirection="column" justifyContent="space-evenly">
                          <div
                              className={
                                small && matches
                                    ? classes.smSubTitle
                                    : classes.subTitle
                              }
                              style={{ visibility: "hidden" }}
                          >
                            {/*Length Unit*/}
                            Dimensions (L&#215;W&#215;H)*
                          </div>
                          <Select
                              value={shippingItem.lengthUnit}
                              label="Unit"
                              onChange={handleItemChange("lengthUnit")}
                              className={classes.textfield}
                              classes={{ root: classes.selectRoot }}
                              MenuProps={{ classes: { paper: classes.menuPaper } }}
                          >
                            <MenuItem value="cm">CM</MenuItem>
                            <MenuItem value="in">IN</MenuItem>
                          </Select>
                        </Box>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box width={"35vw"} mr={1} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                      >
                        Weight *
                      </div>
                      <CustomInput
                        // className={classes.textfield}
                        value={shippingItem.weight}
                        onChange={handleItemChange("weight")}
                        // disabled={!selectedType}
                        error={
                          userInputError.weightError ||
                          (userInputError.emtpyError &&
                            shippingItem.weight === null)
                        }
                        type="weight"
                        weightLabel="kg"
                        onKeyDown={(evt) => {
                          (evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "E") &&
                            evt.preventDefault();
                        }}
                        placeholderValue={`Weight${maxWeight !== 0 ? ` (Maximum ${maxWeight})` : ""}`}
                      />
                      <Box
                        pl={1}
                        color="red"
                        visibility={
                          userInputError.weightError ? "visible" : "hidden"
                        }
                      >
                        {userInputError.weightError}
                      </Box>
                    </Box>
                    <Box width={"5vw"} flexGrow="1" display="flex">
                      <Box display="flex" flexDirection="column" justifyContent="space-evenly">
                        <div
                            className={
                              small && matches
                                  ? classes.smSubTitle
                                  : classes.subTitle
                            }
                            style={{ visibility: "hidden" }}
                        >
                          {/*Weight Unit*/}
                          Dimensions (L&#215;W&#215;H)*
                        </div>
                        <Select
                            value={shippingItem.weightUnit}
                            label="Unit"
                            onChange={handleItemChange("weightUnit")}
                            className={classes.textfield}
                            classes={{ root: classes.selectRoot }}
                            MenuProps={{ classes: { paper: classes.menuPaper } }}
                        >
                          <MenuItem value="kg">KG</MenuItem>
                          <MenuItem value="lb">LB</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Box>

                </Box>
              </Box>
              <Box
                width={matches ? "60vw" : "21vw"}
                mb={2}
                mt={1}
                minWidth={matches ? "" : "360px"}
                style={{ textAlign: "left" }}
              >
                <div className={classes.subTitle}>Volume</div>
                {/* <CustomInput
                  // className={classes.textfield}
                  value={shippingItem.quantity}
                  onChange={handleItemChange("quantity")}
                  disabled={selectedType === "6" || selectedType === "7"}
                  type="number"
                /> */}
                {
                  (() => {
                    let calculateVolume = shippingItem.height * shippingItem.width * shippingItem.length * shippingItem.looseUnit;
                    if (isNaN(calculateVolume)) {
                      calculateVolume = 0;
                    }
                    if (lengthUnitText === "CM") {
                      if (calculateVolume >= 1e6) {
                        return (
                            <span>{(calculateVolume * 0.000001).toFixed(1)} m<sup>3</sup></span>
                        )
                      }
                      return <span>{calculateVolume.toFixed(1)} cm<sup>3</sup></span>
                    }
                    if (calculateVolume >= 1728) {
                      return (
                          <span>{(calculateVolume * 0.000578704).toFixed(1)} foot<sup>3</sup></span>
                      )
                    }
                    return <span>{calculateVolume.toFixed(1)} inch<sup>3</sup></span>
                  })()
                }
              </Box>
              <Box width="100%">
                {errorMsg ? (
                  <Alert severity="error" classes={{ root: classes.alertRoot }}>
                    {errorMsg}
                  </Alert>
                ) : (
                  ""
                )}
              </Box>
              <Box mb={2} mt={2} display="flex" justifyContent="center">
                {edit && (
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      color="default"
                      onClick={onCancel}
                    >
                      CANCEL
                    </Button>
                  </Box>
                )}

                <Box ml={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePalletData("LooseItem")}
                  >
                    {edit || guest ? "UPDATE" : "ADD"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
  selectField: {
      width: "100%",
      backgroundColor: "white",
      borderRadius: "5px",
      textAlign: "left"
      // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
      // paddingTop: "0.4vw",
      // paddingBottom: "0.4vw",
  },
  dimensionsStyle: {
    backgroundColor: '#f0f0f0 !important',
  },
  // textfield: {
  //   width: "100%",
  //   backgroundColor: "white",
  //   borderRadius: "5px",
  //   // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
  //   // paddingTop: "0.4vw",
  //   // paddingBottom: "0.4vw",
  // },
  // selectRoot: {
  //   paddingLeft: theme.spacing(1),
  //   paddingTop: theme.spacing(1),
  //   paddingBottom: theme.spacing(1),
  // },
  menuPaper: {
    maxWidth: 150
  },
  input: {
    color: "grey",
    width: "90%",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
  smSubTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Montserrat",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
}));

ShippingItemInput.propTypes = {
  item: PropTypes.object,
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  small: PropTypes.bool,
  guest: PropTypes.bool,
  changeItem: PropTypes.func,
  setShippingItems: PropTypes.func,
};

export default ShippingItemInput;

{
  /* <TabPanel value={value} index={2}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="column"
                  width={matches ? "60vw" : "30vw"}
                  mr="1.5vw"
                  mb="2vh"
                  minWidth={matches ? "" : "360px"}
                >
                  <Box textAlign="left" fontSize="1.2rem" mt={1}>
                    <Box component="span" fontWeight="600" color="darkgreen">
                      Type:{" "}
                    </Box>
                    Standard flatbed
                  </Box>
                  <Box pb={1} pt={1}>
                    <div className={classes.subTitle}>Item name *</div>
                    <CustomInput
                      // className={classes.textfield}
                      value={shippingItem.description}
                      onChange={handleItemChange("description")}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" mb={1}>
                    <Box width={matches ? "" : "15vw"} mr={1} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                      >
                        Length *
                      </div>
                      <CustomInput
                        // className={classes.textfield}
                        value={shippingItem.length}
                        onChange={handleItemChange("length")}
                        disabled={disableInput(
                          selectedType,
                          shipmentTypesDetails,
                          "length"
                        )}
                        type="bigSize"
                      />
                    </Box>
                    <Box width={matches ? "" : "15vw"} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                      >
                        Width *
                      </div>
                      <CustomInput
                        value={shippingItem.width}
                        onChange={handleItemChange("width")}
                        disabled={disableInput(
                          selectedType,
                          shipmentTypesDetails,
                          "width"
                        )}
                        type="bigSize"
                      />
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box width={matches ? "" : "15vw"} mr={1} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                      >
                        Height *
                      </div>
                      <CustomInput
                        value={shippingItem.height}
                        onChange={handleItemChange("height")}
                        // disabled={disableInput(selectedType, shipmentTypesDetails, 'height')}
                        disabled={!selectedType}
                        error={
                          userInputError.heightError ||
                          (userInputError.emtpyError &&
                            shippingItem.height === null)
                        }
                        onKeyDown={(evt) => {
                          (evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "E") &&
                            evt.preventDefault();
                        }}
                        type="bigSize"
                        placeholderValue={
                          selectedType &&
                          shipmentTypesDetails[selectedType].max_height
                            ? `Maximum: ${shipmentTypesDetails[selectedType].max_height}`
                            : ""
                        }
                      />
                      <Box
                        pl={1}
                        color="red"
                        visibility={
                          userInputError.heightError ? "visible" : "hidden"
                        }
                      >
                        {userInputError.heightError}
                      </Box>
                    </Box>
                    <Box width={matches ? "" : "15vw"} flexGrow="1">
                      <div
                        className={
                          small && matches
                            ? classes.smSubTitle
                            : classes.subTitle
                        }
                      >
                        Weight *
                      </div>
                      <CustomInput
                        // className={classes.textfield}
                        value={shippingItem.weight}
                        onChange={handleItemChange("weight")}
                        disabled={!selectedType}
                        error={
                          userInputError.weightError ||
                          (userInputError.emtpyError &&
                            shippingItem.weight === null)
                        }
                        type="weight"
                        weightLabel="tons"
                        onKeyDown={(evt) => {
                          (evt.key === "e" ||
                            evt.key === "-" ||
                            evt.key === "E") &&
                            evt.preventDefault();
                        }}
                        placeholderValue={
                          selectedType &&
                          shipmentTypesDetails[selectedType].max_weight
                            ? `Maximum: ${shipmentTypesDetails[selectedType].max_weight}`
                            : ""
                        }
                      />
                      <Box
                        pl={1}
                        color="red"
                        visibility={
                          userInputError.weightError ? "visible" : "hidden"
                        }
                      >
                        {userInputError.weightError}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                width={matches ? "60vw" : "21vw"}
                mb={2}
                mt={1}
                minWidth={matches ? "" : "360px"}
              >
                <div className={classes.subTitle}>Number of units *</div>
                <CustomInput
                  // className={classes.textfield}
                  value={shippingItem.quantity}
                  onChange={handleItemChange("quantity")}
                  disabled={selectedType === "6" || selectedType === "7"}
                  type="number"
                />
              </Box>
              <Box width="100%">
                {errorMsg ? (
                  <Alert severity="error" classes={{ root: classes.alertRoot }}>
                    {errorMsg}
                  </Alert>
                ) : (
                  ""
                )}
              </Box>
              <Box mb={2} mt={2} display="flex" justifyContent="center">
                {edit && (
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      color="default"
                      onClick={onCancel}
                    >
                      CANCEL
                    </Button>
                  </Box>
                )}

                <Box ml={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePalletData}
                  >
                    {edit || guest ? "UPDATE" : "ADD"}
                  </Button>
                </Box>
              </Box>
            </Box> */
}
