//To-do move all the API calls in this file
// export const BACKEND_ROUTE_URI = "https://admin.uucargo.ca/api";

export const BACKEND_ROUTE_URI = "https://api.uucargo.ca/api";

export const NODE_API =
  process.env.NODE_ENV === "production"
    ? "https://api.uucargo.ca/v2"
    : "http://localhost:9000";

export const PARTNER_URI =
  process.env.NODE_ENV === "production"
    ? "https://partner.uucargo.ca/api"
    : "http://localhost:9001/api";

export const SERVICE_SERVICE_URI =
    process.env.NODE_ENV === "production"
    ? "https://api.uucargo.ca/service/service"
    : "http://localhost:9003/service/service";

// Check login state
// export const BACKEND_LOGIN_STATE = `${BACKEND_ROUTE_URI}/loginstate`;

// Upload file API
export const UPLOAD_CSV_FILE = `${NODE_API}/cargo_control_tracking/upload`;
export const UPLOAD_XLSX_FILE = `${NODE_API}/bol/upload-bol`;

//shipment management
export const SEARCH_SHIPMENT =
  process.env.NODE_ENV === "production"
    ? `${NODE_API}/shipment_management/search`
    : `${NODE_API}/shipment_management/search`;

//HS Code
export const GET_HSCODE_SECTIONS = `${NODE_API}/hscode/sections`;
export const GET_HSCODE_DETAIL = `${NODE_API}/hscode/hscodedetails/hscode`;
export const UPDATE_HSCODE_SECTION = `${NODE_API}/hscode/sections/update`;
export const UPDATE_HSCODE_DETAIL = `${NODE_API}/hscode/hscodedetails/update`;
export const INIT_ELASTIC_SEARCH = `${PARTNER_URI}/elastic-search/init`;
export const TRAIN_HSCODE = `${PARTNER_URI}/hscode/hscodedetails/tags/training`;
export const GET_TAGS_BY_PARTNER_ID_HS_CODE = `${PARTNER_URI}/hscode/hscodedetails/tags/getTags`;

// Bol List Code
export const BOL_LIST_ALL = `${NODE_API}/bol/bol-ref`;
// Bol update bol ref record status
export const BOL_REF_STATUS_UPDATE = `${NODE_API}/bol/update-bol-status`;

export const BOL_REF_NUMBER_UPDATE = `${NODE_API}/bol/update-bol-number`;

// Bol details
export const BOL_DETAILS = `${NODE_API}/bol/bol-details-be`;
// Search order API
export const SEARCH_ORDER = `${NODE_API}/order_management/list`;

// Get rating  by uploading the Xlsx file
// export const UPLOAD_RATING_XLSX_FILE = `${NODE_API}/api/freight-forwarding/test`;
// export const UPLOAD_SHIPPING_XLSX_FILE = `${NODE_API}/api/freight-forwarding/test_2`;

export const UPLOAD_RATING_XLSX_FILE = `${PARTNER_URI}/loose-item/3rd-party/excel/rates`;

export const UPLOAD_SHIPPING_XLSX_FILE = `${PARTNER_URI}/loose-item/3rd-party/excel/shipping`;

export const UPLOAD_SAMPLE_LABEL_XLSX_FILE = `${PARTNER_URI}/loose-item/3rd-party/excel/sampleLabel`;

// Login
// export const MIDDLEWARE_LOGIN_API = `${NODE_API}/login_middleware`;
export const MIDDLEWARE_LOGIN_API = `${PARTNER_URI}/login_middleware`;
// export const MIDDLEWARE_MICROSOFT_LOGIN_API = `${NODE_API}/login_middleware/microsoft`;
export const MIDDLEWARE_MICROSOFT_LOGIN_API = `${PARTNER_URI}/login_middleware/microsoft`;

// Original Backend Me API
// export const BACKEND_ME_API = `${BACKEND_ROUTE_URI}/me`;
export const BACKEND_ME_API = `${PARTNER_URI}/partner/me`;
export const BACKEND_IS_PARTNER_API = `${PARTNER_URI}/partner/isPartner`;

export const GET_PARTNER_INFO_API = `${PARTNER_URI}/partner/partnerInfo`

export const LABEL_DOWNLOAD_URL_PREFIX = "https://storage.googleapis.com/";

export const LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE = "https://storage.googleapis.com";

export const GET_PARTNER = `${PARTNER_URI}/partner/get-partner`;

// Hscode
export const HSCODE_SEARCH = `${PARTNER_URI}/elasticsearch/search`;

export const HSCODE_GET_NODE = `${PARTNER_URI}/elasticsearch/node`;

export const BOL_LIST = `${PARTNER_URI}/bol/list-bol`;

export const ADD_BOL_RECORD = `${PARTNER_URI}/bol/add-bol`;

export const UPDATE_BOL_RECORD = `${PARTNER_URI}/bol/update-bol-ref-user`;

export const PROFILE_API = `${NODE_API}/user_profile/`;

export const CHANGE_PASSWORD_API = `${NODE_API}/login_middleware/change_password/`;

export const FORGOT_PASSWORD = `${PARTNER_URI}/login_middleware/sendResetPasswordEmail`;

export const RESET_PASSWORD = `${PARTNER_URI}/login_middleware/reset_password/`;