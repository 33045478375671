import {actionType} from "../../utils/Helper";


export const updateLandingEstimatePackage = (packageData) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_PACKAGE_DATA,
    payload: packageData
})

export const updateLandingEstimatePallet = (palletData) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_PALLET_DATA,
    payload: palletData
})

export const updateLandingEstimatePackageMissing = (packageMissing) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING,
    payload: packageMissing
})

export const updateLandingEstimatePalletMissing = (palletMissing) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_PALLET_MISSING,
    payload: palletMissing
})

export const updateLandingEstimatePackageCost = (packageCost) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_PACKAGE_COST,
    payload: packageCost
})

export const updateLandingEstimatePalletCost = (palletCost) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_PALLET_COST,
    payload: palletCost
})

export const clearLandingEstimatePackageCost = () => ({
    type: actionType.LANDING_ESTIMATE_CLEAR_PACKAGE_COST
})

export const clearLandingEstimatePalletCost = () => ({
    type: actionType.LANDING_ESTIMATE_CLEAR_PALLET_COST
})

export const updateLandingClickSignIn = (isClick) => ({
    type: actionType.LANDING_UPDATE_CLICK_SIGN_IN,
    payload: isClick
})

export const updateLandingClickJoinUs = (isClick) => ({
    type: actionType.LANDING_UPDATE_CLICK_JOIN_US,
    payload: isClick
})

export const updateLandingEstimatePickupPostalCodeValid = (isValid) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION,
    payload: isValid
})

export const updateLandingEstimateDeliverPostalCodeValid = (isValid) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION,
    payload: isValid
})


export const updateLandingEstimateTypeService = (type) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_TYPE_SERVICE,
    payload: type
})

export const updateLandingEstimateCostError = (isError) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_COST_ERROR,
    payload: isError
})

export const updateLandingEstimateCostLoading = (isLoading) => ({
    type: actionType.LANDING_ESTIMATE_UPDATE_COST_LOADING,
    payload: isLoading
})

export const updateLandingClickEstimate = (isClick) => ({
    type: actionType?.LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE,
    payload: isClick
})

export const clearLandingEstimateTypeService = () => ({
    type: actionType.LANDING_ESTIMATE_CLEAR_TYPE_SERVICE
})
