import React, {useState} from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

const PickupAppointmentRow = ({ appoint_id,
                                confirm_appoint_no,
                                appoint_date,
                                appoint_time_start,
                                appoint_time_end,
                                pickup_addr,
                                pickup_city,
                                pickup_province,
                                pickup_country,
                                pickup_postal_code,
                                pickup_name,
                                pickup_tel,
                                pickup_email,
                                number_of_count,
                                estimate_weight,
                                weight_unit,
                                tracking_nos,
                                status
}) => {
  const [isCanceled, setIsCanceled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const clearStatusMessages = () => {
    setStatusMessage("");
    setErrorMessage("");
  };

  const cancelPickup = async () => {
    console.log("confirmAppointNo:", confirm_appoint_no);
    setIsLoading(true);
    clearStatusMessages();
    try {
      const storedToken = getAccessToken("access_token");
      const { data, status } = await axios({
        method: "delete",
        url: `${PARTNER_URI}/packagesPickupAppointment/cancelPickupAppointmentPartner`,
        params: {
          appointmentNumber: confirm_appoint_no,
        },
        headers: {
          authorization: `Bearer ${storedToken}`,
        },
      });
      if (status === 200) {
        console.log("Success:", data);
        setStatusMessage("Successfully Cancelled Pickup");
        setIsCanceled(true);
        handleToastClick();
      } else {
        console.log("Error:", data);
        throw new Error();
      }
    } catch (e) {
      setErrorMessage("Failed to Cancel Pickup. Try again another time.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <>
        {/*Pop-up Toast*/}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={toastOpen}
            onClose={handleToastClose}
            autoHideDuration={3000}
            message="Cancel Appointment">
          {(() => {
            if (errorMessage !== "") {
              return (
                  <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                    Cancel Appointment Failed!
                    <hr />
                    Error: {errorMessage}
                  </Alert>
              )
            }
            return (
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                  Cancel Appointment Success!
                </Alert>
            )
          })()}

        </Snackbar>
        <TableRow
            hover
            tabIndex={-1}
            key={appoint_id}
        >
          <TableCell>
            {confirm_appoint_no}
          </TableCell>
          <TableCell>
            {appoint_date}
            <br />
            {appoint_time_start.substring(3, 8)} -  {appoint_time_end.substring(3, 8)}
          </TableCell>
          <TableCell>
            {pickup_addr}
            <br />
            {pickup_city} ,{pickup_province}, {pickup_country}
            <br />
            {pickup_postal_code}
          </TableCell>
          <TableCell>
            {pickup_name}
            <br />
            {pickup_tel}
            <br />
            {pickup_email}
          </TableCell>
          <TableCell>
            Number of Package: {number_of_count}
            <br />
            Estimate Weight: {estimate_weight} ({weight_unit})
            <br />
            <br />
            Tracking Numbers:
            <br />
            {tracking_nos}
          </TableCell>
          <TableCell>
            {
              (() => {
                if (isCanceled) {
                  return "Cancelled"
                }
                return status === 1 ? "Submitted" : "Canceled"
              })()
            }
          </TableCell>
          <TableCell>
            <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
            >
              {
                !isCanceled && status === 1 ?
                    <LoadingButton
                        loading={isLoading}
                        onClick={cancelPickup}
                        sx={{
                          borderColor: "warning.main",
                          borderStyle: "solid",
                          borderWidth: 1,
                          color: "warning.main",
                          width: "clamp(3rem, 100px, 100%)",
                          mb: 1,
                        }}
                    >
                      Cancel
                    </LoadingButton> : undefined
              }
            </Box>
          </TableCell>
        </TableRow>
      </>
  );
};

export default PickupAppointmentRow;
