import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
// import ServicePickUp from './servicePickup';
// import ServiceDelivery from './serviceDelivery';

// import { defaultPickUpDetails } from "../../slices";

import {useHistory} from "react-router-dom";

import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import {
    IconButton,
    TextField,
    Dialog,
    RadioGroup,
    FormControlLabel,
    Radio,
    Container,
    Button,
    useMediaQuery,
    Select,
    MenuItem,
    FormGroup,
    Checkbox,
    Modal,
    DialogTitle,
    DialogContent,
    Snackbar,
    Divider,
    Typography,
    Tooltip
} from "@material-ui/core";
import {Paper, Box} from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import {makeStyles} from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import SettingsIcon from "@material-ui/icons/Settings";

import "../../styles/shipping.css";
import produce from "immer";

import AddressInputCombo from "../../shared/AddressInputCombo/addressInputCombo";
import {
    countryList,
    internationalShipmentTypeList,
    provinces,
    shipmentTypesDetails,
    transportationList, UOMList
} from "../../shared/constInfo";
import {getAccessToken} from "../../utils/doToken";
import LoadingContainer from "../../shared/loadingContainer";
import {redirectAddressBook} from "../../utils/redirect";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "../../shared/customInput";
import {
    addDeliverAddressBookList,
    addSenderAddressBookList,
    deleteAddressBookByPartnerIdAndAddressBookId,
    deleteAddressBookList,
    getAddressBookList,
    getDeliverAddressBookList,
    getSenderAddressBookList
} from "../../utils/addressBookData";
import {ReactComponent as CloseIcon} from "../../images/close.svg";
import {Alert} from "@material-ui/lab";
import getProvinceCodeByName, {getUSProvinceCodeByName} from "../../utils/getProvinceCode";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getCountryCode} from "../../utils/getCountryCode";

const warehouseAddress = [
    {
        address: "140-6751 Westminster Hwy",
        city: "Richmond",
        province: "BC",
        zipCode: "V7C 4V4",
        phone: "6043215355",
    },
    {
        address: "Toronto A514A4, J-Link Cargo unit 12 or unit 13 - 1 Select Ave.",
        city: "Scarborough",
        province: "ON",
        zipCode: "M1V 5J3",
        phone: "6043215355",
    },
    {
        address: "6635 Chemin De La Cote-de-liesse",
        city: "Saint-Laurent",
        province: "QC",
        zipCode: "H4T 1Z5",
        phone: "6043215355",
    }
];

const accountOptions = [
    "UPS-Richmond-BC",
    "UPS-Toronto-ON",
    "UPS-Montreal-QC",
    // "FEDEX-Richmond-BC",
    // "FEDEX-Toronto-ON",
    // "FEDEX-Montreal-QC",
    // "FEDEX-Calgary-AB",
    "UPS-Calgary-AB",
    "UPS-UUC-Brokerage",
    "FEDEX-Richmond-BC",
    "FEDEX-Toronto-ON",
];

const accountOptionsValue = {
    "UPS-Richmond-BC": "ups-richmond-bc",
    "UPS-Toronto-ON": "ups-toronto-on",
    "UPS-Montreal-QC": "ups-montreal-qc",
    "FEDEX-Richmond-BC": "fedex-richmond-bc",
    "FEDEX-Toronto-ON": "fedex-toronto-on",
    "FEDEX-Montreal-QC": "fedex-montreal-qc",
    "FEDEX-Calgary-AB": "fedex-calgary-ab",
};

const addressOptions = {
    "ups-richmond-bc": {
        name: "UUCargo",
        address: "6751 Westminster Hwy",
        city: "Richmond",
        zipCode: "V7C4V4",
        province: "BC",
        phone: "6049981888",
    },
    "ups-toronto-on": {
        name: "UUCargo",
        address: "Unit 12,13 - 1 Select Ave",
        city: "Scarborough",
        zipCode: "M1V5J3",
        province: "ON",
        phone: "4169000110",
    },
    "ups-montreal-qc": {
        name: "UUCargo",
        address: "6635 Chemin De La Cote-de-liesse",
        city: "Saint-Laurent",
        zipCode: "H4T1Z5",
        province: "QC",
        phone: "5149185899",
    },
    // fedexuucrcm: {
    //   name: "jlink1",
    //   address: "6751 Westminster Hwy",
    //   city: "Richmond",
    //   zipCode: "V7C4V4",
    //   province: "BC",
    //   phone: "6043215355",
    // },
    // fedexuuctro: {
    //   name: "TOUV",
    //   address: "Unit 12,13 - 1 Select Ave",
    //   city: "Scarborough",
    //   zipCode: "M1V5J3",
    //   province: "ON",
    //   phone: "4169000110",
    // },
};

const AddressInputTab = ({
                             setUserLocation,
                             setDeliverLocation,
                             userLocation,
                             deliverLocation,
                             checkError,
                             formatData,
                             setFormatData,
                             setShippingItems,
                             setItemInfo,
                             next,
                             showErroMsg,
                             setMsgStatus,
                             setAmazonFba,
                             amazonFba,
                             nextErrorMessage
                         }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const matches = useMediaQuery("(max-width:1080px)");

    const [accountBaseCombinedName, setAccountBaseCombinedName] = useState(formatData?.accountBaseCombinedName || "");
    const [cargoControlNumber, setCargoControlNumber] = useState(formatData?.cargoControlNumber || "");
    const [refOrderNumber, setRefOrderNumber] = useState(formatData?.refOrderNumber || "");

    // Sender Address, Apt Info, Postal Code, City, Province
    const [senderAddressInputValue, setSenderAddressInputValue] = useState("");
    const [senderAptInfoInputValue, setSenderAptInfoInputValue] = useState("");
    const [senderPostalCodeInputValue, setSenderPostalCodeInputValue] = useState("");
    const [senderCityInputValue, setSenderCityInputValue] = useState("");
    const [senderProvinceInputValue, setSenderProvinceInputValue] = useState("");

    // Deliver Address, Apt Info, Postal Code, City, Province
    const [deliverAddressInputValue, setDeliverAddressInputValue] = useState("");
    const [deliverAptInfoInputValue, setDeliverAptInfoInputValue] = useState("");
    const [deliverPostalCodeInputValue, setDeliverPostalCodeInputValue] = useState("");
    const [deliverCityInputValue, setDeliverCityInputValue] = useState("");
    const [deliverProvinceInputValue, setDeliverProvinceInputValue] = useState("");

    const {id: partnerId, email} = useSelector((state) => state.user);
    const [errorMessage, setErrorMessage] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    useEffect(() => {
        console.log("Pick Up: ", formatData.pickUp);
    }, [formatData]);

    // useEffect(() => {
    //   setFormatData(v => {
    //     return {
    //       ...v,
    //       pickUp: {
    //         ...v.pickUp,
    //         address: "7691 Canada Way"
    //       }
    //     }
    //   })
    // }, []);
    useEffect(() => {
        console.log("Form Data:", formatData);
    }, [formatData]);

    const handleAccountBasedCombinedNameChange = (e) => {
        const value = e.target.value;
        setAccountBaseCombinedName(value);
        setFormatData(v => {
            return {
                ...v,
                accountBaseCombinedName: value
            }
        })
    }

    const handleCargoControlNumberChange = (e) => {
        const value = e.target.value;
        setCargoControlNumber(value);
        setFormatData(v => {
            return {
                ...v,
                cargoControlNumber: value
            }
        })
    }

    const handleRefOrderNumberChange = (e) => {
        const value = e.target.value;
        setRefOrderNumber(value);
        setFormatData(v => {
            return {
                ...v,
                refOrderNumber: value
            }
        })
    }

    const initOrderData = useSelector((state) => state.orders.initOrderData);
    const {addressBook, addressBookLoading} = useSelector(
        (state) => state.user
    );

    const [isWindowOpen, setWindowOpenState] = useState(false);

    const [value, setValue] = useState(null);

    // const [isAddressBook, setToAddressBook] = useState(true);

    const getEmptyStatus = (_formatData) => {
        const {pickUp, deliver} = _formatData;
        const pickUpEmpty =
            !pickUp.name ||
            !pickUp.phone ||
            pickUp.address ||
            !pickUp.city ||
            !pickUp.province;
        const deliverEmpty =
            !deliver.name ||
            !deliver.phone ||
            !deliver.address ||
            !deliver.city ||
            !deliver.province;
        return [pickUpEmpty, deliverEmpty];
    };

    const [isSaveAddress, setIsSaveAddress] = useState(false);
    const [openSenderAddressBook, setOpenSenderAddressBook] = useState(false);
    const [senderAddressBookList, setSenderAddressBookList] = useState([]);
    const [openDeliverAddressBook, setOpenDeliverAddressBook] = useState(false);
    const [deliverAddressBookList, setDeliverAddressBookList] = useState([]);

    const handleOpenSenderAddressBook = async () => {
        setSenderAddressBookList(await getSenderAddressBookList({partnerId}));
        setOpenSenderAddressBook(true);
    };

    const handleCloseSenderAddressBook = () => {
        setOpenSenderAddressBook(false);
    };

    const handleSaveAddress = () => {
        if (!isFullInformation()) {
            setIsSaveAddress(false);
            return;
        }
        setIsSaveAddress((v) => !v);
    };

    const isEmpty = (word) => {
        if (typeof word !== "string") {
            return false;
        }
        return word?.trim() !== "";
    }

    const isFullInformation = () => {
        const pickUp = formatData.pickUp;
        const deliver = formatData.deliver;
        return isEmpty(pickUp.address)
            && isEmpty(pickUp.province)
            && isEmpty(pickUp.name)
            && isEmpty(pickUp.city)
            && isEmpty(pickUp.zipCode)
            && isEmpty(pickUp.phone)
            && isEmpty(deliver.address)
            && isEmpty(deliver.province)
            && isEmpty(deliver.name)
            && isEmpty(deliver.city)
            && isEmpty(deliver.zipCode)
            && isEmpty(deliver.phone)
    }

    useEffect(() => {
        if (isSaveAddress) {
            (async () => {
                const pickUp = formatData.pickUp;
                const deliver = formatData.deliver;
                await addSenderAddressBookList({
                    partnerId,
                    address: pickUp.address,
                    address_2: pickUp.aptInfo,
                    country: pickUp.country,
                    province: pickUp.country === "CA" ? getProvinceCodeByName(pickUp.province) : getUSProvinceCodeByName(pickUp.province),
                    name: pickUp.name,
                    city: pickUp.city,
                    email: email,
                    postalCode: pickUp.zipCode,
                    phone: pickUp.phone,
                    addressEmail: pickUp.email
                })
                await addDeliverAddressBookList({
                    partnerId,
                    address: deliver.address,
                    address_2: deliver.aptInfo,
                    country: deliver.country,
                    province: deliver.country === "CA" ? getProvinceCodeByName(deliver.province) : getUSProvinceCodeByName(deliver.province),
                    name: deliver.name,
                    city: deliver.city,
                    email: email,
                    postalCode: deliver.zipCode,
                    phone: deliver.phone,
                    addressEmail: deliver.email
                })
                handleToastClick();
            })()
        }
    }, [isSaveAddress])

    const handleOpenDeliverAddressBook = async () => {
        setDeliverAddressBookList(await getDeliverAddressBookList({partnerId}));
        setOpenDeliverAddressBook(true);
    };

    const handleCloseDeliverAddressBook = () => {
        setOpenDeliverAddressBook(false);
    };

    useEffect(() => {
        if (initOrderData) {
            console.log("Initialize Data", initOrderData);
            const {estimateDetails, itemInfo} = initOrderData;
            if (estimateDetails && itemInfo) {
                setUserLocation(
                    produce((draft) => {
                        draft.city = estimateDetails.pickupCity;
                    })
                );
                setDeliverLocation(
                    produce((draft) => {
                        draft.city = estimateDetails.deliverCity;
                    })
                );
                console.log("Estimate Details:", estimateDetails);
                setFormatData(
                    produce((draft) => {
                        draft.pickUp.city = estimateDetails.pickupCity;
                        draft.deliver.city = estimateDetails.deliverCity;
                        draft.pickUp.province = estimateDetails.pickupProvince;
                        draft.deliver.province = estimateDetails.deliverProvince;
                    })
                );
                setItemInfo({
                    description: itemInfo.description || "",
                    height: itemInfo.height || null,
                    weight: itemInfo.weight || null,
                    quantity: itemInfo.quantity,
                    length: itemInfo.length
                        ? itemInfo.length
                        : itemInfo.selectedTypeID &&
                        shipmentTypesDetails[itemInfo.selectedTypeID].length
                            ? shipmentTypesDetails[itemInfo.selectedTypeID].length
                            : null,
                    width: itemInfo.width
                        ? itemInfo.width
                        : itemInfo.selectedTypeID &&
                        shipmentTypesDetails[itemInfo.selectedTypeID].width
                            ? shipmentTypesDetails[itemInfo.selectedTypeID].width
                            : null,
                    shipmentType: itemInfo.selectedTypeID,
                    color: "",
                    hazardous: false,
                    tempSensitive: false,
                });
            }
        } else {
            const prevItems = localStorage.getItem("shippingData");

            if (prevItems) {
                console.log("Previous Item", prevItems);
                const {pickUpDetails, shippingItems} = JSON.parse(prevItems);
                console.log("pickup details:", pickUpDetails);
                if (pickUpDetails && shippingItems) {
                    setUserLocation(
                        produce((draft) => {
                            draft.address = pickUpDetails.pickUp.address;
                            draft.city = pickUpDetails.pickUp.city;
                            draft.province = pickUpDetails.pickUp.province;
                            draft.zipCode = pickUpDetails.pickUp.zipCode || "";
                            draft.aptInfo = pickUpDetails.pickUp.aptInfo;
                        })
                    );
                    setDeliverLocation(
                        produce((draft) => {
                            draft.address = pickUpDetails.deliver.address;
                            draft.city = pickUpDetails.deliver.city;
                            draft.province = pickUpDetails.deliver.province;
                            draft.zipCode = pickUpDetails.deliver.zipCode || "";
                            draft.aptInfo = pickUpDetails.deliver.aptInfo;
                        })
                    );
                    setFormatData(
                        produce((draft) => {
                            // draft = { ...pickUpDetails };
                            draft.pickUp.address = pickUpDetails.pickUp.address;
                            draft.pickUp.city = pickUpDetails.pickUp.city;
                            if (pickUpDetails.pickUp.zipCode)
                                draft.pickUp.zipCode = pickUpDetails.pickUp.zipCode;
                            draft.deliver.address = pickUpDetails.deliver.address;
                            draft.deliver.city = pickUpDetails.deliver.city;
                            if (pickUpDetails.deliver.zipCode)
                                draft.deliver.zipCode = pickUpDetails.deliver.zipCode;
                            draft.selectedDate = new Date(pickUpDetails.selectedDate);
                            draft.pickUp.province = pickUpDetails.pickUp.province;
                            draft.deliver.province = pickUpDetails.deliver.province;
                            draft.pickUp.phone = pickUpDetails.pickUp.phone;
                            draft.deliver.phone = pickUpDetails.deliver.phone;
                        })
                    );
                    // setShippingItems(shippingItems);
                }
            }
            // dispatch(loadShippingItems({ items: shippingItems }));
        }
    }, [initOrderData, setDeliverLocation, setFormatData, setItemInfo, setUserLocation]);

    useEffect(() => {
        const prevItems = localStorage.getItem("shippingData");

        const current_address_cache = getAccessToken("user_current_address");
        if (current_address_cache && !initOrderData && !prevItems) {
            const {city, province, address, postal} = JSON.parse(
                current_address_cache
            );
            setUserLocation(
                produce((draft) => {
                    draft.address = address;
                    draft.city = city;
                    if (postal) draft.zipCode = postal;
                })
            );
            setFormatData(
                produce((draft) => {
                    draft.pickUp.address = address;
                    draft.pickUp.city = city;
                    if (postal) draft.pickUp.zipCode = postal;
                    draft.pickUp.province = provinces.find(
                        (ele) => ele.value === province
                    ).label;
                })
            );
        }
    }, [initOrderData, setFormatData, setUserLocation]);

    useEffect(() => {
        const [pickUpEmpty, deliverEmpty] = getEmptyStatus(formatData);
        if (!pickUpEmpty && !deliverEmpty) setMsgStatus(false);
    }, [formatData, setMsgStatus]);

    /**
     * @param {boolean} address Belongs to the address submodule if True
     * @param {string} fieldName The field name of the form data object
     * @param {string} addressType 'pickUp' or 'Deliver'
     */
    const handleChange =
        (address, addressType = "pickUp") =>
            (fieldName) =>
                (e) => {
                    setFormatData(
                        produce((draft) => {
                            if (address) draft[addressType][fieldName] = e.target.value;
                            else draft[fieldName] = e.target.value;
                        })
                    );
                };

    /**
     * @param {boolean} address Belongs to the address submodule if True
     * @param {string} fieldName The field name of the form data object
     * @param {string} addressType 'pickUp' or 'Deliver'
     */
    const handleChangeFromSelection =
        (address, addressType = "pickUp") =>
            (fieldName) =>
                (newValue) => {
                    if (fieldName === "province") {
                        setFormatData(
                            produce((draft) => {
                                draft[addressType]["province"] = newValue?.target?.value || newValue;
                            })
                        );
                        return;
                    }
                    setFormatData(
                        produce((draft) => {
                            if (address && newValue) {
                                draft[addressType][fieldName] = newValue;
                            } else if (address && !newValue) {
                                draft[addressType][fieldName] = fieldName === "province" || fieldName === "city" ? null : "";
                            } else {
                                draft[fieldName] = newValue;
                            }
                        })
                    );
                };

    const handleAddressSelection = (field, event) => {
        const index = parseInt(event.target.value);
        setValue(index);
        const item = addressBook[index];
        if (field === "deliver")
            setDeliverLocation(
                produce((draft) => {
                    draft.address = item.address;
                    draft.city = item.city;
                    draft.zipCode = item.zipCode || "";
                    draft.province = item.province;
                    draft.aptInfo = item.aptInfo;
                })
            );
        setFormatData(
            produce((draft) => {
                draft[field].placeId = item?.google_place_id || "";
                draft[field].phone = item?.phone || "";
                draft[field].city = item?.city;
                draft[field].zipCode = item?.zipCode || "";
                draft[field].province = provinces.find(
                    (ele) => ele.value === item.province
                ).label;
            })
        );
        setTimeout(() => {
            if (setWindowOpenState && setValue) {
                setWindowOpenState(false);
                setValue(null);
            }
        }, 200);
    };

    return (
        <Container>
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Save Address Failed!
                                <hr/>
                                Error: {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            Save Addresses Success!
                        </Alert>
                    );
                })()}
            </Snackbar>
            {/*Pop-up Toast*/}
            <Dialog
                open={openSenderAddressBook}
                onClose={handleCloseSenderAddressBook}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    <Box textAlign="center">Address Book</Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        sx={{gap: "1rem"}}
                    >
                        {senderAddressBookList.map((item) => (
                            <Box display="flex" mb={2} key={item.id} width="100%">
                                <Box
                                    p={1}
                                    width="100%"
                                    component="button"
                                    sx={{border: "1px dashed grey"}}
                                    className={classes.addressLine}
                                    onClick={() => {
                                        // setFormatData({
                                        //   phone: item.phone,
                                        //   address: item.address,
                                        //   zipCode: item.postal_code,
                                        //   city: item.city,
                                        //   province: item.province,
                                        // });
                                        setSenderAddressInputValue(item.address);
                                        setSenderPostalCodeInputValue(item.postal_code);
                                        setSenderCityInputValue(item.city);
                                        setSenderProvinceInputValue(item.province);
                                        setOpenSenderAddressBook(false);
                                        setFormatData((v) => {
                                            return {
                                                ...v,
                                                pickUp: {
                                                    phone: item.phone,
                                                    address: item.address,
                                                    zipCode: item.postal_code,
                                                    city: item.city,
                                                    aptInfo: item.address_2,
                                                    province: getProvinceCodeByName(item.province),
                                                    name: item.name,
                                                    country: item.country,
                                                    email: item.address_email
                                                },
                                            };
                                        });
                                    }}
                                >
                                    <h4>Name: {item.name}</h4>
                                    <h4>Phone: {item.phone}</h4>
                                    <h4>Email: {item.address_email !== "" ? item.address_email : "N/A"}</h4>
                                    <h4>
                                        Address: {item.address}, {item.city}, {item.province},{" "}
                                        {item.country}, {item.postal_code}
                                    </h4>
                                </Box>
                                <IconButton
                                    onClick={async () => {
                                        // Remove this address by item id
                                        const id = item.id;
                                        setSenderAddressBookList((currentAddressBookList) =>
                                            currentAddressBookList.filter(
                                                (address) => address.id !== id
                                            )
                                        );
                                        await deleteAddressBookByPartnerIdAndAddressBookId({
                                            partnerId,
                                            id,
                                        });
                                        setSenderAddressBookList(
                                            await getSenderAddressBookList({partnerId})
                                        );
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDeliverAddressBook}
                onClose={handleCloseDeliverAddressBook}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    <Box textAlign="center">Address Book</Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        sx={{gap: "1rem"}}
                    >
                        {deliverAddressBookList.map((item) => (
                            <Box display="flex" mb={2} key={item.id} width="100%">
                                <Box
                                    p={1}
                                    width="100%"
                                    component="button"
                                    sx={{border: "1px dashed grey"}}
                                    className={classes.addressLine}
                                    onClick={() => {
                                        // setFormatData({
                                        //   phone: item.phone,
                                        //   address: item.address,
                                        //   zipCode: item.postal_code,
                                        //   city: item.city,
                                        //   province: item.province,
                                        // });
                                        setDeliverAddressInputValue(item.address);
                                        setDeliverPostalCodeInputValue(item.postal_code);
                                        setDeliverCityInputValue(item.city);
                                        setDeliverProvinceInputValue(item.province);
                                        setOpenDeliverAddressBook(false);
                                        setFormatData((v) => {
                                            return {
                                                ...v,
                                                deliver: {
                                                    phone: item.phone,
                                                    address: item.address,
                                                    zipCode: item.postal_code,
                                                    city: item.city,
                                                    aptInfo: item.address_2,
                                                    province: getProvinceCodeByName(item.province),
                                                    name: item.name,
                                                    country: item.country,
                                                    email: item.address_email
                                                },
                                            };
                                        });
                                    }}
                                >
                                    <h4>Name: {item.name}</h4>
                                    <h4>Phone: {item.phone}</h4>
                                    <h4>Email: {item.address_email !== "" ? item.address_email : "N/A"}</h4>
                                    <h4>
                                        Address: {item.address}, {item.city}, {item.province},{" "}
                                        {item.country}, {item.postal_code}
                                    </h4>
                                </Box>
                                <IconButton
                                    onClick={async () => {
                                        // Remove this address by item id
                                        const id = item.id;
                                        setDeliverAddressBookList((currentAddressBookList) =>
                                            currentAddressBookList.filter(
                                                (address) => address.id !== id
                                            )
                                        );
                                        await deleteAddressBookByPartnerIdAndAddressBookId({
                                            partnerId,
                                            id,
                                        });
                                        setDeliverAddressBookList(
                                            await getDeliverAddressBookList({partnerId})
                                        );
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>
            <Box
                display="flex"
                justifyContent="center"
                // heigh="100%"
                width="100%"
                flexDirection={matches ? "column" : "row"}
            >
                {/* <Fade duration={2000}> */}
                <Box
                    display="flex"
                    flexDirection="column"
                    width={matches ? "100%" : "60%"}
                    mr={2}
                >
                    <Box color="orange">
                        <h1>Address Information</h1>
                    </Box>
                    {/*<Box*/}
                    {/*    mb={1}*/}
                    {/*    className={classes.subTitle}*/}
                    {/*    display="flex"*/}
                    {/*    flexDirection="column"*/}
                    {/*>*/}
                    {/*  <span>Drop Off</span>*/}
                    {/*  <Box variant="contained" display="flex" >*/}
                    {/*    <RadioGroup*/}
                    {/*        row*/}
                    {/*        aria-labelledby="demo-row-radio-buttons-group-label"*/}
                    {/*        name="row-radio-buttons-group"*/}
                    {/*        defaultValue="store"*/}
                    {/*        onChange={e => {*/}
                    {/*          const value = e.target.value;*/}
                    {/*          if (value === "store") {*/}
                    {/*            setDropOffOption("store");*/}
                    {/*            setDropOffInfo({*/}
                    {/*              province: userLocation.province,*/}
                    {/*              city: userLocation.city,*/}
                    {/*              address: userLocation.address,*/}
                    {/*              zipCode: userLocation.zipCode*/}
                    {/*            })*/}
                    {/*          } else {*/}
                    {/*            setDropOffOption("warehouse");*/}
                    {/*            setDropOffInfo({*/}
                    {/*              address: "",*/}
                    {/*              zipCode: "",*/}
                    {/*              city: "",*/}
                    {/*              province: ""*/}
                    {/*            });*/}
                    {/*          }*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*      <FormControlLabel value="store" control={<Radio />} label="Store" />*/}
                    {/*      <FormControlLabel value="warehouse" control={<Radio />} label="Warehouse" />*/}
                    {/*    </RadioGroup>*/}
                    {/*  </Box>*/}
                    {/*  <Box hidden={dropOffOption === "store"}>*/}
                    {/*    <FormControl sx={{ m: 1, minWidth: 80 }}>*/}
                    {/*      <InputLabel id="demo-simple-select-autowidth-label">Location</InputLabel>*/}
                    {/*      <Select*/}
                    {/*          labelId="demo-simple-select-autowidth-label"*/}
                    {/*          id="demo-simple-select-autowidth"*/}
                    {/*          value={dropOffInfo}*/}
                    {/*          onChange={handleDropOffInfo}*/}
                    {/*          autoWidth*/}
                    {/*          label="dropOff"*/}
                    {/*      >*/}
                    {/*        {*/}
                    {/*          warehouseAddress.map((v, index) => {*/}
                    {/*            return <MenuItem value={v} key={index}>{`${v.address}, ${v.city}, ${v.province}, ${v.zipCode}`}</MenuItem>*/}
                    {/*          })*/}
                    {/*        }*/}
                    {/*      </Select>*/}
                    {/*    </FormControl>*/}
                    {/*  </Box>*/}
                    {/*</Box>*/}
                    {/*<Box*/}
                    {/*    mb={1}*/}
                    {/*    className={classes.subTitle}*/}
                    {/*    display="flex"*/}
                    {/*    alignItems="center"*/}
                    {/*>*/}
                    {/*  <span>Account Base</span>*/}
                    {/*</Box>*/}
                    {/*<Box display="flex" textAlign="left" >*/}
                    {/*  <FormControl fullWidth>*/}
                    {/*    <InputLabel id="demo-simple-select-autowidth-label">Account</InputLabel>*/}
                    {/*    <Select*/}
                    {/*        labelId="demo-simple-select-autowidth-label"*/}
                    {/*        id="demo-simple-select-autowidth"*/}
                    {/*        value={accountBaseCombinedName}*/}
                    {/*        onChange={handleAccountBasedCombinedNameChange}*/}
                    {/*        autoWidth*/}
                    {/*        label=""*/}
                    {/*    >*/}
                    {/*      {*/}
                    {/*        accountOptions.map((v, index) => {*/}
                    {/*          return <MenuItem value={v} key={index}>{v}</MenuItem>*/}
                    {/*        })*/}
                    {/*      }*/}
                    {/*    </Select>*/}
                    {/*  </FormControl>*/}
                    {/*</Box>*/}
                    <Box>
                        <Box display="flex" mb={1} mt={1}>
                            <Box flexBasis="50%" mr={1}>
                                <CustomInput
                                    //   error={pickUp && checkError && !addressObject.phone}
                                    onChange={handleRefOrderNumberChange}
                                    // value={provinces.find(ele => ele.value === addressObject.province) || ''}
                                    value={refOrderNumber}
                                    type="line"
                                    label={"Ref Order #"}
                                />
                            </Box>
                            <Box flexBasis="50%" ml={1}>
                                <CustomInput
                                    //   error={pickUp && checkError && !addressObject.phone}
                                    onChange={handleCargoControlNumberChange}
                                    // value={provinces.find(ele => ele.value === addressObject.province) || ''}
                                    value={cargoControlNumber}
                                    type="line"
                                    label={"Cargo control #"}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <hr/>
                    <hr/>
                    <Box
                        mb={1}
                        className={classes.subTitle}
                        display="flex"
                        alignItems="center"
                        sx={{gap: "1rem"}}
                    >
                        <span>Sender</span>
                        <Tooltip title="Address Book" placement="right">
                            <Button
                                variant="contained"
                                component="span"
                                color="primary"
                                style={{
                                    textTransform: "unset",
                                    fontSize: "1rem",
                                    // width: "10rem",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    handleOpenSenderAddressBook();
                                }}
                            >
                                <img
                                    src="/book.png"
                                    style={{width: "30px", height: "30px"}}
                                    alt="image"
                                />
                                {/* Address Book */}
                            </Button>
                        </Tooltip>
                    </Box>
                    <AddressInputCombo
                        handleChange={handleChange(true)}
                        handleChangeFromSelection={handleChangeFromSelection(true)}
                        addressObject={formatData.pickUp}
                        userLocation={userLocation}
                        pickUp
                        checkError={checkError}
                        addressInputValue={senderAddressInputValue}
                        setAddressInputValue={setSenderAddressInputValue}
                        aptInfoInputValue={senderAptInfoInputValue}
                        setAptInfoInputValue={setSenderAptInfoInputValue}
                        postalCodeInputValue={senderPostalCodeInputValue}
                        setPostalCodeInputValue={setSenderPostalCodeInputValue}
                        cityInputValue={senderCityInputValue}
                        setCityInputValue={setSenderCityInputValue}
                        provinceInputValue={senderProvinceInputValue}
                        setProvinceInputValue={setSenderProvinceInputValue}
                    />
                    <Box
                        mb={1}
                        mt={2}
                        className={classes.subTitle}
                        display="flex"
                        alignItems="center"
                        sx={{gap: "1rem"}}
                    >
                        <span>Deliver</span>
                        <Tooltip title="Address Book" placement="right">
                            <Button
                                variant="contained"
                                component="span"
                                color="primary"
                                style={{
                                    textTransform: "unset",
                                    fontSize: "1rem",
                                    // width: "10rem",
                                }}
                                onClick={() => {
                                    handleOpenDeliverAddressBook();
                                }}
                            >
                                <img
                                    src="/book.png"
                                    style={{width: "30px", height: "30px", cursor: "pointer"}}
                                    alt="image"
                                />
                                {/* Address Book */}
                            </Button>
                        </Tooltip>
                    </Box>
                    <AddressInputCombo
                        handleChange={handleChange(true, "deliver")}
                        handleChangeFromSelection={handleChangeFromSelection(
                            true,
                            "deliver"
                        )}
                        addressObject={formatData.deliver}
                        userLocation={deliverLocation}
                        checkError={checkError}
                        addressInputValue={deliverAddressInputValue}
                        setAddressInputValue={setDeliverAddressInputValue}
                        aptInfoInputValue={deliverAptInfoInputValue}
                        setAptInfoInputValue={setDeliverAptInfoInputValue}
                        postalCodeInputValue={deliverPostalCodeInputValue}
                        setPostalCodeInputValue={setDeliverPostalCodeInputValue}
                        cityInputValue={deliverCityInputValue}
                        setCityInputValue={setDeliverCityInputValue}
                        provinceInputValue={deliverProvinceInputValue}
                        setProvinceInputValue={setDeliverProvinceInputValue}
                        isSendTo
                    />
                    {/*Save to Address Book*/}
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={isSaveAddress}
                                    onClick={handleSaveAddress}
                                    disabled={!isFullInformation()}
                                />
                            }
                            disabled={!isFullInformation()}
                            label="Save to address book"
                        />
                    </FormGroup>
                    <Divider/>
                    <Accordion className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                Cross Border Only (Mandatory for shipping Different Countries.){" "}
                                <b>Click Here To Expand</b>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <Box display="flex" alignItems="center">
                                    <Box width="100%">
                                        <CustomInput
                                            onChange={(e) => {
                                                console.log(e);
                                                setFormatData((prev) => {
                                                    return {
                                                        ...prev,
                                                        transMode: e,
                                                    };
                                                });
                                            }}
                                            value={formatData.transMode}
                                            label="Transportation Mode"
                                            fullWidth
                                            inputWithSelect
                                            options={transportationList}
                                            // checkError={checkError && (!addressObject.country || addressObject.country?.trim() === "")}
                                            type="inputWithSelect"
                                        />
                                    </Box>
                                    <Box width="100%">
                                        <CustomInput
                                            onChange={(e) => {
                                                console.log(e);
                                                setFormatData((prev) => {
                                                    return {
                                                        ...prev,
                                                        shipmentType: e,
                                                    };
                                                });
                                            }}
                                            value={formatData.shipmentType}
                                            label="International Shipment Type"
                                            fullWidth
                                            inputWithSelect
                                            options={internationalShipmentTypeList}
                                            // checkError={checkError && (!addressObject.country || addressObject.country?.trim() === "")}
                                            type="inputWithSelect"
                                        />
                                    </Box>
                                </Box>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                {/*<Box*/}
                {/*  display="flex"*/}
                {/*  flexDirection="column"*/}
                {/*  width={matches ? "100%" : "40%"}*/}
                {/*  mt={matches ? 2 : ""}*/}
                {/*>*/}
                {/*  <div id="quote-content2">*/}
                {/*    <MuiThemeProvider theme={theme}>*/}
                {/*      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGBLocale}>*/}
                {/*        <Paper className={classes.paper}>*/}
                {/*          <DatePicker*/}
                {/*            autoOk*/}
                {/*            variant="static"*/}
                {/*            value={formatData.selectedDate}*/}
                {/*            onChange={handleChangeFromSelection(false)("selectedDate")}*/}
                {/*          />*/}
                {/*        </Paper>*/}
                {/*      </MuiPickersUtilsProvider>*/}
                {/*    </MuiThemeProvider>*/}
                {/*  </div>*/}
                {/*  <Box mt="2vh">*/}
                {/*    <div className={classes.subTitle}>Requested loading date</div>*/}
                {/*    <TextField*/}
                {/*      value={format(formatData.selectedDate, "dd/MM/yyyy")}*/}
                {/*      className={classes.input}*/}
                {/*    />*/}
                {/*  </Box>*/}
                {/*  <Box pt="1vh" width="100%">*/}
                {/*    {showErroMsg ? (*/}
                {/*      <Alert severity="error" classes={{ root: classes.alertRoot }}>*/}
                {/*        {amazonFba ? (*/}
                {/*          <span>*/}
                {/*            The deliver destination cannot be the amazon fulfillment*/}
                {/*            center*/}
                {/*          </span>*/}
                {/*        ) : (*/}
                {/*          <span>*/}
                {/*            Please enter information. <br /> * Mandatory*/}
                {/*          </span>*/}
                {/*        )}*/}
                {/*      </Alert>*/}
                {/*    ) : (*/}
                {/*      ""*/}
                {/*    )}*/}
                {/*  </Box>*/}
                {/*</Box>*/}
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={isWindowOpen}
                    onClose={() => setWindowOpenState(false)}
                >
                    <Box ml={1} className={classes.dialogTitle}>
                        <Box fontSize="1.5rem">
                            Address book {/*{isAddressBook && (*/}
                            {/*  <IconButton onClick={() => redirectAddressBook(history)}>*/}
                            {/*    <SettingsIcon />*/}
                            {/*  </IconButton>*/}
                            {/*)}*/}
                        </Box>
                        <IconButton onClick={() => setWindowOpenState(false)}>
                            <ClearIcon/>
                        </IconButton>
                    </Box>
                    {addressBookLoading ? (
                        <LoadingContainer/>
                    ) : (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            m={1}
                            mt={2}
                            mb={2}
                        >
                            <RadioGroup
                                aria-label="address"
                                name="addressBook"
                                // value={value}
                                // onChange={(e) => handleAddressSelection(currentField, e)}
                            >
                                {/*{(isAddressBook ? addressBook : warehouseAddress).map(*/}
                                {/*  (item, index, array) => (*/}
                                {/*    <Box key={index}>*/}
                                {/*      <FormControlLabel*/}
                                {/*        className={classes.addressItem}*/}
                                {/*        value={index}*/}
                                {/*        control={*/}
                                {/*          <Radio checked={value === index} color="primary" />*/}
                                {/*        }*/}
                                {/*        label={`${item.address}, ${item.city}, ${*/}
                                {/*          item.province*/}
                                {/*        } ${item.zipCode || ""}`}*/}
                                {/*      />*/}
                                {/*      {index !== array.length - 1 && (*/}
                                {/*        <div className={classes.divider} />*/}
                                {/*      )}*/}
                                {/*    </Box>*/}
                                {/*  )*/}
                                {/*)}*/}
                            </RadioGroup>
                        </Box>
                    )}
                </Dialog>
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={amazonFba}
                    onClose={() => {
                        setAmazonFba(false);
                    }}
                >
                    <div className={classes.dialogTitle}>
                        <Box fontSize="1.5rem"></Box>
                        <IconButton
                            style={{fill: "green"}}
                            onClick={() => {
                                setAmazonFba(false);
                            }}
                        >
                            <ClearIcon/>
                        </IconButton>
                    </div>
                    <Box
                        color="seagreen"
                        fontSize="1.5rem"
                        minHeight="20vh"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        margin="2vw"
                    >
                        The delivery destination cannot be the amazon fulfillment center.
                        (FBA)
                    </Box>
                    <Box display="flex" justifyContent="center" pb={4}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setAmazonFba(false);
                            }}
                            className={`${classes.outLinedButton} ${classes.fixWidthButton}`}
                        >
                            Go back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => next(true)}
                            className={`${classes.button} ${classes.fixWidthButton}`}
                        >
                            Continue
                        </Button>
                    </Box>
                </Dialog>
            </Box>
        </Container>
    );
};

const theme = createMuiTheme({
    palette: {
        primary: {light: orange[300], main: orange[500], dark: orange[700]},
    },
});

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "20px",
        // padding: theme.spacing(2, 4, 3),
        overflow: "hidden",
        width: "100%",
        margin: "0 auto",
    },
    button: {
        borderRadius: 5,
        fontSize: "12pt",
    },
    input: {
        color: "grey",
        width: "90%",
    },
    largeIcon: {
        fontSize: "200%",
    },
    label: {
        flexDirection: "column",
    },
    icon: {
        fontSize: "32px !important",
        marginBottom: theme.spacing(1),
    },
    subTitle: {
        color: "darkgreen",
        fontWeight: 600,
        fontSize: "1.5rem",
        fontFamily: "Montserrat",
    },
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    dialogTitle: {
        padding: "1vh 1vw 0.5vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "rgb(141, 173, 155)",
    },
    // button: {
    //   fontSize: "12pt",
    //   backgroundColor: "#ffa726",
    //   color: "white",
    //   margin: "7px",
    // },
    fixWidthButton: {
        width: "8rem",
    },
    outLinedButton: {
        fontSize: "12pt",
        // margin: "7px",
        marginRight: "24px",
    },
    noteWrapper: {
        // border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: 5,
        // padding: theme.spacing(1),
        backgroundColor: "#FEEAAE",
    },
    divider: {
        borderBottom: "1px solid rgba(0,0,0,0.5)",
        marginLeft: "24px",
        marginRight: "24px",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addressItem: {
        "&:hover": {
            backgroundColor: "rgba(141, 173, 155,0.5)",
        },
    },
    stepper: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(1),
    },
    root: {
        maxWidth: 400,
        flexGrow: 1,
        width: "100% !important",
        margin: "0 !important",
        height: "4rem",
    },
    accordion: {
        width: "100%"
    },
}));

// export default connect(mapStateToProps, {addPickUpDetails})(StartQuote);
export default AddressInputTab;
