import {actionType} from "../../utils/Helper";

const initialState = {
    landingEstimatePackage: {},
    landingEstimatePallet: {},
    landingEstimatePackageMissing: [],
    landingEstimatePalletMissing: [],
    landingEstimatePackageCost: [],
    landingEstimatePalletCost: {},
    landingIsClickSignIn: false,
    landingIsClickJoinUs: false,
    landingEstimatePickupPostalCodeValid: true,
    landingEstimateDeliverPostalCodeValid: true,
    landingEstimateTypeService: null,
    landingEstimateCostError: false,
    landingEstimateCostLoading: false,
    landingEstimateIsClickEstimate: false,
}

export const landingEstimateReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionType.LANDING_ESTIMATE_UPDATE_PACKAGE_DATA:
            return {...state, landingEstimatePackage: action?.payload}
        case actionType.LANDING_ESTIMATE_UPDATE_PALLET_DATA:
            return {...state, landingEstimatePallet: action?.payload}
        case actionType.LANDING_ESTIMATE_UPDATE_PACKAGE_MISSING:
            return {...state, landingEstimatePackageMissing: action?.payload}
        case actionType.LANDING_ESTIMATE_UPDATE_PALLET_MISSING:
            return {...state, landingEstimatePalletMissing: action?.payload}
        case actionType.LANDING_ESTIMATE_UPDATE_PACKAGE_COST:
            console.log('action payload', action?.payload);
            let copyState = state.landingEstimatePackageCost;

            const payloadState = action?.payload;
            payloadState.forEach(item => {
                if (!item.hasOwnProperty('errorMessage')) {
                    copyState = [...copyState, item]
                }
            })

            return {...state, landingEstimatePackageCost: copyState}
        case actionType.LANDING_ESTIMATE_UPDATE_PALLET_COST:
            return {...state, landingEstimatePalletCost: action?.payload}
        case actionType.LANDING_ESTIMATE_CLEAR_PACKAGE_COST:
            return {...state, landingEstimatePackageCost: []}
        case actionType.LANDING_ESTIMATE_CLEAR_PALLET_COST:
            return {...state, landingEstimatePalletCost: {}}
        case actionType.LANDING_UPDATE_CLICK_SIGN_IN:
            return {...state, landingIsClickSignIn: action?.payload}
        case actionType.LANDING_UPDATE_CLICK_JOIN_US:
            return {...state, landingIsClickJoinUs: action?.payload}
        case actionType.LANDING_ESTIMATE_UPDATE_PICKUP_POSTAL_CODE_VALIDATION:
            return {...state, landingEstimatePickupPostalCodeValid: action?.payload}
        case actionType.LANDING_ESTIMATE_UPDATE_DELIVER_POSTAL_CODE_VALIDATION:
            return {...state, landingEstimateDeliverPostalCodeValid: action?.payload}
        case actionType.LANDING_ESTIMATE_UPDATE_TYPE_SERVICE:
            return {...state, landingEstimateTypeService: action?.payload}
        case actionType.LANDING_ESTIMATE_UPDATE_COST_ERROR:
            return {...state, landingEstimateCostError: action?.payload}
        case actionType.LANDING_ESTIMATE_UPDATE_COST_LOADING:
            return {...state, landingEstimateCostLoading: action?.payload}
        case actionType.LANDING_ESTIMATE_UPDATE_CLICK_ESTIMATE:
            // console.log('is click estimate', action?.payload)
            return {...state, landingEstimateIsClickEstimate: action?.payload}
        case actionType.LANDING_ESTIMATE_CLEAR_TYPE_SERVICE:
            return {...state, landingEstimateTypeService: null}
        default:
            return state

    }

}