import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Stack} from "@mui/joy";
import Button from "@mui/material/Button";
import addressIcon from "../../images/Address.png";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {Checkbox, FormControlLabel, FormLabel, Link, MenuItem, Select} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {updateShippingPackageReturnLabelStatus} from "../Actions/shippingPackageAction";

const styles = {
    ShippingPackageCreateLabelShipFromRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelShipFromSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageCreateLabelShipFromHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelShipFromSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelShipFromSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    ShippingPackageCreateLabelShipFromText: {
        fontSize: '14px',
        textAlign: 'left',
        color: '#609966',
        fontWeight: '600',
    },
    ShippingPackageCreateLabelShipFromWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageCreateLabelShipFromModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelShipFromSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelShipFromAddressText: {
        fontSize: '14px'
    }
}

export const ShippingPackageImportShipmentUploadExcel = ({
                                                             cbGetCarrier,
                                                             cbGetReferenceOrderNumber,
                                                             validationResult,
                                                             cbGetUploadFile,
                                                             cbGetReturnLabel,
                                                             isAbleToReturn,
                                                             returnLabelAccount
                                                         }) => {

    const inputFile = useRef(null);

    const [returnLabel, setReturnLabel] = useState(false);
    const [selectCarrier, setSelectCarrier] = useState('default');
    const [referenceOrderNumber, setReferenceOrderNumber] = useState('');
    const [theInputKey, setTheInputKey] = useState(null);
    const [fileObj, setFileObj] = useState(null);
    const [contactFileObj, setContactFileObj] = useState(null);
    const [fileName, setFileName] = useState(null);

    const functionThatResetsTheFileInput = () => {
        const randomString = Math.random().toString(36);
        setTheInputKey(randomString);
    };

    const handleChange = (event) => {
        // console.log(event.target.value);
        setSelectCarrier(event.target.value);
    };

    const handleReferenceOrderNumber = event => {
        setReferenceOrderNumber(event.target.value)
    }

    const handleReturnLabel = event => {
        setReturnLabel(event?.target?.checked);
    }

    const onDrop = (_type) => (e) => {
        if (!(e.target.files && e.target.files[0])) return;
        if (_type === "bol") {
            setFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
        } else {
            setContactFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
            setFileName(e.target.files[0].name);
        }
    };

    useEffect(() => {
        if (selectCarrier === 'default') {
            cbGetCarrier(null);
        } else {
            cbGetCarrier(selectCarrier)
        }
    }, [selectCarrier])

    useEffect(() => {
        cbGetReferenceOrderNumber(referenceOrderNumber);
    }, [referenceOrderNumber]);

    useEffect(() => {
        cbGetReturnLabel(returnLabel);
    }, [returnLabel])

    useEffect(() => {
        if (fileName) {
            cbGetUploadFile({
                fileObj,
                contactFileObj
            })
        }
    }, [fileName])

    return (
        <Box sx={styles.ShippingPackageCreateLabelShipFromRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipFromSection}>
                        <Typography style={styles.ShippingPackageCreateLabelShipFromHeading}>
                            {intl.get('IMPORT_SHIPMENT.UPLOAD_BATCH_SHIPMENT_FILE')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCreateLabelShipFromSubHeading}>
                                {intl.get('IMPORT_SHIPMENT.IMPORT_BULK_SHIPMENT')}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={fileName}
                                fullWidth
                                disabled={true}
                                size='small'
                                error={validationResult !== null && !validationResult?.shipmentFile}
                                helperText={(validationResult !== null && !validationResult?.shipmentFile) && intl.get('CROSS_BORDER.REQUIRED')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <input
                                accept=".xlsx, .xls"
                                style={{display: "none"}}
                                ref={inputFile}
                                multiple
                                type="file"
                                // onChange={()={}}
                                onChange={onDrop("contacts")}
                                key={theInputKey || ""}
                            />
                            <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={() => {
                                    inputFile.current.click();
                                }}
                            >
                                <Typography style={{textTransform: 'none'}}>
                                    Upload
                                </Typography>
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://storage.googleapis.com/uucargo-resource/samples/shipping-package-list-sample.xlsx"
                                    color='#1D8B45'
                                >
                                    Download the CSV template
                                </Link> to ensure you fill out your batch shipment
                                details correctly.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCreateLabelShipFromSubHeading}>
                                {intl.get('IMPORT_SHIPMENT.REFERENCE_ORDER_NUMBER')}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <TextField
                                    value={referenceOrderNumber}
                                    size='small'
                                    onInput={handleReferenceOrderNumber}
                                    // error={validationResult !== null && !validationResult?.referenceOrderNumber}
                                    // helperText={(validationResult !== null && !validationResult?.referenceOrderNumber) && intl.get('CROSS_BORDER.REQUIRED')}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCreateLabelShipFromSubHeading}>
                                {intl.get('IMPORT_SHIPMENT.SELECT_CARRIER')}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <Select
                                    value={selectCarrier}
                                    size='small'
                                    onChange={handleChange}
                                    error={(validationResult !== null && !validationResult?.carrier) || !isAbleToReturn}
                                >
                                    <MenuItem disabled value='default'>
                                        <em>Select Carrier</em>
                                    </MenuItem>
                                    <MenuItem value='ups' sx={{fontSize: '14px'}}>
                                        UPS
                                    </MenuItem>
                                    <MenuItem value='canadaPost' sx={{fontSize: '14px'}}>
                                        Canada Post
                                    </MenuItem>
                                    <MenuItem value='canpar' sx={{fontSize: '14px'}}>
                                        Canpar
                                    </MenuItem>
                                    <MenuItem value='fedex' sx={{fontSize: '14px'}}>
                                        FedEx
                                    </MenuItem>
                                </Select>
                                <FormHelperText
                                    sx={{
                                        color: "error.main",
                                    }}
                                >
                                    {(validationResult !== null && !validationResult?.carrier) ? intl.get('CROSS_BORDER.REQUIRED') : !isAbleToReturn && 'Return label creation not allowed'}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                Select a carrier from our trusted partners
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCreateLabelShipFromSubHeading}>
                                Return Label
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                Return labels are for North American shipments only.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={styles.ShippingPackageCreateLabelSectionBox}>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox
                                            checked={returnLabel}
                                            onChange={handleReturnLabel}
                                            color="success"
                                        />}
                                        label={
                                            <Typography style={{textAlign: 'left'}}>
                                                Return Label
                                            </Typography>
                                        }
                                        labelPlacement="end"
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
