import React, { useState, useEffect } from "react";

import { Box, TextField } from "@material-ui/core";
import {
  americaProvinceList,
  canadaProvincesList,
  countryList,
} from "../../../shared/constInfo";
// import { makeStyles } from "@material-ui/core/styles";
// import { propTypes } from 'react-bootstrap/esm/Image';
import CustomInput from "../../../shared/customInput";

export default function AddressInputCombo({ handleChange, addressObject }) {
  const [provinceList, setProvinceList] = useState(
    addressObject?.country !== "CA" ? americaProvinceList : canadaProvincesList
  );

  useEffect(() => {
    if (addressObject.country === "US") {
      setProvinceList(americaProvinceList);
      return;
    }
    setProvinceList(canadaProvincesList);
  }, [addressObject.country]);

  return (
    <Box>
      <Box mr={1} display="flex" mb={1}>
        <Box flexBasis="50%" mr={1}>
          <CustomInput
            //   error={pickUp && checkError && !addressObject.phone}
            onChange={handleChange("name")}
            // value={provinces.find(ele => ele.value === addressObject.province) || ''}
            value={addressObject.name}
            type="line"
            label={"Name"}
          />
        </Box>
        <Box flexBasis="50%" ml={1}>
          <CustomInput
            //   error={pickUp && checkError && !addressObject.phone}
            onChange={handleChange("phone")}
            // value={provinces.find(ele => ele.value === addressObject.province) || ''}
            value={addressObject.phone}
            type="line"
            label={"Phone"}
          />
        </Box>
      </Box>
      <Box mr={1} display="flex" mb={1}>
        <Box flexBasis="50%" mr={1}>
          <CustomInput
              //   error={pickUp && checkError && !addressObject.phone}
              onChange={handleChange("companyName")}
              value={addressObject.companyName}
              type="line"
              label={"Company Name"}
          />
        </Box>
        <Box flexBasis="50%" ml={1}>
          <CustomInput
              //   error={pickUp && checkError && !addressObject.phone}
              onChange={handleChange("email")}
              // value={provinces.find(ele => ele.value === addressObject.province) || ''}
              value={addressObject.email}
              type="line"
              label={"Email"}
          />
        </Box>
      </Box>
      <Box>
        <CustomInput
          //   error={pickUp && checkError && !addressObject.phone}
          onChange={handleChange("address")}
          value={addressObject.address}
          type="line"
          label={"Address"}
        />
      </Box>
      <Box mr={1} display="flex" mb={1} mt={1}>
        <Box flexBasis="50%" mr={1}>
          <CustomInput
            onChange={handleChange("addressLineTwo")}
            value={addressObject.addressLineTwo}
            label="Address line 2"
            fullWidth
          />
        </Box>
        <Box flexBasis="50%" ml={1}>
          <CustomInput
            onChange={handleChange("zipCode")}
            value={addressObject.zipCode}
            type="line"
            label={"Zip Code"}
          />
        </Box>
      </Box>
      <Box mr={1} display="flex" mt={1} sx={{ gap: "0.4rem" }}>
        <Box mr={1} flexBasis="50%">
          <CustomInput
            onChange={handleChange("city")}
            value={addressObject.city}
            label={"City"}
          />
        </Box>
        <Box flexBasis="30%">
          <CustomInput
            onChange={handleChange("province")}
            // value={provinces.find(ele => ele.value === addressObject.province) || ''}
            value={addressObject.province}
            label="Province/State"
            // inputWithSelect
            type="select"
            options={provinceList}
          />
        </Box>
        <Box flexBasis="20%">
          <CustomInput
            onChange={handleChange("country")}
            // value={provinces.find(ele => ele.value === addressObject.province) || ''}
            value={addressObject.country}
            label="Country"
            // inputWithSelect
            type="select"
            options={countryList}
          />
        </Box>
      </Box>
    </Box>
  );
}
