import React, { useRef, useState} from "react";
import {Box, makeStyles} from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import { CSVLink } from "react-csv";
import xlsx from "json-as-xlsx";
import CurrencyFormat from "react-currency-format";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  },
  showMoreText: {
    color: "blue",
    textDecoration: "underline"
  }
}));

export default function OceanBookingListTable({ bookingId,
                                                startPortName,
                                                startPortCode,
                                                endPortName,
                                                endPortCode,
                                                shipCompany,
                                                routeCode,
                                                etd,
                                                eta,
                                                voyage,
                                                twentyGPPrice,
                                                twentyGPCount,
                                                fortyGPPrice,
                                                fortyGPCount,
                                                fortyHQPrice,
                                                forthHQCount,
                                                surcharges,
                                                createdDate
                                              }) {
  const classes = useStyles();

  const [isDisplayMore, setIsDisplayMore] = useState(false);

  const surChargeTextArray = surcharges.split("\n");

  return (
      <>
        <tr className={classes.listItemWrapper}>
          <td>{bookingId}</td>
          <td>
            <Box display="flex" flexDirection="column" justifyContent="left"  sx={{ gap: "0.5rem" }}>
              <Box textAlign="left">{startPortName} | {startPortCode}</Box>
              <Box textAlign="left">{endPortName} | {endPortCode}</Box>
            </Box>
          </td>
          <td style={{ minWidth: "350px" }}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row" justifyContent="left" sx={{ gap: "2rem" }}>
                <Box display="flex" flexDirection="column">
                  {
                    twentyGPCount > 0 && <span>20GP:</span>
                  }
                  {
                    fortyGPCount > 0 && <span>40GP:</span>
                  }
                  {
                    forthHQCount > 0 && <span>40HQ:</span>
                  }
                </Box>
                <Box display="flex" flexDirection="column">
                  {
                    twentyGPCount > 0 && <span>{+twentyGPPrice >= 88888 ? "Unavailable" : <CurrencyFormat value={(+twentyGPPrice).toFixed(1)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</span>
                  }
                  {
                    fortyGPCount > 0 && <span>{+fortyGPPrice >= 88888 ? "Unavailable" : <CurrencyFormat value={(+fortyHQPrice).toFixed(1)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</span>
                  }
                  {
                    forthHQCount > 0 && <span>{+fortyHQPrice >= 88888 ? "Unavailable" : <CurrencyFormat value={(+fortyHQPrice).toFixed(1)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</span>
                  }
                </Box>
              <Box display="flex" flexDirection="column">
                {
                    twentyGPCount > 0 && <span>{twentyGPCount}(count)</span>
                }
                {
                    fortyGPCount > 0 && <span>{fortyGPCount}(count)</span>
                }
                {
                    forthHQCount > 0 && <span>{forthHQCount}(count)</span>
                }
              </Box>
              </Box>
              {
                (() => {
                  if (!surcharges || surcharges === "") {
                    return <></>
                  }
                  return (
                      <Box textAlign="right" fontSize="small">
                        <span className={classes.showMoreText} onClick={() => {
                          setIsDisplayMore(v => !v);
                        }}
                              hidden={isDisplayMore}>
                          Show More
                        </span>
                    {
                      (() => {
                        if (isDisplayMore) {
                          return (
                              <Box display="flex" flexDirection="column">
                                {
                                  surChargeTextArray.filter(oneCharge => oneCharge?.trim() !== "").map(oneCharge => {
                                    return (
                                        <Box display="flex" sx={{ gap: "0.7rem" }} justifyContent="space-between">
                                            <span>- {oneCharge}</span>
                                        </Box>
                                    )
                                  })
                                }
                              </Box>
                          )
                        }
                      })()
                    }
                    <span className={classes.showMoreText} onClick={() => {
                      setIsDisplayMore(v => !v);
                    }} hidden={!isDisplayMore}>
                  Show Less
                </span>
                  </Box>
                  )
                })()
              }
            </Box>
          </td>
          <td>{shipCompany},&nbsp;&nbsp; {routeCode}</td>
          <td>{eta} / {etd}</td>
          <td>{voyage} days</td>
          <td>{createdDate}</td>
        </tr>
      </>
  );
}
