import React, { useRef, useState} from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI, SERVICE_SERVICE_URI} from "../../utils/apiUrl";
import { CSVLink } from "react-csv";
import xlsx from "json-as-xlsx";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function PartnerInvoiceTable({
                                              partner_id,
                                              date,
                                              invoiceId,
                                              invoiceNumber,
                                              accountBase
                                               }) {
  const classes = useStyles();
  const [trackings, setTrackings] = useState([])
  const csvTrackingLink = useRef();

  const getTrackingsData = async () => {
    try {
      const { data } = await axios.get(`${PARTNER_URI}/invoices/getInvoiceCSVData?partnerId=${partner_id}&invoiceId=${invoiceId}`);
      console.log(data);
      const headData = data.headData;
      const lineData = data.lineData;
      const resultArray = [];
      resultArray.push(headData.split(", "));
      for (const column of lineData) {
        const rowData = column.line_data.split(", ")
        resultArray.push(rowData);
      }
      setTrackings(v => {
        const temp = [...v];
        temp.push(...resultArray);
        return temp;
      });
      console.log(resultArray);
      csvTrackingLink.current.link.click()
    } catch (e) {
      console.log(e);
    }
  }

  return (
      <>
        <tr className={classes.listItemWrapper}>
          <td>{invoiceId}</td>
          <td>{invoiceNumber}</td>
          <td>{accountBase}</td>
          <td>{date}</td>
          <td>
            <div>
              <button onClick={getTrackingsData}>Download</button>
              <CSVLink
                  data={trackings}
                  filename={`${accountBase}-${invoiceNumber}-${partner_id}-${dayjs(date).format("YYYYMMDDHHMM")}.csv`}
                  className='hidden'
                  ref={csvTrackingLink}
                  target='_blank'
              />
            </div>
          </td>
        </tr>
      </>
  );
}
