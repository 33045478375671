import {Checkbox, Container, Divider} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import {ShippingPackageQuickQuoteGetRate} from "./ShippingPackageQuickQuoteGetRate";
import {ShippingPackageQuickQuoteLocation} from "./ShippingPackageQuickQuoteLocation";
import Tabs from "@mui/material/Tabs";
import {useEffect, useState} from "react";
import Tab from "@mui/material/Tab";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {ShippingPackageQuickQuoteRateList} from "./ShippingPackageQuickQuoteRateList";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useMediaQuery} from '@material-ui/core';
import {clearShippingPackageQuickQuotePackageList} from "../Actions/shippingPackageAction";
import packageIcon from '../../images/icons/packageIcon.png';
import ltlIcon from '../../images/icons/ltlIcon.png';
import brokerageIcon from '../../images/icons/brokerageIcon.png';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import intl from "react-intl-universal";
import {ShippingPackageQuickQuotePackageInformation} from "./ShippingPackageQuickQuotePackageInformation";
import {ShippingPackageQuickQuoteShippingOptions} from "./ShippingPackageQuickQuoteShippingOptions";
import PublicIcon from '@mui/icons-material/Public';
import {RefreshPageNotification} from "../../utils/Helper";

const styles = {
    ShippingPackageQuickQuoteRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        // margin: '100px 0'
    },
    ShippingPackageQuickQuoteSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column'
    },
    ShippingPackageQuickQuoteButtonSection: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        // borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column'
    },
    ShippingPackageQuickQuoteHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        width: '100%',
        backgroundColor: 'transparent',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        color: '#545454',
        // border: '1px solid #609966',
        // borderRadius: '25px',
        '&.Mui-selected': {
            color: '#000000',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

export const ShippingPackageQuickQuote = () => {

    const dispatch = useDispatch();

    let packageIsGetRate = useSelector(state => state?.shippingPackage?.quickQuoteIsGetRate);
    let packageListRedux = useSelector(state => state?.shippingPackage?.quickQuotePackageList);

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const [isCallAllCarrier, setIsCallAllCarrier] = useState(0);

    const getIsCallAllCarrier = (data) => {
        console.log('is call all carrier', data);
        setIsCallAllCarrier(data);
    }

    useEffect(() => {
        dispatch(clearShippingPackageQuickQuotePackageList());
    }, [])

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingPackageQuickQuoteRoot}>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <Typography style={styles.ShippingPackageQuickQuoteHeading}>
                        {intl.get('QUICK_QUOTE_NAV.SELECT_TYPE')}
                    </Typography>
                    <Box sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked
                            />
                            <img src={packageIcon} alt="packageIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.PACKAGE')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/shipping-package/international-shipment')}
                            />
                            <PublicIcon sx={{
                                fontSize: '40px'
                            }}/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.INTERNATION')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/LTL-quick-quote')}
                            />
                            <img src={ltlIcon} alt="ltlIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.LTL')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                onClick={() => history.push('/brokerage/estimate')}
                            />
                            <img src={brokerageIcon} alt="brokerageIcon" width='40px'/>
                            <Typography style={{color: '#545454', padding: '0 5px'}}>
                                {intl.get('QUICK_QUOTE_NAV.BROKERAGE')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <ShippingPackageQuickQuoteLocation isGetRate={packageIsGetRate}/>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <ShippingPackageQuickQuotePackageInformation packageListRedux={packageListRedux}
                                                                 isGetRate={packageIsGetRate}/>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteSection}>
                    <ShippingPackageQuickQuoteShippingOptions isGetRate={packageIsGetRate}/>
                </Box>
                <Box sx={styles.ShippingPackageQuickQuoteButtonSection}>
                    <ShippingPackageQuickQuoteGetRate getIsCallAllCarrier={getIsCallAllCarrier}/>
                </Box>
                {
                    packageIsGetRate &&
                    <Box sx={styles.ShippingPackageQuickQuoteSection}>
                        <ShippingPackageQuickQuoteRateList isCallAllCarrier={isCallAllCarrier}/>
                    </Box>
                }
            </Box>
        </Container>
    )
}