import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import {} from "@mui/material";

import {createTheme, ThemeProvider} from "@mui/material/styles";
import {orange, red, green, grey} from "@mui/material/colors";
import LoadingButton from "@mui/lab/LoadingButton";

import TrackPackageItem from "./TrackPackageItem";

import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import {updateTrackingNumber} from "../Actions/trackingPackageAction";

const theme = createTheme({
    palette: {
        primary: orange,
        danger: red,
        success: green,
        warning: red,
        secondary: grey,
    },
});

const TrackPackages = () => {

    const dispatch = useDispatch();

    const trackingNumbersRedux = useSelector(state => state?.trackingPackage?.trackingNumber);

    const [trackedPackagesList, setTrackedPackagesList] = useState([])
    const [trackingNumbers, setTrackingNumbers] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isTrackEmpty, setIsTrackEmpty] = useState(false);

    const getTrackedPackageList = async () => {
        try {
            if (trackingNumbers?.trim() === "") {
                return;
            }

            setIsLoading(true);
            const storedToken = getAccessToken("access_token");
            const {data, status} = await axios({
                method: "get",
                url: `${PARTNER_URI}/loose-item/3rd-party/multipleTrackingForWeb?trackingNumbers=${trackingNumbers}`,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                }
            });
            const trackingList = data?.filter(v => v?.trackingResponse !== null);
            setIsTrackEmpty(trackingList?.length === 0);

            if (status === 200) {
                console.log("Success:", data);
                setTrackedPackagesList(trackingList)
                setStatusMessage("Successfully Scheduled Pickup");
            } else {
                console.log("Error:", data);
                throw new Error();
            }
        } catch (e) {
            setErrorMessage("Failed to Schedule Pickup. Try again another time.");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setTrackingNumbers(trackingNumbersRedux);
    },[])

    useEffect(() => {
        dispatch(updateTrackingNumber(trackingNumbers));
    },[trackingNumbers])

    console.log('tracking number', trackingNumbers);
    console.log('tracking number redux', trackingNumbersRedux);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
            }}>
                <Box sx={{
                    mt: 4,
                    width: "800px",
                    // backgroundColor: "orange",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Box sx={{
                        p: 4,
                        width: "70%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                        backgroundColor: "white",
                        borderRadius: 2,
                    }}>
                        <Box>
                            <Typography variant="h5">Track</Typography>
                        </Box>
                        <Typography>Enter up to 25 tracking numbers, one per line.</Typography>
                        <TextField
                            sx={{
                                width: "100%",
                                mb: 1,
                            }}
                            multiline
                            value={trackingNumbers}
                            onChange={v => {
                                setTrackingNumbers(v.target.value);
                            }}
                            rows={6}
                            // placeholder="Enter your comma seperated tracking numbers here"
                        />
                        <LoadingButton
                            loading={isLoading}
                            variant="outlined"
                            onClick={getTrackedPackageList}
                            sx={{
                                color: "white",
                                backgroundColor: "primary.main",
                                "&:hover": {
                                    color: "primary.main",
                                    backgroundColor: "white",
                                    borderColor: "primary.main",
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                },
                                borderRadius: 5,
                                width: 100,
                                height: 35,
                                mb: 1,
                            }}>Track</LoadingButton>
                        {
                            isTrackEmpty && (
                                <Box>
                                    Tracking Results Not Found
                                </Box>
                            )
                        }
                        {
                            trackedPackagesList.map((trackedPackage, index) => {
                                return (
                                    <TrackPackageItem key={index}
                                                      activity={trackedPackage.trackingResponse ? trackedPackage.trackingResponse.activity : undefined}
                                                      trackingNo={trackedPackage.trackingNumber}
                                                      courier={trackedPackage.accountBase}
                                                      deliverStatus={trackedPackage.trackingResponse.status}
                                                      transaction={trackedPackage.transaction}
                                    />
                                )
                            })
                        }
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default TrackPackages;
