import Box from "@mui/material/Box";
import {Snackbar} from "@mui/material";
import React, {useEffect, useState} from 'react';
import MuiAlert from "@mui/material/Alert";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TransportationClaimTasksRemarkNote = ({
                                                       taskDetails,
                                                       getOrderDetails,
                                                       id,
                                                       remarkNoteAPI
                                                   }) => {

    const storedToken = getAccessToken("access_token");

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [remarkNote, setRemarkNote] = useState('');
    const [updateRemarkNote, setUpdateRemarkNote] = useState(false);
    const [remarkNoteLoading, setRemarkNoteLoading] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleRemarkNote = (event) => {
        setRemarkNote(event?.target?.value);
    }

    const cancelRemarkNote = () => {
        setUpdateRemarkNote(false);
        setRemarkNote(taskDetails?.task_remark_notes);
    }

    const editRemarkNotes = async () => {
        setRemarkNoteLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/editRemarkNotesForShipmentTask`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    backendOrderId: id,
                    remarkNotes: remarkNote
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully edit remark note.');
            setUpdateRemarkNote(false);
            setTimeout(() => {
                getOrderDetails();
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage("Fail to edit remark note.");
        } finally {
            setRemarkNoteLoading(false);
        }
    }

    const handleEditRemarkNote = () => {
        editRemarkNotes();
    }

    useEffect(() => {
        setRemarkNote(remarkNoteAPI);
    }, [remarkNoteAPI])

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Claimed Order Remake Note
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        value={remarkNote}
                                        multiline
                                        rows={4}
                                        size='small'
                                        disabled={!updateRemarkNote}
                                        onInput={handleRemarkNote}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: '5px'
                                }}>
                                    {
                                        !updateRemarkNote ? <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={() => setUpdateRemarkNote(true)}
                                            size='small'
                                        >
                                            <Typography style={{textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button> : <>
                                            <Button
                                                variant='contained'
                                                onClick={cancelRemarkNote}
                                                size='small'
                                            >
                                                <Typography style={{textTransform: 'none'}}>
                                                    Cancel
                                                </Typography>
                                            </Button>
                                            <LoadingButton
                                                variant='contained'
                                                sx={{
                                                    backgroundColor: '#1D8B45',
                                                    "&:hover": {
                                                        backgroundColor: '#1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={handleEditRemarkNote}
                                                loading={remarkNoteLoading}
                                                size='small'
                                            >
                                                <Typography style={{textTransform: 'none'}}>
                                                    Confirm
                                                </Typography>
                                            </LoadingButton>
                                        </>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}