import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {bottomNavigationActionClasses, Divider, Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {useEffect, useState} from "react";
import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import {getAccessToken} from "../../utils/doToken";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    AddressRoot: {
        margin: '100px auto',
        width: '800px',
        paddingBottom: '40px'
    },

    AddressListHeader: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        gap: '10px'
    },

    AddressListHeaderAdd: {
        width: '30%',
    },

    AddressListHeaderTab: {
        width: '70%'
    },

    AddressListHeaderButton: {
        justifyContent: 'flex-start',
        borderRadius: '40px'
    },

    AddressListTitle: {
        flexGrow: 1,
        margin: '15px auto',
        width: '100%',
        backgroundColor: '#444444',
        color: '#ffffff',
        borderRadius: '5px'
    },

    AddressListWhite: {
        flexGrow: 1,
        backgroundColor: '#ffffff',
        margin: '15px auto',
        borderRadius: '5px',
        width: '100%'
    },

    AddressListDark: {
        flexGrow: 1,
        backgroundColor: '#eeeeee',
        margin: '15px auto',
        borderRadius: '5px',
        width: '100%'
    },

    AddressListItem: {
        maxHeight: '400px'
    },
    AddressListItemBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        margin: ' 0 10px',
        height: '100%'
    },


    AddressListItemText: {
        textAlign: 'left'
    }

}

export const MyStaff = () => {

    const {id: partnerId, backendUserId } = useSelector((state) => state.user);

    const [toastOpen, setToastOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [successMessage, setSuccessMessage] = useState("");

    const [staffList, setStaffList] = useState([]);

    const [selectStaffId, setSelectStaffId] = useState(null);

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    const [removeLoading, setRemoveLoading] = useState(false);
    const [addStaffLoading, setAddStaffLoading] = useState(false);

    const [inputEmail, setInputEmail] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleRemoveDialogOpen = () => {
        setRemoveDialogOpen(true);
    };

    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    };


    const getAllStaffList = async () => {

        let requestURL = `${PARTNER_URI}/adminUser/getAllStaffList`;
        const accessToken = getAccessToken("access_token");
        try {
            const { data } = await axios.get(requestURL, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            setStaffList(data);
        } catch (e) {
            console.log(e);
        }
    }

    const removeStaff = async (id) => {

        let requestURL = `${PARTNER_URI}/adminUser/removeOneStaffFromCompany`;
        const accessToken = getAccessToken("access_token");
        try {
            await axios.post(requestURL, {
                "staffId": id
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            handleSuccessMessage();
            setErrorMessage("");
            setSuccessMessage("Remove Success!");
            await getAllStaffList();
        } catch (e) {
            console.log(e.response);
            handleErrorMessage();
            setErrorMessage("fail to remove staff");
        }
    }

    const removeStaffFromSelectID = async (e) => {
        let requestURL = `${PARTNER_URI}/adminUser/removeOneStaffFromCompany`;
        const accessToken = getAccessToken("access_token");
        setRemoveLoading(true);
        try {
            await axios.post(requestURL, {
                "staffId": selectStaffId
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            handleSuccessMessage();
            setErrorMessage("");
            setSuccessMessage("Remove Success!");
            handleRemoveDialogClose();
            await getAllStaffList();
        } catch (e) {
            console.log(e.response);
            handleErrorMessage();
            setErrorMessage("fail to remove staff");
        } finally {
            setRemoveLoading(false);
        }
    }

    const addStaffByEmail = async () => {
        try {
            setAddStaffLoading(true);
            let requestURL = `${PARTNER_URI}/adminUser/addStaffToCompany`;
            const accessToken = getAccessToken("access_token");

            await axios.post(requestURL, {
                "staffEmail": inputEmail
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            setErrorMessage("");
            setSuccessMessage("Add Staff Success!");
            setInputEmail("");
            handleSuccessMessage();
            await getAllStaffList();

        } catch (e) {
            setErrorMessage(e?.response?.data?.error || "Error!");
            handleErrorMessage();
            console.log(e);
        } finally {
            setAddStaffLoading(false);
        }
    }


    useEffect(() => {
        getAllStaffList();
    }, [])

    console.log('staff list', staffList);

    return (
        <Box sx={style.AddressRoot}>

            {/*Confirm Remove User */}
            <Dialog
                open={removeDialogOpen}
                onClose={handleRemoveDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Remove Staff From Company
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will remove this staff from current company. Are you sure to cancel?
                        <hr />
                        Click <b>Confirm</b> to remove this staff.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose} variant="outlined" autoFocus type="button" >No</Button>
                    <Button onClick={removeStaffFromSelectID} variant="outlined" type="button" disabled={removeLoading} >
                        {removeLoading && <CircularProgress size={14} />}
                        {!removeLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Confirm Remove User */}

            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>

            <Typography variant="h4">
                My STAFF List
            </Typography>

            <Box flex="45%" width="100%" mt={2} sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: "0.5rem"
            }}>
                <TextField
                    id="outlined-basic"
                    label="Add Staff By Email"
                    variant="outlined"
                    required
                    type="email"
                    value={inputEmail}
                    onChange={e => {
                        setInputEmail(e.target.value);
                    }}
                    fullWidth
                />
                {/*<Button*/}
                {/*    type="submit" variant="contained" color="primary"*/}
                {/*    onClick={() => {}}*/}
                {/*>*/}
                {/*    Add*/}
                {/*</Button>*/}
                <Button onClick={() => {
                    addStaffByEmail();
                }}  type="submit" variant="contained" color="primary" disabled={addStaffLoading} >
                    {addStaffLoading && <CircularProgress size={14} />}
                    {!addStaffLoading && "Add"}
                </Button>
            </Box>

            <hr style={{marginBlock: "3rem"}} />


            <Box sx={style.AddressListTitle}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography>
                                NAME
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>
                                Email
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>
                                PHONE
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>
                                REMOVE
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            {
                staffList.filter((staff) => staff.id !== backendUserId)?.map((staff, index) => {
                    return <Box sx={index % 2 === 0 ? style.AddressListWhite : style.AddressListDark} key={index}>
                        <Grid container spacing={2}>
                            <Grid item xs={3} sx={style.AddressListItem}>
                                <Box>
                                    <Typography style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
                                        {staff.firstname || ""} {staff.lastname || ""}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box>
                                    <Typography style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
                                        {staff.email}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box>
                                    <Typography>
                                        {staff.phone}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box>
                                    <IconButton onClick={() => {
                                        setSelectStaffId(staff.id);
                                        handleRemoveDialogOpen();
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                })
            }
        </Box>

    )
}