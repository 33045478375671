import React, {useState, useEffect} from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import {useSelector} from "react-redux";
import ShippingTransactions from "./ShippingTransactions";

export default function ShippingTransactionCard({classes, orderId}) {
    const [transactionList, setTransactionList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {firstname, id} = useSelector((state) => state.user);

    // const getPaidInvoicesList = async () => {
    //   let requestURI = `${PARTNER_URI}/billingCenter/getNotBilledOrderListForPartner`;
    //   const storedToken = getAccessToken("access_token");
    //   try {
    //     const { data } = await axios({
    //       method: "get",
    //       url: requestURI,
    //       headers: {
    //         Authorization: `Bearer ${storedToken}`,
    //       },
    //     });
    //     console.log("not billed orders: ", data);
    //     setOrders(data);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // };
    const getTransactionListByOrderId = async () => {
        try {
            setIsLoading(true);
            const requestURI = `${PARTNER_URI}/shipping/list?&order_id=${orderId}&partner_id=${id}`;
            const {data} = await axios.get(requestURI);
            const result = data.result;
            const resultTotalNumber = data.totalNumber;
            // setTotalNumber(resultTotalNumber);
            setTransactionList(result);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getTransactionListByOrderId();
    }, [orderId]);

    return (
        <Grid item xs={6} md={6} lg={6} xl={6}>
            <Card
                className={classes.paper}
                style={{minHeight: "55vh", boxShadow: "none"}}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h6">{`Shipping Transactions`}</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        {" "}
                        <ShippingTransactions transactionList={transactionList}/>
                    </Grid>
                    {/*<Grid item xs={12} md={12} lg={12}>*/}
                    {/*  <Button variant="contained">{`Check More`}</Button>*/}
                    {/*</Grid>*/}
                </Grid>
            </Card>
        </Grid>
    );
}
