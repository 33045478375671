import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Divider,
    Snackbar,
    useMediaQuery
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {mapCarrierLogo} from "../../utils/Helper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Fragment, useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {deleteToken, getAccessToken} from "../../utils/doToken";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CircularProgress from "@mui/material/CircularProgress";
import {
    clearShippingPackageCreateLabelPackageList,
    clearShippingPackageCrossBorderItemList, clearShippingPackageQuickQuotePackageList,
    updateShipFromPackageCreateLabelPackage,
    updateShippingPackageInternationalShipmentShipFrom,
    updateShippingPackageInternationalShipmentShipTo,
    updateShipToPackageCreateLabelPackage
} from "../Actions/shippingPackageAction";
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
}

export const ShippingPackageCreateLabelOrderDetails = () => {

    const dispatch = useDispatch();
    const token = getAccessToken("access_token");
    const isMobile = useMediaQuery('(max-width: 600px)');
    const history = useHistory();

    let returnLabel = useSelector(state => state?.shippingPackage?.returnLabel);
    let signatureOptions = useSelector(state => state?.shippingPackage?.createLabelSignatureOptions);
    let batteryDetails = useSelector(state => state?.shippingPackage?.createLabelBatteryDetails);
    let selectRate = useSelector(state => state?.shippingPackage?.createLabelRateSelect);
    let packageListRedux = useSelector(state => state?.shippingPackage?.createLabelPackageList);
    let shipFrom = useSelector(state => state?.shippingPackage?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.createLabelShipTo);
    let referenceOrderNumber = useSelector(state => state?.shippingPackage?.createLabelReferenceOrderNumber);
    let crossBorder = useSelector(state => state?.shippingPackage?.createLabelCrossBorder);
    let crossBorderRate = useSelector(state => state?.shippingPackage?.crossBorderRate);
    let crossBorderItemListRedux = useSelector(state => state?.shippingPackage?.crossBorderItemList);
    const userInfo = useSelector((state) => state.user);

    const [loadingLabel, setLoadingLabel] = useState(false);
    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);
    const [labelImage, setLabelImage] = useState();
    const [buyShippingStatus, setBuyShippingStatus] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    console.log('select rate', selectRate);
    // console.log('package list', packageListRedux);
    // console.log('ship from', shipFrom);
    // console.log('user info', userInfo);
    // console.log('cross border rate', crossBorderRate);
    // console.log('reference order number', referenceOrderNumber);
    // console.log('cross border item list', crossBorderItemListRedux);
    console.log('cross border', crossBorder);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const partnerGetLabel = async () => {
        setLoadingLabel(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/getSampleLabelByAccountBaseAndService`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let data = {
            accountBase: selectRate?.accountBase,
            serviceType: selectRate?.serviceType,
            shipFromName: shipFrom?.name,
            shipFromCompanyName: shipFrom?.company,
            shipFromPhone: shipFrom?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipFromEmail: shipFrom?.email,
            shipFromAddress: shipFrom?.displayAddress?.text,
            shipFromCity: shipFrom?.city,
            shipFromProvince: shipFrom?.province?.code,
            shipFromCountry: shipFrom?.country,
            shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
            shipToName: shipTo?.name,
            shipToCompanyName: shipTo?.company,
            shipToPhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipToEmail: shipTo?.email,
            shipToAddress: shipTo?.displayAddress?.text,
            shipToAddressTwo: shipTo?.address2,
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToPostalCode: shipTo?.postalCode?.replace(/\s+/g, '').toUpperCase(),
            shipToCountry: shipTo?.country,
            cargoControlNumber: "",
            shipmentSpecialServices: {
                returnShipment: returnLabel
            },
            refOrderNumber: referenceOrderNumber,
            packageData: packageData
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            // console.log('result', result?.data?.result);
            setLabelImage(result?.data?.result);
            setLoadingLabel(false);
            setSuccessMessage('Successful Get Sample Label.');
            setErrorMessage("");
            handleSuccessMessage();

        } catch (e) {
            console.log('error', e);
            setLoadingLabel(false);
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            } else {
                setErrorMessage('Fail To Get Sample Label.');
            }
            handleErrorMessage();
        }
    }

    const partnerBuyShipping = async () => {
        setLoadingPlaceOrder(true);
        setBuyShippingStatus(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/shipping`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        // concat address 1 and 2 into address 1
        let shipFromAddress = shipFrom?.address2 ? `${shipFrom?.address2?.trim()}-${shipFrom?.displayAddress?.text}` : shipFrom?.displayAddress?.text

        let data = {
            account_base_combine_name: selectRate?.accountBaseCombineName,
            service_type: selectRate?.serviceType,
            user_email: userInfo?.email,
            pickup_address: shipFrom?.displayAddress?.text,
            pickup_area: shipFrom?.city,
            deliver_address: shipTo?.displayAddress?.text,
            deliver_area: shipTo?.city,
            cargo_control_no: "",
            ref_order_no: referenceOrderNumber,
            deliver_address_two: shipTo?.address2,
            schedule_pickup: "2021-09-27 09:29",
            pickup_address_obj: {
                address: shipFromAddress,
                addressLineTwo: shipFrom?.address2,
                city: shipFrom?.city,
                postal_code: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
                province: shipFrom?.province?.code,
                companyName: shipFrom?.company,
                country: shipFrom?.country,
                name: shipFrom?.name,
                email: shipFrom?.email
            },
            pickup_phone: shipFrom?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            pickup_email: shipFrom?.email,
            deliver_address_obj: {
                address: shipTo?.displayAddress?.text,
                city: shipTo?.city,
                addressLineTwo: shipTo?.address2,
                postal_code: shipTo?.postalCode.replace(/\s+/g, '').toUpperCase(),
                province: shipTo?.province?.code,
                companyName: shipTo?.company,
                country: shipTo?.country,
                name: shipTo?.name,
                email: shipTo?.email
            },
            delivery_phone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            deliver_email: shipTo?.email,
            shipmentType: shipFrom.country === shipTo.country ? null : crossBorder?.shipmentType,
            transMode: shipFrom.country === shipTo.country ? null : crossBorder?.tranMode,
            shipmentSpecialServices: {
                returnShipment: returnLabel // true | false
            },
            package_data: packageData,
            packingList: selectRate?.isCrossBoarder ? crossBorderItemListRedux : []
        }

        try {

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('result', result.data);

            if (result?.data?.shippingResData[0]?.tranStatus === 1) {
                setErrorMessage('');
                handleSuccessMessage();
                setSuccessMessage('Successfully place order.');

                dispatch(updateShipFromPackageCreateLabelPackage({
                    postalCode: null,
                    province: null,
                    city: null,
                    address: null,
                    address2: null,
                    country: null,
                    displayAddress: {
                        description: null,
                        placeId: null,
                        text: null
                    },
                    name: null,
                    phone: null,
                    email: null,
                    company: null,
                    saveAddress: false,
                }))
                dispatch(updateShipToPackageCreateLabelPackage({
                    postalCode: null,
                    province: null,
                    city: null,
                    address: null,
                    address2: null,
                    country: null,
                    displayAddress: {
                        description: null,
                        placeId: null,
                        text: null
                    },
                    name: null,
                    phone: null,
                    email: null,
                    company: null,
                    saveAddress: false,
                }))
                dispatch(clearShippingPackageCreateLabelPackageList());
                dispatch(clearShippingPackageQuickQuotePackageList());
                dispatch(clearShippingPackageCrossBorderItemList());
                dispatch(updateShippingPackageInternationalShipmentShipFrom(null));
                dispatch(updateShippingPackageInternationalShipmentShipTo(null));
                setTimeout(() => {
                    history.push(`/package-order-dashboard/${result?.data?.shippingOrderData?.orderId}`);

                }, 2000);
            } else {
                handleErrorMessage();
                setErrorMessage(`Fail to place order.${result?.data?.shippingResData[0]?.tranMessage}`);
            }

        } catch (e) {
            console.log(e.response);
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            } else {
                setErrorMessage(`Fail to place order. ${e.response?.data?.message || 'Please try again later.'}`);
            }
            handleErrorMessage();
        } finally {
            setLoadingPlaceOrder(false);
        }

    }

    const northAmericaShipment = () => {
        if ((shipFrom?.country?.toLowerCase() === 'ca' || shipFrom?.country?.toLowerCase() === 'us') && (shipTo?.country?.toLowerCase() === 'ca' || shipTo?.country?.toLowerCase() === 'us')) {
            return true
        } else {
            return false
        }
    }

    const handleGetLabel = () => {
        setLabelImage(null);
        partnerGetLabel();
    }

    const handleQuoteList = () => {
        const backToRateList = northAmericaShipment();
        if (backToRateList) {
            history.push('/shipping-package/create-label/rate-list');
        } else {
            history.push('/shipping-package/international-shipment/rate-list');
        }
    }

    const handlePlaceOrder = () => {
        partnerBuyShipping();
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    if (!shipFrom?.country && !buyShippingStatus) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsHeading}>
                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SHIPPING_ORDER_DETAILS')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                You've lost all the data. Please return to the home page and create a new shipment.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                <Button
                                    variant='contained'
                                    onClick={() => history.replace('/home')}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Go Back Home
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsHeading}>
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SHIPPING_ORDER_DETAILS')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.PACKAGE_DETAILS')}
                                    </Typography>
                                </Grid>
                                {
                                    !isMobile &&
                                    <>
                                        <Grid item xs={3}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.PACKAGE_NAME')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.DIMENSION')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.QUANTITY')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.WEIGHT')}
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                            {
                                packageListRedux.length > 0 && packageListRedux?.map((each, index) => (
                                    <Grid container spacing={1} key={index}>
                                        <Grid item md={3} xs={12}>
                                            <Typography
                                                style={isMobile ? styles.ShippingPackageCreateLabelOrderDetailsTextLeft : styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {isMobile && `${intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.PACKAGE_NAME')}:`} {each?.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Typography
                                                style={isMobile ? styles.ShippingPackageCreateLabelOrderDetailsTextLeft : styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {isMobile && `${intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.DIMENSION')}:`} {each?.length} x {each?.width} x {each?.height} {each?.lengthUnit}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Typography
                                                style={isMobile ? styles.ShippingPackageCreateLabelOrderDetailsTextLeft : styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {isMobile && `${intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.QUANTITY')}:`} {each?.quantity}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <Typography
                                                style={isMobile ? styles.ShippingPackageCreateLabelOrderDetailsTextLeft : styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {isMobile && `${intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.WEIGHT')}:`} {each?.weight} {each?.weightUnit}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                    <span
                                        style={{color: 'red'}}>*</span>{intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.MESSAGE')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                        {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipFrom?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipFrom?.address2 && `${shipFrom?.address2} - `}{shipFrom?.address}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipFrom?.city}, {shipFrom?.province?.code}, {shipFrom?.country}, {shipFrom?.postalCode}
                                    </Typography>
                                </Grid>
                                {shipFrom?.email &&
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.email}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipFrom?.phone}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                        {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipTo?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipTo?.address2 && `${shipTo?.address2} - `}{shipTo?.address}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipTo?.city}, {shipTo?.province?.code}, {shipTo?.country}, {shipTo?.postalCode}
                                    </Typography>
                                </Grid>
                                {shipTo?.email &&
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.email}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipTo?.phone}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SHIPPING_CARRIER')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{border: '2px solid #609966', borderRadius: '10px'}}>
                                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSectionPrice}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3}>
                                                    <Box sx={styles?.ShippingPackageCreateLabelOrderDetailsImageBox}>
                                                        <img src={mapCarrierLogo(selectRate?.accountBase)} alt="logo"
                                                             width='60px'/>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Box
                                                        sx={styles.ShippingPackageCreateLabelOrderDetailsOtherBoxCenter}>
                                                        <Typography
                                                            sx={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                            {selectRate?.serviceName}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Box
                                                        sx={styles.ShippingPackageCreateLabelOrderDetailsOtherBoxCenter}>
                                                        <Typography
                                                            sx={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                            {selectRate?.shipRange} business days
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Box
                                                        sx={styles.ShippingPackageCreateLabelOrderDetailsOtherBoxRight}>
                                                        <Typography
                                                            sx={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            $ {selectRate?.isCrossBoarder ?
                                                            (+selectRate?.finalTotalAmount?.value + crossBorderRate?.grandTotal).toFixed(2)
                                                            :
                                                            (+selectRate?.finalTotalAmount?.value).toFixed(2)
                                                        }
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box sx={{borderTop: '2px solid #609966', padding: '0 20px'}}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <Accordion
                                                        sx={{
                                                            boxShadow: 'none',
                                                        }}
                                                    >
                                                        <AccordionSummary
                                                            sx={{
                                                                flexDirection: 'row-reverse'
                                                            }}
                                                            expandIcon={<ExpandMoreIcon/>}
                                                        >
                                                            < Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PRICE_DETAILS')}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={1}>
                                                                {
                                                                    selectRate?.shipmentCharges?.map((each, index) => {
                                                                        const cost = +each?.value
                                                                        if (cost !== 0) {
                                                                            return (
                                                                                <Fragment key={index}>
                                                                                    <Grid item xs={6}>
                                                                                        <Typography
                                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                            {each?.description}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={6}>
                                                                                        <Typography
                                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                            $ {(+each?.value)?.toFixed(2)}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Fragment>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                {
                                                                    (+selectRate?.serviceFee?.value) > 0 &&
                                                                    <>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                Service Fee
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                $ {selectRate?.serviceFee?.value}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                <Grid item xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        Subtotal
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        $ {((+selectRate?.totalCharge?.value) + selectRate?.serviceFee?.value).toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        Tax
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        $ {((+selectRate?.serviceFeeTax?.value) + selectRate?.taxes?.reduce((total, item) => total + (+item?.value), 0))?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                                {selectRate?.isCrossBoarder &&
                                                                    <>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                Total Brokerage Fee
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                $ {crossBorderRate?.totalBrokerageFees?.toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                Total Duty and Tax
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                $ {crossBorderRate?.totalDutyandTax?.toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                <Grid item xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                                        Total
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                                        $ {selectRate?.isCrossBoarder ?
                                                                        (+selectRate?.finalTotalAmount?.value + crossBorderRate?.grandTotal).toFixed(2)
                                                                        :
                                                                        (+selectRate?.finalTotalAmount?.value).toFixed(2)
                                                                    }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SAMPLE_LABEL')}
                                    </Typography>
                                </Grid>
                                {!labelImage &&
                                    <Grid item xs={12}>
                                        {/*<LoadingButton*/}
                                        {/*    variant='contained'*/}
                                        {/*    color='warning'*/}
                                        {/*    size='large'*/}
                                        {/*    loading={loadingLabel}*/}
                                        {/*    onClick={handleGetLabel}*/}
                                        {/*    startIcon={<AddBoxIcon/>}*/}
                                        {/*>*/}
                                        {/*</LoadingButton>*/}
                                        <IconButton
                                            sx={{color: '#609966'}}
                                            onClick={handleGetLabel}
                                            disabled={loadingLabel}
                                        >
                                            {
                                                loadingLabel ?
                                                    <CircularProgress size={24}/>
                                                    :
                                                    <AddBoxIcon/>
                                            }
                                        </IconButton>
                                        <Typography style={{fontSize: '12px', fontWeight: '600', color: '#609966'}}>
                                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.GET_LABEL')}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    {
                                        labelImage &&
                                        <Box sx={{display: 'flex', justifyContent: 'center', margin: '40px 0'}}>
                                            <img
                                                src={`data:image/jpeg;base64,${labelImage}`}
                                                alt="label"
                                                width={isMobile ? 300 : 700}
                                                height="auto"
                                            />
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                            <Button
                                size='large'
                                variant='contained'
                                onClick={handleQuoteList}
                            >
                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.BACK')}
                            </Button>
                            <LoadingButton
                                size='large'
                                variant='contained'
                                color='success'
                                loading={loadingPlaceOrder}
                                onClick={handlePlaceOrder}
                            >
                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PLACE_ORDER')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}