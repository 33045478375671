import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import {Snackbar} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import {useState} from "react";
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TopUpConfirm = ({referenceNumber, selectPayment, amount, selectCardId, coupon, selectOther}) => {

    const storedToken = getAccessToken("access_token");

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const TopUpViaEmailReferenceNumber = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/billingCenter/partnerTopUpByEmailReferenceNumber`;

        const data = {
            referenceNumber: referenceNumber,
            coupon: coupon?.coupon
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: data
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successful Top Up');
            handleSuccessMessage();
            setLoading(false);
            setTimeout(() => {
                history.push("/billing-center");
            }, 2000);
        } catch (e) {
            console.log(e.response)
            setErrorMessage(e?.response?.data?.error);
            handleErrorMessage();
            setLoading(false);
        }
    }

    const TopUpViaCreditCard = async () => {
        const requestURL = `${PARTNER_URI}/billingCenter/initDownPaymentInvoiceAndTopUpViaCreditCardForPartner`;
        setLoading(true);
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: {
                    amount: amount,
                    creditCardId: selectCardId,
                    coupon: coupon?.coupon
                }
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully Top Up');
            handleSuccessMessage();
            setLoading(false);
            setTimeout(() => {
                history.push("/billing-center");
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Fail To Top Up');
            handleErrorMessage();
            setLoading(false);
        }
    }

    const handleEmailTopUp = () => {
        TopUpViaEmailReferenceNumber();
    }

    const handleCreditCardTopUp = () => {
        TopUpViaCreditCard();
    }

    console.log('reference number in TopUpConfirm component', referenceNumber);
    console.log('select payment in TopUpConfirm component', selectPayment);
    console.log('amount in TopUpConfirm component', amount);
    console.log('select card id in TopUpConfirm component', selectCardId);

    return (
        <Grid item xs={12}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
                {/*<Button*/}
                {/*    variant='contained'*/}
                {/*    sx={{*/}
                {/*        backgroundColor: '#F2BE22',*/}
                {/*        "&:hover": {*/}
                {/*            backgroundColor: '#F2BE22',*/}
                {/*            filter: 'brightness(0.9)'*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onClick={() => history.push('/billing-center')}*/}
                {/*>*/}
                {/*    <Typography style={{textTransform: 'none', fontSize: '14px'}}>*/}
                {/*        Back to Billing Center*/}
                {/*    </Typography>*/}
                {/*</Button>*/}
                {
                    selectPayment === 'email' && <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleEmailTopUp}
                        loading={loading}
                        disabled={referenceNumber ? false : true}
                    >
                        <Typography style={{textTransform: 'none', fontSize: '18px'}}>
                            {intl.get('BILLING_CENTER.TOP_UP_PAGE.PAY')}
                        </Typography>
                    </LoadingButton>
                }
                {
                    selectPayment === 'credit' && <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleCreditCardTopUp}
                        loading={loading}
                        disabled={!selectCardId || +amount < 50}
                    >
                        <Typography style={{textTransform: 'none', fontSize: '18px'}}>
                            {intl.get('BILLING_CENTER.TOP_UP_PAGE.PAY')}
                        </Typography>
                    </LoadingButton>
                }
                {
                    selectPayment === 'other' && <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={() => history.push('/billing-center')}
                        disabled={!selectOther}
                    >
                        <Typography style={{textTransform: 'none', fontSize: '18px'}}>
                            {intl.get('BILLING_CENTER.TOP_UP_PAGE.PAY')}
                        </Typography>
                    </LoadingButton>
                }
            </Box>
        </Grid>
    )
}