import {Container, Divider, FormLabel, MenuItem, Select, Snackbar} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect, useState, Fragment} from "react";
import GoogleMapReact from "google-map-react";
import {TransportationAvailableTasksMapMarker} from "./TransportationAvailableTasksMapMarker";
import RoomIcon from "@material-ui/icons/Room";
import Carousel from 'react-material-ui-carousel'
import Paper from "@mui/material/Paper";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import {useHistory, useParams} from "react-router-dom";
import React from 'react';
import MuiAlert from "@mui/material/Alert";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import {movingOrderStatus, movingShippingStatus} from "../../utils/Helper";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {PalletCalculator} from "./PalletCalculator";
import {TransportationClaimTasksContent} from "./TransportationClaimTasksContent";
import {TransportationClaimTasksSummary} from "./TransportationClaimTasksSummary";
import {TransportationClaimTasksAddress} from "./TransportationClaimTasksAddress";
import {TransportationClaimTasksDataAndTime} from "./TransportationClaimTasksDataAndTime";
import {TransportationClaimTasksContact} from "./TransportationClaimTasksContact";
import {TransportationClaimTasksAdditionalService} from "./TransportationClaimTasksAdditionalService";
import {TransportationClaimTasksRemarkNote} from "./TransportationClaimTasksRemarkNote";
import {TransportationClaimTasksCost} from "./TransportationClaimTasksCost";

const styles = {
    ShippingPackageCreateLabelRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },
    ShippingPackageCreateLabelRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelRowRight: {
        display: 'flex',
        justifyContent: 'flex-end'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TransportationClaimTasksDetails = () => {

    const {id} = useParams();
    const storedToken = getAccessToken("access_token");
    const history = useHistory();

    const currentDateTime = new Date().toISOString();
    const currentDate = currentDateTime.split('T')[0];
    const currentTime = currentDateTime.split('T')[1].split('.')[0];

    const [cost, setCost] = useState(0);
    const [pickupDate, setPickupDate] = useState(null);
    const [deliverDate, setDeliverDate] = useState(null);
    const [pickupTime, setPickupTime] = useState(null);
    const [deliverTime, setDeliverTime] = useState(null);
    const [updateOrder, setUpdateOrder] = useState(false);
    const [remarkNote, setRemarkNote] = useState('');
    const [directions, setDirections] = useState(null);
    const [taskDetails, setTaskDetails] = useState(null);
    const [pickupCoordinate, setPickupCoordinate] = useState(null);
    const [deliverCoordinate, setDeliverCoordinate] = useState(null);
    const [googleApi, setGoogleApi] = useState(null);
    const [additionalData, setAdditionalData] = useState(null);
    const [additionalService, setAdditionalService] = useState([]);
    const [storageService, setStorageService] = useState({
        cubicMeterSpace: 0,
        storageDays: 0
    });
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [unclaimedLoading, setUnclaimedLoading] = useState(false);
    const [updateOrderLoading, setUpdateOrderLoading] = useState(false);
    const [discardOrderDialog, setDiscardOrderDialog] = useState(false);
    const [pickupConfirmLoading, setPickupConfirmLoading] = useState(false);
    const [pickupConfirmDialog, setPickupConfirmDialog] = useState(false);
    const [deliverConfirmLoading, setDeliverConfirmLoading] = useState(false);
    const [deliverConfirmDialog, setDeliverConfirmDialog] = useState(false);
    const [paidPaymentList, setPaidPaymentList] = useState([]);
    const [declinedPaymentList, setDeclinedPaymentList] = useState([]);
    const [underReviewPaymentList, setUnderReviewPaymentList] = useState([]);
    const [pickupWarehouseCoordinate, setPickupWarehouseCoordinate] = useState(null);
    const [deliveryWarehouseCoordinate, setDeliveryWarehouseCoordinate] = useState(null);
    const [pickupClientCoordinate, setPickupClientCoordinate] = useState(null);
    const [deliverClientCoordinate, setDeliverClientCoordinate] = useState(null);
    // const [mapInitialized, setMapInitialized] = useState(false); // State to track map initialization status

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDiscardOrderDialog = () => {
        setDiscardOrderDialog(!discardOrderDialog);
    };

    const handleDiscardOrderDialogClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setDiscardOrderDialog(!discardOrderDialog);
        }
    }

    const isValidJSON = (jsonString) => {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    }

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    }

    const convertAddressToCoordinate = async (address) => {
        let requestURL = `${PARTNER_URI}/googleMaps/findPlace`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: address
                }
            });

            const location = result?.data?.data?.candidates[0]?.geometry?.location;
            return location;
        } catch (e) {
            console.log('error', e?.response);
        }
    }

    const handlePickupCoordinate = async (coordinate, address) => {
        if (coordinate) {
            const [latitude, longitude] = coordinate.split(', ');
            setPickupCoordinate(prevState => ({
                ...prevState,
                lat: +latitude,
                lng: +longitude
            }))
        } else {
            const location = await convertAddressToCoordinate(address);
            // console.log('[TransportationAvailableTasksDetails] handlePickupCoordinate - location', location);
            setPickupCoordinate(prevState => ({
                ...prevState,
                lat: +location?.lat,
                lng: +location?.lng
            }));
        }
    }

    const handleDeliverCoordinate = async (coordinate, address) => {
        if (coordinate) {
            const [latitude, longitude] = coordinate.split(', ');
            setDeliverCoordinate(prevState => ({
                ...prevState,
                lat: +latitude,
                lng: +longitude
            }))
        } else {
            const location = await convertAddressToCoordinate(address);
            // console.log('[TransportationAvailableTasksDetails] handlePickupCoordinate - location', location);
            setDeliverCoordinate(prevState => ({
                ...prevState,
                lat: +location?.lat,
                lng: +location?.lng
            }));
        }
    }

    const handlePickupClientCoordinate = async (coordinate, address) => {
        if (coordinate) {
            const [latitude, longitude] = coordinate.split(', ');
            setPickupClientCoordinate(prevState => ({
                ...prevState,
                lat: +latitude,
                lng: +longitude
            }))
        } else {
            const location = await convertAddressToCoordinate(address);
            // console.log('[TransportationAvailableTasksDetails] handlePickupCoordinate - location', location);
            setPickupClientCoordinate(prevState => ({
                ...prevState,
                lat: +location?.lat,
                lng: +location?.lng
            }));
        }
    }

    const handleDeliverClientCoordinate = async (coordinate, address) => {
        if (coordinate) {
            const [latitude, longitude] = coordinate.split(', ');
            setDeliverClientCoordinate(prevState => ({
                ...prevState,
                lat: +latitude,
                lng: +longitude
            }))
        } else {
            const location = await convertAddressToCoordinate(address);
            // console.log('[TransportationAvailableTasksDetails] handlePickupCoordinate - location', location);
            setDeliverClientCoordinate(prevState => ({
                ...prevState,
                lat: +location?.lat,
                lng: +location?.lng
            }));
        }
    }

    const getOrderDetails = async () => {
        let requestURL = `${PARTNER_URI}/movingService/getMovingOrderDashBoardDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                params: {
                    backendOrderId: id
                }
            });
            console.log('[TransportationAvailableTasksDetails] getOrderDetails - result', result?.data);
            setTaskDetails(result?.data);
            if (result?.data?.pickup_warehouse?.id === result?.data?.deliver_warehouse?.id) {
                await handlePickupCoordinate(result?.data?.pickup_addr_lat_lng, result?.data?.pickup_address);
                await handleDeliverCoordinate(result?.data?.deliver_addr_lat_lng, result?.data?.deliver_address);
            } else {
                await handlePickupCoordinate(result?.data?.pickup_addr_lat_lng, result?.data?.pickup_address);
                setDeliverCoordinate(prevState => ({
                    ...prevState,
                    lat: +result?.data?.pickup_warehouse?.address_latitude,
                    lng: +result?.data?.pickup_warehouse?.address_longitude
                }))
            }
            await handlePickupClientCoordinate(result?.data?.pickup_addr_lat_lng, result?.data?.pickup_address);
            await handleDeliverClientCoordinate(result?.data?.deliver_addr_lat_lng, result?.data?.deliver_address);
            setPickupWarehouseCoordinate(prevState => ({
                ...prevState,
                lat: +result?.data?.pickup_warehouse?.address_latitude,
                lng: +result?.data?.pickup_warehouse?.address_longitude
            }));
            setDeliveryWarehouseCoordinate(prevState => ({
                ...prevState,
                lat: +result?.data?.deliver_warehouse?.address_latitude,
                lng: +result?.data?.deliver_warehouse?.address_longitude
            }))
            if (result?.data?.messages) {
                const jsonString = result?.data?.messages;
                const json = JSON.parse(jsonString);
                if (isValidJSON(jsonString) && !isEmptyObject(json)) {
                    const jsonData = JSON.parse(jsonString);
                    setAdditionalData(jsonData);
                    const additionalServicesArray = Object.keys(jsonData?.additionalService)
                        .filter(service => jsonData?.additionalService[service] === true)
                        .map(service => {
                            const capitalizedService = service.charAt(0).toUpperCase() + service.slice(1);
                            return capitalizedService.replace(/([a-z])([A-Z])/g, '$1 $2');
                        });
                    setAdditionalService(additionalServicesArray);
                }
                // For Storage Service
                if (isValidJSON(jsonString)) {
                    const jsonData = JSON?.parse(jsonString);
                    if (jsonData?.additionalService?.storageService) {
                        const jsonStorageService = jsonData?.additionalService?.storageService;
                        setStorageService(jsonStorageService);
                    }
                }
            }
            setRemarkNote(result?.data?.task_remark_notes);
            setCost(result?.data?.taskShippingFee);
            let pickupDateOriginal = result?.data?.schedule_pickup?.split(' ')[0];
            let deliverDateOriginal = result?.data?.schedule_deliver?.split(' ')[0];
            let pickupTimeOriginal = result?.data?.schedule_pickup?.split(' ')[1];
            let deliverTimeOriginal = result?.data?.schedule_deliver?.split(' ')[1];

            pickupDateOriginal ? setPickupDate(pickupDateOriginal) : setPickupDate(currentDate);
            deliverDateOriginal ? setDeliverDate(deliverDateOriginal) : setDeliverDate(currentDate);
            pickupTimeOriginal ? setPickupTime(pickupTimeOriginal) : setPickupTime('10:00');
            deliverTimeOriginal ? setDeliverTime(deliverTimeOriginal) : setDeliverTime('16:00');

            const paidPaymentList = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "CAPTURED_SUCCEEDED");
            const declinedPaymentList = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "DECLINE");
            const underReviewPaymentList = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "UNDER_REVIEW");
            setPaidPaymentList(paidPaymentList);
            setDeclinedPaymentList(declinedPaymentList);
            setUnderReviewPaymentList(underReviewPaymentList);

        } catch (e) {
            console.log('[TransportationAvailableTasksDetails] getOrderDetails - error', e);
        } finally {

        }
    }

    const placeMarkers = (googleApi, map) => {
        // Add custom markers for pickup and delivery
        new googleApi.maps.Marker({
            position: new googleApi.maps.LatLng(pickupCoordinate.lat, pickupCoordinate.lng),
            map: map,
            // Custom marker options here
        });

        new googleApi.maps.Marker({
            position: new googleApi.maps.LatLng(deliverCoordinate.lat, deliverCoordinate.lng),
            map: map,
            // Custom marker options here
        });
    };

    const calculateDirections = (directionsService, directionsRenderer) => {

        const originLatLng = new googleApi.maps.LatLng(pickupCoordinate?.lat, pickupCoordinate?.lng);
        const destinationLatLng = new googleApi.maps.LatLng(deliverCoordinate?.lat, deliverCoordinate?.lng);

        directionsService.route(
            {
                origin: originLatLng,
                destination: destinationLatLng,
                travelMode: googleApi.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === googleApi.maps.DirectionsStatus.OK) {
                    // Suppress default markers
                    directionsRenderer.setOptions({
                        suppressMarkers: true,
                    });

                    directionsRenderer.setDirections(result);

                    // Add custom markers for pickup and delivery
                    new googleApi.maps.Marker({
                        position: new googleApi.maps.LatLng(pickupCoordinate.lat, pickupCoordinate.lng),
                        map: directionsRenderer.getMap(),
                        // label: 'P', // Custom label for pickup,
                        icon: {
                            path: googleApi.maps.SymbolPath.CIRCLE,
                            scale: 6, // Adjust the scale as needed
                            fillColor: '#FFFFFF', // Set the fill color
                            fillOpacity: 1, // Set the fill opacity
                            strokeWeight: 2, // Set the stroke weight to 0 for no border
                        },
                    });

                    new googleApi.maps.Marker({
                        position: new googleApi.maps.LatLng(deliverCoordinate.lat, deliverCoordinate.lng),
                        map: directionsRenderer.getMap(),
                        label: 'D', // Custom label for delivery
                    });

                    const map = directionsRenderer.getMap();
                    map.setZoom(4);

                } else {
                    console.error(`Directions request failed due to ${status}`);
                }
            }
        );
    };

    const handleCost = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setCost(value);
        }
    }

    const handlePickupDate = (event) => {
        const inputDate = event.target.value;
        if (!inputDate) {
            setPickupDate(inputDate);
        } else if (inputDate <= currentDate) {
            setPickupDate(currentDate);
        } else if (inputDate >= deliverDate) {
            setPickupDate(deliverDate);
        } else {
            setPickupDate(inputDate);
        }
    }

    const handleDeliverDate = (event) => {
        const inputDate = event.target.value;
        if (!inputDate) {
            setDeliverDate(inputDate);
        } else if (inputDate <= currentDate) {
            setDeliverDate(currentDate);
        } else if (inputDate <= pickupDate) {
            setDeliverDate(pickupDate);
        } else {
            setDeliverDate(inputDate);
        }
    }

    const handlePickupTime = (event) => {
        setPickupTime(event?.target?.value);
    }

    const handleDeliverTime = (event) => {
        setDeliverTime(event?.target?.value);
    }

    const unclaimOrder = async () => {
        setUnclaimedLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/partnerUnClaimMovingOrder`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    backendOrderId: id
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully discard order.');
            setTimeout(() => {
                history.push(`/transportation/claimTasks`);
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage("Fail to discard order.");
        } finally {
            setUnclaimedLoading(false);
        }
    }

    const handleDiscardOrder = () => {
        unclaimOrder();
    }

    const askEnduserToUpdateOrder = async () => {

        setUpdateOrderLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/partnerWaitingForApproval`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    backendOrderId: id,
                    price: cost,
                    pickupDate: pickupDate ? `${pickupDate} ${pickupTime}` : null,
                    deliverDate: deliverDate ? `${deliverDate} ${deliverTime}` : null,
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully send updated the order.');
            setUpdateOrder(false);
            setTimeout(() => {
                getOrderDetails();
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage("Fail to send updated the order.");
        } finally {
            setUpdateOrderLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        askEnduserToUpdateOrder();
    }

    const confirmPickup = async () => {
        setPickupConfirmLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/confirmPickup`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    backendOrderId: id,
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully pickup confirm and waiting for approval.');
            handleConfirmPickupDialogClose();
            setTimeout(() => {
                getOrderDetails();
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage("Fail to pickup confirm.");
        } finally {
            setPickupConfirmLoading(false);
        }
    }

    const confirmDeliver = async () => {
        setDeliverConfirmLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/confirmDeliver`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    backendOrderId: id,
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully deliver confirm and waiting for approval.');
            handleConfirmDeliverDialogClose();
            setTimeout(() => {
                getOrderDetails();
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage("Fail to deliver confirm.");
        } finally {
            setDeliverConfirmLoading(false);
        }
    }

    const handleConfirmPickupDialogOpen = () => {
        setPickupConfirmDialog(true);
    }

    const handleConfirmPickupDialogClose = () => {
        setPickupConfirmDialog(false);
    }

    const handleConfirmPickup = () => {
        confirmPickup();
    }

    const handleConfirmDeliverDialogOpen = () => {
        setDeliverConfirmDialog(true);
    }

    const handleConfirmDeliverDialogClose = () => {
        setDeliverConfirmDialog(false);
    }

    const handleConfirmDeliver = () => {
        confirmDeliver();
    }

    const handleChangeRouteCoordinate = (data) => {
        if (data === 'pickup') {
            setPickupCoordinate(pickupWarehouseCoordinate);
            setDeliverCoordinate(pickupClientCoordinate);
        } else {
            setPickupCoordinate(deliveryWarehouseCoordinate);
            setDeliverCoordinate(deliverClientCoordinate);
        }
    }

    useEffect(() => {
        getOrderDetails();
    }, []);

    useEffect(() => {

        console.log('window,google', window.google);

        if (googleApi) {

            const directionsService = new googleApi.maps.DirectionsService();
            const directionsRenderer = new googleApi.maps.DirectionsRenderer();
            calculateDirections(directionsService, directionsRenderer);
            setDirections(directionsRenderer);

            // Listen for the 'directions_changed' event on the DirectionsRenderer
            googleApi.maps.event.addListener(directionsRenderer, 'directions_changed', () => {
                // After the directions are changed (i.e., rendered), check if the map is fully loaded
                if (googleApi.map) {
                    googleApi.maps.event.addListenerOnce(googleApi.map, 'tilesloaded', () => {
                        // console.log('pickup coordinate', pickupCoordinate);
                        // console.log('deliver coordinate', deliverCoordinate);
                        // check if the pickup and deliver coordinate is same
                        if (pickupCoordinate.lat === deliverCoordinate.lat && pickupCoordinate.lng === deliverCoordinate.lng) {
                            googleApi.map.setZoom(10); // Set your desired zoom level here
                        }
                    });
                }
            });
        }
    }, [googleApi, pickupCoordinate, deliverCoordinate]);

    useEffect(() => {
        if (directions) {
            directions.setMap(googleApi.map);
        }
    }, [directions]);

// console.log('[TransportationClaimTasksDetails] pickupClientCoordinate', pickupClientCoordinate);
// console.log('[TransportationClaimTasksDetails] deliverClientCoordinate', deliverClientCoordinate);
// console.log('[TransportationClaimTasksDetails] pickupWarehouseCoordinate', pickupWarehouseCoordinate);
// console.log('[TransportationClaimTasksDetails] deliverWarehouseCoordinate', deliveryWarehouseCoordinate);

    if (taskDetails?.shipping_status_id === 1) {
        history.push(`/transportation/availableTasks/details/${id}`)
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={discardOrderDialog}
                onClose={handleDiscardOrderDialogClose}
            >
                <DialogTitle>Discard Order Confirmation</DialogTitle>
                <DialogContent>
                    Are you sure you want to discard your order? This action cannot be undone. Please confirm if you
                    would like to proceed with the request.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDiscardOrderDialog}
                        variant="contained"
                        autoFocus
                    >
                        CLOSE
                    </Button>
                    <LoadingButton
                        onClick={handleDiscardOrder}
                        variant="contained"
                        autoFocus
                        loading={unclaimedLoading}
                    >
                        CONFIRM
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                marginBottom: '30px'
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '5px'
                }}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#454545',
                            "&:hover": {
                                backgroundColor: '#454545',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={() => history.push('/transportation/claimTasks')}
                    >
                        <Typography style={{
                            textAlign: 'left',
                            textTransform: 'none'
                        }}>
                            Back To Order List
                        </Typography>
                    </Button>
                </Box>
                <TransportationClaimTasksSummary taskDetails={taskDetails}/>
                <TransportationClaimTasksCost taskDetails={taskDetails}
                                              underReviewPaymentList={underReviewPaymentList}
                                              getOrderDetails={getOrderDetails}
                                              paidPaymentList={paidPaymentList}/>
                <TransportationClaimTasksAddress taskDetails={taskDetails}
                                                 pickupCoordinate={pickupCoordinate}
                                                 googleApi={googleApi}
                                                 setGoogleApi={setGoogleApi}
                                                 additionalData={additionalData}
                                                 handleChangeRouteCoordinate={handleChangeRouteCoordinate}
                    // setMapInitialized={setMapInitialized}
                />
                <TransportationClaimTasksDataAndTime taskDetails={taskDetails}/>
                <TransportationClaimTasksContact taskDetails={taskDetails}/>
                <TransportationClaimTasksContent taskDetails={taskDetails}/>
                {
                    additionalService?.length > 0 &&
                    <TransportationClaimTasksAdditionalService additionalService={additionalService}
                                                               storageService={storageService}/>
                }
                <TransportationClaimTasksRemarkNote taskDetails={taskDetails}
                                                    getOrderDetails={getOrderDetails}
                                                    id={id}
                                                    remarkNoteAPI={remarkNote}
                />
                {
                    !updateOrder &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                        {
                            (taskDetails?.shipping_status_id === 2) &&
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '5px'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#F2BE22',
                                        "&:hover": {
                                            backgroundColor: '#F2BE22',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    // loading={unclaimedLoading}
                                    onClick={handleDiscardOrderDialog}
                                >
                                    <Typography style={{
                                        textAlign: 'left',
                                        textTransform: 'none'
                                    }}>
                                        Discard Order
                                    </Typography>
                                </Button>
                            </Box>
                        }
                        {
                            taskDetails?.shipping_status_id === 2 &&
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '5px'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => setUpdateOrder(true)}
                                >
                                    <Typography style={{
                                        textAlign: 'left',
                                        textTransform: 'none'
                                    }}>
                                        Update Order
                                    </Typography>
                                </Button>
                            </Box>
                        }
                    </Box>
                }
                {
                    updateOrder &&
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                Update Order
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}>
                                    <FormLabel required>
                                        Adjusted Subtotal <span style={{fontSize: '14px'}}>(charge before tax)</span>
                                    </FormLabel>
                                </Box>
                                <TextField
                                    value={cost}
                                    size='small'
                                    type='number'
                                    onInput={handleCost}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}>
                                    <FormLabel required>
                                        Pickup Date
                                    </FormLabel>
                                </Box>
                                <TextField
                                    value={pickupDate}
                                    onChange={handlePickupDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size='small'
                                    type='date'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}>
                                    <FormLabel required>
                                        Pickup Time
                                    </FormLabel>
                                </Box>
                                <TextField
                                    fullWidth
                                    type='time'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={pickupTime}
                                    onChange={handlePickupTime}
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}>
                                    <FormLabel required>
                                        Delivery Date
                                    </FormLabel>
                                </Box>
                                <TextField
                                    value={deliverDate}
                                    onChange={handleDeliverDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size='small'
                                    type='date'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}>
                                    <FormLabel required>
                                        Deliver Time
                                    </FormLabel>
                                </Box>
                                <TextField
                                    fullWidth
                                    type='time'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={deliverTime}
                                    onChange={handleDeliverTime}
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '5px'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#454545',
                                        "&:hover": {
                                            backgroundColor: '#454545',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => setUpdateOrder(false)}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Cancel
                                    </Typography>
                                </Button>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleUpdateOrder}
                                    loading={updateOrderLoading}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Send
                                    < /Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {
                    (taskDetails?.shipping_status_id === 25 && !pickupConfirmDialog) &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '5px'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleConfirmPickupDialogOpen}
                        >
                            <Typography style={{
                                textAlign: 'left',
                                textTransform: 'none'
                            }}>
                                Confirm Pickup
                            </Typography>
                        </Button>
                    </Box>
                }
                {
                    pickupConfirmDialog && <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                You have picked up all items and sent the message to the end user, now waiting for them to
                                confirm. Please confirm if you would like to proceed with the request.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '10px'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#454545',
                                        "&:hover": {
                                            backgroundColor: '#454545',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    // size='small'
                                    onClick={handleConfirmPickupDialogClose}>
                                    <Typography sx={{textTransform: 'none'}}>
                                        Cancel
                                    </Typography>
                                </Button>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    // size='small'
                                    onClick={handleConfirmPickup}
                                    loading={pickupConfirmLoading}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Confirm
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {
                    ((taskDetails?.shipping_status_id === 3 || taskDetails?.shipping_status_id === 30) && !deliverConfirmDialog) &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '5px'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleConfirmDeliverDialogOpen}
                        >
                            <Typography style={{
                                textAlign: 'left',
                                textTransform: 'none'
                            }}>
                                Confirm Delivery
                            </Typography>
                        </Button>
                    </Box>
                }
                {
                    deliverConfirmDialog && <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                You have delivered all items and sent the message to the end user, now waiting for them to
                                confirm. Please confirm if you would like to proceed with the request.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '10px'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#454545',
                                        "&:hover": {
                                            backgroundColor: '#454545',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    // size='small'
                                    onClick={handleConfirmDeliverDialogClose}>
                                    <Typography sx={{textTransform: 'none'}}>
                                        Cancel
                                    </Typography>
                                </Button>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    // size='small'
                                    onClick={handleConfirmDeliver}
                                    loading={deliverConfirmLoading}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Confirm
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Container>
    )
}
