import {actionType} from "../../utils/Helper";

export const updateTrackingNumber = trackingNumber => ({
    type: actionType.UPDATE_TRACKING_NUMBER,
    payload: trackingNumber
})

export const updateTrackingNumberBOL = trackingNumber => ({
    type: actionType.UPDATE_TRACKING_NUMBER_BOL,
    payload: trackingNumber
})