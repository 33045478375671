import {useDispatch, useSelector} from "react-redux";
import {Stack, Switch, useMediaQuery} from "@mui/material";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    addShippingLTLCreateLabelPalletList,
    initShippingLTLCreateLabelPalletList,
    updateShippingLTLCreateLabelUnit
} from "../Actions/shippingLTLAction";
import {generateRandomString} from "../../utils/Helper";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import IconButton from "@mui/material/IconButton";
import addPallet from "../../images/Create Label.png";
import {ShippingLTLCreateLabelPalletEach} from "./ShippingLTLCreateLabelPalletEach";

const styles = {
    ShippingPackageCreateLabelPackageDetailsRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },

    ShippingPackageCreateLabelPackageDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },

    ShippingPackageCreateLabelPackageDetailsSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    ShippingPackageCreateLabelPackageDetailsSectionColumn: {
        display: 'flex',
        flexDirection: 'column',
    },

    ShippingPackageCreateLabelPackageDetailsModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '15px 15px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelPackageDetailsSelectBox: {
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelPackageDetailsSelectCommonBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    }
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const ShippingLTLCreateLabelPallet = ({palletListRedux, palletListHistory, metricHistory}) => {

    const dispatch = useDispatch();

    let metric = useSelector(state => state?.shippingLTL?.createLabelUnit);

    const [palletList, setPalletList] = useState([]);
    const [itemId, setItemId] = useState(1);

    const handleMetric = (event) => {
        dispatch(updateShippingLTLCreateLabelUnit(event.target.checked));
    }

    const handleAddPallet = () => {
        const palletId = generateRandomString(8);
        const newPallet = {
            description: '',
            packagingType: metric ? {name: '122cmx102cm', length: '122', width: '102'} : {
                name: '48"x40"',
                length: '48',
                width: '40'
            },
            length: metric ? '122' : '48',
            width: metric ? '102' : '40',
            height: metric ? '152' : '60',
            weight: metric ? '180' : '400',
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb',
            UOM: '',
            hscode: '',
            originCountryCode: '',
            currency: '',
            priceEach: '',
            quantity: '1',
            palletId: palletId
        }
        dispatch(addShippingLTLCreateLabelPalletList(newPallet));
        setItemId(prevState => prevState + 1);
    }

    useEffect(() => {
        setPalletList(palletListRedux);
    }, [palletListRedux]);

    useEffect(() => {
        if (palletListHistory?.length > 0) {
            dispatch(initShippingLTLCreateLabelPalletList(palletListHistory));
        }
    }, [palletListHistory])

    useEffect(() => {
        dispatch(updateShippingLTLCreateLabelUnit(metricHistory || false));
    },[])

    // console.log('pallet list', palletList);
    // console.log('pallet list redux', palletListRedux);
    // console.log('metric', metric);

    return (
        <Box sx={styles.ShippingPackageCreateLabelPackageDetailsRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelPackageDetailsSection}>
                        <Typography sx={styles.ShippingPackageCreateLabelPackageDetailsHeading}>
                            {intl.get('ADD_PALLET.PALLET_INFORMATION')}
                        </Typography>
                        <Box sx={styles.ShippingPackageCreateLabelPackageDetailsSection}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <AntSwitch
                                    checked={metric}
                                    onChange={handleMetric}
                                    inputProps={{'aria-label': 'ant design'}}
                                />
                                <Typography>
                                    {intl.get('ADD_PALLET.METRIC')}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <IconButton
                                    sx={{color: '#609966'}}
                                    onClick={handleAddPallet}
                                >
                                    <img src={addPallet} width='35px'/>
                                </IconButton>
                                <Typography>
                                    {intl.get('ADD_PALLET.ADD_NEW_BTN')}
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Grid>
                {
                    palletList?.length > 0 ? palletList?.map((eachPallet, index) =>
                        <Grid item xs={12} key={index}>
                            <ShippingLTLCreateLabelPalletEach palletInfo={eachPallet}/>
                        </Grid>
                    ) : <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #B7B7B7',
                            padding: '20px',
                            borderRadius: '10px'
                        }}>
                            <Typography
                                style={{fontSize: '20px', fontWeight: '600', color: '#7F8487', marginBottom: '10px'}}>
                                {intl.get('ADD_PALLET.EMPTY_PACKAGE_LIST')}
                            </Typography>
                            <IconButton
                                sx={{color: '#609966'}}
                                onClick={handleAddPallet}
                            >
                                <img src={addPallet} width='35px'/>
                            </IconButton>
                            <Typography style={{fontSize: '12px', fontWeight: '600', color: '#609966'}}>
                                {intl.get('ADD_PALLET.ADD_NEW_TEXT')}
                            </Typography>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}