import {actionType} from "../../utils/Helper";

const initialState = {
    quickQuoteUnit: false,
    quickQuotePalletList: [],
    quickQuoteMoveFrom: {},
    quickQuoteMoveTo: {},
    quickQuoteMoveFromMissing: [],
    quickQuoteMoveToMissing: [],
    quickQuoteRate: [],
    quickQuoteRateSelect: null,
    quickQuoteMoveFromAddition: {},
    quickQuoteMoveToAddition: {},
    quickQuoteShipperInfo: {},
    quickQuoteConsigneeInfo: {},
    quickQuoteShipPickupDateTime: {},
    quickQuotePlaceOrderValidation: null,
    quickQuoteCrossBorder: null,
    quickQuoteSpecialService: [],
    quickQuoteSavedSpecialService: [],
    quickQuoteReferenceNumber: '',
    quickQuoteRemark: null,
    quickQuoteBatteryDetails: null,
    createLabelShipFrom: null,
    createLabelShipTo: null,
    createLabelPalletList: [],
    createLabelValidation: null,
    createLabelUnit: false,
    createLabelSpecialService: [],
    createLabelRate: [],
    createLabelSelectRate: null,
    createLabelCallCarrier: 0,
    createLabelReferenceNumber: null,
    createLabelComment: null,
    createLabelDateAndTime: null,
    createLabelBatteryDetails: null,
    crossBorderItemList: [],
    crossBorderRate: null,
    crossBorderTransAndType: null
}

export const shippingLTLReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_UNIT:
            return {...state, quickQuoteUnit: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_UNIT:
            return {...state, createLabelUnit: action?.payload}
        case actionType.INIT_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST:
            return {...state, createLabelPalletList: action?.payload}
        case actionType.ADD_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST:
            return {...state, quickQuotePalletList: [...state.quickQuotePalletList, action?.payload]}
        case actionType.ADD_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST:
            return {...state, createLabelPalletList: [...state.createLabelPalletList, action?.payload]}
        case actionType.ADD_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST:
            return {...state, crossBorderItemList: [...state.crossBorderItemList, action?.payload]}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST:
            console.log('update pallet', action.payload);
            const updatedPallet = action?.payload;
            const updatedPalletList = state?.quickQuotePalletList.map(pallet => {
                if (pallet?.palletId === updatedPallet.palletId) {
                    return {...updatedPallet}
                }
                return pallet
            });
            return {...state, quickQuotePalletList: updatedPalletList}
        case actionType.UPDATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST:
            console.log('update pallet', action.payload);
            const updatedPalletCreateLabel = action?.payload;
            const updatedPalletListCreateLabel = state?.createLabelPalletList.map(pallet => {
                if (pallet?.palletId === updatedPalletCreateLabel.palletId) {
                    return {...updatedPalletCreateLabel}
                }
                return pallet
            });
            return {...state, createLabelPalletList: updatedPalletListCreateLabel}
        case actionType.UPDATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST:
            const updatedItemCreateLabel = action?.payload;
            const updatedItemListCreateLabel = state?.crossBorderItemList?.map(each => {
                if (each?.itemId === updatedItemCreateLabel?.itemId) {
                    return {...updatedItemCreateLabel}
                }
                return each
            })
            return {...state, crossBorderItemList: updatedItemListCreateLabel}
        case actionType.REMOVE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST:
            console.log('remove pallet', action.payload);
            const palletIdToFind = action?.payload?.palletId;
            const filteredPalletList = state?.quickQuotePalletList?.filter(pallet => pallet?.palletId !== palletIdToFind);
            return {...state, quickQuotePalletList: filteredPalletList}
        case actionType.REMOVE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST:
            console.log('remove pallet', action.payload);
            const palletIdToFindCreateLabel = action?.payload?.palletId;
            const filteredPalletListCreateLabel = state?.createLabelPalletList?.filter(pallet => pallet?.palletId !== palletIdToFindCreateLabel);
            return {...state, createLabelPalletList: filteredPalletListCreateLabel}
        case actionType.REMOVE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST:
            console.log('cross border remove', action?.payload);
            const itemIdToFindCrossBorder = action?.payload?.itemId;
            const filterItemListCrossBorder = state?.crossBorderItemList?.filter(each => each?.itemId !== itemIdToFindCrossBorder);
            return {...state, crossBorderItemList: filterItemListCrossBorder}
        case actionType.DUPLICATE_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST:
            return {...state, quickQuotePalletList: [...state.quickQuotePalletList, action.payload]}
        case actionType.DUPLICATE_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST:
            return {...state, createLabelPalletList: [...state.createLabelPalletList, action.payload]}
        case actionType.DUPLICATE_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST:
            return {...state, crossBorderItemList: [...state.crossBorderItemList, action?.payload]}
        case actionType.CLEAR_SHIPPING_LTL_QUICK_QUOTE_PALLET_LIST:
            return {...state, quickQuotePalletList: []}
        case actionType.CLEAR_SHIPPING_LTL_CREATE_LABEL_PALLET_LIST:
            return {...state, createLabelPalletList: []}
        case actionType.CLEAR_SHIPPING_LTL_CROSS_BORDER_ITEM_LIST:
            return {...state, crossBorderItemList: []}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION:
            return {...state, quickQuoteMoveFrom: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION:
            return {...state, quickQuoteMoveTo: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_LOCATION_MISSING:
            return {...state, quickQuoteMoveFromMissing: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_LOCATION_MISSING:
            return {...state, quickQuoteMoveToMissing: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_CROSS_BORDER:
            return {...state, quickQuoteCrossBorder: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_RATE:
            return {...state, quickQuoteRate: action?.payload}
        case actionType.SELECT_SHIPPING_LTL_QUICK_QUOTE_RATE:
            return {...state, quickQuoteRateSelect: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_FROM_ADDITIONAL_LOCATION:
            return {...state, quickQuoteMoveFromAddition: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_MOVE_TO_ADDITIONAL_LOCATION:
            return {...state, quickQuoteMoveToAddition: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIPPER_INFO:
            return {...state, quickQuoteShipperInfo: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_CONSIGNEE_INFO:
            return {...state, quickQuoteConsigneeInfo: action?.payload}
        case actionType.UPDATE_SHIPPING_LTL_QUICK_QUOTE_SHIP_AND_PICKUP_DATA_TIME:
            return {...state, quickQuoteShipPickupDateTime: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_QUICK_QUOTE_PLACE_ORDER_VALIDATE:
            return {...state, quickQuotePlaceOrderValidation: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_CREATE_LABEL_VALIDATION:
            return {...state, createLabelValidation: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE:
            return {...state, quickQuoteSpecialService: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_CREATE_LABEL_SPECIAL_SERVICE:
            return {...state, createLabelSpecialService: action?.payload}
        case actionType?.SAVE_SHIPPING_LTL_QUICK_QUOTE_SPECIAL_SERVICE:
            return {...state, quickQuoteSavedSpecialService: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_QUICK_QUOTE_REFERENCE_NUMBER:
            return {...state, quickQuoteReferenceNumber: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_QUICK_QUOTE_REMARK:
            return {...state, quickQuoteRemark: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_FROM_LOCATION:
            return {...state, createLabelShipFrom: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_CREATE_LABEL_MOVE_TO_LOCATION:
            return {...state, createLabelShipTo: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_CREATE_LABEL_RATE:
            console.log('create label ltl update rate', action?.payload)
            const updateRate = [...state.createLabelRate, action?.payload];
            return {...state, createLabelRate: updateRate}
        case actionType?.CLEAR_SHIPPING_LTL_CREATE_LABEL_RATE:
            console.log('create label ltt clear rate')
            return {...state, createLabelRate: []}
        case actionType?.SELECT_SHIPPING_LTL_CREATE_LABEL_RATE:
            return {...state, createLabelSelectRate: action?.payload}
        case actionType?.UPDATE_CALLED_ALL_LTL_CARRIER:
            return {...state, createLabelCallCarrier: state?.createLabelCallCarrier + action?.payload}
        case actionType?.CLEAR_CALLED_ALL_LTL_CARRIER:
            return {...state, createLabelCallCarrier: 0}
        case actionType?.UPDATE_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER:
            return {...state, createLabelReferenceNumber: action?.payload}
        case actionType?.CLEAR_SHIPPING_LTL_CREATE_LABEL_REFERENCE_NUMBER:
            return {...state, createLabelReferenceNumber: null}
        case actionType?.UPDATE_SHIPPING_LTL_CREATE_LABEL_COMMENT:
            return {...state, createLabelComment: action?.payload}
        case actionType?.CLEAR_SHIPPING_LTL_CREATE_LABEL_COMMENT:
            return {...state, createLabelComment: null}
        case actionType?.UPDATE_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME:
            return {...state, createLabelDateAndTime: action?.payload}
        case actionType?.CLEAR_SHIPPING_LTL_CREATE_LABEL_DATE_AND_TIME:
            return {...state, createLabelDateAndTime: null}
        case actionType?.UPDATE_SHIPPING_LTL_CROSS_BORDER_RATE:
            return {...state, crossBorderRate: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_CROSS_BORDER_TRANS_AND_TYPE:
            return {...state, crossBorderTransAndType: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_QUICK_QUOTE_BATTERY_DETAILS:
            return {...state, quickQuoteBatteryDetails: action?.payload}
        case actionType?.UPDATE_SHIPPING_LTL_CREATE_LABEL_BATTERY_DETAILS:
            return {...state, createLabelBatteryDetails: action?.payload}
        default:
            return state
    }
}