import React, {useEffect, useState} from "react";
import {Check, Cancel} from "@material-ui/icons";
import {
  TableBody,
  TableRow,
  TableCell,
  Button, Box, Typography, Tooltip, IconButton, Select, TextField
} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import {Accordion, AccordionDetails, AccordionSummary, MenuItem} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import CustomInput from "../../../shared/customInput";
import {UOMList} from "../../../shared/constInfo";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getCountryCode} from "../../../utils/getCountryCode";
import {makeStyles} from "@material-ui/core/styles";
import dayjs from "dayjs";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
  HSCodetextfield: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    // paddingTop: "0.4vw",
    // paddingBottom: "0.4vw",
  },
  accordion: {
    width: "100%"
  },
  accordionSummaryRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  // textfield: {
  //   width: "100%",
  //   backgroundColor: "white",
  //   borderRadius: "5px",
  //   // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
  //   // paddingTop: "0.4vw",
  //   // paddingBottom: "0.4vw",
  // },
  // selectRoot: {
  //   paddingLeft: theme.spacing(1),
  //   paddingTop: theme.spacing(1),
  //   paddingBottom: theme.spacing(1),
  // },
  menuPaper: {
    maxWidth: 150,
  },
  input: {
    color: "grey",
    width: "90%",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
  smSubTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Montserrat",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  warningText: {
    color: "red",
    fontWeight: "bold",
  },
}));
const LooseItemServices = ({
  setCompanyName,
  companyName,
  shippingService,
  chargeAmount,
  toSeventh,
  setServiceName,
  setPriceText,
  message,
  setFormatData,
  info,
  setAccountBaseCombinedName,
  serviceType,
  setServiceType,
  crossBoarderTotal,
  setPriceInfo,
  shipmentCharges,
  serviceFee,
  expectedDate
}) => {
  const classes = useStyles();

  const handleAccountBasedCombinedNameChange = (accountBaseCombineName) => {
    setFormatData(v => {
      return {
        ...v,
        accountBaseCombinedName: accountBaseCombineName
      }
    })
  }

  const setAndLoadNext = (currentCompanyName, currentServiceName) => {
    setCompanyName(currentCompanyName);
    setServiceName(currentServiceName);
    setPriceText(chargeAmount);
    handleAccountBasedCombinedNameChange(info.accountBaseCombineName);
    setAccountBaseCombinedName(info.accountBaseCombineName)
    setServiceType(serviceType);
    setPriceInfo(info);
    toSeventh()
  };

  useEffect(() => {
    console.log("Charge Amount: ", chargeAmount)
  }, [chargeAmount]);

  useEffect(() => {
    console.log("shipment charges", shipmentCharges);
  }, [shipmentCharges]);

  const getServiceIconByAccountBaseAndService = (serviceName) => {
    const width = "80"
    const height= "80"
    switch (serviceName?.toLowerCase()) {
      case "ups standard":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/ups-ground-icon.png" alt={serviceName} width={width} height={height} />;
      case "ups super express":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/ups-express-icon.png" alt={serviceName} width={width} height={height} />;
      case "ups express saver":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/ups-express-icon.png" alt={serviceName} width={width} height={height} />;
      case "fedex ground":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/fedex-ground-icon.png" alt={serviceName} width={width} height={height} />;
      case "fedex express saver":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/fedex-express-icon.png" alt={serviceName} width={width} height={height} />;
      case "canpar ground":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/canpar-ground.png" alt={serviceName} width={width} height={height} />;
      case "canpar select":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/canpar-express.png" alt={serviceName} width={width} height={height} />;
      case "canada post regular parcel":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/canadapost.png" alt={serviceName} width={width} height={height} />;
      case "canada post expedited parcel":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/canadapost.png" alt={serviceName} width={width} height={height} />;
    }
  }

  return (
    <TableBody>
    <TableRow>
      {/*<TableCell align="left">*/}
      {/*  <Button onClick={() => setAndLoadNext(companyName, shippingService)} disabled={message !== ""}>Select</Button>*/}
      {/*</TableCell>*/}
      {/*<TableCell align="center">{companyName}</TableCell>*/}
      <TableCell align="center" width="20%">
        <Box display="flex" flexDirection="column">
          <Button onClick={() => setAndLoadNext(companyName, shippingService)} disabled={message !== ""} style={{ backgroundColor: 'transparent' }}>
            {getServiceIconByAccountBaseAndService(shippingService)}
          </Button>
          {/*<span onClick={() => setAndLoadNext(companyName, shippingService)} disabled={message !== ""}>{shippingService}</span>*/}
          <Box width="20" height="10" >
            <Button onClick={() => setAndLoadNext(companyName, shippingService)} disabled={message !== ""} variant="outlined" style={{
              borderRadius: 15,
              backgroundColor: "#21b6ae",
              fontSize: 10,
              width: "20",
              height: "10"
            }}>{shippingService}</Button>
          </Box>

        </Box>
      </TableCell>
      <TableCell align="right" width="50%">
        {
          chargeAmount ? <b style={{ fontSize: "25px" }}><CurrencyFormat value={(chargeAmount)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b> : "N/A"
        }
        <br />
        <Accordion className={classes.accordion}>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordionSummaryRight}
              classes={{ content: classes.accordionSummaryRight }}
          >
            <Typography sx={{ textAlign: "right" }}>
                Price Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Box display="flex" flexDirection="column">
                {
                  shipmentCharges && shipmentCharges?.filter(v => v?.name || v?.description).map(v => {
                    return (
                        <>
                          <Box display="flex" sx={{ gap: "0.7rem" }} justifyContent="space-between">
                      <span>
                        <span>- <b>{v?.description || v?.name}</b></span>
                      </span>
                            <span>
                        <CurrencyFormat value={(v.value)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </span>
                          </Box>
                        </>
                    )
                  })
                }
                <Box display="flex" sx={{ gap: "0.7rem" }} justifyContent="space-between">
                  <span>
                    <span>- <b>Service Fee</b></span>
                  </span>
                  <span><CurrencyFormat value={serviceFee} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                </Box>
                <Box display="flex" sx={{ gap: "0.7rem" }} justifyContent="space-between">
                  <span>
                    <span>- <b>Cross Boarder Fee (If applicable)</b></span>
                  </span>
                  <span>{crossBoarderTotal ? <CurrencyFormat value={(crossBoarderTotal)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : "N/A"}</span>
                </Box>
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </TableCell>
      <TableCell align="center">
        <h3>
          {
            expectedDate ? dayjs(expectedDate).format("YYYY-MM-DD") : "N/A"
          }
        </h3>
      </TableCell>
      <TableCell align="right">{message !== "" ? `Error! ${message}` : ""}</TableCell>
    </TableRow>
  </TableBody>
  )
}

export default LooseItemServices;
