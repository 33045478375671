import QRCode from 'qrcode.react';
import {useSelector} from "react-redux";
import {stringToImage} from "../../utils/Helper";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import logo from "../../images/icons/icon.png"

export const MyQRCode = ({size}) => {

    // let userName = useSelector(state => state?.user?.legalName);

    // const [imageSrc, setImageSrc] = useState('');

    // const handleDownload = () => {
    //     const qrCodeURL = document.getElementById('qrCode')
    //         .toDataURL("image/png")
    //         .replace("image/png", "image/octet-stream");
    //     let aEl = document.createElement("a");
    //     aEl.href = qrCodeURL;
    //     aEl.download = "QR_Code.png";
    //     document.body.appendChild(aEl);
    //     aEl.click();
    //     document.body.removeChild(aEl);
    // };

    // useEffect(() => {
    //     const canvas = document.createElement('canvas');
    //     const context = canvas.getContext('2d');
    //     const fontSize = 14;
    //     const fontFace = 'Arial';
    //     const textWidth = context.measureText(userName).width;
    //     const textHeight = fontSize;
    //     canvas.width = textWidth * 1.5;
    //     canvas.height = textHeight;
    //     context.font = `${fontSize}px ${fontFace}`;
    //     context.textAlign = 'center';
    //     context.textBaseline = 'middle';
    //     context.fillText(userName, canvas.width / 2, canvas.height / 2);
    //     const dataURL = canvas.toDataURL('image/png');
    //     setImageSrc(dataURL);
    //
    // }, [userName]);

    // get url by calling API
    const [referralURL, setReferralURL] = useState();

    const getReferralAgentURL = async () => {

        let requestURL = `${PARTNER_URI}/partner/getReferralAgentURLForPartner`;
        const storedToken = getAccessToken("access_token");

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                }
            })
            const agentUrl = result?.data?.referralAgentUrl;
            setReferralURL(agentUrl);
            console.log(agentUrl);

        } catch (e) {
            console.log(e.response)
        }
    }

    useEffect(() => {
        getReferralAgentURL();
    },[])

    return (
        <div>
            {/*{imageSrc && <img src={imageSrc} alt="My Image" />}*/}
            {referralURL &&
                <QRCode
                    id='qrCode'
                    value={referralURL}
                    size={size}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={false}
                    imageSettings={{
                        src: logo,
                        x: undefined,
                        y: undefined,
                        height: size / 5,
                        width: size  / 5,
                        excavate: true,
                    }}
                />
            }
        </div>
    )
}