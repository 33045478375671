import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {forwardRef, useState} from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {MenuItem, Select} from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getCountryCode} from "../../utils/getCountryCode";
import {UOMList} from "../../shared/constInfo";
import {useForm} from "react-hook-form";
import axios from "axios";
import {HSCODE_SEARCH, PARTNER_URI} from "../../utils/apiUrl";
import {useSelector} from "react-redux";
import HelpIcon from '@mui/icons-material/Help';
import IconButton from "@mui/material/IconButton";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const customStyles = {
    menu: provided => ({
        ...provided,
        zIndex: 9999,
    }),
};

export const MyBoxesForm = () => {

    const id = useSelector((state) => state.user.id);

    const [open, setOpen] = useState(false);

    const [HSCodeList, setHSCodeList] = useState([]);

    const [HScode, setHScode] = useState('');

    const [currency, setCurrency] = useState('');

    const [lengthUnit, setLengthUnit] = useState('');

    const [weightUnit, setWeightUnit] = useState('');

    const [originCountry, setOriginCountry] = useState('');

    const [boxName, setBoxName] = useState('');

    const [itemName, setItemName] = useState('');

    const [price, setPrice] = useState('');

    const [length, setLength] = useState('');

    const [width, setWidth] = useState('');

    const [height, setHeight] = useState('');

    const [weight, setWeight] = useState('');

    const [UOM, setUOM] = useState('');

    const [toastOpen, setToastOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [successMessage, setSuccessMessage] = useState("");

    const {register, handleSubmit, errors} = useForm();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        resetValue();
    }

    const resetValue = () => {
        setBoxName('');
        setItemName('');
        setLength('');
        setWidth('');
        setHeight('');
        setPrice('');
        setCurrency('CAD');
        setLengthUnit('in');
        setWeightUnit('lb');
        setWeight('');
        setOriginCountry('CN');
        setUOM('BOX');
    }

    const createMyBoxForPartner = async (data) => {
        console.log(data)
        let requestURL = `${PARTNER_URI}/myboxes/createMyBoxForPartner`;

        try {
            await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            })
            setOpen(false);
            resetValue();
            window.location.reload();
        } catch (e) {
            console.log(e?.response?.data?.message || 'Error');
            console.log(e.response)
            setToastOpen(true)
            setErrorMessage(e?.response?.data?.message || 'Error')
        }
    }

    const onSubmit = (data) => {

        console.log(data)

        let newData = {
            boxName: data.boxName,
            dimensionUnit: lengthUnit,
            height: height ? height : 1,
            itemName: itemName ? itemName : itemName,
            length: length ? length : 1,
            partnerId: id,
            weight: weight ? weight : 1,
            weightUnit: weightUnit,
            width: width ? width : 1,
            originalCountry: originCountry,
            uom: UOM,
            currency: currency,
            hsCode: HScode,
            unitPrice: price ? price : 0
        }

        console.log(newData)

        createMyBoxForPartner(newData);
    }

    const searchHSCode = async (value) => {
        try {
            const {data} = await axios.get(`${HSCODE_SEARCH}?query_string=${value}`);
            // console.log("searchLevelSixSection", data);

            const hits = data.hits;
            const levelSixRecords = hits.filter(hit => {
                return hit["_source"].level === "6";
            })
            const filterResult = levelSixRecords?.map(record => {
                return record["_source"]
            })
            setHSCodeList(filterResult);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            {!open ?
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%'
                    }}>
                    <Button onClick={handleOpen}>
                        Add a new box
                    </Button>
                </Box>
                :
                <Box sx={style}>
                    <Snackbar
                        anchorOrigin={{vertical: "top", horizontal: "center"}}
                        open={toastOpen}
                        onClose={handleToastClose}
                        autoHideDuration={3000}
                        message="Submit Transaction"
                    >
                        {(() => {
                            if (errorMessage !== "") {
                                return (
                                    <Alert
                                        onClose={handleToastClose}
                                        severity="error"
                                        sx={{width: "100%"}}
                                    >
                                        Error!
                                        <hr/>
                                        {errorMessage}
                                    </Alert>
                                );
                            }
                            return (
                                <Alert
                                    onClose={handleToastClose}
                                    severity="success"
                                    sx={{width: "100%"}}
                                >
                                    {successMessage}
                                </Alert>
                            );
                        })()}
                    </Snackbar>
                    <Typography variant="h5">
                        New Box
                    </Typography>
                    <Box sx={{backgroundColor: '#ffffff', padding: '10px', borderRadius: '10px'}}>
                        <Typography sx={{textAlign: 'left'}}>
                            Item Information
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'none',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                            <TextField
                                value={boxName}
                                sx={{margin: '10px'}}
                                label="Box Name"
                                required
                                fullWidth
                                variant="standard"
                                onInput={e => setBoxName(e.target.value)}
                                {...register("boxName", {required: true})}
                            />
                            <TextField
                                value={itemName}
                                sx={{margin: '10px'}}
                                label="Item Name"
                                fullWidth
                                required
                                variant="standard"
                                onInput={e => setItemName(e.target.value)}
                            />
                            <TextField
                                value={weight}
                                sx={{margin: '10px'}}
                                label="Weight"
                                fullWidth
                                variant="standard"
                                required
                                onInput={e => setWeight(e.target.value)}
                            />
                            <FormControl variant="standard" sx={{margin: '10px'}} fullWidth>
                                <InputLabel>Weight Unit*</InputLabel>
                                <Select
                                    value={weightUnit}
                                    label="weight unit"
                                    onChange={e => setWeightUnit(e.target.value)}
                                >
                                    <MenuItem value={'lb'}>lb</MenuItem>
                                    <MenuItem value={'kg'}>kg</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'none',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                            <TextField
                                value={length}
                                sx={{margin: '10px'}}
                                label="Length"
                                required
                                fullWidth
                                variant="standard"
                                onInput={e => setLength(e.target.value)}
                            />
                            <TextField
                                value={width}
                                sx={{margin: '10px'}}
                                label="Width"
                                required
                                variant="standard"
                                fullWidth
                                onInput={e => setWidth(e.target.value)}
                            />
                            <TextField
                                value={height}
                                sx={{margin: '10px', minWidth: '100px'}}
                                label="Height"
                                required
                                fullWidth
                                variant="standard"
                                onInput={e => setHeight(e.target.value)}
                            />
                            <FormControl variant="standard" sx={{margin: '10px'}} fullWidth>
                                <InputLabel>Length Unit*</InputLabel>
                                <Select
                                    value={lengthUnit}
                                    label="length unit"
                                    onChange={e => setLengthUnit(e.target.value)}
                                >
                                    <MenuItem value={'cm'}>cm</MenuItem>
                                    <MenuItem value={'in'}>in</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        {/*<Box sx={{*/}
                        {/*    display: 'flex',*/}
                        {/*    justifyContent: 'flex-start',*/}
                        {/*    alignItems: 'center',*/}
                        {/*    marginTop: '10px'*/}
                        {/*}}>*/}
                        {/*    <IconButton*/}
                        {/*        onClick={() => window.location.href = 'https://uucargo.ca/support/cross-border-shipping-parcels/'}>*/}
                        {/*        <HelpIcon color="info" sx={{fontSize: 25}}/>*/}
                        {/*    </IconButton>*/}
                        {/*    <Typography sx={{}}>*/}
                        {/*        Cross Border Only (Mandatory for shipping Different Countries)*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        {/*<Box*/}
                        {/*    sx={{*/}
                        {/*        display: 'flex',*/}
                        {/*        flexWrap: 'none',*/}
                        {/*        alignItems: 'center',*/}
                        {/*        justifyContent: 'space-between'*/}
                        {/*    }}>*/}
                        {/*    <TextField*/}
                        {/*        value={price}*/}
                        {/*        sx={{margin: '10px'}}*/}
                        {/*        label="Price"*/}
                        {/*        required*/}
                        {/*        variant="standard"*/}
                        {/*        onInput={e => setPrice(e.target.value)}*/}
                        {/*        fullWidth*/}
                        {/*    />*/}
                        {/*    <FormControl variant="standard" fullWidth sx={{margin: '10px'}}>*/}
                        {/*        <InputLabel>Currency*</InputLabel>*/}
                        {/*        <Select*/}
                        {/*            styles={customStyles}*/}
                        {/*            value={currency}*/}
                        {/*            label="Currency"*/}
                        {/*            onChange={e => setCurrency(e.target.value)}*/}
                        {/*        >*/}
                        {/*            <MenuItem value={'CAD'}>CAD</MenuItem>*/}
                        {/*            <MenuItem value={'USD'}>USD</MenuItem>*/}
                        {/*        </Select>*/}
                        {/*    </FormControl>*/}
                        {/*    <FormControl sx={{margin: '10px'}} fullWidth variant="standard">*/}
                        {/*        <InputLabel>Origin Country*</InputLabel>*/}
                        {/*        <Select*/}
                        {/*            value={originCountry}*/}
                        {/*            label="origin country"*/}
                        {/*            onChange={e => setOriginCountry(e.target.value)}*/}
                        {/*        >*/}
                        {/*            {*/}
                        {/*                getCountryCode().map(v => {*/}
                        {/*                    return <MenuItem value={v.code}>{v.name}</MenuItem>*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </FormControl>*/}
                        {/*    <FormControl sx={{margin: '10px'}} fullWidth variant="standard">*/}
                        {/*        <InputLabel>Unit of Measure*</InputLabel>*/}
                        {/*        <Select*/}
                        {/*            value={UOM}*/}
                        {/*            label="unit of measure"*/}
                        {/*            onChange={e => setUOM(e.target.value)}*/}
                        {/*        >*/}
                        {/*            {UOMList.map(v => {*/}
                        {/*                return <MenuItem value={v}>{v}</MenuItem>*/}
                        {/*            })}*/}
                        {/*        </Select>*/}
                        {/*    </FormControl>*/}
                        {/*</Box>*/}
                        {/*<Box*/}
                        {/*    sx={{*/}
                        {/*        display: 'flex',*/}
                        {/*        flexWrap: 'none',*/}
                        {/*        alignItems: 'center',*/}
                        {/*        margin: '0 10px'*/}
                        {/*    }}>*/}
                        {/*    <Autocomplete*/}
                        {/*        // sx={{margin: '10px'}}*/}
                        {/*        disablePortal*/}
                        {/*        options={HSCodeList}*/}
                        {/*        fullWidth*/}
                        {/*        freeSolo*/}
                        {/*        filterOptions={(options, state) => options}*/}
                        {/*        // getOptionLabel={(option) => option.label}*/}
                        {/*        getOptionLabel={(option) => `${option.hscode}`}*/}
                        {/*        renderOption={(option) => <span><b>{option.hscode}</b> | {option.desc}</span>}*/}
                        {/*        onChange={(e, value) => {*/}
                        {/*            // handleItemChangeByValue("hscode")(value?.hscode || "");*/}
                        {/*        }}*/}
                        {/*        onInputChange={async (e, value) => {*/}
                        {/*            // handleItemChangeByValue("hscodeDescription")(value);*/}
                        {/*            setHScode(value);*/}
                        {/*            await searchHSCode(value);*/}

                        {/*        }}*/}
                        {/*        renderInput={(params) => <TextField*/}
                        {/*            multiline*/}
                        {/*            id="outlined-basic"*/}
                        {/*            label="HS CODE"*/}
                        {/*            variant="standard"*/}
                        {/*            fullWidth*/}
                        {/*            {...params}*/}
                        {/*            required*/}
                        {/*        />}*/}
                        {/*    />*/}
                        {/*</Box>*/}
                        <Box>
                            <Button
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Confirm
                            </Button>
                            <Button
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>

    )
}