import {Checkbox, Container} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import packageIcon from "../../images/icons/packageIcon.png";
import PublicIcon from "@mui/icons-material/Public";
import ltlIcon from "../../images/icons/ltlIcon.png";
import brokerageIcon from "../../images/icons/brokerageIcon.png";
import {useEffect, useState} from "react";
import {TransportationAvailableTasksList} from "./TransportationAvailableTasksList";
import {TransportationAvailableTasksMap} from "./TransportationAvailableTasksMap";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../utils/doToken";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../utils/consInfo";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {extractFullAddress} from "../../utils/Helper";
import {makeStyles} from "@material-ui/core/styles";
import {InputAdornment} from "@material-ui/core";
import {Pagination} from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useHistory} from "react-router-dom";

const styles = {
    ShippingPackageCreateLabelRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },
    ShippingPackageCreateLabelRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelRowRight: {
        display: 'flex',
        justifyContent: 'flex-end'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
})

export const TransportationAvailableTasks = () => {

    const classes = useStyles();

    const storedToken = getAccessToken("access_token");
    const history = useHistory();

    const [tabValue, setTabValue] = useState('available');
    const [viewType, setViewType] = useState('list');
    const [availableTasks, setAvailableTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [shipFrom, setShipFrom] = useState({
        city: null,
        province: null,
        addressType: {value: 'warehouse', name: 'WAREHOUSE'},
        postalCode: null
    });
    const [shipTo, setShipTo] = useState({
        city: '',
        province: null,
        addressType: {value: 'warehouse', name: 'WAREHOUSE'},
        postalCode: null
    });
    const [distance, setDistance] = useState(0);

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;

            return predictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromCity = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            city: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality');
        setMovingFromAddressPredictions(predictions);
    }

    const handleInputShipToCity = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            city: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality');
        setMovingToAddressPredictions(predictions);
    }

    const handleChangeShipFromCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.place_id);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship from', address);
            setShipFrom(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: {
                    countryCode: address?.country?.code,
                    country: address?.country?.name,
                    name: address?.state?.name,
                    code: address?.state?.code,
                }
            }))
        }
    }

    const handleChangeShipToCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.place_id);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship to', address);
            setShipTo(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: {
                    countryCode: address?.country?.code,
                    country: address?.country?.name,
                    name: address?.state?.name,
                    code: address?.state?.code,
                }
            }))
        }
    }

    const handleDistance = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setDistance(value);
        }
    }

    const getAllAvailableTransportationOrders = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/getAllAvailableMovingOrders`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                params: {
                    page: 0,
                    pageSize: 10,
                    pickupCity: shipFrom?.city,
                    pickupProvince: shipFrom?.province?.code,
                    deliverCity: shipTo?.city,
                    deliverProvince: shipTo?.province?.code,
                    distance: distance
                }
            });
            console.log('[TransportationAvailableTasks] getAllAvailableTransportationOrders api - result', result?.data?.data);
            setAvailableTasks(result?.data?.data);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
            setError(false);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getAllAvailableTransportationOrdersByPage = async (page) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/getAllAvailableMovingOrders`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                params: {
                    page: page,
                    pageSize: 10,
                    pickupCity: shipFrom?.city,
                    pickupProvince: shipFrom?.province?.code,
                    deliverCity: shipTo?.city,
                    deliverProvince: shipTo?.province?.code,
                    distance: distance
                }
            });
            console.log('[TransportationAvailableTasks] getAllAvailableTransportationOrders api - result', result?.data?.data);
            setAvailableTasks(result?.data?.data);
            setError(false);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        const page = value;
        getAllAvailableTransportationOrdersByPage(page - 1);
    };

    const filterOrders = () => {
        getAllAvailableTransportationOrders();
    }

    useEffect(() => {
        getAllAvailableTransportationOrders();
    }, [])

    const handleViewType = (data) => {
        setViewType(data);
    }

    const handleChange = (event, newValue) => {
        if (newValue === 'claim') {
            history.push('/transportation/claimTasks/');
        }
    };

    console.log('[TransportationAvailableTasks] availableTasks', availableTasks);

    return (
        <Container maxWidth='xl'>
            <Tabs
                value={tabValue}
                onChange={handleChange}
            >
                <Tab value="available" label={intl.get('TRANSPORTATION.AVAILABLE_TRANSPORTATION_ORDERS')} />
                <Tab value="claim" label={intl.get('TRANSPORTATION.MY_TRANSPORTATION_ORDERS')} />
            </Tabs>
            <Box style={styles.ShippingPackageCreateLabelRoot}>
                <Typography style={styles.ShippingPackageCreateLabelHeading}>
                    Available Moving Orders
                </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: '10px'
                }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={viewType === 'list'}
                            onClick={() => handleViewType('list')}
                        />
                        <img src={packageIcon} alt="packageIcon" width='40px'/>
                        <Typography style={{color: '#545454', padding: '0 5px'}}>
                            List
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={viewType === 'map'}
                            onClick={() => handleViewType('map')}
                        />
                        <PublicIcon sx={{
                            fontSize: '40px'
                        }}/>
                        <Typography style={{color: '#545454', padding: '0 5px'}}>
                            Map
                        </Typography>
                    </Box>
                </Box>
                <Grid container spacing={1}>
                    <Grid item md={1} xs={12}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{textAlign: 'left'}}>
                                Filter By
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container spacing={1}>
                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={movingFromAddressPredictions}
                                        getOptionLabel={option => option?.structured_formatting?.main_text}
                                        onInputChange={handleInputShipFromCity}
                                        onChange={handleChangeShipFromCity}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                            placeholder='Pickup City'
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '12px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        // value={shipFrom?.city}
                                        options={movingToAddressPredictions}
                                        getOptionLabel={option => option?.structured_formatting?.main_text}
                                        onInputChange={handleInputShipToCity}
                                        onChange={handleChangeShipToCity}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                            placeholder='Deliver City'
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '12px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        value={distance}
                                        onInput={handleDistance}
                                        type='number'
                                        placeholder='Deliver Distance'
                                        size='small'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    km
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder='Pickup Date'
                                        size='small'
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1} xs={2}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={filterOrders}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                Search
                            </Typography>
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
            {
                loading ?
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    error ?
                        <Box sx={styles.ShippingOrderContent}>
                            <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                {intl.get('HOME_PAGE.ERROR')}
                            </Typography>
                        </Box>
                        :
                        availableTasks?.length === 0 ?
                            <Box sx={styles.ShippingOrderContent}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    No Available Transportation Orders
                                </Typography>
                            </Box> :
                            <>
                                {
                                    viewType === 'list' ?
                                        <TransportationAvailableTasksList availableTasks={availableTasks}
                                                                          currentPage={currentPage}
                                                                          lastPage={lastPage}
                                                                          handlePageChange={handlePageChange}/>
                                        :
                                        <TransportationAvailableTasksMap availableTasks={availableTasks}/>
                                }
                                <Pagination
                                    count={lastPage}
                                    page={+currentPage}
                                    size='small'
                                    // defaultPage={6}
                                    onChange={handlePageChange}
                                />
                            </>
            }
        </Container>
    )
}
