import React, { useRef, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Snackbar
} from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import xlsx from "json-as-xlsx";
import CurrencyFormat from "react-currency-format";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PARTNER_URI} from "../../../utils/apiUrl";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem",
    fontSize: "16px"
  },
  td: {
    fontSize: "20px"
  }
}));

export default function QuoteTable({ quoteId,
                                     businessName,
                                     standardAmount,
                                     serviceFee,
                                     additionalFee,
                                     tax,
                                     finalAmount,
                                     totalAmount,
                                     discount,
                                     currencyCode,
                                     quotesURI,
                                     expiredDate,
                                     remarkNote,
                                     statusName,
                                     status,
                                     refreshList,
}) {
  const classes = useStyles();

  const [toastOpen, setToastOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const acceptQuote = async () => {
    try {
      setIsLoading(true);
      await axios.post(`${PARTNER_URI}/partnerPallets/partnerAcceptShippingQuote`, {
        quoteId
      });
      setErrorMessage("");
      handleToastClick();
      refreshList();
    } catch (e) {
      setErrorMessage("We can not continue this process.");
    } finally {
      setIsLoading(false);
      handleDialogClose();
    }
  }

  const onAcceptQuote = async () => {
    handleDialogOpen();
  }


  return (
      <>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={toastOpen}
            onClose={handleToastClose}
            autoHideDuration={3000}
            message="Cancel Order">
          {(() => {
            if (errorMessage !== "") {
              return (
                  <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                    Error!
                    <hr />
                    Oops. {errorMessage}
                  </Alert>
              )
            }
            return (
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                  Accept Quote Success!
                </Alert>
            )
          })()}

        </Snackbar>
        {/*Pop-up Toast*/}

        {/*Confirm Delete Tracking Dialog*/}
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Accept Your Reqeust Quote
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This process will accept the request quote. Are you sure to accept?
              <hr />
              Click <b>ACCEPT</b> to accept this quote.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button" >No</Button>
            <Button onClick={acceptQuote} variant="outlined" type="button" disabled={isLoading} >
              {isLoading && <CircularProgress size={14} />}
              {!isLoading && "Accept"}
            </Button>
          </DialogActions>
        </Dialog>
        <tr className={classes.listItemWrapper}>
          <td className={classes.td}>{quoteId}</td>
          <td className={classes.td}>{businessName}</td>
          <td className={classes.td}>
            <CurrencyFormat value={(standardAmount || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </td>
          <td className={classes.td}>
            <CurrencyFormat value={(serviceFee || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </td>
          <td className={classes.td}>
            <CurrencyFormat value={(additionalFee || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </td>
          <td className={classes.td}>
            <CurrencyFormat value={(tax || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </td>
          <td className={classes.td}>
            <CurrencyFormat value={(discount || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </td>
          <td className={classes.td}>
            Total: <CurrencyFormat value={(totalAmount || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
            <br />
            Final: <CurrencyFormat value={(finalAmount || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </td>
          <td className={classes.td}>{expiredDate}</td>
          <td className={classes.td}>
            <a href={quotesURI} target="_blank" rel="noopener noreferrer">
              {quotesURI ? "Download" : ""}
            </a>
          </td>
          <td className={classes.td}>
            {
              (() => {
                if (status === 0) {
                  return <button onClick={onAcceptQuote}>Accept</button>
                }
                if (status === 1) {
                  return "Accepted"
                }
                return "Cancelled"
              })()
            }
          </td>
        </tr>
        {/*<tr>*/}
        {/*  /!*<td><span style={{ fontSize: "18px", fontWeight: "700" }}>Notes:</span> {remarkNote}</td>*!/*/}
        {/*  <td>*/}
        {/*    <Box sx={{ whiteSpace: "pre-wrap" }}>*/}
        {/*      <span style={{ fontSize: "18px", fontWeight: "700" }}>Notes:</span> {remarkNote}*/}
        {/*    </Box>*/}
        {/*  </td>*/}

        {/*</tr>*/}
        <Box display="flex" flexDirection="row" sx={{ gap: "0.5rem" }} alignContent="center" alignItems="center">
          <Box style={{ fontSize: "18px", fontWeight: "700" }}>
            Notes:
          </Box>
          <Box sx={{ whiteSpace: "pre-wrap", fontSize: "16px", fontWeight: "500" }}>
            {remarkNote}
          </Box>
        </Box>
        <br />
      </>
  );
}
