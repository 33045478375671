import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {Divider, Snackbar} from "@mui/material";
import Button from "@mui/material/Button";
import {PalletCalculator} from "./PalletCalculator";
import {Fragment, useEffect, useState} from "react";
import Carousel from "react-material-ui-carousel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Paper from "@mui/material/Paper";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import React from 'react';
import MuiAlert from "@mui/material/Alert";
import {convertCubicInToCubicMeter, convertLBToKG} from "../../utils/Helper";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TransportationClaimTasksContent = ({taskDetails}) => {

    const [palletCalculator, setPalletCalculator] = useState(false);

    const handleOpenPalletCalculator = () => {
        setPalletCalculator(true);
    }

    const handleClosePalletCalculator = () => {
        setPalletCalculator(false);
    }

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Claimed Order Item
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            {
                                taskDetails?.itemList?.length > 0 &&
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Item List
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Category
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Description
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Tracking Number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Dimension
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Weight
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {
                                            taskDetails?.itemList?.map((item, index) => (
                                                <Fragment key={index}>
                                                    <Grid item md={5} xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={6} xs={12}>
                                                                <Typography
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        fontSize: '14px'
                                                                    }}>
                                                                    {item?.category_name}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item md={6} xs={12}>
                                                                <Typography
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        fontSize: '14px'
                                                                    }}>
                                                                    {item?.item_description}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={3} xs={12}>
                                                        <Typography
                                                            style={{textAlign: 'left', fontSize: '14px'}}>
                                                            {item?.trackingNumber}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Typography
                                                            style={{textAlign: 'left', fontSize: '14px'}}>
                                                            {item?.length}x{item?.width}x{item?.height} {item?.dimension_unit}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Typography
                                                            style={{textAlign: 'left', fontSize: '14px'}}>
                                                            {item?.weight} {item?.weight_unit}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                </Fragment>
                                            ))

                                        }
                                    </Grid>
                                </Grid>
                            }
                            {
                                taskDetails?.boxList?.length > 0 &&
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Box List
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Category
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Description
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Tracking Number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Dimension
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Weight
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Quantity
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {
                                            taskDetails?.boxList?.map((item, index) => (
                                                <Fragment key={index}>
                                                    <Grid item md={5} xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={6} xs={12}>
                                                                <Typography
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        fontSize: '14px'
                                                                    }}>
                                                                    {item?.category_name}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item md={6} xs={12}>
                                                                <Typography
                                                                    style={{
                                                                        textAlign: 'left',
                                                                        fontSize: '14px'
                                                                    }}>
                                                                    {item?.item_description}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Typography
                                                            style={{textAlign: 'left', fontSize: '14px'}}>
                                                            {item?.trackingNumber}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Typography
                                                            style={{textAlign: 'left', fontSize: '14px'}}>
                                                            {item?.length}x{item?.width}x{item?.height} {item?.dimension_unit}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Typography
                                                            style={{textAlign: 'left', fontSize: '14px'}}>
                                                            {item?.weight} {item?.weight_unit}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={1} xs={12}>
                                                        <Typography
                                                            style={{textAlign: 'left', fontSize: '14px'}}>
                                                            {item?.number}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                </Fragment>
                                            ))

                                        }
                                    </Grid>
                                </Grid>
                            }
                            {
                                taskDetails?.boxList?.length > 0 &&
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Box Summary
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Total
                                                Volume: {(+taskDetails?.boxVolumes)?.toFixed(2)} {taskDetails?.dimensionUnit}{'\u00B3'} / {(+convertCubicInToCubicMeter(+taskDetails?.boxVolumes)).toFixed(2)} m{'\u00B3'}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Total
                                                Weight: {(+taskDetails?.boxWeights)?.toFixed(2)} {taskDetails?.weightUnit} / {(+convertLBToKG(+taskDetails?.boxWeights, 2)).toFixed(2)} kg
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Quantity: {+taskDetails?.boxQuantity}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Standard Pallet: {+taskDetails?.standardPalletQuantity}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Standard Pallet Dimension: 48in x 40in x 80in
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Standard Pallet Weight Capacity: 3000lb
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start'
                                            }}>
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: palletCalculator ? '#454545' : '#1D8B45',
                                                        "&:hover": {
                                                            backgroundColor: palletCalculator ? '#454545' : '#1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                    onClick={palletCalculator ? handleClosePalletCalculator : handleOpenPalletCalculator}
                                                >
                                                    <Typography sx={{textTransform: 'none'}}>
                                                        {palletCalculator ? 'Close Calculator' : 'Pallet Calculator'}
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                        {
                                            palletCalculator &&
                                            <Grid item xs={12}>
                                                <PalletCalculator taskDetails={taskDetails}/>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {
                                taskDetails?.images?.length > 0 &&
                                <Grid item xs={12}>
                                    <Carousel
                                        navButtonsAlwaysVisible={true}
                                        // indicators={false}
                                        fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
                                        NextIcon={<NavigateNextIcon/>}
                                        PrevIcon={<NavigateBeforeIcon/>}
                                        autoPlay={false}
                                    >
                                        {
                                            taskDetails?.images?.map((item, index) => (
                                                <Paper key={index}>
                                                    <img
                                                        src={`${LABEL_DOWNLOAD_URL_PREFIX}${(item?.file_location_uri).startsWith("/") ? item?.file_location_uri?.slice(1) : item?.file_location_uri}`}
                                                        alt={`image${index}`}
                                                        style={{height: '300px', objectFit: 'cover'}}
                                                    />
                                                </Paper>
                                            ))
                                        }
                                    </Carousel>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}