import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import {Button, Box, TextField, Popper, Paper} from "@material-ui/core";
import produce from "immer";
// import { Paper } from '@material-ui/core'
// import orange from '@material-ui/core/colors/orange';
// import { makeStyles } from "@material-ui/core/styles";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { Alert } from '@material-ui/lab';

import PropTypes from "prop-types";

import HscodeInput from "./hscodeInput";
import HscodeInputField from "../../bolInputField";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {HSCODE_SEARCH} from "../../../../../utils/apiUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {styled} from "@material-ui/styles";
import {autocompleteClasses} from "@mui/material";
import uuid from 'react-uuid';

const defaultShippingItem = {
  hscode: "",
  weight: 0,
  measurement: 0,
  hscodeDescription: "",
  goodsDescription: "",
  containerType: "20GP",
  containerNumber: "",
  containerUnit: 1,
  packageType: "",
  packageUnit: 1,
  sealNumber: "",
  service: "",
  id: ""
};

const getDefaultShippingItem = () => {
  const result = {...defaultShippingItem};
  result.id = uuid();
  return result;
}

// const packageTypeOptions = [
//     "pallet",
//     "carton"
// ]

const containerTypeOptions = [
    "20GP",
    "40GP",
    "40HQ"
]


const BolItemInput = ({ bolItem, updateItemList, edit = false, onCancel }) => {
  // const dispatch = useDispatch();
  // const shippingItems = useSelector((state) => state.shipping.shippingItems);
  const classes = useStyles();

  const [userInputError, setUserInputError] = useState(false);
  // const classes = useStyles();

  const [shippingItem, setShippingItem] = useState(getDefaultShippingItem());

  const [searchResult, setSearchResult] = useState([]);

  const handleItemChange = (fieldName) => (e) => {
    const value = e.target.value;
    setShippingItem(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };
  const handleItemChangeByValue = (fieldName) => (value) => {
    setShippingItem(
        produce((draft) => {
          draft[fieldName] = value;
        })
    );
  }

  useEffect(() => {
    if (bolItem) {
      setShippingItem(bolItem);
    }
  }, [bolItem]);

  const handleItemChangeFromSelect = (fieldName) => (_value) => {
    setShippingItem(
      produce((draft) => {
        draft[fieldName] = _value;
      })
    );
  };

  const handleHscodeChange = (hscodeItem) => {
    handleItemChangeFromSelect("hscode")(hscodeItem.hscode || "");
    handleItemChangeFromSelect("hscodeDescription")(hscodeItem.desc || "");
  };


  const searchLevelSixSection = async () => {
    try {
      const { data } = await axios.get(`${HSCODE_SEARCH}?query_string=${shippingItem.hscodeDescription}`);

      const hits = data.hits;
      const levelSixRecords = hits.filter(hit => {
        return hit["_source"].level === "6";
      })
      const filterResult = levelSixRecords?.map(record => {
        return record["_source"]
      })
      setSearchResult(filterResult);
      console.log(filterResult);
    } catch (e) {
      console.log(e);
    }
  }

  const handleDataChange = () => {
    if (edit) {
      // dispatch(updateShippingItem({ newItem }));
      updateItemList(
        produce((draft) => {
          const index = draft.findIndex(
            (item) => item.id === shippingItem.id
          );
          console.log("Item Index:", index);
          if (index !== -1) {
            draft[index] = shippingItem;
          }
        })
      );
      //   setErrorMsg("");
      onCancel();
    } else {
      // dispatch(addShippingItem({ newItem }));
      updateItemList(
        produce((draft) => {
          draft.push(shippingItem);
        })
      );
      setShippingItem(defaultShippingItem);
    }
  };

  const CustomPopper = styled(Popper)({
    // maxWidth: "fit-content",
    width: "2000px",
    display: "flex",
    justifyContent: "start",
    alignContent: "center",
    [`& .MuiAutocomplete-listbox`]: {
        padding: 0,
        margin: 0,
        width: "1000px",
        display: "flex",
        flexDirection: "column",
        "& li": {
          width: "600px"
        }
    },
  });

  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} fullWidth style={{ width: 600 }} />;
  };

  // const CustomPaper = styled(Paper)({
  //   display: "flex",
  //   alignItems: "center",
  //   alignContent: "center",
  //   justifyContent: "center",
  //   width: "600px",
  // });

  return (
    <Box>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ gap: "0.6rem" }}>
          <Box>
            <Box textAlign="left">
              <span>Search HS Code</span>
            </Box>
            <HscodeInput handleItemChange={handleHscodeChange} />
            {/*{shippingItem.hscodeDescription ? (*/}
            {/*  <>*/}
            {/*    <Box mr={2} width="580px">*/}
            {/*      Hscode * : {shippingItem.hscode}*/}
            {/*    </Box>*/}
            {/*    <Box width="580px" mt={1}>*/}
            {/*      {shippingItem.hscodeDescription || ""}*/}
            {/*    </Box>*/}
            {/*  </>*/}
            {/*) : (*/}
            {/*  <Box mb={4} />*/}
            {/*)}*/}
          </Box>
          <hr />
          <Box width={"600px"} margin="0 auto">
            <Box textAlign="left">
              <span>Item Description</span>
            </Box>
            <Autocomplete
                // disablePortal
                options={searchResult}
                freeSolo
                PopperComponent={CustomPopper}
                inputValue={shippingItem.hscodeDescription}
                filterOptions={(options, state) => options}
                getOptionLabel={(option) => option.desc}
                onChange={(e, value) => {
                  handleItemChangeByValue("hscode")(value?.hscode || "");
                }}
                PaperComponent={CustomPaper}
                disableListWrap
                onInputChange={async (e, value, reason) => {
                  if (reason === "input") {
                    handleItemChangeByValue("hscodeDescription")(value);
                    await searchLevelSixSection();
                  }

                }}
                renderInput={(params) => <TextField
                    multiline
                    id="outlined-basic"
                    className={classes.textfield}
                    variant="outlined"
                    // value={shippingItem.hscodeDescription}
                    fullWidth
                    {...params}
                    required
                />}
                 />
            {/*<HscodeInputField*/}
            {/*    value={shippingItem.hscodeDescription}*/}
            {/*    title=""*/}
            {/*    onChange={handleItemChange("hscodeDescription")}*/}
            {/*    className={classes.textfield}*/}
            {/*    // rows={6}*/}
            {/*    placeholderValue="Sheep; live"*/}
            {/*    multiline*/}
            {/*/>*/}
          </Box>
          <Box width={"600px"} margin="0 auto">
            <Box textAlign="left">
              <span>HS Code</span>
            </Box>
            <Box width={"600px"} margin="0 auto">
              <HscodeInputField
                  title=""
                  value={shippingItem.hscode}
                  onChange={handleItemChange("hscode")}
                  className={classes.textfield}
                  // rows={6}
                  placeholderValue="010410"
              />
            </Box>
          </Box>
        </Box>
        <Box
          pl={1}
          color="red"
          visibility={
            userInputError && shippingItem.hscode === "" ? "visible" : "hidden"
          }
        >
          Cannot be emtpy
        </Box>
      </Box>

      <Box display="flex">
        <Box mr={4} flexBasis="50%">
          <HscodeInputField
            error={userInputError && shippingItem.packageType === ""}
            value={shippingItem.packageType}
            title="Package Type"
            mandatory={true}
            // type="select"
            // options={packageTypeOptions}
            onChange={handleItemChange("packageType")}
            placeholderValue="carton | pallet"
          />
        </Box>
        <Box mr={4} flexBasis="50%">
          <HscodeInputField
              error={userInputError && shippingItem.packageUnit < 0}
              value={shippingItem.packageUnit}
              title="Package Unit"
              isNonNegativeNumber
              mandatory={true}
              type="number"
              onChange={handleItemChange("packageUnit")}
              placeholderValue=""
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box mr={4} flexBasis="50%">
          <HscodeInputField
              value={shippingItem.sealNumber}
              title="Seal Number"
              onChange={handleItemChange("sealNumber")}
              placeholderValue="EMCMQV9631"
          />
        </Box>
        <Box mr={4} flexBasis="50%">
          <HscodeInputField
              value={shippingItem.service}
              title="Service"
              onChange={handleItemChange("service")}
              placeholderValue="CY-CY"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box mr={4} flexBasis="50%">
          <HscodeInputField
              value={shippingItem.containerType}
              title="Container Type"
              mandatory={true}
              type="select"
              options={containerTypeOptions}
              onChange={handleItemChange("containerType")}
          />
        </Box>
        <Box mr={4} flexBasis="50%">
          <HscodeInputField
              error={userInputError && shippingItem.containerUnit < 0}
              value={shippingItem.containerUnit}
              isNonNegativeNumber
              title="Unit of Container"
              mandatory={true}
              type="number"
              onChange={handleItemChange("containerUnit")}
              placeholderValue="1"
          />
        </Box>
        <Box mr={4} flexBasis="50%">
          <HscodeInputField
              value={shippingItem.containerNumber}
              title="Container Number"
              // mandatory={true}
              onChange={handleItemChange("containerNumber")}
              placeholderValue="FSCU8265366"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box mr={4} flexBasis="50%">
          <HscodeInputField
              error={userInputError && shippingItem.weight === ""}
              value={shippingItem.weight}
              isNonNegativeNumber
              title="Gross Weight(kg)"
              type="number"
              mandatory={true}
              onChange={handleItemChange("weight")}
          />
        </Box>
        <Box mr={4} flexBasis="50%">
          <Box>
            <HscodeInputField
              error={userInputError && shippingItem.measurement === ""}
              value={shippingItem.measurement}
              title="Measurement(CBM)"
              mandatory={true}
              isNonNegativeNumber
              type="number"
              onChange={handleItemChange("measurement")}
            />
          </Box>
          {/*<Box>*/}
          {/*  <HscodeInputField*/}
          {/*    value={shippingItem.containerType}*/}
          {/*    title="Container Type & Number/Seal No/Service"*/}
          {/*    onChange={handleItemChange("containerType")}*/}
          {/*    placeholderValue="40HD/FSCU8265366/EMCMQV9631/CY-CY"*/}
          {/*  />*/}
          {/*</Box>*/}
        </Box>
      </Box>

      <Box mb={2} mt={2} display="flex" justifyContent="center">
        {edit && (
          <Box mr={2}>
            <Button variant="outlined" color="default" onClick={onCancel}>
              CANCEL
            </Button>
          </Box>
        )}

        <Box ml={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDataChange}
            style={{
              textTransform: "unset",
            }}
          >
            {edit ? "UPDATE" : "Add to the list"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

// const theme = createMuiTheme({
//     palette: {
//         primary: { light: orange[300], main: orange[500], dark: orange[700] }
//     }
// })

const useStyles = makeStyles((theme) => ({

  textfield: {
    width: "100%",
    backgroundColor: "white",
    padding: "0.4px",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: "0.4vw",
    paddingBottom: "0.4vw",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    padding: theme.spacing(2, 4, 3),
    overflow: "hidden",
  },
  input: {
    color: "grey",
    width: "90%",
  },
  label: {
    flexDirection: "column",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
  },
  backButton: {
    marginRight: theme.spacing(4),
    backgroundColor: "white",
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
}));

BolItemInput.propTypes = {
  userInputError: PropTypes.bool.isRequired,
  addItem: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
};

export default BolItemInput;
