import {
    americaProvinceCodeToNameMap,
    americaProvinceNameToCodeMap,
    canadaProvinceCodeToNameMap
} from "../shared/constInfo";

const getProvinceCodeByName = (name) => {
    switch (name) {
        case "Newfoundland and Labrador":
            return "NL";
        case "Prince Edward Island":
            return "PE";
        case "Nova Scotia":
            return "NS";
        case "New Brunswick":
            return "NB";
        case "Quebec":
            return "QC";
        case "Québec":
            return "QC";
        case "Ontario":
            return "ON";
        case "Manitoba":
            return "MB";
        case "Saskatchewan":
            return "SK";
        case "Alberta":
            return "AB";
        case "British Columbia":
            return "BC";
        case "Yukon":
            return "YT";
        case "Northwest Territories":
            return "NT";
        case "Nunavut":
            return "NU";
        case "Alabama":
            return "AL";
        case "Alaska":
            return "AK";
        case "Arizona":
            return "AZ";
        case "Arkansas":
            return "AR";
        case "California":
            return "CA";
        case "Colorado":
            return "CO";
        case "Connecticut":
            return "CT";
        case "Delaware":
            return "DE";
        case "Florida":
            return "FL";
        case "Georgia":
            return "GA";
        case "Hawaii":
            return "HI";
        case "Idaho":
            return "ID";
        case "Illinois":
            return "IL";
        case "Indiana":
            return "IN";
        case "Iowa":
            return "IA";
        case "Kansas":
            return "KS";
        case "Kentucky":
            return "KY";
        case "Louisiana":
            return "LA";
        case "Maine":
            return "ME";
        case "Maryland":
            return "MD";
        case "Massachusetts":
            return "MA";
        case "Michigan":
            return "MI";
        case "Minnesota":
            return "MN";
        case "Mississippi":
            return "MS";
        case "Missouri":
            return "MO";
        case "Montana":
            return "MT";
        case "Nebraska":
            return "NE";
        case "Nevada":
            return "NV";
        case "New Hampshire":
            return "NH";
        case "New Jersey":
            return "NJ";
        case "New Mexico":
            return "NM";
        case "New York":
            return "NY";
        case "North Carolina":
            return "NC";
        case "North Dakota":
            return "ND";
        case "Ohio":
            return "OH";
        case "Oklahoma":
            return "OK";
        case "Oregon":
            return "OR";
        case "Pennsylvania":
            return "PA";
        case "Rhode Island":
            return "RI";
        case "South Carolina":
            return "SC";
        case "South Dakota":
            return "SD";
        case "Tennessee":
            return "TN";
        case "Texas":
            return "TX";
        case "Utah":
            return "UT";
        case "Vermont":
            return "VT";
        case "Virginia":
            return "VA";
        case "Washington":
            return "WA";
        case "West Virginia":
            return "WV";
        case "Wisconsin":
            return "WI";
        case "Wyoming":
            return "WY";
        case "NL":
            return "NL";
        case "PE":
            return "PE";
        case "NS":
            return "NS";
        case "NB":
            return "NB";
        case "QC":
            return "QC";
        case "ON":
            return "ON";
        case "MB":
            return "MB";
        case "SK":
            return "SK";
        case "AB":
            return "AB";
        case "BC":
            return "BC";
        case "YT":
            return "YT";
        case "NT":
            return "NT";
        case "NU":
            return "NU";
        default:
            return name;
    }
};

export const getCountryCodeByName = (name) => {
    switch (name) {
        case "Afghanistan":
            return "AF";
        case "Albania":
            return "AL";
        case "Algeria":
            return "DZ";
        case "Andorra":
            return "AD";
        case "Angola":
            return "AO";
        case "Antigua and Barbuda":
            return "AG";
        case "Argentina":
            return "AR";
        case "Armenia":
            return "AM";
        case "Australia":
            return "AU";
        case "Austria":
            return "AT";
        case "Azerbaijan":
            return "AZ";
        case "Bahamas":
            return "BS";
        case "Bahrain":
            return "BH";
        case "Bangladesh":
            return "BD";
        case "Barbados":
            return "BB";
        case "Belarus":
            return "BY";
        case "Belgium":
            return "BE";
        case "Belize":
            return "BZ";
        case "Benin":
            return "BJ";
        case "Bhutan":
            return "BT";
        case "Bolivia":
            return "BO";
        case "Bosnia and Herzegovina":
            return "BA";
        case "Botswana":
            return "BW";
        case "Brazil":
            return "BR";
        case "Brunei Darussalam":
            return "BN";
        case "Bulgaria":
            return "BG";
        case "Burkina Faso":
            return "BF";
        case "Burundi":
            return "BI";
        case "Cabo Verde":
            return "CV";
        case "Cambodia":
            return "KH";
        case "Cameroon":
            return "CM";
        case "Canada":
            return "CA";
        case "Central African Republic":
            return "CF";
        case "Chad":
            return "TD";
        case "Chile":
            return "CL";
        case "China":
            return "CN";
        case "Colombia":
            return "CO";
        case "Comoros":
            return "KM";
        case "Congo":
            return "CG";
        case "Costa Rica":
            return "CR";
        case "Côte d'Ivoire":
            return "CI";
        case "Croatia":
            return "HR";
        case "Cuba":
            return "CU";
        case "Cyprus":
            return "CY";
        case "Czech Republic":
            return "CZ";
        case "Denmark":
            return "DK";
        case "Djibouti":
            return "DJ";
        case "Dominica":
            return "DM";
        case "Dominican Republic":
            return "DO";
        case "Ecuador":
            return "EC";
        case "Egypt":
            return "EG";
        case "El Salvador":
            return "SV";
        case "Equatorial Guinea":
            return "GQ";
        case "Eritrea":
            return "ER";
        case "Estonia":
            return "EE";
        case "Eswatini":
            return "SZ";
        case "Ethiopia":
            return "ET";
        case "Fiji":
            return "FJ";
        case "Finland":
            return "FI";
        case "France":
            return "FR";
        case "Gabon":
            return "GA";
        case "Gambia":
            return "GM";
        case "Georgia":
            return "GE";
        case "Germany":
            return "DE";
        case "Ghana":
            return "GH";
        case "Greece":
            return "GR";
        case "Grenada":
            return "GD";
        case "Guatemala":
            return "GT";
        case "Guinea":
            return "GN";
        case "Guinea-Bissau":
            return "GW";
        case "Guyana":
            return "GY";
        case "Haiti":
            return "HT";
        case "Honduras":
            return "HN";
        case "Hungary":
            return "HU";
        case "Iceland":
            return "IS";
        case "India":
            return "IN";
        case "Indonesia":
            return "ID";
        case "Iran":
            return "IR";
        case "Iraq":
            return "IQ";
        case "Ireland":
            return "IE";
        case "Israel":
            return "IL";
        case "Italy":
            return "IT";
        case "Jamaica":
            return "JM";
        case "Japan":
            return "JP";
        case "Jordan":
            return "JO";
        case "Kazakhstan":
            return "KZ";
        case "Kenya":
            return "KE";
        case "Kiribati":
            return "KI";
        case "Korea, North":
            return "KP";
        case "Korea, South":
            return "KR";
        case "Kosovo":
            return "XK";
        case "Kuwait":
            return "KW";
        case "Kyrgyzstan":
            return "KG";
        case "Laos":
            return "LA";
        case "Latvia":
            return "LV";
        case "Lebanon":
            return "LB";
        case "Lesotho":
            return "LS";
        case "Liberia":
            return "LR";
        case "Libya":
            return "LY";
        case "Liechtenstein":
            return "LI";
        case "Lithuania":
            return "LT";
        case "Luxembourg":
            return "LU";
        case "Macao":
            return "MO";
        case "Macedonia (the former Yugoslav Republic of)":
            return "MK";
        case "Madagascar":
            return "MG";
        case "Malawi":
            return "MW";
        case "Malaysia":
            return "MY";
        case "Maldives":
            return "MV";
        case "Mali":
            return "ML";
        case "Malta":
            return "MT";
        case "Marshall Islands":
            return "MH";
        case "Martinique":
            return "MQ";
        case "Mauritania":
            return "MR";
        case "Mauritius":
            return "MU";
        case "Mayotte":
            return "YT";
        case "Mexico":
            return "MX";
        case "Micronesia (Federated States of)":
            return "FM";
        case "Moldova (Republic of)":
            return "MD";
        case "Monaco":
            return "MC";
        case "Mongolia":
            return "MN";
        case "Montenegro":
            return "ME";
        case "Montserrat":
            return "MS";
        case "Morocco":
            return "MA";
        case "Mozambique":
            return "MZ";
        case "Myanmar":
            return "MM";
        case "Namibia":
            return "NA";
        case "Nauru":
            return "NR";
        case "Nepal":
            return "NP";
        case "Netherlands":
            return "NL";
        case "New Caledonia":
            return "NC";
        case "New Zealand":
            return "NZ";
        case "Nicaragua":
            return "NI";
        case "Niger":
            return "NE";
        case "Nigeria":
            return "NG";
        case "Niue":
            return "NU";
        case "Norfolk Island":
            return "NF";
        case "North Korea":
            return "KP";
        case "Northern Mariana Islands":
            return "MP";
        case "Norway":
            return "NO";
        case "Oman":
            return "OM";
        case "Pakistan":
            return "PK";
        case "Palau":
            return "PW";
        case "Palestine, State of":
            return "PS";
        case "Panama":
            return "PA";
        case "Papua New Guinea":
            return "PG";
        case "Paraguay":
            return "PY";
        case "Peru":
            return "PE";
        case "Philippines":
            return "PH";
        case "Pitcairn":
            return "PN";
        case "Poland":
            return "PL";
        case "Portugal":
            return "PT";
        case "Puerto Rico":
            return "PR";
        case "Qatar":
            return "QA";
        case "Russia":
            return "RU";
        case "Rwanda":
            return "RW";
        case "Saint Kitts and Nevis":
            return "KN";
        case "Saint Lucia":
            return "LC";
        case "Saint Vincent and the Grenadines":
            return "VC";
        case "Samoa":
            return "WS";
        case "San Marino":
            return "SM";
        case "Sao Tome and Principe":
            return "ST";
        case "Saudi Arabia":
            return "SA";
        case "Senegal":
            return "SN";
        case "Serbia":
            return "RS";
        case "Seychelles":
            return "SC";
        case "Sierra Leone":
            return "SL";
        case "Singapore":
            return "SG";
        case "Slovakia":
            return "SK";
        case "Slovenia":
            return "SI";
        case "Solomon Islands":
            return "SB";
        case "Somalia":
            return "SO";
        case "South Africa":
            return "ZA";
        case "South Sudan":
            return "SS";
        case "Spain":
            return "ES";
        case "Sri Lanka":
            return "LK";
        case "Sudan":
            return "SD";
        case "Suriname":
            return "SR";
        case "Sweden":
            return "SE";
        case "Switzerland":
            return "CH";
        case "Syria":
            return "SY";
        case "Taiwan":
            return "TW";
        case "Tajikistan":
            return "TJ";
        case "Tanzania":
            return "TZ";
        case "Thailand":
            return "TH";
        case "Timor-Leste":
            return "TL";
        case "Togo":
            return "TG";
        case "Tonga":
            return "TO";
        case "Trinidad and Tobago":
            return "TT";
        case "Tunisia":
            return "TN";
        case "Turkey":
            return "TR";
        case "Turkmenistan":
            return "TM";
        case "Tuvalu":
            return "TV";
        case "Uganda":
            return "UG";
        case "Ukraine":
            return "UA";
        case "United Arab Emirates":
            return "AE";
        case "United Kingdom":
            return "GB";
        case "United States":
            return "US";
        case "Uruguay":
            return "UY";
        case "Uzbekistan":
            return "UZ";
        case "Vanuatu":
            return "VU";
        case "Vatican City":
            return "VA";
        case "Venezuela":
            return "VE";
        case "Vietnam":
            return "VN";
        case "Yemen":
            return "YE";
        case "Zambia":
            return "ZM";
        case "Zimbabwe":
            return "ZW";
        default:
            return name;
    }
}

export const getUSProvinceCodeByName = (name) => {
    return americaProvinceNameToCodeMap[name] || name;
}

export const getCanadaProvinceNameByCode = (name) => {
    return canadaProvinceCodeToNameMap[name] || name;
}

export const getUSProvinceNameByCode = (code) => {
    return americaProvinceCodeToNameMap[code] || code;

}

export default getProvinceCodeByName;


