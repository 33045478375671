import React, {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {
    AppBar,
    Box,
    Toolbar,
    makeStyles,
    useMediaQuery,
    Button,
} from "@material-ui/core";

import "../styles.css";
import axios from "axios";

import {getAccessToken, deleteToken} from "../utils/doToken";
import {useHistory} from "react-router-dom";
// import { userSignOut } from '../../actions/userSignInAction';
import {deleteUserInfo, updateUserInfo} from "../redux/userSlice";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    logo: {
        flexGrow: 1,
    },
    button: {
        borderRadius: 20,
        borderColor: "green",
        color: "green",
        fontSize: "14pt",
        flexGrow: 1,
    },
    userButton: {
        borderRadius: 20,
        borderColor: "green",
        color: "green",
        fontSize: "14pt",
        marginRight: "2vw",
    },

    // appBarSpacer: {
    //   height: "70px",
    // },
}));

const NavBar = ({deferredPrompt, setDeferredPrompt}) => {
    const history = useHistory();
    //   const classes = useStyles();
    const dispatch = useDispatch();

    const matches = useMediaQuery("(max-width:800px)");

    const signOut = (e) => {
        const storedToken = getAccessToken("access_token");
        e.preventDefault();
        try {
            axios.get(`https://admin.uucargo.ca/api/logout`, {
                headers: {Authorization: `Bearer ${storedToken}`},
            });
            //setHide(true)
        } finally {
            deleteToken("access_token");
            dispatch(deleteUserInfo());
            dispatch(updateUserInfo({field: "login", value: false}));
            dispatch(updateUserInfo({field: "signIn", value: false}));

            history.push("/sign-in");
        }
    };

    //   const redirectSupport = () => {
    //     window.open("https://www.uucargo.ca/support/", "_blank");
    //   };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            width={matches ? "100%" : "800px"}
        >
            <Button onClick={() => history.push("/")}>
                <b>Home</b>
            </Button>
            <Button
                aria-label="Sign out"
                aria-haspopup="true"
                onClick={signOut}
                color="inherit"
            >
                Sign Out
            </Button>
        </Box>
    );
};

export default NavBar;
