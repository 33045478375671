import {Modal} from "@mui/joy";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {MenuItem, Select} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {getCountryCode} from "../../utils/getCountryCode";
import {UOMList} from "../../shared/constInfo";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";
import axios from "axios";
import {HSCODE_SEARCH, PARTNER_URI} from "../../utils/apiUrl";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const MyBoxItemEdit = ({editBoxItem, closeEdit}) => {

    const [boxItemDetail, setBoxItemDetail] = useState({})

    const [HSCodeList, setHSCodeList] = useState([]);

    const [toastOpen, setToastOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleClose = () => {
        closeEdit()
    }

    const updateMyBoxById = async (data) => {
        let requestURL = `${PARTNER_URI}/myboxes/updateMyBoxById`;
        try {
            await axios({
                method: 'put',
                url: requestURL,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            })
            closeEdit();
            window.location.reload();
        } catch (e) {
            console.log(e?.response?.data?.error?.code || e?.response?.data?.message || 'Error');
            setToastOpen(true)
            setErrorMessage(e?.response?.data?.error?.code || e?.response?.data?.message || 'Error')
        }
    }

    const searchHSCode = async (value) => {
        try {
            const {data} = await axios.get(`${HSCODE_SEARCH}?query_string=${value}`);
            // console.log("searchLevelSixSection", data);

            const hits = data.hits;
            const levelSixRecords = hits.filter(hit => {
                return hit["_source"].level === "6";
            })
            const filterResult = levelSixRecords?.map(record => {
                return record["_source"]
            })
            setHSCodeList(filterResult);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        setBoxItemDetail(prevState => ({
            ...prevState,
            boxId: editBoxItem.boxId,
            boxName: editBoxItem.boxName,
            partnerId: editBoxItem.partnerId,
            itemName: editBoxItem.itemName,
            weightUnit: editBoxItem.weightUnit,
            dimensionUnit: editBoxItem.dimensionUnit,
            length: editBoxItem.length,
            width: editBoxItem.width,
            height: editBoxItem.height,
            weight: editBoxItem.weight,
            originalCountry: editBoxItem.originalCountry,
            uom: editBoxItem.uom,
            currency: editBoxItem.currency,
            hsCode: editBoxItem.hsCode,
            unitPrice: editBoxItem.unitPrice
        }))
    }, [])

    const handleSubmit = () => {

        let newData = {
            boxId: boxItemDetail.boxId,
            boxName: boxItemDetail.boxName,
            dimensionUnit: boxItemDetail.dimensionUnit,
            height: boxItemDetail.height ? boxItemDetail.height : 1,
            itemName: boxItemDetail.itemName ? boxItemDetail.itemName : "",
            length: boxItemDetail.length ? boxItemDetail.length : 1,
            partnerId: boxItemDetail.partnerId,
            weight: boxItemDetail.weight ? boxItemDetail.weight : 1,
            weightUnit: boxItemDetail.weightUnit,
            width: boxItemDetail.width ? boxItemDetail.width : 1,
            originalCountry: boxItemDetail.originalCountry,
            uom: boxItemDetail.uom,
            currency: boxItemDetail.currency,
            hsCode: boxItemDetail.hsCode,
            unitPrice: boxItemDetail.unitPrice ? boxItemDetail.unitPrice : 0
        }
        updateMyBoxById(newData)
        // console.log(newData)
    }

    // console.log('box item detail', boxItemDetail)

    return (
        <Box sx={style}>
            <Typography variant="h5">
                Edit Box
            </Typography>
            <Box sx={{backgroundColor: '#ffffff', padding: '10px', borderRadius: '10px'}}>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={toastOpen}
                    onClose={handleToastClose}
                    autoHideDuration={3000}
                    message="Submit Transaction"
                >
                    {(() => {
                        if (errorMessage !== "") {
                            return (
                                <Alert
                                    onClose={handleToastClose}
                                    severity="error"
                                    sx={{width: "100%"}}
                                >
                                    Error!
                                    <hr/>
                                    {errorMessage}
                                </Alert>
                            );
                        }
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="success"
                                sx={{width: "100%"}}
                            >
                                {successMessage}
                            </Alert>
                        );
                    })()}
                </Snackbar>
                <Typography sx={{textAlign: 'left'}}>
                    Item Information
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'none',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <TextField
                        value={boxItemDetail?.boxName ? boxItemDetail?.boxName : ''}
                        sx={{margin: '10px'}}
                        label="Box Name"
                        required
                        fullWidth
                        variant="standard"
                        onInput={e => setBoxItemDetail(prevState => ({
                            ...prevState, boxName: e.target.value
                        }))}
                    />
                    <TextField
                        value={boxItemDetail.itemName}
                        sx={{margin: '10px'}}
                        label="Item Name"
                        fullWidth
                        required
                        variant="standard"
                        onInput={e => setBoxItemDetail(prevState => ({
                            ...prevState, itemName: e.target.value
                        }))}
                    />
                    <TextField
                        value={boxItemDetail.weight}
                        sx={{margin: '10px'}}
                        label="Weight"
                        fullWidth
                        variant="standard"
                        required
                        onInput={e => setBoxItemDetail(prevState => ({
                            ...prevState, weight: e.target.value
                        }))}
                    />
                    <FormControl variant="standard" sx={{margin: '10px'}} fullWidth>
                        <InputLabel>Weight Unit*</InputLabel>
                        <Select
                            value={boxItemDetail?.weightUnit ? boxItemDetail?.weightUnit : ''}
                            label="weight unit"
                            onChange={e => setBoxItemDetail(prevState => ({
                                ...prevState, weightUnit: e.target.value
                            }))}
                        >
                            <MenuItem value={'lb'}>lb</MenuItem>
                            <MenuItem value={'kg'}>kg</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'none',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <TextField
                        value={boxItemDetail.length}
                        sx={{margin: '10px'}}
                        label="Length"
                        required
                        fullWidth
                        variant="standard"
                        onInput={e => setBoxItemDetail(prevState => ({
                            ...prevState, length: e.target.value
                        }))}
                    />
                    <TextField
                        value={boxItemDetail.width}
                        sx={{margin: '10px'}}
                        label="Width"
                        required
                        variant="standard"
                        fullWidth
                        onInput={e => setBoxItemDetail(prevState => ({
                            ...prevState, width: e.target.value
                        }))}
                    />
                    <TextField
                        value={boxItemDetail.height}
                        sx={{margin: '10px'}}
                        label="Height"
                        required
                        fullWidth
                        variant="standard"
                        onInput={e => setBoxItemDetail(prevState => ({
                            ...prevState, height: e.target.value
                        }))}
                    />
                    <FormControl variant="standard" sx={{margin: '10px'}} fullWidth>
                        <InputLabel>Length Unit*</InputLabel>
                        <Select
                            value={boxItemDetail?.dimensionUnit ? boxItemDetail?.dimensionUnit : ''}
                            label="length unit"
                            onChange={e => setBoxItemDetail(prevState => ({
                                ...prevState, dimensionUnit: e.target.value
                            }))}
                        >
                            <MenuItem value={'cm'}>cm</MenuItem>
                            <MenuItem value={'in'}>in</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {/*<Box sx={{*/}
                {/*    display: 'flex',*/}
                {/*    justifyContent: 'flex-start',*/}
                {/*    alignItems: 'center',*/}
                {/*    marginTop: '10px'*/}
                {/*}}>*/}
                {/*    <IconButton*/}
                {/*        onClick={() => window.location.href = 'https://uucargo.ca/support/cross-border-shipping-parcels/'}>*/}
                {/*        <HelpIcon color="info" sx={{fontSize: 25}}/>*/}
                {/*    </IconButton>*/}
                {/*    <Typography sx={{}}>*/}
                {/*        Cross Border Only (Mandatory for shipping Different Countries)*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                {/*<Box*/}
                {/*    sx={{*/}
                {/*        display: 'flex',*/}
                {/*        flexWrap: 'none',*/}
                {/*        alignItems: 'center',*/}
                {/*        justifyContent: 'space-between'*/}
                {/*    }}>*/}
                {/*    <TextField*/}
                {/*        value={boxItemDetail.unitPrice}*/}
                {/*        sx={{margin: '10px'}}*/}
                {/*        label="Price"*/}
                {/*        required*/}
                {/*        variant="standard"*/}
                {/*        onInput={e => setBoxItemDetail(prevState => ({*/}
                {/*            ...prevState, unitPrice: e.target.value*/}
                {/*        }))}*/}
                {/*        fullWidth*/}
                {/*    />*/}
                {/*    <FormControl variant="standard" fullWidth sx={{margin: '10px'}}>*/}
                {/*        <InputLabel>Currency*</InputLabel>*/}
                {/*        <Select*/}
                {/*            value={boxItemDetail?.currency ? boxItemDetail?.currency : ''}*/}
                {/*            label="Currency"*/}
                {/*            onChange={e => setBoxItemDetail(prevState => ({*/}
                {/*                ...prevState, currency: e.target.value*/}
                {/*            }))}*/}
                {/*        >*/}
                {/*            <MenuItem value={'CAD'}>CAD</MenuItem>*/}
                {/*            <MenuItem value={'USD'}>USD</MenuItem>*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*    <FormControl sx={{margin: '10px'}} fullWidth variant="standard">*/}
                {/*        <InputLabel>Origin Country*</InputLabel>*/}
                {/*        <Select*/}
                {/*            value={boxItemDetail?.originalCountry ? boxItemDetail?.originalCountry : ''}*/}
                {/*            label="origin country"*/}
                {/*            onChange={e => setBoxItemDetail(prevState => ({*/}
                {/*                ...prevState, originalCountry: e.target.value*/}
                {/*            }))}*/}
                {/*        >*/}
                {/*            {*/}
                {/*                getCountryCode().map(v => {*/}
                {/*                    return <MenuItem value={v.code}>{v.name}</MenuItem>*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*    <FormControl sx={{margin: '10px'}} fullWidth variant="standard">*/}
                {/*        <InputLabel>Unit of Measure*</InputLabel>*/}
                {/*        <Select*/}
                {/*            value={boxItemDetail?.uom ? boxItemDetail?.uom : ''}*/}
                {/*            label="unit of measure"*/}
                {/*            onChange={e => setBoxItemDetail(prevState => ({*/}
                {/*                ...prevState, uom: e.target.value*/}
                {/*            }))}*/}
                {/*        >*/}
                {/*            {UOMList.map(v => {*/}
                {/*                return <MenuItem value={v}>{v}</MenuItem>*/}
                {/*            })}*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*</Box>*/}
                {/*<Box*/}
                {/*    sx={{*/}
                {/*        display: 'flex',*/}
                {/*        flexWrap: 'none',*/}
                {/*        alignItems: 'center',*/}
                {/*        margin: '0 10px'*/}
                {/*    }}>*/}
                {/*    <Autocomplete*/}
                {/*        // sx={{margin: '10px'}}*/}
                {/*        disablePortal*/}
                {/*        options={HSCodeList}*/}
                {/*        fullWidth*/}
                {/*        freeSolo*/}
                {/*        filterOptions={(options, state) => options}*/}
                {/*        // getOptionLabel={(option) => option.label}*/}
                {/*        getOptionLabel={(option) => `${option.hscode}`}*/}
                {/*        renderOption={(option) => <span><b>{option.hscode}</b> | {option.desc}</span>}*/}
                {/*        onChange={(e, value) => {*/}
                {/*            // handleItemChangeByValue("hscode")(value?.hscode || "");*/}
                {/*        }}*/}
                {/*        onInputChange={async (e, value) => {*/}
                {/*            // handleItemChangeByValue("hscodeDescription")(value);*/}
                {/*            setBoxItemDetail(prevState => ({*/}
                {/*                ...prevState, hsCode: value*/}
                {/*            }))*/}
                {/*            await searchHSCode(value);*/}

                {/*        }}*/}
                {/*        renderInput={(params) => <TextField*/}
                {/*            multiline*/}
                {/*            id="outlined-basic"*/}
                {/*            label={boxItemDetail.hsCode}*/}
                {/*            variant="standard"*/}
                {/*            fullWidth*/}
                {/*            {...params}*/}
                {/*            required*/}
                {/*        />}*/}
                {/*    />*/}
                {/*</Box>*/}
                <Box>
                    <Button
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}