import Grid from "@material-ui/core/Grid";
import {Snackbar} from "@mui/material";
import * as React from 'react';
import MuiAlert from "@mui/material/Alert";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import CircularProgress from "@mui/material/CircularProgress";
import {CreditCardList} from "../CreditCard/CreditCardList";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreditCardIcon from "../../images/creditCardIcon.png";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Modal from "@mui/material/Modal";
import {CreditCardSetup} from "../CreditCard/CreditCardSetup";
import {useHistory} from "react-router-dom";
import intl from "react-intl-universal";

const styles = {
    TopUpCreditCardRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    TopUpCreditCardTitle: {
        fontWeight: '600',
        textAlign: 'left'
    },
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TopUpCreditCard = ({amount, cbSelectCreditCard}) => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");

    const [cardList, setCardList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [addNewCard, setAddNewCard] = useState(false);
    const [selectCardId, setSelectCardId] = useState(null);

    const handleClickOpen = () => {
        setAddNewCard(true);
    };

    const handleClose = () => {
        setAddNewCard(false);
    };

    const getValidCardList = async () => {
        const requestURL = `${PARTNER_URI}/stripe/validCreditCardList`;
        setIsLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
            })

            console.log('result', result);
            setCardList(result?.data);
            setIsLoading(false);
            setError(false);
        } catch (e) {
            console.log('error', e.response);
            setError(true);
        }
    }

    const TopUpViaCreditCard = async () => {
        const requestURL = `${PARTNER_URI}/billingCenter/initDownPaymentInvoiceAndTopUpViaCreditCardForPartner`;
        setLoading(true);
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: {
                    amount: amount,
                    creditCardId: selectCardId
                }
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully Top Up');
            handleSuccessMessage();
            setLoading(false);
            setTimeout(() => {
                history.push("/billing-center");
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Fail To Top Up');
            handleErrorMessage();
            setLoading(false);
        }
    }

    const cbGetValidCardList = () => {
        getValidCardList();
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cbSelectCardId = (cardId) => {
        setSelectCardId(cardId);
    }

    const handleCreditCardTopUp = () => {
        TopUpViaCreditCard();
    }

    useEffect(() => {
        getValidCardList()
    }, []);

    useEffect(() => {
        cbSelectCreditCard(selectCardId);
    }, [selectCardId])

    if (isLoading) {
        return <>
            <CircularProgress/>
        </>
    }

    if (error && !isLoading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    // console.log('select card id', selectCardId);

    return (
        <Grid container spacing={4} alignItems="center" justifyContent="center">
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item md={9} xs={12}>
                <Box sx={styles.TopUpCreditCardRoot}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography style={styles.TopUpCreditCardTitle}>
                            {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.SELECT_CARD')}
                        </Typography>
                        {
                            cardList?.length > 0 ? <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    // size='small'
                                    onClick={handleClickOpen}
                                    disabled={!selectCardId}
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                        {intl.get('BILLING_CENTER.UPDATE')}
                                    </Typography>
                                </LoadingButton>
                                :
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleClickOpen}
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                        {intl.get('BILLING_CENTER.ADD_NEW')}
                                    </Typography>
                                </LoadingButton>
                        }
                    </Box>
                    {
                        cardList?.length > 0 ? <CreditCardList cardList={cardList} cbSelectCardId={cbSelectCardId}/> :
                            <Box sx={{
                                display: 'flex',
                                border: '1px solid #000000',
                                flexDirection: 'column',
                                borderRadius: '5px',
                                gap: '10px',
                                padding: '10px'
                            }}>
                                <Box sx={{position: 'relative'}}>
                                    <img src={CreditCardIcon} alt="creditCardLogo" width={30}/>
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            left: '49%',
                                            top: '15%',
                                        }}
                                        onClick={handleClickOpen}
                                    >
                                        <AddCircleIcon
                                            sx={{
                                                backgroundColor: '#ffffff',
                                                color: '#1D8B45',
                                                borderRadius: '50%',
                                                fontSize: '12px'
                                            }}
                                        />
                                    </IconButton>
                                    <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                                        {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.ADD_NEW_CARD')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography style={{fontSize: '12px'}}>
                                        {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.NO_CREDIT_CARD_NOTE')}
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', gap: '2px'}}>
                                    <Typography style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                        *
                                    </Typography>
                                    <Box>
                                        <Typography style={{fontSize: '10px', textAlign: 'left'}}>
                                            {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.ADD_NEW_CARD_NOTE')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                    }
                </Box>
            </Grid>
            <Modal onClose={handleClose} open={addNewCard}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #000000',
                    width: 400,
                    boxShadow: 24,
                    backgroundColor: '#ffffff',
                    borderRadius: '10px',
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Box sx={{backgroundColor: '#1D8B45', borderRadius: '9px 9px 0 0', padding: '20px'}}>
                            <Typography style={{fontSize: '20px', color: '#ffffff'}}>
                                Add Credit Card
                            </Typography>
                        </Box>
                        <Box>
                            <CreditCardSetup handleClose={handleClose} cbGetValidCardList={cbGetValidCardList}
                                             selectCardId={selectCardId}/>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Grid>
    )
}