import {Redirect, Route, Switch} from "react-router-dom";
import {getAccessToken} from "../utils/doToken";
import {LandingEstimate} from "../component/Landing/LandingEstimate";
import {Landing} from "../component/Landing/Landing";
import PrivateRoute from "../component/PrivateRoute";
import {Brokerage} from "../component/Brokerage/Brokerage";
import {BrokeragePackingList} from "../component/Brokerage/BrokeragePackingList";
import {MyBoxes} from "../component/MyBoxes/MyBoxes";
import {AddressList} from "../component/AddressList/AddressList";
import {PackingList} from "../component/PackingList/PackingList";
import CsvFFHandler from "../component/CsvFFHandler/csvFFHandler";
import ShippingViaExcel from "../component/ShippingViaExcel/csvFFHandler";
import LandingPage from "../component/landingPage";
import ShippingListTracking from "../component/ShippingList/ShippingListTracking";
import ShippingListTrackingByOrderId from "../component/ShippingList/ShippingListTrackingByOrderId";
import ShippingExportsTransaction from "../component/ShippingList/ShippingExportsTransaction";
import SearchShippingTransactions from "../component/SearchShippingTransactions/SearchShippingTransactions";
import ShippingListMultipleTrackingNumber from "../component/ShippingList/ShippingListMultipleTrackingNumber";
import ShippingListProduct from "../component/ShippingList/ShippingListProduct";
import ShippingOrderList from "../component/ShippingList/ShippingOrderList";
import ShippingOrderListByOrderId from "../component/ShippingList/ShippingOrderListByOrderId";
import OrderDashboard from "../component/OrderDashboard/OrderDashboard";
import BillingCenter from "../component/BillingCenter/BilingCenter";
import AllPartnerShippingOrderPaymentList
    from "../component/Shipping/SearchShippingOrders/All3PLShippingOrderList/OrderPaymentList";
import ShippingOrderPaymentDetails from "../component/Shipping/SearchShippingOrders/ShippingOrderPaymentDetails";
import Printer from "../component/Printer/Printer";
import BolListPage from "../component/Hscode/bolListPage";
import HscodePage from "../component/Hscode/hscodePage";
import UserProfile from "../component/UserProfile/userProfile";
import ChangePassword from "../component/Auth/changePassword";
import HSCodeMainPage from "../component/HSCodeTrainingPage/HSCodeMainPage";
import StartQuote from "../component/Shipping/startQuote";
import StartShippingLTL from "../component/ShippingLTL/startShippingLTL";
import StartPalletsQuote from "../component/Pallets/startPalletsQuote";
import PalletsListPage from "../component/PalletsList/paletsListPage";
import OceanShippingPage from "../component/OceanShipping/OceanShippingPage";
import OceanBooking from "../component/OceanBooking/OceanBooking";
import PartnerInvoicePage from "../component/Invoice/PartnerInvoicePage";
import CommercialInvoicePage from "../component/CommercialInvoice/commercialInvoicePage";
import SchedulePickup from "../component/SchedulePickup/SchedulePickup";
import MyScheduledPickups from "../component/SchedulePickup/MyScheduledPickups";
import TrackPackages from "../component/Tracking/TrackPackages";
import {useGaTracker} from "../shared/Hooks/useGaTracker";
import ShippingLTLDetailsPage from "../component/ShippingLTLDetails/shippingLTLDetailsPage";
import ShippingLTLListPage from "../component/ShippingLTLList/ShippingLTLListPage";
import ShippingBrokerageListPage from "../component/ShippingBrokerageList/ShippingBrokerageListPage";
import ShippingBrokerageDetails from "../component/ShippingBrokerageDetails/shippingBrokerageDetailsPage";
import {LandingSignIn} from "../component/Landing/LandingSignIn";
import {LandingSignUp} from "../component/Landing/LandingSignUp";
import {LandingShippingRateCalculator} from "../component/Landing/LandingShippingRateCalculator";
import ForgotPasswordPage from "../component/Auth/forgotPasswordPage";
import ResetPasswordPage from "../component/Auth/resetPasswordPage";
import ReceiveEmailPage from "../component/Auth/receiveEmailPage";
import {MyStaff} from "../component/MyStaff/MyStaff";
import {ShippingLTLQuickQuote} from "../component/ShippingLTL/ShippingLTLQuickQuote";
import {ShippingPackageQuickQuote} from "../component/ShippingPackage/ShippingPackageQuickQuote";
import {QuoteLTL} from "../component/QuoteLTL/QuoteLTL";
import {QuoteLTLDetails} from "../component/QuoteLTL/QuoteLTLDetails";
import {ShippingPackageCreateLabel} from "../component/ShippingPackage/ShippingPackageCreateLabel";
import {ShippingPackageCreateLabelRate} from "../component/ShippingPackage/ShippingPackageCreateLabelRate";
import {
    ShippingPackageCreateLabelOrderDetails
} from "../component/ShippingPackage/ShippingPackageCreateLabelOrderDetails";
import {TopUp} from "../component/TopUp/TopUp";
import {Billing} from "../component/Billing/Billing";
import {PackageOrderDashboard} from "../component/OrderDashboard/NewDesign/PackageOrderDashboard";
import {ShippingPackageCrossBorder} from "../component/ShippingPackage/ShippingPackageCrossBorder";
import {AdjustOrderDashboard} from "../component/AdjustOrderDashboard/AdjustOrderDashboard";
import {CrossBorderDashboard} from "../component/CrossBorder/CrossBorderDashboard";
import {MyLTLList} from "../component/ShippingLTLList/MyLTLList";
import {LTLOrderDashboard} from "../component/OrderDashboard/NewDesign/LTLOrderDashboard";
import {TrackingLTL} from "../component/Tracking/TrackingLTL";
import {ShippingLTLCreateLabel} from "../component/ShippingLTL/ShippingLTLCreateLabel";
import {ShippingLTLCreateLabelRateList} from "../component/ShippingLTL/ShippingLTLCreateLabelRateList";
import {ShippingLTLCreateLabelOrderDetails} from "../component/ShippingLTL/ShippingLTLCreateLabelOrderDetails";
import {ShippingLTLCreateLabelCrossBorder} from "../component/ShippingLTL/ShippingLTLCreateLabelCrossBorder";
import {LTLCrossBorderDashboard} from "../component/CrossBorder/LTLCrossBorderDashboard";
import {PackageOrderList} from "../component/ShippingList/Package/PackageOrderList";
import {AccountSetting} from "../component/UserProfile/AccountSetting";
import {ShippingPackageImportShipment} from "../component/ShippingViaExcel/ShippingPackageImportShipment";
import {ShippingPackageInternationShipment} from "../component/ShippingPackage/ShippingPackageInternationShipment";
import {
    ShippingPackageInternationShipmentQuote
} from "../component/ShippingPackage/ShippingPackageInternationShipmentQuote";
import {
    ShippingPackageInternationShipmentOrder,
    shippingPackageInternationShipmentOrder
} from "../component/ShippingPackage/ShippingPackageInternationShipmentOrder";
import {
    ShippingPackageInternationShipmentRate
} from "../component/ShippingPackage/ShippingPackageInternationShipmentRate";
import {TransportationAvailableTasks} from "../component/Transportation/TransportationAvailableTasks";
import {TransportationClaimTasks} from "../component/Transportation/TransportationClaimTasks";
import {TransportationAvailableTasksDetails} from "../component/Transportation/TransportationAvailableTasksDetails";
import {TransportationClaimTasksDetails} from "../component/Transportation/TransportationClaimTasksDetails";
import {ReturnLabel} from "../component/ReturnLabel/ReturnLabel";
import {ReturnLabelOrderDetails} from "../component/ReturnLabel/ReturnLabelOrderDetails";
import {CloneLabel} from "../component/CloneLabel/CloneLabel";
import {CloneLabelOrderDetails} from "../component/CloneLabel/CloneLabelOrderDetails";
import {useSelector} from "react-redux";
import {useEffect} from "react";

export const Routes = () => {

    const {isPartner} = useSelector((state) => state.user);

    console.log('[Routes] isPartner', isPartner);

    // redirect to https://app.uucargo.ca

    // useEffect(() => {
    //     window.location.href = 'https://app.uucargo.ca';
    // }, [])

    useGaTracker();

    return (
        <Switch>
            <Route exact path="/">
                {Boolean(getAccessToken("access_token")) ? (
                    <Redirect to={'/home'}/>
                ) : (
                    <Redirect to="/sign-in"/>
                )}
            </Route>
            <Route exact
                   path="/landing-estimate"
            >
                <LandingEstimate/>
            </Route>
            <Route exact
                   path="/landing"
            >
                <Landing/>
            </Route>
            <PrivateRoute
                path="/brokerage/estimate"
                exact
            >
                <Brokerage/>
            </PrivateRoute>
            <PrivateRoute
                path="/brokerage/packing-list"
                exact
            >
                <BrokeragePackingList/>
            </PrivateRoute>
            <PrivateRoute
                path="/settings/my-boxes"
                exact
            >
                <MyBoxes/>
            </PrivateRoute>
            <PrivateRoute
                path="/settings/address-list"
                exact
            >
                <AddressList/>
            </PrivateRoute>
            <PrivateRoute
                path="/settings/my-staff"
                exact
            >
                <MyStaff/>
            </PrivateRoute>
            <PrivateRoute
                path="/settings/packing-list"
                exact
            >
                <PackingList/>
            </PrivateRoute>
            {/*<Route path="/sign-in" exact component={UserSignIn}/>*/}
            {/*<Route exact*/}
            {/*       path="/sign-in"*/}
            {/*>*/}
            {/*    <UserSignIn/>*/}
            {/*</Route>*/}
            <Route exact
                   path="/sign-in"
            >
                <LandingSignIn/>
            </Route>
            <Route exact
                   path="/sign-up"
            >
                <LandingSignUp/>
            </Route>
            <Route
                exact
                path="/shipping-rate-calculator"
            >
                <LandingShippingRateCalculator/>
            </Route>
            <Route
                path="/loose-item/3rd-party/shipping/upload-file"
                exact
                component={CsvFFHandler}
            />
            <PrivateRoute path={'/home'} exact>
                <TransportationAvailableTasks/>
            </PrivateRoute>
            <PrivateRoute
                path="/loose-item/3rd-party/shipping/trans/"
                exact
            >
                <ShippingListTracking/>
            </PrivateRoute>
            <PrivateRoute
                path="/loose-item/3rd-party/shipping/trans/:order_id"
                exact
            >
                <ShippingListTrackingByOrderId/>
            </PrivateRoute>
            <PrivateRoute
                path="/loose-item/3rd-party/shipping/exportsTransactions/"
                exact
            >
                <ShippingExportsTransaction/>
            </PrivateRoute>
            <PrivateRoute
                path="/loose-item/3rd-party/shipping/searchTransactions/"
                exact
            >
                <SearchShippingTransactions/>
            </PrivateRoute>
            <Route
                path="/loose-item/3rd-party/shipping/list/product/"
                exact
                component={ShippingListMultipleTrackingNumber}
            />
            <Route
                path="/loose-item/3rd-party/shipping/list/product/:tran_id"
                component={ShippingListProduct}
                exact
            />
            <PrivateRoute
                path="/loose-item/3rd-party/shipping/order/"
                exact
            >
                <ShippingOrderList/>
            </PrivateRoute>
            <PrivateRoute
                path="/loose-item/3rd-party/shipping/order/:order_id"
                exact
            >
                <ShippingOrderListByOrderId/>
            </PrivateRoute>
            <PrivateRoute
                path="/loose-item/order-dashboard/:order_id"
                exact
            >
                <OrderDashboard/>
            </PrivateRoute>
            {/*<PrivateRoute*/}
            {/*    path="/billing-center"*/}
            {/*    exact*/}
            {/*>*/}
            {/*    <BillingCenter/>*/}
            {/*</PrivateRoute>*/}
            <PrivateRoute
                path="/loose-item/3rd-party/shipping/searchorder"
                exact
            >
                <AllPartnerShippingOrderPaymentList/>
            </PrivateRoute>
            <PrivateRoute
                path="/loose-item/3rd-party/shipping/searchorder/detail/:id"
                exact
            >
                <ShippingOrderPaymentDetails/>
            </PrivateRoute>
            <Route path="/v2/test-print" component={Printer} exact/>
            <Route
                path="/freight-forwarding/bol-list/:tab"
                component={BolListPage}
                exact
            />
            <Route
                path="/freight-forwarding/hs-code"
                component={HscodePage}
                exact
            />
            <PrivateRoute path="/change-password" exact>
                <ChangePassword/>
            </PrivateRoute>
            <Route
                path="/hs-code-training"
                component={HSCodeMainPage}
                exact
            />
            <PrivateRoute
                exact
                path="/shipping/:tab"
                component={StartQuote}
            />
            <PrivateRoute
                exact
                path="/shippingLTL/:tab"
                component={StartShippingLTL}
            />
            {/*<PrivateRoute*/}
            {/*    exact*/}
            {/*    path="/LTLs/:tab"*/}
            {/*    component={StartPalletsQuote}*/}
            {/*/>*/}
            {/*<PrivateRoute*/}
            {/*    exact*/}
            {/*    path="/myLTLs/"*/}
            {/*    component={PalletsListPage}*/}
            {/*/>*/}
            {/*<PrivateRoute*/}
            {/*    exact*/}
            {/*    path="/myLTLs/:tab"*/}
            {/*    component={PalletsListPage}*/}
            {/*/>*/}
            <PrivateRoute
                exact
                path="/myLTLQuote"
                component={QuoteLTL}
            />
            <PrivateRoute
                exact
                path="/LTLQuoteDetails/:quoteId"
                component={QuoteLTLDetails}
            />
            <PrivateRoute
                exact
                path="/myLTLs/"
                // component={ShippingLTLListPage}
                component={MyLTLList}
            />
            <PrivateRoute
                exact
                path="/myLTLs/:tab"
                component={ShippingLTLListPage}
            />
            <PrivateRoute
                exact
                path="/shippingLTLDetails"
                component={ShippingLTLDetailsPage}
            />
            <PrivateRoute
                exact
                path="/myBrokerages/"
                component={ShippingBrokerageListPage}
            />
            <PrivateRoute
                exact
                path="/myBrokerages/:tab"
                component={ShippingBrokerageListPage}
            />
            <PrivateRoute
                exact
                path="/shippingBrokerageDetails"
                component={ShippingBrokerageDetails}
            />
            <PrivateRoute
                exact
                path="/ocean-shipping"
                component={OceanShippingPage}
            />
            <PrivateRoute
                exact
                path="/ocean-bookings"
                component={OceanBooking}
            />
            <PrivateRoute
                exact
                path="/original-invoices-breakdown"
                component={PartnerInvoicePage}
            />
            <PrivateRoute
                exact
                path="/commercial-invoice/:order_id"
                component={CommercialInvoicePage}
            />
            <PrivateRoute
                exact
                path="/schedulepickup"
                component={SchedulePickup}
            />
            <PrivateRoute
                exact
                path="/MyScheduledPickups"
                component={MyScheduledPickups}
            />
            <PrivateRoute
                exact
                path="/tracking"
                component={TrackPackages}
            />
            <PrivateRoute
                exact
                path="/LTL-quick-quote"
                component={ShippingLTLQuickQuote}
            />
            <PrivateRoute
                exact
                path="/shipping-package/quick-quote"
                component={ShippingPackageQuickQuote}
            />
            <PrivateRoute
                exact
                path="/shipping-package/create-label"
                component={ShippingPackageCreateLabel}
            />
            <PrivateRoute
                exact
                path="/shipping-package/create-label/rate-list"
                component={ShippingPackageCreateLabelRate}
            />
            <PrivateRoute
                exact
                path="/shipping-package/create-label/order-details"
                component={ShippingPackageCreateLabelOrderDetails}
            />
            <PrivateRoute
                exact
                path="/billing-center/top-up"
                component={TopUp}
            />
            <PrivateRoute
                exact
                path="/billing-center"
                component={Billing}
            />
            <Route exact path="/forgot-password" component={ForgotPasswordPage}/>
            <Route exact path="/reset-password" component={ResetPasswordPage}/>
            <Route exact path="/receive-email" component={ReceiveEmailPage}/>
            <PrivateRoute
                path="/package-order-dashboard/:order_id"
                exact
                component={PackageOrderDashboard}
            />
            <PrivateRoute
                path="/ltl-order-dashboard/:order_id"
                exact
                component={LTLOrderDashboard}
            />
            <PrivateRoute
                exact
                path="/shipping-package/cross-border-details"
                component={ShippingPackageCrossBorder}
            />
            <PrivateRoute
                exact
                path="/adjustOrderDashboard/:orderId"
                component={AdjustOrderDashboard}
            />
            <PrivateRoute
                exact
                path="/cross-border-dashboard/:order_id"
                component={CrossBorderDashboard}
            />
            <PrivateRoute
                exact
                path="/ltl-cross-border-dashboard/:order_id"
                component={LTLCrossBorderDashboard}
            />
            <PrivateRoute
                exact
                path="/tracking-ltl"
                component={TrackingLTL}
            />
            <PrivateRoute
                exact
                path="/LTL-create-label"
                component={ShippingLTLCreateLabel}
            />
            <PrivateRoute
                exact
                path="/LTL-create-label/rate-list"
                component={ShippingLTLCreateLabelRateList}
            />
            <PrivateRoute
                exact
                path="/LTL-create-label/order-details"
                component={ShippingLTLCreateLabelOrderDetails}
            />
            <PrivateRoute
                exact
                path="/LTL-create-label/cross-border-order-details"
                component={ShippingLTLCreateLabelCrossBorder}
            />
            <PrivateRoute
                exact
                path="/package/order-list"
                component={PackageOrderList}
            />
            <PrivateRoute
                exact
                path="/profile"
                component={AccountSetting}
            />
            <PrivateRoute path="/shipping-package/rating-buy/excel" exact>
                <ShippingViaExcel/>
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/shipping-package/import-shipment"
                component={ShippingPackageImportShipment}
            />
            <PrivateRoute
                exact
                path="/shipping-package/international-shipment"
                component={ShippingPackageInternationShipment}
            />
            <PrivateRoute
                exact
                path="/shipping-package/international-shipment/quote-list"
                component={ShippingPackageInternationShipmentQuote}
            />
            <PrivateRoute
                exact
                path="/shipping-package/international-shipment/place-order"
                component={ShippingPackageInternationShipmentOrder}
            />
            <PrivateRoute
                exact
                path="/shipping-package/international-shipment/rate-list"
                component={ShippingPackageInternationShipmentRate}
            />
            <PrivateRoute
                exact
                path="/transportation/availableTasks"
                component={TransportationAvailableTasks}
            />
            <PrivateRoute
                exact
                path="/transportation/availableTasks/details/:id"
                component={TransportationAvailableTasksDetails}
            />
            <PrivateRoute
                exact
                path="/transportation/claimTasks"
                component={TransportationClaimTasks}
            />
            <PrivateRoute
                exact
                path="/transportation/claimTasks/details/:id"
                component={TransportationClaimTasksDetails}
            />
            <PrivateRoute
                exact
                path="/packageOrder/returnLabel/:tranId"
                component={ReturnLabel}
            />
            <PrivateRoute
                exact
                path="/packageOrder/returnLabelDetails"
                component={ReturnLabelOrderDetails}
            />
            <PrivateRoute
                exact
                path="/packageOrder/cloneLabel/:tranId"
                component={CloneLabel}
            />
            <PrivateRoute
                exact
                path="/packageOrder/cloneLabelDetails"
                component={CloneLabelOrderDetails}
            />
        </Switch>
    )
}