import {Checkbox, Divider, MenuItem, Select, Snackbar, useMediaQuery} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {useEffect, useState, Fragment} from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import {internationalShipmentTypeList} from "../../shared/constInfo";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {generateRandomString} from "../../utils/Helper";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLCrossBorderDashboardInvoiceForm = ({orderData, invoiceList, addInvoiceList, closeCreateInvoice}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [date, setDate] = useState();
    const [invoiceNo, setInvoiceNo] = useState();
    const [purchaseOrder, setPurchaseOrder] = useState();
    const [terms, setTerms] = useState('DDU');
    const [reason, setReason] = useState('SALE');
    const [comments, setComments] = useState('');
    const [freightCharge, setFreightCharge] = useState(0);
    const [insurance, setInsurance] = useState(0);
    const [tax, setTax] = useState(0);
    const [shipperTaxId, setShipperTaxId] = useState('');
    const [shipToTaxId, setShipToTaxId] = useState('');
    const [soldToTaxId, setSoldToTaxId] = useState('');
    const [packingList, setPackingList] = useState([]);
    const [validation, setValidation] = useState(true);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDate = e => {
        setDate(e.target.value);
    }

    const handleInvoiceNo = e => {
        setInvoiceNo(e.target.value);
    }

    const handlePurchaseOrder = e => {
        setPurchaseOrder(e.target.value);
    }

    const handleTerms = e => {
        setTerms(e.target.value);
    }

    const handleReason = (e) => {
        setReason(e.target.value);
    }

    const handleComments = e => {
        setComments(e.target.value);
    }

    const handleFreightCharge = e => {
        const {value} = e.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setFreightCharge(value)
        }
    }

    const handleInsurance = e => {
        const {value} = e.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setInsurance(value)
        }
    }

    const handleTax = e => {
        const {value} = e.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setTax(value)
        }
    }

    const handleShipperTaxId = e => {
        setShipperTaxId(e.target.value);
    }

    const handleShipToTaxId = e => {
        setShipToTaxId(e.target.value);
    }

    const handleSoldToTaxId = e => {
        setSoldToTaxId(e.target.value);
    }

    const handleCheckPacking = (e) => {
        // console.log(e.target.value);
        const updateId = +e.target.value;

        const updatedPackingList = packingList?.map(item => {
            if (item?.packingId === updateId) {
                return {...item, isChecked: !item.isChecked};
            } else {
                return item;
            }
        });
        setPackingList(updatedPackingList);
    }

    const handleQuantity = (event, packingId) => {
        const quantity = event.target.value;
        console.log('qty', quantity);
        console.log('packing id', packingId);

        const updatedPackingList = packingList?.map(item => {
            if (item?.packingId === packingId) {
                return {...item, quantity: quantity < 1 ? '1' : event.target.value};
            } else {
                return item;
            }
        });
        setPackingList(updatedPackingList);
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        // const validateInvoice = (!date || !invoiceNo || !purchaseOrder || !terms || !reason);
        const validateItems = packingList.some(element => element.isChecked === true);

        // validateInvoice && setErrorMessage(prevState => prevState + ' Missing Required in invoice information section.');
        !validateItems && setErrorMessage(prevState => prevState + ' You have to select at least one item.');

        if (validateItems) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleCreateInvoice = () => {
        const validationResult = validate();
        setValidation(validationResult);
        if (validationResult) {
            const items = packingList.filter(element => element.isChecked === true);

            let invoiceSubtotal = 0;
            items.forEach(element => {
                invoiceSubtotal += ((+element.unitPrice) * (+element.quantity));
            })
            console.log('invoice subtotal', invoiceSubtotal);

            let invoiceTotalAmount = (+insurance) + (+tax) + (+freightCharge) + (+invoiceSubtotal);
            console.log('invoiceTotalAmount', invoiceTotalAmount);

            let totalWeight = 0;
            items.forEach(element => {
                totalWeight += ((+element.weight) * (+element.quantity));
            })

            let totalQuantity = 0;
            items.forEach(element => {
                totalQuantity += (+element.quantity);
            })

            let invoiceData = {
                orderId: orderData.id,
                items: items,
                date: date,
                billingNumber: "",
                invoiceNumber: invoiceNo,
                purchaseOrderNumber: purchaseOrder,
                termsOfSale: terms,
                reasonForExport: reason,
                currency: 'CAD',
                discount: "",
                invoiceSubTotal: invoiceSubtotal,
                freightCharges: freightCharge,
                insurance: insurance,
                tax: tax,
                comments: comments,
                invoiceTotalAmount: invoiceTotalAmount,
                totalQuantity: totalQuantity,
                totalWeight: totalWeight,
                invoiceLineTotal: "",
                shipperCompanyName: orderData?.bolInfo?.shipper_company_name,
                shipperContactName: orderData?.bolInfo?.shipper_name,
                shipperTaxId: shipperTaxId,
                shipperCompanyAddress: orderData?.bolInfo?.shipper_address,
                shipperCompanyCity: orderData?.bolInfo?.shipper_city,
                shipperCompanyProvince: orderData?.bolInfo?.shipper_province,
                shipperCompanyPostalCode: orderData?.bolInfo?.shipper_postal_code,
                shipperCompanyCountry: orderData?.bolInfo?.shipper_country,
                shipperCompanyPhone: orderData?.bolInfo?.shipper_phone,
                shipperCompanyEmail: orderData?.bolInfo?.shipper_email,
                shipToTaxId: shipToTaxId,
                shipToContactName: orderData?.bolInfo?.consignee_name,
                shipToCompanyName: orderData?.bolInfo?.consignee_company_name,
                shipToCompanyAddress: orderData?.bolInfo?.consignee_address,
                shipToCompanyCity: orderData?.bolInfo?.consignee_city,
                shipToCompanyProvince: orderData?.bolInfo?.consignee_province,
                shipToCompanyPostalCode: orderData?.bolInfo?.consignee_postal_code,
                shipToCompanyCountry: orderData?.bolInfo?.consignee_country,
                shipToCompanyPhone: orderData?.bolInfo?.consignee_phone,
                shipToCompanyEmail: orderData?.bolInfo?.consignee_email,
                soldToTaxId: soldToTaxId,
                soldToContactName: orderData?.bolInfo?.consignee_name,
                soldToCompanyName: orderData?.bolInfo?.consignee_company_name,
                soldToCompanyAddress: orderData?.bolInfo?.consignee_address,
                soldToCompanyCity: orderData?.bolInfo?.consignee_city,
                soldToCompanyProvince: orderData?.bolInfo?.consignee_province,
                soldToCompanyPostalCode: orderData?.bolInfo?.consignee_postal_code,
                soldToCompanyCountry: orderData?.bolInfo?.consignee_country,
                soldToCompanyPhone: orderData?.bolInfo?.consignee_phone,
                soldToCompanyEmail: orderData?.bolInfo?.consignee_email,
                invoiceId: generateRandomString(8),
            }
            addInvoiceList(invoiceData);
            closeCreateInvoice();
        }
    }

    console.log('order data', orderData);

    useEffect(() => {
        const updatedPackingList = [];
        orderData?.packingList?.forEach(element => {
            let object = {
                packingId: element?.id,
                isChecked: true,
                description: element?.item_name,
                unitPrice: element?.unit_price,
                quantity: element?.quantity,
                UOM: element?.uom,
                weight: element?.weight,
                hsCode: element?.hs_code,
                originalCountry: element?.origi_country,
                currency: element?.currency_code,
                weightUnit: element?.weight_unit,
            }
            updatedPackingList.push(object);
        })
        setPackingList(updatedPackingList);
    }, [orderData?.packingList])

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                    Invoice Information
                </Typography>
                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                    When exporting items for commercial purposes, please ensure to include the following
                    details: invoice date, invoice number, purchase order number, and terms of sale.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={date}
                        type='date'
                        label="Date"
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        // required
                        fullWidth
                        variant="standard"
                        onInput={handleDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        // error={!validation && !date}
                        // helperText={
                        //     (!validation && !date) && "Missing or Invalid"
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={invoiceNo}
                        label="Invoice Number"
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        // required
                        fullWidth
                        variant="standard"
                        onInput={handleInvoiceNo}
                        // error={!validation && !invoiceNo}
                        // helperText={
                        //     (!validation && !invoiceNo) && "Missing or Invalid"
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={purchaseOrder}
                        label="Purchase Order No."
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        // required
                        fullWidth
                        variant="standard"
                        onInput={handlePurchaseOrder}
                        // error={!validation && !purchaseOrder}
                        // helperText={
                        //     (!validation && !purchaseOrder) && "Missing or Invalid"
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={terms}
                        label="Terms of Sale (Incoterm)"
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        // required
                        fullWidth
                        variant="standard"
                        onInput={handleTerms}
                        // error={!validation && !terms}
                        // helperText={
                        //     (!validation && !terms) && "Missing or Invalid"
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth variant="standard">
                    <InputLabel>Reason for Export</InputLabel>
                    <Select
                        value={reason}
                        size='small'
                        style={{fontSize: 14}} // font size of input text
                        onChange={handleReason}
                    >
                        {
                            internationalShipmentTypeList?.map(v => {
                                return <MenuItem value={v}>{v}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={comments}
                        label="Additional Comments"
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        variant="standard"
                        onInput={handleComments}
                        // error={!validation && itemDetail?.weight <= 0}
                        // helperText={
                        //     (!validation && itemDetail?.weight <= 0) && "Missing or Invalid"
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={freightCharge}
                        label="Freight Charge"
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        type="number"
                        variant="standard"
                        onInput={handleFreightCharge}
                        // error={!validation && itemDetail?.weight <= 0}
                        // helperText={
                        //     (!validation && itemDetail?.weight <= 0) && "Missing or Invalid"
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={insurance}
                        label="Insurance"
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        variant="standard"
                        onInput={handleInsurance}
                        // error={!validation && itemDetail?.weight <= 0}
                        // helperText={
                        //     (!validation && itemDetail?.weight <= 0) && "Missing or Invalid"
                        // }
                        type="number"
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={tax}
                        label="Tax"
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        variant="standard"
                        type="number"
                        onInput={handleTax}
                        // error={!validation && itemDetail?.weight <= 0}
                        // helperText={
                        //     (!validation && itemDetail?.weight <= 0) && "Missing or Invalid"
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={shipperTaxId}
                        label="Shipper Tax Id"
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        variant="standard"
                        onInput={handleShipperTaxId}
                        // error={!validation && itemDetail?.weight <= 0}
                        // helperText={
                        //     (!validation && itemDetail?.weight <= 0) && "Missing or Invalid"
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={shipToTaxId}
                        label="Ship To Tax Id"
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        variant="standard"
                        onInput={handleShipToTaxId}
                        // error={!validation && itemDetail?.weight <= 0}
                        // helperText={
                        //     (!validation && itemDetail?.weight <= 0) && "Missing or Invalid"
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <TextField
                        value={soldToTaxId}
                        label="Sold To Tax Id"
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        fullWidth
                        variant="standard"
                        onInput={handleSoldToTaxId}
                        // error={!validation && itemDetail?.weight <= 0}
                        // helperText={
                        //     (!validation && itemDetail?.weight <= 0) && "Missing or Invalid"
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                    Customs Items
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item md={1} xs={2}>
            </Grid>
            <Grid item md={3} xs={8}>
                <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                    Item Name
                </Typography>
            </Grid>
            {
                !isMobile && <>
                    <Grid item xs={2}>
                        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                            HS Code
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                            Unit Weight
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                            Unit Price
                        </Typography>
                    </Grid>
                </>
            }
            <Grid item xs={2}>
                <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                    Quantity
                </Typography>
            </Grid>
            {
                packingList?.map((element, index) => (
                        <Fragment key={index}>
                            <Grid item md={1} xs={2}>
                                <Checkbox
                                    checked={element?.isChecked}
                                    onChange={handleCheckPacking}
                                    value={element?.packingId}
                                />
                            </Grid>
                            <Grid item md={3} xs={8}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                    <Typography style={{fontSize: '14px'}}>
                                        {element?.description}
                                    </Typography>
                                </Box>
                            </Grid>
                            {
                                !isMobile &&
                                <>
                                    <Grid item xs={2}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%'
                                        }}>
                                            <Typography style={{fontSize: '14px'}}>
                                                {element?.hsCode}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%'
                                        }}>
                                            <Typography style={{fontSize: '14px'}}>
                                                {element?.weight} {element?.weightUnit}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%'
                                        }}>
                                            <Typography style={{fontSize: '14px'}}>
                                                $ {element?.unitPrice.toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        value={element?.quantity}
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        size='small'
                                        disabled={!element?.isChecked}
                                        onChange={(event) => handleQuantity(event, element?.packingId)}
                                        // error={!validation && itemDetail?.weight <= 0}
                                        // helperText={
                                        //     (!validation && itemDetail?.weight <= 0) && "Missing or Invalid"
                                        // }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                        </Fragment>
                    )
                )
            }
            <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '10px'}}>
                    <Button
                        onClick={handleCreateInvoice}
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        // size='small'
                    >
                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                            Create
                        </Typography>
                    </Button>
                    <Button
                        onClick={closeCreateInvoice}
                        variant='contained'
                        sx={{
                            backgroundColor: '#F2BE22',
                            "&:hover": {
                                backgroundColor: '#F2BE22',
                                filter: 'brightness(0.9)'
                            }
                        }}
                    >
                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                            Close
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}