import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import qrCodeImage from "../../images/uucargo-paypal-qrcode.png";
import wechatPay from "../../images/wechatPay.svg";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";

const styles = {
    TopUpPayPalRoot: {
        border: '1px solid #000000',
        display: 'flex',
        borderRadius: '10px',
        padding: '20px 0'
    }
}

export const TopUpPayPal = ({crmInvoiceInfo, crmInvoiceNumber, coupon}) => {

    return (
        <Box sx={styles.TopUpPayPalRoot}>
            <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                    <Box sx={{padding: '5px 15px'}}>
                        <img src={qrCodeImage} alt="qrCodeLogo" width={170}/>
                    </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src='https://storage.googleapis.com/uucargo-resource/image/icon/paypal.svg'
                                 alt="paypalLogo" width={35}/>
                            <Typography style={{fontSize: '14px'}}>
                                {intl.get('BILLING_CENTER.PAYMENT.PAY_PAL.PAY_PAL')}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                {intl.getHTML('BILLING_CENTER.PAYMENT.PAY_PAL.STEP_1_TOP_UP')}
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                {intl.getHTML('BILLING_CENTER.PAYMENT.PAY_PAL.STEP_2_TOP_UP')}
                            </Typography>
                        </Box>
                        {/*<Grid container spacing={0}>*/}
                        {/*    <Grid item xs={6}>*/}
                        {/*        <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                        {/*            Total Amount:*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={6}>*/}
                        {/*        <Typography style={{fontSize: '14px', textAlign: 'left'}}>*/}
                        {/*            /!*$ {amount.toFixed(2)}*!/*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={6}>*/}
                        {/*        <Typography style={{fontSize: '12px', textAlign: 'left'}}>*/}
                        {/*            Receiver:*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={6}>*/}
                        {/*        <Typography style={{fontSize: '12px', textAlign: 'left'}}>*/}
                        {/*            UU CARGO LTD.*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={6}>*/}
                        {/*        <Typography style={{fontSize: '12px', textAlign: 'left'}}>*/}
                        {/*            Order Id:*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={6}>*/}
                        {/*        <Typography style={{fontSize: '12px', textAlign: 'left'}}>*/}
                        {/*            {crmInvoiceNumber}*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={6}>*/}
                        {/*        <Typography style={{fontSize: '12px', textAlign: 'left'}}>*/}
                        {/*            Order Number:*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={6}>*/}
                        {/*        <Typography style={{fontSize: '12px', textAlign: 'left'}}>*/}
                        {/*            {crmInvoiceInfo?.invoiceRefNumber}*/}
                        {/*        </Typography>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}