import React, {useEffect, useState} from "react";
import {Check, Cancel} from "@material-ui/icons";
import {
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
  Typography,
  Tooltip,
  IconButton,
  Select,
  TextField,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText, DialogActions, CircularProgress
} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import {Accordion, AccordionDetails, AccordionSummary, MenuItem} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import Upload from "@mui/icons-material/Upload";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";
import getProvinceCode, {getUSProvinceCodeByName} from "../../../utils/getProvinceCode";
import axios from "axios";
import {PARTNER_URI} from "../../../utils/apiUrl";
import {getAccessToken} from "../../../utils/doToken";
import {Alert} from "@material-ui/lab";
import {useSelector} from "react-redux";
import {getTransportationCode} from "../../../shared/constInfo";

const splitString = (value) => {
  const parts = value.split("_");
  return parts[0];
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
  HSCodetextfield: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    // paddingTop: "0.4vw",
    // paddingBottom: "0.4vw",
  },
  accordion: {
    width: "100%"
  },
  accordionSummaryRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  menuPaper: {
    maxWidth: 150,
  },
  input: {
    color: "grey",
    width: "90%",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
  smSubTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Montserrat",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  warningText: {
    color: "red",
    fontWeight: "bold",
  },
}));
const ShippingLTLCrossBorderTable = ({ serviceName,
                                       pickUpDetails,
                                       message,
                                       totalBrokerageFees,
                                       shipmentItems,
                                       currency,
                                       surcharges,
                                       totalDutyandTax,
                                       shippingItems
}) => {
  const classes = useStyles();
  const [pickupLoading, setPickupLoading] = useState(false);
  const id = useSelector((state) => state.user.id);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [isPlaceOrderSuccess, setIsPlaceOrderSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };
  const getServiceIconByAccountBaseAndService = (serviceName) => {
    const width = "220"
    const height= "120"
    switch (serviceName?.toLowerCase()) {
      case "ups":
        return  <img src="https://storage.googleapis.com/uucargo-resource/image/icon/uucargo.png" alt={serviceName} width={width} height={height} />;
    }
  }
  const confirmPlaceOrder = async (e) => {
    e.preventDefault();
    const requestBody = {
      partnerId: id,
      pickupAddressType: pickUpDetails.pickUp.service,
      pickupAddress: pickUpDetails.pickUp.address,
      pickupCity: pickUpDetails.pickUp.city,
      pickupProvince: pickUpDetails.pickUp.country === "US" ? getUSProvinceCodeByName(pickUpDetails.pickUp.province) : getProvinceCode(pickUpDetails.pickUp.province),
      pickupCountry: pickUpDetails.pickUp.country,
      pickupPostalCode: pickUpDetails.pickUp.zipCode.replace(/\s*/g, ""),
      pickupPhone: pickUpDetails.pickUp.phone,
      senderName: pickUpDetails.pickUp.name,
      deliverAddress: pickUpDetails.deliver.address,
      deliverCity: pickUpDetails.deliver.city,
      deliverProvince: pickUpDetails.deliver.country === "US" ? getUSProvinceCodeByName(pickUpDetails.deliver.province) : getProvinceCode(pickUpDetails.deliver.province),
      deliverCountry: pickUpDetails.deliver.country,
      deliverPostalCode: pickUpDetails.deliver.zipCode.replace(/\s*/g, ""),
      deliverName: pickUpDetails.deliver.name,
      deliverPhone: pickUpDetails.deliver.phone,
      deliverAddressType: pickUpDetails.deliver.service,
      schedulePickupDate: pickUpDetails.schedulePickupDate ? pickUpDetails.schedulePickupDate?.toDate() : null,
      shipmentItems: shippingItems.map(v => {
        return {
          length: v.length,
          height: v.height,
          width: v.width,
          quantity: v.looseUnit,
          weight: v.weight,
          description: v?.description,
          lengthUnit: v.lengthUnit,
          weightUnit: v.weightUnit,
          hscode: v.hscode,
          originCountryCode: v.originCountryCode,
          currency: v.currency,
          priceEach: v.priceEach,
          UOM: v.UOM
        }
      }),
      shipperName: pickUpDetails.pickUp.name,
      shipperPhone: pickUpDetails.pickUp.phone,
      shipperCompanyName: pickUpDetails.pickUp?.companyName || "",
      shipperEmail: pickUpDetails.pickUp?.email || "",
      shipperAddress: pickUpDetails.pickUp.address,
      shipperAddressType: pickUpDetails.pickUp.service,
      shipperCity: pickUpDetails.pickUp.city,
      shipperProvince: pickUpDetails.pickUp.country === "US" ? getUSProvinceCodeByName(pickUpDetails.pickUp.province) : getProvinceCode(pickUpDetails.pickUp.province),
      shipperPostalCode: pickUpDetails.pickUp.zipCode.replace(/\s*/g, ""),
      shipperCountry: pickUpDetails.pickUp.country,

      consigneeName: pickUpDetails.deliver.name,
      consigneePhone: pickUpDetails.deliver.phone,
      consigneeCompanyName: pickUpDetails.deliver?.companyName || "",
      consigneeEmail: pickUpDetails.deliver?.email || "",
      consigneeAddress: pickUpDetails.deliver.address,
      consigneeAddressType: pickUpDetails.deliver.service,
      consigneeCity: pickUpDetails.deliver.city,
      consigneeProvince: pickUpDetails.deliver.country === "US" ? getUSProvinceCodeByName(pickUpDetails.deliver.province) : getProvinceCode(pickUpDetails.deliver.province),
      consigneePostalCode: pickUpDetails.deliver.zipCode.replace(/\s*/g, ""),
      consigneeCountry: pickUpDetails.deliver.country,

      serviceName: serviceName,
      shipDate: dayjs(pickUpDetails.shipDate).format("YYYY-MM-DD"),
      pickupDate: dayjs(pickUpDetails.pickupDate).format("YYYY-MM-DD"),
      transModes: getTransportationCode(pickUpDetails.transMode),
      shipmentType: pickUpDetails.shipmentType,
      pickupInstruction: "",

    }
    console.log(requestBody);
    try {
      setPlaceOrderLoading(true);
      const { data } = await axios.post(`${PARTNER_URI}/brokerage/placeLandedCostBackendOrder`, requestBody, {
        headers: {
          Authorization: `Bearer ${getAccessToken("access_token")}`
        }
      });
      console.log(data);
      setErrorMessage("");
      handleToastClick();
      // handleConfirmDialogClose();
      setIsPlaceOrderSuccess(true);
      // Go Back to home page after 2 second
      // setTimeout(() => {
      //   history.push("/home");
      //
      // }, 2000);
    } catch (e) {
      setErrorMessage(e?.response?.data?.errorMessage || "Error!");
      console.log(e);
    } finally {
      setPlaceOrderLoading(false);
    }
  }

  return (
      <>
        {/*Pop-up Toast*/}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={toastOpen}
            onClose={handleToastClose}
            autoHideDuration={3000}
            message="Cancel Order">
          {(() => {
            if (errorMessage !== "") {
              return (
                  <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                    Send Request Failed!
                    <hr />
                    Error: {errorMessage}
                  </Alert>
              )
            }
            return (
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                  Send Request Success!
                </Alert>
            )
          })()}

        </Snackbar>
        {/*Pop-up Toast*/}

        {/*Confirm Place Order Dialog*/}
        <Dialog
            open={confirmDialogOpen}
            onClose={handleConfirmDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Send Request
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {
                isPlaceOrderSuccess ? (
                    <>
                      Thank you! We already received your request, one of our operator would contact you soon.
                    </>
                ) : (
                    <>
                      This process will place your Cross Border request. Are you sure to continue?
                      <hr />
                      Click <b>Confirm</b> to send a request
                    </>
                )
              }
              {/*This process will place your LTL Shipping Order. Are you sure to continue?*/}
              {/*<hr />*/}
              {/*Click <b>Confirm</b> to place an order*/}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {
              isPlaceOrderSuccess ? (
                  <>
                    <Button onClick={handleConfirmDialogClose} variant="outlined" type="button" >Got It</Button>
                    <Button onClick={() => {
                      handleConfirmDialogClose();
                      history.push("/home");
                    }
                    } variant="outlined" autoFocus type="button" >GO TO HOME PAGE</Button>
                  </>
              ) : (
                  <>
                    <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button" >No</Button>
                    <Button onClick={confirmPlaceOrder} variant="outlined" type="button" disabled={placeOrderLoading} >
                      {placeOrderLoading && <CircularProgress size={14} />}
                      {!placeOrderLoading && "Confirm"}
                    </Button>
                  </>
              )
            }
            {/*<Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button" >No</Button>*/}
            {/*<Button onClick={confirmPlaceOrder} variant="outlined" type="button" disabled={placeOrderLoading} >*/}
            {/*  {placeOrderLoading && <CircularProgress size={14} />}*/}
            {/*  {!placeOrderLoading && "Confirm"}*/}
            {/*</Button>*/}
          </DialogActions>
        </Dialog>
        {/*Confirm Place Order Dialog*/}
        <TableBody>
          <TableRow>
            <TableCell align="center" width="20%">
              <Box display="flex" flexDirection="column">
              <span>
                {getServiceIconByAccountBaseAndService(serviceName)}
              </span>
                {/*<span style={{ fontWeight: "bold", fontSize: "20px" }}>{serviceName}</span>*/}
              </Box>
            </TableCell>
            <TableCell align="right" width="50%">
              {
                totalBrokerageFees || totalDutyandTax ? <b style={{ fontSize: "25px" }}><CurrencyFormat value={(totalBrokerageFees + totalDutyandTax)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b> : "N/A"
              }
              <br />
              <Accordion className={classes.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.accordionSummaryRight}
                    classes={{ content: classes.accordionSummaryRight }}
                >
                  <Typography sx={{ textAlign: "right" }}>
                    Price Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Box>
                      <Typography>
                        <Accordion>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon/>}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className={classes.accordionSummaryRight}
                              classes={{ content: classes.accordionSummaryRight }}
                          >
                            Duty and Tax: &nbsp;
                            {
                              totalDutyandTax ? <b><CurrencyFormat value={(totalDutyandTax)} displayType={'text'} thousandSeparator={true} prefix={`${currency} $`} /></b> : "N/A"
                            }
                          </AccordionSummary>
                          <AccordionDetails sx={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            textAlign: "right"
                          }}>
                            <Typography sx={{ textAlign: "right" }}>
                              {
                                shipmentItems?.map(shipmentItem => {
                                  return (
                                      <>
                                        <ul style={{ "textAlign": "right",  }}>
                                          <li style={{ "textAlign": "right", display: "flex", justifyContent: "flex-end", padding: 0, marginTop: "0.2rem" }}>
                                            <span>Item Name: <b>{splitString(shipmentItem?.commodityId)}</b></span>
                                          </li>
                                          <li style={{ "textAlign": "right", display: "flex", justifyContent: "flex-end", padding: 0, marginTop: "0.2rem" }}>
                                            <span>Total Commodity Taxes And Fees: <b><CurrencyFormat value={shipmentItem?.totalCommodityTaxesAndFees} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b></span>
                                          </li>
                                          <li style={{ "textAlign": "right", display: "flex", justifyContent: "flex-end", padding: 0, marginTop: "0.2rem" }}>
                                            <span>Commodity Duty: <b><CurrencyFormat value={shipmentItem?.commodityDuty} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b></span>
                                          </li>
                                        </ul>
                                        <br />
                                      </>
                                  )
                                })
                              }

                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Typography>
                      <Typography>
                        <Accordion>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon/>}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className={classes.accordionSummaryRight}
                              classes={{ content: classes.accordionSummaryRight }}
                          >
                            Brokerage: &nbsp;
                            {
                              totalBrokerageFees ? <b><CurrencyFormat value={(totalBrokerageFees)} displayType={'text'} thousandSeparator={true} prefix={`${currency} $`} /></b> : "N/A"
                            }
                          </AccordionSummary>
                          <AccordionDetails sx={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            textAlign: "right"
                          }}>
                            <Typography sx={{ textAlign: "right" }}>
                              <ul style={{ "textAlign": "right",  }}>
                                {
                                  surcharges?.map(surcharge => {
                                    return (
                                        <li style={{ "textAlign": "right", display: "flex", justifyContent: "flex-end", padding: 0, marginTop: "0.2rem" }}>
                                        <span>{surcharge.chargeName}: &nbsp;
                                          <b>
                                            {
                                              totalBrokerageFees ? <b><CurrencyFormat value={(surcharge.chargeAmount)} displayType={'text'} thousandSeparator={true} prefix={`${currency} $`} /></b> : "N/A"
                                            }
                                          </b>
                                        </span>
                                        </li>
                                    )
                                  })
                                }
                              </ul>

                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Typography>
                    </Box>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </TableCell>
            <TableCell align="right">
              <LoadingButton
                  variant="contained"
                  component="span"
                  color="primary"
                  style={{
                    width: "150px"
                  }}
                  onClick={() => {
                    handleConfirmDialogOpen();
                  }}
                  loading={pickupLoading}
                  disabled={message && message !== ""}
              >
                Send Request
              </LoadingButton>
            </TableCell>
          </TableRow>
        </TableBody>
        <Box>
          {message && message !== "" ? `${message}` : ""}
        </Box>
      </>
  )
}

export default ShippingLTLCrossBorderTable;
