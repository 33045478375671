import {actionType} from "../../utils/Helper";

const initialState = {
    tabValue: 'one',
    addAddress: false,
    editAddress: false,
    addressInfo: null
}

export const AddressListReducer = (state = initialState, action) => {
    // console.log('reducer', action);
    switch (action.type) {
        case actionType.ADDRESS_LIST_SELECTED_TAB:
            return {...state, tabValue: action?.payload};
        case actionType.ADDRESS_LIST_ADD:
            return {...state, addAddress: action?.payload};
        case actionType.ADDRESS_LIST_EDIT:
            return {...state, editAddress: action?.payload};
        case actionType.ADDRESS_LIST_PASS_ADDRESS_INFO:
            return {...state, addressInfo: action?.payload};
        default:
            return state;
    }
}