import React, {useEffect} from "react";
import {Box, Button, FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { format } from "date-fns";

import { ordinal_suffix_of } from "../../../utils/addOrdinalSuffix";
// import { shipmentTypesDetails } from "../../shared/constInfo";
import {
  ADDRESS_TYPE_FBA,
  ADDRESS_TYPE_RESIDENTIAL,
  ADDRESS_TYPE_WAREHOUSE,
  shipmentTypesDetails
} from "../../../shared/constInfo";

//getPickUpEmptyStatus(pickUpDetails)

const findUnit = (labelId, fieldName) => {
  const itemUnit = shipmentTypesDetails[labelId];
  return itemUnit ? itemUnit[fieldName] : "";
};

const getPackagingTypeName = (packageTypeId) => {
  switch (packageTypeId) {
    case "2":
      return `48" x 48"`;
    case "3":
      return "Oversize";
    case "4":
      return "Overweight";
    default:
      return `48" x 40"`;
  }
}

const getStatusText = (status) => {
  switch (status) {
    case 0:
      return "Initial";
    case 1:
      return "Processed";
    case 100:
      return "Processing";
    case -1:
      return "Cancelled"
    default:
      return ""
  }
}

const ReviewOrderHeader = ({
  pickupEmpty,
  deliverEmpty,
  toFirst,
  toFourth,
  pickUpDetails,
  setPickupDetails,
  pallets,
  requestStatus,
  edit,
  isAcceptQuote
}) => {
  const classes = useStyles();

  useEffect(() => {

  }, [])

  return (
    <>
      <div className="subtitle">
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.summaryHeader} fontWeight="700">Shipper</Box>
          <Box fontWeight="400" className="bodytext" visibility={requestStatus === -1 ? "hidden" : ""}>
            <Button
              color="primary"
              onClick={toFourth}
              style={{ color: "seagreen" }}
              disabled={requestStatus === -1}
            >
              Edit
            </Button>
          </Box>
        </Box>

        <Box className={classes.summaryBodyText} mb={2}>
          Name: {pickUpDetails.pickUp.name}
          <br />
          Phone number: {pickUpDetails.pickUp.phone}
          <br />
          {`${pickUpDetails.pickUp.address}`}, {pickUpDetails.pickUp?.aptInfo} <br />
          {`${pickUpDetails.pickUp.city}, ${pickUpDetails.pickUp.province} ${pickUpDetails.pickUp.zipCode}`}{" "}
          <br />
          {/*Requested for {format(pickUpDetails.selectedDate, "dd/MM/yyyy")}*/}
        </Box>
      </div>
      <div className="subtitle">
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.summaryHeader} fontWeight="700">Consignee</Box>
          <Box fontWeight="400" className="bodytext" visibility={requestStatus === -1 ? "hidden" : ""}>
            <Button
                color="primary"
                onClick={toFourth}
                style={{ color: "seagreen" }}
                disabled={requestStatus === -1}
            >
              Edit
            </Button>
          </Box>
        </Box>

        <Box className={classes.summaryBodyText} mb={2}>
          Name: {pickUpDetails.deliver.name}
          <br />
          Phone number: {pickUpDetails.deliver.phone}
          <br />
          {`${pickUpDetails.deliver.address}`}, {pickUpDetails.deliver?.aptInfo} <br />
          {`${pickUpDetails.deliver.city}, ${pickUpDetails.deliver.province} ${pickUpDetails.deliver.zipCode}`}{" "}
          <br />
          {/*Requested for {format(pickUpDetails.selectedDate, "dd/MM/yyyy")}*/}
        </Box>
      </div>
      <div className="bodytext"></div>
      {
        requestStatus !== 0 && (
            <>
              <Box className={classes.summaryHeader} mt={2} fontWeight="700">
                Status: {getStatusText(requestStatus)}
              </Box>
              <br />
              </>
              )
      }
      <br />
      <div className="subtitle">
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.summaryHeader}>Items</Box>
          <Box fontWeight="400" className="bodytext" visibility={requestStatus === -1 || isAcceptQuote ? "hidden" : ""}>
            <Button
              color="primary"
              onClick={toFirst}
              style={{ color: "seagreen" }}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <Box className={classes.summaryBodyText} mb={1}>
          {pallets.map((item, key) => {
            return (
              <div key={key}>
                <p>
                  <Box component="span" fontWeight="700">
                    {ordinal_suffix_of(key + 1)} Package:
                  </Box>{" "}
                  <Box component="span" fontWeight="400">
                    {/*{item.description} - {item.quantity}{" "}*/}
                    {/*{findUnit(item.shipmentType, "label")}(s) &times;{" "}*/}
                    {/*{item.weight} {findUnit(item.shipmentType, "weightUnit")}*/}
                    {/*{item.description} - {item.length}&times;{item.width}&times;{item.height} ({item.lengthUnit}) - {item.weight} ({item.weightUnit}) &times; {item.looseUnit} - <b>{getPackagingTypeName(item.packagingType)}</b>*/}
                    {item.description} - {item.length}&times;{item.width}&times;{item.height} ({item.lengthUnit}) - {item.weight} ({item.weightUnit})
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>&times; {item.looseUnit}</b>
                  </Box>
                </p>
              </div>
            );
          })}
        </Box>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  summaryBodyText: {
    fontSize: "1.1rem",
    color: "rgba(0,0,0,0.8)",
    fontFamily: "Montserrat",
    fontWeight: "400",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
}));

ReviewOrderHeader.propTypes = {
  pickupEmpty: PropTypes.bool.isRequired,
  toFirst: PropTypes.func.isRequired,
  pickUpDetails: PropTypes.object.isRequired,
  toFourth: PropTypes.func.isRequired,
  pallets: PropTypes.array.isRequired,
};

export default ReviewOrderHeader;
