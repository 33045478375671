import {configureStore} from "@reduxjs/toolkit";
import searchShipmentReducer from "./shipment/searchShipmentSlice";
import updateShipmentInfo from "./shipment/updateShipmentSlice";
import HSCodeList from "./HSCode/HSCodeListSlice";
import {userReducer} from "./userSlice";
import {ordersReducer} from "../slices/ordersSlice";
import {oceanShippingReducer} from "../slices/oceanShippingSlice";
import {AddressListReducer} from "../component/Reducers/AddressListReducer";
import {landingEstimateReducer} from "../component/Reducers/landingEstimateReducer";
import {landingPageReducer} from "../component/Reducers/landingPageReducer";
import {crossBorderInvoiceReducer} from "../component/Reducers/crossBorderInvoiceReducer";
import {shippingLTLReducer} from "../component/Reducers/shippingLTLReducer";
import {shippingPackageReducer} from "../component/Reducers/shippingPackageReducer";
import {trackingPackageReducer} from "../component/Reducers/trackingPackageReducer";
import {commercialInvoiceReducer} from "../component/Reducers/commercialInvoiceReducer";

export default configureStore({
    reducer: {
        shipmentSearchPreference: searchShipmentReducer,
        updateShipmentInfo: updateShipmentInfo,
        HSCodeList: HSCodeList,
        user: userReducer,
        orders: ordersReducer,
        oceanShipping: oceanShippingReducer,
        addressList: AddressListReducer,
        landingEstimate: landingEstimateReducer,
        landingPage: landingPageReducer,
        crossBorderInvoice: crossBorderInvoiceReducer,
        shippingLTL: shippingLTLReducer,
        shippingPackage: shippingPackageReducer,
        trackingPackage: trackingPackageReducer,
        commercialInvoice: commercialInvoiceReducer
    },
});
