import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useHistory, Link} from "react-router-dom";
import {
    Button,
    Box,
    Divider,
    Container,
    CircularProgress,
    Modal,
    Typography,
    IconButton,
    Snackbar, InputAdornment,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import "../../styles.css";
import axios from "axios";
import {
    deleteToken,
    getAccessToken,
    setTokeninCookie,
} from "../../utils/doToken";
import {
    MIDDLEWARE_LOGIN_API,
    MIDDLEWARE_MICROSOFT_LOGIN_API,
} from "../../utils/apiUrl";
import {updateUserInfo} from "../../redux/userSlice";
import CustomInput from "../../shared/customInput";
import {verifyIsPartner} from "../../utils/verifyIsPartner";
import {deleteUserInfo} from "../../slices/userSlice";
import {updateLandingClickJoinUs, updateLandingClickSignIn} from "../Actions/landingEstimateAction";
import TextField from "@mui/material/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useMediaQuery} from "@mui/material";
import intl from "react-intl-universal";

const useStyles = makeStyles((theme) => ({
    input: {
        color: "grey",
        borderRadius: "25px",
        textAlign: "center",
        width: "100%",
    },
    button: {
        borderRadius: 20,
        color: "white",
    },
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    forgetPassword: {
        cursor: "pointer",
        "&:hover": {
            "text-decoration": "underline",
        },
    },
    modalBox: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    },
    LoginButton: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
    NoAccount: {
        display: 'flex',
        padding: '20px',
        gap: '5px',
        justifyContent: 'center'
    },
    LoginHeading: {
        fontSize: '30px',
        fontWeight: '600',
        color: 'green',
        textAlign: 'left',
        '@media (max-width: 480px)': {
            fontSize: '25px',
        },
    }
}));

const styles = {
    root: {
        display: 'flex',
        marginTop: '20px',
        '@media (max-width: 1024px)': {
            flexDirection: 'column', // for tablets and above
        },
    },
    signUpRoot: {
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: '500px',
        // width: '500px',
        width: '50%',
        margin: '60px auto',
        gap: '10px',
        borderRadius: '5px',
        '@media (max-width: 1024px)': {
            width: '100%', // for tablets and above
        },
        '@media (max-width: 480px)': {
            width: '100%', // for phone and above
            margin: '0 auto',
        },
    },
    signUpRootContent: {
        padding: '30px',
    },
    signUpRootContentEmail: {
        display: 'flex',
        flexDirection: 'column',
        gap: '50px'
    },

}

const UserSignIn = ({driver = false, showNotValidPartner = false}) => {
    const [confirmUser, setConfirmUser] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validLength, setValidLength] = useState(false);

    // Modal Box
    const [openForgetPassword, setOpenForgetPassword] = useState(false);
    const handleOpenForgetPassword = () => setOpenForgetPassword(true);
    const handleCloseForgetPassword = () => setOpenForgetPassword(false);

    const [openSignup, setOpenSignup] = useState(false);
    const handleOpenSignup = () => setOpenSignup(true);
    const handleCloseSignup = () => setOpenSignup(false);

    const location = useLocation();
    const dispatch = useDispatch();

    // const [signInResponse, setSignInResponse] = useState(0);
    const [signInErrorMessage, setSignInErrorMessage] = useState("");
    const [signInFail, setSignInFail] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [recaptchaValue, setRecaptchaValue] = useState(undefined);

    const [showPassword, setShowPassword] = useState(false);

    const isMobile = useMediaQuery('(max-width: 480px)');

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const history = useHistory();

    const classes = useStyles();

    const signOut = (e) => {
        const storedToken = getAccessToken("access_token");
        try {
            axios.get(`https://admin.uucargo.ca/api/logout`, {
                headers: {Authorization: `Bearer ${storedToken}`},
            });
            //setHide(true)
        } finally {
            deleteToken("access_token");
            dispatch(deleteUserInfo());
            dispatch(updateUserInfo({field: "login", value: false}));
            dispatch(updateUserInfo({field: "signIn", value: false}));
        }
    };

    const [toastOpen, setToastOpen] = useState(showNotValidPartner);
    const handleToastClose = () => setToastOpen(false);

    useEffect(() => {
        if (getAccessToken("access_token")) {
            history.push("/home");
        }
    }, []);

    //useEffect handler which makes sure the userData state is first updated then sends the data to the MongoDB :D! FIXED
    useEffect(() => {
        if (confirmUser === true) {
            setConfirmUser(false);
        }
        if (signInErrorMessage.length > 2) {
            setSignInFail(true);
        }
    }, [signInFail]);

    useEffect(() => {
        if (showNotValidPartner) {
            signOut();
        }
    }, []);

    const checkPasswordLength = (password) => {
        if (password.length >= 6) {
            setValidLength(true);
        } else {
            setValidLength(false);
        }
    };

    const handleEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value.trim());
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        setPassword(e.target.value.trim());
        checkPasswordLength(e.target.value.trim());
    };

    /*Function that sets the login object to the inputted e-mail and password
          login object is passed in to a POST redux action where the data is delivered to the express server
          then, the response from the express server is received and the user either logs in or it produces an error
      */
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSignInErrorMessage("");
        const loginInfo = {
            email: email.trim(),
            password: password.trim(),
            type: "Email",
            driver,
        };

        await login(loginInfo);

        // axios({
        //     method: "post",
        //     url: MIDDLEWARE_LOGIN_API,
        //     data: JSON.stringify(loginInfo),
        //     headers: {
        //         "Content-Type": "application/json",
        //         "X-Requested-With": "XMLHttpRequest",
        //     },
        // })
        //     .then(async (response) => {
        //         setTokeninCookie("access_token", response.data.access_token, 30);
        //         setSignInFail(false);
        //         checkUserRole(response.data?.user?.role);
        //         const isPartner = await verifyIsPartner();
        //         console.log("Is Partner: ", isPartner);
        //         dispatch(
        //             updateUserInfo({field: "isPartner", value: isPartner || false})
        //         );
        //         setLoading(false);
        //         history.push("/home");
        //     })
        //     .catch((error) => {
        //         setSignInErrorMessage(error.response?.data?.message || "");
        //         setSignInFail(true);
        //         setLoading(false);
        //         return;
        //     });
    };

    const login = async (data) => {

        try {

            const result = await axios({
                method: 'post',
                url: MIDDLEWARE_LOGIN_API,
                data: data
            })

            console.log('result', result);
            setTokeninCookie("access_token", result.data.access_token, 30);
            setSignInFail(false);
            const isPartner = await verifyIsPartner();
            dispatch(updateUserInfo({field: "isPartner", value: isPartner || false}));
            dispatch(updateUserInfo({field: "role", value: result.data?.user?.role}));
            if (isPartner) {
                dispatch(updateUserInfo({field: "signIn", value: true}));
                history.push("/home");
            } else {
                dispatch(updateUserInfo({field: "signIn", value: false}));
                deleteToken("access_token");
                setSignInFail(true);
                setLoading(false);
                setSignInErrorMessage("The login has failed because you used an end-user account instead of a partner account.");
            }
        } catch (e) {
            console.log(e.response);
            setSignInErrorMessage(e.response?.data?.message || "");
            setSignInFail(true);
            setLoading(false);
        }
    }

    const responseSocial =
        (type = "google") =>
            (response) => {
                let email, token;
                // {profileObj:{email}, tokenId: token} = response;
                if (type === "google") {
                    if (!response.profileObj.email) {
                        setSignInErrorMessage(
                            "The email address is invalid, please login by other means"
                        );
                        return;
                    }
                    email = response.profileObj.email;
                    token = response.tokenId;
                }
                if (type === "facebook" || type === "microsoft") {
                    if (!response.email) {
                        setSignInErrorMessage(
                            "The email address is invalid, please login by other means"
                        );
                        return;
                    }
                    email = response.email;
                    token = response.accessToken;
                }

                setLoading(true);
                setSignInErrorMessage("");

                const loginInfo = {
                    email: email,
                    token,
                    type,
                    driver,
                };

                axios({
                    method: "post",
                    url:
                        type === "microsoft"
                            ? MIDDLEWARE_MICROSOFT_LOGIN_API
                            : MIDDLEWARE_LOGIN_API,
                    // url: `${
                    //   driver ? BACKEND_DRIVER_LOGIN_API : BACKEND_LOGIN_API
                    // }?source=${type}`,
                    data: JSON.stringify(loginInfo),
                    headers:
                        type === "microsoft"
                            ? {
                                Authorization: `Bearer ${token}`,
                                "Content-Type": "application/json",
                                "X-Requested-With": "XMLHttpRequest",
                            }
                            : {
                                "Content-Type": "application/json",
                                "X-Requested-With": "XMLHttpRequest",
                            },
                })
                    .then(async (response) => {
                        setTokeninCookie("access_token", response.data.access_token, 30);
                        setSignInFail(false);
                        // dispatch(updateUserInfo({ field: "login", value: true }));
                        checkUserRole(response.data?.user?.role);
                        const isPartner = await verifyIsPartner();
                        console.log("Is Partner: ", isPartner);
                        dispatch(updateUserInfo({field: "isPartner", value: isPartner || false}));
                        setLoading(false);
                    })
                    .catch((error) => {
                        // console.log(error.response.data.message);
                        setSignInErrorMessage(error?.response?.data);
                        setLoading(false);
                    });
            };

    const checkUserRole = (role) => {
        dispatch(updateUserInfo({field: "role", value: role}));
        dispatch(updateUserInfo({field: "signIn", value: true}));
        if (role === "End User") {
            if (location.state) history.replace(location.state.from.pathname);
            else history.replace("/");
        } else {
            //should be handling who does not have both roles
            if (location.state) history.replace(location.state.from.pathname);
            history.replace("/");
        }
    };

    let isClickSignIn = useSelector(state => state?.landingEstimate?.landingIsClickSignIn);

    console.log('isClick Sign In', isClickSignIn);

    const signInSection = useRef(null);

    const scrollDown = (ref) => {
        // console.log(ref.current.offsetTop)
        window.scrollTo({
            top: ref.current.offsetTop - 50,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        isClickSignIn && scrollDown(signInSection)

        return () => {
            dispatch(updateLandingClickSignIn(false));
        }
    }, [isClickSignIn])

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Login Error"
            >
                <Alert
                    onClose={handleToastClose}
                    severity="error"
                    sx={{width: "100%"}}
                >
                    Sorry, Your login status has been expired, or this account is not a partner account
                    <hr/>
                    Please login again or contact{" "}
                    <span>
            <a href="mailto:op@uucargo.ca">op@uucargo.ca</a>
          </span>{" "}
                    to apply for a UUCargo Partner
                </Alert>
            </Snackbar>
            {/*Forget Password Modal*/}
            <Modal
                open={openForgetPassword}
                onClose={handleCloseForgetPassword}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 1000,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="start"
                        alignItems="center"
                    >
                        <h2>Forget Password?</h2>
                        <h3>
                            Please contact{" "}
                            <span>
                <a href="mailto:ticket@uucargo.ca">ticket@uucargo.ca</a>
              </span>{" "}
                            for requesting reset password
                        </h3>
                    </Box>
                </Box>
            </Modal>
            {/*Sign Up Modal*/}
            {/*Forget Password Modal*/}
            <Modal
                open={openSignup}
                onClose={handleCloseSignup}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 1000,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="start"
                        alignItems="center"
                    >
                        {/*<h2>Sign Up</h2>*/}
                        <Box style={{textAlign: "center", fontSize: "20px"}}>
                            {/*Please contact{" "}*/}
                            {/*<span>*/}
                            {/*  <a href="mailto:op@uucargo.ca">op@uucargo.ca</a>*/}
                            {/*</span>{" "}*/}
                            {/*to apply for a UUCargo Partner*/}
                            Get lower shipping rates?
                            <br/>
                            <br/>
                            <span>
                <a href="https://uucargo.ca/support/become-a-partner/" target="_blank" rel="noopener noreferrer">Become a Partner</a>
              </span>{" "}
                        </Box>
                    </Box>
                </Box>
            </Modal>
            {/*<Box sx={{*/}
            {/*    display: 'flex',*/}
            {/*    flexDirection: 'column',*/}
            {/*    alignItems: 'center',*/}
            {/*}}>*/}
            {/*    <Typography style={{*/}
            {/*        color: "green",*/}
            {/*        fontFamily: "Montserrat",*/}
            {/*        fontSize: "40px",*/}
            {/*        letterSpacing: "2px",*/}
            {/*        fontWeight: '600',*/}
            {/*    }}>*/}
            {/*        Welcome to UUCargo Partner Portal*/}
            {/*    </Typography>*/}
            {/*    <Box sx={{*/}
            {/*        display: 'flex',*/}
            {/*        gap: '10px'*/}
            {/*    }}>*/}
            {/*        <BusinessIcon/>*/}
            {/*        <Typography>*/}
            {/*            Free estimate shipping costs*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            <Box sx={styles.signUpRoot} ref={signInSection}>
                <Box sx={styles.signUpRootContent}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', margin: '10px 0 40px'}}>
                        <Typography style={{
                            fontSize: isMobile ? '25px' : '40px',
                            fontWeight: '600',
                            color: 'green',
                            textAlign: 'left'
                        }}>
                            {intl.get('LANDING_PAGE.SIGN_IN.WELCOME')}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            {intl.get('LANDING_PAGE.SIGN_IN.TEXT')}
                        </Typography>
                    </Box>
                    <Box sx={styles.signUpRootContentEmail}>
                        <TextField
                            value={email}
                            onChange={(e) => handleEmail(e)}
                            // label='Email'
                            placeholder={intl.get('LANDING_PAGE.SIGN_IN.EMAIL')}
                            required
                            variant='standard'
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonOutlineIcon/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            value={password}
                            onChange={(e) => handlePasswordChange(e)}
                            // label='Email'
                            placeholder={intl.get('LANDING_PAGE.SIGN_IN.PASSWORD')}
                            type={showPassword ? 'text' : 'password'}
                            required
                            variant='standard'
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockOutlinedIcon/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/*<Typography style={{fontSize: '25px', color: 'green', fontWeight: '600'}}>E-mail</Typography>*/}
                        {/*<CustomInput*/}
                        {/*    onChange={(e) => handleEmail(e)}*/}
                        {/*    placeholderValue="Email"*/}
                        {/*    value={email}*/}
                        {/*/>*/}
                        {/*<Typography style={{fontSize: '25px', color: 'green', fontWeight: '600'}}>Password</Typography>*/}
                        {/*<CustomInput*/}
                        {/*    onChange={(e) => handlePasswordChange(e)}*/}
                        {/*    placeholderValue="Enter Password"*/}
                        {/*    value={password}*/}
                        {/*    type="password"*/}
                        {/*/>*/}
                        <Box fontSize="14px" textAlign="left" color='green'>
                            <Box
                                onClick={() => {
                                    // handleOpenForgetPassword();
                                    history.push('/forgot-password')
                                }}
                                className={classes.forgetPassword}
                            >
                                {intl.get('LANDING_PAGE.SIGN_IN.FORGOT_PASSWORD')}
                            </Box>
                        </Box>
                        <Button
                            disabled={loading}
                            variant="contained"
                            onClick={handleSubmit}
                            // className={classes.LoginButton}
                        >
                            {loading ? <CircularProgress size={24}/> : intl.get('LANDING_PAGE.SIGN_IN.SIGN_IN')}
                        </Button>
                        {signInErrorMessage ? (
                            <Alert severity="error" classes={{root: classes.alertRoot}}>
                                {signInErrorMessage}
                            </Alert>
                        ) : (
                            ""
                        )}
                    </Box>
                    {/*<Box style={{textAlign: "center"}}>*/}
                    {/*    <h3>OR</h3>*/}
                    {/*</Box>*/}
                    {/*<Box>*/}
                    {/*    <SocialLogin*/}
                    {/*        responseSocial={responseSocial}*/}
                    {/*    />*/}
                    {/*</Box>*/}
                    {/*<Divider style={{marginTop: '20px'}}/>*/}
                    <Box className={classes.NoAccount}>
                        <Typography
                            style={{fontSize: '14px'}}
                        >
                            {intl.get('LANDING_PAGE.SIGN_IN.NOT_HAVE_ACCOUNT')}
                        </Typography>
                        <Typography
                            style={{fontSize: '14px', color: 'green', cursor: 'pointer', fontWeight: '600'}}
                            onClick={() => {
                                // handleOpenSignup();
                                history.push('/sign-up')
                                // dispatch(updateLandingClickJoinUs(true))
                            }}
                        >
                            {intl.get('LANDING_PAGE.SIGN_IN.SIGN_UP')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default UserSignIn;
