import {MyBoxesList} from "./MyBoxesList";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {MyBoxesForm} from "./MyBoxesForm";
import {Container} from "@mui/material";

export const MyBoxes = () => {

    return (
        <Container maxWidth='xl'>
            <Typography variant="h4">
                My Boxes List
            </Typography>
            <MyBoxesForm/>
            <MyBoxesList/>
        </Container>
    )
}