import Box from "@mui/material/Box";
import {Checkbox, Divider, FormControlLabel} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import {getAccessToken} from "../../../utils/doToken";
import axios from "axios";
import {PARTNER_URI} from "../../../utils/apiUrl";
import {useEffect, useState, Fragment} from "react";
import {useSelector} from "react-redux";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import intl from "react-intl-universal";
import dayjs from "dayjs";
import {mapCarrierLogo} from "../../../utils/Helper";
import {PackageOrderDashboardTransactionEach} from "./PackageOrderDashboardTransactionEach";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

const style = {
    PackageOrderDashboardTransactionRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const PackageOrderDashboardTransaction = ({
                                                     orderId,
                                                     cbHandleSelectedTransactionData,
                                                     cbHandleTransactionList,
                                                     transactions
                                                 }) => {

    const {firstname, id} = useSelector((state) => state.user);
    const [transactionList, setTransactionList] = useState([]);
    const [orderData, setOrderData] = useState({});
    const [invoiceRef, setInvoiceRef] = useState("N/A");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTranId, setSelectedTranId] = useState(null);
    const [selectedTranData, setSelectedTranData] = useState(null);

    const getCRMInvoiceInfoById = async ({invoiceId}) => {
        try {
            const storedToken = getAccessToken("access_token");
            const {data} = await axios.get(`${PARTNER_URI}/billingCenter/getInvoiceInfoByInvoiceId?invoiceId=${invoiceId}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    const getTransactionListByOrderId = async () => {
        try {
            setIsLoading(true);
            const requestURI = `${PARTNER_URI}/shipping/list?&order_id=${orderId}&partner_id=${id}`;
            const {data} = await axios.get(requestURI);
            console.log('get transaction data', data);
            const result = data?.result;
            setTransactionList(result);
            cbHandleTransactionList(result);
            if (result?.length === 1) {
                setSelectedTranId(result[0]?.tran_id);
                setSelectedTranData(result[0]);
                cbHandleSelectedTransactionData(result[0]);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const getOrderData = async ({orderId}) => {
        try {
            setIsLoading(true);
            const requestURI = `${PARTNER_URI}/shipping/list/order?partner_id=${id}&order_id=${orderId}`;
            const {data} = await axios.get(requestURI);
            if (data?.result && data?.result?.length > 0) {
                const resOrderData = data.result[0];
                // console.log("orderData: ", resOrderData);
                // const isCrossBorder = resOrderData?.cross_border_shipment_type !== null;
                // setIsCrossBorder(isCrossBorder);
                setOrderData(resOrderData);

                const crmInvoiceNumber = resOrderData.crm_invoice_no;
                if (crmInvoiceNumber) {
                    const crmInfo = await getCRMInvoiceInfoById({
                        invoiceId: crmInvoiceNumber
                    });
                    const resCRMRefNumber = crmInfo.invoiceRefNumber;
                    setInvoiceRef(resCRMRefNumber);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const handleSelectTransaction = (transactionData) => {
        setSelectedTranId(transactionData?.tran_id);
        setSelectedTranData(transactionData);
        cbHandleSelectedTransactionData(transactionData);
    }

    useEffect(() => {
        getOrderData({orderId});
        getTransactionListByOrderId();
    }, [orderId]);

    console.log('order data', orderData);
    console.log('transaction list', transactionList)

    return (

        <Box sx={style.PackageOrderDashboardTransactionRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_TRANSACTIONS')}
                        </Typography>
                        {
                            +selectedTranData?.is_return_label === 1 && <Box sx={{display: 'flex'}}>
                                <RotateLeftIcon sx={{color: '#1D8B45'}}/>
                                <Typography sx={{color: '#1D8B45'}}>Return Label</Typography>
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    height: '100%',
                                    gap: '5px',
                                    flexDirection: 'column'
                                }}>
                                    <Typography><span
                                        style={{fontWeight: '600'}}>{intl.get('ORDER_DASHBOARD.PROCEED_DATE')}</span>
                                    </Typography>
                                    <Typography>
                                        {dayjs(orderData?.created_at)?.format('YYYY-MM-DD')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    {/*<Typography><span*/}
                                    {/*    style={{fontWeight: '600'}}>Carrier: </span>*/}
                                    {/*</Typography>*/}
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}>
                                        <img src={mapCarrierLogo(orderData?.account_base?.toUpperCase())}
                                             alt="logo"
                                             width='40px'/>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            {intl.get('ORDER_DASHBOARD.SHIPPING_FROM')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.send_from_name}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.send_from_addr}, {orderData?.send_from_city}, {orderData?.send_from_province}, {orderData?.send_from_postal_code}, {orderData?.send_from_country}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {orderData?.send_from_tel}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                    Transaction Id
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                    Shipping To
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography style={{textAlign: 'center', fontWeight: '600'}}>
                                    Charge (Include Tax)
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            {
                                transactionList?.map((transaction, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={3}>
                                            <FormControlLabel
                                                value="start"
                                                control={<Checkbox
                                                    checked={transaction?.tran_id === selectedTranId}
                                                    onChange={() => handleSelectTransaction(transaction)}
                                                    // disabled={confirm}
                                                    icon={<RadioButtonUncheckedIcon/>}
                                                    checkedIcon={<CheckCircleIcon/>}
                                                    color="success"
                                                />}
                                                label={
                                                    <Typography>
                                                        {transaction?.tran_id}
                                                    </Typography>
                                                }
                                                labelPlacement="end"
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {transaction?.send_to_name}
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {transaction?.send_to_addr}, {transaction?.send_to_city}, {transaction?.send_to_province}, {transaction?.send_to_postal_code}, {transaction?.send_to_country}
                                            </Typography>
                                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                {transaction?.send_to_tel}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography style={{textAlign: 'center', fontSize: '14px'}}>
                                                $ {(+transaction?.final_total).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {
                                            transaction?.tran_id === selectedTranId &&
                                            <Grid item xs={12}>
                                                <PackageOrderDashboardTransactionEach
                                                    selectedTranData={selectedTranData}/>
                                            </Grid>
                                        }
                                    </Fragment>
                                ))
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}