import React, {useRef, useState} from 'react';
import { Box, Button, TextField, useMediaQuery, FormControl, FormLabel, RadioGroup,FormControlLabel, Radio } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import { PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX } from "../../utils/apiUrl";
import { useSelector } from "react-redux";
import NavBar from "../navBar";
import OceanBookingListTable from "./OceanBookingListTable";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateRangeCalendar from "../OceanShipping/Calendar/DateRangeCalendar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
    whiteSpace: "nowrap"
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  container: {
    overflowX: "auto"
  }
}));

const OceanBooking = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const pageSize = 100;

  const partnerInfo = useSelector((state) => state.user);

  const [startPortList, setStartPortList] = useState([]);
  const [endPortList, setEndPortList] = useState([]);

  // const { register, handleSubmit } = useForm();
  const [startPort, setStartPort] = useState("");
  const [endPort, setEndPort] = useState("");
  const [bookingListData, setBookingListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const cacheInput = useRef({});

  const getData = async ({ startPort, endPort }) => {
    const { data } = await axios.post(`${PARTNER_URI}/ocean-freight/oceanShipBooking`, {
      startPortName: startPort,
      endPortName: endPort,
      partnerId: partnerInfo.id
    });
    const result = data.result;
    setBookingListData(result);
    // const resultTotalNumber = data.totalNumber;
    // setTotalNumber(resultTotalNumber);
    // setShippingListData(result);
    try {
    } catch (e) {
      // setTotalNumber(0);
      console.log(e);
      setBookingListData([]);
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    // const { ref_order_no, cargo_control_no, account_base, order_id } = data;
    // cacheInput.current = data;
    setPage(1);
    getData({ startPort, endPort });
  }
  // const handlePageChange = (e, value) => {
  //   if (value !== page) {
  //     console.log(value);
  //     setPage(value);
  //     const { order_id, product_id , ref_order_no, account_base, cargo_control_no } = cacheInput.current;
  //     getData({ order_id, product_id, ref_order_no, account_base, currentPage: value, cargo_control_no });
  //   }
  // }

  const getPortSearchResult = async (portName) => {
    try {
      const { data } = await axios.get(`${PARTNER_URI}/elasticsearch/oceanPorts?query_string=${portName}`);
      const result = [];
      for (const hit of data?.hits) {
        const source = hit._source;
        const portData = {
          label: `${source?.port_en_name} | ${source?.port_code}`,
          value: source?.port_en_name
        }
        result.push(portData);
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  }

  return (
      <>

        <Box className={`${classes.container} oceanPage`}>
          <Box component="form" width={"1000px"} margin="0 auto" sx={{
            display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem"
          }}>
            <h1>Ocean Bookings</h1>
            <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" flexDirection="column" sx={{ gap: "2rem" }} width="100%">
              <Box width="100%">
                <Autocomplete
                    disablePortal
                    options={startPortList}
                    fullWidth
                    filterOptions={(options, state) => options}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => {
                      setStartPort(value.value);
                    }}
                    onInputChange={async (e, value) => {
                      setStartPort("");
                      const result = await getPortSearchResult(value);
                      console.log(result);
                      setStartPortList(result);
                    }}
                    renderInput={(params) => <TextField id="outlined-basic" label="Start Port&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e.g. Shanghai | 上海" variant="outlined" fullWidth {...params} required />}
                />
              </Box>
              <Box width="100%">
                <Autocomplete
                    disablePortal
                    options={endPortList}
                    filterOptions={(options, state) => options}
                    fullWidth
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => {
                      console.log("End Port", value);
                      setEndPort(value.value);
                    }}
                    onInputChange={async (e, value) => {
                      setEndPort("");
                      const result = await getPortSearchResult(value);
                      setEndPortList(result);
                    }}
                    renderInput={(params) => (
                        <TextField id="outlined-basic" label="End Port&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e.g. Singapore | 新加坡" variant="outlined" fullWidth {...params} required />
                    )
                }
                />
              </Box>
              {/*<Box width="100%" className={classes.calendarContainer}>*/}
              {/*  <ServerRequestDatePicker maxDate={MAX_DATE} value={date} setValue={setDate} isLoading={isHighLightDatesLoading} setIsLoading={isHighLightDatesLoading} highLightDays={highLightDates} onMonthChange={handleMonthChange} />*/}
              {/*</Box>*/}
            </Box>
            <Box>
              <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  style={{
                    textTransform: "unset",
                    fontSize: "1rem",
                    width: "7rem",
                  }}
                  type="submit"
                  onClick={onSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>

          <br />
          {
            isLoading ? (
                <>
                  <Box>
                    <h1>Loading...</h1>
                  </Box>
                </>
            ) : (
                <>
                  <Box>
                    <h2>Found {totalNumber} results</h2>
                    <hr width={matches ? "100%" : "1rem"} />
                    <Box>
                      <Box mt={2}>
                        <table className={classes.table}>
                          <thead>
                          <tr>
                            <th className={classes.idTH}>Booking ID</th>
                            <th className={classes.nameTH}>Start Port &nbsp;&nbsp;&nbsp;--> &nbsp;&nbsp;&nbsp;End Port</th>
                            <th className={classes.nameTH}>Containers</th>
                            <th className={classes.nameTH}>Ship Company & Route Code</th>
                            <th className={classes.nameTH}>ETD / ETA</th>
                            <th className={classes.nameTH}>Voyage</th>
                            <th className={classes.nameTH}>Created Date</th>
                          </tr>
                          </thead>
                          <tbody className={classes.tbody}>
                          {bookingListData.map((v, index) => {
                            return <OceanBookingListTable
                                key={index}
                                bookingId={v.booking_id}
                                startPortName={v.start_port_en_name}
                                startPortCode={v.start_port_code}
                                endPortName={v.end_port_en_name}
                                endPortCode={v.end_port_code}
                                shipCompany={v.ship_company}
                                routeCode={v.ship_company_route_code}
                                etd={v.etd_date}
                                eta={v.eta_date}
                                voyage={v.voyage}
                                twentyGPPrice={v.unit_price_of_container_20gp}
                                twentyGPCount={v.unit_of_container_20gp}
                                fortyGPPrice={v.unit_price_of_container_40gp}
                                fortyGPCount={v.unit_of_container_40gp}
                                fortyHQPrice={v.unit_price_of_container_40hq}
                                forthHQCount={v.unit_of_container_40hq}
                                surcharges={v.surcharges_text}
                                createdDate={v.created_at}
                            />;
                          })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                    <br />
                    <Box margin="auto" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                      <Pagination count={Math.floor(totalNumber / pageSize) + 1}
                                  page={page}
                                  onChange={() => {}}
                                  className={classes.pagination}
                                  variant="outlined"
                                  shape="rounded" />
                    </Box>
                  </Box>
                </>
            )
          }
        </Box>
      </>
  );
};

export default OceanBooking;
