import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {InputAdornment, MenuItem, Select} from "@mui/material";
import {useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {BillingInvoicesList} from "./BillingInvoicesList";
import intl from "react-intl-universal";

const styles = {
    BillingInvoicesRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    BillingInvoicesContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    BillingInvoicesTitle: {
        fontWeight: '600',
        textAlign: 'left'
    }
}

export const BillingInvoices = () => {

    const [status, setStatus] = useState('status');
    const [invoiceNumber, setInvoiceNumber] = useState(null);

    const handleChange = (event) => {
        console.log(event.target.value);
        setStatus(event.target.value);
    };

    const handleInvoiceNumber = (event) => {
        if (event.target.value) {
            setInvoiceNumber(event.target.value);
        } else {
            setInvoiceNumber(null)
        }
    }

    console.log('invoice number', invoiceNumber);

    return (
        <Box sx={styles.BillingInvoicesRoot}>
            <Box sx={styles.BillingInvoicesContent}>
                <Typography style={styles.BillingInvoicesTitle}>
                    {intl.get('BILLING_CENTER.INVOICES_DETAILS')}
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <TextField
                        value={invoiceNumber}
                        fullWidth
                        size='small'
                        onInput={handleInvoiceNumber}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                        placeholder={intl.get('BILLING_CENTER.INVOICE_NO')}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                        <Select
                            value={status}
                            size='small'
                            placeholder='Status'
                            onChange={handleChange}
                        >
                            <MenuItem disabled value='status' sx={{
                                fontSize: '14px',    // Adjust the desired text size
                            }}>
                                <em>{intl.get('BILLING_CENTER.STATUS')}</em>
                            </MenuItem>
                            <MenuItem value='all' sx={{
                                fontSize: '14px',    // Adjust the desired text size
                            }}>{intl.get('BILLING_CENTER.ALL')}</MenuItem>
                            <MenuItem value='paid' sx={{
                                fontSize: '14px',    // Adjust the desired text size
                            }}>{intl.get('BILLING_CENTER.PAID')}</MenuItem>
                            <MenuItem value='unpaid' sx={{
                                fontSize: '14px',    // Adjust the desired text size
                            }}>{intl.get('BILLING_CENTER.UNPAID')}</MenuItem>
                            <MenuItem value='topup' sx={{
                                fontSize: '14px',    // Adjust the desired text size
                            }}>{intl.get('BILLING_CENTER.TOP_UP')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {/*<Grid item md={4} xs={12}>*/}
                {/*    <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                {/*        <DatePicker*/}
                {/*            value={selectDate}*/}
                {/*            onChange={handleDateChange}*/}
                {/*            renderInput={(props) =>*/}
                {/*                <TextField*/}
                {/*                    {...props}*/}
                {/*                    fullWidth*/}
                {/*                    size='small'*/}
                {/*                />}*/}
                {/*        />*/}
                {/*    </LocalizationProvider>*/}
                {/*</Grid>*/}
            </Grid>
            <BillingInvoicesList invoiceNumber={invoiceNumber} status={status}/>
        </Box>
    )
}