import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Fragment, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {Divider, Snackbar} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {clearCommercialInvoiceList, removeCommercialInvoiceList} from "../Actions/commercialInvoiceAction";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CommercialInvoiceFormList = ({invoiceList, handleAddInvoiceOpen}) => {

    const dispatch = useDispatch();

    let invoiceListRedux = useSelector(state => state?.commercialInvoice?.commercialInvoiceList);

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleRemove = (invoice) => {
        dispatch(removeCommercialInvoiceList(invoice));
    }

    const createCommercialInvoice = async () => {

        setLoading(true);
        let requestURL = `${PARTNER_URI}/commercialInvoice/generateCommercialInvoiceAndUpload/ups`;

        let data = {
            invoices: invoiceListRedux
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            });

            console.log('create invoice', result?.data);
            setErrorMessage('');
            setSuccessMessage('Successfully create invoices');
            handleSuccessMessage();
            setLoading(false);
            // dispatch(clearCommercialInvoiceList());
            setTimeout(() => {
                window.location.reload();
            }, 2000)

        } catch (e) {
            console.log('e', e.response);
            setErrorMessage('Fail to create invoices');
            handleErrorMessage();
            setLoading(false);
        }
    }

    const handleCreateInvoice = () => {
        createCommercialInvoice();
    }

    // console.log('invoice list', invoiceList);
    // console.log('invoice list redux', invoiceListRedux)
    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                        Invoice List
                    </Typography>
                    <Button
                        onClick={() => handleAddInvoiceOpen()}
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        size='small'
                    >
                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                            Add New Invoice
                        </Typography>
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Typography style={{fontWeight: '600'}}>
                    Invoice Number
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography style={{fontWeight: '600'}}>
                    Tracking Number
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography style={{fontWeight: '600'}}>
                    Amount
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography style={{fontWeight: '600'}}>
                    Item Quantity
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography style={{fontWeight: '600'}}>
                    Item Weight
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography style={{fontWeight: '600'}}>
                    Action
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                invoiceListRedux?.map((element, index) => (
                    <Fragment key={index}>
                        <Grid item xs={2}>
                            <Typography>
                                {element?.invoiceNumber}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {
                                element?.trackingNumbers?.map((each, index) => (
                                    <Typography key={index}>
                                        {each}
                                    </Typography>
                                ))
                            }
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>
                                $ {element?.invoiceTotalAmount.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>
                                {element?.totalQuantity}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>
                                {element?.totalWeight} {element?.items[0]?.weightUnit}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#C70039',
                                    "&:hover": {
                                        backgroundColor: '#C70039',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                size='small'
                                onClick={() => handleRemove(element)}
                            >
                                <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                    Delete
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </Fragment>
                ))
            }
            <Grid item xs={12}>
                <LoadingButton
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    loading={loading}
                    onClick={handleCreateInvoice}
                >
                    <Typography style={{textTransform: 'none', fontSize: '18px'}}>
                        Create and Upload
                    </Typography>
                </LoadingButton>
            </Grid>
        </Grid>
    )
}