import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@material-ui/core";
import Button from "@mui/material/Button";
import {
    duplicateShippingPackageQuickQuotePackageList,
    removeShippingPackageQuickQuotePackageList,
    updateShippingPackageQuickQuotePackageList
} from "../Actions/shippingPackageAction";
import {generateRandomString} from "../../utils/Helper";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    MenuItem,
    Select,
    Tooltip, tooltipClasses,
    useMediaQuery
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import {getCountryCode} from "../../utils/getCountryCode";
import {UOMList} from "../../shared/constInfo";
import axios from "axios";
import {HSCODE_SEARCH, PARTNER_URI} from "../../utils/apiUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import remove from "../../images/Delete.png";
import duplicate from "../../images/Duplicate.png";
import intl from "react-intl-universal";
import {styled} from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';

const styles = {
    ShippingPackageQuickQuotePackageEachSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
    },
    ShippingPackageQuickQuotePackageEachSectionRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageQuickQuotePackageEachWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

const GreenTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const ShippingPackageQuickQuotePackageEach = ({
                                                         packageInfo,
                                                         cbHandleOpen,
                                                         cbHandleUpdatePackage,
                                                         updatedPackage,
                                                         isGetRate,
                                                         index
                                                     }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');

    const classes = useStyles();

    let shipFrom = useSelector(state => state?.shippingPackage?.quickQuoteShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.quickQuoteShipTo);

    const dispatch = useDispatch();

    const metric = useSelector(state => state?.shippingPackage?.quickQuoteUnit);

    const [packageDetail, setPackageDetail] = useState(packageInfo);
    const [HSCodeList, setHSCodeList] = useState([]);
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [commonBoxesList, setCommonBoxesList] = useState([]);

    const toggleOpen = () => {
        setAccordionOpen(!accordionOpen);
    }

    const getCommonBoxesList = async () => {
        let requestURL = `${PARTNER_URI}/myBoxes/commonBoxes`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL
            })
            console.log('result', result);
            setCommonBoxesList(result?.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const searchHSCode = async (value) => {

        try {
            const {data} = await axios.get(`${HSCODE_SEARCH}?query_string=${value}`);
            console.log('data', data);
            const hits = data.hits;
            const levelSixRecords = hits.filter(hit => {
                return hit["_source"].level === "6";
            })
            const filterResult = levelSixRecords?.map(record => {
                return record["_source"]
            })
            setHSCodeList(filterResult);

        } catch (e) {
            console.log(e);
        }
    }

    const handleSelect = (event) => {
        const type = event.target.value;
        if (type === 'myBox' || type === 'customBox') {
            setPackageDetail(prevState => ({
                ...prevState,
                packageInfoType: event.target.value
            }));
        } else {
            const find = commonBoxesList?.find((item) => item?.box_name?.replace(/\s+/g, '') === type);
            console.log('find', find);
            setPackageDetail(prevState => ({
                ...prevState,
                description: find?.item_name,
                UOM: find?.uom,
                currency: find?.currency_code,
                height: find?.height,
                length: find?.length,
                width: find?.width,
                lengthUnit: find?.dimension_unit,
                originCountryCode: find?.origi_country,
                weight: find?.weight,
                weightUnit: find?.weight_unit,
                hscode: find?.hs_code,
                priceEach: find?.unit_price,
                packageInfoType: type
            }))
        }

        if (event.target.value === 'myBox') {
            cbHandleOpen();
            cbHandleUpdatePackage(packageDetail);
        }
    };

    const handleMenuItemClick = (event) => {
        // console.log('menu click', event.target.getAttribute('data-value'));
        if (event.target.getAttribute('data-value') === 'myBox') {
            cbHandleOpen();
            cbHandleUpdatePackage(packageDetail);
        }
    }

    const handleDescription = event => {
        if (event.target.value) {
            setPackageDetail(prevState => ({
                ...prevState,
                description: event.target.value
            }))
        }
    }

    const handleLength = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                length: value
            }))
        }
    }

    const handleWidth = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                width: value
            }))
        }
    }

    const handleHeight = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                height: value
            }))
        }
    }

    const handleWeight = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setPackageDetail(prevState => ({
                ...prevState,
                weight: value
            }))
        }
    }

    const handleQuantity = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            quantity: event.target.value < 1 ? '1' : event.target.value
        }))
    }

    const handleInsurance = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            packageSpecialServices: {
                ...prevState.packageSpecialServices,
                declaredValue: {
                    value: event.target.value,
                    currency: 'CAD'
                }
            }
        }))
    }

    const handlePrice = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            priceEach: event.target.value < 1 ? '1' : event.target.value
        }))
    }

    const handleCurrency = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            currency: event.target.value
        }))
    }

    const handleCountryCode = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            originCountryCode: event.target.value
        }))
    }

    const handleUOM = event => {
        setPackageDetail(prevState => ({
            ...prevState,
            UOM: event.target.value
        }))
    }

    const handleOnInputChangeHSCode = async (event, value) => {
        await searchHSCode(value);
        setPackageDetail(prevState => ({
            ...prevState,
            hscode: value
        }))
    }

    const handleRemove = () => {
        dispatch(removeShippingPackageQuickQuotePackageList(packageDetail));
    }

    const handleDuplicate = () => {
        const packageId = generateRandomString(8);
        const duplicatedPackage = {...packageDetail};
        duplicatedPackage.packageId = packageId;
        duplicatedPackage.description = `${duplicatedPackage.description}-copy`;
        dispatch(duplicateShippingPackageQuickQuotePackageList(duplicatedPackage));
    }

    useEffect(() => {
        setPackageDetail(prevState => ({
            ...prevState,
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb'
        }))
    }, [metric]);

    useEffect(() => {
        setPackageDetail(packageInfo);
    }, [packageInfo?.packageId]);

    useEffect(() => {
        dispatch(updateShippingPackageQuickQuotePackageList(packageDetail));
    }, [packageDetail])

    useEffect(() => {
        if (packageDetail?.packageId === updatedPackage?.packageId) {
            setPackageDetail(updatedPackage)
        }
    }, [updatedPackage]);

    useEffect(() => {

        if (shipFrom && shipTo) {
            if (shipFrom?.country?.code === shipTo?.country?.code) {
                setAccordionOpen(false);
            } else {
                setAccordionOpen(true);
            }
        }
    }, [shipFrom, shipTo])

    useEffect(() => {
        getCommonBoxesList();
    }, [])

    // console.log('package details', packageDetail);
    // console.log('updated package', updatedPackage);
    //
    // console.log('battery details', batteryDetails);

    // console.log('hs code list', HSCodeList);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px'
        }}>
            <Box>
                <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                    # {index + 1}
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item md={3} xs={12}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required sx={{fontSize: '14px'}}>
                            {intl.get('ADD_PACKAGE.INFO')}
                        </InputLabel>
                        <Select
                            disabled={isGetRate}
                            value={packageDetail?.packageInfoType}
                            fullWidth
                            onChange={handleSelect}
                            size='small'
                            MenuProps={{
                                variant: 'menu',
                                MenuListProps: {
                                    'aria-labelledby': 'select-label',
                                },
                                onClick: handleMenuItemClick,
                            }}
                            sx={{fontSize: 14}}
                        >
                            <MenuItem value='customBox' data-value='customBox'>Custom</MenuItem>
                            <MenuItem value='myBox' data-value='myBox'>From My Box</MenuItem>
                            {
                                commonBoxesList?.length > 0 && commonBoxesList?.map((each, index) => <MenuItem
                                    key={index}
                                    value={each?.box_name?.replace(/\s+/g, '')}
                                >{each?.box_name}</MenuItem>)
                            }
                        </Select>
                        {/*<Typography>*/}
                        {/*    {packageDetail?.packageId}*/}
                        {/*</Typography>*/}
                    </Box>
                </Grid>
                <Grid item md={2} xs={6}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required sx={{fontSize: '14px'}}>
                            {intl.get('ADD_PACKAGE.LENGTH')}
                        </InputLabel>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.length}
                                onInput={handleLength}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder={intl.get('ADD_PACKAGE.LENGTH')}
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {packageDetail?.lengthUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                            <Typography style={styles.ShippingPackageQuickQuotePackageEachWarningText}>
                                {
                                    packageDetail?.length > 0 ? "" : intl.get('ADD_PACKAGE.MISSING_INVALID')
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2} xs={6}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required sx={{fontSize: '14px'}}>
                            {intl.get('ADD_PACKAGE.WIDTH')}
                        </InputLabel>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.width}
                                onInput={handleWidth}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder={intl.get('ADD_PACKAGE.WIDTH')}
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {packageDetail?.lengthUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                            <Typography style={styles.ShippingPackageQuickQuotePackageEachWarningText}>
                                {
                                    packageDetail?.width > 0 ? "" : intl.get('ADD_PACKAGE.MISSING_INVALID')
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2} xs={6}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required sx={{fontSize: '14px'}}>
                            {intl.get('ADD_PACKAGE.HEIGHT')}
                        </InputLabel>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.height}
                                onInput={handleHeight}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder={intl.get('ADD_PACKAGE.HEIGHT')}
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {packageDetail?.lengthUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                            <Typography style={styles.ShippingPackageQuickQuotePackageEachWarningText}>
                                {
                                    packageDetail?.height > 0 ? "" : intl.get('ADD_PACKAGE.MISSING_INVALID')
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2} xs={6}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required sx={{fontSize: '14px'}}>
                            {intl.get('ADD_PACKAGE.WEIGHT')}
                        </InputLabel>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.weight}
                                onInput={handleWeight}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder={intl.get('ADD_PACKAGE.WEIGHT')}
                                size='small'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {packageDetail?.weightUnit}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                            <Typography style={styles.ShippingPackageQuickQuotePackageEachWarningText}>
                                {
                                    packageDetail?.weight > 0 ? "" : intl.get('ADD_PACKAGE.MISSING_INVALID')
                                }
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <InputLabel required sx={{fontSize: '14px'}}>
                                {intl.get('ADD_PACKAGE.INSURANCE')}
                            </InputLabel>
                            <GreenTooltip
                                placement="right-start"
                                title='The declared value you enter is for EACH individual package, not the total for all packages. Ensure accurate declaration for proper carrier insurance coverage.'
                            >
                                <IconButton sx={{
                                    padding: '0',
                                    margin: '0'
                                }}>
                                    <HelpIcon sx={{fontSize: '16px', color: '#1D8B45'}}/>
                                </IconButton>
                            </GreenTooltip>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.packageSpecialServices?.declaredValue?.value}
                                onInput={handleInsurance}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder={intl.get('ADD_PACKAGE.INSURANCE')}
                                size='small'
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required sx={{fontSize: '14px'}}>
                            {intl.get('ADD_PACKAGE.DESCRIPTION')}
                        </InputLabel>
                        <TextField
                            disabled={isGetRate}
                            value={packageDetail?.description}
                            onInput={handleDescription}
                            fullWidth
                            variant='outlined'
                            placeholder={intl.get('ADD_PACKAGE.DESCRIPTION')}
                            size='small'
                            inputProps={{style: {fontSize: 14}}} // font size of input text
                        />
                        <Typography style={styles.ShippingPackageQuickQuotePackageEachWarningText}>
                            {
                                packageDetail?.description?.length < 30 ? "" : intl.get('ADD_PACKAGE.OVER_CHARACTER')
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={1} xs={1}>
                    <Box sx={{
                        display: 'flex',
                        height: '100%',
                        alignItems: 'flex-end',
                        justifyContent: 'center'
                    }}>
                        <CloseIcon sx={{
                            fontSize: '35px',
                            color: '#1D8B45'
                        }}/>
                    </Box>
                </Grid>
                <Grid item md={3} xs={11}>
                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>
                        <InputLabel required sx={{fontSize: '14px'}}>
                            {intl.get('ADD_PACKAGE.QUANTITY')}
                        </InputLabel>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <TextField
                                disabled={isGetRate}
                                value={packageDetail?.quantity}
                                onInput={handleQuantity}
                                fullWidth
                                variant='outlined'
                                type='number'
                                placeholder={intl.get('ADD_PACKAGE.QUANTITY')}
                                size='small'
                                InputProps={{
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={1} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        // flexDirection: isMobile ? 'row' : 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                        height: '100%',
                        width: '100%',
                        gap: '10px'
                    }}>
                        <IconButton
                            color='success'
                            onClick={handleDuplicate}
                            disabled={isGetRate}
                            sx={{padding: 0}}
                        >
                            <img src={duplicate} width='30px'/>
                        </IconButton>
                        <IconButton
                            color='error'
                            onClick={handleRemove}
                            disabled={isGetRate}
                            sx={{padding: 0}}
                        >
                            <img src={remove} width='30px'/>
                        </IconButton>
                    </Box>
                </Grid>
                {/*<Grid item md={12}>*/}
                {/*    <Accordion*/}
                {/*        expanded={accordionOpen}*/}
                {/*        onChange={toggleOpen}*/}
                {/*    >*/}
                {/*        <AccordionSummary*/}
                {/*            expandIcon={<ExpandMoreIcon/>}*/}
                {/*        >*/}
                {/*            <Box sx={styles.ShippingPackageQuickQuotePackageEachSectionRow}>*/}
                {/*                <IconButton*/}
                {/*                    onClick={() => window.location.href = 'https://uucargo.ca/support/cross-border-shipping-parcels/'}>*/}
                {/*                    <HelpIcon color="info" sx={{fontSize: 25}}/>*/}
                {/*                </IconButton>*/}
                {/*                <Typography>*/}
                {/*                    {intl.getHTML('CROSS_BORDER.CROSS_BORDER_TITLE')}*/}
                {/*                </Typography>*/}
                {/*            </Box>*/}
                {/*        </AccordionSummary>*/}
                {/*        <AccordionDetails>*/}
                {/*            <Grid container spacing={1}>*/}
                {/*                <Grid item md={2} xs={6}>*/}
                {/*                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>*/}
                {/*                        <InputLabel required*/}
                {/*                                    sx={{fontSize: '14px'}}>{intl.get('CROSS_BORDER.PRICE')}</InputLabel>*/}
                {/*                        <TextField*/}
                {/*                            // disabled={isGetRate}*/}
                {/*                            value={packageDetail?.priceEach}*/}
                {/*                            onInput={handlePrice}*/}
                {/*                            fullWidth*/}
                {/*                            variant='outlined'*/}
                {/*                            type='number'*/}
                {/*                            size='small'*/}
                {/*                            InputProps={{*/}
                {/*                                inputProps: {*/}
                {/*                                    min: 1*/}
                {/*                                }*/}
                {/*                            }}*/}
                {/*                        />*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*                <Grid item md={2} xs={6}>*/}
                {/*                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>*/}
                {/*                        <InputLabel required*/}
                {/*                                    sx={{fontSize: '14px'}}>{intl.get('CROSS_BORDER.CURRENCY')}</InputLabel>*/}
                {/*                        <FormControl fullWidth>*/}
                {/*                            <Select*/}
                {/*                                value={packageDetail?.currency}*/}
                {/*                                onChange={handleCurrency}*/}
                {/*                                size='small'*/}
                {/*                            >*/}
                {/*                                <MenuItem value={'CAD'}>CAD</MenuItem>*/}
                {/*                                <MenuItem value={'USD'}>USD</MenuItem>*/}
                {/*                            </Select>*/}
                {/*                        </FormControl>*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*                <Grid item md={2} xs={6}>*/}
                {/*                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>*/}
                {/*                        <InputLabel required*/}
                {/*                                    sx={{fontSize: '14px'}}>{intl.get('CROSS_BORDER.COUNTRY')}</InputLabel>*/}
                {/*                        <FormControl fullWidth>*/}
                {/*                            <Select*/}
                {/*                                value={packageDetail?.originCountryCode}*/}
                {/*                                onChange={handleCountryCode}*/}
                {/*                                size='small'*/}
                {/*                            >*/}
                {/*                                {*/}
                {/*                                    getCountryCode().map(v => {*/}
                {/*                                        return <MenuItem value={v.code}>{v.name}</MenuItem>*/}
                {/*                                    })*/}
                {/*                                }*/}
                {/*                            </Select>*/}
                {/*                        </FormControl>*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*                <Grid item md={2} xs={6}>*/}
                {/*                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>*/}
                {/*                        <InputLabel required*/}
                {/*                                    sx={{fontSize: '14px'}}>{intl.get('CROSS_BORDER.UOM')}</InputLabel>*/}
                {/*                        <FormControl fullWidth>*/}
                {/*                            <Select*/}
                {/*                                value={packageDetail?.UOM}*/}
                {/*                                onChange={handleUOM}*/}
                {/*                                size='small'*/}
                {/*                            >*/}
                {/*                                {UOMList.map(v => {*/}
                {/*                                    return <MenuItem value={v}>{v}</MenuItem>*/}
                {/*                                })}*/}
                {/*                            </Select>*/}
                {/*                        </FormControl>*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*                <Grid item md={4} xs={12}>*/}
                {/*                    <Box sx={styles.ShippingPackageQuickQuotePackageEachSection}>*/}
                {/*                        <InputLabel required*/}
                {/*                                    sx={{fontSize: '14px'}}>{intl.get('CROSS_BORDER.HS_CODE')}</InputLabel>*/}
                {/*                        <Autocomplete*/}
                {/*                            disablePortal*/}
                {/*                            freeSolo*/}
                {/*                            options={HSCodeList}*/}
                {/*                            getOptionLabel={option => option.hscode}*/}
                {/*                            filterOptions={(options, state) => options}*/}
                {/*                            fullWidth*/}
                {/*                            onInputChange={handleOnInputChangeHSCode}*/}
                {/*                            className={classes.smallInput}*/}
                {/*                            renderInput={(params) => <TextField*/}
                {/*                                {...params}*/}
                {/*                                // multiline*/}
                {/*                                placeholder={packageDetail?.hscode}*/}
                {/*                            />}*/}
                {/*                            renderOption={(option) => (*/}
                {/*                                <Typography*/}
                {/*                                    style={{fontSize: '12px'}}*/}
                {/*                                >*/}
                {/*                                    /!*<Typography>*!/*/}
                {/*                                    {option.hscode} | {option.desc}*/}
                {/*                                    /!*</Typography>*!/*/}
                {/*                                </Typography>*/}
                {/*                            )}*/}
                {/*                        />*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*        </AccordionDetails>*/}
                {/*    </Accordion>*/}
                {/*</Grid>*/}
            </Grid>
            <Divider/>
        </Box>
    )
}