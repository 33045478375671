import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import {MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import GoogleMapReact from "google-map-react";

export const TransportationAvailableTasksAddress = ({
                                                        taskDetails,
                                                        additionalData,
                                                        pickupCoordinate,
                                                        googleApi,
                                                        setGoogleApi,
                                                        handleChangeRouteCoordinate
                                                    }) => {

    const [viewMap, setViewMap] = useState(false);
    const [route, setRoute] = useState('pickup');

    const handleViewMapOpen = () => {
        setViewMap(!viewMap);
    }

    const handleChangeRoute = (event) => {
        setViewMap(false);
        setRoute(event?.target?.value);
        handleChangeRouteCoordinate(event?.target?.value);
    }

    useEffect(() => {
        if (googleApi) {
            setViewMap(true);
        }
    }, [pickupCoordinate])

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Order Address
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Moving From
                                        </Typography>
                                    </Grid>
                                    {
                                        taskDetails?.pickup_addr_type?.toUpperCase() === 'SCHEDULE' ?
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Scheduled Pickup
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Address:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {taskDetails?.pickup_address}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Area:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {taskDetails?.pickup_area}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Postal Code:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {taskDetails?.pickup_postal_code}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Property Type:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {additionalData?.movingFromAddress?.propertyType?.propertyType}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        House Type:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {additionalData?.movingFromAddress?.propertyType?.houseType}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Floor Level:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {additionalData?.movingFromAddress?.floorLevel}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Lift:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {additionalData?.movingFromAddress?.lift ? 'Yes' : 'No'}
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Distance from Truck to Door:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {additionalData?.movingFromAddress?.distanceFromTruckToDoor}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Staircase Number*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {additionalData?.movingFromAddress?.staircaseNumber}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Narrow Street*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {additionalData?.movingFromAddress?.isNarrowStreet ? "YES" : "NO"}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Steep Driveway*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {additionalData?.movingFromAddress?.isSteepDriveway ? "YES" : "NO"}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                            </>
                                            :
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Self Drop-Off
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Warehouse Name:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {taskDetails?.pickup_warehouse?.name}
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Warehouse Address:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {taskDetails?.pickup_warehouse?.address}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Warehouse Area:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {taskDetails?.pickup_warehouse?.city}, {taskDetails?.pickup_warehouse?.province}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Warehouse Postal Code:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {taskDetails?.pickup_warehouse?.postal_code}
                                                    </Typography>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Moving To
                                        </Typography>
                                    </Grid>
                                    {
                                        taskDetails?.deliver_addr_type?.toUpperCase() === 'SCHEDULE' ?
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Scheduled Delivery
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Address:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {taskDetails?.deliver_address}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Area:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {taskDetails?.deliver_area}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Postal Code:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {taskDetails?.deliver_postal_code}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Property Type:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {additionalData?.movingToAddress?.propertyType?.propertyType}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        House Type:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {additionalData?.movingToAddress?.propertyType?.houseType}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Floor Level:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {additionalData?.movingToAddress?.floorLevel}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Lift:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {additionalData?.movingToAddress?.lift ? 'Yes' : 'No'}
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Distance from Truck to Door:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.distanceFromTruckToDoor}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Staircase Number*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.staircaseNumber}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Narrow Street*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.isNarrowStreet ? "YES" : "NO"}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Steep Driveway*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.isSteepDriveway ? "YES" : "NO"}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        House Type:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.property?.houseType}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Storey:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {additionalData?.movingToAddress?.storey}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                            </>
                                            :
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Self Pickup
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Warehouse Name:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {taskDetails?.deliver_warehouse?.name}
                                                    </Typography>
                                                </Grid>
                                                {/*<Grid item xs={5}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        Warehouse Address:*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                {/*<Grid item xs={7}>*/}
                                                {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                {/*        {taskDetails?.deliver_warehouse?.address}*/}
                                                {/*    </Typography>*/}
                                                {/*</Grid>*/}
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Warehouse Area:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {taskDetails?.deliver_warehouse?.city}, {taskDetails?.deliver_warehouse?.province}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Warehouse Postal Code:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {taskDetails?.deliver_warehouse?.postal_code}
                                                    </Typography>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    {
                                        taskDetails?.pickup_warehouse?.id !== taskDetails?.deliver_warehouse?.id &&
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={route}
                                                    onChange={handleChangeRoute}
                                                    size='small'
                                                    sx={{textAlign: 'left'}}
                                                >
                                                    <MenuItem value='pickup'>
                                                        {
                                                            taskDetails?.pickup_addr_type?.toUpperCase() === 'SCHEDULE' ?
                                                                'Customer Home to Warehouse (Scheduled Pickup)' :
                                                                'Warehouse Location (Self Drop-Off)'
                                                        }
                                                    </MenuItem>
                                                    <MenuItem value='delivery'>
                                                        {
                                                            taskDetails?.deliver_addr_type?.toUpperCase() === 'SCHEDULE' ?
                                                                'Warehouse to Customer Home (Scheduled Delivery)' :
                                                                'Warehouse Location (Self Pickup)'
                                                        }
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                    <Grid item xs={6}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start'
                                        }}>
                                            <Button
                                                variant='contained'
                                                sx={{
                                                    backgroundColor: '#F2BE22',
                                                    "&:hover": {
                                                        backgroundColor: '#F2BE22',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={handleViewMapOpen}
                                            >
                                                <Typography style={{
                                                    textAlign: 'left',
                                                    textTransform: 'none'
                                                }}>
                                                    {viewMap ? 'Close Map' : 'View Map'}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    {
                                        viewMap &&
                                        <Grid item xs={12}>
                                            <Box sx={{height: "75vh", width: "100%"}}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{key: 'AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ'}}
                                                    center={{
                                                        lat: pickupCoordinate.lat,
                                                        lng: pickupCoordinate.lng,
                                                    }}
                                                    defaultZoom={11}
                                                    yesIWantToUseGoogleMapApiInternals={true}
                                                    onGoogleApiLoaded={({map, maps}) => {
                                                        // console.log('[GoogleMapReact] map', map);
                                                        // console.log('[GoogleMapReact] maps', maps);
                                                        setGoogleApi({map, maps});
                                                        // setMapInitialized(true);
                                                    }}
                                                    options={{
                                                        mapTypeControl: true,
                                                        styles: [
                                                            {
                                                                featureType: 'administrative',
                                                                elementType: 'labels.text',
                                                                stylers: [{visibility: 'on'}],
                                                            },
                                                        ],
                                                    }}
                                                >
                                                </GoogleMapReact>
                                            </Box>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}