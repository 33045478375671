import {BACKEND_ME_API, GET_PARTNER_INFO_API} from "./apiUrl";
import axios from "axios";
import { getHeaders } from "./fetchUtil";
import { BACKEND_IS_PARTNER_API } from "./apiUrl";
import {getAccessToken} from "./doToken";

const verifyIsPartner = async () => {
  try {
    const storedToken = getAccessToken("access_token");
    const { data: partnerData } = await axios.get(BACKEND_IS_PARTNER_API, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      }
    });
    console.log('verify partner', partnerData);
    return partnerData.result;
  } catch (e) {
    console.log(e.response);
    return false;
  }
}

const getPartnerInfoByPartnerId = async () => {
  try {
    const storedToken = getAccessToken("access_token");
    const { data } = await axios.get(GET_PARTNER_INFO_API, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      }
    });
    return data

  } catch (e) {
    console.log(e);
    return false;
  }
}

export {
  verifyIsPartner,
  getPartnerInfoByPartnerId
}