import {LandingIntro} from "./LandingIntro";
import Box from "@mui/material/Box";
import {UserSignUp} from "../SignUp/UserSignUp";
import {Container} from "@mui/material";
import '../../styles/landing.css';
import {useEffect} from "react";


const styles = {
    LandingSignUpRoot: {
        display: 'flex',
        margin: 'auto',
        '@media (max-width: 480px)': {
            flexDirection: "column", // for phone and above
        },
    }
}

export const LandingSignUp = () => {

    useEffect(() => {
        document.body.classList.add('white-background');

        return () => {
            document.body.classList.remove('white-background');
        };
    }, []);

    return  (
        <Container maxWidth='xl'>
            <Box sx={styles.LandingSignUpRoot}>
                <LandingIntro/>
                <UserSignUp/>
            </Box>
        </Container>
    )
}