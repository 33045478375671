import GoogleMapReact from "google-map-react";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {getLocation} from "../../utils/Helper";
import RoomIcon from "@material-ui/icons/Room";
import {TransportationAvailableTasksMapMarker} from "./TransportationAvailableTasksMapMarker";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useSelector} from "react-redux";
import {getAccessToken} from "../../utils/doToken";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {ButtonBase, Snackbar} from "@mui/material";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";

const styles = {
    ShippingPackageCreateLabelRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },
    ShippingPackageCreateLabelRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelRowRight: {
        display: 'flex',
        justifyContent: 'flex-end'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TransportationAvailableTasksMap = ({availableTasks}) => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");

    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [loadingOwnLocation, setLoadingOwnLocation] = useState(false);
    const [address, setAddress] = useState(null);
    const [location, setLocation] = useState(null);
    const [mapMarkerList, setMapMakerList] = useState([]);
    const [selectTask, setSelectTask] = useState(null);
    const [yourLocation, setYourLocation] = useState(null);
    const [showYourLocation, setShowYourLocation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("")

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const claimOrder = async (id) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/partnerClaimMovingOrder`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    backendOrderId: id
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully claim order.');
            setTimeout(() => {
                history.push(`/transportation/claimTasks/details/${id}`);
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage("Fail to claim order.");
        } finally {
            setLoading(false);
        }
    }

    const handleClaimOrder= (id) => {
        claimOrder(id);
    }

    const findPlace = async (address) => {
        let requestURL = `${PARTNER_URI}/googleMaps/findPlace`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: address
                }
            });

            const location = result?.data?.data?.candidates[0]?.geometry?.location;
            setLocation(location);
            setYourLocation(location);
        } catch (e) {
            console.log('error', e?.response);
        }
    }

    const handleAddressList = async (address) => {
        let requestURL = `${PARTNER_URI}/googleMaps/findPlace`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: address
                }
            });

            const location = result?.data?.data?.candidates[0]?.geometry?.location;
            return location;
        } catch (e) {
            console.log('error', e?.response);
        }
    }

    const convertAddressToCoordinate = async () => {
        const updatedTasks = await Promise.all(
            availableTasks?.map(async (task) => {
                if (task?.pickup_addr_lat_lng) {
                    const [latitude, longitude] = task?.pickup_addr_lat_lng.split(', ');
                    return {
                        ...task,
                        coordinates: {
                            lat: +latitude,
                            lng: +longitude
                        }
                    }
                } else {
                    try {
                        const location = await handleAddressList(task?.pickup_address);
                        console.log('[TransportationAvailableTasksMap] convertAddressToCoordinate - location', location);
                        return {
                            ...task,
                            coordinates: location
                        };
                    } catch (error) {
                        console.error('[TransportationAvailableTasksMap] convertAddressToCoordinate - Error:', error);
                        return task;// or handle the error in an appropriate way
                    }
                }
            })
        );
        console.log('[TransportationAvailableTasksMap] convertAddressToCoordinate - updatedTasks', updatedTasks);
        setMapMakerList(updatedTasks);
    }

    const getUserInfoAndCompany = async () => {
        setLoadingOwnLocation(true);
        let requestURL = `${PARTNER_URI}/login_middleware/getUserInfoWithCompany`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
            })
            const {data} = result;
            console.log('result', data);
            setAddress(data?.address);
        } catch (e) {
            console.log('error', e?.response);
        } finally {
            setLoadingOwnLocation(false);
        }
    }

    const handleSelectTask = (task) => {
        setSelectTask(task);
        setLocation(task?.coordinates);
    }

    const handleViewDetails = (id) => {
        history.push(`/transportation/availableTasks/details/${id}`);
    }

    useEffect(async () => {
        const location = await getLocation();
        await getUserInfoAndCompany();
        setLatitude(location?.coords?.latitude);
        setLongitude(location?.coords?.longitude);
        console.log('[TransportationAvailableTasksMap] position', location);
    }, []);

    useEffect(() => {
        if (address) {
            findPlace(address);
        }
    }, [address]);

    useEffect(() => {
        if (availableTasks?.length > 0) {
            convertAddressToCoordinate();
        }
    }, [availableTasks])

    console.log('[TransportationAvailableTasksMap] mapMarkerList', mapMarkerList);
    console.log('[TransportationAvailableTasksMap] availableTasks', availableTasks);

    return (
        <Box style={styles.ShippingPackageCreateLabelRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={4} xl={3}>
                    <Box sx={{
                        height: "75vh",
                        overflow: 'hidden auto',
                        whiteSpace: 'wrap',
                    }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left'}}>
                                    Result: {availableTasks?.length} Listings
                                </Typography>
                            </Grid>
                            {
                                mapMarkerList?.map((task, index) => (
                                    <Grid item xs={12} key={index}>
                                        <ButtonBase
                                            onClick={() => handleSelectTask(task)}
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            <Box sx={{
                                                borderRadius: '5px',
                                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                                                padding: '5px',
                                                margin: '5px',
                                                border: selectTask?.id === task?.id && '2px #1D8B45 solid',
                                                width: '100%'
                                            }}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <Typography style={{
                                                            fontSize: '14px',
                                                            textAlign: 'left',
                                                            fontWeight: '600',
                                                            color: '#1D8B45'
                                                        }}>
                                                            $ {task?.final_total?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                                            {task?.pickup_area}, {task?.pickup_postal_code}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                                            {dayjs(task?.scheduled_pickup_date)?.format("YYYY-MM-DD")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                                            {task?.google_map_distance} km
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                            gap: '5px'
                                                        }}>
                                                            <Button
                                                                variant='outlined'
                                                                sx={{
                                                                    border: '2px solid #1D8B45',
                                                                    "&:hover": {
                                                                        border: '2px solid #1D8B45',
                                                                        filter: 'brightness(0.9)'
                                                                    }
                                                                }}
                                                                size='small'
                                                                onClick={() => handleViewDetails(task?.id)}
                                                            >
                                                                <Typography style={{
                                                                    textTransform: 'none',
                                                                    color: '#1D8B45',
                                                                    fontSize: '12px'
                                                                }}>
                                                                    Details
                                                                </Typography>
                                                            </Button>
                                                            <LoadingButton
                                                                variant='outlined'
                                                                sx={{
                                                                    border: '2px solid #1D8B45',
                                                                    "&:hover": {
                                                                        border: '2px solid #1D8B45',
                                                                        filter: 'brightness(0.9)'
                                                                    }
                                                                }}
                                                                size='small'
                                                                onClick={() => handleClaimOrder(task?.id)}
                                                                loading={loading}
                                                            >
                                                                <Typography style={{
                                                                    textTransform: 'none',
                                                                    color: '#1D8B45',
                                                                    fontSize: '12px'
                                                                }}>
                                                                    Claim
                                                                </Typography>
                                                            </LoadingButton>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </ButtonBase>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={8} xl={9}>
                    <Box sx={{height: "75vh", width: "100%"}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{key: 'AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ'}}
                            center={{
                                lat: location?.lat,
                                lng: location?.lng,
                            }}
                            defaultZoom={11}
                        >
                            <Box
                                lat={yourLocation?.lat}
                                lng={yourLocation?.lng}
                                sx={{position: 'relative'}}
                            >
                                <IconButton
                                    onMouseEnter={() => setShowYourLocation(true)}
                                    onMouseLeave={() => setShowYourLocation(false)}
                                >
                                    <RoomIcon style={{
                                        color: '#3081D0',
                                        fontSize: '30px',
                                    }}/>
                                </IconButton>
                                {
                                    showYourLocation &&
                                    <Box sx={{
                                        position: 'absolute',
                                        zIndex: '10',
                                        width: '200px',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -140%)'

                                    }}>
                                        <Typography
                                            style={{fontSize: '16px', fontWeight: '600'}}>
                                            Your Location
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                            {
                                mapMarkerList?.map((marker, index) => <TransportationAvailableTasksMapMarker
                                    lat={marker?.coordinates?.lat}
                                    lng={marker?.coordinates?.lng}
                                    key={index}
                                    marker={marker}
                                    selectTask={selectTask}
                                    handleSelectTask={handleSelectTask}
                                />)
                            }
                        </GoogleMapReact>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}