import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
// import Slide from 'react-reveal';
// import "../styles/styles.css";
import {ReactComponent as OceanShippingIcon} from "../images/earth.svg";
import {ReactComponent as BookingIcon} from "../images/order_unread.svg";
import {ReactComponent as BolIcon} from "../images/international.svg";
import {ReactComponent as InvoicesIcon} from "../images/invoice.svg";
import {ReactComponent as PalletsIcon} from "../images/pallet.svg";
import {ReactComponent as RequestIcon} from "../images/Request Quote.svg";
import ScheduleIcon from '@mui/icons-material/Schedule';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import axios from "axios";
import {Box, Button, Card, Container} from "@material-ui/core";
import NavBar from "./navBar";

import {GET_PARTNER, GET_PARTNER_INFO_API, PARTNER_URI, SERVICE_SERVICE_URI} from "../utils/apiUrl";
import LoadingContainer from "../shared/loadingContainer";
import SearchIcon from "@material-ui/icons/Search";
import WidgetsIcon from "@material-ui/icons/Widgets";
import ShippingOrderListTableLanding from "./ShippingList/ShippingOrderListTableLanding";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import OutboxIcon from '@mui/icons-material/Outbox';

import {CloudUploadOutlined} from "@material-ui/icons";
import BolListTableLanding from "./Hscode/Components/BolListTableLanding";
import PartnerInvoiceTable from "./Invoice/PartnerInvoiceTable";
import PalletRequestTableLanding from "./PalletsList/palletRequestTableLanding";
import {separateTaxes} from "../utils/calculateTax";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import upsLogo from "../images/UPS.png";
import fedexLogo from "../images/FedEx.png";
import canadapostLogo from "../images/CanadaPost.png";
import canparLogo from "../images/Canpar.png";
import {
    selectShippingLTLQuickQuoteRate,
    updateShippingLTLQuickQuotePlaceOrderValidate
} from "./Actions/shippingLTLAction";
import {getAccessToken} from "../utils/doToken";
import {Divider, useMediaQuery} from "@mui/material";
import {ReactComponent as QuickQuoteLogo} from "../images/homepageIcon/QuickQuote.svg";
import {ReactComponent as CreateShipmentLogo} from "../images/homepageIcon/CreateShipment.svg";
import {ReactComponent as ImportShipmentLogo} from "../images/homepageIcon/ImportShipments.svg";
import {ReactComponent as SchedulePickupLogo} from "../images/homepageIcon/SchedulePickup.svg";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import {ShippingOrder} from "./HomePage/ShippingOrder";
import {ScheduledPickup} from "./HomePage/ScheduledPickup";
import {Tracking} from "./HomePage/Tracking";
import intl from "react-intl-universal";
import Cookies from 'js-cookie';
import {Welcome} from "./Welcome/Welcome";
import {getWelcome} from "../utils/Helper";
import Modal from "@mui/material/Modal";

const styles = {
    landingPageCard: {
        display: 'flex',
        gap: '15px',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',

    },
    landingPageCardEach: {
        display: "flex",
        justifyContent: "center",
        gap: '20px',
        width: "100%",
        height: '100%',
        alignItems: "center",
    },
    landingPageCardEachText: {
        '@media (max-width: 480px)': {
            fontSize: '14px'
        }
    },
    landingPageLatestShippingOrder: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    landingPageLatestShippingOrderText: {
        border: '1px solid #000000',
        fontSize: '14px',
        padding: '0 5px'
    },
    landingPageLatestShippingOrderTableText: {
        fontSize: '14px',
        fontWeight: '600'
    },
}

const LandingPage = () => {
    // const token = getAccessToken("access_token");

    const dispatch = useDispatch();

    const isMobile = useMediaQuery('(max-width: 480px)');

    const {firstname, id, isLoading} = useSelector((state) => state.user);
    const history = useHistory();
    const classes = useStyles();
    const ORDER_NUMBER = 10;

    const [partnerInfo, setPartnerInfo] = useState(null);

    const [gettingPartner, setGettingPartnerStatus] = useState(true);

    const [partnerData, setPartnerData] = useState(null);

    const [message, setMessage] = useState("");

    const [shippingOrderData, setShippingOrderData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [bolOrderData, setBolOrderData] = useState([]);
    const [palletRequestData, setPalletRequestData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [directURL, setDirectURL] = useState("/billing-center");
    const [availableCredit, setAvailableCredit] = useState(0);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    console.log('[LandingPage] partner info', partnerInfo);

    useEffect(() => {
            if (id) {
                const storedToken = getAccessToken("access_token");
                axios({
                    method: "get",
                    url: GET_PARTNER_INFO_API,
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                })
                    .then((res) => {
                        setGettingPartnerStatus(false);
                        if (res.data) {
                            setPartnerData(res.data);
                        } else {
                            setMessage(
                                "Please contact us to access the buy shipping page \n Phone: 1-604-998-1888, email: info@uucargo.ca"
                            );
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        setMessage("Error when accessing the page");
                    });

                // Load Latest Order Item
                (async () => {
                    try {
                        const {data} = await axios.get(
                            `${PARTNER_URI}/shipping/list/order?partner_id=${id}&limit=${ORDER_NUMBER}`
                        );
                        setShippingOrderData(data.result);
                        console.log(data);
                    } catch (e) {
                        console.log(e);
                    }
                })();

                // Get Latest Invoice List
                (async () => {
                    try {
                        const {data} = await axios.get(`${PARTNER_URI}/invoices/getInvoicesList?partnerId=${id}&page=0&pageSize=5`);
                        const result = data.data.filter((v, index) => index < 5);
                        console.log("Invoices: ", result);
                        setInvoiceData(result);
                    } catch (e) {
                        console.log(e);
                    }
                })();

                (async () => {
                        setLoading(true);
                        try {
                            const result = await axios({
                                method: 'get',
                                url: GET_PARTNER_INFO_API,
                                headers: {
                                    Authorization: `Bearer ${storedToken}`,
                                }
                            });
                            console.log('partner info result', result);
                            const {data: {overdraft_limit, current_bill_balance}} = result;
                            setPartnerInfo(result?.data);
                            // console.log('limit', overdraft_limit);
                            // console.log('balance', current_bill_balance);
                            const credit = overdraft_limit + current_bill_balance
                            setAvailableCredit(credit);
                            setLoading(false);
                        } catch (e) {
                            console.log(e);
                            setLoading(false);
                            return false;
                        }
                    }
                )();

                (async () => {
                    try {
                        const {data} = await axios.get(`${PARTNER_URI}/partner/bol/getLatestBolList?partnerId=${id}&limit=${ORDER_NUMBER}`);
                        setBolOrderData(data.result);
                        console.log(data);
                    } catch (e) {
                        console.log(e);
                    }
                })();

                // Pallets API
                (async () => {
                    try {
                        const {data} = await axios.post(`${PARTNER_URI}/partnerPallets/getMyShippingQuoteRequestList`, {
                            partnerId: id,
                            requestStatusArray: [0, 1],
                            page: 0,
                            pageSize: 5
                        });
                        setPalletRequestData(data.results);
                    } catch (e) {
                        console.log(e);
                    }
                })();

                // (async () => {
                //         const storedToken = getAccessToken("access_token");
                //         try {
                //             const result = await axios({
                //                 method: 'get',
                //                 url: GET_PARTNER_INFO_API,
                //                 headers: {
                //                     Authorization: `Bearer ${storedToken}`,
                //                 }
                //             });
                //             setPartnerInfo(result?.data);
                //         } catch (e) {
                //             console.log(e);
                //         }
                //     }
                // )();

            } else if (!isLoading && !id && id !== 0) {
                setMessage("Can't get user information");
                history.push("/");
            }
        }
        ,
        [id, isLoading]
    );

    useEffect(() => {
        const isWelcome = getWelcome();
        setOpen(isWelcome);
    }, [])

    // if (getWelcome()) {
    //     return (
    //         <Welcome/>
    //     )
    // }

    // console.log('welcome', getWelcome());

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'flex-start',
                marginBottom: '30px'
            }}>
                <Divider orientation="vertical" flexItem sx={{backgroundColor: '#1D8B45', width: '5px'}}/>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <Typography style={{
                        fontSize: '20px',
                        fontWeight: '600',
                    }}>
                        {intl.get('HOME_PAGE.WELCOME', {name: firstname})}
                    </Typography>
                    <Typography style={{
                        fontSize: '14px',
                        // fontWeight: '600'
                    }}>
                        {intl.get('HOME_PAGE.SLOGAN')}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'flex-start',
                marginBottom: '20px'
            }}>
                <Typography style={{fontSize: '16px', fontWeight: 600}}>
                    {intl.get('HOME_PAGE.QUICK_ACTION.QUICK_ACTION')}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item md={9} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item md={3} xs={6}>
                                <Button
                                    className={classes.ButtonBase}
                                    variant='outlined'
                                    onClick={() => history.push("/shipping-package/quick-quote")}
                                >
                                    <Box className={classes.section}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <QuickQuoteLogo width="40" height="40"/>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '5px',
                                        }}>
                                            <Typography
                                                style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    textAlign: 'left'
                                                }}>
                                                {intl.get('HOME_PAGE.QUICK_ACTION.QUICK_QUOTE')}
                                            </Typography>
                                            {!isMobile &&
                                                <Typography
                                                    style={{
                                                        textTransform: 'none',
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.QUICK_QUOTE_SLOGAN')}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Button
                                    className={classes.ButtonBase}
                                    variant='outlined'
                                    onClick={() => history.push("/shipping-package/create-label")}
                                >
                                    <Box className={classes.section}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <CreateShipmentLogo width="40" height="40"/>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '5px',
                                            justifyContent: 'flex-start',
                                            height: '100%'
                                        }}>
                                            <Typography
                                                style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    textAlign: 'left'
                                                }}>
                                                {intl.get('HOME_PAGE.QUICK_ACTION.CREATE_SHIPMENT')}
                                            </Typography>
                                            {!isMobile &&
                                                <Typography
                                                    style={{
                                                        textTransform: 'none',
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.CREATE_SHIPMENT_SLOGAN')}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                {
                                    partnerInfo?.ltl_rating_status === 1 ?
                                        <Button
                                            className={classes.ButtonBase}
                                            variant='outlined'
                                            onClick={() => history.push('/LTL-quick-quote')}
                                        >
                                            <Box className={classes.section}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <ImportShipmentLogo width="40" height="40"/>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: '5px',
                                                    justifyContent: 'flex-start',
                                                    height: '100%',
                                                }}>
                                                    <Typography
                                                        style={{
                                                            textTransform: 'none',
                                                            fontSize: '14px',
                                                            fontWeight: '600',
                                                            textAlign: 'left'
                                                        }}>
                                                        {intl.get('HOME_PAGE.QUICK_ACTION.LTL_FREIGHT')}
                                                    </Typography>
                                                    {!isMobile &&
                                                        <Typography
                                                            style={{
                                                                textTransform: 'none',
                                                                fontSize: '12px',
                                                                textAlign: 'left'
                                                            }}>
                                                            {intl.get('HOME_PAGE.QUICK_ACTION.LTL_FREIGHT_SLOGAN')}
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Box>
                                        </Button>
                                        :
                                        <Button
                                            className={classes.ButtonBase}
                                            variant='outlined'
                                            onClick={() => history.push('/shipping-package/international-shipment')}
                                        >
                                            <Box className={classes.section}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <ImportShipmentLogo width="40" height="40"/>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: '5px',
                                                    justifyContent: 'flex-start',
                                                    height: '100%',
                                                }}>
                                                    <Typography
                                                        style={{
                                                            textTransform: 'none',
                                                            fontSize: '14px',
                                                            fontWeight: '600',
                                                            textAlign: 'left'
                                                        }}>
                                                        {intl.get('HOME_PAGE.QUICK_ACTION.INTERNATIONAL_SHIPMENT')}
                                                    </Typography>
                                                    {!isMobile &&
                                                        <Typography
                                                            style={{
                                                                textTransform: 'none',
                                                                fontSize: '12px',
                                                                textAlign: 'left'
                                                            }}>
                                                            {intl.get('HOME_PAGE.QUICK_ACTION.INTERNATIONAL_SHIPMENT_SLOGAN')}
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Box>
                                        </Button>
                                }
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Button
                                    className={classes.ButtonBase}
                                    variant='outlined'
                                    onClick={() => history.push("/schedulepickup")}
                                >
                                    <Box className={classes.section}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <SchedulePickupLogo width="40" height="40"/>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '5px',
                                        }}>
                                            <Typography
                                                style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    textAlign: 'left'
                                                }}>
                                                {intl.get('HOME_PAGE.QUICK_ACTION.SCHEDULE_PICKUP')}
                                            </Typography>
                                            {
                                                !isMobile &&
                                                <Typography
                                                    style={{
                                                        textTransform: 'none',
                                                        fontSize: '12px',
                                                        textAlign: 'left'
                                                    }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.SCHEDULE_PICKUP_SLOGAN')}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={12}
                    >
                        {loading ? <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <CircularProgress/>
                            </Box> :
                            <Button
                                className={classes.BillingButtonBase}
                                variant='outlined'
                                onClick={() => history.push(directURL)}
                            >
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            textTransform: 'none',
                                            marginBottom: '5px'
                                        }}>
                                            {intl.get('HOME_PAGE.QUICK_ACTION.BILLING_CENTER')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                {
                                                    availableCredit < 0 ?
                                                        <Typography style={{
                                                            color: '#FE0000',
                                                            textAlign: 'left',
                                                            fontSize: '14px',
                                                            fontWeight: '600'
                                                        }}>
                                                            ${availableCredit.toFixed(2)}
                                                        </Typography> :
                                                        <Typography style={{
                                                            color: '#1D8B45',
                                                            textAlign: 'left',
                                                            fontSize: '14px',
                                                            fontWeight: '600'
                                                        }}>
                                                            ${availableCredit.toFixed(2)}
                                                        </Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    textAlign: 'left',
                                                    textTransform: 'none'
                                                }}>
                                                    {intl.get('HOME_PAGE.QUICK_ACTION.AVAILABLE_CREDITS')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant='contained'
                                            className={classes.button}
                                            onMouseMove={() => setDirectURL('/billing-center/top-up')}
                                            onMouseLeave={() => setDirectURL('/billing-center')}
                                        >
                                            <Typography style={{textTransform: 'none', fontSize: '12px'}}>
                                                {intl.get('HOME_PAGE.QUICK_ACTION.ADD_CREDITS')}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={1}>
                <Grid item xl={9} md={12} xs={12}>
                    <ShippingOrder shippingOrderData={shippingOrderData}/>
                </Grid>
                <Grid item xl={3} md={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} md={6} xs={12}>
                            <ScheduledPickup/>
                        </Grid>
                        <Grid item xl={12} md={6} xs={12}>
                            <Tracking/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/*{shippingOrderData.length > 0 ?*/}
            {/*    <Box>*/}
            {/*        <Box sx={styles.landingPageLatestShippingOrder}>*/}
            {/*            <Box fontSize="1.5rem" mt={1} textAlign="left">*/}
            {/*                Latest shipping order:*/}
            {/*            </Box>*/}
            {/*            <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>*/}
            {/*                <Button*/}
            {/*                    variant='contained'*/}
            {/*                    size='small'*/}
            {/*                    onClick={() => history.push('/shipping-package/quick-quote')}*/}
            {/*                    className={classes.button}*/}
            {/*                >*/}
            {/*                    Quick Quote*/}
            {/*                </Button>*/}
            {/*                <Button*/}
            {/*                    variant='contained'*/}
            {/*                    size='small'*/}
            {/*                    onClick={() => history.push('/shipping-package/rating-buy/excel')}*/}
            {/*                    className={classes.button}*/}
            {/*                >*/}
            {/*                    Upload Excel*/}
            {/*                </Button>*/}
            {/*            </Box>*/}
            {/*        </Box>*/}
            {/*        <Card classes={{root: classes.lgRoot}}>*/}
            {/*            <table className={classes.table}>*/}
            {/*                <thead>*/}
            {/*                <tr className={classes.idTRLarge}>*/}
            {/*                    <th>*/}
            {/*                        Order ID / Ref No. / Cargo Control#*/}
            {/*                    </th>*/}
            {/*                    <th>Total Charge Include Tax</th>*/}
            {/*                    <th>Create Date</th>*/}
            {/*                    <th>Transaction</th>*/}
            {/*                    <th>Tracking #</th>*/}
            {/*                    <th>Labels</th>*/}
            {/*                </tr>*/}
            {/*                <tr className={classes.idTRSmall}>*/}
            {/*                    <th>*/}
            {/*                        <Typography sx={styles.landingPageLatestShippingOrderTableText}>*/}
            {/*                            Order ID /*/}
            {/*                        </Typography>*/}
            {/*                        <Typography sx={styles.landingPageLatestShippingOrderTableText}>*/}
            {/*                            Ref No.*/}
            {/*                        </Typography>*/}
            {/*                    </th>*/}
            {/*                    <th>*/}
            {/*                        <Typography sx={styles.landingPageLatestShippingOrderTableText}>*/}
            {/*                            Total Charge Include Tax*/}
            {/*                        </Typography>*/}
            {/*                    </th>*/}
            {/*                    <th>*/}
            {/*                        <Typography sx={styles.landingPageLatestShippingOrderTableText}>*/}
            {/*                            Create Date*/}
            {/*                        </Typography>*/}
            {/*                    </th>*/}
            {/*                    <th>*/}
            {/*                        <Typography sx={styles.landingPageLatestShippingOrderTableText}>*/}
            {/*                            Action*/}
            {/*                        </Typography>*/}
            {/*                    </th>*/}
            {/*                </tr>*/}
            {/*                </thead>*/}
            {/*                <tbody className={classes.tbody}>*/}
            {/*                {shippingOrderData?.map((order, index) => {*/}
            {/*                    return (*/}
            {/*                        <ShippingOrderListTableLanding*/}
            {/*                            key={index}*/}
            {/*                            index={index}*/}
            {/*                            order_id={order.order_id}*/}
            {/*                            ref_order_no={order.ref_order_no}*/}
            {/*                            partner_id={id}*/}
            {/*                            total_amount={order?.total_amount || 0}*/}
            {/*                            cargo_control_no={order.cargo_control_no}*/}
            {/*                            account_base={order.account_base}*/}
            {/*                            created_time={order.created_at}*/}
            {/*                            origi_shipping_item_file={order.origi_shipping_item_file}*/}
            {/*                            currency_code={order?.currency_code || "CAD"}*/}
            {/*                            isCrossBorder={order?.cross_border_shipment_type !== null}*/}
            {/*                            service_fee_include_gst={order?.service_fee_include_gst}*/}
            {/*                            total_amount_include_tax={order?.total_amount_include_tax}*/}
            {/*                        />*/}
            {/*                    );*/}
            {/*                })}*/}
            {/*                </tbody>*/}
            {/*            </table>*/}
            {/*        </Card>*/}
            {/*        <Box textAlign="right">*/}
            {/*            <Button*/}
            {/*                onClick={() =>*/}
            {/*                    history.push("/loose-item/3rd-party/shipping/order")*/}
            {/*                }*/}
            {/*                style={{*/}
            {/*                    borderRadius: 35,*/}
            {/*                    color: "#003559",*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                Show More*/}
            {/*            </Button>*/}
            {/*        </Box>*/}
            {/*    </Box>*/}
            {/*    :*/}
            {/*    <Box>*/}
            {/*        <Box sx={styles.landingPageLatestShippingOrder}>*/}
            {/*            <Box fontSize="1.5rem" mt={1} textAlign="left">*/}
            {/*                Latest shipping order:*/}
            {/*            </Box>*/}
            {/*        </Box>*/}
            {/*        <Box sx={{display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center'}}>*/}
            {/*            <Button*/}
            {/*                variant='contained'*/}
            {/*                size='small'*/}
            {/*                onClick={() => history.push('/shipping-package/quick-quote')}*/}
            {/*                className={classes.button}*/}
            {/*            >*/}
            {/*                Quick Quote*/}
            {/*            </Button>*/}
            {/*            <Button*/}
            {/*                variant='contained'*/}
            {/*                size='small'*/}
            {/*                onClick={() => history.push('/shipping-package/rating-buy/excel')}*/}
            {/*                className={classes.button}*/}
            {/*            >*/}
            {/*                Upload Excel*/}
            {/*            </Button>*/}
            {/*        </Box>*/}
            {/*    </Box>*/}
            {/*}*/}
            {/*<br/>*/}
            {/*<hr/>*/}
            {/*<Box>*/}
            {/*  <Box fontSize="1.5rem" mt={1} textAlign="left">*/}
            {/*    Latest Original Invoices from UPS/FedEX:*/}
            {/*  </Box>*/}
            {/*  <Card classes={{ root: classes.lgRoot }}>*/}
            {/*    <table className={classes.table}>*/}
            {/*      <thead>*/}
            {/*        <tr>*/}
            {/*          <th className={classes.idTH}>Invoice ID</th>*/}
            {/*          <th className={classes.idTH}>Invoice Number</th>*/}
            {/*          <th className={classes.idTH}>Account Base</th>*/}
            {/*          <th className={classes.idTH}>Invoice Date</th>*/}
            {/*          <th className={classes.idTH}>Download</th>*/}
            {/*        </tr>*/}
            {/*      </thead>*/}
            {/*      <tbody className={classes.tbody}>*/}
            {/*        {invoiceData.map((v, index) => {*/}
            {/*          return (*/}
            {/*            <PartnerInvoiceTable*/}
            {/*              key={index}*/}
            {/*              invoiceId={v.invoice_id}*/}
            {/*              invoiceNumber={v.invoice_no}*/}
            {/*              partner_id={id}*/}
            {/*              date={v.invoice_date}*/}
            {/*              accountBase={v.account_base}*/}
            {/*            />*/}
            {/*          );*/}
            {/*        })}*/}
            {/*      </tbody>*/}
            {/*    </table>*/}
            {/*  </Card>*/}
            {/*  <Box textAlign="right">*/}
            {/*    <Button*/}
            {/*      onClick={() => history.push("/original-invoices-breakdown")}*/}
            {/*      style={{*/}
            {/*        borderRadius: 35,*/}
            {/*        color: "#003559",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Show More*/}
            {/*    </Button>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<br/>*/}
            {/*Pallets Pages*/}
            {/*<Box sx={styles.landingPageCard}>*/}
            {/*<Box*/}
            {/*    className={classes.root}*/}
            {/*    mr={4}*/}
            {/*    role="button"*/}
            {/*    onClick={() => {*/}
            {/*        // history.push("/LTLs/shipping-item")*/}
            {/*        history.push("/shippingLTL/shipping-item")*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Box*/}
            {/*        display="flex"*/}
            {/*        justifyContent="center"*/}
            {/*        height="100%"*/}
            {/*        alignItems="center"*/}
            {/*    >*/}
            {/*        <PalletsIcon/>*/}
            {/*        &nbsp;&nbsp;Shipping LTL*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*    className={classes.root}*/}
            {/*    role="button"*/}
            {/*    onClick={() => {*/}
            {/*        // history.push("/LTLs/shipping-item")*/}
            {/*        dispatch(updateShippingLTLQuickQuotePlaceOrderValidate(null));*/}
            {/*        dispatch(selectShippingLTLQuickQuoteRate(null));*/}
            {/*        history.push("/LTL-quick-quote");*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Box sx={styles.landingPageCardEach}>*/}
            {/*        <PalletsIcon/>*/}
            {/*        <Typography sx={styles.landingPageCardEachText}>*/}
            {/*            LTL Quick Quote*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*    className={classes.root}*/}
            {/*    role="button"*/}
            {/*    onClick={() => history.push("/myLTLQuote/")}*/}
            {/*>*/}
            {/*    <Box sx={styles.landingPageCardEach}>*/}
            {/*        <RequestIcon/>*/}
            {/*        <Typography sx={styles.landingPageCardEachText}>*/}
            {/*            My LTL Quote*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*    className={classes.root}*/}
            {/*    role="button"*/}
            {/*    onClick={() => history.push("/myLTLs/")}*/}
            {/*>*/}
            {/*    <Box sx={styles.landingPageCardEach}>*/}
            {/*        <RequestIcon/>*/}
            {/*        <Typography sx={styles.landingPageCardEachText}>*/}
            {/*            My LTL Orders*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*    className={classes.root}*/}
            {/*    mr={4}*/}
            {/*    role="button"*/}
            {/*    onClick={() => history.push("/myBrokerages/")}*/}
            {/*>*/}
            {/*    <Box*/}
            {/*        display="flex"*/}
            {/*        justifyContent="center"*/}
            {/*        height="100%"*/}
            {/*        alignItems="center"*/}
            {/*    >*/}
            {/*        <RequestIcon/>*/}
            {/*        &nbsp;&nbsp;My Brokerages*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            {/*</Box>*/}
            {/*<hr />*/}
            {/*<Box>*/}
            {/*  <Box fontSize="1.5rem" mt={1} textAlign="left">*/}
            {/*    Latest Quote Requests:*/}
            {/*  </Box>*/}
            {/*  <Card classes={{ root: classes.lgRoot }}>*/}
            {/*    <table className={classes.table}>*/}
            {/*      <thead>*/}
            {/*        <tr>*/}
            {/*          <th className={classes.idTH}>Request Id</th>*/}
            {/*          <th className={classes.idTH}>Sender</th>*/}
            {/*          <th className={classes.idTH}>Deliver</th>*/}
            {/*          <th className={classes.idTH}>Pickup/Deliver Address Type</th>*/}
            {/*          <th className={classes.idTH}>Schedule Date</th>*/}
            {/*          <th className={classes.idTH}>Status</th>*/}
            {/*        </tr>*/}
            {/*      </thead>*/}
            {/*      <tbody className={classes.tbody}>*/}
            {/*        {palletRequestData.map((v, index) => {*/}
            {/*          return (*/}
            {/*            <PalletRequestTableLanding*/}
            {/*              key={index}*/}
            {/*              requestId={v.request_id}*/}
            {/*              pickupCity={v.pickup_city}*/}
            {/*              pickupProvince={v.pickup_province}*/}
            {/*              pickupCountry={v.pickup_country}*/}
            {/*              pickupPostalCode={v.pickup_postal_code}*/}
            {/*              pickupAddress={v.pickup_address}*/}
            {/*              deliverAddress={v.deliver_address}*/}
            {/*              deliverAddressType={v.deliver_addr_type}*/}
            {/*              deliverCity={v.deliver_city}*/}
            {/*              deliverCountry={v.deliver_country}*/}
            {/*              deliverName={v.deliver_name}*/}
            {/*              deliverPostalCode={v.deliver_postal_code}*/}
            {/*              deliverProvince={v.deliver_province}*/}
            {/*              pickupAddressType={v.pickup_addr_type}*/}
            {/*              scheduleDate={v.scheduled_pickup_date}*/}
            {/*              senderName={v.sender_name}*/}
            {/*              status={v.status_id}*/}
            {/*            />*/}
            {/*          );*/}
            {/*        })}*/}
            {/*      </tbody>*/}
            {/*    </table>*/}
            {/*  </Card>*/}
            {/*  <Box textAlign="right">*/}
            {/*    <Button*/}
            {/*      onClick={() => history.push("/myLTLs/")}*/}
            {/*      style={{*/}
            {/*        borderRadius: 35,*/}
            {/*        color: "#003559",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Show More*/}
            {/*    </Button>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<br/>*/}
            {/*<Box display="flex">*/}
            {/*  <Box*/}
            {/*      className={classes.root}*/}
            {/*      mr={4}*/}
            {/*      role="button"*/}
            {/*      onClick={() => history.push("/original-invoices-breakdown")}*/}
            {/*  >*/}
            {/*    <Box*/}
            {/*        display="flex"*/}
            {/*        justifyContent="center"*/}
            {/*        height="100%"*/}
            {/*        alignItems="center"*/}
            {/*    >*/}
            {/*      <InvoicesIcon />*/}
            {/*      Original Invoices*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<Box display="flex">*/}
            {/*  <Box*/}
            {/*    className={classes.root}*/}
            {/*    mr={4}*/}
            {/*    role="button"*/}
            {/*    onClick={() => history.push("/ocean-shipping")}*/}
            {/*  >*/}
            {/*    <Box*/}
            {/*      display="flex"*/}
            {/*      justifyContent="center"*/}
            {/*      height="100%"*/}
            {/*      alignItems="center"*/}
            {/*    >*/}
            {/*      <OceanShippingIcon />*/}
            {/*      Ocean Shipping*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*  <Box*/}
            {/*    className={classes.root}*/}
            {/*    mr={4}*/}
            {/*    role="button"*/}
            {/*    onClick={() => history.push("/ocean-bookings")}*/}
            {/*  >*/}
            {/*    <Box*/}
            {/*      display="flex"*/}
            {/*      justifyContent="center"*/}
            {/*      height="100%"*/}
            {/*      alignItems="center"*/}
            {/*    >*/}
            {/*      <BookingIcon />*/}
            {/*      &nbsp;&nbsp;My Bookings*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*  <Box*/}
            {/*    className={classes.root}*/}
            {/*    mr={4}*/}
            {/*    role="button"*/}
            {/*    onClick={() => history.push("/freight-forwarding/bol-list/created")}*/}
            {/*  >*/}
            {/*    <Box*/}
            {/*      display="flex"*/}
            {/*      justifyContent="center"*/}
            {/*      height="100%"*/}
            {/*      alignItems="center"*/}
            {/*    >*/}
            {/*      <BolIcon />*/}
            {/*      &nbsp;&nbsp;&nbsp;My BOLs*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<hr />*/}
            {/*<Box>*/}
            {/*  <Box fontSize="1.5rem" mt={1} textAlign="left">*/}
            {/*    Latest BOLs:*/}
            {/*  </Box>*/}
            {/*  <Card classes={{ root: classes.lgRoot }}>*/}
            {/*    <table className={classes.table}>*/}
            {/*      <thead>*/}
            {/*        <tr>*/}
            {/*          <th className={classes.idTH}>Bol Id / Bol #</th>*/}
            {/*          <th className={classes.idTH}>Shipper</th>*/}
            {/*          <th className={classes.idTH}>Consignee</th>*/}
            {/*          <th className={classes.idTH}>ETD / ETA</th>*/}
            {/*          <th className={classes.idTH}>Voyage</th>*/}
            {/*        </tr>*/}
            {/*      </thead>*/}
            {/*      <tbody className={classes.tbody}>*/}
            {/*        {bolOrderData?.map((order, index) => {*/}
            {/*          return (*/}
            {/*            <BolListTableLanding*/}
            {/*              key={index}*/}
            {/*              bolId={order.bol_id}*/}
            {/*              created_time={order.created_at}*/}
            {/*              shipperName={order.shipper_name}*/}
            {/*              shipperAddress={order.shipper_full_address}*/}
            {/*              consigneeAddress={order.consignee_full_address}*/}
            {/*              consigneeName={order.consignee_name}*/}
            {/*              bolNumber={order.bol_no}*/}
            {/*              etd={order.etd_date}*/}
            {/*              eta={order.eta_date}*/}
            {/*              voyage={order.voyage}*/}
            {/*            />*/}
            {/*          );*/}
            {/*        })}*/}
            {/*      </tbody>*/}
            {/*    </table>*/}
            {/*  </Card>*/}
            {/*</Box>*/}
            <Modal
                open={open}
                sx={{
                    overflow: 'scroll',
                    height: '100%',
                    display: 'block'
                }}
                // onClose={handleClose}
            >
                <Welcome/>
            </Modal>
        </Container>
    );
};
const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Montserrat",
        fontSize: "2rem",
        letterSpacing: "2px",
        color: "green",
        marginTop: "2vh",
        textAlign: "left",
    },
    root: {
        width: 150,
        height: "6rem",
        backgroundColor: "white",
        borderRadius: 5,
        cursor: "pointer",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "orange",
            color: "white",
        },
        '@media (max-width: 480px)': {
            width: 130,
        }

    },
    lgRoot: {
        minHeight: 200,
        margin: 10,
        marginLeft: 0,
        marginTop: 10,
        // width: '90%',
        width: "100%",
        '@media (max-width: 480px)': {
            margin: 0,
        }
    },
    table: {
        width: "100%",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
        textAlign: "left",
    },
    nameTH: {
        width: "100px",
    },
    idTRSmall: {
        display: 'none',
        '@media (max-width: 480px)': {
            display: 'table-row'
        }
    },

    idTRLarge: {
        '@media (max-width: 480px)': {
            display: 'none'
        }
    },

    idTH: {
        fontSize: '14px',
        fontWeight: '600'
    },
    button: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        "&:hover": {
            filter: 'brightness(0.9)',
            backgroundColor: '#1D8B45',
        },
    },
    verticalLine: {
        width: '2px',
        height: '100%',
        background: '#1D8B45'
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '5fr 5fr 5fr 5fr 6fr', // Divide into 5 equal parts
        maxHeight: '300px', // Set the desired height for the container
        gap: '5px', // You can adjust the gap between items if needed
    },
    item: {
        border: '1px solid #000000',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center'
        // backgroundColor: theme.palette.primary.main, // You can customize the color here
    },
    section: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        gap: '10px', // You can adjust the gap between items if needed
        height: '100%',
        width: '100%'
    },
    BillingSection: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px', // You can adjust the gap between items if needed
        height: '100%',
        width: '100%'
    },
    ButtonBase: {
        maxHeight: 300,
        height: 130,
        backgroundColor: '#FFFFFF',
        width: '100%',
        '&:hover': {
            transform: 'scale(1.1)',
            transition: 'transform 0.3s linear',
            backgroundColor: '#F5F5F5',
            zIndex: '1000',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        },
    },
    BillingButtonBase: {
        maxHeight: 300,
        height: 130,
        backgroundColor: '#FFFFFF',
        width: '100%',
        '&:hover': {
            transform: 'scale(1.05)',
            transition: 'transform 0.3s linear',
            backgroundColor: '#F5F5F5',
            zIndex: '1000',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        },
    }
}));
export default LandingPage;
