import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export const useGaTracker = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send(
            {hitType: 'pageview', page: location.pathname + location.search });
    }, [location]);
};
