import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Box } from "@material-ui/core";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReceiveEmailPage = () => {
  let query = useQuery();
  const history = useHistory();

  const handleClick = () => {
    history.push("/sign-in");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      fontFamily="Montserrat"
      justifyContent="center"
      alignItems="center"
      mt="30vh"
      letterSpacing="1.5px"
    >
      <Box fontSize="3rem" color="green" mb="5vh">
        Forgot Password
      </Box>
      <Box textAlign="center" color="rgb(141, 173, 155)" pb="5vh">
        {query.get("status_code") === "200" ? (
          <>
            <p>A password reset link was sent.</p>
            <p>Click the link in the email to create a new password.</p>
          </>
        ) : (
          <p>Email address not exist.</p>
        )}
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ color: "white", fontFamily: "Montserrat" }}
      >
        Go to sign in page
      </Button>
    </Box>
  );
};

export default ReceiveEmailPage;
