import React, { useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Button, Box } from "@material-ui/core";


import { FORGOT_PASSWORD } from "../../utils/apiUrl";

// import Slide from 'react-reveal';
import axios from "axios";
import CustomInput from "../../shared/customInput";

// A custom hook that builds on useLocation to parse
// the query string for you.
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const ForgotPasswordPage = () => {
  //   let query = useQuery();
  const history = useHistory();

  const [email, setEmail] = useState("");

  const handleForward = () => {
    const url = FORGOT_PASSWORD;
    const putObj = {
      email_to: email,
    };
    axios({
      method: "post",
      url: url,
      data: JSON.stringify(putObj),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => {
        history.push("/receive-email?status_code=200");
      })
      .catch((error) => {
        history.push("/receive-email?status_code=403");
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      letterSpacing="1.5px"
      justifyContent="center"
      alignItems="center"
      mt="18vh"
      fontFamily="Montserrat"
    >
      {/* <Box fontSize='3rem' color='green' mb='5vh'>
                {query.get("status_code") === '200' ? 'Email Activation Success' : 'Email Activation Failed'}
            </Box> */}
      <Box fontSize="3rem" color="green" textAlign="center">
        Forgot Password
      </Box>
      <Box fontSize="1.5rem" color="rgb(141, 173, 155)" textAlign="center">
        <p>Enter your UUCargo account email to reset your password.</p>
      </Box>
      <Box width="25vw" pt="5vh" pb="5vh" minWidth="300px">
        <CustomInput
          placeholderValue="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <Box mb="3vh">
        <Button
          variant="contained"
          color="primary"
          onClick={handleForward}
          style={{ color: "white", fontFamily: "Montserrat" }}
        >
          Reset Password
        </Button>
      </Box>
      <Box fontSize="1rem">
        <Link to="/sign-in">Return to sign in page</Link>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
