import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    extractAddress,
    extractFullAddress,
    isPositiveInteger,
    loadAsyncScript,
    provinces,
    provinces_states
} from "../../utils/Helper";
import {updateLandingEstimatePallet} from "../Actions/landingEstimateAction";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import intl from "react-intl-universal";
import Grid from "@material-ui/core/Grid";
import FormControl from "@mui/material/FormControl";
import {FormLabel} from "@mui/material";

const style = {
    EstimatePackageRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px'
    },
    EstimatePackageHeader: {
        fontSize: '20px',
        fontWeight: '600'
    },
    EstimatePackageItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        width: '100%',
    },
    EstimatePackageItemSection: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'flex-end',
        width: '100%',
        '@media (max-width: 480px)': {
            flexDirection: 'column',
        }
    },
    EstimatePalletItemSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
    },
    EstimatePalletItemSectionWarningText: {
        fontSize: '12px',
        color: '#FF0303'
    }
}

const packagingTypeOptions = [
    {
        value: '48" x 40"',
        code: 1,
        weight: 'max 500lb',
        length: '48',
        width: '40',
        height: '55'
    },
    {
        value: '48" x 48"',
        code: 2,
        weight: 'max 500lb',
        length: '48',
        width: '48',
        height: '55'
    }];

export const EstimatePallet = ({currentLocation}) => {

    const dispatch = useDispatch();

    let missingKeys = useSelector(state => state?.landingEstimate?.landingEstimatePalletMissing);

    const [quantity, setQuantity] = useState(1);
    const [pickupCity, setPickupCity] = useState();
    const [deliverCity, setDeliverCity] = useState();
    const [pickupCityPredictions, setPickupCityPredictions] = useState([]);
    const [deliverCityPredictions, setDeliverCityPredictions] = useState([]);
    const [packagingType, setPackagingType] = useState({
        value: '48" x 40"',
        code: 1,
        weight: 'max 400lb',
        length: '48',
        width: '40',
        height: '55'
    });
    const [pickupProvince, setPickupProvince] = useState(null);
    const [deliverProvince, setDeliverProvince] = useState(null);
    const [palletData, setPalletData] = useState({
        quantity: 1,
        packagingType: {
            value: '48" x 40"',
            code: 1,
            weight: 'max 400lb',
            length: '48',
            width: '40',
            height: '60'
        }
    });
    const [movingFromPostalCodePredictions, setMovingFromPostalCodePredictions] = useState([]);
    const [movingToPostalCodePredictions, setMovingToPostalCodePredictions] = useState([]);
    const [pickupLocation, setPickupLocation] = useState(null);
    const [deliverLocation, setDeliverLocation] = useState(null);
    const [quantityError, setQuantityError] = useState(null);

    const [address, setAddress] = useState();

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })

            return updatedPredictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputPickupCity = async (event, value) => {
        setPickupCity(value);
        setPalletData(prevState => ({
            ...prevState, pickupCity: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality');
        setPickupCityPredictions(predictions);
    }

    const handleChangePickupCity = async (event, value) => {
        console.log('select', value);
        setPickupLocation(value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setPickupCity(address?.city);
            setPickupProvince(prevState => ({
                ...prevState,
                name: address?.state?.name,
                code: address?.state?.code,
                country: address?.country?.name,
                countryCode: address?.country?.code
            }));
            setPalletData(prevState => ({
                ...prevState,
                pickupCity: address?.city,
                pickupProvince: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                    country: address?.country?.name,
                    countryCode: address?.country?.code
                }
            }))
        }
    }

    const handleInputDeliverCity = async (event, value) => {
        setDeliverCity(value);
        setPalletData(prevState => ({
            ...prevState, deliverCity: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality');
        setDeliverCityPredictions(predictions);
    }

    const handleChangeDeliverCity = async (event, value) => {
        console.log('select', value);
        setDeliverLocation(value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setDeliverCity(address?.city);
            setDeliverProvince(prevState => ({
                ...prevState,
                name: address?.state?.name,
                code: address?.state?.code,
                country: address?.country?.name,
                countryCode: address?.country?.code
            }));
            setPalletData(prevState => ({
                ...prevState,
                deliverCity: address?.city,
                deliverProvince: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                    country: address?.country?.name,
                    countryCode: address?.country?.code
                }
            }))
        }
    }

    const handleQuantity = e => {
        const value = e.target.value;
        const isPositive = isPositiveInteger(e.target.value);
        setQuantity(value);
        setPalletData(prevState => ({
            ...prevState, quantity: value
        }));
        if (isPositive) {
            setQuantityError(false);
        } else {
            setQuantityError(true);
        }
    }

    const handlePackingType = (event, value) => {
        setPackagingType(value);
        setPalletData(prevState => ({
            ...prevState, packagingType: value
        }))
    }

    const handlePickupProvinceChange = (event, value) => {
        setPickupProvince(value);
        setPalletData(prevState => ({
            ...prevState, pickupProvince: value
        }))
    };

    const handlePickupCity = e => {
        const value = e.target.value;
        setPickupCity(value);
        setPalletData(prevState => ({
            ...prevState, pickupCity: value
        }));
    }

    const handleDeliverProvinceChange = (event, value) => {
        setDeliverProvince(value);
        setPalletData(prevState => ({
            ...prevState, deliverProvince: value
        }))
    };

    const handleDeliverCity = e => {
        const value = e.target.value;
        setDeliverCity(value);
        setPalletData(prevState => ({
            ...prevState, deliverCity: value
        }));
    }

    const handleInputShipFromPostalCode = async (event, value) => {
        setPalletData(prevState => ({
            ...prevState,
            pickupPostalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        console.log('handleInputShipFromPostalCode - predictions', predictions);
        const filterPredictions = predictions?.filter(address => address?.description?.includes(palletData?.pickupCity));
        console.log('local state pallet data filterPredictions', filterPredictions);
        setMovingFromPostalCodePredictions(filterPredictions);
    }

    const handleInputShipToPostalCode = async (event, value) => {
        setPalletData(prevState => ({
            ...prevState,
            deliverPostalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        console.log('handleInputShipFromPostalCode - predictions', predictions);
        const filterPredictions = predictions?.filter(address => address?.description?.includes(palletData?.deliverCity));
        console.log('local state pallet data filterPredictions', filterPredictions);
        setMovingToPostalCodePredictions(filterPredictions);
    }

    // console.log('pick up city prediction', pickupCityPredictions);
    console.log('pick up city', pickupCity);
    console.log('local state pallet data', palletData);

    console.log('pick up province', pickupProvince);
    // console.log('current location', currentLocation);
    // console.log('redux store missing keys', missingKeys);

    useEffect(() => {
        dispatch(updateLandingEstimatePallet(palletData));
    }, [palletData])
    //
    // useEffect(async () => {
    //     console.log('current location', currentLocation);
    //
    //     const predictions = await googleMapsPlaceAutocomplete(currentLocation?.city, 'locality');
    //     setPickupCityPredictions(predictions);
    //
    //     setPickupCity(prevState => ({
    //         ...prevState,
    //         description: `${currentLocation?.city}, ${currentLocation?.province?.code}, ${currentLocation?.country?.name}`,
    //         text: currentLocation?.city
    //     }));
    //     setPickupProvince(currentLocation?.province);
    //     setPalletData(prevState => ({
    //         ...prevState,
    //         pickupProvince: currentLocation?.province,
    //         pickupCity: currentLocation?.city,
    //     }))
    // }, [currentLocation])

    return (
        <Box sx={style.EstimatePackageRoot}>
            <Box sx={style.EstimatePackageItem}>
                <Typography style={style.EstimatePackageHeader}>
                    {intl.get('LANDING_PAGE.CALCULATOR.SHIPPING_PALLET')}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={6}>
                        <TextField
                            value={'48" x 40"'}
                            label={intl.get('LANDING_PAGE.CALCULATOR.TYPE')}
                            required
                            fullWidth
                            variant="standard"
                            disabled={true}
                        />
                        {/*<Box sx={style.EstimatePalletItemSectionBox}>*/}
                        {/*    <Autocomplete*/}
                        {/*        value={packagingType}*/}
                        {/*        options={packagingTypeOptions}*/}
                        {/*        fullWidth*/}
                        {/*        getOptionLabel={(option) => option.value}*/}
                        {/*        onChange={handlePackingType}*/}
                        {/*        renderInput={(params) => <TextField*/}
                        {/*            variant="standard"*/}
                        {/*            required*/}
                        {/*            {...params}*/}
                        {/*            label={intl.get('LANDING_PAGE.CALCULATOR.TYPE')}/>*/}
                        {/*        }*/}
                        {/*        renderOption={option => <Typography>*/}
                        {/*            {option?.value} | {option?.weight}*/}
                        {/*        </Typography>}*/}
                        {/*    />*/}
                        {/*    <Typography sx={style.EstimatePalletItemSectionWarningText}>*/}
                        {/*        {missingKeys.includes('packagingType') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : ''}*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <TextField
                            value={'60"'}
                            label={intl.get('LANDING_PAGE.CALCULATOR.HEIGHT')}
                            required
                            fullWidth
                            variant="standard"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <TextField
                            value={'400 lbs'}
                            label={intl.get('LANDING_PAGE.CALCULATOR.WEIGHT')}
                            required
                            fullWidth
                            variant="standard"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Box sx={style.EstimatePalletItemSectionBox}>
                            <TextField
                                value={quantity}
                                label={intl.get('LANDING_PAGE.CALCULATOR.QUANTITY')}
                                type="number"
                                required
                                fullWidth
                                variant="standard"
                                disabled={true}
                            />
                            {/*<TextField*/}
                            {/*    value={quantity}*/}
                            {/*    label={intl.get('LANDING_PAGE.CALCULATOR.QUANTITY')}*/}
                            {/*    type="number"*/}
                            {/*    required*/}
                            {/*    fullWidth*/}
                            {/*    variant="standard"*/}
                            {/*    onInput={handleQuantity}*/}
                            {/*    disabled={true}*/}
                            {/*/>*/}
                            {/*<Typography sx={style.EstimatePalletItemSectionWarningText}>*/}
                            {/*    {missingKeys.includes('quantity') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : quantityError ? intl.get('LANDING_PAGE.CALCULATOR.POSITIVE_INTEGER') : ''}*/}
                            {/*</Typography>*/}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Box sx={style.EstimatePackageItem}>
                        <Typography style={style.EstimatePackageHeader}>
                            {intl.get('LANDING_PAGE.CALCULATOR.PICKUP')}
                        </Typography>
                        <Box sx={style.EstimatePackageItemSection}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={style.EstimatePalletItemSectionBox}>
                                        <Autocomplete
                                            // freeSolo
                                            options={pickupCityPredictions}
                                            getOptionLabel={option => option?.text}
                                            onInputChange={handleInputPickupCity}
                                            onChange={handleChangePickupCity}
                                            fullWidth
                                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                            renderInput={params => <TextField
                                                {...params}
                                                variant='standard'
                                                label={intl.get('LANDING_PAGE.CALCULATOR.CITY')}
                                                required
                                            />}
                                            renderOption={(option) => (
                                                <Typography
                                                    style={{fontSize: '12px'}}
                                                >
                                                    {/*<Typography>*/}
                                                    {option.description}
                                                    {/*</Typography>*/}
                                                </Typography>
                                            )}
                                        />
                                        <Typography sx={style.EstimatePalletItemSectionWarningText}>
                                            {missingKeys.includes('pickupCity') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : ''}
                                        </Typography>
                                    </Box>
                                </Grid>
                                {/*<Grid item xs={6}>*/}
                                {/*    <Box sx={style.EstimatePalletItemSectionBox}>*/}
                                {/*        <Autocomplete*/}
                                {/*            value={pickupProvince}*/}
                                {/*            options={provinces_states}*/}
                                {/*            groupBy={(option) => option.country}*/}
                                {/*            fullWidth*/}
                                {/*            getOptionLabel={(option) => option.code}*/}
                                {/*            onChange={handlePickupProvinceChange}*/}
                                {/*            getOptionSelected={(option, value) => option.name === value.name}*/}
                                {/*            renderInput={(params) => <TextField*/}
                                {/*                {...params}*/}
                                {/*                label={intl.get('LANDING_PAGE.CALCULATOR.PROVINCE')}*/}
                                {/*                required*/}
                                {/*                variant="standard"/>*/}
                                {/*            }*/}
                                {/*            renderOption={(option) => (*/}
                                {/*                <div>*/}
                                {/*                    {option.name}*/}
                                {/*                </div>*/}
                                {/*            )}*/}
                                {/*        />*/}
                                {/*        <Typography sx={style.EstimatePalletItemSectionWarningText}>*/}
                                {/*            {missingKeys.includes('pickupProvince') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : ''}*/}
                                {/*        </Typography>*/}
                                {/*    </Box>*/}
                                {/*</Grid>*/}
                                {
                                    (palletData?.deliverProvince?.countryCode?.toLowerCase() === 'us' || palletData?.pickupProvince?.countryCode?.toLowerCase() === 'us') &&
                                    <Grid item xs={12}>
                                        <Box sx={style.EstimatePalletItemSectionBox}>
                                            <Autocomplete
                                                options={movingFromPostalCodePredictions}
                                                getOptionLabel={option => option?.text}
                                                onInputChange={handleInputShipFromPostalCode}
                                                fullWidth
                                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                                renderInput={params => <TextField
                                                    {...params}
                                                    label={intl.get('LANDING_PAGE.CALCULATOR.POSTAL_CODE')}
                                                    required
                                                    variant='standard'
                                                />}
                                                renderOption={(option) => (
                                                    <Typography
                                                        style={{fontSize: '12px'}}
                                                    >
                                                        {option.description}
                                                    </Typography>
                                                )}
                                            />
                                            <Typography sx={style.EstimatePalletItemSectionWarningText}>
                                                {missingKeys.includes('pickupPostalCode') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : ''}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {pickupLocation?.description}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={style.EstimatePackageItem}>
                        <Typography style={style.EstimatePackageHeader}>
                            {intl.get('LANDING_PAGE.CALCULATOR.DESTINATION')}
                        </Typography>
                        <Box sx={style.EstimatePackageItemSection}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={style.EstimatePalletItemSectionBox}>
                                        <Autocomplete
                                            // freeSolo
                                            options={deliverCityPredictions}
                                            getOptionLabel={option => option?.text}
                                            onInputChange={handleInputDeliverCity}
                                            onChange={handleChangeDeliverCity}
                                            fullWidth
                                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                            renderInput={params => <TextField
                                                {...params}
                                                variant='standard'
                                                label={intl.get('LANDING_PAGE.CALCULATOR.CITY')}
                                                required
                                            />}
                                            renderOption={(option) => (
                                                <Typography
                                                    style={{fontSize: '12px'}}
                                                >
                                                    {/*<Typography>*/}
                                                    {option.description}
                                                    {/*</Typography>*/}
                                                </Typography>
                                            )}
                                        />
                                        <Typography sx={style.EstimatePalletItemSectionWarningText}>
                                            {missingKeys.includes('deliverCity') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : ''}
                                        </Typography>
                                    </Box>
                                </Grid>
                                {/*<Grid item xs={6}>*/}
                                {/*    <Box sx={style.EstimatePalletItemSectionBox}>*/}
                                {/*        <Autocomplete*/}
                                {/*            value={deliverProvince}*/}
                                {/*            options={provinces_states}*/}
                                {/*            groupBy={(option) => option.country}*/}
                                {/*            fullWidth*/}
                                {/*            getOptionLabel={(option) => option.code}*/}
                                {/*            onChange={handleDeliverProvinceChange}*/}
                                {/*            renderInput={(params) => <TextField*/}
                                {/*                {...params}*/}
                                {/*                label={intl.get('LANDING_PAGE.CALCULATOR.PROVINCE')}*/}
                                {/*                required*/}
                                {/*                variant="standard"/>*/}
                                {/*            }*/}
                                {/*            renderOption={(option) => (*/}
                                {/*                <div>*/}
                                {/*                    {option.name}*/}
                                {/*                </div>*/}
                                {/*            )}*/}
                                {/*        />*/}
                                {/*        <Typography sx={style.EstimatePalletItemSectionWarningText}>*/}
                                {/*            {missingKeys.includes('deliverProvince') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : ''}*/}
                                {/*        </Typography>*/}
                                {/*    </Box>*/}
                                {/*</Grid>*/}
                                {
                                    (palletData?.deliverProvince?.countryCode?.toLowerCase() === 'us' || palletData?.pickupProvince?.countryCode?.toLowerCase() === 'us') &&
                                    <Grid item xs={12}>
                                        <Box sx={style.EstimatePalletItemSectionBox}>
                                            <Autocomplete
                                                options={movingToPostalCodePredictions}
                                                getOptionLabel={option => option?.text}
                                                onInputChange={handleInputShipToPostalCode}
                                                fullWidth
                                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                                renderInput={params => <TextField
                                                    {...params}
                                                    label={intl.get('LANDING_PAGE.CALCULATOR.POSTAL_CODE')}
                                                    required
                                                    variant='standard'
                                                />}
                                                renderOption={(option) => (
                                                    <Typography
                                                        style={{fontSize: '12px'}}
                                                    >
                                                        {option.description}
                                                    </Typography>
                                                )}
                                            />
                                            <Typography sx={style.EstimatePalletItemSectionWarningText}>
                                                {missingKeys.includes('deliverPostalCode') ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : ''}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {deliverLocation?.description}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}