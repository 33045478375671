import React, { useState, useEffect } from "react";
import GooglePlaceInput from "./googlePlacesAutoComplete";

// import DateFnsUtils from '@date-io/date-fns';
// import {format, setDate} from 'date-fns'
// import {dateFormat} from 'dateformat';
// import { MuiPickersUtilsProvider, Calendar, DatePicker } from "@material-ui/pickers";
// import enGBLocale from "date-fns/locale/en-GB";
// import { createMuiTheme } from '@material-ui/core/styles';
// import { Store, Home, Business, ArrowForwardIos } from '@material-ui/icons';
import { Box, TextField } from "@material-ui/core";
// import {countryList, canadaProvincesList, americaProvinceList} from "../constInfo";
import CustomInput from "../../../../shared/customInput";
import {americaProvinceList, canadaProvincesList, countryList} from "../../../../shared/constInfo";
// import { makeStyles } from "@material-ui/core/styles";
// import { propTypes } from 'react-bootstrap/esm/Image';
// import CustomInput from "../CustomInput";
// import CustomInput from "../customInput";
// import getProvinceCodeByName from "../../utils/getProvinceCode";

export default function AddressInputCombo({
  handleChange,
  handleChangeFromSelection,
  addressObject,
  pickUp = false,
  userLocation,
  // freeSolo = false,
  checkError = false,
  readOnly= false,
  addressInputValue,
  setAddressInputValue,
  aptInfoInputValue,
  setAptInfoInputValue,
  setPostalCodeInputValue,
  cityInputValue,
  postalCodeInputValue,
  provinceInputValue,
  setCityInputValue,
  setProvinceInputValue,
  defaultCity,
  isAcceptQuote
}) {
  // const classes = useStyles();

  const [cityObj, setCityObj] = useState({ city: defaultCity || null });
  const [provinceList, setProvinceList] = useState(addressObject?.country !== "CA" ? americaProvinceList : canadaProvincesList);

  useEffect(() => {
    if (addressObject.country === "US") {
      setProvinceList(americaProvinceList);
      return;
    }
    setProvinceList(canadaProvincesList);
  }, [addressObject.country])

  useEffect(() => {
    userLocation?.city && setCityObj({ city: userLocation.city });
  }, [userLocation?.city]);

  const [postalObj, setPostalObj] = useState({ zipCode: "" });

  useEffect(() => {
    userLocation?.zipCode && setPostalObj({ zipCode: userLocation.zipCode });
  }, [userLocation?.zipCode]);

  useEffect(() => {
    console.log("Address Object:", addressObject);
  }, [addressObject]);


  return (
    <Box>
      <Box mr={1} mb={1}>
        <CustomInput
            error={pickUp && checkError && !addressObject.name}
            onChange={handleChange("name")}
            // value={provinces.find(ele => ele.value === addressObject.province) || ''}
            value={addressObject.name}
            type="line"
            label="Name (Optional)"
            disabled={readOnly}
            minLength={3}
            checkError={checkError}
            placeholderValue="Name"
        />
      </Box>
      <Box mr={1} mb={1}>
        <CustomInput
            error={pickUp && checkError && !addressObject.companyName}
            onChange={handleChange("companyName")}
            // value={provinces.find(ele => ele.value === addressObject.province) || ''}
            value={addressObject.companyName}
            type="line"
            label="Company Name (Optional)"
            disabled={readOnly}
            minLength={3}
            checkError={checkError}
            placeholderValue="Company Name"
        />
      </Box>
      <Box mr={1} mb={1}>
        <CustomInput
          error={pickUp && checkError && !addressObject.phone}
          onChange={handleChange("phone")}
          // value={provinces.find(ele => ele.value === addressObject.province) || ''}
          value={addressObject.phone}
          type="line"
          label="Phone (Optional)"
          minLength={10}
          maxLength={14}
          checkError={checkError}
          disabled={readOnly}
          placeholderValue="e.g 778-123-1234.  Length between 10-14"
        />
      </Box>
      <Box mr={1} mb={1}>
        <CustomInput
            error={pickUp && checkError && !addressObject.email}
            onChange={handleChange("email")}
            // value={provinces.find(ele => ele.value === addressObject.province) || ''}
            value={addressObject.email}
            type="line"
            label="Email (Optional)"
            disabled={readOnly}
            minLength={3}
            checkError={checkError}
            placeholderValue="Email"
        />
      </Box>
      <Box mr={1} mb={1}>
        <GooglePlaceInput
          type="street"
          handleChange={handleChangeFromSelection("address")}
          setProvinceValue={handleChangeFromSelection("province")}
          setCityValueUpperLevel={handleChangeFromSelection("city")}
          setZipcodeValueUpperLevel={handleChangeFromSelection("zipCode")}
          setPlaceId={handleChangeFromSelection("placeId")}
          setCityValue={setCityObj}
          setZipcodeValue={setPostalObj}
          setCountryValue={handleChangeFromSelection("country")}
          addressValue={userLocation?.address}
          checkError={checkError}
          value={addressInputValue}
          setValue={setAddressInputValue}
          isAcceptQuote={isAcceptQuote}
          readOnly={isAcceptQuote}
        />
      </Box>
      <Box mr={1} display="flex" mb={1}>
        <Box flexBasis="50%" mr={1}>
          <TextField
            onChange={handleChange("aptInfo")}
            value={addressObject.aptInfo}
            label="Apartment, suite, etc (optional)"
            fullWidth
            disabled={isAcceptQuote}
          />
        </Box>
        <Box flexBasis="50%" ml={1}>
          <GooglePlaceInput
            type="postal"
            handleChange={handleChangeFromSelection("zipCode")}
            zipCodeValue={postalObj}
            readOnly={isAcceptQuote}
            checkError={checkError}
            value={postalCodeInputValue}
            setValue={setPostalCodeInputValue}
          />
        </Box>
      </Box>
      <Box mr={1} display="flex">
        <Box mr={1} flexBasis="50%">
          <GooglePlaceInput
            type="city"
            handleChange={handleChangeFromSelection("city")}
            // cityValue={userLocation?.city}
            setProvinceValue={handleChangeFromSelection("province")}
            setCountryValue={handleChangeFromSelection("country")}
            cityValue={cityObj}
            checkError={checkError}
            readOnly={isAcceptQuote}
            value={cityInputValue}
            setValue={setCityInputValue}
          />
        </Box>
        <Box flexBasis="30%">
          <CustomInput
            onChange={handleChangeFromSelection("province")}
            // value={provincesList.find(ele => ele.value === addressObject.province || '')}
            value={addressObject.province}
            label="Province/State *"
            fullWidth
            inputWithSelect
            options={provinceList}
            checkError={checkError && !addressObject.province}
            type="inputWithSelect"
            disabled={isAcceptQuote}
          />
        </Box>
        <Box flexBasis="20%">
          <CustomInput
              onChange={handleChangeFromSelection("country")}
              value={addressObject.country}
              label="Country *"
              fullWidth
              inputWithSelect
              options={countryList}
              checkError={checkError && (!addressObject.country || addressObject.country?.trim() === "")}
              type="inputWithSelect"
              disabled={isAcceptQuote}
          />
        </Box>
      </Box>
    </Box>
  );
}
