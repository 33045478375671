import React, {useEffect, useRef, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Tooltip} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import {CSVLink} from "react-csv";
import xlsx from "json-as-xlsx";
import CurrencyFormat from "react-currency-format";
import {separateTaxes} from "../../utils/calculateTax";
import dayjs from "dayjs";
import PublicIcon from '@mui/icons-material/Public';
import {saveAs} from 'file-saver';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import {useDispatch, useSelector} from "react-redux";
import {landingPageLatestOrderUpdateOpenIndex} from "../Actions/landingPageAction";
import Button from "@mui/material/Button";
import TrackPackageItem from "../Tracking/TrackPackageItem";
import {getAccessToken} from "../../utils/doToken";

const useStyles = makeStyles((theme) => ({
    listItemWrapper: {
        width: "100%",
        margin: "3px auto",
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
        lineHeight: "2rem"
    },
    idTRLarge: {
        '@media (max-width: 480px)': {
            display: 'none'
        }
    },
}));

const styles = {
    ShippingOrderListTableLandingSectionLarge: {
        '@media (max-width: 480px)': {
            display: 'none'
        }
    },
    ShippingOrderListTableLandingSectionSmall: {
        display: 'none',
        '@media (max-width: 480px)': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    ShippingOrderListTableLandingText: {
        fontSize: '14px'
    },
    ShippingOrderListTableLandingMoreSection: {
        position: 'relative'
    },
    ShippingOrderListTableLandingAction: {
        position: 'absolute',
        background: '#FFFFFF',
        left: '-300%',
        top: '0',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1000,
        borderRadius: '5px',
        padding: '0 10px',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
    },
    ShippingOrderListTableLandingActionEach: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}

export default function ShippingOrderListTableLanding({
                                                          index,
                                                          order_id,
                                                          partner_id,
                                                          ref_order_no,
                                                          cargo_control_no,
                                                          account_base,
                                                          total_amount,
                                                          origi_shipping_item_file,
                                                          created_time,
                                                          currency_code,
                                                          isCrossBorder,
                                                          total_amount_include_tax,
                                                          service_fee_include_gst
                                                      }) {
    const classes = useStyles();
    // const [transactions, setTransactions] = useState([
    //     ["Order ID", "Cargo Control Number", "Ref Order No", "Tran ID", "Account Base", "Client Number", "Product ID", "Exclude Tax", "Include Tax", "GST", "HST", "QST", "Date", "Tracking #", "Labels"],
    // ]);
    const [transactions, setTransactions] = useState([
        [
            "Order ID",
            "Cargo Control Number",
            "Ref Order No",
            "Tran ID",
            "Carrier",
            "Client Number",
            "Product ID",
            "Exclude Tax",
            "Include Tax",
            "GST",
            "HST",
            "QST",
            "Total Brokerage Charge",
            "Total Duties Charge",
            "Total Duties Tax Charge",
            "Service Fee Exclude Tax",
            "Service Fee Include Tax",
            "Shipping Total Tax",

            "Freight Charge",
            "SurCharge",
            "Fuel Charge",
            "Collect Charge",
            "Residential Charge",
            "Rural Charge",
            "Extended Area Charge",
            "Extra Care Charge",
            "Handling Charge",
            "Over Size Charge",
            "Over Weight Charge",
            "COD Charge",
            "COS Charge",
            "Dangerous Charge",
            "Surcharge Details",
            "Date",
            "Tracking #",
            "Labels",

            "Send From Name",
            // "Send From Company",
            "Send From Address",
            "Send From City",
            "Send From Province",
            "Send From Country",
            "Send From Postal Code",

            "Send To Name",
            // "Send To Company",
            "Send To Phone",
            "Send To Email",
            "Send To Address",
            "Send To City",
            "Send To Province",
            "Send To Country",
            "Send To Postal Code"
        ],
    ]);

    const [trackings, setTrackings] = useState([
        // ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Order Total Amount", "Transaction Amount", "Currency", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
        ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
    ])

    const excelFields = {
        "Order ID": "",
        "Cargo Control Number": "",
        "Ref Order No": "",
        "Tran ID": "",
        "Account Base": "",
        "Client ID": "",
        "Product ID": "",
        "Net Price": "",
        "Date": "",
        "Labels": "",
    }

    const [openAction, setOpenAction] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] = useState(false);
    const [trackingList, setTrackingList] = useState([]);

    let openIndex = useSelector(state => state?.landingPage?.latestOrderOpenIndex);
    const dispatch = useDispatch();

    console.log('open index', openIndex);

    console.log('key', index);

    console.log('open action', openAction);

    const csvLink = useRef();
    const csvTrackingLink = useRef();
    const history = useHistory();

    const toggleOpenAction = () => {
        setOpenAction(!openAction);
        dispatch(landingPageLatestOrderUpdateOpenIndex(index));
    }

    //handle tracking information dialog open and close
    const handleTrackingInfo = () => {
        setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
    };

    const handleTrackingInfoClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
        }
    }

    useEffect(() => {
        openIndex !== index && setOpenAction(false);
    }, [openIndex])

    const getTrackingList = async () => {
        const storedToken = getAccessToken("access_token");
        const requestURL = `${PARTNER_URI}/loose-item/3rd-party/getAllTrackingForShippingOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    orderId: order_id
                }
            })
            console.log('result --> getTrackingList', result)
            const trackingData = result?.data?.filter((v) => v?.trackingResponse !== null);
            setTrackingList(trackingData);
        } catch (e) {
            console.log(e.response);
        }
    }

    useEffect(() => {
        getTrackingList();
    }, [])

    const getTransactionData = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getTransactionsByOrderId?partner_id=${partner_id}&order_id=${order_id}&account_base=${account_base}`);
            const result = data.result;
            // const resultArray = [
            //     ["Order ID", "Cargo Control Number", "Ref Order No", "Tran ID", "Account Base", "Client Number", "Product ID", "Exclude Tax", "Include Tax", "GST", "HST", "QST", "Date", "Tracking #", "Labels"],
            // ];
            const resultArray = [
                [
                    "Order ID",
                    "Cargo Control Number",
                    "Ref Order No",
                    "Tran ID",
                    "Carrier",
                    "Client Number",
                    "Product ID",
                    "Exclude Tax",
                    "Include Tax",
                    "GST",
                    "HST",
                    "QST",
                    "Total Brokerage Charge",
                    "Total Duties Charge",
                    "Total Duties Tax Charge",
                    "Service Fee Exclude Tax",
                    "Service Fee Include Tax",
                    "Shipping Total Tax",

                    "Freight Charge",
                    "SurCharge",
                    "Fuel Charge",
                    "Collect Charge",
                    "Residential Charge",
                    "Rural Charge",
                    "Extended Area Charge",
                    "Extra Care Charge",
                    "Handling Charge",
                    "Over Size Charge",
                    "Over Weight Charge",
                    "COD Charge",
                    "COS Charge",
                    "Dangerous Charge",
                    "Surcharge Details",
                    "Date",
                    "Tracking #",
                    "Labels",

                    "Send From Name",
                    // "Send From Company",
                    "Send From Address",
                    "Send From City",
                    "Send From Province",
                    "Send From Country",
                    "Send From Postal Code",

                    "Send To Name",
                    // "Send To Company",
                    "Send To Phone",
                    "Send To Email",
                    "Send To Address",
                    "Send To City",
                    "Send To Province",
                    "Send To Country",
                    "Send To Postal Code"
                ],
            ];
            for (const column of result) {
                const columnData = [];
                columnData.push(column.order_id);
                columnData.push(column.cargo_control_no);
                columnData.push(column.ref_order_no);
                columnData.push(column.tran_id);
                columnData.push(column.account_base);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                // Swap Price
                columnData.push(column.amount_gross);
                columnData.push(column.amount);

                const taxesObject = separateTaxes({
                    excludeTaxAmount: column.amount_gross,
                    includeTaxAmount: column.amount
                })
                // // GST
                // columnData.push(taxesObject.gstTax);
                // // PST
                // columnData.push(taxesObject.pstTax);
                // columnData.push(taxesObject["GST/HST"]);
                columnData.push(taxesObject.gstTax);
                columnData.push(taxesObject.hstTax);
                columnData.push(taxesObject.qstTax);

                columnData.push(column.total_brokerage_charge);
                columnData.push(column.total_duties_charge);
                columnData.push(column.total_duties_taxes_charge);
                columnData.push(column.service_fee_exclude_tax);
                columnData.push(column.service_fee_include_gst);
                columnData.push(column.shipping_total_tax);

                columnData.push(column.freight_charge);
                columnData.push(column.sur_charge);
                columnData.push(column.fuel_surcharge);
                columnData.push(column.collect_charge);
                columnData.push(column.residential_charge);
                columnData.push(column.rural_charge);
                columnData.push(column.extended_area_charge);
                columnData.push(column.extra_care_charge);
                columnData.push(column.handling_charge);
                columnData.push(column.over_size_charge);
                columnData.push(column.over_weight_charge);
                columnData.push(column.cod_charge);
                columnData.push(column.cos_charge);
                columnData.push(column.dangerous_charge);
                columnData.push(column?.surcharge_details || "");

                columnData.push(column.tranacted_at);
                columnData.push(column.tran_tracking_nos);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");

                // Send From Info
                columnData.push(column.send_from_name);
                columnData.push(column.send_from_addr);
                columnData.push(column.send_from_city);
                columnData.push(column.send_from_province);
                columnData.push(column.send_from_country);
                columnData.push(column.send_from_postal_code);

                // Send To Info
                columnData.push(column.send_to_name);
                columnData.push(column.send_to_tel);
                columnData.push(column.send_to_email);
                columnData.push(column.send_to_addr);
                columnData.push(column.send_to_city);
                columnData.push(column.send_to_province);
                columnData.push(column.send_to_country);
                columnData.push(column.send_to_postal_code);

                resultArray.push(columnData);
            }
            setTransactions(resultArray);
            console.log(resultArray);
            csvLink.current.link.click()
        } catch (e) {
            console.log(e);
        }
    }

    const getTrackingsData = async () => {
        try {
            const {data} = await axios.post(`${PARTNER_URI}/shipping/list/order/trackings`, {
                partnerId: partner_id,
                orderId: order_id,
            });
            const result = data.result;
            const resultArray = [];
            for (const column of result) {
                const columnData = [];
                columnData.push(order_id);
                columnData.push(ref_order_no);
                columnData.push(cargo_control_no);
                columnData.push(account_base);
                columnData.push(column.tracking_no);
                // columnData.push(column.total_amount);
                // columnData.push(column.amount);
                // columnData.push(column.currency_code);
                columnData.push(column.tran_id);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");
                columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
                columnData.push(column.tranacted_at);

                resultArray.push(columnData);
            }
            setTrackings(v => {
                const temp = [...v];
                temp.push(...resultArray);
                return temp;
            });
            console.log(resultArray);
            csvTrackingLink.current.link.click()
        } catch (e) {
            console.log(e);
        }
    }

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const getTransactionDataExcel = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getTransactionsByOrderId?partner_id=${partner_id}&order_id=${order_id}&account_base=${account_base}`);
            const result = data.result;
            const resultArray = [];
            for (const column of result) {
                const columnData = {...excelFields};
                columnData["Order ID"] = order_id;
                columnData["Cargo Control Number"] = column.cargo_control_no;
                columnData["Ref Order No"] = ref_order_no;
                columnData["Tran ID"] = column.tran_id;
                columnData["Account Base"] = account_base;
                columnData["Client ID"] = column.ref_other_no;
                columnData["Product ID"] = column.ref_product_no;
                columnData["Net Price"] = column.amount;
                columnData["Date"] = column.tranacted_at;
                // columnData["Label PDF"] = column.label_pdf_uri ? `=HYPERLINK("https://storage.googleapis.com/${column.label_pdf_uri}")` : "N/A";
                // columnData["ZIP Label"] = column.label_zip_uri ? `=HYPERLINK("https://storage.googleapis.com/${column.label_zip_uri}")` : "N/A";
                columnData["Labels"] = getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A";
                resultArray.push(columnData);
            }
            console.log(resultArray);
            const excelBody = [
                {
                    sheet: "Transactions",
                    columns: [
                        {
                            label: "Order ID",
                            value: "orderId"
                        },
                        {
                            label: "Cargo Control Number",
                            value: "cargoControlNumber"
                        },
                        {
                            label: "Ref Order No",
                            value: "refOrderNumber"
                        },
                        {
                            label: "Tran ID",
                            value: "tranId"
                        },
                        {
                            label: "Account Base",
                            value: "accountBase"
                        },
                        {
                            label: "Client ID",
                            value: "clientId",
                        },
                        {
                            label: "Product ID",
                            value: "productId"
                        },
                        {
                            label: "Net Price",
                            value: "netPrice"
                        },
                        {
                            label: "Date",
                            value: "date"
                        },
                        {
                            label: "Labels",
                            value: "labels"
                        }
                    ],
                    content: resultArray.map(v => {
                        return {
                            orderId: v["Order ID"],
                            cargoControlNumber: v["Cargo Control Number"],
                            refOrderNumber: v["Ref Order No"],
                            tranId: v["Tran ID"],
                            accountBase: v["Account Base"],
                            clientId: v["Client ID"],
                            productId: v["Product ID"],
                            netPrice: v["Net Price"],
                            date: v["Date"],
                            labels: v["Labels"]
                        }
                    })
                }
            ]
            let settings = {
                fileName: `${order_id}-${ref_order_no}`,
            }
            // excelLink.current.link.click()
            xlsx(excelBody, settings)
        } catch (e) {
            console.log(e);
        }
    }
    const goToCrossBorderPage = () => {
        history.push(`/commercial-invoice/${order_id}`);
    }

    const downloadLabelIntoZip = async () => {
        try {
            const accessToken = getAccessToken("access_token");
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getLabelZipByOrderId?partner_id=${partner_id}&order_id=${order_id}&account_base=${account_base}`, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const blobData = new Blob([data], {
                type: "application/zip"
            });
            saveAs(blobData, `${partner_id}-${order_id}.zip`);
            // console.log("labels: ", labels);
            // saveZip("my_project_files_to_download.zip", labels);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <Dialog
                open={trackingInfoDialogIsOpen}
                onClose={handleTrackingInfoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Tracking Details</DialogTitle>
                <DialogContent>
                    {trackingList?.length === 0 ? <Box>No Trackng Information Found</Box> :
                        trackingList.map((trackedPackage, index) => {
                            return (
                                <TrackPackageItem
                                    key={index}
                                    activity={
                                        trackedPackage?.trackingResponse
                                            ? trackedPackage?.trackingResponse?.activity
                                            : undefined
                                    }
                                    trackingNo={trackedPackage?.trackingNumber}
                                    courier={trackedPackage?.accountBase}
                                    deliverStatus={trackedPackage?.trackingResponse?.status}
                                    transaction={trackedPackage?.transaction}
                                />
                            );
                        })}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleTrackingInfo}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
            <tr className={classes.listItemWrapper}>
                <td>
                    {
                        isCrossBorder && (
                            <Tooltip title="Edit Commercial Invoices For Cross-Boarder Order" placement="top">
                                <IconButton color="primary" aria-label="Cross Border" onClick={goToCrossBorderPage}>
                                    <PublicIcon/>
                                </IconButton>
                            </Tooltip>
                        )
                    }
                    <Box sx={styles.ShippingOrderListTableLandingSectionLarge}>
                        <Link
                            to={`/loose-item/order-dashboard/${order_id}`}>{order_id}
                        </Link>
                        / {ref_order_no} / {cargo_control_no}
                    </Box>
                    <Box sx={styles.ShippingOrderListTableLandingSectionSmall}>
                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                            <Link
                                to={`/loose-item/order-dashboard/${order_id}`}>{order_id}
                            </Link>
                        </Typography>
                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                            / {ref_order_no}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Box sx={styles.ShippingOrderListTableLandingSectionLarge}>
                        {account_base} / &nbsp;
                        <span>
                            <CurrencyFormat
                                value={(Math.abs((total_amount_include_tax + service_fee_include_gst) || 0).toFixed(2))}
                                displayType={'text'} thousandSeparator={true}
                                prefix={((total_amount_include_tax + service_fee_include_gst) || 0) >= 0 ? `${currency_code} $` : `${currency_code} $-`}/>
                        </span>
                    </Box>
                    <Box sx={styles.ShippingOrderListTableLandingSectionSmall}>
                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                            {account_base} /
                        </Typography>
                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                            <CurrencyFormat
                                value={(Math.abs((total_amount_include_tax + service_fee_include_gst) || 0).toFixed(2))}
                                displayType={'text'} thousandSeparator={true}
                                prefix={((total_amount_include_tax + service_fee_include_gst) || 0) >= 0 ? `${currency_code} $` : `${currency_code} $-`}/>
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Box sx={styles.ShippingOrderListTableLandingSectionLarge}>
                        {dayjs(created_time).format("YYYY-MM-DD HH:mm")}
                    </Box>
                    <Box sx={styles.ShippingOrderListTableLandingSectionSmall}>
                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                            {dayjs(created_time).format("YYYY-MM-DD HH:mm")}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <div className={classes.idTRLarge}>
                        <button onClick={getTransactionData}>Download</button>
                        <CSVLink
                            data={transactions}
                            filename={`${order_id}-${ref_order_no}.csv`}
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                    </div>
                    <Box sx={styles.ShippingOrderListTableLandingSectionSmall}>
                        <Box sx={styles.ShippingOrderListTableLandingMoreSection}>
                            <IconButton onClick={toggleOpenAction}>
                                <MoreHorizIcon/>
                            </IconButton>
                            {(openAction && openIndex === index) &&
                                <Box sx={styles.ShippingOrderListTableLandingAction}>
                                    <Box sx={styles.ShippingOrderListTableLandingActionEach}>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            Transaction
                                        </Typography>
                                        <IconButton onClick={getTransactionData}>
                                            <DownloadIcon/>
                                        </IconButton>
                                    </Box>
                                    <Box sx={styles.ShippingOrderListTableLandingActionEach}>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            Tracking#
                                        </Typography>
                                        <IconButton onClick={getTrackingsData}>
                                            <DownloadIcon/>
                                        </IconButton>
                                    </Box>
                                    <Box sx={styles.ShippingOrderListTableLandingActionEach}>
                                        <Typography sx={styles.ShippingOrderListTableLandingText}>
                                            Labels
                                        </Typography>
                                        <IconButton onClick={downloadLabelIntoZip}>
                                            <DownloadIcon/>
                                        </IconButton>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                </td>
                <td className={classes.idTRLarge}>
                    <div>
                        <button onClick={getTrackingsData}>Download</button>
                        <CSVLink
                            data={trackings}
                            filename={`${order_id}-${ref_order_no}-trackings.csv`}
                            className='hidden'
                            ref={csvTrackingLink}
                            target='_blank'
                        />
                    </div>
                    <div>
                        <button onClick={handleTrackingInfo}>Tracking Info</button>
                    </div>
                </td>
                <td className={classes.idTRLarge}>
                    <div>
                        <button onClick={downloadLabelIntoZip}>Download</button>
                    </div>
                </td>
            </tr>
        </>
    );
}
