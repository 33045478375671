import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Divider} from "@mui/material";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";

const styles = {
    ShippingPackageCrossBorderRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },

    ShippingPackageCrossBorderHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },

    ShippingPackageCrossBorderSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

export const CloneLabelCrossBorder = ({orderData, selectedRate, tranId}) => {

    const history = useHistory();

    const [crossBorderFee, setCrossBorderFee] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);

    const getCrossBorderFee = async () => {

        setLoading(true);
        let requestURL = `${PARTNER_URI}/brokerage/package/landedCostByServiceName`;
        const token = getAccessToken("access_token");

        let packingListData = orderData?.packingList.map(element => {
            const updatedElement = {
                description: element?.item_desc,
                sku: element?.item_sku,
                weight: element?.weight,
                weightUnit: element?.weight_unit,
                UOM: element?.uom,
                hscode: element?.hs_code,
                originCountryCode: element?.origi_country,
                currency: element?.currency_code,
                priceEach: element?.unit_price,
                quantity: element?.quantity,

            };
            return updatedElement;
        });

        let data = {
            serviceName: selectedRate?.accountBase,
            importCountryCode: orderData?.shipToCountry,
            importProvince: orderData?.shipToPostalCode,
            exportCountryCode: orderData?.shipFromCountry,
            transModes: orderData?.transMode,
            shipmentType: orderData?.shipmentType,
            shipmentItems: packingListData
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('result', result);
            if (result?.data?.error?.errors?.length > 0) {
                setErrorMessage(`Fail to get cross border fees. ${result?.data?.error?.errors[0]?.message}`);
                setError(true);
            } else {
                setErrorMessage('');
                setCrossBorderFee(result?.data);
                setError(false);
            }
            setLoading(false);
        } catch (e) {
            console.log('error', e.response);
            setLoading(false);
            setErrorMessage('Fail to get cross border fees.');
            setError(true);
        }
    }

    const handleOrderDetails = () => {
        history.push({
            pathname: '/packageOrder/cloneLabelDetails',
            state: {
                orderData: orderData,
                selectedRate: selectedRate,
                crossBorderFee: crossBorderFee,
                tranId: tranId
            }
        });
    }

    useEffect(() => {
        if (orderData && selectedRate) {
            getCrossBorderFee();
        }
    }, [orderData, selectedRate]);

    if (loading) {
        return (
            <Box sx={styles.ShippingPackageCrossBorderRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCrossBorderHeading}>
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CROSS_BORDER')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    if (error) {
        return (
            <Box sx={styles.ShippingPackageCrossBorderRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCrossBorderHeading}>
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CROSS_BORDER')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCrossBorderSubHeading}>
                            Sorry We currently encounter some issues, please try again later.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={styles.ShippingPackageCrossBorderRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCrossBorderHeading}>
                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.CROSS_BORDER')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCrossBorderSubHeading}>
                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.COST_DETAILS')}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right'}}>
                                Total Brokerage Fees
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right'}}>
                                $ {crossBorderFee?.totalBrokerageFees?.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right'}}>
                                Total Duty And Taxes
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right'}}>
                                $ {crossBorderFee?.totalDutyandTax?.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right', fontSize: '16px', fontWeight: '600'}}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Typography style={{textAlign: 'right', fontSize: '16px', fontWeight: '600'}}>
                                $ {crossBorderFee?.grandTotal?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                    <LoadingButton
                        size='large'
                        color="success"
                        variant='contained'
                        onClick={handleOrderDetails}
                    >
                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.ORDER_DETAILS')}
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>
    )
}