import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";

export default function EmptyCard({ classes, title, content }) {

  return (
      <Grid item xs={6} md={6} lg={6} xl={6}>
        <Card
            className={classes.paper}
            style={{ minHeight: "55vh", boxShadow: "none" }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h6" sx={{ fontSize: "14px" }}>{content}</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {/*<CrossBoarders orderId={orderData.order_id} tranId={orderData.tran_id} shippingIdNumber={orderData.shipping_id_no} />*/}
            </Grid>
            {/*<Grid item xs={12} md={12} lg={12}>*/}
            {/*  <Button variant="contained">{`Check More`}</Button>*/}
            {/*</Grid>*/}
          </Grid>
        </Card>
      </Grid>
  );
}
