import {LandingIntro} from "./LandingIntro";
import Box from "@mui/material/Box";
import UserSignIn from "../SignIn/userSignIn";
import {Container} from "@mui/material";
import '../../styles/landing.css';
import {useEffect} from "react";

const styles = {
    LandingSignInRoot: {
        display: 'flex',
        margin: 'auto',
        '@media (max-width: 480px)': {
            flexDirection: "column", // for phone and above
        },
    }
}

export const LandingSignIn = () => {

    useEffect(() => {
        document.body.classList.add('white-background');

        return () => {
            document.body.classList.remove('white-background');
        };
    }, []);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.LandingSignInRoot}>
                <LandingIntro/>
                <UserSignIn/>
            </Box>
        </Container>
    )
}