import {Container} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useParams} from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {PackageOrderDashboardTransaction} from "./PackageOrderDashboardTransaction";
import {useEffect, useState} from "react";
import {PackageOrderDashboardTransactionDetails} from "./PackageOrderDashboardTransactionDetails";
import {PackageOrderDashboardDocuments} from "./PackageOrderDashboardDocuments";
import {PARTNER_URI} from "../../../utils/apiUrl";
import axios from "axios";
import {useSelector} from "react-redux";
import {getAccessToken} from "../../../utils/doToken";
import {PackageOrderDashboardCrossBorder} from "./PackageOrderDashboardCrossBorder";
import {PackageOrderDashboardPickupAppointment} from "./PackageOrderDashboardPickupAppointment";
import {PackageOrderDashboardAdjustOrder} from "./PackageOrderDashboardAdjustOrder";
import intl from "react-intl-universal";
import {PackageOrderDashboardCost} from "./PackageOrderDashboardCost";
import {PackageOrderDashboardTransactionError} from "./PackageOrderDashboardTransactionError";
import {PackageOrderDashboardTransactionPending} from "./PackageOrderDashboardTransactionPending";
import {PackageOrderDashboardTransactionInitial} from "./PackageOrderDashboardTransactionInitial";
import CircularProgress from "@mui/material/CircularProgress";

export const PackageOrderDashboard = () => {

    const storedToken = getAccessToken("access_token");
    const {order_id: orderId} = useParams();
    const {firstname, id} = useSelector((state) => state.user);

    const [selectTransactionData, setSelectedTransactionData] = useState(null);
    const [orderData, setOrderData] = useState({});
    const [invoiceRef, setInvoiceRef] = useState("N/A");
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingTransaction, setIsLoadingTransaction] = useState(true);
    const [transactionList, setTransactionList] = useState(null);

    const [failedTransactionList, setFailedTransactionList] = useState([]);
    const [pendingTransactionList, setPendingTransactionList] = useState([]);
    const [initialTransactionList, setInitialTransactionList] = useState([]);

    const cbHandleSelectedTransactionData = (transactionData) => {
        setSelectedTransactionData(transactionData);
    }

    const cbHandleTransactionList = (transactionList) => {
        setTransactionList(transactionList);
    }

    const getCRMInvoiceInfoById = async ({invoiceId}) => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/billingCenter/getInvoiceInfoByInvoiceId?invoiceId=${invoiceId}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    const getOrderData = async ({orderId}) => {
        try {
            setIsLoading(true);
            const requestURI = `${PARTNER_URI}/shipping/list/order?partner_id=${id}&order_id=${orderId}`;
            const {data} = await axios.get(requestURI);
            if (data?.result && data?.result?.length > 0) {
                const resOrderData = data.result[0];
                console.log("orderData: ", resOrderData);
                setOrderData(resOrderData);
                const crmInvoiceNumber = resOrderData.crm_invoice_no;
                if (crmInvoiceNumber) {
                    const crmInfo = await getCRMInvoiceInfoById({
                        invoiceId: crmInvoiceNumber
                    });
                    const resCRMRefNumber = crmInfo.invoiceRefNumber;
                    setInvoiceRef(resCRMRefNumber);
                }

            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const getTransactionList = async ({orderId}) => {
        try {
            setIsLoadingTransaction(true);
            const requestURI = `${PARTNER_URI}/shipping/list/getExcelTransactionLists`;
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    shippingOrderId: orderId
                }
            })

            console.log('[PackageOrderDashboard] getTransactionList api result', result);
            setInitialTransactionList(result?.data?.initial);
            setFailedTransactionList(result?.data?.failed);
            setPendingTransactionList(result?.data?.pending);
        } catch (e) {
            console.log('[PackageOrderDashboard] getTransactionList api error', e?.response);
        } finally {
            setIsLoadingTransaction(false);
        }
    }

    useEffect(() => {
        getOrderData({orderId});
        getTransactionList({orderId});
    }, [orderId]);

    console.log('selected trans data', selectTransactionData);

    if (isLoading || isLoadingTransaction) {
        return (
            <Container maxWidth='xl'>
                <Box sx={{display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center'}}>
                    <CircularProgress/>
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={4}>
                <Grid item md={8} xs={12}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center'}}>
                        <CheckCircleIcon sx={{color: '#1D8B45', fontSize: '30px'}}/>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            {(initialTransactionList?.length > 0 || pendingTransactionList?.length > 0 || failedTransactionList?.length > 0) ? 'Your order was partially placed successfully!' : intl.get('ORDER_DASHBOARD.ORDER_STATUS.PLACED')}
                            <span
                                style={{color: '#1D8B45'}}> {intl.get('ORDER_DASHBOARD.ORDER_ID')}: {orderId}</span>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center'}}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}><span
                            style={{color: '#1D8B45'}}> {intl.get('ORDER_DASHBOARD.ORDER_COST')}: $ {orderData?.final_total?.toFixed(2)}</span>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Grid container spacing={2}>
                        {
                            (transactionList === null || transactionList?.length > 1) &&
                            <Grid item md={12} xs={12}>
                                <PackageOrderDashboardTransaction orderId={orderId}
                                                                  cbHandleSelectedTransactionData={cbHandleSelectedTransactionData}
                                                                  cbHandleTransactionList={cbHandleTransactionList}
                                />
                            </Grid>
                        }
                        {
                            transactionList?.length === 1 &&
                            <Grid item md={12} xs={12}>
                                <PackageOrderDashboardTransactionDetails orderId={orderId}
                                                                         selectTransactionData={selectTransactionData}/>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <PackageOrderDashboardPickupAppointment/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PackageOrderDashboardCost orderData={orderData}/>
                        </Grid>
                        <Grid item xs={12}>
                            <PackageOrderDashboardDocuments orderData={orderData}/>
                        </Grid>
                        {
                            failedTransactionList?.length > 0 &&
                            <Grid item md={12} xs={12}>
                                <PackageOrderDashboardTransactionError failedTransactionList={failedTransactionList}/>
                            </Grid>
                        }
                        {
                            initialTransactionList?.length > 0 &&
                            <Grid item md={12} xs={12}>
                                <PackageOrderDashboardTransactionInitial
                                    initialTransactionList={initialTransactionList}/>
                            </Grid>
                        }
                        {
                            pendingTransactionList?.length > 0 &&
                            <Grid item md={12} xs={12}>
                                <PackageOrderDashboardTransactionPending
                                    pendingTransactionList={pendingTransactionList}/>
                            </Grid>
                        }
                        {
                            orderData?.cross_border_shipment_type !== null &&
                            <Grid item xs={12}>
                                <PackageOrderDashboardCrossBorder orderData={orderData}/>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <PackageOrderDashboardAdjustOrder/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}