import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";

export const TransportationClaimTasksAdditionalService = ({ additionalService, storageService }) => {

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Claimed Order Additional Service
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            {
                                additionalService?.map((service, index) => (
                                    <Grid item xs={3} key={index}>
                                        <Typography style={{textAlign: 'left'}}>
                                            {service}
                                        </Typography>
                                    </Grid>
                                ))
                            }
                            {
                                storageService?.storageDays && +storageService?.storageDays > 0 && (
                                    <>
                                        <Grid item xs={3}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Storage Space: {storageService.cubicMeterSpace} m³
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={{textAlign: 'left'}}>
                                                Storage Days: {storageService.storageDays} day(s)
                                            </Typography>
                                        </Grid>
                                    </>
                                )
                            }

                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}