import Box from "@mui/material/Box";
import RoomIcon from "@material-ui/icons/Room";
import IconButton from "@mui/material/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {getAccessToken} from "../../utils/doToken";
import {Fragment, useState} from "react";
import {Snackbar} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TransportationAvailableTasksMapMarker = ({marker, selectTask, handleSelectTask}) => {

    const history = useHistory();
    const storedToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleViewDetails = (id) => {
        history.push(`/transportation/availableTasks/details/${id}`);
    }

    const claimOrder = async (id) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/partnerClaimMovingOrder`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    backendOrderId: id
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully claim order.');
            setTimeout(() => {
                history.push(`/transportation/claimTasks/details/${id}`);
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage("Fail to claim order.");
        } finally {
            setLoading(false);
        }
    }

    const handleClaimOrder = (id) => {
        claimOrder(id);
    }

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{
                position: 'relative'
            }}>
                <IconButton onClick={() => handleSelectTask(marker)}>
                    <RoomIcon style={{
                        color: marker?.id === selectTask?.id ? '#EF4040' : '#FB8B24',
                        fontSize: '30px',
                    }}/>
                </IconButton>
                {
                    marker?.id === selectTask?.id &&
                    <Box sx={{
                        position: 'absolute',
                        borderRadius: '5px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                        padding: '5px',
                        margin: '5px',
                        backgroundColor: '#FFFFFF',
                        width: '240px',
                        zIndex: '10'
                    }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography style={{
                                    fontSize: '14px',
                                    textAlign: 'left',
                                    fontWeight: '600',
                                    color: '#1D8B45'
                                }}>
                                    $ {selectTask?.final_total?.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {selectTask?.pickup_area}, {selectTask?.pickup_postal_code}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {dayjs(selectTask?.schedule_pickup).format("YYYY-MM-DD")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {selectTask?.google_map_distance} km
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: '5px'
                                }}>
                                    <Button
                                        variant='outlined'
                                        sx={{
                                            border: '2px solid #1D8B45',
                                            "&:hover": {
                                                border: '2px solid #1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        size='small'
                                        onClick={() => handleViewDetails(selectTask?.id)}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                            color: '#1D8B45',
                                            fontSize: '12px'
                                        }}>
                                            Details
                                        </Typography>
                                    </Button>
                                    {/*<LoadingButton*/}
                                    {/*    variant='outlined'*/}
                                    {/*    sx={{*/}
                                    {/*        border: '2px solid #1D8B45',*/}
                                    {/*        "&:hover": {*/}
                                    {/*            border: '2px solid #1D8B45',*/}
                                    {/*            filter: 'brightness(0.9)'*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*    size='small'*/}
                                    {/*    onClick={() => handleClaimOrder(selectTask?.id)}*/}
                                    {/*    loading={loading}*/}
                                    {/*>*/}
                                    {/*    <Typography style={{*/}
                                    {/*        textTransform: 'none',*/}
                                    {/*        color: '#1D8B45',*/}
                                    {/*        fontSize: '12px'*/}
                                    {/*    }}>*/}
                                    {/*        Claim*/}
                                    {/*    </Typography>*/}
                                    {/*</LoadingButton>*/}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                }
            </Box>
        </Fragment>
    )
}
