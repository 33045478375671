import React from "react";
import { Box } from "@material-ui/core";
import CustomInput from "../../../shared/customInput";
import PropTypes from "prop-types";

const BolInputField = ({
  error = false,
  value,
  title,
  mandatory = false,
  onChange,
  errorMsg = "Cannot be emtpy",
  placeholderValue = "",
  rows = 1,
  multiline = false,
  type,
  options,
  isNonNegativeNumber
}) => {
  return (
    <>
      <div>{mandatory ? `${title} *` : `${title}`}</div>
      <CustomInput
        value={value}
        onChange={onChange}
        error={mandatory ? error : false}
        placeholderValue={placeholderValue}
        rows={rows}
        multiline={multiline}
        type={type}
        options={options}
        isNonNegativeNumber={isNonNegativeNumber}
      />
      <Box pl={1} color="red" visibility={error ? "visible" : "hidden"}>
        {errorMsg}
      </Box>
    </>
  );
};

BolInputField.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  errorMsg: PropTypes.string,
  mandatory: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default BolInputField;
