import {Container, Divider, Snackbar} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../utils/doToken";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {mapLTLAdditionalService, mapLTLLogo} from "../../utils/Helper";
import Button from "@mui/material/Button";
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../shared/constInfo";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import LoadingContainer from "../../shared/loadingContainer";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {isValid} from 'date-fns';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    QuoteLTLDetailsHeading: {
        fontSize: '35px',
        fontWeight: '600',
        textAlign: 'center'
    },
    QuoteLTLDetailsTitle: {
        fontSize: '24px',
        textAlign: 'left',
    },
    QuoteLTLDetailsText: {
        fontSize: '18px',
        textAlign: 'left',
    },
    QuoteLTLDetailsTextCost: {
        fontSize: '18px',
        textAlign: 'right',
    },
    QuoteLTLDetailsTextFinal: {
        fontSize: '22px',
        fontWeight: '600',
        textAlign: 'left',
    },
    QuoteLTLDetailsTextCostFinal: {
        fontSize: '22px',
        fontWeight: '600',
        textAlign: 'right',
    },
    QuoteLTLDetailsRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        margin: '100px 0 40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    QuoteLTLDetailsRootSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    QuoteLTLDetailsSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}

export const QuoteLTLDetails = () => {

    const {quoteId} = useParams();

    const history = useHistory();

    const [quoteDetails, setQuoteDetails] = useState();
    const [additionalService, setAdditionalService] = useState([]);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [earliestPickupTime, setEarliestPickupTime] = useState('11:00');
    const [latestPickupTime, setLatestPickupTime] = useState('16:00');
    const [packageData, setPackageData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [quoteDialog, setQuoteDialog] = useState(false);
    const [orderDialog, setOrderDialog] = useState(false);

    const [dateValid, setDateValid] = useState(true);

    const handleQuoteDialogClose = () => {
        setQuoteDialog(false);
    };

    const handleQuoteDialogOpen = () => {
        setQuoteDialog(true);
    };

    const handleOrderDialogClose = () => {
        setOrderDialog(false);
    };

    const handleOrderDialogOpen = () => {
        setOrderDialog(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleError = (errorCode) => {
        switch (errorCode) {
            case 400:
                setErrorMessage("Bad Request: The server cannot process the request.");
                break;
            case 401:
                setErrorMessage("Unauthorized: Authentication is required to access the resource.");
                break;
            case 404:
                setErrorMessage("Not Found: The requested resource could not be found.");
                break;
            case 500:
                setErrorMessage("Internal Server Error: The server encountered an unexpected condition.");
                break;
            default:
                setErrorMessage("Unknown Error: An unknown error occurred.");
                break;
        }
    }

    const handleDateChange = (date) => {
        // console.log('data', date);
        if (date) {
            const valid = isValid(date?.$d);
            if (valid) {
                const dateString = date.toISOString().split('T')[0];
                setSelectedDate(dateString);
                setDateValid(true);
            } else {
                setSelectedDate(date);
                // const defaultData = getNextWeekday();
                // setSelectedDate(defaultData);
                setDateValid(false);
            }
        } else {
            const defaultData = getNextWeekday();
            setSelectedDate(defaultData);
            setDateValid(true);
        }
    };

    const handleEarliestPickupTimeChange = (event) => {
        const inputTime = event.target.value;
        setEarliestPickupTime(inputTime);
    };

    const handleLatestPickupTimeChange = (event) => {
        const inputTime = event.target.value;
        setLatestPickupTime(inputTime);
    };

    const token = getAccessToken("access_token");

    const isWeekend = (date) => {
        const day = date.day();
        return day === 0 || day === 6;
    };

    const getNextWeekday = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 48 * 60 * 60 * 1000); // Adding two day

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        return tomorrow.toISOString().split('T')[0];
    };

    const getMinDate = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000); // Adding two day

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        return tomorrow.toISOString().split('T')[0];
        ;
    };

    const handleQuoteList = () => {
        history.push('/myLTLQuote')
    }

    const getLTLQuoteByRequestId = async () => {
        let requestURL = `${PARTNER_URI}/shippingLTL/getLTLQuoteByRequestId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    requestId: quoteId
                }
            })

            console.log('result', result);

            const remark = result?.data?.remark_notes;
            if (remark) {
                const remarkNote = remark.split('\n');
                // console.log('remark note', remarkNote);
                const specialServices = remarkNote?.map(element => {
                    return {serviceCode: element}
                })
                // console.log('special service', specialServices);
                setAdditionalService(specialServices);
            }
            const filterPackageData = result?.data?.packages?.map(element => {
                return {
                    length: element?.length,
                    width: element?.width,
                    height: element?.height,
                    quantity: element?.shipment_count,
                    weight: element?.weight,
                    description: element?.item_name,
                    lengthUnit: element?.dimension_unit,
                    weightUnit: element?.weight_unit,
                    hscode: "",
                    originCountryCode: "",
                    currency: "",
                    priceEach: "",
                    UOM: "",
                }
            });

            // console.log('filter package', filterPackageData);
            setPackageData(filterPackageData);
            setQuoteDetails(result?.data);

        } catch (e) {
            console.log('error', e.response);
        }
    }

    const createQuote = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/shippingLTL/createLTLQuote`;
        let data = {
            shipperName: quoteDetails?.sender_name,
            shipperPhone: quoteDetails?.pickup_phone,
            shipperCompanyName: "",
            shipperEmail: "",
            shipperAddress: quoteDetails?.pickup_address,
            shipperAddressType: quoteDetails?.pickup_addr_type,
            shipperCity: quoteDetails?.pickup_city,
            shipperProvince: quoteDetails?.pickup_province,
            shipperPostalCode: quoteDetails?.pickup_postal_code,
            shipperCountry: quoteDetails?.pickup_country,
            consigneeName: quoteDetails?.deliver_name,
            consigneePhone: quoteDetails?.deliver_phone,
            consigneeCompanyName: "",
            consigneeEmail: "",
            consigneeAddress: quoteDetails?.deliver_address,
            consigneeAddressType: quoteDetails?.deliver_addr_type,
            consigneeCity: quoteDetails?.deliver_city,
            consigneeProvince: quoteDetails?.deliver_province,
            consigneePostalCode: quoteDetails?.deliver_postal_code,
            consigneeCountry: quoteDetails?.deliver_country,
            serviceName: quoteDetails?.account_base,
            pickupDate: selectedDate,
            packageData: packageData,
            specialServices: additionalService
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('create quote', result);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully create quote.')
            setLoading(false);
            setTimeout(() => {
                history.push(`/myLTLQuote`);
            }, 2000);

        } catch (e) {
            const errorCode = e?.response?.data?.error?.status;
            console.log('error code', errorCode);
            handleErrorMessage();
            handleError(errorCode);
            setLoading(false);
        }
    }

    const placeOrder = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/shippingLTL/initBackendOrderByRequestId`;
        let data = {
            requestId: quoteDetails?.request_id,
            pickupDate: selectedDate,
            pickupLocationCloseTime: `${selectedDate} ${latestPickupTime}`,
            pickupLocationOpenTime: `${selectedDate} ${earliestPickupTime}`
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully place order')
            setLoading(false);
            setTimeout(() => {
                history.push(`/myLTLs/`);
            }, 2000);
        } catch (e) {
            console.log('error', e?.response);
            const errorCode = e?.response?.status;
            const errorMessage = e?.response?.data?.error;
            handleErrorMessage();
            if (errorCode === 403) {
                setErrorMessage(`Fail to place order. ${errorMessage}`);
            } else {
                setErrorMessage(`Fail to place order. ${errorMessage}`);
            }
            // handleError(errorCode);
            setLoading(false);
        }
    }

    const handlePlaceOrder = () => {
        placeOrder();
    }

    const handleCreateQuote = () => {
        createQuote();
    }

    useEffect(() => {
        getLTLQuoteByRequestId();
        const defaultData = getNextWeekday();
        setSelectedDate(defaultData);
    }, []);

    // console.log('quote id', quoteId);
    // console.log('package data', packageData);
    // console.log('additional service', additionalService);
    // console.log('quote details', quoteDetails);

    // console.log('date', selectedDate);

    console.log('date', selectedDate);
    console.log('date validation', isValid(selectedDate?.$d));

    // console.log('date string', selectedDate?.toISOString().split('T')[0]);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={quoteDialog}
                onClose={handleQuoteDialogClose}
            >
                <DialogTitle>
                    Create Quote From This Quote
                </DialogTitle>
                <DialogContent>
                    This process will create a new LTL quote from the existing quote. Are you sure you want to proceed
                    with the provided pickup date and time?
                    <hr/>
                    Click <b>Confirm</b> to create the quote
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleQuoteDialogClose}>
                        No
                    </Button>
                    <LoadingButton
                        onClick={handleCreateQuote}
                        loading={loading}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={orderDialog}
                onClose={handleOrderDialogClose}
            >
                <DialogTitle>
                    Place Order From This Quote
                </DialogTitle>
                <DialogContent>
                    This process will place a LTL shipping order from the existing quote. Are you sure you want to
                    proceed
                    with the provided pickup date and time?
                    <hr/>
                    Click <b>Confirm</b> to place the order
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOrderDialogClose}>
                        No
                    </Button>
                    <LoadingButton
                        onClick={handlePlaceOrder}
                        loading={loading}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Box sx={styles.QuoteLTLDetailsRoot}>
                <Typography style={styles.QuoteLTLDetailsHeading}>
                    Quote Summary
                </Typography>
                <Grid item xs={12}>
                    {/*<Typography style={styles.QuoteLTLDetailsTitle}>*/}
                    {/*    Carrier*/}
                    {/*</Typography>*/}
                    <Grid container spacing={0}>
                        <Grid item md={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: '10px',
                                // flexDirection: 'column'
                            }}>
                                <img src={mapLTLLogo(quoteDetails?.account_base)} alt="logo" width={60}/>
                                <Typography style={styles.QuoteLTLDetailsTitle}>
                                    {quoteDetails?.account_base === 'dayandross' ? 'DAY & ROSS' : quoteDetails?.account_base?.toUpperCase()}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography style={styles.QuoteLTLDetailsTitle}>
                                    Shipping From
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    Name:
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    {quoteDetails?.sender_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    Phone:
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    {quoteDetails?.pickup_phone}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    Address:
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    {quoteDetails?.pickup_address}
                                </Typography>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    {quoteDetails?.pickup_city}, {quoteDetails?.pickup_province}, {quoteDetails?.pickup_country}, {quoteDetails?.pickup_postal_code}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography style={styles.QuoteLTLDetailsTitle}>
                                    Shipping To
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    Name:
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    {quoteDetails?.deliver_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    Phone:
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    {quoteDetails?.deliver_phone}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    Address:
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    {quoteDetails?.deliver_address}
                                </Typography>
                                <Typography style={styles.QuoteLTLDetailsText}>
                                    {quoteDetails?.deliver_city}, {quoteDetails?.deliver_province}, {quoteDetails?.deliver_country}, {quoteDetails?.deliver_postal_code}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={styles.QuoteLTLDetailsTitle}>
                            Items
                        </Typography>
                        {
                            quoteDetails?.packages?.map((each, index) => {
                                return (
                                    <Grid container spacing={0} key={index}>
                                        <Grid item xs={12} md={3}>
                                            <Typography style={styles.QuoteLTLDetailsText}>
                                                {each?.item_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Typography style={styles.QuoteLTLDetailsText}>
                                                {each?.length} x {each?.width} x {each?.height} ({each?.dimension_unit})
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Typography style={styles.QuoteLTLDetailsText}>
                                                {each?.weight} ({each?.weight_unit})
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Typography style={styles.QuoteLTLDetailsText}>
                                                x {each?.shipment_count}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    {additionalService?.length > 0 &&
                        <Grid item xs={12}>
                            <Typography style={styles.QuoteLTLDetailsTitle}>
                                Additional Service
                            </Typography>
                            <Grid item xs={12}>
                                <Box sx={{display: 'flex', gap: '40px', flexWrap: 'wrap'}}>
                                    {
                                        additionalService?.map((each, index) => {
                                            return (
                                                <Typography style={styles.QuoteLTLDetailsText} key={index}>
                                                    {mapLTLAdditionalService(each.serviceCode)}
                                                </Typography>
                                            )
                                        })
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography style={styles.QuoteLTLDetailsTitle}>
                        Estimate Cost
                    </Typography>
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <Typography style={styles.QuoteLTLDetailsText}>
                                Shipping Fee
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography style={styles.QuoteLTLDetailsTextCost}>
                                $ {(quoteDetails?.total_amount)?.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography style={styles.QuoteLTLDetailsText}>
                                Tax
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography style={styles.QuoteLTLDetailsTextCost}>
                                $ {(quoteDetails?.taxes)?.toFixed(2)}
                            </Typography>
                        </Grid>
                        {quoteDetails?.total_brokerage_charge > 0 &&
                            <>
                                <Grid item xs={4}>
                                    <Typography style={styles.QuoteLTLDetailsText}>
                                        Custom Brokerage Fee
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={styles.QuoteLTLDetailsTextCost}>
                                        $ {(quoteDetails?.total_brokerage_charge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography style={styles.QuoteLTLDetailsTextFinal}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography style={styles.QuoteLTLDetailsTextCostFinal}>
                                $ {(quoteDetails?.final_amount)?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box style={styles.QuoteLTLDetailsRootSection}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={styles.QuoteLTLDetailsTitle}>
                                    Pickup Date & Time
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Box sx={styles.QuoteLTLDetailsSection}>
                                            <InputLabel>Pickup Date</InputLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    shouldDisableDate={isWeekend}
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    minDate={getMinDate()}
                                                    renderInput={(props) =>
                                                        <TextField
                                                            {...props}
                                                            fullWidth
                                                        />}
                                                />
                                            </LocalizationProvider>
                                            <Typography style={{
                                                fontSize: '12px',
                                                color: "#FF0303"
                                            }}>
                                                {dateValid ? '' : 'This is invalid'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box sx={styles.QuoteLTLDetailsSection}>
                                            <InputLabel>Earliest Pickup Time</InputLabel>
                                            <TextField
                                                fullWidth
                                                type='time'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={earliestPickupTime}
                                                onChange={handleEarliestPickupTimeChange}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box sx={styles.QuoteLTLDetailsSection}>
                                            <InputLabel>Latest Pickup Time</InputLabel>
                                            <TextField
                                                fullWidth
                                                type='time'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={latestPickupTime}
                                                onChange={handleLatestPickupTimeChange}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            onClick={handleOrderDialogOpen}
                                            color='success'
                                            disabled={!dateValid}
                                        >
                                            Place Order
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            onClick={handleQuoteDialogOpen}
                                            disabled={!dateValid}
                                        >
                                            Create New Quote
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Box>
        </Container>
    )
}