import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider,
    makeStyles,
    Snackbar
} from "@material-ui/core";
import {Link} from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import LoadingButton from "@mui/lab/LoadingButton";
import Upload from "@mui/icons-material/Upload";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CommercialInvoiceTrackingTable from "./commercialInvoiceTrackingTable";
import CommercialInvoiceBorderDocTable from "./commercialInvoiceBorderDocTable";

const useStyles = makeStyles((theme) => ({
    listItemWrapper: {
        // width: "100%",
        margin: "3px auto",
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
        lineHeight: "2rem",
        textAlign: "left"
    },
    table: {
        width: "100%",
    },
}));

export default function CommercialInvoiceTransactionTable({
                                                              tran_id,
                                                              order_id,
                                                              ref_order_no,
                                                              cargo_control_no,
                                                              ref_product_no,
                                                              ref_other_no,
                                                              account_base,
                                                              gross_price,
                                                              net_price,
                                                              zip_label_uri,
                                                              label_pdf_uri,
                                                              invoicePDF,
                                                              date,
                                                              send_to_name,
                                                              send_to_email,
                                                              send_to_tel,
                                                              send_to_addr,
                                                              send_to_city,
                                                              send_to_postal_code,
                                                              send_to_province,
                                                              send_to_country,
                                                              currency_code,
                                                              transactionNumbers,
                                                              tran_status,
                                                              shipping_id_no
                                                          }) {
    const classes = useStyles();

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const [cancelTrackingNumberText, setCancelTrackingNumberText] = useState("");
    const [cancelTrackingNumberErrorMessage, setCancelTrackingNumberErrorMessage] = useState("");
    const [cancelLoading, setCancelLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [trackingList, setTrackingList] = useState([]);
    const [borderDocList, setBorderDocList] = useState([]);

    const inputPDFFile = useRef(null);
    const [pdfFileObj, setPdfFileObj] = useState(null);
    const [pdfFileName, setPDFFileName] = useState("");
    const [uploadPDFLoading, setUploadPDFLoading] = useState(false);

    const uploadPDFToServer = async ({fileObj}) => {
        try {
            if (!fileObj) {
                return;
            }
            setUploadPDFLoading(true);
            const formData = new FormData();
            formData.append("tranId", tran_id);
            formData.append("invoice_pdf", fileObj);
            formData.append("orderId", order_id);

            const {data} = await axios.post(`${PARTNER_URI}/commercialInvoice/uploadCommercialInvoice/ups`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data;",
                }
            })
            console.log(data.result);
            setErrorMessage("");
            handleToastClick();
        } catch (e) {
            console.log(e);
            // setErrorMessage("Error! This CSV file is invalid! Either row errors or you already submit this before!");
            // handleToastClick();
            setErrorMessage(e?.message || e?.error?.message || "Error");
        } finally {
            setUploadPDFLoading(false);
        }
    }

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const onPDFDrop = async (e) => {
        if (!(e.target.files && e.target.files[0])) {
            return;
        }
        // setPdfFileObj(e.target.files[0]);
        // setPDFFileName(e.target.files[0].name);
        await uploadPDFToServer({fileObj: e.target.files[0]});
        await getDocListByTranId();
    };

    const cancelOrderByTransactionId = async () => {
        try {
            setCancelLoading(true);
            const storedToken = getAccessToken("access_token");
            const {data} = await axios.delete(`${PARTNER_URI}/loose-item/3rd-party/internal/cancel-shipping`, {
                headers: {Authorization: `Bearer ${storedToken}`},
                data: {
                    trackingNumber: shipping_id_no
                }
            });
            console.log(data);
            setCancelTrackingNumberErrorMessage("");
            setIsCancelled(true);
        } catch (e) {
            console.log(e);
            setCancelTrackingNumberErrorMessage(e?.response?.data?.errorMessage || "Error!");
        } finally {
            setCancelLoading(false);
        }
    }

    const confirmCancelOrder = async (e) => {
        e.preventDefault();
        await cancelOrderByTransactionId();
        handleToastClick();
        handleDialogClose();
    }

    // Cancel Tracking Order
    const onCancelOrder = () => {
        handleDialogOpen();
    }
    const getTrackingListByTranId = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getTrackingViewByTranId/${tran_id}`);
            console.log("getTrackingListByTranId", data);
            setTrackingList(data);
        } catch (e) {
            console.log(e);
        }
    }

    const getDocListByTranId = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/commercialInvoice/getBoraderDocListByTranId/${tran_id}`);
            console.log("getDocListByTranId", data);
            setBorderDocList(data);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (tran_id) {
            getTrackingListByTranId();
            getDocListByTranId();
        }
    }, [tran_id]);

    return (
        <Box
            // width="100%"
            // display="flex"
            // justifyContent="start"
            // flexDirection="column"
            sx={{gap: "1rem"}}
        >
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order">
                {(() => {
                    if (cancelTrackingNumberErrorMessage !== "") {
                        return (
                            <Alert onClose={handleToastClose} severity="error" sx={{width: '100%'}}>
                                Upload PDF Error!
                                <hr/>
                                Error: {cancelTrackingNumberErrorMessage}
                            </Alert>
                        )
                    }
                    return (
                        <Alert onClose={handleToastClose} severity="success" sx={{width: '100%'}}>
                            Upload Invoice PDF Success!
                        </Alert>
                    )
                })()}
            </Snackbar>
            {/*Pop-up Toast*/}
            {/*Confirm Delete Tracking Dialog*/}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cancel Tracking Order
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will cancel ALL the trackings related to this
                        Transaction <b>{transactionNumbers}</b>. Are you sure to cancel?
                        <hr/>
                        Click <b>Confirm</b> to cancel this shipping.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <Button onClick={confirmCancelOrder} variant="outlined" type="button" disabled={cancelLoading}>
                        {cancelLoading && <CircularProgress size={14}/>}
                        {!cancelLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Confirm Delete Tracking Dialog*/}
            {/*transaction table*/}
            {/*<Box mt={2}>*/}
            {/*    <table className={classes.table}>*/}
            {/*        <thead>*/}
            {/*        <tr className={classes.listItemWrapper}>*/}
            {/*            <th className={classes.idTH}>Transaction ID</th>*/}
            {/*            <th className={classes.idTH}>Order ID</th>*/}
            {/*            <th className={classes.nameTH}>Ref #</th>*/}
            {/*            <th className={classes.nameTH}>Send To</th>*/}
            {/*            <th className={classes.nameTH}>Account Base</th>*/}
            {/*            <th className={classes.nameTH}>Price</th>*/}
            {/*            <th className={classes.nameTH}>Tracking #</th>*/}
            {/*            <th className={classes.nameTH}>Labels</th>*/}
            {/*            <th className={classes.nameTH}>Date</th>*/}
            {/*        </tr>*/}
            {/*        </thead>*/}
            {/*        <tbody className={classes.tbody}>*/}
            {/*        <tr className={classes.listItemWrapper}>*/}
            {/*            <td>*/}
            {/*                <Link*/}
            {/*                    to={`/loose-item/3rd-party/shipping/list/product/${tran_id}`}>*/}
            {/*                    {tran_id}*/}
            {/*                </Link>*/}
            {/*            </td>*/}
            {/*            <td>{order_id}</td>*/}
            {/*            <td>*/}
            {/*                Ref Order #: {ref_order_no}*/}
            {/*                <br/>*/}
            {/*                Ref Product #: {ref_product_no}*/}
            {/*                <br/>*/}
            {/*                Ref Other #: {ref_other_no}*/}
            {/*            </td>*/}
            {/*            <td>*/}
            {/*                {send_to_name ? send_to_name : "N/A"}*/}
            {/*                <br/>*/}
            {/*                {send_to_tel ? send_to_tel : "N/A"}, {send_to_email ? send_to_email : "N/A"}*/}
            {/*                <br/>*/}
            {/*                {send_to_addr}*/}
            {/*                <br/>*/}
            {/*                {send_to_city}, {send_to_province}, {send_to_country}, {send_to_postal_code}*/}
            {/*            </td>*/}
            {/*            <td>{account_base}</td>*/}
            {/*            <td>*/}
            {/*                excl. tax: <CurrencyFormat value={gross_price} displayType={'text'} thousandSeparator={true}*/}
            {/*                                           prefix={`${currency_code} $`}/>*/}
            {/*                <br/>*/}
            {/*                inc. tax: <CurrencyFormat value={net_price} displayType={'text'} thousandSeparator={true}*/}
            {/*                                          prefix={`${currency_code} $`}/>*/}
            {/*            </td>*/}
            {/*            <td>{transactionNumbers}</td>*/}
            {/*            <td>*/}
            {/*                <a href={getLabelURL(label_pdf_uri, zip_label_uri)} target="_blank"*/}
            {/*                   rel="noopener noreferrer">*/}
            {/*                    {getLabelURL(label_pdf_uri, zip_label_uri) ? "Download" : ""}*/}
            {/*                </a>*/}
            {/*            </td>*/}
            {/*            <td>{dayjs(date).format("YYYY-MM-DD HH:mm")}</td>*/}
            {/*        </tr>*/}
            {/*        </tbody>*/}
            {/*    </table>*/}
            {/*</Box>*/}
            {/*<Box mt={2} display="flex" justifyContent="center" alignContent="center" alignItems="center">*/}
            {/*  <input type="file" style={{ display: "none" }} ref={inputPDFFile} onChange={onPDFDrop} accept=".pdf" name="file" />*/}
            {/*  <LoadingButton*/}
            {/*      variant="contained"*/}
            {/*      component="span"*/}
            {/*      color="primary"*/}
            {/*      style={{*/}
            {/*        textTransform: "unset",*/}
            {/*        fontSize: "1rem",*/}
            {/*        width: "10rem"*/}
            {/*      }}*/}
            {/*      onClick={() => {*/}
            {/*        inputPDFFile.current.click();*/}
            {/*      }}*/}
            {/*      endIcon={<Upload />}*/}
            {/*      loading={uploadPDFLoading}*/}
            {/*  >*/}
            {/*    Upload PDF*/}
            {/*  </LoadingButton>*/}
            {/*</Box>*/}
            <br/>
            <h4>Trackings</h4>
            <Box mt={2}>
                <table className={classes.table}>
                    <thead>
                    <tr className={classes.listItemWrapper}>
                        <th className={classes.nameTH}>Tracking #</th>
                        <th className={classes.nameTH}>Item Name</th>
                        <th className={classes.nameTH}>Length/Width/Height</th>
                        <th className={classes.nameTH}>Weight</th>
                        {/*<th className={classes.nameTH}>Unit Price</th>*/}
                        {/*<th className={classes.nameTH}>HS Code</th>*/}
                        {/*<th className={classes.nameTH}>Quantity</th>*/}
                        <th className={classes.nameTH}>Label</th>
                        <th className={classes.nameTH}>Invoice</th>
                        <th className={classes.nameTH}>Status</th>
                        <th className={classes.nameTH}></th>
                        <th className={classes.nameTH}></th>
                    </tr>
                    </thead>
                    <tbody className={classes.tbody}>
                    {trackingList.map(tracking => {
                        return <CommercialInvoiceTrackingTable
                            itemName={tracking.item_name}
                            tracking_no={tracking.tracking_no}
                            length={tracking.length}
                            width={tracking.width}
                            height={tracking.height}
                            lengthUnit={tracking.dimension_unit}
                            weightUnit={tracking.weight_unit}
                            quantity={tracking.number}
                            unitPrice={tracking.unit_price}
                            currency={tracking.currency_code}
                            hscode={tracking.hs_code}
                            label_uri={`${LABEL_DOWNLOAD_URL_PREFIX}${tracking.label_uri}`}
                            pdfURI={tracking.file_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${tracking.file_uri}` : ""}
                            weight={tracking.weight}
                            getDocListByTranId={getDocListByTranId}
                            getTrackingListByTranId={getTrackingListByTranId}
                            orderId={order_id}
                            tranId={tran_id}
                            shippingIdNumber={shipping_id_no}
                            status={tracking.doc_status}
                            documentId={tracking.ref_doc_id}
                            docId={tracking.doc_id}
                        />
                    })}
                    </tbody>
                </table>
            </Box>
            {/*<br/>*/}
            {/*<h4>Uploaded PDF</h4>*/}
            {/*<Box mt={2}>*/}
            {/*    <table className={classes.table}>*/}
            {/*        <thead>*/}
            {/*        <tr className={classes.listItemWrapper}>*/}
            {/*            <th className={classes.nameTH}>File Name</th>*/}
            {/*            <th className={classes.nameTH}>Document Id</th>*/}
            {/*            <th className={classes.nameTH}></th>*/}
            {/*            <th className={classes.nameTH}>Upload Time</th>*/}
            {/*            /!*<th className={classes.nameTH}>Operation</th>*!/*/}
            {/*        </tr>*/}
            {/*        </thead>*/}
            {/*        <tbody className={classes.tbody}>*/}
            {/*        {borderDocList.map(borderDoc => {*/}
            {/*            return <CommercialInvoiceBorderDocTable*/}
            {/*                fileName={borderDoc.file_name}*/}
            {/*                documentId={borderDoc.ref_doc_id}*/}
            {/*                uploadTime={borderDoc.created_at}*/}
            {/*                fileURI={`${LABEL_DOWNLOAD_URL_PREFIX}${borderDoc.file_uri}`}*/}
            {/*            />*/}
            {/*        })}*/}
            {/*        </tbody>*/}
            {/*    </table>*/}
            {/*</Box>*/}
        </Box>
    );
}
