import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function ShippingListTransactionTable({
                                                       tran_id,
                                                       product_id,
                                                       label_uri,
                                                       tracking_no,
                                                       tn_status,
                                                       onCancelOrder,
                                                       order_id,
                                                       ref_order_number,
                                                       cargo_control_no,
                                                       account_base
}) {
  const classes = useStyles();

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>
          <Link
              to={`/loose-item/3rd-party/shipping/order/${order_id}`}>
            {order_id}
          </Link>
        </td>
        <td>{ref_order_number}</td>
        <td>{cargo_control_no}</td>
        <td>
          <Link
            to={`/loose-item/3rd-party/shipping/list/product/${tran_id}`}>
            {tran_id}
          </Link>
        </td>
        <td>{product_id}</td>
        <td>
          <a href={label_uri || "#"} target="_blank" rel="noopener noreferrer">{tracking_no}</a>
        </td>
        {/*<td>*/}
        {/*  {*/}
        {/*    tn_status === 0 ? (*/}
        {/*        <button onClick={() => {onCancelOrder(tracking_no)}}>Cancel</button>*/}
        {/*        ) : "Cancelled"*/}
        {/*  }*/}
        {/*</td>*/}
        <td>
          {
            (() => {
              if (+tn_status === 1 || +tn_status === 0) {
                return (
                    <button
                        onClick={() => {
                          onCancelOrder();
                        }}
                    >
                      Cancel
                    </button>
                );
              }
              if (+tn_status === 2) {
                return "Delivered";
              }
              return "Cancelled";
            })()
          }
        </td>
      </tr>
    </>
  );
}
