import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {extractFullAddress, provinces_states} from "../../utils/Helper";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Paper from "@mui/material/Paper";
import {value} from "lodash/seq";
import {useDispatch, useSelector} from "react-redux";
import {
    updateCrossBorderQuickQuotePackage,
    updateShipFromPackageQuickQuotePackage,
    updateShipToPackageQuickQuotePackage
} from "../Actions/shippingPackageAction";
import {Accordion, AccordionDetails, AccordionSummary, MenuItem, Select} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import FormControl from "@mui/material/FormControl";
import {internationalShipmentTypeList, transportationListToCodeMap} from "../../shared/constInfo";
import {makeStyles} from "@material-ui/core/styles";
import intl from "react-intl-universal";

const styles = {
    ShippingPackageQuickQuoteLocationTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationHeading: {
        fontSize: '20px',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteLocationSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageQuickQuoteLocationBox: {
        padding: '20px',
        // border: '1px solid #000000',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    },
    ShippingPackageQuickQuoteLocationErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageQuickQuoteLocationRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "0px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingPackageQuickQuoteLocation = ({isGetRate}) => {

    const dispatch = useDispatch();

    const classes = useStyles();

    let shipFromMissing = useSelector(state => state?.shippingPackage?.quickQuoteShipFromMissing);
    let shipToMissing = useSelector(state => state?.shippingPackage?.quickQuoteShipToMissing);

    const TransportationModeList = Object.entries(transportationListToCodeMap).map(([key, value]) => [key, value]);

    const [accordionOpen, setAccordionOpen] = useState(false);

    const [crossBorder, setCrossBorder] = useState({
        tranMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
    })

    const [shipFrom, setShipFrom] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        country: null,
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        }
    });

    const [shipTo, setShipTo] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        country: null,
        displayPostalCode: {
            description: null,
            placeId: null,
            text: null
        },
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        }
    });

    const [shipFromPredictions, setShipFromPredictions] = useState([]);
    const [shipToPredictions, setShipToPredictions] = useState([]);
    const [shipFromPredictionsZip, setShipFromPredictionsZip] = useState([]);
    const [shipToPredictionsZip, setShipToPredictionsZip] = useState([]);

    const toggleOpen = () => {
        setAccordionOpen(!accordionOpen);
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromFullAddress = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            address: value
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipFromPredictions(predictionsAddress);
    }

    const handleInputShipFromPostalCode = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setShipFromPredictionsZip(predictions);
    }

    const handleChangeShipFromFullAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place)
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            const predictionsZip = await googleMapsPlaceAutocomplete(address?.zip, 'postal_code');
            // console.log('prediction zip list', predictionsZip);
            setShipFromPredictionsZip(predictionsZip);
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: address?.state,
                country: address?.country?.code,
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: address?.zip
                },
                displayAddress: value
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                address: '',
                postalCode: '',
                city: '',
                province: '',
                country: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleChangeShipFromPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            console.log('value', value);
            if (value?.description.includes(shipFrom?.country?.name) &&
                value?.description.includes(shipFrom?.province?.code)) {
                setShipFrom(prevState => ({
                    ...prevState,
                    displayPostalCode: value
                }))
            } else {
                setShipFrom(prevState => ({
                    ...prevState,
                    city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                    province: address?.state,
                    country: address?.country?.code,
                    displayPostalCode: value,
                    displayAddress: {
                        description: '',
                        placeId: '',
                        text: ''
                    },
                }))
            }
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                city: '',
                province: '',
                country: '',
                postalCode: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleInputShipToFullAddress = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            address: value
        }));
        const predictions = await googleMapsPlaceAutocomplete(value);
        setShipToPredictions(predictions);
    }

    const handleInputShipToPostalCode = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        setShipToPredictionsZip(predictions);
    }

    const handleChangeShipToFullAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);

        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            const predictionsZip = await googleMapsPlaceAutocomplete(address?.zip, 'postal_code');
            setShipToPredictionsZip(predictionsZip);
            setShipTo(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: address?.state,
                country: address?.country?.code,
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: address?.zip
                },
                displayAddress: value,
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                address: '',
                postalCode: '',
                city: '',
                province: '',
                country: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleChangeShipToPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        console.log('place', place);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            console.log('value', value);
            if (value?.description.includes(shipTo?.country?.name) &&
                value?.description.includes(shipTo?.province?.code)) {
                setShipTo(prevState => ({
                    ...prevState,
                    displayPostalCode: value
                }))
            } else {
                setShipTo(prevState => ({
                    ...prevState,
                    city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                    province: address?.state,
                    country: address?.country?.code,
                    displayPostalCode: value,
                    displayAddress: {
                        description: '',
                        placeId: '',
                        text: ''
                    },
                }))
            }
        } else {
            setShipTo(prevState => ({
                ...prevState,
                city: '',
                province: '',
                country: '',
                postalCode: '',
                displayPostalCode: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleTranMode = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            tranMode: event.target.value
        }))
    }

    const handleShipmentType = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            shipmentType: event.target.value
        }))
    }

    console.log('ship from', shipFrom);
    console.log('ship to', shipTo);

    useEffect(() => {
        dispatch(updateShipFromPackageQuickQuotePackage(shipFrom));
    }, [shipFrom]);

    useEffect(() => {
        dispatch(updateShipToPackageQuickQuotePackage(shipTo));
    }, [shipTo]);

    useEffect(() => {
        dispatch(updateCrossBorderQuickQuotePackage(crossBorder));
    }, [crossBorder])

    useEffect(() => {
        if (shipFrom?.country?.code === shipTo?.country?.code) {
            setAccordionOpen(false);
        } else {
            setAccordionOpen(true);
        }
    }, [shipFrom, shipTo])

    // console.log('cross border', crossBorder);

    console.log('ship from prediction', shipFromPredictions);

    return (

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography style={styles.ShippingPackageQuickQuoteLocationTitle}>
                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.ADDRESS_DETAILS')}
                </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingPackageQuickQuoteLocationBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageQuickQuoteLocationHeading}>
                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.SHIP_FROM')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                                {/*<InputLabel required>Postal Code</InputLabel>*/}
                                <InputLabel sx={{fontSize: '14px'}}>
                                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.ADDRESS_LABEL')}
                                </InputLabel>
                                <Autocomplete
                                    value={shipFrom?.displayAddress}
                                    disabled={isGetRate}
                                    options={shipFromPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.description}
                                    onInputChange={handleInputShipFromFullAddress}
                                    onChange={handleChangeShipFromFullAddress}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                                {/*<Typography style={styles.ShippingPackageQuickQuoteLocationErrorText}>*/}
                                {/*    {*/}
                                {/*        shipFromMissing?.length > 0 ? "This is required field or check your address." : ''*/}
                                {/*    }*/}
                                {/*</Typography>*/}
                            </Box>
                        </Grid>
                        < Grid item xs={12} md={6}>
                            <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel required
                                            sx={{fontSize: '14px'}}>
                                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.POSTAL_CODE')}
                                </InputLabel>
                                <Autocomplete
                                    value={shipFrom?.displayPostalCode}
                                    disabled={isGetRate}
                                    options={shipFromPredictionsZip}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputShipFromPostalCode}
                                    onChange={handleChangeShipFromPostalCode}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                                <Typography style={styles.ShippingPackageQuickQuoteLocationErrorText}>
                                    {
                                        shipFromMissing?.length > 0 ? intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID') : ''
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <Box sx={styles.ShippingPackageQuickQuoteLocationBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageQuickQuoteLocationHeading}>
                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.SHIP_TO')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel
                                    sx={{fontSize: '14px'}}>{intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.ADDRESS_LABEL')}</InputLabel>
                                <Autocomplete
                                    value={shipTo?.displayAddress}
                                    disabled={isGetRate}
                                    options={shipToPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.description}
                                    onInputChange={handleInputShipToFullAddress}
                                    onChange={handleChangeShipToFullAddress}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                                {/*<Typography style={styles.ShippingPackageQuickQuoteLocationErrorText}>*/}
                                {/*    {*/}
                                {/*        shipToMissing?.length > 0 ? "This is required field or check your address." : ''*/}
                                {/*    }*/}
                                {/*</Typography>*/}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>
                                <InputLabel required
                                            sx={{fontSize: '14px'}}>{intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.POSTAL_CODE')}</InputLabel>
                                <Autocomplete
                                    value={shipTo?.displayPostalCode}
                                    disabled={isGetRate}
                                    filterOptions={(options, state) => options}
                                    options={shipToPredictionsZip}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputShipToPostalCode}
                                    onChange={handleChangeShipToPostalCode}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '12px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                                <Typography style={styles.ShippingPackageQuickQuoteLocationErrorText}>
                                    {
                                        shipToMissing?.length > 0 ? intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.ADDRESS.MISSING_INVALID') : ''
                                    }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/*<Grid item md={12}>*/}
            {/*    <Accordion*/}
            {/*        expanded={accordionOpen}*/}
            {/*        onChange={toggleOpen}*/}
            {/*    >*/}
            {/*        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>*/}
            {/*            <Box sx={styles.ShippingPackageQuickQuoteLocationRow}>*/}
            {/*                <IconButton*/}
            {/*                    onClick={() => window.location.href = 'https://uucargo.ca/support/cross-border-shipping-parcels/'}>*/}
            {/*                    <HelpIcon color="info" sx={{fontSize: 25}}/>*/}
            {/*                </IconButton>*/}
            {/*                <Typography>*/}
            {/*                    {intl.getHTML('CROSS_BORDER.CROSS_BORDER_TITLE')}*/}
            {/*                </Typography>*/}
            {/*            </Box>*/}
            {/*        </AccordionSummary>*/}
            {/*        <AccordionDetails>*/}
            {/*            <Grid container spacing={4}>*/}
            {/*                <Grid item md={6} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>*/}
            {/*                        <InputLabel required>{intl.get('CROSS_BORDER.TRANSPORTATION_MODE')}</InputLabel>*/}
            {/*                        <FormControl variant="outlined" fullWidth>*/}
            {/*                            <Select*/}
            {/*                                value={crossBorder?.tranMode}*/}
            {/*                                onChange={handleTranMode}*/}
            {/*                                size='small'*/}
            {/*                            >*/}
            {/*                                {*/}
            {/*                                    TransportationModeList.map(value => {*/}
            {/*                                        return <MenuItem value={value[0]}>{value[1]}</MenuItem>*/}
            {/*                                    })*/}
            {/*                                }*/}
            {/*                            </Select>*/}
            {/*                        </FormControl>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*                <Grid item md={6} xs={12}>*/}
            {/*                    <Box sx={styles.ShippingPackageQuickQuoteLocationSection}>*/}
            {/*                        <InputLabel required>{intl.get('CROSS_BORDER.SHIPMENT_TYPE')}</InputLabel>*/}
            {/*                        <FormControl variant="outlined" fullWidth>*/}
            {/*                            <Select*/}
            {/*                                value={crossBorder?.shipmentType}*/}
            {/*                                onChange={handleShipmentType}*/}
            {/*                                size='small'*/}
            {/*                            >*/}
            {/*                                {internationalShipmentTypeList.map(value => {*/}
            {/*                                    return <MenuItem value={value}>{value}</MenuItem>*/}
            {/*                                })}*/}
            {/*                            </Select>*/}
            {/*                        </FormControl>*/}
            {/*                    </Box>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*        </AccordionDetails>*/}
            {/*    </Accordion>*/}
            {/*</Grid>*/}
        </Grid>

    )
}