import React, {useEffect, useRef, useState} from 'react';
import { Box, Button, TextField, useMediaQuery, FormControl, FormLabel, RadioGroup,FormControlLabel, Radio } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import {PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX, SERVICE_SERVICE_URI} from "../../utils/apiUrl";
import { useSelector } from "react-redux";
import NavBar from "../navBar";
import PartnerInvoiceTable from "./PartnerInvoiceTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const PartnerInvoicePage = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const pageSize = 100;

  const partnerInfo = useSelector((state) => state.user);

  const { register, handleSubmit, control } = useForm();
  const [invoiceData, setInvoiceData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const cacheInput = useRef({

  });

  const getData = async ({ currentPage }) => {
    const { data } = await axios.get(`${PARTNER_URI}/invoices/getInvoicesList?partnerId=${partnerInfo.id}&page=${currentPage - 1 < 0 ? 0 : currentPage - 1}&pageSize=50`);
    const result = data.data;
    console.log(data);
    const resultTotalNumber = data.pagination.total;
    setTotalNumber(resultTotalNumber);
    setInvoiceData(result);
    try {
    } catch (e) {
      setTotalNumber(0);
      console.log(e);
      setInvoiceData([]);
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmit = data => {
    // console.log(data);
    const { ref_order_no, cargo_control_no, account_base, order_id, ref_product_no } = data;
    cacheInput.current = data;
    setPage(1);
    getData({ currentPage: 1 });
  }
  const handlePageChange = (e, value) => {
    if (value !== page) {
      console.log(value);
      setPage(value);
      // const { order_id, ref_product_no , ref_order_no, account_base, cargo_control_no } = cacheInput.current;
      getData({ currentPage: value });
    }
  }

  useEffect(() => {
    (async() => {
      await getData({ currentPage: 0 })
    })();
  }, [partnerInfo.id])

  return (
      <>

        <Box>
          <Box component="form" width={matches ? "100%" : "600px"} margin="0 auto" sx={{
            display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem"
          }}>
            <h1>Original Invoices from UPS/FedEX</h1>
            {/*<TextField id="outlined-basic" label="Ref Order Number" variant="outlined" {...register("ref_order_no")} />*/}
            {/*<TextField id="outlined-basic" label="Cargo Control Number" variant="outlined" {...register("cargo_control_no")} />*/}
            {/*<TextField id="outlined-basic" label="Order ID" variant="outlined" {...register("order_id")} />*/}
            {/*<TextField id="outlined-basic" label="Product Id" variant="outlined" {...register("ref_product_no")} />*/}

            {/*<Box mt={2} mb={4}>*/}
            {/*  <FormControl>*/}
            {/*    <FormLabel required color="primary">Account Base</FormLabel>*/}
            {/*    <Controller control={control} defaultValue="UPS" name="account_base" rules={{ required: true }} render={({ field }) => (*/}
            {/*        <RadioGroup*/}
            {/*            row*/}
            {/*            aria-labelledby="demo-row-radio-buttons-group-label"*/}
            {/*            name="row-radio-buttons-group"*/}
            {/*            defaultValue="UPS"*/}
            {/*            {...field}*/}
            {/*        >*/}
            {/*          <FormControlLabel value="FedEx" control={<Radio />} label="FedEx" />*/}
            {/*          <FormControlLabel value="UPS" control={<Radio />} label="UPS" />*/}
            {/*        </RadioGroup>*/}
            {/*    )}/>*/}

            {/*  </FormControl>*/}
            {/*</Box>*/}
            {/*<Box>*/}
            {/*  <Button*/}
            {/*      variant="contained"*/}
            {/*      component="span"*/}
            {/*      color="primary"*/}
            {/*      style={{*/}
            {/*        textTransform: "unset",*/}
            {/*        fontSize: "1rem",*/}
            {/*        width: "7rem",*/}
            {/*      }}*/}
            {/*      type="submit"*/}
            {/*      onClick={handleSubmit(onSubmit)}*/}
            {/*  >*/}
            {/*    Search*/}
            {/*  </Button>*/}
            {/*</Box>*/}
          </Box>

          <br />
          {
            isLoading ? (
                <>
                  <Box>
                    <h1>Loading...</h1>
                  </Box>
                </>
            ) : (
                <>
                  <Box>
                    <h2>Found {totalNumber} results</h2>
                    <hr width={matches ? "100%" : "rem"} />
                    <Box maxWidth="70%" margin="0 auto">
                      <Box mt={2}>
                        <table className={classes.table}>
                          <thead>
                          <tr>
                            <th className={classes.idTH}>Invoice ID</th>
                            <th className={classes.idTH}>Invoice Number</th>
                            <th className={classes.idTH}>Account Base</th>
                            <th className={classes.idTH}>Invoice Date</th>
                            <th className={classes.idTH}>Download</th>
                          </tr>
                          </thead>
                          <tbody className={classes.tbody}>
                          {
                            invoiceData.map((v, index) => {
                              return <PartnerInvoiceTable
                                  key={index}
                                  invoiceId={v.invoice_id}
                                  invoiceNumber={v.invoice_no}
                                  partner_id={partnerInfo.id}
                                  date={v.invoice_date}
                                  accountBase={v.account_base}
                              />
                            })
                          }
                          {/*{shippingListData.map((v, index) => {*/}
                          {/*  return <ShippingOrderListTable key={index}*/}
                          {/*                                 partner_id={partnerInfo.id}*/}
                          {/*                                 account_base={v.account_base}*/}
                          {/*                                 send_from_email={v.send_from_email}*/}
                          {/*                                 send_from_name={v.send_from_name}*/}
                          {/*                                 send_from_postal_code={v.send_from_postal_code}*/}
                          {/*                                 order_id={v.order_id}*/}
                          {/*                                 cargo_control_no={v.cargo_control_no}*/}
                          {/*                                 send_from_tel={v.send_from_tel}*/}
                          {/*                                 ref_order_no={v.ref_order_no}*/}
                          {/*                                 send_from_addr={v.send_from_addr}*/}
                          {/*                                 send_from_city={v.send_from_city}*/}
                          {/*                                 send_from_province={v.send_from_province}*/}
                          {/*                                 total_amount={v.total_amount || 0}*/}
                          {/*                                 origi_shipping_item_file={v.origi_shipping_item_file}*/}
                          {/*                                 currency_code={v.currency_code}*/}
                          {/*                                 date={v.created_at}*/}
                          {/*  />;*/}
                          {/*})}*/}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                    <br />
                    <Box margin="auto" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                      <Pagination count={Math.floor(totalNumber / pageSize) + 1}
                                  page={page}
                                  onChange={handlePageChange}
                                  className={classes.pagination}
                                  variant="outlined"
                                  shape="rounded" />
                    </Box>
                  </Box>
                </>
            )
          }
        </Box>
      </>
  );
};

export default PartnerInvoicePage;
