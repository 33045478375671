import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {extractFullAddress, isValidatePhone} from "../../utils/Helper";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/joy";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {Checkbox, FormControlLabel, MenuItem, Select} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {updateShipToPackageCreateLabelPackage} from "../Actions/shippingPackageAction";
import {useHistory} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import {getCountryCode} from "../../utils/getCountryCode";
import addressIcon from "../../images/Address.png";
import {getAccessToken} from "../../utils/doToken";
import intl from "react-intl-universal";
import LoadingButton from "@mui/lab/LoadingButton";
import {useMediaQuery} from "@material-ui/core";

const styles = {
    ShippingPackageCreateLabelShipToRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelShipToSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageCreateLabelShipToHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelShipToSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    ShippingPackageCreateLabelShipToText: {
        fontSize: '14px',
        textAlign: 'left',
        color: '#609966',
        fontWeight: '600',
    },
    ShippingPackageCreateLabelShipToWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageCreateLabelShipToModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelShipToSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelShipToAddressText: {
        fontSize: '14px'
    }
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingPackageCreateLabelShipTo = ({shipToHistory}) => {

    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width: 480px)');

    const token = getAccessToken("access_token");

    const dispatch = useDispatch();

    const history = useHistory();

    const {id: partnerId, email} = useSelector((state) => state.user);
    let validation = useSelector(state => state?.shippingPackage?.createLabelValidation);
    let shipToRedux = useSelector(state => state?.shippingPackage?.createLabelShipTo);

    const [shipToAddressPredictions, setShipToAddressPredictions] = useState([]);
    const [open, setOpen] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [searchAddressList, setSearchAddressList] = useState([]);
    const [verifiedAddress, setVerifiedAddress] = useState(null);
    const [isVerifiedAddress, setIsVerifiedAddress] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [shipTo, setShipTo] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: '',
        phone: null,
        email: '',
        company: '',
        saveAddress: false,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        }
    });

    const verifyAddress = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/verifyAddress/forWeb`;
        let data = {
            addressLines: [shipTo?.address, shipTo?.address2],
            city: shipTo?.city,
            province: shipTo?.province?.code,
            postalCode: shipTo?.postalCode,
            country: shipTo?.country
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            const verifiedAddress = result?.data
            console.log('result verify address', verifiedAddress);
            // setVerifiedAddress(verifiedAddress);
            if (verifiedAddress?.customerMessages?.length > 0) {
                setIsVerifiedAddress(true);
                setVerifiedAddress(null);
            } else if (verifiedAddress?.missingKeys?.length > 0) {
                setIsVerifiedAddress(false);
                setVerifiedAddress(verifiedAddress);
            } else if (
                !verifiedAddress?.correction?.addressMatch ||
                !verifiedAddress?.correction?.cityMatch ||
                !verifiedAddress?.correction?.provinceMatch ||
                !verifiedAddress?.correction?.countryMatch ||
                !verifiedAddress?.correction?.postalCodeMatch
            ) {
                setVerifiedAddress(verifiedAddress);
                setIsVerifiedAddress(true);
            } else {
                setIsVerifiedAddress(false);
                setVerifiedAddress(verifiedAddress);
            }
        } catch (e) {
            console.log('error', e?.response);
            setIsVerifiedAddress(true);
            setVerifiedAddress(null)
        } finally {
            setLoading(false);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const searchAddress = async (searchString) => {
        let requestURL = `${PARTNER_URI}/elasticsearch/addressBook/searchPartnerAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    role: 'RECEIVER',
                    queryString: searchString
                }
            })

            console.log('result', result?.data);
            const searchAddressResult = result?.data;
            // setSearchNameList(result?.data);
            return searchAddressResult;

        } catch (e) {
            console.log('error', e.response)
        }
    }

    const handleName = event => {
        setShipTo(prevState => ({
            ...prevState,
            name: event?.target?.value
        }))
    }

    const handleInputChangeName = async (event, value) => {
        console.log('value', value);
        setShipTo(prevState => ({
            ...prevState,
            name: value,
            addressList: {
                ...prevState.addressList,
                name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeName = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipTo(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                value: value?.value,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.value}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                name: ""
            }))
        }
    }

    const handlePhone = event => {
        setShipTo(prevState => ({
            ...prevState,
            phone: event?.target?.value
        }))
    }

    const handleInputChangePhone = async (event, value) => {
        console.log('value', value);
        // const trimmedValue = value.replace(/\s/g, '');
        setShipTo(prevState => ({
            ...prevState,
            phone: value,
            addressList: {
                ...prevState.addressList,
                phone: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.phone.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangePhone = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipTo(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                phone: ""
            }))
        }
    }

    const handleEmail = event => {
        setShipTo(prevState => ({
            ...prevState,
            email: event?.target?.value
        }))
    }

    const handleInputChangeEmail = async (event, value) => {
        console.log('value', value);
        setShipTo(prevState => ({
            ...prevState,
            email: value,
            addressList: {
                ...prevState.addressList,
                address_email: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.address_email.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeEmail = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipTo(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                email: ""
            }))
        }
    }

    const handleCompany = event => {
        setShipTo(prevState => ({
            ...prevState,
            company: event?.target?.value
        }))
    }

    const handleInputChangeCompany = async (event, value) => {
        console.log('value', value);
        setShipTo(prevState => ({
            ...prevState,
            company: value,
            addressList: {
                ...prevState.addressList,
                company_name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.company_name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeCompany = (event, value) => {
        console.log('value', value);
        if (value) {
            setShipTo(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                company: ""
            }))
        }
    }

    const handleAddress2 = event => {
        setShipTo(prevState => ({
            ...prevState,
            address2: event?.target?.value
        }))
    }

    const handleInputChangeAddress = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipToAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setShipTo(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleCity = event => {
        setShipTo(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleProvince = event => {
        setShipTo(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleCountry = event => {
        setShipTo(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handlePostalCode = event => {
        setShipTo(prevState => ({
            ...prevState,
            postalCode: event?.target?.value
        }))
    }

    const handleSaveAddress = event => {
        setShipTo(prevState => ({
            ...prevState,
            saveAddress: event.target.checked
        }))
    }

    const getShipToAddress = async () => {
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/deliver`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    partnerId: partnerId.toString()
                }
            })
            setAddressList(result.data.result);

        } catch (e) {
            console.log(e.response);
        }
    }

    const handleSelectAddress = (address) => {
        console.log('select address', address);
        setShipTo(prevState => ({
            ...prevState,
            postalCode: address?.postal_code,
            province: {
                code: address?.province,
                name: address?.province
            },
            city: address?.city,
            address: address?.address,
            address2: address?.address_2,
            country: address?.country,
            displayAddress: {
                description: `${address?.address}, ${address?.city}, ${address?.province}, ${address?.country}`,
                placeId: null,
                text: address?.address
            },
            name: address?.name,
            phone: address?.phone,
            email: address?.address_email,
            company: address?.company_name,
            saveAddress: false,
            addressList: address
        }))
        handleClose();
    }

    const handleNewAddress = () => {
        history.push('/settings/address-list')
    }

    // console.log('ship to local', shipTo);

    const handleValidateAddress = () => {
        verifyAddress();
    }

    const handleAcceptRecommendation = () => {
        setShipTo(prevState => ({
            ...prevState,
            postalCode: verifiedAddress?.postalCode,
            province: {
                code: verifiedAddress?.stateOrProvinceCode,
                name: verifiedAddress?.stateOrProvinceCode
            },
            city: verifiedAddress?.city,
            address: verifiedAddress?.streetLinesToken[0],
            address2: verifiedAddress?.streetLinesToken[1] || shipTo?.address2,
            country: verifiedAddress?.countryCode,
            displayAddress: {
                description: `${verifiedAddress?.streetLinesToken[0]}, ${verifiedAddress?.city}, ${verifiedAddress?.stateOrProvinceCode}, ${verifiedAddress?.countryCode}`,
                placeId: null,
                text: verifiedAddress?.streetLinesToken[0]
            },
        }))
        setVerifiedAddress(null);
        setIsVerifiedAddress(false);
    }

    useEffect(() => {
        dispatch(updateShipToPackageCreateLabelPackage(shipTo));
    }, [shipTo]);

    useEffect(() => {
        if (shipToRedux) {
            setShipTo(shipToRedux);
        }
        getShipToAddress();
    }, []);

    // console.log('address list', addressList);
    console.log('ship to', shipTo);
    console.log('ship to history', shipToHistory);

    // useEffect(() => {
    //     if (shipToHistory) {
    //         setShipTo(prevState => ({
    //             ...prevState,
    //             postalCode: shipToHistory?.postalCode,
    //             province: {
    //                 code: shipToHistory?.province?.code,
    //                 name: shipToHistory?.province?.name
    //             },
    //             city: shipToHistory?.city,
    //             address: shipToHistory?.displayAddress?.text,
    //             country: shipToHistory?.country?.code,
    //             displayAddress: shipToHistory?.displayAddress,
    //             saveAddress: false,
    //             addressList: shipToHistory?.addressList
    //         }))
    //     }
    // }, [shipToHistory])

    return (
        <Box sx={styles.ShippingPackageCreateLabelShipToRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSection}>
                        <Typography style={styles.ShippingPackageCreateLabelShipToHeading}>
                            {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                        </Typography>
                        <Stack direction='row' spacing={0} alignItems='center'>
                            <Button
                                onClick={handleOpen}
                                startIcon={<img src={addressIcon} width='35px'/>}>
                                <Typography style={styles.ShippingPackageCreateLabelShipToText}>
                                    {intl.get('SELECT_ADDRESS.ADDRESSES')}
                                </Typography>
                            </Button>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <InputLabel required>
                            {intl.get('SHIPPING_INFORMATION.NAME')}
                        </InputLabel>
                        <Autocomplete
                            freeSolo
                            value={shipTo?.addressList}
                            fullWidth
                            options={searchAddressList}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.name}
                            onInputChange={handleInputChangeName}
                            onChange={handleChangeName}
                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                className={classes.smallInput}
                                variant="outlined"
                            />}
                            renderOption={option => (
                                <Typography style={{fontSize: '12px'}}>
                                    {option?.name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                </Typography>
                            )}
                        />
                        <Typography style={styles.ShippingPackageCreateLabelShipToWarningText}>
                            {
                                validation?.shipToMissing?.includes('name') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ''
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <InputLabel required>
                            {intl.get('SHIPPING_INFORMATION.PHONE')}
                        </InputLabel>
                        <Autocomplete
                            freeSolo
                            value={shipTo?.addressList}
                            fullWidth
                            options={searchAddressList}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.phone}
                            onInputChange={handleInputChangePhone}
                            onChange={handleChangePhone}
                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                className={classes.smallInput}
                                variant="outlined"
                            />}
                            renderOption={option => (
                                <Typography style={{fontSize: '12px'}}>
                                    {option?.phone} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                </Typography>
                            )}
                        />
                        <Typography style={styles.ShippingPackageCreateLabelShipToWarningText}>
                            {
                                validation?.shipToMissing?.includes('phone') ? intl.get('SHIPPING_INFORMATION.REQUIRED') :
                                    shipTo?.phone === null ? "" :
                                        isValidatePhone(shipTo?.phone) ? "" : intl.get('SHIPPING_INFORMATION.VALID_PHONE')
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <InputLabel>
                            {intl.get('SHIPPING_INFORMATION.EMAIL')}
                        </InputLabel>
                        <Autocomplete
                            freeSolo
                            value={shipTo?.addressList}
                            fullWidth
                            options={searchAddressList}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.address_email}
                            onInputChange={handleInputChangeEmail}
                            onChange={handleChangeEmail}
                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                className={classes.smallInput}
                                variant="outlined"
                                placeholder={intl.get('SHIPPING_INFORMATION.RECOMMEND')}
                            />}
                            renderOption={option => (
                                <Typography style={{fontSize: '12px'}}>
                                    {option?.address_email} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                </Typography>
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <InputLabel>
                            {intl.get('SHIPPING_INFORMATION.COMPANY')}
                        </InputLabel>
                        <Autocomplete
                            freeSolo
                            value={shipTo?.addressList}
                            fullWidth
                            options={searchAddressList}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.company_name}
                            onInputChange={handleInputChangeCompany}
                            onChange={handleChangeCompany}
                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                className={classes.smallInput}
                                variant="outlined"
                            />}
                            renderOption={option => (
                                <Typography style={{fontSize: '12px'}}>
                                    {option?.company_name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                </Typography>
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <InputLabel required>
                            {intl.get('SHIPPING_INFORMATION.ADDRESS')}
                        </InputLabel>
                        <Autocomplete
                            value={shipTo?.displayAddress}
                            freeSolo
                            fullWidth
                            options={shipToAddressPredictions}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.text}
                            onInputChange={handleInputChangeAddress}
                            onChange={handleChangeAddress}
                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                variant="outlined"
                                className={classes.smallInput}
                            />}
                            renderOption={option => (
                                <Typography style={{fontSize: '12px'}}>
                                    {option.description}
                                </Typography>
                            )}
                        />
                        <Typography style={styles.ShippingPackageCreateLabelShipToWarningText}>
                            {
                                validation?.shipToMissing?.includes('address') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ""
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <InputLabel>
                            {intl.get('SHIPPING_INFORMATION.ADDRESS_2')}
                        </InputLabel>
                        <TextField
                            value={shipTo?.address2}
                            fullWidth
                            variant='outlined'
                            size='small'
                            onInput={handleAddress2}
                        />
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <InputLabel required>
                            {intl.get('SHIPPING_INFORMATION.CITY')}
                        </InputLabel>
                        <TextField
                            value={shipTo?.city}
                            fullWidth
                            variant='outlined'
                            size='small'
                            onInput={handleCity}
                        />
                        <Typography style={styles.ShippingPackageCreateLabelShipToWarningText}>
                            {
                                validation?.shipToMissing?.includes('city') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ""
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <InputLabel required>
                            {intl.get('SHIPPING_INFORMATION.PROVINCE')}
                        </InputLabel>
                        <TextField
                            value={shipTo?.province?.code}
                            fullWidth
                            variant='outlined'
                            size='small'
                            onInput={handleProvince}
                        />
                        <Typography style={styles.ShippingPackageCreateLabelShipToWarningText}>
                            {
                                validation?.shipToMissing?.includes('province') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ""
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <InputLabel required>
                            {intl.get('SHIPPING_INFORMATION.COUNTRY')}
                        </InputLabel>
                        <FormControl fullWidth>
                            <Select
                                value={shipTo?.country}
                                size='small'
                                onChange={handleCountry}
                            >
                                {
                                    getCountryCode().map(v => {
                                        return <MenuItem value={v.code}>{v.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        <Typography style={styles.ShippingPackageCreateLabelShipToWarningText}>
                            {
                                validation?.shipToMissing?.includes('country') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ""
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <InputLabel required>
                            {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                        </InputLabel>
                        <TextField
                            value={shipTo?.postalCode}
                            fullWidth
                            variant='outlined'
                            size='small'
                            onInput={handlePostalCode}
                        />
                        <Typography style={styles.ShippingPackageCreateLabelShipToWarningText}>
                            {
                                validation?.shipToMissing?.includes('postalCode') ? intl.get('SHIPPING_INFORMATION.REQUIRED') : ""
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipToSectionBox}>
                        <FormControlLabel
                            value="start"
                            control={<Checkbox
                                checked={shipTo?.saveAddress}
                                onChange={handleSaveAddress}
                            />}
                            label={
                                <Typography>
                                    {intl.get('SHIPPING_INFORMATION.SAVE')}
                                </Typography>
                            }
                            labelPlacement="end"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='outlined'
                            sx={{
                                border: '2px solid #1D8B45',
                                "&:hover": {
                                    border: '2px solid #1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleValidateAddress}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                                fontSize: '16px',
                                color: '#1D8B45',
                            }}>
                                {intl.get('SHIPPING_INFORMATION.VALIDATE')}
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
                {
                    (!isVerifiedAddress && verifiedAddress) && <>
                        <Grid item xs={12}>
                            <Typography style={{
                                color: '#1D8B45',
                                fontWeight: '600'
                            }}>
                                Address Verified Successfully
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{
                                fontSize: '14px'
                            }}>
                                Your shipping address aligns perfectly with the Canada Post AddressComplete. You're
                                good
                                to go!
                            </Typography>
                        </Grid>
                    </>
                }
                {
                    (verifiedAddress && isVerifiedAddress) && <>
                        <Grid item xs={12}>
                            <Typography style={{
                                color: '#1D8B45',
                                fontWeight: '600'
                            }}>
                                Verify Your Shipping Address
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{
                                fontSize: '14px',
                            }}>
                                Based on Canada Post AddressComplete, we've detected some discrepancies in the
                                address
                                you provided. For accurate shipping rates and timely delivery, consider our
                                recommended
                                address.
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography style={{
                                fontSize: '14px',
                                fontWeight: '600',
                            }}>
                                Original Address
                            </Typography>
                            <Typography style={{
                                fontSize: '14px',
                            }}>
                                {shipTo?.address2} {shipTo?.address}
                            </Typography>
                            <Typography style={{
                                fontSize: '14px',
                            }}>
                                {shipTo?.city}, {shipTo?.province?.code}, {shipTo?.country}, {shipTo?.postalCode}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography style={{
                                fontSize: '14px',
                                fontWeight: '600'
                            }}>
                                Recommended Address
                            </Typography>
                            <Typography style={{
                                fontSize: '14px',
                            }}>
                                {verifiedAddress?.streetLinesToken[1]} {verifiedAddress?.streetLinesToken[0]}
                            </Typography>
                            <Typography style={{
                                fontSize: '14px',
                            }}>
                                {verifiedAddress?.city}, {verifiedAddress?.stateOrProvinceCode}, {verifiedAddress?.countryCode}, {verifiedAddress?.postalCode}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleAcceptRecommendation}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                }}>
                                    Accept Recommendation
                                </Typography>
                            </Button>
                        </Grid>
                    </>
                }
                {
                    (isVerifiedAddress && !verifiedAddress) && <>
                        <Grid item xs={12}>
                            <Typography style={{
                                color: '#1D8B45',
                                fontWeight: '600'
                            }}>
                                Verify Your Shipping Address
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{
                                fontSize: '14px'
                            }}>
                                While your address did not fully match with Canada Post AddressComplete, it’s possible
                                your shipment can still proceed normally.
                            </Typography>
                        </Grid>
                    </>
                }
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={styles.ShippingPackageCreateLabelShipToModalBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                minWidth:  isMobile ? 300 : 400,
                            }}>
                                <Typography style={{fontSize: '20px'}}>
                                    {intl.get('SELECT_ADDRESS.ADDRESS_LIST')}
                                </Typography>
                                <Button
                                    color='success'
                                    onClick={handleNewAddress}
                                >
                                    {intl.get('SELECT_ADDRESS.ADD_NEW')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                    }}>
                        {
                            addressList?.length > 0 ? addressList.map((address, index) =>
                                    (
                                        <Box
                                            sx={styles.ShippingPackageCreateLabelShipToSelectBox}
                                            key={index}
                                            onClick={() => handleSelectAddress(address)}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                                        <HomeIcon color='success'/>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <Typography
                                                            style={styles?.ShippingPackageCreateLabelShipToAddressText}>
                                                            {address?.name}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingPackageCreateLabelShipToAddressText}>
                                                            {address?.address_2 && `${address?.address_2} - `}{address?.address}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingPackageCreateLabelShipToAddressText}>
                                                            {address?.city}, {address?.province}, {address?.country} {address?.postal_code}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingPackageCreateLabelShipToAddressText}>
                                                            {address?.address_email}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingPackageCreateLabelShipToAddressText}>
                                                            {address?.phone}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                {/*<Grid item xs={2}>*/}
                                                {/*    <Box sx={{display: 'flex', flexDirection: 'column'}}>*/}
                                                {/*        <Button>*/}
                                                {/*            */}
                                                {/*        </Button>*/}
                                                {/*    </Box>*/}
                                                {/*</Grid>*/}
                                            </Grid>
                                        </Box>

                                    ))
                                : <Grid item xs={12}>
                                    <Typography>
                                        {intl.get('SELECT_ADDRESS.NO_ADDRESS')}
                                    </Typography>
                                </Grid>
                        }
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}