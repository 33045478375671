import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox
} from "@material-ui/core";
import {useForm, Controller} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import {Pagination} from "@material-ui/lab";
import axios from "axios";
import {PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";
import {useSelector} from "react-redux";
import NavBar from "../navBar";
import {useLocation, useParams} from "react-router-dom";
import ShippingListTrackingTable from "../ShippingList/ShippingListTrackingTable";
import CommercialInvoiceTransactionTable from "./commercialInvoiceTransactionTable";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {CommercialInvoiceCreate} from "./commercialInvoiceCreate";
import {Container} from "@mui/material";
import {CommercialInvoiceForm} from "./CommercialInvoiceForm";

const useQuery = () => {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word",
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff",
    },
    table: {
        width: "100%",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
    },
    nameTH: {
        width: "100px",
    },
    idTH: {
        width: "100px",
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
}));

const CommercialInvoicePage = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(max-width:600px)");
    const pageSize = 100;

    const query = useQuery();

    const {order_id: default_order_id} = useParams();
    // const default_account_base = query.get("account_base") || "ups";

    // const partnerInfo = getPartnerInfo();
    const partnerInfo = useSelector((state) => state.user);

    const {register, handleSubmit, control} = useForm();
    const [shippingListData, setShippingListData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectUPS, setSelectUPS] = useState(false);
    const [selectFedEx, setSelectFedEx] = useState(false);
    const cacheInput = useRef({});
    const [tabValue, setTabValue] = useState('upload');

    let tabValueRedux = useSelector(state => state?.crossBorderInvoice?.commercialTab);

    useEffect(() => {
        setTabValue(tabValueRedux);
    }, [tabValueRedux])

    console.log('tabValueRedux', tabValueRedux);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const getData = async ({
                               order_id,
                               product_id,
                               ref_other_no,
                               cargo_control_no,
                               send_to_name,
                               send_to_tel,
                               send_to_addr,
                               currentPage
                           }) => {
        let requestURI = `${PARTNER_URI}/shipping/list?&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;

        if ((selectUPS && selectFedEx) || (!selectUPS && !selectFedEx)) {
            requestURI = `${PARTNER_URI}/shipping/list?&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;
        } else if (selectUPS) {
            requestURI = `${PARTNER_URI}/shipping/list?account_base=ups&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;
        } else if (selectFedEx) {
            requestURI = `${PARTNER_URI}/shipping/list?account_base=fedex&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;
            ;
        }
        const {data} = await axios.get(requestURI);
        const result = data.result;
        const resultTotalNumber = data.totalNumber;
        setTotalNumber(resultTotalNumber);
        setShippingListData(result);
        try {
        } catch (e) {
            setTotalNumber(0);
            console.log(e);
            setShippingListData([]);
        } finally {
            setIsLoading(false);
        }
    }

    const onSubmit = data => {
        // console.log(data);
        const {order_id, product_id, ref_other_no, cargo_control_no, send_to_name, send_to_addr, send_to_tel} = data;
        cacheInput.current = data;
        setPage(1);
        getData({
            order_id,
            product_id,
            ref_other_no,
            currentPage: 1,
            cargo_control_no,
            send_to_name,
            send_to_addr,
            send_to_tel
        });
    }
    const handlePageChange = (e, value) => {
        if (value !== page) {
            console.log(value);
            setPage(value);
            const {
                order_id,
                product_id,
                ref_other_no,
                cargo_control_no,
                send_to_name,
                send_to_addr,
                send_to_tel
            } = cacheInput.current;
            getData({
                order_id,
                product_id,
                ref_other_no,
                currentPage: value,
                cargo_control_no,
                send_to_name,
                send_to_addr,
                send_to_tel
            });
        }
    }

    useEffect(() => {
        if (partnerInfo?.id) {
            getData({
                order_id: default_order_id,
                currentPage: 1,
                cargo_control_no: "",
                product_id: "",
                ref_other_no: "",
                send_to_name: "",
                send_to_addr: "",
                send_to_tel: ""
            })
        }
    }, [partnerInfo?.id]);

    if (!partnerInfo?.id) {
        return <Box>Loading...</Box>
    }

    return (
        <Container maxWidth='xl'>
            <Box
                // component="form"
                // width="1000px"
                // margin="0 auto"
                // sx={{
                //     display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem"
                // }}
            >
                <h1>Commercial Invoice</h1>
                <Box>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab value="upload" label="Upload Invoice"></Tab>
                        <Tab value="create" label="Create Invoice"></Tab>
                        {/*<Tab value="create2" label="Create Invoice v2"></Tab>*/}
                    </Tabs>
                </Box>
                {tabValue === "upload" ?
                    <Box display="flex" justifyContent="start" alignItems="start" flexDirection="column">
                        <h3 style={{fontWeight: "normal"}}>
                            <b>Step 1</b>. <a
                            href="https://storage.googleapis.com/uucargo-resource/commercial-invoice/ups/commercial-invoice-sample.pdf"
                            target="_blank" rel="noopener noreferrer">
                            Download Sample Invoice PDF
                        </a>, and fill out the form by using <a href="https://get.adobe.com/reader/" target="_blank"
                                                                rel="noopener noreferrer">Acrobat Reader</a>.
                            <br/>
                        </h3>
                        <h3 style={{fontWeight: "normal"}}>
                            <span style={{visibility: "hidden"}}>Step 1.</span> Or use your own commercial invoice
                        </h3>
                        {/*<h3>*/}
                        {/*  Step 2. */}
                        {/*</h3>*/}
                        <h3 style={{fontWeight: "normal"}}>
                            <b>Step 2</b>. Upload the filled-out form for <b>every</b> Tracking #
                        </h3>
                        <h3 style={{fontWeight: "normal"}}>
                            <b>Step 3</b>: Submit the uploaded form for <b>every</b> Tracking #.
                        </h3>
                        <h3 style={{fontWeight: "normal"}}>
                            <b>Step 4</b>. (Recommended) Print the forms, seal them into a small bag and paste it
                            beside
                            the shipping label
                        </h3>
                    </Box>
                    :
                    // tabValue === "create" ?
                    //     <CommercialInvoiceCreate shippingListData={shippingListData}/>
                    //     :
                        <CommercialInvoiceForm shippingListData={shippingListData}/>
                }
            </Box>

            {/*todo: fix width*/}
            <br/>
            {
                isLoading ? (
                    <>
                        <Box>
                            <h1>Loading...</h1>
                        </Box>
                    </>
                ) : (
                    <Box>
                        <hr width="100%"/>
                        <Box>
                            {shippingListData.map((v, index) => {
                                return <CommercialInvoiceTransactionTable key={index}
                                                                          account_base={v.account_base}
                                                                          date={v.tranacted_at}
                                                                          gross_price={v?.amount_gross}
                                                                          net_price={v?.amount}
                                                                          order_id={v?.order_id}
                                                                          ref_product_no={v.ref_product_no}
                                                                          ref_other_no={v?.ref_other_no}
                                                                          ref_order_no={v?.ref_order_no}
                                                                          tran_id={v.tran_id}
                                                                          zip_label_uri={v?.label_pdf_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_pdf_uri}` : ""}
                                                                          label_pdf_uri={v?.label_pdf_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_pdf_uri}` : ""}
                                                                          send_to_addr={v.send_to_addr}
                                                                          send_to_city={v.send_to_city}
                                                                          send_to_province={v.send_to_province}
                                                                          send_to_country={v.send_to_country}
                                                                          send_to_postal_code={v.send_to_postal_code}
                                                                          send_to_name={v.send_to_name}
                                                                          send_to_tel={v.send_to_tel}
                                                                          send_to_email={v.send_to_email}
                                                                          currency_code={v?.currency_code || "CAD"}
                                                                          transactionNumbers={v.tran_tracking_nos}
                                                                          tran_status={v.tran_status}
                                                                          shipping_id_no={v.shipping_id_no}
                                />
                            })}
                        </Box>
                        <br/>
                        {/*<Box margin="auto" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>*/}
                        {/*  <Pagination count={Math.floor(totalNumber / pageSize) + 1}*/}
                        {/*              page={page}*/}
                        {/*              onChange={handlePageChange}*/}
                        {/*              className={classes.pagination}*/}
                        {/*              variant="outlined"*/}
                        {/*              shape="rounded" />*/}
                        {/*</Box>*/}
                    </Box>
                )
            }
        </Container>
    )
        ;
};

export default CommercialInvoicePage;
