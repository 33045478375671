import {Container, Divider, Icon, Snackbar} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect, useState, Fragment} from "react";
import GoogleMapReact from "google-map-react";
import {TransportationAvailableTasksMapMarker} from "./TransportationAvailableTasksMapMarker";
import RoomIcon from "@material-ui/icons/Room";
import Carousel from 'react-material-ui-carousel'
import Paper from "@mui/material/Paper";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import {useHistory, useParams} from "react-router-dom";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import React from 'react';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import {TransportationAvailableTasksSummary} from "./TransportationAvailableTasksSummary";
import {TransportationAvailableTasksCost} from "./TransportationAvailableTasksCost";
import {TransportationAvailableTasksAddress} from "./TransportationAvailableTasksAddress";
import {TransportationAvailableTasksDateAndTime} from "./TransportationAvailableTasksDateAndTime";
import {TransportationAvailableTasksContent} from "./TransportationAvailableTasksContent";
import {TransportationAvailableTasksAdditionalService} from "./TransportationAvailableTasksAdditionalService";

const styles = {
    ShippingPackageCreateLabelRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '100px 0'
    },
    ShippingPackageCreateLabelRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelRowRight: {
        display: 'flex',
        justifyContent: 'flex-end'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TransportationAvailableTasksDetails = () => {

    const {id} = useParams();
    const storedToken = getAccessToken("access_token");
    const history = useHistory();

    const [directions, setDirections] = useState(null);
    const [taskDetails, setTaskDetails] = useState(null);
    const [pickupCoordinate, setPickupCoordinate] = useState(null);
    const [deliverCoordinate, setDeliverCoordinate] = useState(null);
    const [viewMap, setViewMap] = useState(false);
    const [googleApi, setGoogleApi] = useState(null);
    const [additionalData, setAdditionalData] = useState(null);
    const [additionalService, setAdditionalService] = useState([]);
    const [storageService, setStorageService] = useState({
        cubicMeterSpace: 0,
        storageDays: 0
    });
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [paidPaymentList, setPaidPaymentList] = useState([]);
    const [pickupWarehouseCoordinate, setPickupWarehouseCoordinate] = useState(null);
    const [deliveryWarehouseCoordinate, setDeliveryWarehouseCoordinate] = useState(null);
    const [pickupClientCoordinate, setPickupClientCoordinate] = useState(null);
    const [deliverClientCoordinate, setDeliverClientCoordinate] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleViewMapOpen = () => {
        setViewMap(!viewMap);
    }

    const isValidJSON = (jsonString) => {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    }

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    }

    const claimOrder = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/partnerClaimMovingOrder`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    backendOrderId: id
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully claim order.');
            setTimeout(() => {
                history.push(`/transportation/claimTasks/details/${id}`);
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage("Fail to claim order.");
        } finally {
            setLoading(false);
        }
    }

    const handlePickupClientCoordinate = async (coordinate, address) => {
        if (coordinate) {
            const [latitude, longitude] = coordinate.split(', ');
            setPickupClientCoordinate(prevState => ({
                ...prevState,
                lat: +latitude,
                lng: +longitude
            }))
        } else {
            const location = await convertAddressToCoordinate(address);
            // console.log('[TransportationAvailableTasksDetails] handlePickupCoordinate - location', location);
            setPickupClientCoordinate(prevState => ({
                ...prevState,
                lat: +location?.lat,
                lng: +location?.lng
            }));
        }
    }

    const handleDeliverClientCoordinate = async (coordinate, address) => {
        if (coordinate) {
            const [latitude, longitude] = coordinate.split(', ');
            setDeliverClientCoordinate(prevState => ({
                ...prevState,
                lat: +latitude,
                lng: +longitude
            }))
        } else {
            const location = await convertAddressToCoordinate(address);
            // console.log('[TransportationAvailableTasksDetails] handlePickupCoordinate - location', location);
            setDeliverClientCoordinate(prevState => ({
                ...prevState,
                lat: +location?.lat,
                lng: +location?.lng
            }));
        }
    }

    const getOrderDetails = async () => {
        let requestURL = `${PARTNER_URI}/movingService/getMovingOrderDashBoardDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                params: {
                    backendOrderId: id
                }
            });
            console.log('[TransportationAvailableTasksDetails] getOrderDetails - result', result?.data);
            setTaskDetails(result?.data);
            if (result?.data?.pickup_warehouse?.id === result?.data?.deliver_warehouse?.id) {
                await handlePickupCoordinate(result?.data?.pickup_addr_lat_lng, result?.data?.pickup_address);
                await handleDeliverCoordinate(result?.data?.deliver_addr_lat_lng, result?.data?.deliver_address);
            } else {
                await handlePickupCoordinate(result?.data?.pickup_addr_lat_lng, result?.data?.pickup_address);
                setDeliverCoordinate(prevState => ({
                    ...prevState,
                    lat: +result?.data?.pickup_warehouse?.address_latitude,
                    lng: +result?.data?.pickup_warehouse?.address_longitude
                }))
            }
            await handlePickupClientCoordinate(result?.data?.pickup_addr_lat_lng, result?.data?.pickup_address);
            await handleDeliverClientCoordinate(result?.data?.deliver_addr_lat_lng, result?.data?.deliver_address);
            setPickupWarehouseCoordinate(prevState => ({
                ...prevState,
                lat: +result?.data?.pickup_warehouse?.address_latitude,
                lng: +result?.data?.pickup_warehouse?.address_longitude
            }));
            setDeliveryWarehouseCoordinate(prevState => ({
                ...prevState,
                lat: +result?.data?.deliver_warehouse?.address_latitude,
                lng: +result?.data?.deliver_warehouse?.address_longitude
            }))
            if (result?.data?.messages) {
                const jsonString = result?.data?.messages;
                const json = JSON.parse(jsonString);
                if (isValidJSON(jsonString) && !isEmptyObject(json)) {
                    const jsonData = JSON.parse(jsonString);
                    setAdditionalData(jsonData);
                    const additionalServicesArray = Object.keys(jsonData?.additionalService)
                        .filter(service => jsonData?.additionalService[service] === true)
                        .map(service => {
                            const capitalizedService = service.charAt(0).toUpperCase() + service.slice(1);
                            return capitalizedService.replace(/([a-z])([A-Z])/g, '$1 $2');
                        });
                    setAdditionalService(additionalServicesArray);
                }
                if (isValidJSON(jsonString)) {
                    const jsonData = JSON?.parse(jsonString);
                    if (jsonData?.additionalService?.storageService) {
                        const jsonStorageService = jsonData?.additionalService?.storageService;
                        setStorageService(jsonStorageService);
                    }
                }
            }

            const paidPaymentList = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "CAPTURED_SUCCEEDED");
            setPaidPaymentList(paidPaymentList);

        } catch (e) {
            console.log('[TransportationAvailableTasksDetails] getOrderDetails - error', e.response);
        } finally {

        }
    }

    const createIconUrl = (iconComponent) => {
        const svgString = new XMLSerializer().serializeToString(
            React.createElement('svg', {xmlns: 'http://www.w3.org/2000/svg'}, iconComponent.props.children)
        );
        return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;
    };

    const calculateDirections = (directionsService, directionsRenderer) => {
        directionsService.route(
            {
                origin: new googleApi.maps.LatLng(pickupCoordinate?.lat, pickupCoordinate?.lng), // pickup
                destination: new googleApi.maps.LatLng(deliverCoordinate?.lat, deliverCoordinate?.lng), // deliver
                travelMode: googleApi.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === googleApi.maps.DirectionsStatus.OK) {

                    // Suppress default markers
                    directionsRenderer.setOptions({
                        suppressMarkers: true,
                    });

                    directionsRenderer.setDirections(result);

                    // Add custom markers for pickup and delivery
                    new googleApi.maps.Marker({
                        position: new googleApi.maps.LatLng(pickupCoordinate.lat, pickupCoordinate.lng),
                        map: directionsRenderer.getMap(),
                        // label: 'P', // Custom label for pickup,
                        icon: {
                            path: googleApi.maps.SymbolPath.CIRCLE,
                            scale: 6, // Adjust the scale as needed
                            fillColor: '#FFFFFF', // Set the fill color
                            fillOpacity: 1, // Set the fill opacity
                            strokeWeight: 2, // Set the stroke weight to 0 for no border
                        },
                    });

                    new googleApi.maps.Marker({
                        position: new googleApi.maps.LatLng(deliverCoordinate.lat, deliverCoordinate.lng),
                        map: directionsRenderer.getMap(),
                        label: 'D', // Custom label for delivery
                    });

                } else {
                    console.error(`Directions request failed due to ${status}`);
                }
            }
        );
    };

    const convertAddressToCoordinate = async (address) => {
        let requestURL = `${PARTNER_URI}/googleMaps/findPlace`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: address
                }
            });

            const location = result?.data?.data?.candidates[0]?.geometry?.location;
            return location;
        } catch (e) {
            console.log('error', e?.response);
        }
    }

    const handlePickupCoordinate = async (coordinate, address) => {
        if (coordinate) {
            const [latitude, longitude] = coordinate.split(', ');
            setPickupCoordinate(prevState => ({
                ...prevState,
                lat: +latitude,
                lng: +longitude
            }))
        } else {
            const location = await convertAddressToCoordinate(address);
            // console.log('[TransportationAvailableTasksDetails] handlePickupCoordinate - location', location);
            setPickupCoordinate(prevState => ({
                ...prevState,
                lat: +location?.lat,
                lng: +location?.lng
            }));
        }
    }

    const handleDeliverCoordinate = async (coordinate, address) => {
        if (coordinate) {
            const [latitude, longitude] = coordinate.split(', ');
            setDeliverCoordinate(prevState => ({
                ...prevState,
                lat: +latitude,
                lng: +longitude
            }))
        } else {
            const location = await convertAddressToCoordinate(address);
            // console.log('[TransportationAvailableTasksDetails] handlePickupCoordinate - location', location);
            setDeliverCoordinate(prevState => ({
                ...prevState,
                lat: +location?.lat,
                lng: +location?.lng
            }));
        }
    }

    const handleClaimOrder = () => {
        claimOrder();
    }

    const Marker = ({text}) => (
        <Box sx={{cursor: 'pointer'}}>
            <Typography style={{zIndex: '10', color: 'red'}}>
                {text}
            </Typography>
        </Box>
    );

    const handleChangeRouteCoordinate = (data) => {
        if (data === 'pickup') {
            setPickupCoordinate(pickupWarehouseCoordinate);
            setDeliverCoordinate(pickupClientCoordinate);
        } else {
            setPickupCoordinate(deliveryWarehouseCoordinate);
            setDeliverCoordinate(deliverClientCoordinate);
        }
    }

    useEffect(() => {
        getOrderDetails();
    }, []);

    useEffect(() => {

        console.log('window,google', window.google);

        if (googleApi) {
            const directionsService = new googleApi.maps.DirectionsService();
            const directionsRenderer = new googleApi.maps.DirectionsRenderer();
            calculateDirections(directionsService, directionsRenderer);
            setDirections(directionsRenderer);

            // Listen for the 'directions_changed' event on the DirectionsRenderer
            googleApi.maps.event.addListener(directionsRenderer, 'directions_changed', () => {
                // After the directions are changed (i.e., rendered), check if the map is fully loaded
                if (googleApi.map) {
                    googleApi.maps.event.addListenerOnce(googleApi.map, 'tilesloaded', () => {
                        // console.log('pickup coordinate', pickupCoordinate);
                        // console.log('deliver coordinate', deliverCoordinate);
                        // check if the pickup and deliver coordinate is same
                        if (pickupCoordinate.lat === deliverCoordinate.lat && pickupCoordinate.lng === deliverCoordinate.lng) {
                            googleApi.map.setZoom(10); // Set your desired zoom level here
                        }
                    });
                }
            });
        }
    }, [googleApi]);

    useEffect(() => {
        if (directions) {
            directions.setMap(googleApi.map);
        }
    }, [directions]);

    if (taskDetails && taskDetails?.shipping_status_id !== 1) {
        history.push(`/transportation/claimTasks/details/${id}`)
    }


    console.log('[TransportationAvailableTasksDetails] pickupCoordinate - pickupCoordinate', pickupCoordinate);
    console.log('[TransportationAvailableTasksDetails] pickupCoordinate - deliverCoordinate', deliverCoordinate);
    console.log('[TransportationAvailableTasksDetails] pickupCoordinate - directions', directions);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#454545',
                                "&:hover": {
                                    backgroundColor: '#454545',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={() => history.push('/transportation/availableTasks')}
                        >
                            <Typography style={{
                                textAlign: 'left',
                                textTransform: 'none'
                            }}>
                                Back To Order List
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            loading={loading}
                            onClick={handleClaimOrder}
                        >
                            <Typography style={{
                                textAlign: 'left',
                                textTransform: 'none'
                            }}>
                                Claim Order
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TransportationAvailableTasksSummary taskDetails={taskDetails}/>
                </Grid>
                <Grid item xs={12}>
                    <TransportationAvailableTasksCost taskDetails={taskDetails} paidPaymentList={paidPaymentList}/>
                </Grid>
                <Grid item xs={12}>
                    <TransportationAvailableTasksAddress taskDetails={taskDetails} pickupCoordinate={pickupCoordinate}
                                                         googleApi={googleApi}
                                                         setGoogleApi={setGoogleApi}
                                                         additionalData={additionalData}
                                                         handleChangeRouteCoordinate={handleChangeRouteCoordinate}/>
                </Grid>
                <Grid item xs={12}>
                    <TransportationAvailableTasksDateAndTime taskDetails={taskDetails}/>
                </Grid>
                <Grid item xs={12}>
                    <TransportationAvailableTasksContent taskDetails={taskDetails}/>
                </Grid>
                <Grid item xs={12}>
                    <TransportationAvailableTasksAdditionalService additionalService={additionalService} storageService={storageService} />
                </Grid>
            </Grid>
        </Container>
    )
}
