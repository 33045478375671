import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Snackbar,
} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import OrderPaymentTable from "./OrderPaymentTable";
import { SERVICE_DAO_URL, NODE_API, PARTNER_URI } from "../../../../utils/apiUrl";
import { useSelector } from "react-redux";

const AllPartnerShippingOrderPaymentList = () => {
  const { register, handleSubmit, control } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [orderList, setOrderList] = useState([]);

  const partnerInfo = useSelector((state) => state.user);
  // useEffect(() => {
  //   getNotPaid3PLShippingOrderListForEndUser();
  // }, []);

  // const getNotPaid3PLShippingOrderListForEndUser = async () => {
  //   try {
  //     const result = await axios.get(
  //       NODE_ROUTE_URI + "/orderPaymentManagementRouter/getAllShippingOrderListForOperator"
  //     );
  //     if (result.status === 200) {
  //       console.log(result.data)
  //       const newOrderList = await result.data;
  //       setOrderList(newOrderList)
  //     } else {
  //       setErrorMessage("Failed to retrieve Partner Shipping List List")
  //       setToastOpen(true)
  //     }
  //   } catch (e) {
  //     setErrorMessage("Failed to retrieve Partner Shipping List List")
  //     setToastOpen(true)
  //   }
  // };

  // Search for specific order
  const onSubmit = async (data) => {
    const userId = partnerInfo.id

    console.log(data)
    if (!data.search && data.search.trim() === "") {
      console.log("Empty search")
      return
    }

    try {
      const result = await axios.get(
        PARTNER_URI +
          "/shipping/list/searchPartnerShippingOrder",
        {
          params: {
            query_string: data.search,
            partnerId: userId,
          },
        }
      );
      if (result.status === 200) {
        const newOrderList = await result.data;
        console.log(newOrderList)
        setOrderList(newOrderList)
      } else {
        setErrorMessage("Failed to search Partner Shipping List")
        setToastOpen(true)
      }
    } catch (e) {
      setErrorMessage("Failed to search Partner Shipping List")
      setToastOpen(true)
    }
  };

  const [toastOpen, setToastOpen] = useState(false);
  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  return (
    <Box paddingBottom="3rem">
      {/*Pop-up Toast*/}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={3000}
        message="Submit Transaction"
      >
        {(() => {
          if (errorMessage !== "") {
            return (
              <Alert
                onClose={handleToastClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Error!
                <hr />
                {errorMessage}
              </Alert>
            );
          }
          return (
            <Alert
              onClose={handleToastClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {successMessage}
            </Alert>
          );
        })()}
      </Snackbar>
      <Box>
        <Box
          component="form"
          width={"1000px"}
          margin="0 auto"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            gap: "2rem",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <h1>All Partner Shipping Orders List</h1>
          {/* <Link className={classes.link} to={`/v2/Partneradd`}>
            <Button color="secondary" variant="outlined">
              Add Partner
            </Button>
          </Link> */}
          <Box flex="45%" width="100%">
            <TextField
              id="outlined-basic"
              label="Search by name, address, order ID, order # and tracking #"
              variant="outlined"
              {...register("search")}
              fullWidth
            />
          </Box>
          <Box display="flex" sx={{ gap: "3rem" }}>
            <LoadingButton
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "7rem",
              }}
              loading={isLoading}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Search
            </LoadingButton>
          </Box>
        </Box>
        <hr style={{ marginBlock: "3rem" }} />
        {isLoading ? (
          <>
            <Box>
              <h1>Loading...</h1>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Box>
                <Box mt={2}>
                  <OrderPaymentTable orderList={orderList} />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default AllPartnerShippingOrderPaymentList