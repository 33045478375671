import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem",
    textAlign: "left"
  },
}));

export default function CommercialInvoiceBorderDocTable({
                                                          fileName,
                                                          documentId,
                                                          fileURI,
                                                          uploadTime
                                                        }) {
  const classes = useStyles();

  return (
      <>
        <tr className={classes.listItemWrapper}>
          <td>{fileName}</td>
          <td>{documentId}</td>
          <td>
            <a href={fileURI} target="_blank" rel="noopener noreferrer">{fileURI ? "View" : ""}</a>
          </td>
          <td>{dayjs(uploadTime).format("YYYY-MM-DD HH:mm")}</td>
        </tr>
      </>
  );
}
