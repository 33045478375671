import React, {useState, useEffect} from "react";
import {
    Box,
    useMediaQuery,
    Button,
    Snackbar,
    CircularProgress,
    DialogTitle,
    Dialog,
    List,
    ListItem,
    ListItemText,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Modal,
    Typography,
    IconButton,
    DialogContent, Divider,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
// import ReCAPTCHA from "react-google-recaptcha";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {useSelector} from "react-redux";
import produce from "immer";
import {ReactComponent as CloseIcon} from "../../images/close.svg";

import {
    UPLOAD_SAMPLE_LABEL_XLSX_FILE,
    UPLOAD_RATING_XLSX_FILE,
    UPLOAD_SHIPPING_XLSX_FILE,
    PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX,
} from "../../utils/apiUrl";
import {getHeaders, errHandling} from "../../utils/fetchUtils";
import CustomInput from "../../shared/customInput";
import CsvRateTableItem from "./csvRateTableItem";
import CsvShipTableItem from "./csvShipTableItem";
import AddressInputCombo from "./Components/addressInputCombo";
import NavBar from "../navBar";
import axios from "axios";
import {
    addSenderAddressBookList,
    deleteAddressBookByPartnerIdAndAddressBookId,
    deleteAddressBookList,
    getAddressBookList,
    getSenderAddressBookList,
    saveAddressBookList,
} from "../../utils/addressBookData";
import getProvinceCodeByName, {
    getCanadaProvinceNameByCode,
    getUSProvinceCodeByName,
    getUSProvinceNameByCode,
} from "../../utils/getProvinceCode";
import getProvinceCode from "../../utils/getProvinceCode";
import CsvRateErrorTableItem from "./csvRateErrorTableItem";
import {Link, useHistory} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const initForm = {
    name: "",
    address: "",
    addressLineTwo: "",
    zipCode: "",
    city: "",
    province: "",
    phone: "",
    country: "CA",
    email: "",
    companyName: ""
};

const accountOptions = [
    "UPS",
    "FEDEX",
    "CANPAR",
    "CANADA POST"
];

const accountOptionsValue = {
    "UPS": "UPS",
    "FEDEX": "FEDEX",
    "CANPAR": "CANPAR",
    "CANADA POST": "CANADAPOST",
};

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    border: '1px solid #609966',
    borderRadius: '25px',
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        width: '100%',
        backgroundColor: 'transparent',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        color: '#000000',
        // border: '1px solid #609966',
        // borderRadius: '25px',
        '&.Mui-selected': {
            color: '#ffffff',
            backgroundColor: '#609966',
            borderRadius: '25px'
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

function ShippingViaExcel() {

    const history = useHistory();

    const classes = useStyles();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const [tabValue, setTabValue] = useState('uploadExcel');

    const {id, email} = useSelector((state) => state.user);

    const matches = useMediaQuery("(max-width:600px)");

    const matches800 = useMediaQuery("(max-width:800px)");

    // Form data to store all the address information
    const [formatData, setFormatData] = useState(initForm);

    // Optional ref order number
    const [refOrderNumber, setRefOrderNumber] = useState("");

    // Optional cargo control number
    const [cargoControlNumber, setCargoControlNumber] = useState("");

    // const [errMsg, setErrMsg] = useState("");
    const [uploadErrMsg, setUploadErrMsg] = useState("");

    const [fileObj, setFileObj] = useState(null);

    const [contactFileObj, setContactFileObj] = useState(null);

    const [fileName, setFileName] = useState(null);

    const [uploading, setUploadingState] = useState(false);

    const [optionEmtpy, setOptionEmpty] = useState(false);

    const [rateSuccess, setRateSuccess] = useState(false);

    const [listRateData, setListRateData] = useState([]);

    const [listShippingData, setListShippingData] = useState([]);

    const [uploadingContact, setUploadingContactState] = useState(false);

    const [uploadingContactShipping, setUploadingContactShippingState] =
        useState(false);

    const [uploadingSuccess, setUploadingSuccessState] = useState(false);

    const [uploadingContactSuccess, setUploadingContactSuccessState] =
        useState(false);

    const [account, setAccount] = useState("UPS");

    const [typeValue, setTypeValue] = useState("rate");

    const [totalPriceWithTaxes, setTotalPriceWithTaxes] = useState("");

    const [totalChargeWithTaxesValue, setTotalChargeWithTaxesValue] = useState(0);

    const [finalTotalAmountValue, setFinalTotalAmountValue] = useState(0);

    const [totalChargeValue, setTotalChargeValue] = useState(0);

    const [ratingShipmentItems, setRatingShipmentItems] = useState([]);

    const [shippingShipmentItems, setShippingShipmentItems] = useState([]);

    const [ratingErrorsRows, setRatingErrorsRow] = useState([]);
    const [shippingErrorsRows, setShippingErrorsRows] = useState([]);
    const [resultOrderId, setResultOrderId] = useState(null);
    const [resultOrderStatus, setResultOrderStatus] = useState(null);

    const [theInputKey, setTheInputKey] = useState(null);

    const [dialogOpen, setDialogOpen] = useState(false);

    const [gettingSampleLabel, setGettingSampleLabel] = useState(false);

    const [sampleLabel, setSampleLabel] = useState("");

    const [isSaveAddress, setIsSaveAddress] = useState(false);
    const [openAddressBook, setOpenAddressBook] = useState(false);
    const [addressBookList, setAddressBookList] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // const [recaptchaValue, setRecaptchaValue] = useState(undefined);

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleOpenAddressBook = async () => {
        setAddressBookList(await getSenderAddressBookList({partnerId: id}));
        setOpenAddressBook(true);
    };

    const handleCloseAddressBook = () => {
        setOpenAddressBook(false);
    };

    const handleSaveAddress = () => {
        setIsSaveAddress((v) => !v);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const functionThatResetsTheFileInput = () => {
        const randomString = Math.random().toString(36);
        setTheInputKey(randomString);
    };

    const handleChange = (_value) => {
        setTypeValue(_value);
    };

    const checkIfRefOrderNumberExist = async () => {
        try {
            const {data} = await axios.post(
                `${PARTNER_URI}/shipping/list/isRefOrderNumberExist`,
                {
                    partnerId: id,
                    refOrderNumber,
                }
            );
            return data?.isDuplicated || false;
        } catch (e) {
            console.log(e);
        }
    };

    const handleAddressChange = (fieldName) => (e) => {
        setFormatData(
            produce((draft) => {
                draft[fieldName] = e.target.value;
            })
        );
    };

    const onDrop = (_type) => (e) => {
        if (!(e.target.files && e.target.files[0])) return;
        setUploadingSuccessState(false);
        setRefOrderNumber(e.target.files[0].name);
        if (_type === "bol") {
            setFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
        } else {
            setContactFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
            setFileName(e.target.files[0].name);
        }
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCloseSnackBar = () => {
        setUploadErrMsg("");
    };

    useEffect(() => {
        console.log(addressBookList);
    }, [addressBookList]);

    const handleSubmit = async (_type) => {
        if (!account) {
            setOptionEmpty(true);
            return;
        }
        const bodyFormData = new FormData();
        const tmpFileObj = _type === "bol" ? fileObj : contactFileObj;
        const requestFormatData = {...formatData};

        // Check if reference order number exist
        if (await checkIfRefOrderNumberExist()) {
            setErrorMessage(
                "The Reference Order Number has already existed! Please change to new one."
            );
            handleToastClick();
            return;
        }

        setErrorMessage("");

        if (tmpFileObj?.selectedFile) {
            const {
                city,
                province,
                zipCode,
                phone,
                address,
                country,
                addressLineTwo,
                name,
                email,
                companyName
            } = requestFormatData;
            // Save Address to address book list
            if (isSaveAddress) {
                console.log("Save address!");
                addSenderAddressBookList({
                    partnerId: id,
                    address: address,
                    address_2: addressLineTwo || "",
                    country: country || "CA",
                    city,
                    province:
                        country === "US"
                            ? getUSProvinceCodeByName(province)
                            : getProvinceCodeByName(province),
                    phone: phone || "",
                    postalCode: zipCode,
                    name,
                    email,
                    addressEmail: email
                });
            }
            requestFormatData.province =
                requestFormatData.country === "US"
                    ? getUSProvinceCodeByName(requestFormatData.province)
                    : getProvinceCode(requestFormatData.province);
            console.log("Province:", requestFormatData.province);

            _type === "bol"
                ? setUploadingState(true)
                : setUploadingContactState(true);
            // const accountValue =
            //   account === "UPS: UUC-TRO" ? "upsuuctro" : "upsjlink";
            const accountValue = accountOptionsValue[account];

            try {
                bodyFormData.append("xlsx_file", tmpFileObj.selectedFile);
                // bodyFormData.append("accountBaseCombinedName", accountValue);
                bodyFormData.append("accountBase", accountValue);
                bodyFormData.append("shipFromAddress", address);
                bodyFormData.append("shipFromAddressTwo", addressLineTwo);
                bodyFormData.append("shipFromCity", city);
                bodyFormData.append("shipFromProvince", requestFormatData.country === "US" ? getUSProvinceCodeByName(requestFormatData.province) : getProvinceCode(requestFormatData.province));
                bodyFormData.append("shipFromPostalCode", zipCode);
                bodyFormData.append("shipFromCountry", country);
                bodyFormData.append("shipFromPhone", phone);

                const {data} = await axios.post(`${PARTNER_URI}/loose-item/3rd-party/excel/ratesFromService`, bodyFormData, {
                    headers: {
                        ...getHeaders(),
                        "Content-Type": "multipart/form-data;",
                        "X-Requested-With": "XMLHttpRequest",
                    },
                })
                const {
                    totalChargeWithTaxes,
                    totalCharge,
                    finalTotalAmount,
                    shipmentItems,
                    errors,
                    serviceFee,
                    serviceFeeTax
                } = data;

                setTotalChargeWithTaxesValue(+totalChargeWithTaxes.value);
                setTotalChargeValue(+totalCharge.value);
                setFinalTotalAmountValue(+finalTotalAmount.value);
                setRatingShipmentItems(shipmentItems);
                setRatingErrorsRow(errors);
                setTotalPriceWithTaxes(+finalTotalAmount.value);

                setUploadingContactSuccessState(true);
                setUploadingContactState(false);
                setRateSuccess(true);
            } catch (e) {
                console.log(e);
                errHandling(e, setUploadErrMsg);
                _type === "bol"
                    ? setUploadingState(false)
                    : setUploadingContactState(false);
            }
        }
    };

    const handleSubmitForShipping = async () => {
        if (!account) {
            setOptionEmpty(true);
            return;
        }
        // const tmpFileObj = contactFileObj;
        if (fileName) {
            // setUploadingContactState(true);
            setUploadingContactShippingState(true);
            // const accountValue = account === "UPS: JLINK" ? "upsjlink" : "upsuuctro";
            try {
                const accountValue = accountOptionsValue[account];
                const requestBody = {
                    accountBase: accountValue,
                    shipFromAddress: formatData.address,
                    shipFromAddressTwo: formatData.addressLineTwo || "",
                    shipFromCity: formatData.city,
                    shipFromProvince: getProvinceCodeByName(formatData.province),
                    shipFromPostalCode: formatData.zipCode.replace(/\s*/g, ""),
                    shipFromCountry: formatData.country,
                    shipFromPhone: formatData.phone,
                    shipFromName: formatData.name,
                    shipFromEmail: formatData.email,
                    refOrderNumber: refOrderNumber,
                    cargoControlNumber: cargoControlNumber,
                    fileName: fileName,
                }
                const {data} = await axios.post(`${PARTNER_URI}/loose-item/3rd-party/excel/shippingFromServiceViaFileName`, requestBody, {
                    headers: {
                        ...getHeaders(),
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                    }
                })
                const {shippingTransactions, shippingOrder, errors} = data;
                console.log("result order: ", shippingOrder);
                setShippingShipmentItems(shippingTransactions);
                setResultOrderId(shippingOrder.order_id);
                setResultOrderStatus(shippingOrder.order_status);
                setShippingErrorsRows(errors);
                setUploadingContactSuccessState(true);
                setUploadingContactShippingState(false);
            } catch (e) {
                console.log(e);
                errHandling(e, setUploadErrMsg);
            } finally {
                setUploadingContactShippingState(false);
            }

            // const accountValue = accountOptionsValue[account];
            //
            // const insertObj = {
            //   accountBaseCombinedName: accountValue,
            //   shipFromAddressData: {
            //     ...formatData,
            //     province: getProvinceCodeByName(formatData.province),
            //     zipCode: formatData.zipCode.replace(/\s*/g, ""),
            //   },
            //   // partnerId: id,
            //   refOrderNumber: refOrderNumber,
            //   cargoControlNumber: cargoControlNumber,
            //   fileName: fileName,
            // };
            // axios({
            //   method: "post",
            //   url: UPLOAD_SHIPPING_XLSX_FILE,
            //   data: JSON.stringify(insertObj),
            //   headers: {
            //     ...getHeaders(),
            //     "Content-Type": "application/json",
            //     "X-Requested-With": "XMLHttpRequest",
            //   },
            // })
            //   .then((res) => {
            //     setListShippingData(res.data.result);
            //     setUploadingContactSuccessState(true);
            //     setUploadingContactShippingState(false);
            //     // setContactFileObj(null);
            //     // setUploadingContactState(false);
            //   })
            //   .catch((error) => {
            //     errHandling(error, setUploadErrMsg);
            //     // setUploadingContactState(false);
            //     setUploadingContactShippingState(false);
            //   });
        }
    };

    const handleSubmitForSampleLabel = () => {
        if (!account) {
            setOptionEmpty(true);
            return;
        }
        // const tmpFileObj = contactFileObj;
        if (fileName) {
            // setUploadingContactState(true);
            setGettingSampleLabel(true);
            // const accountValue = account === "UPS: JLINK" ? "upsjlink" : "upsuuctro";
            const accountValue = accountOptionsValue[account];

            const insertObj = {
                accountBaseCombinedName: accountValue,
                accountBase: accountValue,
                shipFromAddressData: {
                    ...formatData,
                    province: getProvinceCodeByName(formatData.province),
                    zipCode: formatData.zipCode.replace(/\s*/g, ""),
                },
                partnerId: id,
                refOrderNumber: refOrderNumber,
                cargoControlNumber: cargoControlNumber,
                fileName: fileName,
            };

            axios({
                method: "post",
                url: `${PARTNER_URI}/loose-item/3rd-party/excel/getSampleLabel`,
                data: JSON.stringify(insertObj),
                headers: {
                    ...getHeaders(),
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                },
            })
                .then((res) => {
                    setSampleLabel(res?.data?.png || "");
                    setGettingSampleLabel(false);
                    // setContactFileObj(null);
                })
                .catch((error) => {
                    console.log(error);
                    errHandling(error, setUploadErrMsg);
                    // setUploadingContactState(false);
                    setGettingSampleLabel(false);
                });
        }
    };

    useEffect(() => {
        if (tabValue === 'createLabel') {
            history.push('/shipping-package/create-label');
        } else {
            return;
        }
    }, [tabValue]);

    const example = (data) => (
        <img
            src={`data:image/jpeg;base64,${data}`}
            alt="label"
            width={700}
            height="auto"
        />
    );

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                Error: {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            Place Order Success!
                        </Alert>
                    );
                })()}
            </Snackbar>
            {/*Pop-up Toast*/}
            <Dialog
                open={openAddressBook}
                onClose={handleCloseAddressBook}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    <Box textAlign="center">Address Book</Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        sx={{gap: "1rem"}}
                    >
                        {addressBookList.map((item) => (
                            <Box display="flex" mb={2} key={item.id} width="100%">
                                <Box
                                    p={1}
                                    width="100%"
                                    component="button"
                                    sx={{border: "1px dashed grey"}}
                                    className={classes.addressLine}
                                    onClick={() => {
                                        setFormatData({
                                            phone: item.phone,
                                            address: item.address,
                                            zipCode: item.postal_code,
                                            addressLineTwo: item.address_2 || "",
                                            city: item.city,
                                            province:
                                                item.country === "US"
                                                    ? getUSProvinceNameByCode(item.province)
                                                    : getCanadaProvinceNameByCode(item.province),
                                            country: item.country,
                                            name: item.name,
                                            email: item?.address_email || ""
                                        });
                                        setOpenAddressBook(false);
                                    }}
                                >
                                    <h4>Name: {item.name}</h4>
                                    <h4>Phone: {item.phone}</h4>
                                    <h4>Email: {item?.address_email}</h4>
                                    <h4>
                                        Address: {item.address}, {item.city}, {item.province},{" "}
                                        {item.country}, {item.postal_code}
                                    </h4>
                                </Box>
                                <IconButton
                                    onClick={async () => {
                                        // Remove this address by item id
                                        const itemId = item.id;
                                        setAddressBookList((currentAddressBookList) =>
                                            currentAddressBookList.filter(
                                                (address) => address.id !== itemId
                                            )
                                        );
                                        await deleteAddressBookByPartnerIdAndAddressBookId({
                                            partnerId: id,
                                            id: itemId,
                                        });
                                        setAddressBookList(
                                            await getSenderAddressBookList({partnerId: id})
                                        );
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>
            <Box mt={3}>
                <Box sx={{borderBottom: '1px solid #609966', borderRadius: '0 25px', marginBottom: '20px'}}>
                    <Box sx={{width: isMobile ? '300px' : '600px'}}>
                        <StyledTabs
                            value={tabValue}
                            onChange={handleChangeTab}
                            variant='fullWidth'
                        >
                            <StyledTab label="Create Label" value="createLabel"/>
                            <StyledTab label="Upload Excel" value="uploadExcel"/>
                        </StyledTabs>
                    </Box>
                </Box>
                <Box
                    // className={
                    //   matches ? classes.smPackageTabContainer : classes.packageTabContainer
                    // }
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    {/* {getMainComponent(authChecking, errMsg, authorized)} */}
                    <Box width={matches ? "100%" : "600px"}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="300px"
                            justifyContent="center"
                            alignItems="center"
                            margin="0 auto"
                        >
                            <Box textAlign="center" mb={2}>
                                {/* Max. file size: <b>10MB</b> <br /> */}
                                Upload contacts files, file type: <b>XLS, XLSX</b>
                            </Box>
                            <input
                                accept=".xlsx, .xls"
                                style={{display: "none"}}
                                id="raised-button-file"
                                multiple
                                type="file"
                                // onChange={()={}}
                                onChange={onDrop("contacts")}
                                key={theInputKey || ""}
                            />
                            <label htmlFor="raised-button-file">
                                <Button
                                    variant="contained"
                                    component="span"
                                    color="primary"
                                    style={{
                                        textTransform: "unset",
                                        fontSize: "1rem",
                                    }}
                                    onClick={functionThatResetsTheFileInput}
                                >
                                    Select one file
                                </Button>
                            </label>
                            {contactFileObj && (
                                <Box className={classes.buttonFilesWrapper} mt={3}>
                                    <Box display="flex" alignItems="center">
                                        <InsertDriveFileIcon/>
                                        {contactFileObj.selectedFileName}
                                    </Box>
                                </Box>
                            )}
                            {/* {uploadingContactSuccess && (
                <Box textAlign="center" mt={3} color="green" fontSize="1.2rem">
                  File uploaded successfully.
                </Box>
              )} */}
                        </Box>
                        <Box display="flex" justifyContent="end">
                            <Button
                                style={{
                                    textTransform: "unset",
                                }}
                            >
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://storage.googleapis.com/uucargo-resource/samples/shipping-package-list-sample.xlsx"
                                >
                                    Download Sample
                                </a>
                            </Button>
                        </Box>
                        <hr/>
                        <Box mt={2} mb={4}>
                            <CustomInput
                                label="Select a Carrier * :"
                                value={account}
                                onChange={(e) => {
                                    setAccount(e.target.value);
                                }}
                                type="select"
                                options={accountOptions}
                            />
                            <Box pt="1vh" width="100%">
                                {optionEmtpy ? (
                                    <Alert severity="error">{"Account option is required"}</Alert>
                                ) : (
                                    ""
                                )}
                            </Box>
                            <Box display="flex" mb={1} mt={1}>
                                <Box flexBasis="50%" mr={1}>
                                    <CustomInput
                                        //   error={pickUp && checkError && !addressObject.phone}
                                        onChange={(e) => setRefOrderNumber(e.target.value)}
                                        // value={provinces.find(ele => ele.value === addressObject.province) || ''}
                                        value={refOrderNumber}
                                        type="line"
                                        label={"Ref Order # *"}
                                    />
                                </Box>
                                <Box flexBasis="50%" ml={1}>
                                    <CustomInput
                                        //   error={pickUp && checkError && !addressObject.phone}
                                        onChange={(e) => setCargoControlNumber(e.target.value)}
                                        // value={provinces.find(ele => ele.value === addressObject.province) || ''}
                                        value={cargoControlNumber}
                                        type="line"
                                        label={"Cargo control #"}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <hr/>
                    </Box>
                </Box>
                <Box width={matches800 ? "100%" : "800px"} mb={2}>
                    <Box
                        display="flex"
                        ml={2}
                        mt={2}
                        width="100%"
                        justifyContent="space-between"
                    >
                        {typeValue === "rate" && (
                            <>
                                <Box
                                    visibility={
                                        typeValue === "sampleLabel" ? "visible" : "hidden"
                                    }
                                >
                                    <Button
                                        component="span"
                                        color="primary"
                                        style={{
                                            textTransform: "unset",
                                            fontSize: "1rem",
                                            width: "10rem",
                                        }}
                                        onClick={() => {
                                            handleChange("rate");
                                        }}
                                    >
                                        {"<- Go to ratings"}
                                    </Button>
                                </Box>
                                <Box>
                                    {/* <Box mb={2} width="100%" display="flex" justifyContent="center">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_GOOGLE_API_RECAPTCHA_KEY}
                      onChange={(e) => setRecaptchaValue(e)}
                    />
                  </Box> */}
                                    <Button
                                        variant="contained"
                                        component="span"
                                        color="primary"
                                        style={{
                                            textTransform: "unset",
                                            fontSize: "1rem",
                                            width: "7rem",
                                            ...(uploadingContact
                                                ? {backgroundColor: "rgba(0,0,255,0.2)"}
                                                : {}),
                                        }}
                                        onClick={() => handleSubmit("contacts")}
                                        // disabled={!recaptchaValue}
                                    >
                                        {uploadingContact ? (
                                            <CircularProgress size={24}/>
                                        ) : (
                                            "Get rate"
                                        )}
                                    </Button>
                                </Box>
                                <Box visibility={rateSuccess ? "visible" : "hidden"}>
                                    <Button
                                        component="span"
                                        color="primary"
                                        style={{
                                            textTransform: "unset",
                                            fontSize: "1rem",
                                            width: "11rem",
                                        }}
                                        disabled={ratingErrorsRows?.length !== 0}
                                        onClick={() => handleChange("sampleLabel")}
                                    >
                                        {"Get sample label ->"}
                                    </Button>
                                </Box>
                            </>
                        )}
                        {typeValue === "sampleLabel" && (
                            <>
                                <Box
                                    visibility={
                                        typeValue === "sampleLabel" ? "visible" : "hidden"
                                    }
                                >
                                    <Button
                                        component="span"
                                        color="primary"
                                        style={{
                                            textTransform: "unset",
                                            fontSize: "1rem",
                                            width: "10rem",
                                        }}
                                        onClick={() => {
                                            handleChange("rate");
                                        }}
                                    >
                                        {"<- Go to ratings"}
                                    </Button>
                                </Box>
                                <Box mr={2}>
                                    <Button
                                        variant="contained"
                                        component="span"
                                        color="primary"
                                        style={{
                                            textTransform: "unset",
                                            fontSize: "1rem",
                                            ...(gettingSampleLabel
                                                ? {backgroundColor: "rgba(0,0,255,0.2)"}
                                                : {}),
                                        }}
                                        onClick={handleSubmitForSampleLabel}
                                        // handleSubmitForShipping
                                    >
                                        {gettingSampleLabel ? (
                                            <CircularProgress size={24}/>
                                        ) : (
                                            "Get sample label"
                                        )}
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        component="span"
                                        color="primary"
                                        style={{
                                            textTransform: "unset",
                                            fontSize: "1rem",
                                            width: "10rem",
                                        }}
                                        onClick={() => {
                                            handleChange("shipping");
                                        }}
                                    >
                                        {"Buy shipping ->"}
                                    </Button>
                                </Box>
                            </>
                        )}
                        {typeValue === "shipping" && (
                            <>
                                <Box>
                                    <Button
                                        component="span"
                                        color="primary"
                                        style={{
                                            textTransform: "unset",
                                            fontSize: "1rem",
                                            width: "11rem",
                                        }}
                                        onClick={() => handleChange("sampleLabel")}
                                    >
                                        {"<- Get sample label"}
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        variant="contained"
                                        component="span"
                                        color="primary"
                                        style={{
                                            textTransform: "unset",
                                            fontSize: "1rem",
                                            ...(uploadingContactShipping
                                                ? {backgroundColor: "rgba(0,0,255,0.2)"}
                                                : {}),
                                        }}
                                        onClick={handleDialogOpen}
                                        // handleSubmitForShipping
                                    >
                                        {uploadingContactShipping ? (
                                            <CircularProgress size={24}/>
                                        ) : (
                                            "Start placing order"
                                        )}
                                    </Button>
                                </Box>
                                <Box
                                    visibility={
                                        typeValue === "sampleLabel" ? "visible" : "hidden"
                                    }
                                >
                                    <Button
                                        component="span"
                                        color="primary"
                                        style={{
                                            textTransform: "unset",
                                            fontSize: "1rem",
                                            width: "11rem",
                                        }}
                                        onClick={() => handleChange("sampleLabel")}
                                    >
                                        {"Get sample label ->"}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                    {typeValue === "rate" && (
                        <Box mt={2} mb={2}>
                            {`Total price with taxes: C$ ${
                                totalPriceWithTaxes
                                    ? parseFloat(totalPriceWithTaxes).toFixed(2)
                                    : ""
                            }`}
                        </Box>
                    )}
                    {
                        ratingErrorsRows?.length > 0 && (
                            <Box mt={2} mb={2} display="flex" alignItems="center" justifyContent="center" width="500">
                                <Alert severity="error">Excel has some invalid rows, please fix them before placing
                                    order.</Alert>
                            </Box>
                        )
                    }
                </Box>
                <Box
                    width={matches800 ? "100%" : "800px"}
                    display="flex"
                    flexDirection="column"
                    mb={4}
                >
                    <Button
                        variant="contained"
                        component="span"
                        color="primary"
                        style={{
                            textTransform: "unset",
                            fontSize: "1rem",
                            width: "10rem",
                        }}
                        onClick={() => {
                            handleOpenAddressBook();
                        }}
                    >
                        Address Book
                    </Button>
                    <AddressInputCombo
                        handleChange={handleAddressChange}
                        addressObject={formatData}
                    />
                    {/*Save to Address Book*/}
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox value={isSaveAddress} onClick={handleSaveAddress}/>
                            }
                            label="Save to address book"
                        />
                    </FormGroup>
                </Box>
                {sampleLabel && typeValue === "sampleLabel" && (
                    <>
                        <Box mb={2}>Label size: 4" x 6"</Box>
                        <Box>{example(sampleLabel)}</Box>
                    </>
                )}
                <Box width={matches800 ? "100%" : "800px"}>
                    {typeValue === "rate" && (
                        <Box mt={2} mb={2}>
                            <h3>Rating Result</h3>
                            <table className={classes.table}>
                                <thead>
                                <tr>
                                    <th className={classes.idTH}>Row Number</th>
                                    <th className={classes.nameTH}>excl. tax</th>
                                    <th className={classes.nameTH}>Total Charge inc. tax</th>
                                    {/*<th className={classes.nameTH}>Service Fee</th>*/}
                                    {/*<th className={classes.nameTH}>Final Charge</th>*/}
                                </tr>
                                </thead>
                                <tbody className={classes.tbody}>
                                {ratingShipmentItems.map((s, index) => {
                                    const shipmentServiceFee = +s?.serviceFee?.value + +s?.serviceFeeTax.value;

                                    return <CsvRateTableItem
                                        key={index}
                                        index={s.index}
                                        totalCharge={+s.totalCharge.value + shipmentServiceFee}
                                        totalChargeWithTaxes={+s.totalChargeWithTaxes.value + shipmentServiceFee}
                                    />;
                                })}
                                </tbody>
                            </table>
                            <br/>
                            <Divider/>
                            {
                                ratingErrorsRows?.length !== 0 && (
                                    <>
                                        <h3>Errors</h3>
                                        <table className={classes.table}>
                                            <thead>
                                            <tr>
                                                <th className={classes.idTH}>Row number</th>
                                                <th className={classes.nameTH}>Message</th>
                                            </tr>
                                            </thead>
                                            <tbody className={classes.tbody}>
                                            {ratingErrorsRows.map((s, index) => {
                                                return <CsvRateErrorTableItem
                                                    key={index}
                                                    index={s.index}
                                                    message={s?.message}
                                                />;
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                )
                            }
                        </Box>
                    )}
                    {typeValue === "shipping" && resultOrderId && resultOrderStatus !== null && (
                        <Box mt={2}>
                            {
                                (() => {
                                    if (!resultOrderStatus || +resultOrderStatus < 1) {
                                        return (
                                            <>
                                                <h3>Oops, We can't process your request. Please double check your excel
                                                    file and ref order number.</h3>
                                                <br/>
                                            </>
                                        )
                                    }
                                    return (
                                        <>
                                            <h3>Thank You. Your order has been created.</h3>
                                            <h4>
                                                Order # {resultOrderId}   &nbsp;&nbsp;<Link
                                                to={`/loose-item/order-dashboard/${resultOrderId}`}>View Order
                                                Details</Link>
                                            </h4>
                                            <br/>
                                        </>
                                    )
                                })()
                            }
                            <table className={classes.table}>
                                <thead>
                                <tr>
                                    <th className={classes.idTH}>Row Number</th>
                                    <th className={classes.nameTH}>Total Charge inc. Tax</th>
                                    <th className={classes.nameTH}>Label</th>
                                    <th className={classes.nameTH}>Tracking Number</th>
                                </tr>
                                </thead>
                                <tbody className={classes.tbody}>
                                {shippingShipmentItems.map((s, index) => {
                                    const shipmentServiceFee = +s?.service_fee_include_gst;
                                    const shipmentShippingFee = +s?.shipping_fee_include_tax;
                                    return <CsvShipTableItem
                                        index={s.index}
                                        totalChargeWithTaxes={shipmentServiceFee + shipmentShippingFee}
                                        labelUri={`${LABEL_DOWNLOAD_URL_PREFIX}${s?.label_pdf_uri}`}
                                        trackingNumbers={s.tran_tracking_nos}
                                    />;
                                })}
                                </tbody>
                            </table>
                            <br/>
                            <Divider/>
                            {
                                shippingErrorsRows?.length !== 0 && (
                                    <>
                                        <h3>Errors</h3>
                                        <table className={classes.table}>
                                            <thead>
                                            <tr>
                                                <th className={classes.idTH}>Row number</th>
                                                <th className={classes.nameTH}>Message</th>
                                            </tr>
                                            </thead>
                                            <tbody className={classes.tbody}>
                                            {shippingErrorsRows.map((s, index) => {
                                                return <CsvRateErrorTableItem
                                                    key={index}
                                                    index={s.index}
                                                    message={s?.message}
                                                />;
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                )
                            }
                        </Box>
                    )}
                    <Snackbar
                        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                        open={Boolean(uploadErrMsg)}
                        onClose={handleCloseSnackBar}
                        message={uploadErrMsg}
                        autoHideDuration={3000}
                    />
                </Box>
                <Dialog
                    onClose={handleDialogClose}
                    aria-labelledby="simple-dialog-title"
                    open={dialogOpen}
                >
                    <DialogTitle id="simple-dialog-title">
                        You will be charged if clicking buy shipping
                    </DialogTitle>
                    <Box mb={2}>
                        <List>
                            <ListItem button onClick={handleDialogClose}>
                                <ListItemText primary="Cancel"/>
                            </ListItem>
                            <ListItem
                                autoFocus
                                button
                                onClick={() => {
                                    handleSubmitForShipping();
                                    handleDialogClose();
                                }}
                            >
                                <ListItemText primary="Buy shipping"/>
                            </ListItem>
                        </List>
                    </Box>
                </Dialog>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word",
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff",
    },
    table: {
        width: "100%",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
    },
    nameTH: {
        width: "100px",
    },
    idTH: {
        width: "100px",
    },
    addressLine: {
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.grey[100],
        },
    },
}));

export default ShippingViaExcel;
