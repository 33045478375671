import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useEffect, useState} from "react";
import {internationalShipmentTypeList} from "../../shared/constInfo";
import Typography from "@mui/material/Typography";
import {CheckBox} from "@mui/icons-material";
import {Checkbox, Divider, Link} from "@mui/material";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {generateRandomString, getMissingKeysForCommercialInvoice, isPositiveInteger} from "../../utils/Helper";
import LoadingContainer from "../../shared/loadingContainer";

const styles = {
    sectionCol: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '20px'
    },
    section: {
        display: 'flex',
        gap: '20px',
        alignItems: 'flex-end'
    },
    sectionColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '5px',
    },
    sectionTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    gridSection: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        width: '100%'
    },
    warningText: {
        fontSize: '12px',
        color: '#FF0303'
    },
    message: {
        textAlign: 'left'
    },
    messageBox: {
        marginBottom: '20px'
    }
}

export const CommercialInvoiceCreate = ({shippingListData}) => {

    console.log('commerical invoice create', shippingListData);

    const {order_id} = useParams();

    const partnerInfo = useSelector((state) => state.user);

    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState();
    const [invoiceNo, setInvoiceNo] = useState();
    const [purchaseOrder, setPurchaseOrder] = useState();
    const [terms, setTerms] = useState('DDU');
    const [reason, setReason] = useState('SALE');
    const [PDFurl, setPDFurl] = useState('');

    const [invoiceInfo, setInvoiceInfo] = useState({
        terms: 'DDU',
        reason: 'SALE'
    });

    const [freightCharge, setFreightCharge] = useState(0);
    const [insurance, setInsurance] = useState(0);
    const [tax, setTax] = useState(0);

    const [freightChargeError, setFreightChargeError] = useState(false);
    const [insuranceError, setInsuranceError] = useState(false);
    const [taxError, setTaxError] = useState(false);

    const [shipperTaxId, setShipperTaxId] = useState('');
    const [shipToTaxId, setShipToTaxId] = useState('');
    const [soldToTaxId, setSoldToTaxId] = useState('');

    const [comments, setComments] = useState('');
    const [trackingList, setTrackingList] = useState([]);
    const [missingKeys, setMissingKeys] = useState([]);
    const [hasChecked, setHasChecked] = useState(true);

    const [invoiceStatus, setInvoiceStatus] = useState(false);

    const handleDate = e => {
        setDate(e.target.value);
        setInvoiceInfo(prevState => ({
            ...prevState, date: e.target.value
        }))
    }

    const handleInvoiceNo = e => {
        setInvoiceNo(e.target.value);
        setInvoiceInfo(prevState => ({
            ...prevState, invoiceNo: e.target.value
        }))
    }

    const handlePurchaseOrder = e => {
        setPurchaseOrder(e.target.value);
        setInvoiceInfo(prevState => ({
            ...prevState, purchaseOrder: e.target.value
        }))
    }

    const handleTerms = e => {
        setTerms(e.target.value);
        setInvoiceInfo(prevState => ({
            ...prevState, terms: e.target.value
        }))
    }

    const handleReason = (event, value) => {
        setReason(value);
        setInvoiceInfo(prevState => ({
            ...prevState, reason: value
        }))
    }

    const handleComments = e => {
        setComments(e.target.value);
    }

    const handleShipperTaxId = e => {
        setShipperTaxId(e.target.value);
    }

    const handleShipToTaxId = e => {
        setShipToTaxId(e.target.value);
    }

    const handleSoldToTaxId = e => {
        setSoldToTaxId(e.target.value);
    }

    const handleFreightCharge = e => {
        setFreightCharge(e.target.value);
        if (e.target.value >= 0) {
            setFreightChargeError(false);
        } else {
            setFreightChargeError(true);
        }
    }

    const handleInsurance = e => {
        setInsurance(e.target.value);
        if (e.target.value >= 0) {
            setInsuranceError(false);
        } else {
            setInsuranceError(true);
        }
    }

    const handleTax = e => {
        setTax(e.target.value);
        if (e.target.value >= 0) {
            setTaxError(false);
        } else {
            setTaxError(true);
        }
    }

    const handleCheck = (e) => {
        // console.log(e.target.value);
        const updateId = e.target.value;

        const updatedTrackingList = trackingList?.map(item => {
            if (item.trackingId === updateId) {
                return {...item, isChecked: !item.isChecked};
            } else {
                return item;
            }
        });
        setTrackingList(updatedTrackingList);
    }

    const getTrackingList = async () => {
        setLoading(true);
        let requestURI = `${PARTNER_URI}/shipping/list/getTrackingViewByOrderId`;
        try {
            const results = await axios({
                method: 'get',
                url: requestURI,
                params: {
                    order_id: order_id,
                    partner_id: partnerInfo?.id
                }
            })

            const {data} = results;

            console.log('get tracking list', data);

            const allInvoiceStatus = data.every(element => element?.doc_status === 2);

            console.log('all invoice status', allInvoiceStatus);

            setInvoiceStatus(allInvoiceStatus);

            const updatedTrackingList = [];

            data.forEach(element => {
                if (element.doc_status !== 2) {
                    const randomId = generateRandomString(8);
                    let object = {
                        trackingId: randomId,
                        isChecked: true,
                        trackingNumber: element?.tracking_no,
                        tranId: element?.tran_id,
                        shippingIdNumber: element?.shipping_id_no,
                        description: element?.item_name,
                        unitPrice: element?.unit_price,
                        quantity: 1,
                        UOM: element?.uom,
                        weight: element?.weight,
                        hsCode: element?.hs_code,
                        originalCountry: element?.origi_country,
                        currency: element?.currency_code,
                        weightUnit: element?.weight_unit,
                    }
                    updatedTrackingList.push(object);
                }
            })
            console.log('update tracking list', updatedTrackingList);
            setTrackingList(updatedTrackingList);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e)
        }
    }

    const generateCommercialInvoice = async () => {
        setLoading(true);
        const requestURI = `${PARTNER_URI}/commercialInvoice/generateCommercialInvoiceAndUpload/ups`;

        const items = trackingList.filter(element => element.isChecked === true);

        console.log('item list', items);

        let invoiceSubtotal = 0;
        items.forEach(element => {
            invoiceSubtotal += element.unitPrice;
        })

        console.log('invoice subtotal', invoiceSubtotal);

        let invoiceTotalAmount = insurance + (+tax) + (+freightCharge) + (+invoiceSubtotal);

        console.log('invoiceTotalAmount', invoiceTotalAmount);

        const data = {
            orderId: order_id,
            items,
            date: date,
            billingNumber: "",
            invoiceNumber: invoiceNo,
            purchaseOrderNumber: purchaseOrder,
            termsOfSale: terms,
            reasonForExport: reason,
            currency: shippingListData[0].currency_code,
            discount: "",
            invoiceSubtotal,
            freightCharges: freightCharge,
            insurance,
            tax,
            invoiceTotalAmount,
            comments,
            invoiceLineTotal: "",
            shipperCompanyName: shippingListData[0].send_from_name,
            shipperContactName: "",
            shipperTaxId,
            shipperCompanyAddress: shippingListData[0].send_from_addr,
            shipperCompanyCity: shippingListData[0].send_from_city,
            shipperCompanyProvince: shippingListData[0].send_from_province,
            shipperCompanyPostalCode: shippingListData[0].send_from_postal_code,
            shipperCompanyCountry: shippingListData[0].send_from_country,
            shipperCompanyPhone: shippingListData[0].send_from_tel,
            shipperCompanyEmail: shippingListData[0].send_from_email,
            shipToTaxId,
            shipToContactName: shippingListData[0].send_to_name,
            shipToCompanyName: "",
            shipToCompanyAddress: shippingListData[0].send_to_addr,
            shipToCompanyCity: shippingListData[0].send_to_city,
            shipToCompanyProvince: shippingListData[0].send_to_province,
            shipToCompanyPostalCode: shippingListData[0].send_to_postal_code,
            shipToCompanyCountry: shippingListData[0].send_to_country,
            shipToCompanyPhone: shippingListData[0].send_to_tel,
            shipToCompanyEmail: shippingListData[0].send_to_email,
            soldToTaxId,
            soldToContactName: shippingListData[0].send_to_name,
            soldToCompanyName: "",
            soldToCompanyAddress: shippingListData[0].send_to_addr,
            soldToCompanyCity: shippingListData[0].send_to_city,
            soldToCompanyProvince: shippingListData[0].send_to_province,
            soldToCompanyPostalCode: shippingListData[0].send_to_postal_code,
            soldToCompanyCountry: shippingListData[0].send_to_country,
            soldToCompanyPhone: shippingListData[0].send_to_tel,
            soldToCompanyEmail: shippingListData[0].send_to_email

        }
        console.log(data)

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: data
            })
            console.log(result);
            setLoading(false);
            setIsSubmit(true);
            setPDFurl(result.data[0].invoiceURL);
        } catch (e) {
            setLoading(false);
            console.log(e)
        }
    }

    useEffect(() => {
        getTrackingList();
        let totalFreightCharge = 0;
        shippingListData.forEach(element => {
            totalFreightCharge += element?.freight_charge;
        })
        setFreightCharge(totalFreightCharge);
    }, [])

    const handleCreate = () => {
        const missing = getMissingKeysForCommercialInvoice(invoiceInfo);
        const isChecked = trackingList.some(obj => obj.isChecked);
        setHasChecked(isChecked);
        setMissingKeys(missing);
        if (missing.length === 0 && !freightChargeError && !insuranceError && !taxError && hasChecked) {
            console.log('call api');
            generateCommercialInvoice();
        }
    }

    return (
        <> {
            loading ? <LoadingContainer/> :
                invoiceStatus ? <Box>
                        <Box sx={styles.messageBox}>
                            <Typography sx={styles.message}>
                                As you have submitted commercial invoices, there is no need for you to create or submit any additional invoices.
                            </Typography>
                        </Box>
                    </Box> :
                    isSubmit ? <Box sx={styles.col}>
                            <Box sx={styles.messageBox}>
                                <Typography sx={styles.message}>
                                    We are delighted to inform you that your invoice has been successfully created and
                                    uploaded
                                    to our system. Once you have confirmed the usage of this invoice, you can proceed to
                                    submit
                                    it.
                                </Typography>
                            </Box>
                            <Box sx={styles.section}>
                                <Button variant="contained" color="warning">
                                    <Link
                                        href={`${LABEL_DOWNLOAD_URL_PREFIX}${PDFurl}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{textDecoration: 'none', color: '#FFFFFF'}}>
                                        Preview Invoice
                                    </Link>
                                </Button>
                                <Button variant="contained" color="success" onClick={() => window.location.reload()}>Confirm
                                    Invoice</Button>
                                <Button variant="contained" onClick={() => setIsSubmit(false)}>Create a New Invoice</Button>
                            </Box>
                        </Box>
                        :
                        <Box sx={styles.sectionCol}>
                            <Box sx={styles.sectionColumn}>
                                <Typography sx={styles.sectionTitle}>
                                    Information
                                </Typography>
                                <Box sx={styles.section}>
                                    <Box sx={{width: '100%'}}>
                                        <TextField
                                            value={date}
                                            type='date'
                                            label="Date"
                                            required
                                            fullWidth
                                            variant="standard"
                                            onInput={handleDate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <Typography sx={styles.warningText}>
                                            {missingKeys.includes('date') ? 'This is required field.' : ''}
                                        </Typography>
                                    </Box>
                                    <Box sx={{width: '100%'}}>
                                        <TextField
                                            value={invoiceNo}
                                            label="Invoice Number"
                                            required
                                            fullWidth
                                            variant="standard"
                                            onInput={handleInvoiceNo}
                                        />
                                        <Typography sx={styles.warningText}>
                                            {missingKeys.includes('invoiceNo') ? 'This is required field.' : ''}
                                        </Typography>
                                    </Box>
                                    <Box sx={{width: '100%'}}>
                                        <TextField
                                            value={purchaseOrder}
                                            label="Purchase Order No."
                                            required
                                            fullWidth
                                            variant="standard"
                                            onInput={handlePurchaseOrder}
                                        />
                                        <Typography sx={styles.warningText}>
                                            {missingKeys.includes('purchaseOrder') ? 'This is required field.' : ''}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={styles.section}>
                                    <Box sx={{width: '100%'}}>
                                        <TextField
                                            value={terms}
                                            label="Terms of Sale (Incoterm)"
                                            required
                                            fullWidth
                                            variant="standard"
                                            onInput={handleTerms}
                                        />
                                        <Typography sx={styles.warningText}>
                                            {missingKeys.includes('terms') ? 'This is required field.' : ''}
                                        </Typography>
                                    </Box>
                                    <Box sx={{width: '100%'}}>
                                        <Autocomplete
                                            value={reason}
                                            options={internationalShipmentTypeList}
                                            fullWidth
                                            getOptionLabel={(option) => option}
                                            onChange={handleReason}
                                            renderInput={(params) => <TextField
                                                variant="standard"
                                                required
                                                {...params}
                                                label="Reason for Export"/>
                                            }
                                        />
                                        <Typography sx={styles.warningText}>
                                            {missingKeys.includes('reason') ? 'This is required field.' : ''}
                                        </Typography>
                                    </Box>
                                    <TextField
                                        value={comments}
                                        label="Additional Comments"
                                        variant="standard"
                                        fullWidth
                                        onInput={handleComments}
                                    />
                                </Box>
                                <Box sx={styles.section}>
                                    <Box sx={{width: '100%'}}>
                                        <TextField
                                            value={freightCharge}
                                            label="Freight Charge"
                                            fullWidth
                                            variant="standard"
                                            onInput={handleFreightCharge}
                                            type="number"
                                        />
                                        <Typography sx={styles.warningText}>
                                            {freightChargeError ? 'Freight Charge cannot be negative.' : ''}
                                        </Typography>
                                    </Box>
                                    <Box sx={{width: '100%'}}>
                                        <TextField
                                            value={insurance}
                                            label="Insurance"
                                            fullWidth
                                            variant="standard"
                                            onInput={handleInsurance}
                                            type="number"
                                        />
                                        <Typography sx={styles.warningText}>
                                            {insuranceError ? 'Insurance cannot be negative.' : ''}
                                        </Typography>
                                    </Box>
                                    <Box sx={{width: '100%'}}>
                                        <TextField
                                            value={tax}
                                            label="Tax"
                                            fullWidth
                                            variant="standard"
                                            onInput={handleTax}
                                            type="number"
                                        />
                                        <Typography sx={styles.warningText}>
                                            {taxError ? 'Tax cannot be negative.' : ''}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={styles.section}>
                                    <TextField
                                        value={shipperTaxId}
                                        label="Shipper Tax Id"
                                        variant="standard"
                                        fullWidth
                                        onInput={handleShipperTaxId}
                                    />
                                    <TextField
                                        value={shipToTaxId}
                                        label="Ship To Tax Id"
                                        variant="standard"
                                        fullWidth
                                        onInput={handleShipToTaxId}
                                    />
                                    <TextField
                                        value={soldToTaxId}
                                        label="Sold To Tax Id"
                                        variant="standard"
                                        fullWidth
                                        onInput={handleSoldToTaxId}
                                    />
                                </Box>
                            </Box>
                            <Box sx={styles.sectionColumn}>
                                <Typography sx={styles.sectionTitle}>
                                    Items
                                </Typography>
                                <Typography sx={styles.warningText}>
                                    {hasChecked ? '' : 'Select at least one items'}
                                </Typography>
                                <Box>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box sx={styles.gridSection}>
                                                <Typography>
                                                    Name
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box sx={styles.gridSection}>
                                                <Typography>
                                                    Tracking No.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box sx={styles.gridSection}>
                                                <Typography>
                                                    Unit Price
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box sx={styles.gridSection}>
                                                <Typography>
                                                    Unit Weight
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box sx={styles.gridSection}>
                                                <Typography>
                                                    No. Units
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Divider/>
                                    {
                                        trackingList?.map((element, index) => {
                                            return (
                                                <Box>
                                                    <Grid container spacing={1} key={index}>
                                                        <Grid item xs={1}>
                                                            <Box sx={styles.gridSection}>
                                                                <Checkbox
                                                                    checked={element.isChecked}
                                                                    onChange={handleCheck}
                                                                    value={element.trackingId}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box sx={styles.gridSection}>
                                                                <Typography>
                                                                    {element?.description}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box sx={styles.gridSection}>
                                                                <Typography>
                                                                    {element?.trackingNumber}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Box sx={styles.gridSection}>
                                                                <Typography>
                                                                    {element?.currency} ${element?.unitPrice}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Box sx={styles.gridSection}>
                                                                <Typography>
                                                                    {element?.weight} {element?.weightUnit}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Box sx={styles.gridSection}>
                                                                <Typography>
                                                                    {element?.quantity}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider/>
                                                </Box>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                            <Box>
                                <Button variant='contained' onClick={handleCreate}>
                                    Create
                                </Button>
                            </Box>
                        </Box>
        }
        </>
    )
}