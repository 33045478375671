import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@material-ui/core/List";
import {ListItem} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import {styled, useTheme} from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from "@mui/material/IconButton";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import logo from "../images/logo.png";
import {ReactComponent as OceanShippingIcon} from "../images/earth.svg";
import {
    AppBar,
    Toolbar,
    MenuItem,
    Menu,
    Divider,
    Typography,
    makeStyles,
    Box,
    useMediaQuery,
} from "@material-ui/core";
import "../styles.css";
import {Link, useHistory} from "react-router-dom";

import {ReactComponent as PalletsIcon} from "../images/pallet.svg";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SearchIcon from "@material-ui/icons/Search";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import BrokerageIcon from "../images/brokerageIcon.png";
import Collapse from '@mui/material/Collapse';
import {
    selectShippingLTLQuickQuoteRate,
    updateShippingLTLQuickQuotePlaceOrderValidate
} from "./Actions/shippingLTLAction";
import {ReactComponent as DashboardLogo} from "../images/homepageIcon/Dashboard.svg";
import {ReactComponent as PackageLogo} from "../images/homepageIcon/Package.svg";
import {ReactComponent as PalletLogo} from "../images/homepageIcon/Pallet.svg";
import {ReactComponent as OceanLogo} from "../images/homepageIcon/OceanFreight.svg";
import {ReactComponent as BrokerageLogo} from "../images/homepageIcon/CustomBrokerage.svg";
import {ReactComponent as BillingLogo} from "../images/homepageIcon/Billing.svg";
import {ReactComponent as SettingsLogo} from "../images/homepageIcon/Settings.svg";
import {ReactComponent as LogoutLogo} from "../images/homepageIcon/Logout.svg";
import {deleteToken, getAccessToken} from "../utils/doToken";
import axios from "axios";
import {deleteUserInfo, updateUserInfo} from "../slices/userSlice";
import intl from "react-intl-universal";
import {GET_PARTNER_INFO_API} from "../utils/apiUrl";

const drawerWidth = 240;

const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const useStyles = makeStyles((theme) => ({
    position: {
        display: "flex",
        gap: '20px',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    listItemText: {
        fontSize: '10px', // You can adjust the font size here
    },
}));

const CustomNav = styled(List)({
    '& .MuiListItemButton-root': {
        padding: '5px 10px'
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 18,
    },
});

export const SideBar = ({cbHandleOpen}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [open, setOpen] = useState(true);
    const {height, width} = useWindowDimensions();

    const [openTab, setOpenTab] = useState('dashboard');
    const [partnerInfo, setPartnerInfo] = useState(null);

    const {isAdmin} = useSelector((state) => state.user);

    const handleDashboardClick = () => {
        setOpenTab('dashboard');
    }

    const handleShippingPackageClick = () => {
        openTab === 'package' ? setOpenTab(null) : setOpenTab('package');
    };

    const handleShippingLTLClick = () => {
        openTab === 'ltl' ? setOpenTab(null) : setOpenTab('ltl');
    }

    const handleOceanShippingClick = () => {
        openTab === 'ocean' ? setOpenTab(null) : setOpenTab('ocean');
    };

    const handleTransportationClick = () => {
        openTab === 'transportation' ? setOpenTab(null) : setOpenTab('transportation');
    };

    const handleCustomsBrokerage = () => {
        openTab === 'brokerage' ? setOpenTab(null) : setOpenTab('brokerage');
    };

    const handleBillingCenter = () => {
        openTab === 'bill' ? setOpenTab(null) : setOpenTab('bill');
    }

    const handleSetting = () => {
        openTab === 'settings' ? setOpenTab(null) : setOpenTab('settings');
    }

    const theme = useTheme();

    const dispatch = useDispatch();

    const history = useHistory();

    const signOut = (e) => {
        const storedToken = getAccessToken("access_token");
        e.preventDefault();
        try {
            axios.get(`https://admin.uucargo.ca/api/logout`, {
                headers: {Authorization: `Bearer ${storedToken}`},
            });
            //setHide(true)
        } finally {
            deleteToken("access_token");
            dispatch(deleteUserInfo());
            dispatch(updateUserInfo({field: "login", value: false}));
            dispatch(updateUserInfo({field: "signIn", value: false}));
            history.push("/sign-in");
        }
    };

    useEffect(() => {
        width < 480 ? handleDrawerClose() : handleDrawerOpen();
    }, [width])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenTab(null);
    };

    const classes = useStyles();

    const {signIn, isPartner} = useSelector((state) => state.user);

    useEffect(() => {
        (async () => {
                const storedToken = getAccessToken("access_token");
                try {
                    const result = await axios({
                        method: 'get',
                        url: GET_PARTNER_INFO_API,
                        headers: {
                            Authorization: `Bearer ${storedToken}`,
                        }
                    });
                    setPartnerInfo(result?.data);
                } catch (e) {
                    console.log(e);
                }
            }
        )();
    }, [])

    console.log('[SideBar] partner info', partnerInfo);

    return (

        <CustomNav
            sx={{
                maxWidth: 240,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
            }}
            disablePadding
        >
            <Box>
                {/*<ListItemButton*/}
                {/*    sx={{padding: '0', display: 'flex', justifyContent: 'space-between'}}*/}
                {/*    onClick={() => {*/}
                {/*        history.replace("/home");*/}
                {/*        handleDashboardClick();*/}
                {/*        isMobile && cbHandleOpen();*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <ListItemIcon className={classes.position}>*/}
                {/*        <DashboardLogo width="20" height="20"*/}
                {/*                       fill={openTab === 'dashboard' ? '#1D8B45' : '#454545'}/>*/}
                {/*        <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*            color: openTab === 'dashboard' ? '#1D8B45' : '#454545',*/}
                {/*            fontSize: '16px'*/}
                {/*        }}>{intl.get('SIDEBAR.DASHBOARD')}</Typography>}/>*/}
                {/*    </ListItemIcon>*/}
                {/*</ListItemButton>*/}
                {/*<ListItemButton*/}
                {/*    onClick={handleShippingPackageClick}*/}
                {/*    sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}*/}
                {/*>*/}
                {/*    <ListItemIcon className={classes.position}>*/}
                {/*        <PackageLogo width="20" height="20"*/}
                {/*                     fill={openTab === 'package' ? '#1D8B45' : '#454545'}/>*/}
                {/*        <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*            color: openTab === 'package' ? '#1D8B45' : '#454545',*/}
                {/*            fontSize: '16px'*/}
                {/*        }}>{intl.get('SIDEBAR.PACKAGE.PACKAGE')}</Typography>}/>*/}
                {/*    </ListItemIcon>*/}
                {/*    {openTab === 'package' ? <ExpandLess/> : <ExpandMore/>}*/}
                {/*</ListItemButton>*/}
                {/*<Collapse in={openTab === 'package' ? true : false} timeout="auto" unmountOnExit>*/}
                {/*    <List component="div" disablePadding>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/shipping-package/quick-quote");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.PACKAGE.QUICK_QUOTE')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/shipping-package/create-label");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.PACKAGE.CREATE_LABEL')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/shipping-package/international-shipment");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.PACKAGE.INTERNATIONAL_SHIPMENT')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/shipping-package/import-shipment");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.PACKAGE.UPLOAD_EXCEL')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/loose-item/3rd-party/shipping/searchTransactions");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.PACKAGE.SEARCH_TRACKING')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/package/order-list");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.PACKAGE.SHIPPING_ORDERS')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/loose-item/3rd-party/shipping/exportsTransactions");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.PACKAGE.EXPORT_TRANSACTIONS')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/schedulepickup");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.PACKAGE.SCHEDULE_PICKUP')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/MyScheduledPickups");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.PACKAGE.SCHEDULE_LIST')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/tracking");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.PACKAGE.TRACKING_PACKAGE')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*    </List>*/}
                {/*</Collapse>*/}
                {/*{*/}
                {/*    partnerInfo?.ltl_rating_status === 1 &&*/}
                {/*    <>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}*/}
                {/*            onClick={handleShippingLTLClick}>*/}
                {/*            <ListItemIcon className={classes.position}>*/}
                {/*                <PalletLogo width="20" height="20" fill={openTab === 'ltl' ? '#1D8B45' : '#454545'}/>*/}
                {/*                <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                    color: openTab === 'ltl' ? '#1D8B45' : '#454545',*/}
                {/*                    fontSize: '16px',*/}
                {/*                }}>{intl.get('SIDEBAR.LTL.LTL')}</Typography>}/>*/}
                {/*            </ListItemIcon>*/}
                {/*            {openTab === 'ltl' ? <ExpandLess/> : <ExpandMore/>}*/}
                {/*        </ListItemButton>*/}
                {/*        <Collapse in={openTab === 'ltl' ? true : false} timeout='auto' unmountOnExit>*/}
                {/*            <List component="div" disablePadding>*/}
                {/*                <ListItemButton*/}
                {/*                    sx={{padding: 0}}*/}
                {/*                    onClick={() => {*/}
                {/*                        dispatch(updateShippingLTLQuickQuotePlaceOrderValidate(null));*/}
                {/*                        dispatch(selectShippingLTLQuickQuoteRate(null));*/}
                {/*                        history.replace("/LTL-quick-quote");*/}
                {/*                        isMobile && cbHandleOpen();*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                        color: '#454545',*/}
                {/*                        fontSize: '14px',*/}
                {/*                        paddingLeft: '40px'*/}
                {/*                    }}>{intl.get('SIDEBAR.LTL.QUICK_QUOTE')}</Typography>}/>*/}
                {/*                </ListItemButton>*/}
                {/*                <ListItemButton*/}
                {/*                    sx={{padding: 0}}*/}
                {/*                    onClick={() => {*/}
                {/*                        history.replace("/LTL-create-label");*/}
                {/*                        isMobile && cbHandleOpen();*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                        color: '#454545',*/}
                {/*                        fontSize: '14px',*/}
                {/*                        paddingLeft: '40px'*/}
                {/*                    }}>{intl.get('SIDEBAR.LTL.CREATE_LABEL')}</Typography>}/>*/}
                {/*                </ListItemButton>*/}
                {/*                <ListItemButton*/}
                {/*                    sx={{padding: 0}}*/}
                {/*                    onClick={() => {*/}
                {/*                        history.replace("/myLTLQuote");*/}
                {/*                        isMobile && cbHandleOpen();*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                        color: '#454545',*/}
                {/*                        fontSize: '14px',*/}
                {/*                        paddingLeft: '40px'*/}
                {/*                    }}>{intl.get('SIDEBAR.LTL.LTL_QUOTE')}</Typography>}/>*/}
                {/*                </ListItemButton>*/}
                {/*                <ListItemButton*/}
                {/*                    sx={{padding: 0}}*/}
                {/*                    onClick={() => {*/}
                {/*                        history.replace("/myLTLs");*/}
                {/*                        isMobile && cbHandleOpen();*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                        color: '#454545',*/}
                {/*                        fontSize: '14px',*/}
                {/*                        paddingLeft: '40px'*/}
                {/*                    }}>{intl.get('SIDEBAR.LTL.LTL_ORDER')}</Typography>}/>*/}
                {/*                </ListItemButton>*/}
                {/*                <ListItemButton*/}
                {/*                    sx={{padding: 0}}*/}
                {/*                    onClick={() => {*/}
                {/*                        history.replace("/tracking-ltl");*/}
                {/*                        isMobile && cbHandleOpen();*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                        color: '#454545',*/}
                {/*                        fontSize: '14px',*/}
                {/*                        paddingLeft: '40px'*/}
                {/*                    }}>{intl.get('SIDEBAR.LTL.TRACKING_LTL')}</Typography>}/>*/}
                {/*                </ListItemButton>*/}
                {/*            </List>*/}
                {/*        </Collapse>*/}
                {/*    </>*/}
                {/*}*/}
                {/*<ListItemButton sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}*/}
                {/*                onClick={handleOceanShippingClick}>*/}
                {/*    <ListItemIcon className={classes.position}>*/}
                {/*        <OceanLogo width="20" height="20" fill={openTab === 'ocean' ? '#1D8B45' : '#454545'}/>*/}
                {/*        <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*            color: openTab === 'ocean' ? '#1D8B45' : '#454545',*/}
                {/*            fontSize: '16px',*/}
                {/*        }}>{intl.get('SIDEBAR.OCEAN_SHIPPING.OCEAN_SHIPPING')}</Typography>}/>*/}
                {/*    </ListItemIcon>*/}
                {/*    {openTab === 'ocean' ? <ExpandLess/> : <ExpandMore/>}*/}
                {/*</ListItemButton>*/}
                {/*<Collapse in={openTab === 'ocean' ? true : false} timeout="auto" unmountOnExit>*/}
                {/*    <List component="div" disablePadding>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/ocean-shipping");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.OCEAN_SHIPPING.OCEAN_SHIPPING')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*    </List>*/}
                {/*</Collapse>*/}
                {
                    partnerInfo?.moving_op_status === 1 &&
                    <>
                        <ListItemButton sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}
                                        onClick={handleTransportationClick}>
                            <ListItemIcon className={classes.position}>
                                <OceanLogo width="20" height="20"
                                           fill={openTab === 'transportation' ? '#1D8B45' : '#454545'}/>
                                <ListItemText primary={<Typography variant="body2" style={{
                                    color: openTab === 'transportation' ? '#1D8B45' : '#454545',
                                    fontSize: '16px',
                                }}>Moving</Typography>}/>
                            </ListItemIcon>
                            {openTab === 'transportation' ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openTab === 'transportation' ? true : false} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.replace("/transportation/availableTasks/");
                                        isMobile && cbHandleOpen();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>Available Orders</Typography>}/>
                                </ListItemButton>
                            </List>
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{padding: 0}}
                                    onClick={() => {
                                        history.replace("/transportation/claimTasks/");
                                        isMobile && cbHandleOpen();
                                    }}
                                >
                                    <ListItemText primary={<Typography variant="body2" style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        paddingLeft: '40px'
                                    }}>My Orders</Typography>}/>
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </>
                }
                {/*<ListItemButton sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}*/}
                {/*                onClick={handleCustomsBrokerage}>*/}
                {/*    <ListItemIcon className={classes.position}>*/}
                {/*        <BrokerageLogo width="20" height="20"*/}
                {/*                       fill={openTab === 'brokerage' ? '#1D8B45' : '#454545'}/>*/}
                {/*        <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*            color: openTab === 'brokerage' ? '#1D8B45' : '#454545',*/}
                {/*            fontSize: '16px',*/}
                {/*        }}>{intl.get('SIDEBAR.BROKERAGE.BROKERAGE')}*/}
                {/*        </Typography>}/>*/}
                {/*    </ListItemIcon>*/}
                {/*    {openTab === 'brokerage' ? <ExpandLess/> : <ExpandMore/>}*/}
                {/*</ListItemButton>*/}
                {/*<Collapse in={openTab === 'brokerage' ? true : false} timeout="auto" unmountOnExit>*/}
                {/*    <List component="div" disablePadding>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/brokerage/estimate");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.BROKERAGE.ESTIMATE_COST')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/brokerage/packing-list");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.BROKERAGE.PACKING_LIST')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*    </List>*/}
                {/*</Collapse>*/}
                {/*<ListItemButton sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}*/}
                {/*                onClick={handleBillingCenter}>*/}
                {/*    <ListItemIcon className={classes.position}>*/}
                {/*        <BillingLogo width="20" height="20" fill={openTab === 'bill' ? '#1D8B45' : '#454545'}/>*/}
                {/*        <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*            color: openTab === 'bill' ? '#1D8B45' : '#454545',*/}
                {/*            fontSize: '16px',*/}
                {/*        }}>{intl.get('SIDEBAR.BILLING_CENTER.BILLING_CENTER')}</Typography>}/>*/}
                {/*    </ListItemIcon>*/}
                {/*    {openTab === 'bill' ? <ExpandLess/> : <ExpandMore/>}*/}
                {/*</ListItemButton>*/}
                {/*<Collapse in={openTab === 'bill' ? true : false} timeout="auto" unmountOnExit>*/}
                {/*    <List component="div" disablePadding>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/billing-center");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.BILLING_CENTER.INVOICES')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/billing-center/top-up");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.BILLING_CENTER.TOP_UP')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*    </List>*/}
                {/*</Collapse>*/}
                {/*<ListItemButton sx={{padding: 0, display: 'flex', justifyContent: 'space-between'}}*/}
                {/*                onClick={handleSetting}>*/}
                {/*    <ListItemIcon className={classes.position}>*/}
                {/*        <SettingsLogo width="20" height="20"*/}
                {/*                      fill={openTab === 'settings' ? '#1D8B45' : '#454545'}/>*/}
                {/*        <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*            color: openTab === 'settings' ? '#1D8B45' : '#454545',*/}
                {/*            fontSize: '16px',*/}
                {/*        }}>{intl.get('SIDEBAR.SETTINGS.SETTINGS')}</Typography>}/>*/}
                {/*    </ListItemIcon>*/}
                {/*    {openTab === 'settings' ? <ExpandLess/> : <ExpandMore/>}*/}
                {/*</ListItemButton>*/}
                {/*<Collapse in={openTab === 'settings' ? true : false} timeout="auto" unmountOnExit>*/}
                {/*    <List component="div" disablePadding>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/settings/my-boxes");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.SETTINGS.BOXES')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/settings/packing-list");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.SETTINGS.PACKING_LIST')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        <ListItemButton*/}
                {/*            sx={{padding: 0}}*/}
                {/*            onClick={() => {*/}
                {/*                history.replace("/settings/address-list");*/}
                {/*                isMobile && cbHandleOpen();*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                color: '#454545',*/}
                {/*                fontSize: '14px',*/}
                {/*                paddingLeft: '40px'*/}
                {/*            }}>{intl.get('SIDEBAR.SETTINGS.ADDRESS_BOOK')}</Typography>}/>*/}
                {/*        </ListItemButton>*/}
                {/*        {*/}
                {/*            (() => {*/}
                {/*                if (isAdmin) {*/}
                {/*                    return (*/}
                {/*                        <ListItemButton*/}
                {/*                            sx={{padding: 0}}*/}
                {/*                            onClick={() => {*/}
                {/*                                history.replace("/settings/my-staff");*/}
                {/*                                isMobile && cbHandleOpen();*/}
                {/*                            }}*/}
                {/*                        >*/}
                {/*                            <ListItemText primary={<Typography variant="body2" style={{*/}
                {/*                                color: '#454545',*/}
                {/*                                fontSize: '14px',*/}
                {/*                                paddingLeft: '40px'*/}
                {/*                            }}>{intl.get('SIDEBAR.SETTINGS.MY_STAFF')}</Typography>}/>*/}
                {/*                        </ListItemButton>*/}
                {/*                    )*/}
                {/*                }*/}
                {/*            })()*/}
                {/*        }*/}
                {/*    </List>*/}
                {/*</Collapse>*/}
            </Box>
            <Box>
                <ListItemButton
                    sx={{padding: '0', display: 'flex', justifyContent: 'space-between'}}
                    onClick={signOut}
                >
                    <ListItemIcon className={classes.position}>
                        <LogoutLogo width="20" height="20"/>
                        <ListItemText primary={<Typography variant="body2" style={{
                            color: '#454545',
                            fontSize: '16px'
                        }}>{intl.get('SIDEBAR.LOGOUT')}</Typography>}/>
                    </ListItemIcon>
                </ListItemButton>
            </Box>
        </CustomNav>
    );
};

