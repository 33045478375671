import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import {Tooltip} from "@material-ui/core";
import ShippingTransactionTable from "./ShippingTransactionTable";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import CrossBoarderTable from "./CrossBoarderTable";
import axios from "axios";
import CommercialInvoiceTrackingTable from "../CommercialInvoice/commercialInvoiceTrackingTable";
import {Alert} from "@mui/material";

const headCells = [
  {
    id: "trackingNumber",
    numeric: false,
    // disablePadding: true,
    label: "Item Name / Tracking #",
  },
  {
    id: "dimension",
    numeric: false,
    // disablePadding: true,
    label: "Dimension (Inch) / Weight (LB)",
  },
  {
    id: "HS Code / Price Each",
    numeric: false,
    // disablePadding: false,
    label: "HS Code / Price Each",
  },
  {
    id: "upload",
    numeric: false,
    // disablePadding: false,
    label: "Invoice",
  },
  {
    id: "submit",
    numeric: false,
    // disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
              <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{ fontWeight: "bold" }}
              >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
          ))}
        </TableRow>
      </TableHead>
  );
}

export default function CrossBoarders({ orderId, tranId, shippingIdNumber }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [borderDocList, setBorderDocList] = useState([]);
  const [trackingList, setTrackingList] = useState([]);
  const [hasUnUploadInvoice, setHasUnUploadInvoice] = useState(false);
  const [hasUnSubmitInvoice, setHasUnSubmitInvoice] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getTrackingListByTranId = async () => {
    try {
      const { data } = await axios.get(`${PARTNER_URI}/shipping/list/getTrackingViewByTranId/${tranId}`);
      console.log("getTrackingListByTranId", data);
      setTrackingList(data);
    } catch (e) {
      console.log(e);
    }
  }

  const getDocListByTranId = async () => {
    try {
      const { data } = await axios.get(`${PARTNER_URI}/commercialInvoice/getBoraderDocListByTranId/${tranId}`);
      console.log("getDocListByTranId", data);
      setBorderDocList(data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (tranId) {
      getTrackingListByTranId();
      getDocListByTranId();
    }
  }, [tranId]);

  useEffect(() => {
    if (trackingList?.length > 0) {
      const unUnloadList = trackingList?.filter(tracking => tracking.doc_status === 0 || tracking.doc_status === null);
      const unSubmitList = trackingList?.filter(tracking => tracking.doc_status === 1);
      setHasUnUploadInvoice(unUnloadList.length > 0);
      setHasUnSubmitInvoice(unSubmitList.length > 0);
    }
  }, [trackingList]);

  return (
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer style={{ maxHeight: 500 }}>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
            >
              <EnhancedTableHead
                  numSelected={trackingList.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={trackingList.length}
              />
              <TableBody>
                {
                  trackingList.map((tracking, index) => {
                      return <CrossBoarderTable
                          itemName={tracking.item_name}
                          tracking_no={tracking.tracking_no}
                          length={tracking.length}
                          width={tracking.width}
                          height={tracking.height}
                          lengthUnit={tracking.dimension_unit}
                          weightUnit={tracking.weight_unit}
                          quantity={tracking.number}
                          unitPrice={tracking.unit_price}
                          currency={tracking.currency_code}
                          hscode={tracking.hs_code}
                          label_uri={`${LABEL_DOWNLOAD_URL_PREFIX}${tracking.label_uri}`}
                          pdfURI={tracking.file_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${tracking.file_uri}` : ""}
                          weight={tracking.weight}
                          getDocListByTranId={getDocListByTranId}
                          getTrackingListByTranId={getTrackingListByTranId}
                          orderId={orderId}
                          tranId={tranId}
                          shippingIdNumber={shippingIdNumber}
                          status={tracking.doc_status}
                          documentId={tracking.ref_doc_id}
                          docId={tracking.doc_id}
                          index={index}
                      />
                    })
                }
              </TableBody>
            </Table>
          </TableContainer>
          {/*<TablePagination*/}
          {/*  // rowsPerPageOptions={[5, 10, 25]}*/}
          {/*    rowsPerPageOptions={[]}*/}
          {/*  component="div"*/}
          {/*  count={10}*/}
          {/*  rowsPerPage={rowsPerPage}*/}
          {/*  page={page}*/}
          {/*  labelRowsPerPage=""*/}
          {/*  onPageChange={handleChangePage}*/}
          {/*  // onRowsPerPageChange={handleChangeRowsPerPage}*/}
          {/*/>*/}
        </Paper>
        {
            (() => {
              if (hasUnSubmitInvoice) {
                return           <Alert variant="filled" severity="warning">
                  You still have un-uploaded invoices — check it out!
                </Alert>
              }
            })()
        }
        {
          (() => {
            if (hasUnUploadInvoice) {
              return <Alert variant="filled" severity="warning">
                You still have un-submitted invoices — check it out!
              </Alert>
            }
          })()
        }
        {
          (() => {
            if (!hasUnSubmitInvoice && !hasUnUploadInvoice) {
              return <Alert variant="filled" severity="success">
                Congrats! You have been upload all the invoices!
                (Recommended) Print the forms, seal them into a small bag and paste it beside the shipping label.
              </Alert>
            }
          })()
        }
      </Box>
  );
}
