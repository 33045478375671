import Box from "@mui/material/Box";
import {MyQRCode} from "./MyQRCode";
import Typography from "@mui/material/Typography";
import UUCargoLogo from "../../images/logo.png";
import {Container} from "@mui/material";
import {useSelector} from "react-redux";

const styles = {
    QRCodeRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: '#FFFFFF',
        height: '100%',
        borderRadius: '30px',
        // border: '1px solid #000000',
        marginBottom: '20px',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
    },
    QRCodeUpper: {
        backgroundColor: '#FCBB10',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '30px 0 50px',
        borderRadius: '30px'
    },

    QRCodeHeading: {
        fontSize: '25px',
        color: '#FFFFFF',
        fontWeight: '600'
    },

    QRCodeSubHeading: {
        fontSize: '20px',
        color: '#FFFFFF',
        fontWeight: '600'
    },

    QRCodeText: {
        fontSize: '15px',
        color: '#FFFFFF',
        fontWeight: '600'
    },

    QRCodeBox: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginTop: '30px',
        borderRadius: '50px'
    },

    QRCodeLogo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0',
    }
}


export const MyQRCodeDisplay = () => {

    const userInfo = useSelector((state) => state.user);

    return (
        <Container>
            <Box sx={styles.QRCodeRoot}>
                <Box sx={styles.QRCodeUpper}>
                    <Typography sx={styles.QRCodeHeading}>
                        Scan. Pay. Deliver
                    </Typography>
                    <Typography sx={styles.QRCodeSubHeading}>
                        Ship Like a Pro with
                    </Typography>
                    <Typography sx={styles.QRCodeText}>
                        {userInfo?.companyName} + UUCargo
                    </Typography>
                    <Box sx={styles.QRCodeBox}>
                        <MyQRCode size={150}/>
                    </Box>
                </Box>
                <Box sx={styles.QRCodeLogo}>
                    <img src={UUCargoLogo} alt="logo" width='100px'/>
                </Box>
            </Box>
        </Container>
    )
}