import React, { useState, useEffect } from "react";
// import Slide from 'react-reveal';
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText, DialogActions
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
// import PropTypes from "prop-types";
import axios from "axios";

import { getHeaders, errHandling } from "../../../../utils/fetchUtil";
import {ADD_BOL_RECORD, PARTNER_URI, UPDATE_BOL_RECORD} from "../../../../utils/apiUrl";
import {useSelector} from "react-redux";
import dayjs from "dayjs";

const BolDetails = ({
  shippingInfo,
  itemList,
  setActiveStep,
  success,
  setSuccessState,
  clearData,
  refNo,
}) => {
  const classes = useStyles();

  const [errMsg, setErrMsg] = useState("");

  const [loading, setLoadingState] = useState(false);

  const history = useHistory();
  const { id: partnerId  } = useSelector((state) => state.user);

  // If Bol Id Exist, then edit mode
  const edit = shippingInfo?.bol_id !== "";

  const [toastOpen, setToastOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    console.log("Shipping Info: ", shippingInfo);
  }, [shippingInfo])

  useEffect(() => {
    console.log("Item List: ", itemList)
  }, [itemList])

  const addBolWithPackages = async ({ bol_no,
                                      voyage,
                                      eta_date,
                                      etd_date,
                                      ship_company_route_code,
                                      ship_company,
                                      end_port_code,
                                      end_port_en_name,
                                      start_port_code,
                                      start_port_en_name,
                                      delivery_agent,
                                      carrier_agent,
                                      notify_party_name,
                                      consignee_name,
                                      shipper_name,
                                      shipper_full_address,
                                      consignee_full_address,
                                      ref_no,
                                      partner_id,
                                      booking_id,
                                      package_data,
                                      notify_party_full_address,
                                      delivery_agent_full_address,
                                      delivery_agent_phone,
                                      delivery_agent_email,
                                      consignee_phone,
                                      shipper_phone,
                                      forwarding_agent_name,
                                      forwarding_agent_full_address,
                                      forwarding_agent_phone,
                                      forwarding_agent_email
  }) => {
    return await axios.post(`${PARTNER_URI}/partner/bol/addBolWithPackages`, {
      bol_no,
      voyage,
      eta_date,
      etd_date,
      ship_company_route_code,
      ship_company,
      end_port_code,
      end_port_en_name,
      start_port_code,
      start_port_en_name,
      delivery_agent,
      carrier_agent,
      notify_party_name,
      consignee_name,
      shipper_name,
      shipper_full_address,
      consignee_full_address,
      ref_no,
      partner_id,
      booking_id,
      package_data,
      notify_party_full_address,
      delivery_agent_full_address,
      delivery_agent_phone,
      delivery_agent_email,
      consignee_phone,
      shipper_phone,
      forwarding_agent_name,
      forwarding_agent_full_address,
      forwarding_agent_phone,
      forwarding_agent_email
    })
  }

  const updateBolWithPackages = async ({
                                         bol_id,
                                         bol_no,
                                         voyage,
                                         eta_date,
                                         etd_date,
                                         ship_company_route_code,
                                         ship_company,
                                         end_port_code,
                                         end_port_en_name,
                                         start_port_code,
                                         start_port_en_name,
                                         delivery_agent,
                                         carrier_agent,
                                         notify_party_name,
                                         consignee_name,
                                         shipper_name,
                                         shipper_full_address,
                                         consignee_full_address,
                                         ref_no,
                                         partner_id,
                                         booking_id,
                                         package_data,
                                         notify_party_full_address,
                                         delivery_agent_full_address,
                                         delivery_agent_phone,
                                         delivery_agent_email,
                                         consignee_phone,
                                         shipper_phone,
                                         forwarding_agent_name,
                                         forwarding_agent_full_address,
                                         forwarding_agent_phone,
                                         forwarding_agent_email
  }) => {
    return await axios.patch(`${PARTNER_URI}/partner/bol/updateBolWithPackages`, {
      bol_id,
      bol_no,
      voyage,
      eta_date,
      etd_date,
      ship_company_route_code,
      ship_company,
      end_port_code,
      end_port_en_name,
      start_port_code,
      start_port_en_name,
      delivery_agent,
      carrier_agent,
      notify_party_name,
      consignee_name,
      shipper_name,
      shipper_full_address,
      consignee_full_address,
      ref_no,
      partner_id,
      booking_id,
      package_data,
      notify_party_full_address,
      delivery_agent_full_address,
      delivery_agent_phone,
      delivery_agent_email,
      consignee_phone,
      shipper_phone,
      forwarding_agent_name,
      forwarding_agent_full_address,
      forwarding_agent_phone,
      forwarding_agent_email
    });
  }

  const cancelBolWithPackages = async ({ bol_id, partner_id }) => {
    return await axios.post(`${PARTNER_URI}/partner/bol/cancelBol`, {
      bol_id,
      partner_id
    });
  }

  const activateBol = async ({ bol_id, partner_id }) => {
    return await axios.post(`${PARTNER_URI}/partner/bol/activateBol`, {
      bol_id,
      partner_id
    });
  }

  const onCreateSubmit = async () => {
    setLoadingState(true);
    try {
      const requestBody = {
        // bol_no: "",
        ref_no: "",
        partner_id: partnerId,
        voyage: shippingInfo.vessel,
        etd_date: dayjs(shippingInfo.onBoardDate).format("YYYY-MM-DD"),
        eta_date: dayjs(shippingInfo.onBoardDate).add(shippingInfo.vessel, "day").format("YYYY-MM-DD"),
        ship_company_route_code: shippingInfo.routeCode,
        ship_company: shippingInfo.shipCompany,
        end_port_code: shippingInfo.endPortCode,
        end_port_en_name: shippingInfo.portOfDischarge,
        start_port_code: shippingInfo.startPortCode,
        start_port_en_name: shippingInfo.portOfLoading,
        delivery_agent: shippingInfo.deliveryAgent,
        carrier_agent: shippingInfo.carrierAgent,
        notify_party_name: shippingInfo.notifyPartyName,
        consignee_name: shippingInfo.consigneeName,
        shipper_name: shippingInfo.shipperName,
        shipper_full_address: shippingInfo.shipperAddress,
        consignee_full_address: shippingInfo.consigneeAddress,
        booking_id: shippingInfo.booking_id || null,
        notify_party_full_address: shippingInfo.notifyPartyAddress,
        delivery_agent_full_address: shippingInfo.deliveryAgentAddress,
        delivery_agent_phone: shippingInfo.deliveryAgentPhone,
        delivery_agent_email: shippingInfo.deliveryAgentEmail,
        consignee_phone: shippingInfo.consigneePhone,
        shipper_phone: shippingInfo.shipperPhone,
        forwarding_agent_name: shippingInfo.forwardingAgentName,
        forwarding_agent_full_address: shippingInfo.forwardingAgentAddress,
        forwarding_agent_phone: shippingInfo.forwardingAgentPhone,
        forwarding_agent_email: shippingInfo.forwardingAgentEmail,
        package_data: itemList.map(item => {
          return {
            "item_hs_code": item.hscode,
            "item_desc": item.hscodeDescription,
            "unit_of_package": item.packageUnit,
            "package_type": item.packageType,
            "measurement_cbm": item.measurement,
            "gross_weight_kg": item.weight,
            "container_type": item.containerType,
            "container_no": item.containerNumber,
            "unit_of_container": item.containerUnit,
            "seal_no": item.sealNumber
          }
        })
      }
      console.log(requestBody);
      // const { data } = await axios.post(`${PARTNER_URI}/partner/bol/addBolWithPackages`, requestBody);
      const { data } = await addBolWithPackages(requestBody);
      console.log(data);
      handleToastClick();
      handleDialogClose();
      setErrorMessage("");
      setToastMessage("BOL Order Create Success!");
      // Go Back to home page after 1.5 second
      setTimeout(() => {
        history.push("/home");
      }, 1500);
    } catch (e) {
      console.log(e);
      setErrorMessage(e);
      handleToastClick();
      errHandling(e, setErrMsg);
    } finally {
      setLoadingState(false);
    }
  }

  const onUpdateSubmit = async () => {
    setLoadingState(true);
    try {
      const requestBody = {
        // bol_no: "",
        // ref_no: "",
        bol_id: shippingInfo.bol_id,
        partner_id: partnerId,
        voyage: shippingInfo.vessel,
        etd_date: dayjs(shippingInfo.onBoardDate).format("YYYY-MM-DD"),
        eta_date: dayjs(shippingInfo.onBoardDate).add(shippingInfo.vessel, "day").format("YYYY-MM-DD"),
        ship_company_route_code: shippingInfo.routeCode,
        ship_company: shippingInfo.shipCompany,
        end_port_code: shippingInfo.endPortCode,
        end_port_en_name: shippingInfo.portOfDischarge,
        start_port_code: shippingInfo.startPortCode,
        start_port_en_name: shippingInfo.portOfLoading,
        delivery_agent: shippingInfo.deliveryAgent,
        carrier_agent: shippingInfo.carrierAgent,
        notify_party_name: shippingInfo.notifyPartyName,
        consignee_name: shippingInfo.consigneeName,
        shipper_name: shippingInfo.shipperName,
        shipper_full_address: shippingInfo.shipperAddress,
        consignee_full_address: shippingInfo.consigneeAddress,
        booking_id: shippingInfo.booking_id,
        notify_party_full_address: shippingInfo.notifyPartyAddress,
        delivery_agent_full_address: shippingInfo.deliveryAgentAddress,
        delivery_agent_phone: shippingInfo.deliveryAgentPhone,
        delivery_agent_email: shippingInfo.deliveryAgentEmail,
        consignee_phone: shippingInfo.consigneePhone,
        shipper_phone: shippingInfo.shipperPhone,
        forwarding_agent_name: shippingInfo.forwardingAgentName,
        forwarding_agent_full_address: shippingInfo.forwardingAgentAddress,
        forwarding_agent_phone: shippingInfo.forwardingAgentPhone,
        forwarding_agent_email: shippingInfo.forwardingAgentEmail,
        package_data: itemList.map(item => {
          return {
            id: item.id,
            "item_hs_code": item.hscode,
            "item_desc": item.hscodeDescription,
            "unit_of_package": item.packageUnit,
            "package_type": item.packageType,
            "measurement_cbm": item.measurement,
            "gross_weight_kg": item.weight,
            "container_type": item.containerType,
            "container_no": item.containerNumber,
            "unit_of_container": item.containerUnit,
            "seal_no": item.sealNumber
          }
        })
      }
      console.log(requestBody);
      const { data } = await updateBolWithPackages(requestBody);
      console.log(data);
      setErrorMessage("");
      setToastMessage("BOL Order Update Success!");
      handleToastClick();
      handleDialogClose();
      // Go Back to home page after 1.5 second
      setTimeout(() => {
        history.push("/home");
      }, 1500);
    } catch (e) {
      console.log(e);
      errHandling(e, setErrMsg);
      setErrorMessage(e);
      handleToastClick();
    } finally {
      setLoadingState(false);
    }
  }

  const onCancelSubmit = async () => {
    setLoadingState(true);
    try {
      const requestBody = {
        bol_id: shippingInfo.bol_id,
        partner_id: partnerId,
      }
      console.log(requestBody);
      await cancelBolWithPackages(requestBody);
      // clearData();
      // setSuccessState(true);
      handleToastClick();
      handleDialogClose();
      setErrorMessage("");
      setToastMessage("BOL Order Cancel Success!");
      // Go Back to home page after 1.5 second
      setTimeout(() => {
        history.push("/home");
      }, 1500);
    } catch (e) {
      console.log(e);
      setErrorMessage(e);
      handleToastClick();
      errHandling(e, setErrMsg);
    } finally {
      setLoadingState(false);
    }
  }

  const onActivateSubmit = async () => {
    setLoadingState(true);
    try {
      const requestBody = {
        bol_id: shippingInfo.bol_id,
        partner_id: partnerId,
      }
      console.log(requestBody);
      await activateBol(requestBody);
      // clearData();
      setErrorMessage("");
      handleToastClick();
      handleDialogClose();
      setErrorMessage("");
      setToastMessage("BOL Order Activate Success!");
      // Go Back to home page after 1.5 second
      setTimeout(() => {
        history.push("/home");
      }, 1500);
    } catch (e) {
      console.log(e);
      setErrorMessage(e);
      handleToastClick();
      errHandling(e, setErrMsg);
    } finally {
      setLoadingState(false);
    }

  }

  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const onClick = () => {
    setLoadingState(true);
    if (edit) {
      axios({
        method: "put",
        url: UPDATE_BOL_RECORD,
        data: JSON.stringify({
          info: shippingInfo,
          itemList: itemList,
          refNo: refNo,
        }),
        headers: {
          ...getHeaders(),
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => {
          setSuccessState(true);
          setLoadingState(false);
          clearData();
        })
        .catch((err) => {
          errHandling(err, setErrMsg);
          setLoadingState(false);
        });
    } else {
      axios({
        method: "post",
        url: ADD_BOL_RECORD,
        data: JSON.stringify({ info: shippingInfo, itemList: itemList }),
        headers: {
          ...getHeaders(),
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => {
          setSuccessState(true);
          setLoadingState(false);
          clearData();
        })
        .catch((err) => {
          errHandling(err, setErrMsg);
          setLoadingState(false);
        });
    }
  };

  return success ? (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box>
        <Box color="seagreen" fontSize="1.2rem" pb={2} fontWeight="700">
          The bill is successfully added.
        </Box>
        <Box>
          <Button
            variant="outlined"
            onClick={() => history.push("/bol-list/created")}
          >
            Go back to list page
          </Button>
        </Box>
      </Box>
    </Box>
  ) : (
      <>
        {/*Pop-up Toast*/}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={toastOpen}
            onClose={handleToastClose}
            autoHideDuration={3000}
            message="Cancel Order">
          {(() => {
            if (errorMessage !== "") {
              return (
                  <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                    Oops
                    <hr />
                    Error: {errorMessage}
                  </Alert>
              )
            }
            return (
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                  {toastMessage}
                </Alert>
            )
          })()}

        </Snackbar>
        {/*Pop-up Toast*/}

        {/*Confirm Cancel Dialog*/}
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Cancel Bol Order
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This process will cancel this BOL order. Are you sure to cancel?
              <hr />
              Click <b>Confirm</b> to cancel this order.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button" >No</Button>
            <Button onClick={onCancelSubmit} variant="outlined" type="button" disabled={loading} >
              {loading && <CircularProgress size={14} />}
              {!loading && "Confirm"}
            </Button>
          </DialogActions>
        </Dialog>
        {/*Confirm Cancel Dialog*/}

        <div>
          <br />
          <Box textAlign="center">
            <h2 className="orange-title">BOL Summary:</h2>
          </Box>
          <br />
          <Grid container spacing={3} alignContent="center">
            <Grid item xs={6}>
              <div id="summary-content2" className="bodytext">
                <Box display="flex" justifyContent="space-between">
                  <Box fontWeight="700">Address and shipping</Box>
                  <Button variant="outlined" onClick={() => setActiveStep(0)}>
                    Edit
                  </Button>
                </Box>
              </div>
              <Box id="summary-content1" className="subtitle" pl={1}>
                <p className={classes.summaryHeader}>Shipper</p>
                <p className={classes.summaryBodyText}>
                  {`${shippingInfo.shipperName}`} <br />
                  {`${shippingInfo.shipperAddress}`}
                </p>
              </Box>
              <Box id="summary-content3" className="subtitle" pl={1}>
                <p className={classes.summaryHeader}>Consignee</p>
                <p className={classes.summaryBodyText}>
                  {`${shippingInfo.consigneeName}`} <br />
                  {`${shippingInfo.consigneeAddress}`}
                </p>
              </Box>
              {(shippingInfo.notifyPartyName ||
                  shippingInfo.notifyPartyAddress) && (
                  <Box id="summary-content5" className="subtitle" pl={1}>
                    <p className={classes.summaryHeader}>Notify Party</p>
                    <p className={classes.summaryBodyText}>
                      {`${shippingInfo.notifyPartyName}`} <br />
                      {`${shippingInfo.notifyPartyAddress}`}
                    </p>
                  </Box>
              )}
              <Box id="summary-content6" className="bodytext" pl={1}>
                <p className={classes.summaryBodyText}>
                  <Box component="span" color="seagreen" fontWeight="600">
                    Carrier Agent
                  </Box>
                  : {shippingInfo.carrierAgent}
                </p>
                <p className={classes.summaryBodyText}>
                  <Box component="span" color="seagreen" fontWeight="600">
                    Delivery Agent
                  </Box>
                  : {shippingInfo.deliveryAgent}
                </p>
                {shippingInfo.placeOfAcceptance && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Place of Acceptance
                      </Box>
                      : {shippingInfo.placeOfAcceptance}
                    </p>
                )}
                {shippingInfo.preCarriage && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Pre-carriage by
                      </Box>
                      : {shippingInfo.preCarriage}
                    </p>
                )}
                {shippingInfo.numberOfOriginal && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Number of Original
                      </Box>
                      : {shippingInfo.numberOfOriginal}
                    </p>
                )}
                {shippingInfo.payableAt && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Freight & Charges Payable at
                      </Box>
                      : {shippingInfo.payableAt}
                    </p>
                )}
                {shippingInfo.excessValueDeclaration && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Excess Value Declaration
                      </Box>
                      : {shippingInfo.excessValueDeclaration}
                    </p>
                )}
                {shippingInfo.vessel && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Vessel / Voyage
                      </Box>
                      : {shippingInfo.vessel}
                    </p>
                )}
                {shippingInfo.portOfLoading && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Port of Loading
                      </Box>
                      : {shippingInfo.portOfLoading}
                    </p>
                )}
                {shippingInfo.portOfDischarge && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Port of Discharge
                      </Box>
                      : {shippingInfo.portOfDischarge}
                    </p>
                )}
                {shippingInfo.placeOfDelivery && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Port of Delivery
                      </Box>
                      : {shippingInfo.placeOfDelivery}
                    </p>
                )}
                {shippingInfo.onCarriageto && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        On Carriage to
                      </Box>
                      : {shippingInfo.onCarriageto}
                    </p>
                )}
                {shippingInfo.onBoardDate && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        On Board Date
                      </Box>
                      : {shippingInfo.onBoardDate}
                    </p>
                )}
                {shippingInfo.issueDate && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Date of Issue
                      </Box>
                      : {shippingInfo.issueDate}
                    </p>
                )}
                {shippingInfo.issuePlace && (
                    <p className={classes.summaryBodyText}>
                      <Box component="span" color="seagreen" fontWeight="600">
                        Place of Issue
                      </Box>
                      : {shippingInfo.issuePlace}
                    </p>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.itemSection}>
                <div id="summary-content8" className="bodytext">
                  <Box display="flex" justifyContent="space-between">
                    <Box fontWeight="700">Items</Box>
                    <Button variant="outlined" onClick={() => setActiveStep(1)}>
                      Edit
                    </Button>
                  </Box>
                </div>
                <Box id="summary-content7" className="subtitle" pl={1}>
                  <div className={classes.summaryBodyText}>
                    {itemList.map((item, key) => {
                      return (
                          <div key={key}>
                            <p>
                              {item.hscode}, {item.hscodeDescription}
                            </p>
                          </div>
                      );
                    })}
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <div></div>
          <div></div>
          <Box display="flex" justifyContent="center" alignItems="center" pt={4}>
            <Box>
              {(() => {
                if (edit) {

                  if (shippingInfo.status === -2) {
                    return (
                        <Box display="flex" sx={{ gap: "3rem" }}>
                          <Button
                              variant="contained"
                              color="primary"
                              onClick={onUpdateSubmit}
                              style={{
                                textTransform: "unset",
                                width: "8rem",
                              }}
                          >
                            {loading ? <CircularProgress size={24} style={{ color: "black" }} /> : "Update" }
                          </Button>
                          <Button
                              variant="contained"
                              color="primary"
                              onClick={onActivateSubmit}
                              style={{
                                textTransform: "unset",
                                width: "8rem",
                              }}
                          >
                            {loading ? <CircularProgress size={24} style={{ color: "black" }} /> : "Activate" }
                          </Button>
                        </Box>
                    )
                  }
                  return (
                      <Box display="flex" sx={{ gap: "3rem" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onUpdateSubmit}
                            style={{
                              textTransform: "unset",
                              width: "8rem",
                            }}
                        >
                          {loading ? <CircularProgress size={24} style={{ color: "black" }} /> : "Update" }
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDialogOpen}
                            style={{
                              textTransform: "unset",
                              width: "8rem",
                            }}
                        >
                          {loading ? <CircularProgress size={24} style={{ color: "black" }} /> : "Cancel" }
                        </Button>
                      </Box>
                  )
                }
                return (
                    <>
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={onCreateSubmit}
                          style={{
                            textTransform: "unset",
                            width: "8rem",
                          }}
                      >
                        {loading ? (
                            <CircularProgress size={24} style={{ color: "black" }} />
                        ) : ("Submit")
                        }
                      </Button>
                    </>

                )
              })()}

            </Box>
            {errMsg && (
                <Box pt="1vh" width="100%">
                  <Alert severity="error" classes={{ root: classes.alertRoot }}>
                    {errMsg}
                  </Alert>
                </Box>
            )}
          </Box>
          <br />
        </div>
      </>

  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: 20,
    fontSize: "25pt",
    backgroundColor: "#ffa726",
    color: "white",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "seagreen",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  summaryBodyText: {
    fontSize: "13pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
  },
  itemSection: {
    borderLeft: "1px solid rgba(0,0,0,0.3)",
    paddingLeft: theme.spacing(2),
  },
}));

// BolDetails.propTypes = {
//   toSeventh: PropTypes.func.isRequired,
//   toFirst: PropTypes.func.isRequired,
//   toFourth: PropTypes.func.isRequired,
// };

// export default connect(mapStateToProps, { getShipmentItems, getPickUpDetails })(QuoteSummary);
export default BolDetails;
