import {Accordion, AccordionDetails, AccordionSummary, Container, Divider, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {useDispatch, useSelector} from "react-redux";
import {mapCarrierLogo, mapLTLLogo, mapSpecialService} from "../../utils/Helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Fragment, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";
import {PARTNER_URI} from "../../utils/apiUrl";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../shared/constInfo";
import axios from "axios";
import {deleteToken, getAccessToken} from "../../utils/doToken";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {
    clearShippingLTLCreateLabelComment,
    clearShippingLTLCreateLabelDateAndTime,
    clearShippingLTLCreateLabelPalletList,
    clearShippingLTLCreateLabelRate,
    clearShippingLTLCreateLabelReferenceNumber,
    saveShippingLTLQuickQuoteSpecialService,
    updateShippingLTLCreateLabelMoveFrom,
    updateShippingLTLCreateLabelMoveTo,
    updateShippingLTLCreateLabelSpecialService,
    updateShippingLTLQuickQuoteSpecialService
} from "../Actions/shippingLTLAction";

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ShippingLTLCreateLabelOrderDetails = () => {

    const token = getAccessToken("access_token");

    const history = useHistory();

    const dispatch = useDispatch();

    let batteryDetails = useSelector(state => state?.shippingLTL?.createLabelBatteryDetails);
    let selectRate = useSelector(state => state?.shippingLTL?.createLabelSelectRate);
    let palletListRedux = useSelector(state => state?.shippingLTL?.createLabelPalletList);
    let shipFrom = useSelector(state => state?.shippingLTL?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingLTL?.createLabelShipTo);
    let metric = useSelector(state => state?.shippingLTL?.createLabelUnit);
    let specialService = useSelector(state => state?.shippingLTL?.createLabelSpecialService);
    let referenceNumberRedux = useSelector(state => state?.shippingLTL?.createLabelReferenceNumber);
    let commentRedux = useSelector(state => state?.shippingLTL?.createLabelComment);
    let dateAndTimeRedux = useSelector(state => state?.shippingLTL?.createLabelDateAndTime);
    let crossBorderFee = useSelector(state => state?.shippingLTL?.crossBorderRate);
    let crossBorderTransAndType = useSelector(state => state?.shippingLTL?.crossBorderTransAndType);
    let packingListRedux = useSelector(state => state?.shippingLTL?.crossBorderItemList);
    const userInfo = useSelector((state) => state.user);

    const [buyShippingStatus, setBuyShippingStatus] = useState(false);
    const [loadingCreateQuote, setLoadingCreateQuote] = useState(false);
    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleError = (errorCode) => {
        switch (errorCode) {
            case 400:
                setErrorMessage("Bad Request: The server cannot process the request.");
                break;
            case 401:
                setErrorMessage("Unauthorized: Authentication is required to access the resource.");
                break;
            case 404:
                setErrorMessage("Not Found: The requested resource could not be found.");
                break;
            case 500:
                setErrorMessage("Internal Server Error: The server encountered an unexpected condition.");
                break;
            default:
                setErrorMessage("Unknown Error: An unknown error occurred.");
                break;
        }
    }

    const createQuote = async () => {
        setLoadingCreateQuote(true);
        let requestURL = `${PARTNER_URI}/shippingLTL/createLTLQuote`;

        // todo edit battery details
        let packageData = palletListRedux?.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement
        });

        let exchangedPackageData = packageData.map(item => {
            let {length, width} = item;
            if (parseInt(length) < parseInt(width)) {
                return {
                    ...item,
                    length: width,
                    width: length
                }
            }
            return item;
        })

        let data = {
            shipperName: shipFrom?.name,
            shipperPhone: shipFrom?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipperCompanyName: shipFrom?.company || shipFrom?.name,
            shipperEmail: shipFrom?.email,
            shipperAddress: shipFrom?.address,
            shipperAddressTwo: shipFrom?.address2 || "",
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: shipFrom?.city,
            shipperProvince: shipFrom?.province?.code,
            shipperPostalCode: shipFrom?.postalCode,
            shipperCountry: shipFrom?.country,
            consigneeName: shipTo?.name,
            consigneePhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            consigneeCompanyName: shipTo?.company || shipTo?.name,
            consigneeEmail: shipTo?.email,
            consigneeAddress: shipTo?.address,
            consigneeAddressTwo: shipTo?.address2 || "",
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: shipTo?.city,
            consigneeProvince: shipTo?.province?.code,
            consigneePostalCode: shipTo?.postalCode,
            consigneeCountry: shipTo?.country,
            serviceName: selectRate?.serviceName,
            pickupDate: dateAndTimeRedux?.pickupDate,
            packageData: exchangedPackageData,
            specialServices: specialService,
            refNumber: referenceNumberRedux,
            comments: commentRedux,
            transMode: crossBorderTransAndType?.transMode,
            shipmentType: crossBorderTransAndType?.shipmentType,
            packingList: packingListRedux
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('create quote', result);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully create quote.')
            setLoadingCreateQuote(false);
            dispatch(clearShippingLTLCreateLabelRate());
            dispatch(clearShippingLTLCreateLabelReferenceNumber());
            dispatch(clearShippingLTLCreateLabelComment());
            dispatch(clearShippingLTLCreateLabelDateAndTime());
            dispatch(updateShippingLTLCreateLabelMoveFrom(null));
            dispatch(updateShippingLTLCreateLabelMoveTo(null));
            dispatch(clearShippingLTLCreateLabelPalletList());
            dispatch(updateShippingLTLCreateLabelSpecialService([]));
            setTimeout(() => {
                history.push(`/myLTLQuote`);
            }, 2000);

        } catch (e) {
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
                handleErrorMessage();
            } else {
                const errorCode = e?.response?.data?.error?.status;
                console.log('error code', errorCode);
                handleErrorMessage();
                handleError(errorCode);
                setLoadingCreateQuote(false);
            }
        }
    }

    const partnerBuyShipping = async () => {

        setLoadingPlaceOrder(true);
        setBuyShippingStatus(true);

        let requestURI = `${PARTNER_URI}/shippingLTL/initLTLBackendOrder`;

        // todo edit battery details
        let packageData = palletListRedux?.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement
        });

        let exchangedPackageData = packageData.map(item => {
            let {length, width} = item;
            if (parseInt(length) < parseInt(width)) {
                return {
                    ...item,
                    length: width,
                    width: length
                }
            }
            return item;
        })

        let data = {
            shipperName: shipFrom?.name,
            shipperPhone: shipFrom?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipperCompanyName: shipFrom?.company || shipFrom?.name,
            shipperEmail: shipFrom?.email,
            shipperAddress: shipFrom?.address,
            shipperAddressTwo: shipFrom?.address2 || "",
            shipperAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: shipFrom?.city,
            shipperProvince: shipFrom?.province?.code,
            shipperPostalCode: shipFrom?.postalCode,
            shipperCountry: shipFrom?.country,
            consigneeName: shipTo?.name,
            consigneePhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            consigneeCompanyName: shipTo?.company || shipTo?.name,
            consigneeEmail: shipTo?.email,
            consigneeAddress: shipTo?.address,
            consigneeAddressTwo: shipTo?.address2 || "",
            consigneeAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: shipTo?.city,
            consigneeProvince: shipTo?.province?.code,
            consigneePostalCode: shipTo?.postalCode,
            consigneeCountry: shipTo?.country,
            shipToName: shipTo?.name,
            shipToPhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipToCompanyName: shipTo?.company || shipTo?.name,
            shipToEmail: shipTo?.email,
            shipToAddress: shipTo?.address,
            shipToAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToPostalCode: shipTo?.postalCode,
            shipToCountry: shipTo?.country,
            soldToName: shipTo?.name,
            soldToPhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            soldToCompanyName: shipTo?.company || shipTo?.name,
            soldToEmail: shipTo?.email,
            soldToAddress: shipTo?.address,
            soldToAddressType: specialService.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
            soldToCity: shipTo?.city,
            soldToProvince: shipTo?.province?.code,
            soldToPostalCode: shipTo?.postalCode,
            soldToCountry: shipTo?.country,
            serviceName: selectRate?.serviceName,
            shipDate: dateAndTimeRedux?.shipDate,
            pickupLocationCloseTime: `${dateAndTimeRedux?.shipDate} ${dateAndTimeRedux?.latestPickupTime}`,
            pickupLocationOpenTime: `${dateAndTimeRedux?.shipDate} ${dateAndTimeRedux?.earliestPickupTime}`,
            pickupAvailableTime: `${dateAndTimeRedux?.shipDate} ${dateAndTimeRedux?.availablePickupTime}`,
            pickupDate: dateAndTimeRedux?.pickupDate,
            transMode: crossBorderTransAndType?.transMode,
            shipmentType: crossBorderTransAndType?.shipmentType,
            pickupInstruction: "",
            packageData: exchangedPackageData,
            specialServices: specialService,
            refNumber: referenceNumberRedux,
            comments: commentRedux,
            packingList: packingListRedux
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('place order', result);
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully place LTL order.')
            setLoadingPlaceOrder(false);
            dispatch(clearShippingLTLCreateLabelRate());
            dispatch(clearShippingLTLCreateLabelReferenceNumber());
            dispatch(clearShippingLTLCreateLabelComment());
            dispatch(clearShippingLTLCreateLabelDateAndTime());
            dispatch(updateShippingLTLCreateLabelMoveFrom(null));
            dispatch(updateShippingLTLCreateLabelMoveTo(null));
            dispatch(clearShippingLTLCreateLabelPalletList());
            dispatch(updateShippingLTLCreateLabelSpecialService([]));
            setTimeout(() => {
                history.push(`/myLTLs`);

            }, 2000);

        } catch (e) {
            console.log(e.response);
            if (e.response?.status === 403) {
                setErrorMessage(`${e.response?.data?.error}. Please contact the operator at op@uucargo.ca for assistance.`);
                handleErrorMessage();
            } else if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
                handleErrorMessage();
            } else {
                const errorCode = e?.response?.data?.error?.status;
                console.log('error code', errorCode);
                handleError(errorCode);
                handleErrorMessage();
            }
        } finally {
            setLoadingPlaceOrder(false);
        }

    }

    const handlePlaceOrder = () => {
        partnerBuyShipping();
    }

    // console.log('select rate', selectRate);
    // console.log('special service', specialService);
    // console.log('date and time', dateAndTimeRedux);
    // console.log('ship from', shipFrom);
    console.log('cross border fee', crossBorderFee);
    console.log('cross border trans and type', crossBorderTransAndType);
    console.log('cross border packing list', packingListRedux);

    const handleQuoteList = () => {
        history.push('/LTL-create-label/rate-list');
    }

    const handleCreateQuote = () => {
        createQuote();
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    if (!shipFrom?.country && !buyShippingStatus) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsHeading}>
                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SHIPPING_ORDER_DETAILS')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                You've lost all the data. Please return to the home page and create a new shipment.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                                <Button
                                    variant='contained'
                                    onClick={() => history.replace('/home')}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Go Back Home
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsHeading}>
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SHIPPING_ORDER_DETAILS')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                        Pallet List
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                        Pallet Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.DIMENSION')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.QUANTITY')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.WEIGHT')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {
                                palletListRedux.length > 0 && palletListRedux?.map((each, index) => (
                                    <Grid container spacing={1} key={index}>
                                        <Grid item xs={3}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {each?.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {each?.length} x {each?.width} x {each?.height} {each?.lengthUnit}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {each?.quantity}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTitle}>
                                                {each?.weight} {each?.weightUnit}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography style={{color: 'red'}}>
                                    *
                                </Typography>
                                <Typography style={{textAlign: 'left', fontSize: '12px'}}>
                                    {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PACKAGE_DETAILS.MESSAGE')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                        {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipFrom?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipFrom?.address2 && `${shipFrom?.address2} - `}{shipFrom?.address}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipFrom?.city}, {shipFrom?.province?.code}, {shipFrom?.country}, {shipFrom?.postalCode}
                                    </Typography>
                                </Grid>
                                {shipFrom?.email &&
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.email}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipFrom?.phone}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                        {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipTo?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipTo?.address2 && `${shipTo?.address2} - `}{shipTo?.address}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipTo?.city}, {shipTo?.province?.code}, {shipTo?.country}, {shipTo?.postalCode}
                                    </Typography>
                                </Grid>
                                {shipTo?.email &&
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.email}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {shipTo?.phone}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                        {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SHIPPING_CARRIER')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{border: '2px solid #609966', borderRadius: '10px'}}>
                                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSectionPrice}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3}>
                                                    <Box sx={styles?.ShippingPackageCreateLabelOrderDetailsImageBox}>
                                                        <img src={mapLTLLogo(selectRate?.serviceName)} alt="logo"
                                                             width='60px'/>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Box
                                                        sx={styles.ShippingPackageCreateLabelOrderDetailsOtherBoxCenter}>
                                                        <Typography
                                                            sx={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                            {
                                                                selectRate?.serviceName.toUpperCase() === "DAYANDROSS" ? "DAY && ROSS" : selectRate?.serviceName.toUpperCase() === "TFORCE" ? "TForce" : selectRate?.serviceName.toUpperCase()
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Box
                                                        sx={styles.ShippingPackageCreateLabelOrderDetailsOtherBoxCenter}>
                                                        <Typography
                                                            sx={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                            2-10 business days
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Box
                                                        sx={styles.ShippingPackageCreateLabelOrderDetailsOtherBoxRight}>
                                                        <Typography
                                                            sx={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            $ {(+selectRate?.finalTotal + (+crossBorderFee?.grandTotal || 0))?.toFixed(2)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box sx={{borderTop: '2px solid #609966', padding: '0 20px'}}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <Accordion
                                                        sx={{
                                                            boxShadow: 'none',
                                                        }}
                                                    >
                                                        <AccordionSummary
                                                            sx={{
                                                                flexDirection: 'row-reverse'
                                                            }}
                                                            expandIcon={<ExpandMoreIcon/>}
                                                        >
                                                            < Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                                {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PRICE_DETAILS')}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={1}>
                                                                {
                                                                    selectRate?.shipmentCharges?.map((each, index) => {
                                                                        const cost = +each?.value
                                                                        if (cost !== 0) {
                                                                            return (
                                                                                <Fragment key={index}>
                                                                                    <Grid item xs={6}>
                                                                                        <Typography
                                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                            {each?.name}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={6}>
                                                                                        <Typography
                                                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                            $ {(+each?.value)?.toFixed(2)}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Fragment>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                <Grid item xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        Shipping Fee
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        $ {((+selectRate?.shippingFee).toFixed(2))}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        Tax
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        $ {(+selectRate?.taxCharge)?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                                {
                                                                    crossBorderFee?.totalBrokerageFees > 0 &&
                                                                    <>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                Custom Brokerage Fee
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                $ {(+crossBorderFee?.totalBrokerageFees)?.toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                {
                                                                    crossBorderFee?.totalDutyandTax > 0 &&
                                                                    <>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                Duty And Tax
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography
                                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                                $ {(+crossBorderFee?.totalDutyandTax)?.toFixed(2)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                <Grid item xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                                        Total
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                                        $ {(+selectRate?.finalTotal + (+crossBorderFee?.grandTotal || 0))?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                        Pickup Date And Time
                                    </Typography>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography
                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        Pickup Date
                                    </Typography>
                                    <Typography
                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {dateAndTimeRedux?.pickupDate}
                                    </Typography>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography
                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        Pickup Earliest Time
                                    </Typography>
                                    <Typography
                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {dateAndTimeRedux?.earliestPickupTime}
                                    </Typography>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography
                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        Pickup Latest Time
                                    </Typography>
                                    <Typography
                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                        {dateAndTimeRedux?.latestPickupTime}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {
                        specialService?.length > 0 &&
                        <Grid item xs={12}>
                            <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                            Special Services
                                        </Typography>
                                    </Grid>
                                    {
                                        specialService?.map((each, index) => (
                                            <Grid item xs={4} key={index}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                    {mapSpecialService(each?.serviceCode)}
                                                </Typography>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                    }
                    {
                        commentRedux &&
                        <Grid item xs={12}>
                            <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                                            Remark Notes
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {commentRedux}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Button
                            size='large'
                            variant='contained'
                            onClick={handleQuoteList}
                        >
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.BACK')}
                        </Button>
                        {
                            (selectRate?.serviceName === 'dayandross' || selectRate?.serviceName?.toLowerCase() === 'csa' || selectRate?.serviceName?.toLowerCase() === 'tforce') &&
                            <LoadingButton
                                size='large'
                                variant='contained'
                                color='success'
                                loading={loadingCreateQuote}
                                onClick={handleCreateQuote}
                            >
                                {intl.get('SHIPPING_LTL_QUICK_QUOTE.CREATE_QUOTE')}
                            </LoadingButton>
                        }
                        <LoadingButton
                            size='large'
                            variant='contained'
                            color='success'
                            loading={loadingPlaceOrder}
                            onClick={handlePlaceOrder}
                        >
                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.PLACE_ORDER')}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Box>
        </Container>
    )
}
