import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CreditCardList} from "../CreditCard/CreditCardList";
import CreditCardIcon from "../../images/creditCardIcon.png";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {getAccessToken} from "../../utils/doToken";
import {useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import {CreditCardSetup} from "../CreditCard/CreditCardSetup";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {Divider, Snackbar} from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {calTransactionFee} from "../../utils/Helper";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    BillingPaymentCardRoot: {
        backgroundColor: '#FFFFFF',
        borderRadius: '0 0 8px 8px',
        padding: '15px 30px 40px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        minWidth: '450px'
        // width: '100%'
    }
}

export const BillingPaymentCard = ({cbResetPaymentMethod, totalAmount, selectInvoice}) => {

    const storedToken = getAccessToken("access_token");

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [cardList, setCardList] = useState([]);
    const [selectCardId, setSelectCardId] = useState(null);
    const [addNewCard, setAddNewCard] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleClickOpen = () => {
        setAddNewCard(true);
    };

    const handleClose = () => {
        setAddNewCard(false);
    };

    const cbSelectCardId = (cardId) => {
        setSelectCardId(cardId);
    }

    const getValidCardList = async () => {
        const requestURL = `${PARTNER_URI}/stripe/validCreditCardList`;
        setIsLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
            })

            console.log('result', result);
            setCardList(result?.data);
            setIsLoading(false);
            setError(false);
        } catch (e) {
            console.log('error', e.response);
            setError(true);
        }
    }

    const payInvoicesByCreditCard = async () => {
        const requestURL = `${PARTNER_URI}/billingCenter/payInvoiceListViaCreditCardForPartner`;
        setLoading(true);

        const invoiceList = selectInvoice?.map(item => {
            return {
                invoiceId: item.id
            }
        })

        const data = {
            creditCardId: selectCardId,
            invoiceList: invoiceList
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: data
            })

            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Your invoice has been successfully paid.');
            handleSuccessMessage();
            setLoading(false);
            setTimeout(() => window.location.reload(), 2000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Your invoice payment has not been successful.');
            handleErrorMessage();
            setLoading(false);
        }
    }

    const handleConfirm = () => {
        payInvoicesByCreditCard();
    }

    const cbGetValidCardList = () => {
        getValidCardList();
    }

    useEffect(() => {
        getValidCardList()
    }, []);

    if (isLoading) {
        return <Box sx={{display: 'flex', justifyContent: 'center', padding: '30px'}}>
            <CircularProgress/>
        </Box>
    }

    if (error && !isLoading) {
        return <Box sx={{maxWidth: '450px', padding: '15px 30px 15px',}}>
            <Typography style={{fontWeight: '600'}}>
                Sorry We currently encounter some issues, please try again later.
            </Typography>
        </Box>
    }

    console.log('select card', selectCardId);

    return (
        <Box sx={styles.BillingPaymentCardRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Typography style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.CREDIT_CARD_INFORMATION')}
            </Typography>
            {
                cardList?.length > 0 ? <Box>
                        <CreditCardList cardList={cardList} cbSelectCardId={cbSelectCardId}/>
                    </Box> :
                    <Box sx={{
                        display: 'flex',
                        border: '1px dashed #000000',
                        flexDirection: 'column',
                        borderRadius: '5px',
                        gap: '5px',
                        padding: '10px',
                        backgroundColor: '#EAEAEA',
                    }}>
                        <Box
                            sx={{position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                            <img src={CreditCardIcon} alt="creditCardLogo" width={30}/>
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    left: '49%',
                                    top: '15%',
                                }}
                                onClick={handleClickOpen}
                            >
                                <AddCircleIcon
                                    sx={{
                                        backgroundColor: '#ffffff',
                                        color: '#1D8B45',
                                        borderRadius: '50%',
                                        fontSize: '12px'
                                    }}
                                />
                            </IconButton>
                            <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                                {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.ADD_NEW_CARD')}
                            </Typography>
                        </Box>
                        {/*<Box>*/}
                        {/*    <Typography style={{fontSize: '12px'}}>*/}
                        {/*        You do not currently have any credit cards associated*/}
                        {/*    </Typography>*/}
                        {/*    <Typography style={{fontSize: '12px'}}>*/}
                        {/*        with your account. Please add a credit card.*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        <Box sx={{display: 'flex', gap: '2px'}}>
                            <Typography style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                *
                            </Typography>
                            <Box>
                                <Typography style={{fontSize: '10px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.ADD_NEW_CARD_NOTE')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
            }
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Typography style={{
                        fontWeight: '600',
                        textAlign: 'right',
                        fontSize: '14px'
                    }}>
                        {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.SUBTOTAL')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{
                        textAlign: 'right',
                        fontSize: '14px'
                    }}>
                        $ {totalAmount?.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{
                        fontWeight: '600',
                        textAlign: 'right',
                        fontSize: '14px'
                    }}>
                        {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.TRANSACTION_FEE')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{
                        textAlign: 'right',
                        fontSize: '14px'
                    }}>
                        $ {calTransactionFee(totalAmount)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{
                        textAlign: 'right',
                        fontWeight: '600',
                    }}>
                        {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.TOTAL')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{
                        textAlign: 'right',
                        fontWeight: '600',
                    }}>
                        $ {(totalAmount + +calTransactionFee(totalAmount)).toFixed(2)}
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: '#F2BE22',
                        "&:hover": {
                            backgroundColor: '#F2BE22',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    onClick={cbResetPaymentMethod}
                >
                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                        {intl.get('BILLING_CENTER.PAYMENT.BACK')}
                    </Typography>
                </Button>
                <LoadingButton
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    onClick={handleConfirm}
                    loading={loading}
                    disabled={!selectCardId}
                >
                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                        {intl.get('BILLING_CENTER.PAYMENT.CONFIRM')}
                    </Typography>
                </LoadingButton>
            </Box>
            <Modal onClose={handleClose} open={addNewCard}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '2px solid #000000',
                    width: 400,
                    boxShadow: 24,
                    backgroundColor: '#ffffff',
                    borderRadius: '10px',
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Box sx={{backgroundColor: '#1D8B45', borderRadius: '5px 0 5px 0', padding: '20px'}}>
                            <Typography style={{fontSize: '20px', color: '#ffffff'}}>
                                Add Credit Card
                            </Typography>
                        </Box>
                        <Box>
                            <CreditCardSetup handleClose={handleClose} cbGetValidCardList={cbGetValidCardList}
                                             selectCardId={selectCardId}/>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}