import Grid from "@material-ui/core/Grid";
import {Fragment, useState} from "react";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {Divider, Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TransportationAvailableTasksEach = ({taskDetails}) => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleViewDetails = (id) => {
        history.push(`/transportation/availableTasks/details/${id}`);
    }

    const claimOrder = async (id) => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/movingService/partnerClaimMovingOrder`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                },
                data: {
                    backendOrderId: id
                }
            });
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage('Successfully claim order.');
            setTimeout(() => {
                history.push(`/transportation/claimTasks/details/${id}`);
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            handleErrorMessage();
            setErrorMessage("Fail to claim order.");
        } finally {
            setLoading(false);
        }
    }

    const handleClaimOrder= (id) => {
        claimOrder(id);
    }

    console.log('[TransportationAvailableTasksEach] taskDetails', taskDetails);

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        {taskDetails?.pickup_area}, {taskDetails?.pickup_postal_code}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        {taskDetails?.deliver_area}, {taskDetails?.deliver_postal_code}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        {dayjs(taskDetails?.scheduled_pickup_date)?.format("YYYY-MM-DD")}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        {taskDetails?.google_map_distance} km
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        $ {taskDetails?.final_total?.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={() => handleViewDetails(taskDetails?.id)}
                                size='small'
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    color: '#1D8B45',
                                    fontSize: '14px'
                                }}>
                                    Details
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <LoadingButton
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                size='small'
                                onClick={() => handleClaimOrder(taskDetails?.id)}
                                loading={loading}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    color: '#1D8B45',
                                    fontSize: '14px'
                                }}>
                                    Claim
                                </Typography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}
