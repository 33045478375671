import React, { useState, useEffect } from "react";
import {
  ArrowBackIos,
  ArrowForwardIos,
  ArrowBack,
  Add,
} from "@material-ui/icons";
import { IconButton, Box } from "@material-ui/core";
// import Slide from 'react-reveal';
import { useSelector } from "react-redux";
import "../../styles/shippingItem.css";
import PropTypes from "prop-types";

const FreightClass = ({ backToFourth, toSixth, shippingItems }) => {
  // const shippingItems = useSelector(state => state.shipping.shippingItems);

  const [pallets, setPallets] = useState(shippingItems);
  const [palletItem, setPalletItem] = useState([
    {
      item: "Speakers",
      numPallets: "5",
      weight: "200",
      weightClass: "250",
    },
    {
      item: "Speakers",
      numPallets: "1",
      weight: "300",
      weightClass: "200",
    },
  ]);

  const createListItems = () => {
    pallets.map((item, key) => {
      return <div key={key.id}>{item.description}</div>;
    });
  };

  return (
    <div>
      {createListItems()}
      <div>
        <div id="freight-content0">
          <h1>We Calculated your dimensional freight class</h1>
        </div>
        <br />
        <br />
        <div>
          {pallets.map((item, key) => {
            return (
              <div key={item.packageID} className="freight-class-container">
                <div id="freight-content1">
                  <h2 className="freight-item-subtitle">CLASS</h2>
                  <p className="freight-item-class" style={{ display: "none" }}>
                    250
                  </p>
                </div>
                <div id="freight-content2">
                  <div className="freight-item-subtitle">
                    <p>{item.description}</p>
                  </div>
                  <div>
                    <p className="freight-item-body">
                      {item.quantity} Pallet(s) &times; {item.weight} pounds
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <br />
        <div className="freight-button-center">
          <Box mr="2vw" display="inline-box">
            <IconButton onClick={backToFourth} id="freight-button-back">
              <ArrowBackIos
                viewBox="-6 0 24 24"
                style={{ fontSize: "160%", fill: "green" }}
              />
            </IconButton>
          </Box>
          <IconButton onClick={toSixth} id="freight-button-forward">
            <ArrowForwardIos style={{ fontSize: "160%", fill: "white" }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

FreightClass.propTypes = {
  backToFourth: PropTypes.func.isRequired,
  toSixth: PropTypes.func.isRequired,
  shippingItems: PropTypes.array.isRequired,
};

// const mapStateToProps = (state) => ({
//     shippingItems: state.shippingItems
// })

// export default connect(mapStateToProps, { getShipmentItems })(FreightClass);
export default FreightClass;
