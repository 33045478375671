import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { PARTNER_URI } from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import {useSelector} from "react-redux";
import ShippingTransactions from "./ShippingTransactions";
import CrossBoarders from "./CrossBoarders";
import {CardHeader, CircularProgress} from "@material-ui/core";

export default function CrossBoarderCard({ classes, orderId }) {
  const [transactionList, setTransactionList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { firstname, id } = useSelector((state) => state.user);
  const [orderData, setOrderData] = useState({});
  const getOrderData = async ({ orderId }) => {
    try {
      setIsLoading(true);
      // const requestURI = `${PARTNER_URI}/shipping/list/order?partner_id=${id}&order_id=${orderId}`;
      const requestURI = `${PARTNER_URI}/shipping/list?&order_id=${orderId}&partner_id=${id}`;
      const { data } = await axios.get(requestURI);
      if (data?.result && data?.result?.length > 0) {
        console.log("orderData: ", data.result[0]);
        setOrderData(data.result[0]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getOrderData({ orderId });
  }, [orderId]);

  if (isLoading) {
    return (
        <Grid item xs={12} md={12} lg={12} xl={6}>
          <CircularProgress />
        </Grid>
    )
  }

  return (
      <Grid item xs={6} md={6} lg={6} xl={6}>
        <Card
            className={classes.paper}
            style={{ minHeight: "55vh", boxShadow: "none" }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <a href={`/commercial-invoice/${orderId}`}>
                <Typography variant="h6">{`Cross Border Commercial Invoice`}</Typography>
              </a>

            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <CrossBoarders orderId={orderData.order_id} tranId={orderData.tran_id} shippingIdNumber={orderData.shipping_id_no} />
            </Grid>
            {/*<Grid item xs={12} md={12} lg={12}>*/}
            {/*  <Button variant="contained">{`Check More`}</Button>*/}
            {/*</Grid>*/}
          </Grid>
        </Card>
      </Grid>
  );
}
