import { makeStyles } from "@material-ui/core/styles";

const BillingCenterStyles = makeStyles((theme) => ({
  billingCenterTitle: {
    color: "#597741",
  },
  card: {
    padding: "1rem",
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    // height:"30vh",
    // minWidth: "800px"
  },
}));

export default BillingCenterStyles;
