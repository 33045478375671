import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery} from "@material-ui/core";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Checkbox, FormControlLabel, Link} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import {updateShippingLTLCreateLabelBatteryDetails} from "../Actions/shippingLTLAction";

const styles = {
    ShippingPackageCreateLabelPackageDetailsRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },

    ShippingPackageCreateLabelPackageDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },

    ShippingPackageCreateLabelPackageDetailsSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    ShippingPackageCreateLabelPackageDetailsSectionColumn: {
        display: 'flex',
        flexDirection: 'column',
    },

    ShippingPackageCreateLabelPackageDetailsModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '15px 15px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelPackageDetailsSelectBox: {
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelPackageDetailsSelectCommonBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    }
}

export const ShippingLTLCreateLabelShippingOptions = ({batteryDetailsHistory}) => {

    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width: 480px)');

    let batteryDetailsRedux = useSelector(state => state?.shippingLTL?.createLabelBatteryDetails);

    const [isBattery, setIsBattery] = useState(false);
    const [batteryDetails, setBatteryDetails] = useState({
        material: null,
        packing: null,
        quantity: 0
    });

    const handleIsBattery = event => {
        setIsBattery(event?.target?.checked);
        if (event?.target?.checked) {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: 'LITHIUM_ION',
                packing: 'CONTAINED_IN_EQUIPMENT',
                quantity: 1
            }))
        } else {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: null,
                packing: null,
                quantity: 0
            }))
        }
    }

    const handleBatteryType = (type) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            material: type
        }))
    }

    const handleBatteryPackaged = (packaged) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            packing: packaged
        }))
    }

    const handleBatteryQuantity = (event) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            quantity: event.target.value < 1 ? '1' : event.target.value
        }))
    }
    useEffect(() => {
        if (batteryDetailsRedux?.material) {
            setIsBattery(true);
            setBatteryDetails(batteryDetailsRedux);
        }
    },[]);

    useEffect(() => {
        dispatch(updateShippingLTLCreateLabelBatteryDetails(batteryDetails));
    },[batteryDetails]);


    useEffect(() => {
        if (batteryDetailsHistory?.material) {
            setIsBattery(true);
            setBatteryDetails(batteryDetailsHistory);
        }
    }, [batteryDetailsHistory]);

    console.log('[ShippingLTLCreateLabelShippingOptions] battery details history', batteryDetailsHistory);
    console.log('[ShippingLTLCreateLabelShippingOptions] battery details local', batteryDetails);
    console.log('[ShippingLTLCreateLabelShippingOptions] battery details redux', batteryDetailsRedux);

    return (
        <Box sx={styles.ShippingPackageCreateLabelPackageDetailsRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={styles.ShippingPackageCreateLabelPackageDetailsHeading}>
                        {intl.get('PACKAGE_SHIPPING_OPTIONS.SHIPPING_OPTIONS')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}>
                        <FormControlLabel
                            value="start"
                            control={<Checkbox
                                checked={isBattery}
                                onChange={handleIsBattery}
                                color="success"
                            />}
                            label={
                                <Typography style={{textAlign: 'left'}}>
                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.INCLUDE_BATTERY')}
                                </Typography>
                            }
                            labelPlacement="end"
                        />
                        <Box sx={{display: 'flex', gap: '5px'}}>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('PACKAGE_SHIPPING_OPTIONS.BATTERY_TEXT')}
                            </Typography>
                            <Typography style={{
                                fontSize: '14px',
                                textAlign: 'left',
                                fontWeight: '600',
                            }}>
                                <Link
                                    href='https://uucargo.ca/support/shipment-of-items-containing-lithium-batteries-with-uucargo/'
                                    underline='none'
                                    target='_blank'
                                    color='#1D8B45'
                                >
                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.MORE')}
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                {
                    isBattery &&
                    <>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                            }}>
                                <Typography>
                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.BATTERY_TYPE')}
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    gap: '10px',
                                    flexDirection: isMobile && 'column',
                                    alignItems: 'flex-start'
                                }}>
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox
                                            checked={batteryDetails?.material === 'LITHIUM_ION'}
                                            onChange={() => handleBatteryType('LITHIUM_ION')}
                                            color="success"
                                            icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                            checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        />}
                                        label={
                                            <Typography>
                                                {intl.get('PACKAGE_SHIPPING_OPTIONS.LITHIUM_ION')}
                                            </Typography>
                                        }
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox
                                            checked={batteryDetails?.material === 'LITHIUM_METAL'}
                                            onChange={() => handleBatteryType('LITHIUM_METAL')}
                                            color="success"
                                            icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                            checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        />}
                                        label={
                                            <Typography>
                                                {intl.get('PACKAGE_SHIPPING_OPTIONS.LITHIUM_METAL')}
                                            </Typography>
                                        }
                                        labelPlacement="end"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                            }}>
                                <Typography>
                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.PACKING_TYPE')}
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    gap: '10px',
                                    flexDirection: isMobile && 'column',
                                    alignItems: 'flex-start'
                                }}>
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox
                                            checked={batteryDetails?.packing === 'CONTAINED_IN_EQUIPMENT'}
                                            onChange={() => handleBatteryPackaged('CONTAINED_IN_EQUIPMENT')}
                                            color="success"
                                            icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                            checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        />}
                                        label={
                                            <Typography style={{textAlign: 'left'}}>
                                                {intl.get('PACKAGE_SHIPPING_OPTIONS.CONTAINED_IN_EQUIPMENT')}
                                            </Typography>
                                        }
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox
                                            checked={batteryDetails?.packing === 'PACKED_WITH_EQUIPMENT'}
                                            onChange={() => handleBatteryPackaged('PACKED_WITH_EQUIPMENT')}
                                            color="success"
                                            icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                            checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                        />}
                                        label={
                                            <Typography style={{textAlign: 'left'}}>
                                                {intl.get('PACKAGE_SHIPPING_OPTIONS.PACKED_WITH_EQUIPMENT')}
                                            </Typography>
                                        }
                                        labelPlacement="end"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                            }}>
                                <Typography>
                                    {intl.get('PACKAGE_SHIPPING_OPTIONS.BATTERY_QUANTITY')}
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    gap: '10px',
                                    flexDirection: isMobile && 'column',
                                    alignItems: 'flex-start'
                                }}>
                                    <TextField
                                        value={batteryDetails?.quantity}
                                        size='small'
                                        onInput={handleBatteryQuantity}
                                        type='number'
                                        InputProps={{
                                            inputProps: {
                                                min: 1
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    )
}