import Box from "@mui/material/Box";
import RoomIcon from "@material-ui/icons/Room";
import IconButton from "@mui/material/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import {movingShippingStatus} from "../../utils/Helper";

export const TransportationClaimTasksMapMarker = ({marker, selectTask, handleSelectTask}) => {

    const history = useHistory();

    const handleViewDetails = (id) => {
        history.push(`/transportation/claimTasks/details/${id}`);
    }
    return (
        <Box sx={{
            position: 'relative'
        }}>
            <IconButton onClick={() => handleSelectTask(marker)}>
                <RoomIcon style={{
                    color: marker?.order_id === selectTask?.order_id ? '#EF4040' : '#FB8B24',
                    fontSize: '30px',
                }}/>
            </IconButton>
            {
                marker?.order_id === selectTask?.order_id &&
                <Box sx={{
                    position: 'absolute',
                    borderRadius: '5px',
                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                    padding: '5px',
                    margin: '5px',
                    backgroundColor: '#FFFFFF',
                    width: '240px',
                    zIndex: '10'
                }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Typography style={{
                                fontSize: '14px',
                                textAlign: 'left',
                                fontWeight: '600',
                                color: '#1D8B45'
                            }}>
                                $ {selectTask?.price?.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                {selectTask?.pickup_address}, {selectTask?.pickup_area}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                {selectTask?.schedule_pickup ? dayjs(selectTask?.schedule_pickup).format("YYYY-MM-DD") : 'To be determined'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{
                                fontSize: '12px',
                                textAlign: 'left',
                                fontWeight: '600',
                                color: selectTask?.shipping_status_id === 2 ? '#FB8B24' :
                                    selectTask?.shipping_status_id === 20 ? '#F2BE22' :
                                        selectTask?.shipping_status_id === 25 ? '#1D8B45' : '#454545'
                            }}>
                                {movingShippingStatus(selectTask?.shipping_status_id)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '5px'
                            }}>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    size='small'
                                    onClick={() => handleViewDetails(selectTask?.order_id)}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        color: '#1D8B45',
                                        fontSize: '12px'
                                    }}>
                                        Details
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    )
}

