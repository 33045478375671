import React, {useEffect, useState} from "react";
import {
    Select,
    MenuItem,
    Switch,
    FormLabel,
    FormControlLabel,
    Button,
    Grid,
    Box,
    Divider,
    Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {orange, red, green, grey} from "@mui/material/colors";

import {useHistory} from "react-router-dom";

import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import intl from "react-intl-universal";

const theme = createTheme({
    palette: {
        primary: orange,
        danger: red,
        success: green,
        warning: red,
        secondary: grey,
    },
});

const MyScheduledPickupItem = ({
                                   index,
                                   accountBase,
                                   accountNumber,
                                   appointDate,
                                   appointId,
                                   appointTimeEnd,
                                   appointTimeStart,
                                   confirmAppointNo,
                                   createdAt,
                                   estimateVolume,
                                   estimateWeight,
                                   numberOfCount,
                                   ownerRole,
                                   partnerId,
                                   pickupAddr,
                                   pickupCity,
                                   pickupCountry,
                                   pickupEmail,
                                   pickupLabelUri,
                                   pickupName,
                                   pickupPostalCode,
                                   pickupProvince,
                                   pickupTel,
                                   status,
                                   trackingNos,
                                   updatedAt,
                                   volumeUnit,
                                   weightUnit,
                                   cancelPickupByIndex,
                                   trackingNumbers
                               }) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isCanceled, setIsCanceled] = useState(false);

    const scheduledPickupDate = new Date(appointDate);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isPickupDatePassed, setIsPickupDatePassed] = useState(false);

    const clearStatusMessages = () => {
        setStatusMessage("");
        setErrorMessage("");
    };

    // console.log('pickup date', appointDate);
    // console.log('current date', currentDate);

    const cancelPickup = async () => {
        console.log("confirmAppointNo:", confirmAppointNo);
        setIsLoading(true);
        clearStatusMessages();
        try {
            const storedToken = getAccessToken("access_token");
            const {data, status} = await axios({
                method: "delete",
                url: `${PARTNER_URI}/packagesPickupAppointment/cancelPickupAppointmentPartner`,
                params: {
                    appointmentNumber: confirmAppointNo,
                },
                headers: {
                    authorization: `Bearer ${storedToken}`,
                },
            });
            if (status === 200) {
                console.log("Success:", data);
                setStatusMessage("Successfully Cancelled Pickup");
                setIsCanceled(true)
            } else {
                console.log("Error:", data);
                throw new Error();
            }
        } catch (e) {
            setErrorMessage("Failed to Cancel Pickup. Try again another time.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Compare the two dates when the component mounts
        setIsPickupDatePassed(scheduledPickupDate < currentDate);
    }, [scheduledPickupDate, currentDate]);

    return (
        <ThemeProvider theme={theme}>
            <Box
                mb={1}
                sx={{
                    backgroundColor: "white",
                    p: 2,
                    borderStyle: "solid",
                    borderColor: "secondary.main",
                    borderWidth: 1,
                    borderRadius: 2,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        textAlign: "start",
                    }}
                >
                    <Typography
                        sx={{
                            color: "error.main",
                        }}
                    >
                        {errorMessage}
                    </Typography>
                    <Typography
                        sx={{
                            color: "success.main",
                        }}
                    >
                        {statusMessage}
                    </Typography>
                </Box>
                <Grid
                    container
                    sx={{
                        // backgroundColor: "orange",
                        width: "100%",
                        mb: 1,
                    }}
                >
                    <Grid item xs={3}>
                        <Box
                            display="flex"
                            flexDirection={"column"}
                            sx={{
                                textAlign: "start",
                            }}
                        >
                            <Typography color={"secondary.main"}>{intl.get('PICKUP_LIST.APPOINTMENT_DATE')}</Typography>
                            <Typography>{appointDate}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            display="flex"
                            flexDirection={"column"}
                            sx={{
                                textAlign: "start",
                            }}
                        >
                            <Typography color={"secondary.main"}>{intl.get('PICKUP_LIST.START')}</Typography>
                            <Typography>{appointTimeStart}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            display="flex"
                            flexDirection={"column"}
                            sx={{
                                textAlign: "start",
                            }}
                        >
                            <Typography color={"secondary.main"}>{intl.get('PICKUP_LIST.END')}</Typography>
                            <Typography>{appointTimeEnd}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box
                            // display="flex"
                            // flexDirection={"column"}
                            sx={{
                                textAlign: "end",
                            }}
                        >
                            {/* <Typography color={"secondary.main"}>Appointment </Typography> */}
                            <Typography variant="body2">
                                {intl.get('PICKUP_LIST.CONFIRMATION')}{confirmAppointNo}
                            </Typography>
                            {/*<Typography*/}
                            {/*  variant={"body2"}*/}
                            {/*  sx={{*/}
                            {/*    cursor: "pointer",*/}
                            {/*    color: "blue",*/}
                            {/*    "&:hover": {*/}
                            {/*      textDecoration: "underline",*/}
                            {/*    },*/}
                            {/*  }}*/}
                            {/*  onClick={() => {*/}
                            {/*    history.push(`#${pickupLabelUri}`);*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  Pickup Label*/}
                            {/*</Typography>*/}
                        </Box>
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        mb: 1,
                    }}
                />
                <Box
                    sx={{
                        textAlign: "start",
                    }}
                >
                    <Grid container>
                        <Grid item xs={10}>
                            {/* <Typography variant="h6">
              Appointment Details
            </Typography> */}
                            <Grid container>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography variant="h6">{intl.get('PICKUP_LIST.PICKUP_ADDRESS')}</Typography>
                                        <Typography variant={"body2"}>
                                            {pickupName || "N/A"}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            {pickupAddr || "N/A"} {pickupPostalCode || "N/A"}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            {pickupCity || "N/A"} {pickupProvince || "N/A"}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            {pickupProvince || "N/A"}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            {pickupCountry || "N/A"}
                                        </Typography>
                                        {/* <Typography variant={"body2"}>
                      {pickupEmail || "N/A"}
                    </Typography> */}
                                        {/* <Typography variant={"body2"}>{pickupLabelUri || "N/A"}</Typography> */}
                                        {/* <Typography variant={"body2"}>
                      {pickupTel || "N/A"}
                    </Typography> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography
                                            variant="h6">{intl.get('PICKUP_LIST.CARRIER')}: {accountBase}</Typography>
                                        <Typography
                                            variant={"body1"}>{intl.get('PICKUP_LIST.CONTACT_INFO')}:</Typography>
                                        <Typography variant={"body2"}>
                                            {intl.get('PICKUP_LIST.EMAIL')}: {pickupEmail || "N/A"}
                                        </Typography>
                                        <Typography variant={"body2"}>
                                            {intl.get('PICKUP_LIST.PHONE')}: {pickupTel || "N/A"}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography
                                            variant="h6">{intl.get('PICKUP_LIST.PICKUP_INFORMATION')}</Typography>
                                        <Typography variant={"body1"}>
                                            {intl.get('PICKUP_LIST.TRACKING_NUMBERS')}
                                            <br/>
                                            {trackingNumbers}
                                        </Typography>
                                        <Divider/>
                                        <Typography variant={"body1"}>
                                            {intl.get('PICKUP_LIST.ESTIMATED_VOLUME')}: {estimateVolume}
                                            {volumeUnit}
                                        </Typography>
                                        <Typography variant={"body1"}>
                                            {intl.get('PICKUP_LIST.ESTIMATED_WEIGHT')}: {estimateWeight}
                                            {weightUnit}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                mt: 2,
                                            }}
                                        >
                                            <Typography variant={"body1"}>{intl.get('PICKUP_LIST.STATUS')}:&nbsp;</Typography>
                                            <Typography
                                                variant={"body1"}
                                                sx={{
                                                    color: status === 1 ? "success.main" : "error.main",
                                                }}
                                            >
                                                {status === 1 ? intl.get('PICKUP_LIST.SUBMITTED') : intl.get('PICKUP_LIST.CANCELED')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* {accountBase}
              {accountNumber}
              {appointDate}
              {appointId}
              {appointTimeEnd}
              {appointTimeStart}
              {confirmAppointNo}
              {createdAt}
              {estimateVolume}
              {estimateWeight}
              {numberOfCount}
              {ownerRole}
              {partnerId}
              {pickupAddr}
              {pickupCity}
              {pickupCountry}
              {pickupEmail}
              {pickupLabelUri}
              {pickupName}
              {pickupPostalCode}
              {pickupProvince}
              {pickupTel}
              {status}
              {trackingNos}
              {updatedAt}
              {volumeUnit}
              {weightUnit} */}
                        </Grid>
                        <Grid item xs={2}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "end",
                                }}
                            >
                                {
                                    !isCanceled && status === 1 ?
                                        <LoadingButton
                                            loading={isLoading}
                                            onClick={cancelPickup}
                                            sx={{
                                                borderColor: "warning.main",
                                                borderStyle: "solid",
                                                borderWidth: 1,
                                                color: "warning.main",
                                                width: "clamp(3rem, 100px, 100%)",
                                                mb: 1,
                                            }}
                                            disabled={isPickupDatePassed}
                                        >
                                            {intl.get('PICKUP_LIST.CANCEL')}
                                        </LoadingButton> : undefined
                                }
                                {/*<Button*/}
                                {/*  sx={{*/}
                                {/*    borderColor: "primary.main",*/}
                                {/*    borderStyle: "solid",*/}
                                {/*    borderWidth: 1,*/}
                                {/*    color: "primary.main",*/}
                                {/*    width: "clamp(3rem, 100px, 100%)",*/}
                                {/*    // mb: 2,*/}
                                {/*  }}*/}
                                {/*>*/}
                                {/*  Something*/}
                                {/*</Button>*/}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default MyScheduledPickupItem;
