import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Accordion, AccordionDetails, AccordionSummary, DialogContentText, Snackbar} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from "@material-ui/core/Grid";
import {Pagination} from "@mui/lab";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {makeStyles} from "@material-ui/core/styles";
import DialogActions from "@mui/material/DialogActions";
import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import {useHistory} from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    mySpan: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '16px',
    },

});

const styles = {
    Button: {
        backgroundColor: '#FFFFFF',
        border: '1px solid rgba(50, 50, 50, .3)',
        color: '#000000',
        "&:hover": {
            backgroundColor: '#F5F5F5',
        }

    }
}

export const BrokerageEstimateItem = ({data, logo, brokerageData, fromPackingList=false}) => {

    const classes = useStyles();

    const history = useHistory();

    const [shipmentItems, setShipmentItems] = useState([]);

    const [open, setOpen] = useState(false);

    const [postOpen, setPostOpen] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickPostOpen = () => {
        setPostOpen(true);
    };

    const handlePostClose = () => {
        setPostOpen(false);
    };

    const shipmentsData = data1d => {
        const data2d = [];
        const chunkSize = 10;
        for (let i = 0; i < data1d?.length; i += chunkSize) {
            const chunk = data1d.slice(i, i + chunkSize);
            data2d.push(chunk)
        }
        // console.log(data2d)
        setShipmentItems(data2d)
    }

    useEffect(() => {
        data?.shipmentItems?.length > 0 && shipmentsData(data?.shipmentItems);
    }, [])

    const currencyFormat = (num) => {
        return '$' + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const splitString = (value) => {
        const parts = value.split("_");
        return parts[0];
    }

    const [page, setPage] = useState(1);

    const [itemsPerPage] = useState(10);

    // Logic to calculate the current items to display
    const indexOfLastItem = page * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data?.shipmentItems?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePlaceOrder = () => {

        const todayTimestamp = Date.now();
        const tomorrowTimestamp = todayTimestamp + 24 * 60 * 60 * 1000;
        const tomorrowDate = new Date(tomorrowTimestamp);
        const year = tomorrowDate.getFullYear();
        const month = String(tomorrowDate.getMonth() + 1).padStart(2, '0');
        const day = String(tomorrowDate.getDate()).padStart(2, '0');
        const tomorrowFormatted = `${year}-${month}-${day}`;

        let apiData = {
            shipperName: "",
            shipperPhone: "",
            shipperCompanyName: "",
            shipperAddress: "",
            shipperAddressType: "",
            shipperCity: "",
            shipperProvince: "",
            shipperPostalCode: "",
            shipperCountry: brokerageData?.exportCountryCode,
            consigneeName: "",
            consigneePhone: "",
            consigneeCompanyName: "",
            consigneeAddress: "",
            consigneeAddressType: "",
            consigneeCity: "",
            consigneeProvince: brokerageData?.importProvince,
            consigneePostalCode: "",
            consigneeCountry: brokerageData?.importCountryCode,
            shipmentItems: brokerageData?.shipmentItems,
            serviceName: data?.serviceName,
            shipmentType: brokerageData?.shipmentType,
            transModes: brokerageData?.transModes,
            shipDate: tomorrowFormatted
        }

        // console.log(apiData)
        handleClose();
        placeLandedCostBackendOrder(apiData)
    }

    const placeLandedCostBackendOrder = async (apiData) => {
        console.log('apiData', apiData)
        let requestURI = `${PARTNER_URI}/brokerage/placeLandedCostBackendOrder`;
        const storedToken = getAccessToken("access_token");
        try {
            await axios({
                method: 'post',
                url: requestURI,
                data: apiData,
                headers: {
                    authorization: `Bearer ${storedToken}`
                }
            })
            setToastOpen(true);
            setPostOpen(true);
            setErrorMessage('');
            setSuccessMessage('Successfully place order');

        } catch (e) {
            console.log(e.response.data.error)
            setToastOpen(true);
            setErrorMessage('Fail to place order');
        }
    }

    // console.log('brokerage Data', brokerageData)

    return (
        <> {data.serviceName !== 'PDT' &&
            <Box sx={{flexGrow: 1, backgroundColor: '#eeeeee', margin: '10px', borderRadius: '10px'}}>
                {
                    <Snackbar
                        anchorOrigin={{vertical: "top", horizontal: "center"}}
                        open={toastOpen}
                        onClose={handleToastClose}
                        autoHideDuration={6000}
                        message="Submit Transaction"
                    >
                        {(() => {
                            if (errorMessage !== "") {
                                return (
                                    <Alert
                                        onClose={handleToastClose}
                                        severity="error"
                                        sx={{width: "100%"}}
                                    >
                                        Error!
                                        <hr/>
                                        {errorMessage}
                                    </Alert>
                                );
                            }
                            return (
                                <Alert
                                    onClose={handleToastClose}
                                    severity="success"
                                    sx={{width: "100%"}}
                                >
                                    {successMessage}
                                </Alert>
                            );
                        })()}
                    </Snackbar>
                }
                <Grid container spacing={1}>
                    <Grid item xs={2} alignItems="center">
                        <Box sx={{height: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src={logo} width="70px" alt="image"/>
                        </Box>
                    </Grid>
                    <Grid item xs={7}>
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            <Box sx={{width: 1}}>
                                <Accordion sx={{width: 1}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        sx={{width: 1}}
                                    >
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: 1
                                        }}>
                                            <Typography sx={{margin: '0 10px'}}>
                                                Duty and Tax:
                                            </Typography>
                                            <Typography sx={{margin: '0 10px'}}>
                                                {data?.currency} {data?.totalDutyandTax !== undefined ? currencyFormat(data?.totalDutyandTax) : 'N/A'}
                                            </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {shipmentItems?.length > 0 && shipmentItems[page - 1]?.map(value => {
                                            const lineDescription = fromPackingList ? `Line: ${+value.index + 2}, ${value.description}, ${value.hsCode}` : `${value.description}`;

                                            return <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                                width: 1,
                                                marginBottom: '10px',
                                                borderBottom: '1px solid #000000'
                                            }}>
                                                <Box>
                                                    <Typography sx={{fontStyle: 'italic'}}>
                                                        {/*{splitString(value?.commodityId)}*/}
                                                        {lineDescription}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    width: 1,
                                                    gap: '50px'
                                                }}>
                                                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                                                        Total Commodity Taxes And Fees
                                                    </Typography>
                                                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                                                        {data?.shipmentItems && currencyFormat(value?.totalCommodityTaxesAndFees)}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    width: 1,
                                                    gap: '50px'
                                                }}>
                                                    <Typography sx={{fontSize: '14px'}}>
                                                        Commodity Duty
                                                    </Typography>
                                                    <Typography sx={{fontSize: '14px'}}>
                                                        {data?.shipmentItems && currencyFormat(value?.commodityDuty)}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        })}
                                        <Pagination
                                            count={Math.ceil(data?.shipmentItems?.length / itemsPerPage)}
                                            page={page}
                                            onChange={(event, value) => setPage(value)}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                            <Box sx={{marginTop: '10px', width: 1}}>
                                <Accordion sx={{width: 1}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                    >
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: 1
                                        }}>
                                            <Typography variant="body1" sx={{margin: '0 10px'}}>
                                                Brokerage:
                                            </Typography>
                                            <Typography variant="body1" sx={{margin: '0 10px'}}>
                                                {data?.currency} {data?.totalBrokerageFees !== undefined ? currencyFormat(data?.totalBrokerageFees) : 'N/A'}
                                            </Typography>
                                        </Box>
                                    < /AccordionSummary>
                                    <AccordionDetails>
                                        {data?.surcharges?.map(value => {
                                            return <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                width: 1,
                                                gap: '50px'
                                            }}>
                                                <Typography sx={{fontSize: '14px'}}>
                                                    {value?.chargeName}
                                                </Typography>
                                                <Typography sx={{fontSize: '14px'}}>
                                                    {data?.totalBrokerageFees && currencyFormat(value?.chargeAmount)}
                                                </Typography>
                                            </Box>
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                            <Box>
                                <Typography variant="body1" sx={{margin: '30px 20px', color: 'red'}}>
                                    {data?.message && data.message}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        {data?.error === null &&
                            <Box sx={{height: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Button variant="contained" onClick={handleClickOpen}>
                                    Send Request
                                </Button>
                            </Box>
                        }
                    </Grid>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '200px'}}>
                    </Box>
                </Grid>
                <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        {"Send Request"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This process will send your request. Are you sure to continue?
                        </DialogContentText>
                        <hr/>
                        <DialogContentText>
                            Click <span className={classes.mySpan}>Confirm</span> to send an request
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={styles.Button}
                            onClick={handleClose}
                        >
                            No
                        </Button>
                        <Button
                            sx={styles.Button}
                            onClick={handlePlaceOrder}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={postOpen}
                    onClose={handlePostClose}
                >
                    <DialogTitle>
                        {"Send Request"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Thank you! We already received your request, one of our operator would contact you soon.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={styles.Button}
                            onClick={handlePostClose}
                        >
                            Got it
                        </Button>
                        <Button
                            sx={styles.Button}
                            onClick={() => {
                                history.replace("/home");
                            }}
                        >
                            Go to home page
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        }
        </>
    )
}