import React, {useState, useEffect, useCallback} from "react";
import {
    TextField,
    Box,
    Button,
    Divider,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Snackbar,

} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import LoadingContainer from "../../shared/loadingContainer";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE, PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import {ordinal_suffix_of} from "../../utils/addOrdinalSuffix";
import CurrencyFormat from "react-currency-format";
import LoadingButton from "@mui/lab/LoadingButton";
import {Alert} from "@material-ui/lab";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import {mapLTLAdditionalService} from "../../utils/Helper";

const useQuery = () => {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ShippingLTLDetailsPage = () => {
    const classes = useStyles();
    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const query = useQuery();
    const orderId = query.get("orderId");

    const [orderInfo, setOrderInfo] = useState(null);
    const [bolInfo, setBolInfo] = useState(null);
    const [backendPackages, setBackendPackages] = useState([]);
    const [backendPrice, setBackendPrice] = useState(null);

    const [specialServices, setSpecialServices] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const [cancelToastOpen, setCancelToastOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
    const handleCancelToastClick = () => {
        setCancelToastOpen(true);
    };

    const handleCancelToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setCancelToastOpen(false);
    };

    const handleCancelDialogOpen = () => {
        setCancelDialogOpen(true);
    };
    const handleCancelOrder = async (e) => {
        handleCancelDialogOpen();
    }

    const handleCancelDialogClose = () => {
        setCancelDialogOpen(false);
    };

    const confirmCancelOrder = async (e) => {
        e.preventDefault();
        try {
            setCancelOrderLoading(true);
            const {data} = await axios.delete(`${PARTNER_URI}/shippingLTL/cancelLTLOrderByOrderIdAndSenderId?orderId=${orderId}`, {
                headers: {
                    Authorization: `Bearer ${getAccessToken("access_token")}`
                }
            });
            console.log(data);
            setErrorMessage("");
            handleCancelToastClick();
            handleCancelDialogClose();
        } catch (e) {
            setErrorMessage(e?.response?.data?.errorMessage || "Error!");
            console.log(e);
        } finally {
            setCancelOrderLoading(false);
        }
    }

    useEffect(() => {
        if (orderId) {
            (async () => {
                try {
                    setIsLoading(true);
                    const storedToken = getAccessToken("access_token");
                    const {data} = await axios.get(`${PARTNER_URI}/shippingLTL/getLTLOrderDetailsByOrderId?orderId=${orderId}`, {
                        headers: {
                            Authorization: `Bearer ${storedToken}`,
                        }
                    });

                    const serviceList = data?.bolInfo?.pickup_instruction
                    // console.log('special service list', serviceList);

                    if (serviceList) {
                        const services = serviceList.split('\n');
                        // console.log('remark note', remarkNote);
                        const specialServices = services?.map(element => {
                            return {serviceCode: element}
                        })
                        // console.log('special service', specialServices);
                        setSpecialServices(specialServices);
                    }

                    setOrderInfo(data);
                    setBackendPackages(data.packages);
                    setBackendPrice(data.prices);
                    setBolInfo(data.bolInfo);

                } catch (e) {
                    console.log(e);
                    setErrorMessage(e?.message || "Oops, We are encountering some issue now, please refresh page");
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [orderId]);

    if (isLoading) {
        return (
            <Box width="100%">
                <LoadingContainer/>
            </Box>
        )
    }

    console.log('order info', orderInfo);
    console.log('special service', specialServices);

    return (
        <Box width="100%">
            <Dialog
                open={cancelDialogOpen}
                onClose={handleCancelDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cancel LTL Request
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will cancel your LTL Shipping Request. Are you sure to continue?
                        <hr/>
                        Click <b>Confirm</b> to cancel your order
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <Button onClick={confirmCancelOrder} variant="outlined" type="button" disabled={cancelOrderLoading}>
                        {cancelOrderLoading && <CircularProgress size={14}/>}
                        {!cancelOrderLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Pop-up Toast For Cancel */}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={cancelToastOpen}
                onClose={handleCancelToastClose}
                autoHideDuration={3000}
                message="Cancel Order">
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert onClose={handleCancelToastClose} severity="error" sx={{width: '100%'}}>
                                Cancel Request Failed!
                                <hr/>
                                Error: {errorMessage}
                            </Alert>
                        )
                    }
                    return (
                        <Alert onClose={handleCancelToastClose} severity="success" sx={{width: '100%'}}>
                            Cancel Request Success!
                        </Alert>
                    )
                })()}
            </Snackbar>
            <Box
                className={classes.rootContainer}
                width="100%"
            >
                <h2 className="orange-title">Shipping LTL Summary</h2>
                <Box width="100%" display="flex" justifyContent="start" flexDirection="column" sx={{gap: "1rem"}}>
                    <Box>
                        <h2>Order #: {orderInfo.order_number}</h2>
                    </Box>
                    <Box width="100%" display="flex" justifyContent="start" flexDirection="column" sx={{gap: "0.7rem"}}>
                        <Box display="flex" justifyContent="start">
                            <Box className={classes.summaryHeader} fontWeight="700">Shipper</Box>
                        </Box>
                        <Box className={classes.summaryBodyText} mb={2}>
                            Name: {bolInfo?.shipper_name || "N/A"}
                            <br/>
                            Phone number: {bolInfo?.shipper_phone || "N/A"}
                            <br/>
                            {bolInfo?.shipper_address || "N/A"} <br/>
                            {bolInfo?.shipper_city || "N/A"}, {bolInfo?.shipper_province || "N/A"}, {bolInfo?.shipper_country || "N/A"}, {bolInfo?.shipper_postal_code || "N/A"}
                            <br/>
                        </Box>
                    </Box>
                    <Box width="100%" display="flex" justifyContent="start" flexDirection="column" sx={{gap: "0.7rem"}}>
                        <Box display="flex" justifyContent="start">
                            <Box className={classes.summaryHeader} fontWeight="700">Consignee</Box>
                        </Box>
                        <Box className={classes.summaryBodyText} mb={2}>
                            Name: {bolInfo?.consignee_name || "N/A"}
                            <br/>
                            Phone number: {bolInfo?.consignee_phone || "N/A"}
                            <br/>
                            {bolInfo?.consignee_address || "N/A"} <br/>
                            {bolInfo?.consignee_city || "N/A"}, {bolInfo?.consignee_province || "N/A"}, {bolInfo?.consignee_country || "N/A"}, {bolInfo?.consignee_postal_code || "N/A"}
                            <br/>
                        </Box>
                    </Box>
                    <Box width="100%" display="flex" justifyContent="start" flexDirection="column" sx={{gap: "0.7rem"}}>
                        <Box display="flex" justifyContent="start">
                            <Box className={classes.summaryHeader} fontWeight="700">Ship To</Box>
                        </Box>
                        <Box className={classes.summaryBodyText} mb={2}>
                            Name: {bolInfo?.ship_to_name || "N/A"}
                            <br/>
                            Phone number: {bolInfo?.ship_to_phone || "N/A"}
                            <br/>
                            {bolInfo?.ship_to_address || "N/A"} <br/>
                            {bolInfo?.ship_to_city || "N/A"}, {bolInfo?.ship_to_province || "N/A"}, {bolInfo?.ship_to_country || "N/A"}, {bolInfo?.ship_to_postal_code || "N/A"}
                            <br/>
                        </Box>
                    </Box>
                    <Box width="100%" display="flex" justifyContent="start" flexDirection="column" sx={{gap: "0.7rem"}}>
                        <Box display="flex" justifyContent="start">
                            <Box className={classes.summaryHeader} fontWeight="700">Sold To</Box>
                        </Box>
                        <Box className={classes.summaryBodyText} mb={2}>
                            Name: {bolInfo?.sold_to_name || "N/A"}
                            <br/>
                            Phone number: {bolInfo?.sold_to_phone || "N/A"}
                            <br/>
                            {bolInfo?.sold_to_address || "N/A"} <br/>
                            {bolInfo?.sold_to_city || "N/A"}, {bolInfo?.sold_to_province || "N/A"}, {bolInfo?.sold_to_country || "N/A"}, {bolInfo?.sold_to_postal_code || "N/A"}
                            <br/>
                        </Box>
                    </Box>
                </Box>
                <Divider/>
                <div className="subtitle">
                    <Box display="flex" justifyContent="space-between">
                        <Box className={classes.summaryHeader}>Items</Box>
                    </Box>
                    <Box className={classes.summaryBodyText} mb={1}>
                        {backendPackages.map((item, key) => {
                            return (
                                <div key={key}>
                                    <p>
                                        <Box component="span" fontWeight="700">
                                            {ordinal_suffix_of(key + 1)} Package:
                                        </Box>{" "}
                                        <Box component="span" fontWeight="400">
                                            {item?.item_description || "N/A"} - {item.length}&times;{item.width}&times;{item.height} ({item.dimension_unit})
                                            - {item.weight} ({item.weight_unit})
                                            <br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>&times; {item.number}</b>
                                        </Box>
                                    </p>
                                </div>
                            );
                        })}
                    </Box>
                </div>
                <Divider/>
                <Box sx={{display: 'flex', gap: '20px', flexDirection: 'column'}}>
                    <Box className={classes.summaryHeader} fontWeight="700">
                        Service Provider
                    </Box>
                    <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                        {orderInfo?.account_base_combine_name === "dayandross" ? "Day & Ross" : orderInfo?.account_base_combine_name?.toUpperCase()}
                    </Typography>
                </Box>
                <Divider/>
                {specialServices?.length > 0 &&
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                        <Box className={classes.summaryHeader} fontWeight="700">
                            Special Services
                        </Box>
                        <Box sx={{display: 'flex', gap: '40px', flexWrap: 'wrap'}}>
                            {
                                specialServices?.map((each, index) => {
                                    return (
                                        <Typography key={index} style={{fontSize: '18px', fontWeight: '600'}}>
                                            {mapLTLAdditionalService(each.serviceCode)}
                                        </Typography>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                }
                <Divider/>
                <div>
                    <Box display="flex" justifyContent="space-between">
                        <Box className={classes.summaryHeader} fontWeight="700">Estimate Shipping Fee</Box>
                    </Box>
                    <Box sx={{marginTop: '20px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '20px'}}>
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '20px'}}>
                                    <CurrencyFormat value={(+backendPrice?.total).toFixed(2)} displayType={'text'}
                                                    thousandSeparator={true} prefix={`C$ `}/>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '20px'}}>
                                    Tax
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '20px'}}>
                                    <CurrencyFormat value={(+backendPrice?.taxes).toFixed(2)} displayType={'text'}
                                                    thousandSeparator={true} prefix={`C$ `}/>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '20px'}}>
                                    Final Total
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '20px'}}>
                                    <CurrencyFormat value={(+backendPrice?.final_total).toFixed(2)} displayType={'text'}
                                                    thousandSeparator={true} prefix={`C$ `}/>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                <Divider/>
                <Box sx={{display: ' flex', gap: '10px', flexDirection: 'column'}}>
                    <Box display="flex" justifyContent="start">
                        <Box className={classes.summaryHeader} fontWeight="700">Bol Information</Box>
                    </Box>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Typography style={{fontSize: '20px'}}>
                            Bol #
                        </Typography>
                        <Typography style={{fontSize: '20px'}}>
                            {orderInfo?.bolInfo?.bol_no}
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Typography style={{fontSize: '20px'}}>
                            Label
                        </Typography>
                        <a href={`${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${orderInfo?.bolInfo?.bol_doc_uri}`}
                           target="_blank"
                           rel="noopener noreferrer">
                            <Typography style={{fontSize: '20px'}}>
                                {orderInfo?.bolInfo?.bol_doc_uri ? "Download" : ""}
                            </Typography>
                        </a>
                    </Box>
                </Box>
                <Box flex="45%" width="100%">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Schedule Pickup Date"
                            value={orderInfo.scheduled_pickup_date}
                            disabled
                            renderInput={(params) => <TextField {...params} id="outlined-basic"
                                                                label="Schedule Pickup Date (Optional) mm-dd-yyyyy"
                                                                variant="outlined" fullWidth/>}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            <Divider/>
            {orderInfo?.account_base_combine_name === "j-link" &&
                <Box
                    display="flex"
                    justifyContent="center"
                    mt={2}
                    mb={1}
                    alignItems="flex-center"
                >
                    <>
                        <Box pt={1} display="flex" sx={{gap: "2rem"}}>
                            <Button
                                color="primary"
                                variant="contained"
                                className={matches ? classes.smButton : classes.button}
                                onClick={handleCancelOrder}
                                style={{
                                    textTransform: "unset",
                                    fontSize: "1rem",
                                    height: "3rem",
                                }}
                            >Cancel Order</Button>
                        </Box>
                    </>
                </Box>
            }
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    rootContainer: {
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: "0 auto",
        width: "40vw",
        minWidth: "1300px",
        paddingLeft: "2vw",
        paddingRight: "2vw",
        textAlign: "left",
        gap: "1rem"
    },
    smRootContainer: {
        width: "100%",
        paddingLeft: "3vw",
        paddingRight: "3vw",
        textAlign: "left"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    button: {
        borderRadius: 5,
        fontSize: "1.2rem",
        backgroundColor: "#ffa726",
        width: "14rem",
    },
    smButton: {
        borderRadius: 5,
        fontSize: "1rem",
        backgroundColor: "#ffa726",
        width: "12rem",
    },
    textfield: {
        width: "100%",
        backgroundColor: "white",
        // border: "1px solid white",
    },
    dockerFee: {
        width: "4rem",
    },
    feeWrapper: {
        // border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: 5,
        marginBottom: theme.spacing(2),
        // backgroundColor: "#FEEAAE",
        backgroundColor: "white",
    },

    infoWrapper: {
        // border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: 5,
        marginBottom: theme.spacing(2),
        backgroundColor: "#FEEAAE",
    },
    summaryHeader: {
        fontSize: "16pt",
        color: "rgb(141, 173, 155)",
        fontFamily: "Montserrat",
        // borderBottom: '1px, solid rgb(141, 173, 155)',
    },
    // summaryBodyText: {
    //   fontSize: "13pt",
    //   color: "seagreen",
    //   fontFamily: "Montserrat",
    // },
    summaryBodyText: {
        fontSize: "1.1rem",
        color: "rgba(0,0,0,0.8)",
        fontFamily: "Montserrat",
        fontWeight: "400",
    },
    textButton: {
        color: "blue",
    },
    bodyText: {
        fontSize: "1rem",
        color: "seagreen",
        fontFamily: "Montserrat",
    },
    dialogTitle: {
        padding: "1vh 1vw 0.5vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "rgb(141, 173, 155)",
    },
    timePicker: {
        width: 160,
    },
    divider: {
        borderBottom: "2px solid rgba(0,0,0,0.3)",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    radioWrapper: {
        border: "1px solid red",
        paddingLeft: theme.spacing(1),
        borderRadius: "5px",
    },
    table: {
        width: "100%",
        // "border-spacing": "2rem 1rem"
    },
    th: {
        fontSize: "18px"
    },
    idTH: {
        fontSize: "16px"
    },
}));

export default ShippingLTLDetailsPage;
