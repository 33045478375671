import React, {useEffect, useMemo, useRef, useState} from "react";
import Card from "@material-ui/core/Card";
import {Box, CardActions, CardContent, CardHeader, CircularProgress, IconButton, Tooltip} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import CurrencyFormat from "react-currency-format";
import {useSelector} from "react-redux";
import HelpIcon from "@mui/icons-material/Help";
import LoadingContainer from "../../shared/loadingContainer";
import dayjs from "dayjs";
import {getAccessToken} from "../../utils/doToken";
import {Link} from "react-router-dom";
import {CSVLink} from "react-csv";
import {separateTaxes} from "../../utils/calculateTax";
import {saveAs} from 'file-saver';
import {Button} from "@mui/material";

export default function OrderSummaryTab({classes, orderId, setIsCrossBorder}) {

    const {firstname, id} = useSelector((state) => state.user);
    const [orderData, setOrderData] = useState({});
    const [invoiceRef, setInvoiceRef] = useState("N/A");
    const [isLoading, setIsLoading] = useState(true);
    // const [transactions, setTransactions] = useState([
    //     ["Order ID", "Cargo Control Number", "Ref Order No", "Tran ID", "Account Base", "Client Number", "Product ID", "Exclude Tax", "Include Tax", "GST", "HST", "QST", "Date", "Tracking #", "Labels"],
    // ]);
    const [transactions, setTransactions] = useState([
        [
            "Order ID",
            "Cargo Control Number",
            "Ref Order No",
            "Tran ID",
            "Account Base",
            "Client Number",
            "Product ID",
            "Exclude Tax",
            "Include Tax",
            "GST",
            "HST",
            "QST",
            "Total Brokerage Charge",
            "Total Duties Charge",
            "Total Duties Tax Charge",
            "Service Fee Exclude Tax",
            "Service Fee Include Tax",
            "Shipping Total Tax",

            "Freight Charge",
            "SurCharge",
            "Fuel Charge",
            "Collect Charge",
            "Residential Charge",
            "Rural Charge",
            "Extended Area Charge",
            "Extra Care Charge",
            "Handling Charge",
            "Over Size Charge",
            "Over Weight Charge",
            "COD Charge",
            "COS Charge",
            "Dangerous Charge",
            "Surcharge Details",
            "Date",
            "Tracking #",
            "Labels",

            "Send From Name",
            // "Send From Company",
            "Send From Address",
            "Send From City",
            "Send From Province",
            "Send From Country",
            "Send From Postal Code",

            "Send To Name",
            // "Send To Company",
            "Send To Phone",
            "Send To Email",
            "Send To Address",
            "Send To City",
            "Send To Province",
            "Send To Country",
            "Send To Postal Code"
        ],
    ]);
    const [trackings, setTrackings] = useState([
        // ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Order Total Amount", "Transaction Amount", "Currency", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
        ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
    ])

    const csvLink = useRef();
    const csvTrackingLink = useRef();
    const getTransactionData = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getTransactionsByOrderId?partner_id=${id}&order_id=${orderId}&account_base=${orderData.account_base}`);
            const result = data.result;
            const resultArray = [
                [
                    "Order ID",
                    "Cargo Control Number",
                    "Ref Order No",
                    "Tran ID",
                    "Account Base",
                    "Client Number",
                    "Product ID",
                    "Exclude Tax",
                    "Include Tax",
                    "GST",
                    "HST",
                    "QST",
                    "Total Brokerage Charge",
                    "Total Duties Charge",
                    "Total Duties Tax Charge",
                    "Service Fee Exclude Tax",
                    "Service Fee Include Tax",
                    "Shipping Total Tax",

                    "Freight Charge",
                    "SurCharge",
                    "Fuel Charge",
                    "Collect Charge",
                    "Residential Charge",
                    "Rural Charge",
                    "Extended Area Charge",
                    "Extra Care Charge",
                    "Handling Charge",
                    "Over Size Charge",
                    "Over Weight Charge",
                    "COD Charge",
                    "COS Charge",
                    "Dangerous Charge",
                    "Surcharge Details",
                    "Date",
                    "Tracking #",
                    "Labels",

                    "Send From Name",
                    // "Send From Company",
                    "Send From Address",
                    "Send From City",
                    "Send From Province",
                    "Send From Country",
                    "Send From Postal Code",

                    "Send To Name",
                    // "Send To Company",
                    "Send To Phone",
                    "Send To Email",
                    "Send To Address",
                    "Send To City",
                    "Send To Province",
                    "Send To Country",
                    "Send To Postal Code"
                ],
            ];
            for (const column of result) {
                const columnData = [];
                columnData.push(column.order_id);
                columnData.push(column.cargo_control_no);
                columnData.push(column.ref_order_no);
                columnData.push(column.tran_id);
                columnData.push(column.account_base);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                // Swap Price
                columnData.push(column.amount_gross);
                columnData.push(column.amount);

                const taxesObject = separateTaxes({
                    excludeTaxAmount: column.amount_gross,
                    includeTaxAmount: column.amount
                })
                // // GST
                // columnData.push(taxesObject.gstTax);
                // // PST
                // columnData.push(taxesObject.pstTax);
                // columnData.push(taxesObject["GST/HST"]);
                columnData.push(taxesObject.gstTax);
                columnData.push(taxesObject.hstTax);
                columnData.push(taxesObject.qstTax);

                columnData.push(column.total_brokerage_charge);
                columnData.push(column.total_duties_charge);
                columnData.push(column.total_duties_taxes_charge);
                columnData.push(column.service_fee_exclude_tax);
                columnData.push(column.service_fee_include_gst);
                columnData.push(column.shipping_total_tax);

                columnData.push(column.freight_charge);
                columnData.push(column.sur_charge);
                columnData.push(column.fuel_surcharge);
                columnData.push(column.collect_charge);
                columnData.push(column.residential_charge);
                columnData.push(column.rural_charge);
                columnData.push(column.extended_area_charge);
                columnData.push(column.extra_care_charge);
                columnData.push(column.handling_charge);
                columnData.push(column.over_size_charge);
                columnData.push(column.over_weight_charge);
                columnData.push(column.cod_charge);
                columnData.push(column.cos_charge);
                columnData.push(column.dangerous_charge);
                columnData.push(column?.surcharge_details || "");

                columnData.push(column.tranacted_at);
                columnData.push(column.tran_tracking_nos);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");

                // Send From Info
                columnData.push(column.send_from_name);
                columnData.push(column.send_from_addr);
                columnData.push(column.send_from_city);
                columnData.push(column.send_from_province);
                columnData.push(column.send_from_country);
                columnData.push(column.send_from_postal_code);

                // Send To Info
                columnData.push(column.send_to_name);
                columnData.push(column.send_to_tel);
                columnData.push(column.send_to_email);
                columnData.push(column.send_to_addr);
                columnData.push(column.send_to_city);
                columnData.push(column.send_to_province);
                columnData.push(column.send_to_country);
                columnData.push(column.send_to_postal_code);

                resultArray.push(columnData);
            }
            setTransactions(resultArray);
            console.log(resultArray);
            csvLink.current.link.click()
        } catch (e) {
            console.log(e);
        }
    }

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const getTrackingsData = async () => {
        try {
            const { data } = await axios.post(`${PARTNER_URI}/shipping/list/order/trackings`, {
                partnerId: id,
                orderId: orderId,
            });
            const result = data.result;
            const resultArray = [
                ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
            ];
            // const resultArray = [];
            for (const column of result) {
                const columnData = [];
                columnData.push(orderId);
                columnData.push(orderData.ref_order_no);
                columnData.push(orderData.cargo_control_no);
                columnData.push(orderData.account_base);
                columnData.push(column.tracking_no);
                // columnData.push(column.total_amount);
                // columnData.push(column.amount);
                // columnData.push(column.currency_code);
                columnData.push(column.tran_id);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");
                columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
                columnData.push(column.tranacted_at);

                resultArray.push(columnData);
            }
            // setTrackings(v => {
            //     const temp = [...v];
            //     temp.push(...resultArray);
            //     return temp;
            // });
            setTrackings(resultArray);
            console.log(resultArray);
            csvTrackingLink.current.link.click()
        } catch (e) {
            console.log(e);
        }
    }

    const getCRMInvoiceInfoById = async ({invoiceId}) => {
        try {
            const storedToken = getAccessToken("access_token");
            const {data} = await axios.get(`${PARTNER_URI}/billingCenter/getInvoiceInfoByInvoiceId?invoiceId=${invoiceId}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    const getOrderData = async ({orderId}) => {
        try {
            setIsLoading(true);
            const requestURI = `${PARTNER_URI}/shipping/list/order?partner_id=${id}&order_id=${orderId}`;
            const {data} = await axios.get(requestURI);
            if (data?.result && data?.result?.length > 0) {
                const resOrderData = data.result[0];
                console.log("orderData: ", resOrderData);
                const isCrossBorder = resOrderData?.cross_border_shipment_type !== null;
                setIsCrossBorder(isCrossBorder);
                setOrderData(resOrderData);

                const crmInvoiceNumber = resOrderData.crm_invoice_no;
                if (crmInvoiceNumber) {
                    const crmInfo = await getCRMInvoiceInfoById({
                        invoiceId: crmInvoiceNumber
                    });
                    const resCRMRefNumber = crmInfo.invoiceRefNumber;
                    setInvoiceRef(resCRMRefNumber);
                }

            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const downloadLabelIntoZip = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getLabelZipByOrderId?partner_id=${id}&order_id=${orderId}`, {
                responseType: "blob"
            });
            const blobData = new Blob([data], {
                type: "application/zip"
            });
            saveAs(blobData, `${id}-${orderId}.zip`);
            // console.log("labels: ", labels);
            // saveZip("my_project_files_to_download.zip", labels);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getOrderData({orderId});
    }, [orderId]);

    if (isLoading) {
        return (
            <Card className={classes.card}>
                <CardHeader
                    // title={`Hello ${firstname}, here is your billing summary`}
                    title={
                        <>
                            Order Dashboard
                        </>
                    }
                    className={classes.cardHeaderStylePref}
                    titleStyle={{textAlign: "left"}}
                />
                <CircularProgress/>
            </Card>
        )
    }

    return (
        <>
            <Card className={classes.card}>
                {/*<CardHeader*/}
                {/*    // title={`Hello ${firstname}, here is your billing summary`}*/}
                {/*    title={*/}
                {/*      <>*/}
                {/*        Order Dashboard*/}
                {/*      </>*/}
                {/*    }*/}
                {/*    className={classes.cardHeaderStylePref}*/}
                {/*    titleStyle={{ textAlign: "left" }}*/}
                {/*/>*/}
                <CardContent className={classes.cardRoot}>
                    <Grid container spacing={4} columns={{xs: 4, sm: 8, md: 12}}>
                        <Grid item xs={3} sm={3} md={3}>
                            <Typography variant="h6" fontWeight="bold">{"Order # / Billed to Invoice #"}</Typography>
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <Typography
                                    style={{wordWrap: "break-word"}}
                                    variant={{subtitle1: "h5"}}
                                >
                                    {orderId} / <Link
                                    to={`/billing-center`}>
                                    {invoiceRef}
                                </Link>
                                </Typography>
                                <Typography
                                    style={{wordWrap: "break-word"}}
                                    variant={{subtitle1: "h5"}}
                                >
                                    {dayjs(orderData.created_at).format("YYYY-MM-DD HH:mm")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <Typography variant="h6"
                                        fontWeight="bold">{"Ref Order # / Cargo Control # / Courier"}</Typography>
                            <Typography
                                style={{wordWrap: "break-word"}}
                                variant={{subtitle1: "h5"}}
                            >
                                {orderData?.ref_order_no || "N/A"} / {orderData?.cargo_control_no || "N/A"} / {orderData.account_base}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <Typography variant="h6" fontWeight="bold">{"Total Charge Include Tax"}</Typography>
                            <Typography
                                style={{wordWrap: "break-word"}}
                                variant={{subtitle1: "h5"}}
                            >
                                <CurrencyFormat
                                    value={(Math.abs((orderData.total_amount_include_tax + orderData.service_fee_include_gst) || 0).toFixed(2))}
                                    displayType={'text'} thousandSeparator={true}
                                    prefix={((orderData.total_amount_include_tax + orderData.service_fee_include_gst) || 0) >= 0 ? `${orderData.currency_code} $` : `${orderData.currency_code} $-`}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <Typography variant="h6" fontWeight="bold">{"Sender Info"}</Typography>
                            <Typography
                                style={{wordWrap: "break-word"}}
                                variant={{subtitle1: "h5"}}
                            >
                                {`${orderData.send_from_name ? orderData.send_from_name : "N/A"} / ${orderData.send_from_email ? orderData.send_from_email : "N/A"} / ${orderData.send_from_tel ? orderData.send_from_tel : "N/A"}`}
                                <br/>
                                {`${orderData.send_from_addr ? orderData.send_from_addr : "N/A"} / ${orderData.send_from_city ? orderData.send_from_city : "N/A"} / ${orderData.send_from_province ? orderData.send_from_province : "N/A"} / ${orderData.send_from_postal_code ? orderData.send_from_postal_code : "N/A"}`}
                            </Typography>
                        </Grid>
                        {/*<Grid item xs={3} sm={3} md={3}>*/}
                        {/*  <Typography variant="h6" fontWeight="bold">{"Sender Addr."}</Typography>*/}
                        {/*  <Typography*/}
                        {/*      style={{ wordWrap: "break-word" }}*/}
                        {/*      variant={{ subtitle1: "h5" }}*/}
                        {/*  >*/}
                        {/*    {`${orderData.send_from_addr ? orderData.send_from_addr : "N/A"} / ${orderData.send_from_city ? orderData.send_from_city : "N/A"} / ${orderData.send_from_province ? orderData.send_from_province : "N/A"} / ${orderData.send_from_postal_code ? orderData.send_from_postal_code : "N/A"}`}*/}
                        {/*  </Typography>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={3} sm={3} md={3}>*/}
                        {/*    <Typography variant="h6" fontWeight="bold">{"Billed to Invoice #"}</Typography>*/}
                        {/*    <Typography*/}
                        {/*        style={{wordWrap: "break-word"}}*/}
                        {/*        variant={{subtitle1: "h5"}}*/}
                        {/*    >*/}
                        {/*        <Link*/}
                        {/*            to={`/billing-center`}>*/}
                        {/*            {invoiceRef}*/}
                        {/*        </Link>*/}
                        {/*    </Typography>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={3} sm={3} md={3}>*/}
                        {/*  <Typography variant="h6" fontWeight="bold">{"Create Date"}</Typography>*/}
                        {/*  <Typography*/}
                        {/*      style={{ wordWrap: "break-word" }}*/}
                        {/*      variant={{ subtitle1: "h5" }}*/}
                        {/*  >*/}
                        {/*    {dayjs(orderData.created_at).format("YYYY-MM-DD HH:mm")}*/}
                        {/*  </Typography>*/}
                        {/*</Grid>*/}
                    </Grid>
                    <br/>
                    <br/>
                    <Grid container spacing={4} columns={{xs: 4, sm: 8, md: 12}}>
                        <Grid item xs={3} sm={3} md={3}>
                            <Typography variant="h6" fontWeight="bold">{"Transactions"}</Typography>
                            <>
                                <Button variant="contained" onClick={getTransactionData}
                                        sx={{width: "150px"}}>Download</Button>
                                <CSVLink
                                    data={transactions}
                                    filename={`${orderId}-${orderData.ref_order_no}-transactions.csv`}
                                    className='hidden'
                                    ref={csvLink}
                                    target='_blank'
                                />
                            </>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <Typography variant="h6" fontWeight="bold">{"Trackings"}</Typography>
                            <>
                                <Button variant="contained" onClick={getTrackingsData}
                                        sx={{width: "150px"}}>Download</Button>
                                <CSVLink
                                    data={trackings}
                                    filename={`${orderId}-${orderData.ref_order_no}-trackings.csv`}
                                    className='hidden'
                                    ref={csvTrackingLink}
                                    target='_blank'
                                />
                            </>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <Typography variant="h6" fontWeight="bold">{"Labels"}</Typography>
                            <Button variant="contained" onClick={downloadLabelIntoZip}
                                    sx={{width: "150px"}}>Download</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
