import React, {useRef, useState} from 'react';
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox, Select
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import ShippingListTrackingTable from "./ShippingListTrackingTable";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import { PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX } from "../../utils/apiUrl";
import { useSelector } from "react-redux";
import NavBar from "../navBar";
import InputLabel from "@material-ui/core/InputLabel";
import {MenuItem} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem",
    textAlign: "left"
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "80%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const ShippingListTracking = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const pageSize = 100;


  // const partnerInfo = getPartnerInfo();
  const partnerInfo = useSelector((state) => state.user);
  console.log(partnerInfo);

  const { register, handleSubmit, control } = useForm();
  const [shippingListData, setShippingListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectUPS, setSelectUPS] = useState(false);
  const [selectFedEx, setSelectFedEx] = useState(false);
  const cacheInput = useRef({});
  const [carrier, setCarrier] = useState("ALL");

  const getData = async ({ order_id, product_id, ref_other_no, cargo_control_no, send_to_name, send_to_tel, send_to_addr, currentPage, carrier }) => {
      let requestURI = `${PARTNER_URI}/shipping/list?&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;

      // if ((selectUPS && selectFedEx) || (!selectUPS && !selectFedEx)) {
      //   requestURI = `${PARTNER_URI}/shipping/list?&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;
      // } else if (selectUPS) {
      //   requestURI = `${PARTNER_URI}/shipping/list?account_base=ups&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;
      // } else if (selectFedEx) {
      //   requestURI = `${PARTNER_URI}/shipping/list?account_base=fedex&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;;
      // }
      if (carrier === "ALL") {
        requestURI = `${PARTNER_URI}/shipping/list?&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;
      } else {
        requestURI = `${PARTNER_URI}/shipping/list?account_base=${carrier}&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`;
      }

      const { data } = await axios.get(requestURI);
      const result = data.result;
      const resultTotalNumber = data.totalNumber;
      setTotalNumber(resultTotalNumber);
      setShippingListData(result);
      try {
      } catch (e) {
        setTotalNumber(0);
        console.log(e);
        setShippingListData([]);
      } finally {
        setIsLoading(false);
      }
  }

  const onSubmit = data => {
    // console.log(data);
    const { order_id, product_id , ref_other_no, cargo_control_no, send_to_name, send_to_addr, send_to_tel } = data;
    cacheInput.current = {
      ...data,
      carrier
    };
    setPage(1);
    getData({ order_id, product_id, ref_other_no, currentPage: 1, cargo_control_no, send_to_name, send_to_addr, send_to_tel, carrier });
  }
  const handlePageChange = (e, value) => {
    if (value !== page) {
      console.log(value);
      setPage(value);
      const { order_id, product_id , ref_other_no, cargo_control_no, send_to_name, send_to_addr, send_to_tel, carrier } = cacheInput.current;
      getData({ order_id, product_id, ref_other_no, currentPage: value, cargo_control_no, send_to_name, send_to_addr, send_to_tel, carrier });
    }
  }

  return (
      <>

        <Box>
          <Box component="form" width={"1000px"} margin="0 auto" sx={{
            display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
            alignItems: "center", alignContent: "center"
          }}>
            <h1>Shipping Order Details</h1>
            <Box display="flex" flexWrap="wrap" sx={{ gap: "2rem" }}>
              <Box flex="25" width="100%">
                <TextField id="outlined-basic" label="Order ID" variant="outlined" {...register("order_id")} fullWidth/>
              </Box>
              <Box flex="25%" width="100%">
                <TextField id="outlined-basic" label="Product ID" variant="outlined" {...register("product_id")} fullWidth />
              </Box>
              <Box flex="25%" width="100%">
                <TextField id="outlined-basic" label="Ref Other Number" variant="outlined" {...register("ref_other_no")} fullWidth />
              </Box>
              <Box flex="25%" width="100%">
                <TextField id="outlined-basic" label="Cargo Control Number" variant="outlined" {...register("cargo_control_no")} fullWidth />
              </Box>
              <Box flex="30%" width="100%">
                <TextField id="outlined-basic" label="Send To Name" variant="outlined" {...register("send_to_name")} fullWidth />
              </Box>
              <Box flex="30%" width="100%">
                <TextField id="outlined-basic" label="Send To Phone" variant="outlined" {...register("send_to_tel")} fullWidth />
              </Box>
              <Box flex="30%" width="100%">
                <TextField id="outlined-basic" label="Send To Address" variant="outlined" {...register("send_to_addr")} fullWidth />
              </Box>
              {/*  <Box flex="45%" width="100%">*/}
              {/*  <FormControl variant="outlined" fullWidth>*/}
              {/*    <InputLabel id="demo-simple-select-filled-label">Carrier</InputLabel>*/}
              {/*    <Select*/}
              {/*        labelId="demo-simple-select-label"*/}
              {/*        id="demo-simple-select"*/}
              {/*        value={carrier}*/}
              {/*        label="Age"*/}
              {/*        onChange={(e) => {*/}
              {/*          setCarrier(e.target.value);*/}
              {/*        }}*/}
              {/*    >*/}
              {/*      <MenuItem value={"ALL"}>ALL</MenuItem>*/}
              {/*      <MenuItem value={"UPS"}>UPS</MenuItem>*/}
              {/*      <MenuItem value={"CANPAR"}>CANPAR</MenuItem>*/}
              {/*      <MenuItem value={"FEDEX"}>FEDEX</MenuItem>*/}
              {/*    </Select>*/}
              {/*  </FormControl>*/}
              {/*</Box>*/}
            </Box>

            {/*<Box mt={2} mb={4}>*/}
            {/*  <FormControl>*/}
            {/*    <FormLabel color="primary">Account Base</FormLabel>*/}
            {/*    /!*<Controller control={control} defaultValue="UPS" name="account_base" rules={{ required: true }} render={({ field }) => (*!/*/}
            {/*    /!*    <RadioGroup*!/*/}
            {/*    /!*        row*!/*/}
            {/*    /!*        aria-labelledby="demo-row-radio-buttons-group-label"*!/*/}
            {/*    /!*        name="row-radio-buttons-group"*!/*/}
            {/*    /!*        defaultValue="UPS"*!/*/}
            {/*    /!*        {...field}*!/*/}
            {/*    /!*    >*!/*/}
            {/*    /!*      <FormControlLabel value="FedEx" control={<Radio />} label="FedEx" />*!/*/}
            {/*    /!*      <FormControlLabel value="UPS" control={<Radio />} label="UPS" />*!/*/}
            {/*    /!*    </RadioGroup>*!/*/}
            {/*    /!*)}/>*!/*/}
            {/*    <Box display="flex" flexDirection="row" sx={{ gap: "2rem" }} justifyContent="center">*/}
            {/*      <FormControlLabel control={<Checkbox />} checked={selectFedEx} onChange={() => {*/}
            {/*        setSelectFedEx(v => !v);*/}
            {/*      }} label="FedEx" value="FedEx" />*/}
            {/*      <FormControlLabel control={<Checkbox />} checked={selectUPS} onChange={() => {*/}
            {/*        setSelectUPS(v => !v);*/}
            {/*      }} label="UPS" value="UPS" />*/}
            {/*    </Box>*/}

            {/*  </FormControl>*/}
            {/*</Box>*/}
            <Box>
              <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  style={{
                    textTransform: "unset",
                    fontSize: "1rem",
                    width: "7rem",
                  }}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Box>
          </Box>

          <br />
          {
            isLoading ? (
                <>
                <Box>
                  <h1>Loading...</h1>
                </Box>
                </>
            ) : (
                <>
                  <Box>
                    <h2>Found {totalNumber} results</h2>
                    <hr width="100%" />
                    <Box>
                      <Box mt={2} display="flex" justifyContent="center">
                        <table className={classes.table}>
                          <thead>
                          <tr className={classes.listItemWrapper}>
                            <th className={classes.idTH}>Transaction ID</th>
                            <th className={classes.idTH}>Order ID</th>
                            {/*<th className={classes.nameTH}>Ref Order Number</th>*/}
                            {/*<th className={classes.nameTH}>Ref Product ID</th>*/}
                            {/*<th className={classes.nameTH}>Ref Other Number</th>*/}
                            <th className={classes.nameTH}>Ref #</th>
                            <th className={classes.nameTH}>Send To</th>
                            {/*<th className={classes.nameTH}>Send To Name/Email/Tel</th>*/}
                            {/*<th className={classes.nameTH}>Send To Address/City/Province/Country/Postal Code</th>*/}
                            {/*<th className={classes.nameTH}>Account Base</th>*/}
                            {/*<th className={classes.nameTH}>Exclude Tax</th>*/}
                            {/*<th className={classes.nameTH}>Include Tax</th>*/}
                            <th className={classes.nameTH}>Price</th>
                            <th className={classes.nameTH}>Carrier / Tracking #</th>
                            {/*<th className={classes.nameTH}>Labels</th>*/}
                            {/*<th className={classes.nameTH}>ZIP Label URI</th>*/}
                            {/*<th className={classes.nameTH}>Label PDF URI</th>*/}
                            <th className={classes.nameTH}>Date</th>
                            <th className={classes.nameTH}>Status</th>
                          </tr>
                          </thead>
                          <tbody className={classes.tbody}>
                          {shippingListData.map((v, index) => {
                            return <ShippingListTrackingTable key={index}
                                                              account_base={v.account_base}
                                                              date={v.tranacted_at}
                                                              gross_price={v?.amount_gross}
                                                              net_price={v?.amount}
                                                              shipping_fee_exclude_tax={v?.shipping_fee_exclude_tax}
                                                              service_fee_exclude_tax={v?.service_fee_exclude_tax}
                                                              shipping_fee_include_tax={v?.shipping_fee_include_tax}
                                                              service_fee_include_gst={v?.service_fee_include_gst}
                                                              order_id={v?.order_id}
                                                              ref_product_no={v.ref_product_no}
                                                              ref_other_no={v?.ref_other_no}
                                                              ref_order_no={v?.ref_order_no}
                                                              tran_id={v.tran_id}
                                                              label_pdf_uri={v?.label_pdf_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_pdf_uri}` : ""}
                                                              zip_label_uri={v?.label_zip_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_zip_uri}` : ""}
                                                              send_to_addr={v.send_to_addr}
                                                              send_to_city={v.send_to_city}
                                                              send_to_province={v.send_to_province}
                                                              send_to_country={v.send_to_country}
                                                              send_to_postal_code={v.send_to_postal_code}
                                                              send_to_name={v.send_to_name}
                                                              send_to_tel={v.send_to_tel}
                                                              send_to_email={v.send_to_email}
                                                              currency_code={v?.currency_code || "CAD"}
                                                              transactionNumbers={v.tran_tracking_nos}
                                                              tran_status={v.tran_status}
                                                              shipping_id_no={v.shipping_id_no}
                            />;
                          })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                    <br />
                    <Box margin="auto" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                      <Pagination count={Math.floor(totalNumber / pageSize) + 1}
                                  page={page}
                                  onChange={handlePageChange}
                                  className={classes.pagination}
                                  variant="outlined"
                                  shape="rounded" />
                    </Box>
                  </Box>
                </>
            )
          }
        </Box>
      </>
  );
};

export default ShippingListTracking;
