import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {InputAdornment, Link, MenuItem, Select} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {makeStyles, createStyles} from "@material-ui/core/styles";
import {useState} from "react";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import LoadingButton from "@mui/lab/LoadingButton";
import TrackPackageItem from "../Tracking/TrackPackageItem";
import CircularProgress from "@mui/material/CircularProgress";
import intl from "react-intl-universal";
import FormControl from "@mui/material/FormControl";
import {TrackingLTLEach} from "../Tracking/TrackingLTLEach";
import trackingDelivered from "../../images/tracking_delivered.png";
import trackingInTransit from "../../images/tracking_in_transit.png";
import trackingReady from "../../images/tracking_ready.png";

const styles = {
    TrackingRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    TrackingContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    endAdornment: {
        paddingRight: 1
    },
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
});

export const Tracking = () => {

    const classes = useStyles();

    const storedToken = getAccessToken("access_token");

    const [type, setType] = useState('package');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [trackingData, setTrackingData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isTrackEmpty, setIsTrackEmpty] = useState(false);
    const [trackedPackagesList, setTrackedPackagesList] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorCode, setErrorCode] = useState(200);

    const handleChange = (event) => {
        console.log(event.target.value);
        setType(event.target.value);
        setError(false);
        setErrorMessage('');
        setTrackingData(null);
        setIsTrackEmpty(false);
        setTrackedPackagesList([]);
        setErrorCode(200);
    };

    const getTrackedPackageList = async () => {
        setIsLoading(true);
        try {
            if (trackingNumber?.trim() === "") {
                return;
            }
            const {data, status} = await axios({
                method: "get",
                url: `${PARTNER_URI}/loose-item/3rd-party/multipleTrackingForWeb?trackingNumbers=${trackingNumber}`,
                headers: {
                    Authorization: `Bearer ${storedToken}`
                }
            });
            const trackingList = data?.filter(v => v?.trackingResponse !== null);
            setIsTrackEmpty(trackingList?.length === 0);
            setTrackedPackagesList(trackingList);
            setError(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
        } finally {
            setIsLoading(false);
        }
    }

    const getTrackingInformation = async () => {
        setIsLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTLTracking/tracking`;
        const storedToken = getAccessToken("access_token");
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    // trackingNumber: 'VAN6337994'
                    trackingNumber: trackingNumber
                }
            })
            console.log('result', result);
            setTrackingData(result?.data);
            setError(false);
        } catch (e) {
            console.log('error', e.response);
            setError(true);
            setErrorCode(e?.response?.status);
            if (e?.response?.status === 401) {
                setErrorMessage(e?.response?.data?.error);
            } else {
                setErrorMessage(e?.response?.data?.error);
            }
        } finally {
            setIsLoading(false);
        }
    }

    const handleTrackingNumber = (event) => {
        setTrackingNumber(event.target.value);
    }

    const handleTrackingNumberByKeyDown = (event) => {
        if (event.key === 'Enter') {
            // setOrderData([]);
            getTrackedPackageList();
        }
    }

    const handleTacking = () => {
        if (type === 'package') {
            getTrackedPackageList();
        } else {
            getTrackingInformation();
        }
    }

    return (
        <Box sx={styles.TrackingRoot}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography style={{fontSize: '16px', fontWeight: 600, textAlign: 'left'}}>
                    {intl.get('HOME_PAGE.TRACKING.TRACKING')}
                </Typography>
                <Link
                    href={type === 'package' ? '/tracking' : '/tracking-ltl'}
                    underline='none'>
                    <Typography style={{
                        fontSize: '12px', fontWeight: 600, textAlign: 'right', color: '#1D8B45',
                    }}>
                        {intl.get('HOME_PAGE.TRACKING.BATCH_TRACKING')}
                    </Typography>
                </Link>
            </Box>
            <Box sx={styles.TrackingContent}>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item md={9} xs={8}>
                                <TextField
                                    value={trackingNumber}
                                    fullWidth
                                    size='small'
                                    onInput={handleTrackingNumber}
                                    onKeyDown={handleTrackingNumberByKeyDown}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    classes={{root: classes.customTextField}}
                                    placeholder={type === 'package' ? intl.get('HOME_PAGE.TRACKING.TRACKING_PLACEHOLDER') : intl.get('HOME_PAGE.TRACKING.TRACKING_PLACEHOLDER_BOL')}
                                />
                            </Grid>
                            <Grid item md={3} xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            height: '100%',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        // size='small'
                                        onClick={handleTacking}
                                        disabled={!trackingNumber}
                                        loading={isLoading}
                                    >
                                        <Typography
                                            style={{textTransform: 'none', fontSize: '14px'}}
                                        >
                                            {intl.get('HOME_PAGE.TRACKING.TRACK')}
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <FormControl fullWidth>
                            <Select
                                value={type}
                                size='small'
                                placeholder='Status'
                                onChange={handleChange}
                            >
                                <MenuItem disabled value='type' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>
                                    <em>{intl.get('HOME_PAGE.SHIPPING_ORDER.TYPE')}</em>
                                </MenuItem>
                                <MenuItem value='package' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>{intl.get('HOME_PAGE.SHIPPING_ORDER.PACKAGE')}</MenuItem>
                                <MenuItem value='ltl' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>{intl.get('HOME_PAGE.SHIPPING_ORDER.LTL')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    type === 'package' && (isLoading ?
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        error ?
                            <Box sx={styles.ShippingOrderContent}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.ERROR')}
                                </Typography>
                            </Box>
                            :
                            isTrackEmpty ?
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.TRACKING.NO_RESULTS')}
                                </Typography> : trackedPackagesList?.map((trackedPackage, index) => {
                                    return (
                                        <TrackPackageItem key={index}
                                                          activity={trackedPackage.trackingResponse ? trackedPackage.trackingResponse.activity : undefined}
                                                          trackingNo={trackedPackage.trackingNumber}
                                                          courier={trackedPackage.accountBase}
                                                          deliverStatus={trackedPackage.trackingResponse.status}
                                                          transaction={trackedPackage.transaction}
                                        />
                                    )
                                }))
                }
                {
                    type === 'ltl' && (isLoading ?
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress/>
                            </Box>
                            :
                            errorCode === 400 ?
                                <Box sx={styles.ShippingOrderContent}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        {errorMessage}
                                    </Typography>
                                </Box> :
                                errorCode === 400 ?
                                    <Box sx={styles.ShippingOrderContent}>
                                        <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                            {errorMessage}
                                        </Typography>
                                    </Box> :
                                    errorCode === 401 ? <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                padding: '10px',
                                                gap: '20px'
                                            }}>
                                                <img src={trackingReady} alt="tacking_logo" width={60}/>
                                                <Box>
                                                    <Typography
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                            textAlign: 'left'
                                                        }}>
                                                        Ready for Shipping
                                                    </Typography>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        Your pallet has passed all checks and is awaiting pickup
                                                        by the courier.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                                {errorMessage}
                                            </Typography>
                                        </Grid> :
                                        !trackingData?.trackingResponse ? <></>
                                            :
                                            <Grid container spacing={2}>
                                                {
                                                    trackingData?.trackingResponse?.statusCode === 2 ? <Grid item xs={12}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-start',
                                                                padding: '10px',
                                                                gap: '20px'
                                                            }}>
                                                                <img src={trackingDelivered} alt="tacking_logo" width={60}/>
                                                                <Box>
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: '16px',
                                                                            fontWeight: '600',
                                                                            textAlign: 'left'
                                                                        }}>
                                                                        Delivered
                                                                    </Typography>
                                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                                        Your pallet has been successfully delivered at the
                                                                        destination.
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        : trackingData?.trackingResponse?.statusCode === 1 ?
                                                            <Grid item xs={12}>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'flex-start',
                                                                    padding: '10px',
                                                                    gap: '20px'
                                                                }}>
                                                                    <img src={trackingInTransit} alt="tacking_logo" width={60}/>
                                                                    <Box>
                                                                        <Typography
                                                                            style={{
                                                                                fontSize: '16px',
                                                                                fontWeight: '600',
                                                                                textAlign: 'left'
                                                                            }}>
                                                                            In Transit
                                                                        </Typography>
                                                                        <Typography
                                                                            style={{fontSize: '14px', textAlign: 'left'}}>
                                                                            Your pallet is currently on its way to the
                                                                            destination.
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                            :
                                                            <Grid item xs={12}>
                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'flex-start',
                                                                    padding: '10px',
                                                                    gap: '20px'
                                                                }}>
                                                                    <img src={trackingReady} alt="tacking_logo" width={60}/>
                                                                    <Box>
                                                                        <Typography
                                                                            style={{
                                                                                fontSize: '16px',
                                                                                fontWeight: '600',
                                                                                textAlign: 'left'
                                                                            }}>
                                                                            Ready for Shipping
                                                                        </Typography>
                                                                        <Typography
                                                                            style={{fontSize: '14px', textAlign: 'left'}}>
                                                                            Your pallet has passed all checks and is awaiting
                                                                            pickup by the courier.
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                }
                                                <TrackingLTLEach trackingData={trackingData}/>
                                            </Grid>
                    )
                }
            </Box>
        </Box>
    )
}