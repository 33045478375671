import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
// import ServicePickUp from './servicePickup';
// import ServiceDelivery from './serviceDelivery';
import {Button, useMediaQuery, MobileStepper, Snackbar} from "@material-ui/core";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import "../../styles/shipping.css";
// import PropTypes from 'prop-types';
import produce from "immer";
// import { provinces, amzFulfillCenterList } from "../shared";
import AddressInputTab from "./addressInputTab";
import ShippingItem from "./shippingItemTab";
import FreightClass from "./freightClass";
import QuoteSummary from "./quoteSummary";
import ReviewAndConfirm from "./reviewAndConfirm";
import LooseItemRates from "./looseItemRates";
import {provinces} from "../../shared/constInfo";
import {defaultPickUpDetails} from "../../slices/shippingSlice";
import {amzFulfillCenterList} from "../../shared/amazonFulfillmentCenter";
import {useSelector} from "react-redux";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";

const initUserLocation = {
    address: "",
    city: "",
    zipCode: "",
    province: "",
    country: "",
    aptInfo: "",
};

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

const isAlphaString = (str) => {
    const regexString = /[^a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/;
    console.log(str, str.match(regexString));
    return str.match(regexString) === null;
}

const getEmptyStatus = (_formatData) => {
    const {pickUp, deliver, accountBaseCombinedName} = _formatData;
    const pickUpEmpty =
        !pickUp.name || pickUp.name?.trim() === "" ||
        // !pickUp.name || pickUp.name?.trim() === "" || pickUp.name?.trim().length <= 2 ||
        !pickUp.phone || pickUp.phone?.trim() === "" || pickUp.phone?.trim().length < 10 || pickUp.phone?.trim().length > 14 ||
        !pickUp.address || pickUp.address?.trim() === "" ||
        !pickUp.city || pickUp.city?.trim() === "" ||
        !pickUp.province || pickUp.province?.trim() === "" ||
        !pickUp.country || pickUp.country?.trim() === "";

    const deliverEmpty =
        !deliver.name || deliver.name?.trim() === "" ||
        // !deliver.name || deliver.name?.trim() === "" || deliver.name?.trim()?.length <= 2 ||
        !deliver.phone || deliver.phone?.trim() === "" || deliver.phone?.trim().length < 10 || deliver.phone?.trim().length > 14 ||
        !deliver.address || deliver.address?.trim() === "" ||
        !deliver.city || deliver.city?.trim() === "" ||
        !deliver.province || deliver.province?.trim() === "" ||
        !deliver.country || deliver.country?.trim() === ""

    const accountBasedCombinedNameEmpty = accountBaseCombinedName === "";

    const hasInvalidCharacter = !(isAlphaString(pickUp.address) && isAlphaString(pickUp.city) && isAlphaString(pickUp.province) && isAlphaString(pickUp.zipCode)
        && isAlphaString(deliver.address) && isAlphaString(deliver.city) && isAlphaString(deliver.province) && isAlphaString(deliver.zipCode));

    return [pickUpEmpty, deliverEmpty, accountBasedCombinedNameEmpty, hasInvalidCharacter];
};

const StartQuote = () => {
    const {tab} = useParams();
    const history = useHistory();
    const {id: partnerId, email} = useSelector((state) => state.user);

    const loaded = React.useRef(false);
    // const initOrderData = useSelector((state) => state.orders.initOrderData);
    // const { lat, lng } = useSelector((state) => state.user.latlng);
    // const { addressBook } = useSelector((state) => state.user);
    const googleApiKey = process.env.REACT_APP_API_KEY;

    if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`,
                document.querySelector("head"),
                "google-maps"
            );
        }

        loaded.current = true;
    }

    // const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    // const [selectedDate, setSelectedDate] = useState(new Date());

    // Form data to store all the information
    const [formatData, setFormatData] = useState(defaultPickUpDetails);
    const [shippingItems, setShippingItems] = useState([]);
    const [item, setItemInfo] = useState(null);

    const [userLocation, setUserLocation] = useState(initUserLocation);
    const [deliverLocation, setDeliverLocation] = useState(initUserLocation);
    const [dropOffLocation, setDropOffLocation] = useState({
        type: "store",
        address: "",
        zipCode: "",
        city: "",
        province: "",
    })

    const [accountBaseCombinedName, setAccountBaseCombinedName] = useState("");
    const [cargoControlNumber, setCargoControlNumber] = useState("");
    const [refOrderNumber, setRefOrderNumber] = useState("");
    const [isWindowOpen, setWindowOpenState] = useState(false);
    const [currentField, setCurrentField] = useState("pickUp");

    const [value, setValue] = useState(null);

    const [checkError, setCheckError] = useState(false);
    const [nextErrorMessage, setNextErrorMessage] = useState("");
    const [distance, setDistance] = useState("0");
    const [emptyItemList, setEmtpyItemList] = useState(false);
    // const [details, setDetails] = useState('');
    const [isLooseItem, setIsLooseItemState] = useState(true);
    const [selectedLooseItem, setSelectedLooseItem] = useState(false);
    const [palletContainerLoose, setPalletContainerLoose] = useState("All");
    const [companyName, setCompanyName] = useState("");
    const [serviceName, setServiceName] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [priceText, setPriceText] = useState("");
    const [priceInfo, setPriceInfo] = useState({});
    const classes = useStyles();
    const [toastOpen, setToastOpen] = useState(false);
    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const [showErroMsg, setMsgStatus] = useState(false);
    const [amazonFba, setAmazonFba] = useState(false);

    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        if (tab === "shipping-item" && activeStep !== 0) setActiveStep(0);
        if (tab === "start-a-quote" && activeStep !== 1) setActiveStep(1);
        if (tab === "rate-services" && activeStep !== 2) setActiveStep(2);
        if (tab === "review-confirm" && activeStep !== 3) setActiveStep(3);
    }, [tab, activeStep]);

    useEffect(() => {
        console.log("Send From", userLocation);
        console.log("Account Base:", accountBaseCombinedName);
        console.log("Send To", deliverLocation);
    }, [deliverLocation, userLocation, accountBaseCombinedName])

    const handleNext = () => {
        if (activeStep === 0) {
            if (shippingItems.length !== 0) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                // handleSeventhPage();
                history.push("/shipping/start-a-quote");
            } else setEmtpyItemList(true);
        }
        if (activeStep === 1) next(false);
        // if (activeStep === 2) {
        //   handleSeventhPage();
        // }
    };

    const handleBack = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep - 1);
        // if (activeStep === 1) {
        //   handleFirstPage();
        // }
        //  if (activeStep === 2 && selectedLooseItem) {
        //   handleFifthPage();
        // } else {
        //   handleFourthPage();
        // }
        if (activeStep === 3 && selectedLooseItem) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            handleFifthPage();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
            handleFourthPage();
        }
        if (activeStep === 1) handleFirstPage();
    };

    /*Function that handles the rendering of different components, Can be implmented as tabbed design for later*/
    const handleSelection = () => {
        if (tab === "start-a-quote") {
            return (
                <AddressInputTab
                    formatData={formatData}
                    setFormatData={setFormatData}
                    setUserLocation={setUserLocation}
                    setDeliverLocation={setDeliverLocation}
                    userLocation={userLocation}
                    deliverLocation={deliverLocation}
                    checkError={checkError}
                    // setShippingItems={setShippingItems}
                    setItemInfo={setItemInfo}
                    next={next}
                    showErroMsg={showErroMsg}
                    setMsgStatus={setMsgStatus}
                    amazonFba={amazonFba}
                    setAmazonFba={setAmazonFba}
                />
            );
        }
        if (tab === "shipping-item") {
            return (
                <ShippingItem
                    backToThird={handleFirstPage}
                    toSeventh={handleSeventhPage}
                    shippingItems={shippingItems}
                    setShippingItems={setShippingItems}
                    itemInfo={item}
                    setEmtpyItemList={setEmtpyItemList}
                    selectedLooseItem={selectedLooseItem}
                    setSelectedLooseItem={setSelectedLooseItem}
                    palletContainerLoose={palletContainerLoose}
                    setPalletContainerLoose={setPalletContainerLoose}
                />
            );
        }
        if (tab === "freight-class") {
            return (
                // <Fade duration={2000}>
                <FreightClass
                    backToFourth={handleFourthPage}
                    toSixth={handleSeventhPage}
                    shippingItems={shippingItems}
                />
            );
        }
        if (tab === "quote-summary") {
            return (
                <QuoteSummary
                    toFourth={handleFourthPage}
                    toFirst={handleFirstPage}
                    toSeventh={handleSeventhPage}
                />
            );
        }
        if (tab === "review-confirm") {
            return (
                <ReviewAndConfirm
                    toFirst={handleFirstPage}
                    toFourth={handleFourthPage}
                    toFifth={handleFifthPage}
                    toConfirm={handleConfirmPage}
                    pallets={shippingItems}
                    pickUpDetails={formatData}
                    distance={distance}
                    isLooseItem={isLooseItem}
                    selectedLooseItem={selectedLooseItem}
                    companyName={companyName}
                    serviceName={serviceName}
                    accountBaseCombinedName={accountBaseCombinedName}
                    priceText={priceText}
                    cargoControlNumber={cargoControlNumber}
                    refOrderNumber={refOrderNumber}
                    shippingItems={shippingItems}
                    serviceType={serviceType}
                    priceInfo={priceInfo}
                />
            );
        }
        if (tab === "service-rate") {
            return (
                <LooseItemRates
                    setCompanyName={setCompanyName}
                    setServiceName={setServiceName}
                    toSeventh={handleSeventhPage}
                    userLocation={userLocation}
                    deliverLocation={deliverLocation}
                    shippingItems={shippingItems}
                    cargoControlNumber={cargoControlNumber}
                    refOrderNumber={refOrderNumber}
                    accountBaseCombinedName={accountBaseCombinedName}
                    setAccountBaseCombinedName={setAccountBaseCombinedName}
                    setPriceText={setPriceText}
                    setFormatData={setFormatData}
                    setServiceType={setServiceType}
                    formatData={formatData}
                    setPriceInfo={setPriceInfo}
                />
            );
        }
        // if (tab === 'order-confirmed') {
        //   return(
        //       <OrderConfirm />
        //   )
        // }
    };

    const checkIfRefOrderNumberExist = async ({partnerId, refOrderNumber}) => {
        try {
            const {data} = await axios.post(`${PARTNER_URI}/shipping/list/isRefOrderNumberExist`, {
                partnerId: partnerId,
                refOrderNumber
            });
            return data?.isDuplicated || false;
        } catch (e) {
            console.log(e);
        }
    }

    //when the arrow button to the next page is pressed, replace the Redux pickUpDetails with the current textfiel values
    const next = async (skipCheckAmz = false) => {
        const [pickUpEmpty, deliverEmpty, accountBaseCombineNameEmpty, hasInvalidCharacter] = getEmptyStatus(formatData);

        if (formatData.refOrderNumber !== "") {
            const isDuplicated = await checkIfRefOrderNumberExist({
                partnerId: partnerId,
                refOrderNumber: formatData.refOrderNumber
            });
            if (isDuplicated) {
                setNextErrorMessage("The Reference Order Number has already existed! Please either remove it or change to new one.");
                handleToastClick();
                return;
            }
        }
        setNextErrorMessage("");
        if (hasInvalidCharacter) {
            setNextErrorMessage("The input contains invalid characters! Please make sure the address/postal code is alphanumeric!");
            handleToastClick();
            return;
        }

        if (pickUpEmpty || deliverEmpty || accountBaseCombineNameEmpty) {
            setCheckError(true);
            setMsgStatus(true);
        } else {
            setUserLocation({
                name: formatData.pickUp.name,
                address: formatData.pickUp.address,
                city: formatData.pickUp.city,
                zipCode: formatData.pickUp.zipCode,
                province: formatData.pickUp.province,
                phone: formatData.pickUp.phone,
                aptInfo: formatData.pickUp.aptInfo,
                country: formatData.pickUp.country
            });
            setDeliverLocation({
                name: formatData.deliver.name,
                address: formatData.deliver.address,
                city: formatData.deliver.city,
                zipCode: formatData.deliver.zipCode,
                province: formatData.deliver.province,
                phone: formatData.deliver.phone,
                aptInfo: formatData.deliver.aptInfo,
                country: formatData.deliver.country
            });
            // if (dropOffLocation.type === "store") {
            //   setDropOffLocation({
            //     address: formatData.pickUp.address,
            //     zipCode: formatData.pickUp.zipCode,
            //     city: formatData.pickUp.city,
            //     country: "CA",
            //     province: formatData.pickUp.province
            //   })
            // } else {
            //   setDropOffLocation({
            //     address: formatData.dropOff.address,
            //     zipCode: formatData.dropOff.zipCode,
            //     city: formatData.dropOff.city,
            //     country: "CA",
            //     province: formatData.dropOff.province
            //   })
            // }
            setAccountBaseCombinedName(formatData.accountBaseCombinedName);
            setRefOrderNumber(formatData.refOrderNumber);
            setCargoControlNumber(formatData.cargoControlNumber);

            const distanceService = new window.google.maps.DistanceMatrixService();
            distanceService.getDistanceMatrix(
                {
                    origins: [
                        `${formatData.pickUp.address}, ${formatData.pickUp.city}, ${formatData.pickUp.province}`,
                    ],
                    destinations: [
                        `${formatData.deliver.address}, ${formatData.deliver.city}, ${formatData.deliver.province}`,
                    ],
                    travelMode: "DRIVING",
                },
                (response, status) => {
                    if (status === "OK") {
                        setDistance(
                            response.rows[0].elements[0].distance.text.split(" ")[0]
                        );
                    }
                }
            );
            if (selectedLooseItem) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                handleFifthPage();
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 2);
                handleSeventhPage();
            }
        }
    };

    // }

    const handleFirstPage = () => {
        history.push("/shipping/shipping-item");
    };

    const handleFourthPage = () => {
        history.push("/shipping/start-a-quote");
    };

    const handleFifthPage = () => {
        history.push("/shipping/service-rate");
    };

    // const handleSixthPage= () => {
    //   history.push('/shipping/quote-summary');
    // }
    const handleSeventhPage = () => {
        history.push("/shipping/review-confirm");
    };

    const handleConfirmPage = (number, addressData, routeErr = false) => {
        history.push({
            pathname: `/order-confirmed/${number}`,
            state: {addressData: addressData, routeErr: routeErr},
        });
    };

    return (
        <div>
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={5000}
                message="Cancel Order">
                {(() => {
                    if (nextErrorMessage !== "") {
                        return (
                            <Alert onClose={handleToastClose} severity="error" sx={{width: '100%'}}>
                                Invalid Input!
                                <hr/>
                                Error: {nextErrorMessage}
                            </Alert>
                        )
                    }
                    return (
                        <Alert onClose={handleToastClose} severity="success" sx={{width: '100%'}}>
                            Saved!
                        </Alert>
                    )
                })()}
            </Snackbar>
            {/*Pop-up Toast*/}
            <br/>
            {handleSelection()}
            <Box pt="1vh" width="100%">
                {emptyItemList ? (
                    <Alert severity="error">Please add the item to the list.</Alert>
                ) : (
                    ""
                )}
            </Box>
            <Box className={classes.stepper}>
                <MobileStepper
                    variant="dots"
                    steps={3}
                    position="static"
                    activeStep={activeStep}
                    className={classes.root}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === 2}
                        >
                            Next
                            <KeyboardArrowRight/>
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                        >
                            <KeyboardArrowLeft/>
                            Back
                        </Button>
                    }
                />
            </Box>
        </div>
    );
};

// const theme = createMuiTheme({
//   palette: {
//     primary: { light: orange[300], main: orange[500], dark: orange[700] },
//   },
// });

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "20px",
        // padding: theme.spacing(2, 4, 3),
        overflow: "hidden",
        width: "100%",
        margin: "0 auto",
    },
    button: {
        borderRadius: 5,
        fontSize: "12pt",
    },
    input: {
        color: "grey",
        width: "90%",
    },
    largeIcon: {
        fontSize: "200%",
    },
    label: {
        flexDirection: "column",
    },
    icon: {
        fontSize: "32px !important",
        marginBottom: theme.spacing(1),
    },
    subTitle: {
        color: "darkgreen",
        fontWeight: 600,
        fontSize: "1.5rem",
        fontFamily: "Montserrat",
    },
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    dialogTitle: {
        padding: "1vh 1vw 0.5vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "rgb(141, 173, 155)",
    },
    // button: {
    //   fontSize: "12pt",
    //   backgroundColor: "#ffa726",
    //   color: "white",
    //   margin: "7px",
    // },
    fixWidthButton: {
        width: "8rem",
    },
    outLinedButton: {
        fontSize: "12pt",
        // margin: "7px",
        marginRight: "24px",
    },
    noteWrapper: {
        // border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: 5,
        // padding: theme.spacing(1),
        backgroundColor: "#FEEAAE",
    },
    divider: {
        borderBottom: "1px solid rgba(0,0,0,0.5)",
        marginLeft: "24px",
        marginRight: "24px",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addressItem: {
        "&:hover": {
            backgroundColor: "rgba(141, 173, 155,0.5)",
        },
    },
    stepper: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(1),
    },
    root: {
        maxWidth: 400,
        flexGrow: 1,
        width: "100% !important",
        margin: "0 !important",
        height: "4rem",
    },
}));

// export default connect(mapStateToProps, {addPickUpDetails})(StartQuote);
export default StartQuote;
