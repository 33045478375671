import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { PARTNER_URI } from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import {useSelector} from "react-redux";
import PickupAppointmentDetails from "./PickupAppointmentDetails";
import {IconButton, Tooltip} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import {useHistory} from "react-router-dom";

export default function PickupAppointmentDetailsCard({ classes, orderId }) {
  const [appointmentList, setAppointmentList] = useState([]);
  const { firstname, id } = useSelector((state) => state.user);
  let history = useHistory();

  const getTransactionListByOrderId = async () => {
    try {
      const requestURI = `${PARTNER_URI}/shipping/list?&order_id=${orderId}&partner_id=${id}`;
      const { data } = await axios.get(requestURI);
      const result = data.result;
      return result;
    } catch (e) {
      console.log(e);
    }
  }

  const getAppointmentList = async () => {
    try {
      const storedToken = getAccessToken("access_token");
      const { data } = await axios.get(`${PARTNER_URI}/packagesPickupAppointment/getPickupAppointmentListByOrderId`, {
        params: {
          orderId
            },
        headers: {
          authorization: `Bearer ${storedToken}`,
        },}
      );
      console.log("appointmentList", data);
      setAppointmentList(data);

    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getAppointmentList();
  }, []);

  return (
      <Grid item xs={6} md={6} lg={6} xl={6}>
        <Card
            className={classes.paper}
            style={{ minHeight: "55vh", boxShadow: "none" }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h6">
                {`Pickup Appointments`}
                <Tooltip title="Schedule A New Pickup Appointment" placement="top">
                  <IconButton
                      onClick={() => {
                        history.push("/schedulepickup")
                      }}
                  >
                    <AddIcon color="info" sx={{ fontSize: 30 }} />
                  </IconButton>
                </Tooltip>
              </Typography>

            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {" "}
              <PickupAppointmentDetails appointments={appointmentList} />
            </Grid>
            {/*<Grid item xs={12} md={12} lg={12}>*/}
            {/*  <Button variant="contained">{`Check More`}</Button>*/}
            {/*</Grid>*/}
          </Grid>
          <br />
          {
              appointmentList?.length === 0 && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5">No Appointments for this order</Typography>
                  </Grid>
              )
          }

        </Card>
      </Grid>
  );
}
