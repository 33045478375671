
import BusinessIcon from "@material-ui/icons/Business";
import {Estimate} from "./Estimate";
import UserSignIn from "../SignIn/userSignIn";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {BecomePartner} from "../BecomePartner/BecomePartner";

const styles = {
    LandingRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center'
    },
    LandingHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    LandingHeaderHeading: {
        color: "green",
        fontFamily: "Montserrat",
        fontSize: "40px",
        letterSpacing: "2px",
        fontWeight: '600',
        textAlign: 'center',
        '@media (max-width: 480px)': {
            fontSize: "30px", // for phone and above
        },
    },
    LandingHeaderSubHeading: {
        display: 'flex',
        gap: '10px'
    },
    LandingContent: {
        display: 'flex',
        marginTop: '20px',
        '@media (max-width: 1024px)': {
            flexDirection: 'column', // for tablets and above
        },
    },
}

export const Landing = () => {

    return (
        <Box sx={styles.LandingRoot}>
            <Box sx={styles.LandingHeader}>
                <Typography sx={styles.LandingHeaderHeading}>
                    Welcome to UUCargo Partner Portal
                </Typography>
                <Box sx={styles.LandingHeaderSubHeading}>
                    <BusinessIcon/>
                    <Typography>
                        Free estimate shipping costs
                    </Typography>
                </Box>
            </Box>
            <Box sx={styles.LandingContent}>
                <Estimate/>
                <UserSignIn/>
            </Box>
            {/*<BecomePartner/>*/}
        </Box>
    )
}