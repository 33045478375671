import React, { useState, useEffect } from "react";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Typography,
  Grid,
  Popper,
  Box,
  Paper,
  Tooltip,
  useMediaQuery,
  Fade,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

// import parse from "autosuggest-highlight/parse";

// import throttle from "lodash/throttle";

const leafLevel = "6";

const parentLeafLevel = "4";

export default function HscodeTable({
  buckets,
  options,
  setSelectedObj,
  setSubList,
  setSelectedSubObj,
  setActiveCate,
  activeItemList,
  activeCate,
  setOpen,
  handleItemChange,
}) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");

  const [value, setValue] = useState("allItems");
  // const [inputValue, setInputValue] = useState(null);

  const getSubList = (parentObj) => {
    if (
      !parentObj ||
      parentObj?.level === parentLeafLevel ||
      parentObj?.level === leafLevel
    )
      return [];
    const res = options.filter((item) => item.parent === parentObj.hscode);
    return res;
  };

  // const findItem = (_hscode) => {
  //   const selectedItem = options.find((option) => option.hscode === _hscode);
  //   return selectedItem ? selectedItem : null;
  // };

  const listAllItems = (
    <Box>
      {activeItemList.map((item) => (
        <Box
          p={1}
          key={item.hscode}
          className={classes.optionItem}
          onClick={() => {
            setSelectedObj(item);
            handleItemChange(item);
            setOpen(false);
            // setInputValue(item.desc);
          }}
        >
          <Typography variant="body2">
            {item.desc}{" "}
            <Tooltip
              title={
                <React.Fragment>
                  <Box>
                    <b>Hscode</b>: {item?.hscode || ""}
                  </Box>
                  <Box>
                    <b>Name_cn_gb</b>: {item?.desc_cn_gb || ""}
                  </Box>
                  <Box>
                    <b>Name_cn_big</b>: {item?.desc_cn_big || ""}
                  </Box>
                </React.Fragment>
              }
            >
              <InfoIcon style={{ color: "rgba(0,0,0,0.3)" }} fontSize="small" />
            </Tooltip>
          </Typography>
        </Box>
      ))}
    </Box>
  );

  const listCategories = (
    <>
      {buckets.map((bucket) =>
        bucket.children ? (
          <>
            <Box
              key={bucket.hscode}
              p={1}
              className={
                bucket.hscode === activeCate?.hscode
                  ? classes.optionCateActive
                  : ""
              }
              margin="auto"
              minHeight="50px"
              onMouseOver={() => setActiveCate(bucket)}
              // onClick={() => {
              //   setSelectedObj(bucket);
              //   setSubList(getSubList(bucket));
              //   setSelectedSubObj(
              //     // cateItem.level !== parentLeafLevel &&
              //     //   cateItem.level !== leafLevel
              //     //   ? getSubList(cateItem)?.[0] || null
              //     //   : cateItem
              //     bucket
              //   );
              //   setOpen(false);
              // }}
            >
              <Typography variant="body2">{bucket.desc || ""}</Typography>
            </Box>
            <Box className={classes.subModule} ml={1}>
              {bucket.children.map((cateItem) => (
                <Box
                  p={1}
                  pl={1}
                  key={cateItem.hscode}
                  minHeight="50px"
                  className={
                    cateItem.hscode === activeCate?.hscode
                      ? classes.optionCateActive
                      : ""
                  }
                  onMouseOver={() => setActiveCate(cateItem)}
                  // onClick={() => {
                  //   setSelectedObj(cateItem);
                  //   setSubList(getSubList(cateItem));
                  //   setSelectedSubObj(cateItem);
                  //   setOpen(false);
                  // }}
                >
                  <Typography variant="body2">-- {cateItem.desc}</Typography>
                </Box>
              ))}
            </Box>
          </>
        ) : (
          <Box
            key={bucket.hscode}
            p={1}
            className={
              bucket.hscode === activeCate?.hscode
                ? classes.optionCateActive
                : ""
            }
            minHeight="50px"
            onMouseOver={() => setActiveCate(bucket)}
            // onClick={() => {
            //   setSelectedObj(bucket);
            //   setSubList(getSubList(bucket));
            //   setSelectedSubObj(
            //     // cateItem.level !== parentLeafLevel &&
            //     //   cateItem.level !== leafLevel
            //     //   ? getSubList(cateItem)?.[0] || null
            //     //   : cateItem
            //     bucket
            //   );
            //   setOpen(false);
            // }}
          >
            <Typography variant="body2">{bucket.desc || ""}</Typography>
          </Box>
        )
      )}
    </>
  );

  return matches ? (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <Box
          className={
            value === "allItems" ? classes.tagItemSelected : classes.tagItem
          }
          onClick={() => {
            setValue("allItems");
          }}
        >
          All items
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          className={
            value !== "allItems" ? classes.tagItemSelected : classes.tagItem
          }
          onClick={() => {
            setValue("categary");
          }}
        >
          Category
        </Box>
      </Grid>

      <Grid item xs={12} className={classes.itemSection}>
        {value === "allItems" ? listAllItems : listCategories}
      </Grid>
    </Grid>
  ) : (
    <Grid container alignItems="center" className={classes.popperSection}>
      <Grid item xs={4} className={classes.cateSection}>
        <Box>
          <Box
            mt={1}
            p={1}
            onMouseOver={() => setActiveCate(null)}
            className={
              activeCate === null
                ? classes.optionCateItemActive
                : classes.optionCateItem
            }
          >
            All items
          </Box>
          <Box mt={1} pl={1}>
            <b>Category:</b>
          </Box>
          {listCategories}
        </Box>
      </Grid>
      <Grid item xs={8} className={classes.itemSection}>
        {listAllItems}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  optionItem: {
    "&:hover": {
      // backgroundColor: "seagreen",
      color: "orange",
      cursor: "pointer",
    },
  },
  optionCateItem: {
    "&:hover": {
      backgroundColor: "grey",
      color: "white",
      cursor: "pointer",
    },
  },
  optionCateItemActive: {
    backgroundColor: "grey",
    color: "white",
    cursor: "pointer",
  },
  optionCateActive: {
    // "&:hover": {
    backgroundColor: "grey",
    color: "white",
    cursor: "pointer",
    // },
  },
  selectedOptionItem: {
    backgroundColor: "grey",
    color: "white",
  },
  cateSection: {
    backgroundColor: "rgb(232,232,232)",
    height: "100%",
    overflowY: "scroll",
  },
  cateDetailTitle: {
    backgroundColor: "rgb(232,232,232)",
  },
  popperSection: {
    height: "300px",
  },
  itemSection: {
    height: "300px",
    overflowY: "scroll",
  },
  tagItem: {
    textAlign: "center",
    height: "2.5rem",
    lineHeight: "2.5rem",
    borderBottom: "1px solid rgba(0,0,0,0.3)",
  },
  tagItemSelected: {
    textAlign: "center",
    height: "2.5rem",
    lineHeight: "2.5rem",
    backgroundColor: "orange",
    color: "white",
  },
  subModule: {
    borderLeft: "2px solid rgba(0,0,0,0.6)",
  },
}));
