import React from "react";
import { makeStyles } from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
  },
}));

export default function CsvRateErrorTableItem({ index,
                                           message
}) {
  const classes = useStyles();

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>{index + 1}</td>
        <td>{message}</td>
      </tr>
    </>
  );
}
