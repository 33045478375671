import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {Divider, Link, Snackbar, useMediaQuery} from "@mui/material";
import {Fragment, useState} from "react";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLCrossBorderDashboardInvoiceRemove = ({orderData, getOrderData}) => {

    const storedToken = getAccessToken("access_token");
    const isMobile = useMediaQuery('(max-width: 480px)');

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const deleteCommercialInvoice = async (docId) => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTL/removeCommercialInvoice`;
        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    docId: docId
                }
            })

            setErrorMessage('');
            setSuccessMessage('Successfully remove invoice');
            handleSuccessMessage();
            setTimeout(() => {
                getOrderData();
            }, 2000);

        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to remove invoice');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    const handleRemoveInvoice = (docId) => {
        deleteCommercialInvoice(docId);
    }

    console.log('order data', orderData);

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '20px'
        }}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Commercial Invoice List
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {
                    !isMobile &&
                    <>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Invoice File
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Invoice Date
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                    </>
                }
                {
                    orderData?.commecialInvoices?.map((element, index) => (
                        <Fragment key={index}>
                            <Grid item md={3}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%'
                                }}>
                                    <Typography style={{fontSize: '14px', textAlign: 'center'}}>
                                        {element?.file_name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={3}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%'
                                }}>
                                    <Typography style={{fontSize: '14px', textAlign: 'center'}}>
                                        {dayjs(element?.created_at).format('YYYY-MM-DD')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Link href={`${LABEL_DOWNLOAD_URL_PREFIX}${element?.file_uri}`}
                                          target="_blank" rel="noopener noreferrer"
                                    >
                                        <Button
                                            variant='outlined'
                                            sx={{
                                                border: '2px solid #1D8B45',
                                                "&:hover": {
                                                    border: '2px solid #1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '14px',
                                                    textTransform: 'none',
                                                    color: '#1D8B45',
                                                }}
                                            >
                                                Download
                                            </Typography>
                                        </Button>
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <LoadingButton
                                        variant='outlined'
                                        sx={{
                                            border: '2px solid #424242',
                                            "&:hover": {
                                                border: '2px solid #424242',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={() => handleRemoveInvoice(element?.doc_id)}
                                        loading={loading}
                                    >
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                textTransform: 'none',
                                                color: '#424242',
                                            }}
                                        >
                                            Delete
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                        </Fragment>
                    ))
                }
            </Grid>
        </Box>
    )
}