import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import EmailIcon from "../../images/emailTransferIcon.png";
import CreditIcon from "../../images/creditCardIcon.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {BillingPaymentEmail} from "./BillingPaymentEmail";
import {BillingPaymentCard} from "./BillingPaymentCard";
import {BillingPaymentOther} from "./BillingPaymentOther";
import {useMediaQuery} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import intl from "react-intl-universal";
import wechatPay from "../../images/wechatPay.svg";
import {BillingPaymentWeChat} from "./BillingPaymentWeChat";
import aliPay from "../../images/alipay.svg";

const styles = {
    BillingPaymentRoot: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        backgroundColor: '#FFFFFF',
        maxWidth: 800,
        border: '1px solid #000',
        borderRadius: '10px'
    },
    BillingPaymentHeader: {
        backgroundColor: '#1D8B45',
        borderRadius: '9px 9px 0 0',
        padding: '15px 30px',
    },
    BillingPaymentContent: {
        backgroundColor: '#FFFFFF',
        borderRadius: '0 0 9px 9px',
        padding: '15px 30px 50px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },

}

export const BillingPayment = ({handlePaymentClose, selectInvoice}) => {

    const [selectPayment, setSelectPayment] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [show, setShow] = useState(false);
    const handleOpenShow = () => setShow(true);
    const handleCloseShow = () => setShow(false);

    const handleSelectPayment = (method) => {
        setSelectPayment(method);
    }

    const cbResetPaymentMethod = () => {
        setSelectPayment(null);
    }

    const calculateTotalAmount = selectInvoice => {
        let total = 0;
        selectInvoice.forEach(invoice => {
            total += +invoice?.remainToPay;
        });
        setTotalAmount(total);
    }

    useEffect(() => {
        calculateTotalAmount(selectInvoice);
    }, [])

    console.log('total amount', totalAmount);

    return (
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: 24,
            backgroundColor: '#FFFFFF',
            maxWidth: 600,
            width: isMobile ? '360px' : '100%',
            border: '1px solid #000',
            borderRadius: '10px'
        }}>
            <Box sx={{
                backgroundColor: '#1D8B45',
                borderRadius: '8px 8px 0 0',
                padding: '15px 30px',
                // width: !isMobile && '480px'
                display: 'flex',
                gap: '10px',
                position: 'relative'
            }}
                 onMouseEnter={handleOpenShow}
                 onMouseLeave={handleCloseShow}
            >
                <Typography style={{color: '#FFFFFF', fontSize: '18px'}}>
                    {
                        selectPayment === 'email' ? intl.get('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.EMAIL_TRANSFER') :
                            selectPayment === 'credit' ? intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.CREDIT_CARD') :
                                selectPayment === 'wechatPay' ? intl.get('BILLING_CENTER.PAYMENT.WECHAT.WECHAT_PAY') :
                                    selectPayment === 'aliPay' ? intl.get('BILLING_CENTER.PAYMENT.ALI.ALI_PAY') :
                                        selectPayment === 'paypal' ? intl.get('BILLING_CENTER.PAYMENT.PAY_PAL.PAY_PAL') :
                                            selectPayment === 'other' ? intl.get('BILLING_CENTER.PAYMENT.OTHER_METHOD') :
                                                intl.get('BILLING_CENTER.PAYMENT.CHOOSE_PAYMENT')
                    }
                </Typography>
                {/*{*/}
                {/*    selectPayment === 'email' && <HelpOutlineIcon sx={{color: '#FFFFFF'}}/>*/}
                {/*}*/}
                {/*{*/}
                {/*    (show && selectPayment === 'email') &&*/}
                {/*    <Typography*/}
                {/*        sx={{*/}
                {/*            display: 'flex',*/}
                {/*            justifyContent: 'center',*/}
                {/*            alignItems: 'center',*/}
                {/*            backgroundColor: '#1D8B45',*/}
                {/*            width: '220px',*/}
                {/*            // height: '150px',*/}
                {/*            padding: '20px',*/}
                {/*            borderRadius: '5px',*/}
                {/*            color: '#ffffff',*/}
                {/*            fontSize: '14px',*/}
                {/*            position: 'absolute',*/}
                {/*            top: '110%',*/}
                {/*            left: '25%',*/}
                {/*            zIndex: 1000*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {intl.get('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.EMAIL_TRANSFER_NOTE')}*/}
                {/*    </Typography>*/}
                {/*}*/}
            </Box>
            {selectPayment === 'email' ?
                <BillingPaymentEmail cbResetPaymentMethod={cbResetPaymentMethod} totalAmount={totalAmount}
                                     selectInvoice={selectInvoice}/> :
                selectPayment === 'credit' ?
                    <BillingPaymentCard cbResetPaymentMethod={cbResetPaymentMethod} totalAmount={totalAmount}
                                        selectInvoice={selectInvoice}/> :
                    (selectPayment === 'wechatPay' || selectPayment === 'other' || selectPayment === 'aliPay' || selectPayment === 'paypal') ?
                        <BillingPaymentOther cbResetPaymentMethod={cbResetPaymentMethod}
                                             selectInvoice={selectInvoice}
                                             totalAmount={totalAmount}
                                             selectPayment={selectPayment}/> :
                        <Box sx={styles.BillingPaymentContent}>
                            <Typography style={{fontSize: '16px'}}>
                                {intl.get('BILLING_CENTER.PAYMENT.PREFER_PAYMENT_METHOD')}
                            </Typography>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item md={4} xs={6}>
                                        <Box sx={{
                                            position: 'relative',
                                            height: isMobile ? '80px' : '100px',
                                            width: isMobile ? '120px' : '150px'
                                        }}>
                                            {
                                                selectPayment === 'email' &&
                                                <CheckCircleIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        right: '-5%',
                                                        top: '-10%',
                                                        zIndex: 1,
                                                        color: '#1D8B45',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            }
                                            <LoadingButton
                                                sx={{
                                                    border: selectPayment === 'email' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: '15px 0px',
                                                    overflow: 'visible',
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                                onClick={() => handleSelectPayment('email')}
                                            >
                                                <img src={EmailIcon} alt="email-icon" width={60}/>
                                                <Typography sx={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    textTransform: 'none',
                                                    fontWeight: '600'
                                                }}>
                                                    {intl.get('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.EMAIL_TRANSFER')}
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} xs={6}>
                                        <Box sx={{
                                            position: 'relative',
                                            height: isMobile ? '80px' : '100px',
                                            width: isMobile ? '120px' : '150px'
                                        }}>
                                            {
                                                selectPayment === 'credit' &&
                                                <CheckCircleIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        right: '-5%',
                                                        top: '-10%',
                                                        zIndex: 1,
                                                        color: '#1D8B45',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            }
                                            <LoadingButton
                                                sx={{
                                                    border: selectPayment === 'credit' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: '15px 0px',
                                                    overflow: 'visible',
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                                onClick={() => handleSelectPayment('credit')}
                                            >
                                                <img src={CreditIcon} alt="credit-icon" width={60}/>
                                                <Typography sx={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    textTransform: 'none',
                                                    fontWeight: '600'
                                                }}>
                                                    {intl.get('BILLING_CENTER.PAYMENT.CREDIT_CARD.CREDIT_CARD')}
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} xs={6}>
                                        <Box sx={{
                                            position: 'relative',
                                            height: isMobile ? '80px' : '100px',
                                            width: isMobile ? '120px' : '150px'
                                        }}>
                                            {
                                                selectPayment === 'wechatPay' &&
                                                <CheckCircleIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        right: '-5%',
                                                        top: '-10%',
                                                        zIndex: 1,
                                                        color: '#1D8B45',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            }
                                            <LoadingButton
                                                sx={{
                                                    border: selectPayment === 'wechatPay' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: '15px 0px',
                                                    overflow: 'visible',
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                                onClick={() => handleSelectPayment('wechatPay')}
                                            >
                                                <img src={wechatPay} alt="wechatPayLogo" width={50}/>
                                                <Typography sx={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    textTransform: 'none',
                                                    fontWeight: '600'
                                                }}>
                                                    {intl.get('BILLING_CENTER.PAYMENT.WECHAT.WECHAT_PAY')}
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} xs={6}>
                                        <Box sx={{
                                            position: 'relative',
                                            height: isMobile ? '80px' : '100px',
                                            width: isMobile ? '120px' : '150px'
                                        }}>
                                            {
                                                selectPayment === 'aliPay' &&
                                                <CheckCircleIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        right: '-5%',
                                                        top: '-10%',
                                                        zIndex: 1,
                                                        color: '#1D8B45',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            }
                                            <LoadingButton
                                                sx={{
                                                    border: selectPayment === 'aliPay' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: '15px 0px',
                                                    overflow: 'visible',
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                                onClick={() => handleSelectPayment('aliPay')}
                                            >
                                                <img src={aliPay} alt="aliPayLogo" width={50}/>
                                                <Typography sx={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    textTransform: 'none',
                                                    fontWeight: '600'
                                                }}>
                                                    {intl.get('BILLING_CENTER.PAYMENT.ALI.ALI_PAY')}
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} xs={6}>
                                        <Box sx={{
                                            position: 'relative',
                                            height: isMobile ? '80px' : '100px',
                                            width: isMobile ? '120px' : '150px'
                                        }}>
                                            {
                                                selectPayment === 'paypal' &&
                                                <CheckCircleIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        right: '-5%',
                                                        top: '-10%',
                                                        zIndex: 1,
                                                        color: '#1D8B45',
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '50%'
                                                    }}
                                                />
                                            }
                                            <LoadingButton
                                                sx={{
                                                    border: selectPayment === 'paypal' ? '2px solid #1D8B45' : '2px solid #A6A6A6',
                                                    width: '100%',
                                                    height: '100%',
                                                    padding: '15px 0px',
                                                    overflow: 'visible',
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                                onClick={() => handleSelectPayment('paypal')}
                                            >
                                                <img
                                                    src={'https://storage.googleapis.com/uucargo-resource/image/icon/paypal.svg'}
                                                    alt="PayPalLogo" width={50}/>
                                                <Typography sx={{
                                                    color: '#000000',
                                                    fontSize: '14px',
                                                    textTransform: 'none',
                                                    fontWeight: '600'
                                                }}>
                                                    {intl.get('BILLING_CENTER.PAYMENT.PAY_PAL.PAY_PAL')}
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
            }
        </Box>
    )
}