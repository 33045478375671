import {actionType} from "../../utils/Helper";

const initialState = {
    commercialTab: 'upload',
}

export const crossBorderInvoiceReducer = (state = initialState, action) => {
    // console.log('reducer', action);
    switch (action.type) {
        case actionType.UPDATE_COMMERCIAL_INVOICE_TAB_VALUE:
            return {...state, commercialTab: action?.payload};
        default:
            return state;
    }
}