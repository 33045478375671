import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import { Alert } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAccessToken } from "../../utils/doToken";
import axios from "axios";
import { PARTNER_URI } from "../../utils/apiUrl";
import TrackPackageItem from "../Tracking/TrackPackageItem";
import { Popover } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
    textAlign: "left",
  },
  popover: {
    pointerEvents: "none",
    overflow: "auto",
    width: "fit-content",
    height: "fit-content",
  },
  paper: {
    padding: theme.spacing(1),
    overflow: "auto",
  },
}));

export default function SearchShippingTransactionTableItem({
  tran_id,
  order_id,
  ref_order_no,
  cargo_control_no,
  ref_product_no,
  ref_other_no,
  account_base,
  gross_price,
  net_price,
  zip_label_uri,
  label_pdf_uri,
  date,
  send_to_name,
  send_to_email,
  send_to_tel,
  send_to_addr,
  send_to_city,
  send_to_postal_code,
  send_to_province,
  send_to_country,
  currency_code,
  transactionNumbers,
  tran_status,
  shipping_id_no,
  shipping_fee_exclude_tax,
  service_fee_exclude_tax,
  service_fee_include_gst,
  shipping_fee_include_tax,
}) {
  const classes = useStyles();

  const getLabelURL = (pdf, zip) => {
    if (pdf) {
      return pdf;
    }
    if (zip) {
      return zip;
    }
    return "";
  };

  const [cancelTrackingNumberText, setCancelTrackingNumberText] = useState("");
  const [
    cancelTrackingNumberErrorMessage,
    setCancelTrackingNumberErrorMessage,
  ] = useState("");
  const [cancelLoading, setCancelLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] =
    useState(false);
  const [trackingCheckTip, setTrackingCheckTip] = useState(false);
  const [trackedPackagesList, setTrackedPackagesList] = useState([]);
  const [anchorEl, setAnchorEI] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const divRef = useRef();

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const cancelOrderByTransactionId = async () => {
    try {
      setCancelLoading(true);
      const storedToken = getAccessToken("access_token");
      const { data } = await axios.delete(
        `${PARTNER_URI}/loose-item/3rd-party/internal/cancel-shipping`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
          data: {
            trackingNumber: shipping_id_no,
          },
        }
      );
      console.log(data);
      setCancelTrackingNumberErrorMessage("");
      setIsCancelled(true);
    } catch (e) {
      console.log(e);
      setCancelTrackingNumberErrorMessage(
        e?.response?.data?.errorMessage || "Error!"
      );
    } finally {
      setCancelLoading(false);
    }
  };

  const confirmCancelOrder = async (e) => {
    e.preventDefault();
    await cancelOrderByTransactionId();
    handleToastClick();
    handleDialogClose();
  };

  // Cancel Tracking Order
  const onCancelOrder = () => {
    handleDialogOpen();
  };

  const excludeTax = +(
    +shipping_fee_exclude_tax + +service_fee_exclude_tax
  ).toFixed(2);
  const includeTax = +(
    +shipping_fee_include_tax + +service_fee_include_gst
  ).toFixed(2);

  //Tracking information dialog
  const getTrackedPackageList = async () => {
    try {
      if (transactionNumbers?.trim() === "") {
        return;
      }

      const storedToken = getAccessToken("access_token");
      const { data, status } = await axios({
        method: "get",
        url: `${PARTNER_URI}/loose-item/3rd-party/multipleTrackingForWeb?trackingNumbers=${transactionNumbers}`,
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      const trackingList = data?.filter((v) => v?.trackingResponse !== null);

      if (status === 200) {
        console.log("Success:", data);
        setTrackedPackagesList(trackingList);
      } else {
        console.log("Error:", data);
        throw new Error();
      }
    } catch (e) {
      setErrorMessage("Failed to Schedule Pickup. Try again another time.");
    } finally {
      setIsLoading(false);
    }
  };

  // function handleTrackingInfoOpen() {
  //   setIsLoading(true);
  //   setAnchorEI(divRef.current);
  // }

  // function handleTrackingInfoClose() {
  //   setAnchorEI(null);
  // }

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  // useEffect(() => {
  //   if (anchorEl) {
  //     getTrackedPackageList();
  //   }
  // }, [anchorEl]);

  const handleCheckTrakcingInfoTip = () => {

    setTrackingCheckTip(!trackingCheckTip);
  };

  useEffect(() => {
    if (trackingInfoDialogIsOpen) {
      getTrackedPackageList();
    }
  }, [trackingInfoDialogIsOpen]);

  const handleTrackingInfo = () => {
    setIsLoading(true)
    setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
  };

  const handleTrackingInfoClose = (event, reason)=>{
    if (reason && reason == "backdropClick") {
      setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
    }
  }
  return (
    <>
      {/*Pop-up Toast*/}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={3000}
        message="Cancel Order"
      >
        {(() => {
          if (cancelTrackingNumberErrorMessage !== "") {
            return (
              <Alert
                onClose={handleToastClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Transaction can't be cancelled!
                <hr />
                Error: {cancelTrackingNumberErrorMessage}
              </Alert>
            );
          }
          return (
            <Alert
              onClose={handleToastClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Transaction has been cancelled!
            </Alert>
          );
        })()}
      </Snackbar>
      {/*Pop-up Toast*/}

      {/*Confirm Delete Tracking Dialog*/}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel Tracking Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This process will cancel ALL the trackings related to this
            Transaction <b>{transactionNumbers}</b>. Are you sure to cancel?
            <hr />
            Click <b>Confirm</b> to cancel this shipping.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="outlined"
            autoFocus
            type="button"
          >
            No
          </Button>
          <Button
            onClick={confirmCancelOrder}
            variant="outlined"
            type="button"
            disabled={cancelLoading}
          >
            {cancelLoading && <CircularProgress size={14} />}
            {!cancelLoading && "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      {/*Confirm Delete Tracking Dialog*/}

      {/*Trakcing Information Dialog*/}
      <Dialog
        open={trackingInfoDialogIsOpen}
        onClose={handleTrackingInfoClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tracking Details</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <>
              <CircularProgress disableShrink={false} />
            </>
          ) : trackedPackagesList.length === 0 ? (
            <>No Tracking Information Found</>
          ) : (
            trackedPackagesList.map((trackedPackage, index) => {
              return (
                <TrackPackageItem
                  key={index}
                  activity={
                    trackedPackage.trackingResponse
                      ? trackedPackage.trackingResponse.activity
                      : undefined
                  }
                  trackingNo={trackedPackage.trackingNumber}
                  courier={trackedPackage.accountBase}
                  deliverStatus={trackedPackage.trackingResponse.status}
                  transaction={trackedPackage.transaction}
                />
              );
            })
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleTrackingInfo}
            variant="outlined"
            autoFocus
            type="button"
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      {/*Trakcing Information Dialog*/}

      <tr className={classes.listItemWrapper}>
        <td width="7%">
          <Link to={`/loose-item/3rd-party/shipping/list/product/${tran_id}`}>
            {tran_id}
          </Link>
        </td>
        <td width="7%">
          <Link to={`/loose-item/order-dashboard/${order_id}`}>
            {order_id}
          </Link>
        </td>
        <td width="5%">
          Ref Order #: {ref_order_no}
          <br />
          Ref Product #: {ref_product_no}
          <br />
          Ref Other #: {ref_other_no}
        </td>
        {/*<td>{ref_order_no}</td>*/}
        {/*<td>{ref_product_no}</td>*/}
        {/*<td>{ref_other_no}</td>*/}

        {/*<td>{`${send_to_name ? send_to_name : "N/A"} / ${send_to_email ? send_to_email : "N/A"} / ${send_to_tel ? send_to_tel : "N/A"}`}</td>*/}
        {/*<td>{`${send_to_addr ? send_to_addr : "N/A"} / ${send_to_city ? send_to_city : "N/A"} / ${send_to_province ? send_to_province : "N/A"} / ${send_to_country ? send_to_country : "N/A"} / ${send_to_postal_code ? send_to_postal_code : "N/A"}`}</td>*/}
        <td width="20%">
          {/*{`${send_to_name ? send_to_name : "N/A"} ${send_to_email ? send_to_email : "N/A"}, ${send_to_tel ? send_to_tel : "N/A"}`}*/}
          {send_to_name ? send_to_name : "N/A"}
          <br />
          {send_to_tel ? send_to_tel : "N/A"},{" "}
          {send_to_email ? send_to_email : "N/A"}
          <br />
          {send_to_addr}
          <br />
          {send_to_city}, {send_to_province}, {send_to_country},{" "}
          {send_to_postal_code}
        </td>
        {/*<td>{account_base}</td>*/}
        {/*<td>*/}
        {/*  excl. tax: <CurrencyFormat value={gross_price} displayType={'text'} thousandSeparator={true} prefix={`${currency_code} $`} />*/}
        {/*  <br />*/}
        {/*  inc. tax: <CurrencyFormat value={net_price} displayType={'text'} thousandSeparator={true} prefix={`${currency_code} $`} />*/}
        {/*</td>*/}
        <td>
          excl. tax:{" "}
          <CurrencyFormat
            value={Math.abs(excludeTax)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={
              +excludeTax >= 0 ? `${currency_code} $` : `${currency_code} $-`
            }
          />
          <br />
          inc. tax:{" "}
          <CurrencyFormat
            value={Math.abs(includeTax)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={
              +includeTax >= 0 ? `${currency_code} $` : `${currency_code} $-`
            }
          />
        </td>
        {/*<td>*/}
        {/*  <CurrencyFormat value={net_price} displayType={'text'} thousandSeparator={true} prefix={`${currency_code} $`} />*/}
        {/*</td>*/}

        <Tooltip
          title="Click to check the tracking information"
          placement="left"
        >
          <td
            width="15%"
            onClick={handleTrackingInfo}
            onMouseOver={handleCheckTrakcingInfoTip}
          >
            {account_base}
            <br />
            {transactionNumbers}
            <br />
            <a
              href={getLabelURL(label_pdf_uri, zip_label_uri)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getLabelURL(label_pdf_uri, zip_label_uri) ? "Label" : ""}
            </a>
          </td>
        </Tooltip>
        {/* <div ref={divRef}>
          <Tooltip
            title="Click to check the tracking information"
            placement="left"
          >
            <td
              width="15%"
              onClick={handleTrackingInfoOpen}
              onMouseOver={handleCheckTrakcingInfoTip}
            >
              {account_base}

              <br />
              {transactionNumbers}

              <br />
              <a
                href={getLabelURL(label_pdf_uri, zip_label_uri)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getLabelURL(label_pdf_uri, zip_label_uri) ? "Label" : ""}
              </a>
            </td>
          </Tooltip>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleTrackingInfoClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            disableRestoreFocus
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
          >
            {isLoading ? (
              <>
                <h3 style={{ "text-align": "center" }}>Tracking Details</h3>
                <CircularProgress disableShrink={false} />
              </>
            ) : trackedPackagesList.length === 0 ? (
              <h3>No Trakcing Information</h3>
            ) : (
              <>
                <h3 style={{ "text-align": "center" }}>Tracking Details</h3>

                {trackedPackagesList.map((trackedPackage, index) => {
                  return (
                    <TrackPackageItem
                      key={index}
                      activity={
                        trackedPackage.trackingResponse
                          ? trackedPackage.trackingResponse.activity
                          : undefined
                      }
                      trackingNo={trackedPackage.trackingNumber}
                      courier={trackedPackage.accountBase}
                      deliverStatus={trackedPackage.trackingResponse.status}
                      transaction={trackedPackage.transaction}
                    />
                  );
                })}
              </>
            )}
          </Popover>
        </div> */}

        {/*<td>*/}
        {/*  <a href={zip_label_uri} target="_blank" rel="noopener noreferrer">*/}
        {/*    {zip_label_uri ? "Download" : ""}*/}
        {/*  </a>*/}
        {/*</td>*/}
        {/*<td>*/}
        {/*  <a href={label_pdf_uri} target="_blank" rel="noopener noreferrer">*/}
        {/*    {label_pdf_uri ? "Download" : ""}*/}
        {/*  </a>*/}
        {/*</td>*/}
        <td>{dayjs(date).format("YYYY-MM-DD HH:mm")}</td>
        <td>
          {
            (() => {
              if (+tran_status === 1 || +tran_status === 0) {
                return (
                  <button
                    onClick={() => {
                      onCancelOrder();
                    }}
                  >
                    Cancel
                  </button>
                );
              }
              if (+tran_status === 2) {
                return "Delivered";
              }
              return "Cancelled";
            })()
          }
        </td>
      </tr>
    </>
  );
}
