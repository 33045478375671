exports.CHECKPAY_WECHAT_PAY = "CHECKPAY-WECHATPAY";

exports.CHECKPAY_ALIPAY = "CHECKPAY-ALIPAY";

exports.ALPHAPAY_WECHATPAY = "ALPHAPAY-WECHATPAY";

exports.ALPHAPAY_ALIPAY = "ALPHAPAY-ALIPAY";

exports.PAGE_SIZE = 20;

exports.TIME_OUT = 60000;

