import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  startPort: "",
  endPort: "",
  startPortCode: "",
  endPortCode: "",
  routeCode: "",
  sailingDate: "",
  shippingCompany: "",
  voyage: 0,
  twentyGP: 0,
  fortyGP: 0,
  fortyHQ: 0,
  surcharge: []
}

const oceanShippingSlice = createSlice({
  name: 'oceanShipping',
  initialState,
  reducers: {
    setOceanOrder: (state, { payload }) => {
      state.startPort = payload.startPort;
      state.endPort = payload.endPort;
      state.startPortCode = payload.startPortCode;
      state.endPortCode = payload.endPortCode;
      state.routeCode = payload.routeCode;
      state.sailingDate = payload.sailingDate;
      state.shippingCompany = payload.shippingCompany;
      state.voyage = payload.voyage;
      state.twentyGP = payload.twentyGP;
      state.fortyGP = payload.fortyGP;
      state.fortyHQ = payload.fortyHQ;
      state.surcharge = payload.surcharge;
    },

    // addpackages: (state, { payload }) => {
    //   state.packageData = [...state.packageData, payload.packages];
    // },

    // selectPackage: (state, { payload }) => {
    //   state.selected_package_number = payload.selected_package_number;
    // },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = oceanShippingSlice;

export const {
  setOceanOrder
} = actions;

export const oceanShippingReducer = reducer;

// Export selector