import {Container} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import {TrackingLTLMultiple} from "./TrackingLTLMultiple";
import {useDispatch, useSelector} from "react-redux";
import {updateTrackingNumberBOL} from "../Actions/trackingPackageAction";

export const TrackingLTL = () => {

    const dispatch = useDispatch();

    const trackingNumbersRedux = useSelector(state => state?.trackingPackage?.trackingNumberBOL);

    const [trackingNumbers, setTrackingNumbers] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [trackingData, setTrackingData] = useState(null);
    const [error, setError] = useState(false);

    const handleTrackingNumber = (event) => {
        setTrackingNumbers(event.target.value);
    }

    const getTrackingByBolNumbers = async () => {
        setIsLoading(true);
        const requestURL = `${PARTNER_URI}/shippingLTLTracking/multipleTracking`;
        const storedToken = getAccessToken("access_token");
        try {

            if (trackingNumbers?.trim() === "") {
                return;
            }

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    trackingNumbers: trackingNumbers
                    // trackingNumber: 'VAN6337994'
                }
            })
            console.log('result', result);
            setTrackingData(result?.data);
            setError(false);
        } catch (e) {
            console.log('error', e.response);
            setError(true);
        } finally {
            setIsLoading(false);
        }
    }

    const handleGetTrackingNumbers = () => {
        getTrackingByBolNumbers();
    }

    useEffect(() => {
        setTrackingNumbers(trackingNumbersRedux);
    },[])

    useEffect(() => {
        dispatch(updateTrackingNumberBOL(trackingNumbers));
    },[trackingNumbers])

    console.log('tracking number', trackingNumbers);
    return (
        <Container maxWidth='xl'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '24px', fontWeight: '600', textAlign: 'left'}}>
                        Tracking
                    </Typography>
                    <Typography style={{fontSize: '16px', textAlign: 'left'}}>
                        Enter up to 25 BOL numbers, seperated tracking numbers by comma.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={trackingNumbers}
                        sx={{
                            width: "100%",
                            mb: 1,
                        }}
                        multiline
                        // value={trackingNumbers}
                        onInput={handleTrackingNumber}
                        rows={6}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start'
                        }}
                    >
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleGetTrackingNumbers}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                Track
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {
                        isLoading ? <Box><CircularProgress/></Box> :
                            error ? <Box><Typography>Error</Typography></Box> :
                                trackingData?.map((element, index) => (
                                    <TrackingLTLMultiple trackingData={element} key={index}/>
                                ))

                    }
                </Grid>
            </Grid>
        </Container>
    )
}