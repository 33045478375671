import React, {useState, useEffect} from "react";
import GooglePlaceInput from "./googlePlacesAutoComplete";

// import DateFnsUtils from '@date-io/date-fns';
// import {format, setDate} from 'date-fns'
// import {dateFormat} from 'dateformat';
// import { MuiPickersUtilsProvider, Calendar, DatePicker } from "@material-ui/pickers";
// import enGBLocale from "date-fns/locale/en-GB";
// import { createMuiTheme } from '@material-ui/core/styles';
// import { Store, Home, Business, ArrowForwardIos } from '@material-ui/icons';
import {Box, Select, TextField} from "@material-ui/core";
import {countryList, canadaProvincesList, americaProvinceList, sendToCountryList} from "../constInfo";
// import { makeStyles } from "@material-ui/core/styles";
// import { propTypes } from 'react-bootstrap/esm/Image';
// import CustomInput from "../CustomInput";
import CustomInput from "../customInput";
import getProvinceCodeByName from "../../utils/getProvinceCode";
import {getCountryCode, getShipFromCountryCode} from "../../utils/getCountryCode";
import {MenuItem} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    // paddingTop: "0.4vw",
    // paddingBottom: "0.4vw",
  },
  lineTextfield: {
    width: "50%",
    paddingRight: theme.spacing(1),
  },
  selectBox: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  root: {
    "& input": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  selectRoot: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  disabled: {
    backgroundColor: grey[300],
    border: `1px solid ${grey[300]}`,
  },
  errorText: {
    border: "1px solid red",
  },
  menuPaper: {
    maxWidth: 150
  },
}));
export default function AddressInputCombo({
                                              handleChange,
                                              handleChangeFromSelection,
                                              addressObject,
                                              pickUp = false,
                                              userLocation,
                                              // freeSolo = false,
                                              checkError = false,
                                              readOnly = false,
                                              addressInputValue,
                                              setAddressInputValue,
                                              aptInfoInputValue,
                                              setAptInfoInputValue,
                                              setPostalCodeInputValue,
                                              cityInputValue,
                                              postalCodeInputValue,
                                              provinceInputValue,
                                              setCityInputValue,
                                              setProvinceInputValue,
                                              isSendTo,
                                              defaultCity
                                          }) {
    const classes = useStyles();

    const [cityObj, setCityObj] = useState({city: defaultCity || null});
    const [provinceList, setProvinceList] = useState(addressObject?.country !== "CA" ? americaProvinceList : canadaProvincesList);

    useEffect(() => {
        if (addressObject.country === "US") {
            setProvinceList(americaProvinceList);
            return;
        }
        setProvinceList(canadaProvincesList);
    }, [addressObject.country])

    useEffect(() => {
        userLocation?.city && setCityObj({city: userLocation.city});
    }, [userLocation?.city]);

    const [postalObj, setPostalObj] = useState({zipCode: ""});

    useEffect(() => {
        userLocation?.zipCode && setPostalObj({zipCode: userLocation.zipCode});
    }, [userLocation?.zipCode]);

    useEffect(() => {
        console.log("Address Object:", addressObject);
    }, [addressObject]);

    return (
        <Box>
            <Box mr={1} mb={1}>
                <CustomInput
                    error={pickUp && checkError && !addressObject.name}
                    onChange={handleChange("name")}
                    // value={provinces.find(ele => ele.value === addressObject.province) || ''}
                    value={addressObject.name}
                    type="line"
                    label="Name *"
                    disabled={readOnly}
                    minLength={3}
                    checkError={checkError}
                    placeholderValue="Name, At Least 3 Character"
                />
            </Box>
            <Box mr={1} mb={1}>
                <CustomInput
                    error={pickUp && checkError && !addressObject.companyName}
                    onChange={handleChange("companyName")}
                    // value={provinces.find(ele => ele.value === addressObject.province) || ''}
                    value={addressObject.companyName}
                    type="line"
                    label="Company Name"
                    disabled={readOnly}
                    minLength={3}
                    checkError={checkError}
                    placeholderValue="Company Name (Optional)"
                />
            </Box>
            <Box mr={1} mb={1}>
                <CustomInput
                    error={pickUp && checkError && !addressObject.phone}
                    onChange={handleChange("phone")}
                    // value={provinces.find(ele => ele.value === addressObject.province) || ''}
                    value={addressObject.phone}
                    type="line"
                    label="Phone *"
                    minLength={10}
                    maxLength={14}
                    checkError={checkError}
                    disabled={readOnly}
                    placeholderValue="e.g 778-123-1234.  Length between 10-14"
                />
            </Box>
            <Box mr={1} mb={1}>
                <CustomInput
                    onChange={handleChange("email")}
                    // value={provinces.find(ele => ele.value === addressObject.province) || ''}
                    value={addressObject.email}
                    type="line"
                    label="Email (Recommended)"
                    minLength={10}
                    maxLength={14}
                    checkError={checkError}
                    disabled={readOnly}
                    placeholderValue="myEmail@gmail.com"
                />
            </Box>
            <Box mr={1} mb={1}>
                <GooglePlaceInput
                    type="street"
                    handleChange={handleChangeFromSelection("address")}
                    setProvinceValue={handleChangeFromSelection("province")}
                    setCityValueUpperLevel={handleChangeFromSelection("city")}
                    setZipcodeValueUpperLevel={handleChangeFromSelection("zipCode")}
                    setPlaceId={handleChangeFromSelection("placeId")}
                    setCityValue={setCityObj}
                    setZipcodeValue={setPostalObj}
                    setCountryValue={handleChangeFromSelection("country")}
                    addressValue={userLocation?.address}
                    checkError={checkError}
                    value={addressInputValue}
                    setValue={setAddressInputValue}
                    setAddressLineTwoValue={handleChangeFromSelection("aptInfo")}
                    alphanumeric
                />
            </Box>
            <Box mr={1} display="flex" mb={1}>
                <Box flexBasis="50%" mr={1}>
                    <TextField
                        onChange={handleChange("aptInfo")}
                        value={addressObject.aptInfo}
                        label="Apartment, suite, etc (optional)"
                        fullWidth
                        disabled={readOnly}
                    />
                </Box>
                <Box flexBasis="50%" ml={1}>
                    <GooglePlaceInput
                        type="postal"
                        handleChange={handleChangeFromSelection("zipCode")}
                        zipCodeValue={postalObj}
                        readOnly={readOnly}
                        checkError={checkError}
                        value={postalCodeInputValue}
                        setValue={setPostalCodeInputValue}
                        alphanumeric
                    />
                </Box>
            </Box>
            <Box mr={1} display="flex">
                <Box mr={1} flexBasis="50%">
                    <GooglePlaceInput
                        type="city"
                        handleChange={handleChangeFromSelection("city")}
                        // cityValue={userLocation?.city}
                        setProvinceValue={handleChangeFromSelection("province")}
                        setCountryValue={handleChangeFromSelection("country")}
                        cityValue={cityObj}
                        checkError={checkError}
                        readOnly={readOnly}
                        value={cityInputValue}
                        setValue={setCityInputValue}
                        alphanumeric
                    />
                </Box>
                <Box flexBasis="30%">
                    <CustomInput
                        onChange={handleChangeFromSelection("province")}
                        // value={provincesList.find(ele => ele.value === addressObject.province || '')}
                        value={addressObject.province}
                        label="Province/State *"
                        fullWidth
                        inputWithSelect
                        options={provinceList}
                        checkError={checkError && !addressObject.province}
                        type="inputWithSelect"
                        alphanumeric
                    />
                </Box>
                <Box flexBasis="20%">
                    {/*<CustomInput*/}
                    {/*    onChange={handleChangeFromSelection("country")}*/}
                    {/*    value={addressObject.country}*/}
                    {/*    label="Country *"*/}
                    {/*    fullWidth*/}
                    {/*    // inputWithSelect*/}
                    {/*    // options={isSendTo ? sendToCountryList : countryList}*/}
                    {/*    options={getCountryCodeValue()}*/}
                    {/*    checkError={checkError && (!addressObject.country || addressObject.country?.trim() === "")}*/}
                    {/*    type="inputWithOptionsValue"*/}
                    {/*/>*/}

                  <Box display="flex" justifyContent="center" alignItems="center" sx={{ paddingLeft: "8px", paddingTop: "12px" }}>
                    <Select
                        fullWidth
                        value={addressObject.country}
                        label="Country *"
                        classes={{ root: classes.selectBox }}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          handleChangeFromSelection("country")(newValue);
                        }
                        }
                        // className={classes.textfield}
                        // classes={{root: classes.selectRoot}}
                        // MenuProps={{classes: {paper: classes.menuPaper}}}
                    >
                      {
                        (() => {
                          if (isSendTo) {
                            return (
                                getCountryCode().map(v => {
                                  return <MenuItem value={v.code}>{v.name}</MenuItem>
                                })
                            )
                          } else {
                            return (
                                getShipFromCountryCode().map(v => {
                                  return <MenuItem value={v.code}>{v.name}</MenuItem>
                                })
                            )
                          }
                        })()
                      }
                    </Select>
                  </Box>

                </Box>
            </Box>
        </Box>
    );
}
