import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CheckPayWechatPaymentInvoiceDialog from "./CheckPayWechatPaymentInvoiceDialog";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    FormControlLabel,
    FormLabel, Snackbar
} from "@mui/material";
import {FormControl, makeStyles, Radio, RadioGroup} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import CurrencyFormat from "react-currency-format";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WechatTopUpPaymentInvoiceDialog from "./WechatTopUpPaymentInvoiceDialog";
import ge from "react-datepicker";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {AliPayTopUpPaymentInvoiceDialog} from "./AliPayTopUpPaymentInvoiceDialog";
import {CreditCardTopUpPaymentInvoiceDialog} from "./CreditCardTopUpPaymentInvoiceDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: 'transparent',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(20),
        color: '#000000',
        '&.Mui-selected': {
            color: '#609966',
            borderBottom: '2px solid #609966',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                ></IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const paymentAmountArray = [100, 200, 500, 1000];

const paymentAmountArrayForAdmin = [0.5, 100, 200, 500, 1000];

const useStyles = makeStyles((theme) => ({
    rootExpanded: {
        background: "blue",
        flexGrow: 1
    },
    accordion: {
        width: "100%"
    }
}));

export default function TopUpDialog({
                                        open,
                                        handleClose,
                                        isForAdmin = false
                                    }) {

    const storedToken = getAccessToken("access_token");
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [referenceNumber, setReferenceNumber] = useState('');
    const [amount, setAmount] = useState(100);
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceId, setInvoiceId] = useState(0);
    const [crmInvoiceInfo, setCRMInvoiceInfo] = useState({});
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [extraReturn, setExtraReturn] = useState(false);
    const [tabValue, setTabValue] = useState('wechat');

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleReferenceNumber = (event) => {
        setReferenceNumber(event.target.value);
    }

    const verifyPartnerCanGetAnExtraReturn = async () => {
        const requestURL = `${PARTNER_URI}/billingCenter/canGetExtraReturnForPartner`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                }
            })
            console.log('result', result.data);
            setExtraReturn(result.data);
        } catch (e) {
            console.log('error', e.response)
        }
    }

    const TopUpViaEmailReferenceNumber = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/billingCenter/partnerTopUpByEmailReferenceNumber`;

        const data = {
            referenceNumber: referenceNumber,
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: data
            })
            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successful Top Up');
            handleSuccessMessage();
            setLoading(false);
            window.location.reload();
        } catch (e) {
            console.log(e.response)
            setErrorMessage(e?.response?.data?.error);
            handleErrorMessage();
            setLoading(false);
        }
    }

    const initDownPayment = async () => {
        try {
            setIsLoading(true);
            const storedToken = getAccessToken("access_token");
            const {data} = await axios.post(`${PARTNER_URI}/billingCenter/initTopUpDownPaymentInvoice`, {
                amount: amount
            }, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            setInvoiceId(data.invoiceId);
            console.log(data);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const getCRMInvoiceInfoById = async ({invoiceId}) => {
        try {
            const storedToken = getAccessToken("access_token");
            const {data} = await axios.get(`${PARTNER_URI}/billingCenter/getInvoiceInfoByInvoiceId?invoiceId=${invoiceId}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(data);
            setCRMInvoiceInfo(data);
        } catch (e) {
            console.log(e);
        }
    }

    const handleEmailTopUp = () => {
        TopUpViaEmailReferenceNumber();
    }

    useEffect(() => {
        if (invoiceId !== 0) {
            try {
                (async () => {
                    const {data} = await getCRMInvoiceInfoById({
                        invoiceId: invoiceId
                    });
                    setCRMInvoiceInfo(data);
                })()
            } catch (e) {
                console.log(e);
            }
        }
    }, [invoiceId])

    useEffect(() => {
        verifyPartnerCanGetAnExtraReturn();
    }, [])

    if (invoiceId !== 0 && crmInvoiceInfo?.id !== 0) {
        return (
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Top-Up
                </BootstrapDialogTitle>
                <DialogContent
                    dividers
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "start",
                        minWidth: "540px",
                        fontSize: "20px"
                    }}
                >
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignContent="flex-start"
                         width="100%" gap='10px'>
                        <Box>
                            <Box className="currency_container" display="flex" justifyContent="space-between"
                                 alignContent="center">
                                Ref #: {crmInvoiceInfo?.invoiceRefNumber}
                            </Box>
                            <Box className="currency_container" display="flex">
                                Amount: &nbsp;<CurrencyFormat
                                value={(+crmInvoiceInfo?.totalChargeIncludeTax).toFixed(2)}
                                displayType={'text'} thousandSeparator={true} prefix="$"/>
                            </Box>
                        </Box>
                        <Box>
                            <StyledTabs
                                value={tabValue}
                                onChange={handleChangeTab}
                                variant='fullWidth'
                            >
                                <StyledTab label="WeChat Pay" value="wechat"/>
                                <StyledTab label="Ali Pay" value="ali"/>
                                <StyledTab label="Credit Card" value="card"/>
                            </StyledTabs>
                        </Box>
                        {
                            tabValue === 'wechat' && <WechatTopUpPaymentInvoiceDialog crmInvoiceNumber={invoiceId}/>
                        }
                        {
                            tabValue === 'ali' && <AliPayTopUpPaymentInvoiceDialog crmInvoiceNumber={invoiceId}/>
                        }
                        {
                            tabValue === 'card' && <CreditCardTopUpPaymentInvoiceDialog crmInvoiceNumber={invoiceId}/>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" width="100%" justifyContent="space-between">
                        <Button variant="contained" color="success" onClick={() => {
                            window.location.reload()
                        }
                        }>
                            I already paid, refresh page
                        </Button>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        )
    }

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                Top-Up
            </BootstrapDialogTitle>
            <DialogContent
                dividers
            >
                {extraReturn &&
                    <Typography style={{textAlign: 'center', marginBottom: '10px'}}>
                        You will receive an additional 10% value when you top up $100 or more.
                    </Typography>
                }
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>
                            E-Transfer
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center">
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                <Typography>
                                    <span style={{color: '#1D8B45', fontWeight: '600'}}>Step 1:</span> Transfer the
                                    amount to
                                    the email address below.
                                </Typography>
                                <Box style={{paddingLeft: '10px'}}>
                                    <Typography>
                                        e-Transfer to <strong>pay@uucargo.ca</strong>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                <Typography>
                                    <span style={{color: '#1D8B45', fontWeight: '600'}}>Step 2:</span> After making the
                                    top-up,
                                    you will receive an email with a reference number.
                                </Typography>
                                <Box>
                                    <InputLabel>Please input your Reference Number</InputLabel>
                                    <TextField
                                        size='small'
                                        onChange={handleReferenceNumber}
                                        sx={{width: '300px'}}
                                    />
                                </Box>
                            </Box>
                            < br/>
                            <LoadingButton
                                variant='contained'
                                onClick={handleEmailTopUp}
                                color='success'
                                loading={loading}
                                disabled={referenceNumber ? false : true}
                            >
                                Confirm
                            </LoadingButton>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>
                            Other
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center">
                            <Box>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label">Amount</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={amount}
                                        onChange={e => {
                                            setAmount(+e.target.value)
                                        }}
                                    >
                                        {/*<FormControlLabel value="female" control={<Radio />} label="Female" />*/}
                                        {/*<FormControlLabel value="male" control={<Radio />} label="Male" />*/}
                                        {/*<FormControlLabel value="other" control={<Radio />} label="Other" />*/}
                                        {
                                            (() => {
                                                if (isForAdmin) {
                                                    return (
                                                        paymentAmountArrayForAdmin.map(value => {
                                                            return (
                                                                <FormControlLabel value={+value} control={<Radio/>}
                                                                                  label={`$ ${value}`}/>
                                                            )
                                                        })
                                                    )
                                                } else {
                                                    return paymentAmountArray.map(value => {
                                                        return (
                                                            <FormControlLabel value={+value} control={<Radio/>}
                                                                              label={`$ ${value}`}/>
                                                        )
                                                    })
                                                }
                                            })()
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            < br/>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                style={{
                                    textTransform: "unset",
                                    fontSize: "1rem",
                                    width: "100%",
                                }}
                                loading={isLoading}
                                // type="submit"
                                onClick={initDownPayment}
                            >
                                Confirm
                            </LoadingButton>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>Done</Button></DialogActions>
        </BootstrapDialog>
    )
}
