import React, {FC, useEffect, useState} from "react";
import {Redirect, Route, useHistory} from "react-router-dom";
import UserSignIn from "./SignIn/userSignIn";
import {deleteToken, getAccessToken} from "../utils/doToken";
import axios from "axios";
import {deleteUserInfo, updateUserInfo} from "../slices/userSlice";
import {verifyIsPartner} from "../utils/verifyIsPartner";
import {useDispatch, useSelector} from "react-redux";
import LoadingContainer from "../shared/loadingContainer";
import {Landing} from "./Landing/Landing";
import {LandingSignIn} from "./Landing/LandingSignIn";

const PrivateRoute = props => {

    const [userIsLogged, setUserIsLogged] = useState(Boolean(getAccessToken("access_token")));
    // const [isLoading, setIsLoading] = useState(false);
    const {isLoading, isPartner} = useSelector((state) => state.user);
    const history = useHistory();
    const dispatch = useDispatch();
    console.log("[PrivateRoute] Is Partner: ", isPartner);

    const signOut = (e) => {
        const storedToken = getAccessToken("access_token");
        try {
            axios.get(`https://admin.uucargo.ca/api/logout`, {
                headers: {Authorization: `Bearer ${storedToken}`},
            });
            //setHide(true)
        } finally {
            deleteToken("access_token");
            dispatch(deleteUserInfo());
            dispatch(updateUserInfo({field: "login", value: false}));
            dispatch(updateUserInfo({field: "signIn", value: false}));
            history.push("/sign-in");
        }
    };

    // useEffect(() => {
    //   if (userIsLogged) {
    //     (async () => {
    //       try {
    //         const isValidPartner = await verifyIsPartner();
    //         if (!isValidPartner) {
    //           signOut();
    //           setUserIsLogged(false);
    //         }
    //       } catch (e) {
    //         console.log(e);
    //         signOut();
    //         setUserIsLogged(false);
    //       } finally {
    //         setIsLoading(false);
    //       }
    //     })()
    //   }
    //
    // }, [])

    // if (isLoading) {
    //   return <Route {...props}><LoadingContainer /></Route>
    // }

    // check if it has token every time
    useEffect(() => {
        const isToken = Boolean(getAccessToken("access_token"));
        setUserIsLogged(isToken);
    });

    // if not token, sign out
    useEffect(() => {
        if (userIsLogged) dispatch(updateUserInfo({field: "signIn", value: true}));
        else dispatch(updateUserInfo({field: "signIn", value: false}));
    }, [userIsLogged]);

    if (!isPartner) {
        // signOut();
        // return <Route {...props}><UserSignIn showNotValidPartner/></Route>
        return <Route {...props}><LandingSignIn/></Route>
    }

    console.log('[PrivateRoute] userIsLogged', userIsLogged)

    return (
        <Route {...props}>{userIsLogged ? props.children : <LandingSignIn/>}</Route>
        // <Route {...props}>{userIsLogged ? props.children : <UserSignIn showNotValidPartner />}</Route>
    );
};

export default PrivateRoute;