import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {extractFullAddress, getCountryFromProvince, mapServiceLogo} from "../../utils/Helper";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import JlinkLogo from "../../images/Jlink_logo.jpg";
import {Divider, useMediaQuery} from "@mui/material";
import LoadingContainer from "../../shared/loadingContainer";
import intl from "react-intl-universal";
import Button from "@mui/material/Button";

const styles = {
    LandingEstimateCostContainer: {
        margin: '50px auto'
    },
    LandingEstimateCostContentRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px 60px',
        borderRadius: '10px',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    LandingEstimateCostPackageEach: {
        height: '100px',
        // border: '1px solid red',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'

    },
    LandingEstimateCostPallet: {
        height: '50px',
    },
    LandingEstimateQueryInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#F5F5F5',
        borderRadius: '5px',
        padding: '10px'
    },
    LandingEstimateQueryInfoHeading: {
        fontSize: '20px',
        fontWeight: '600',

    },
    LandingEstimateQueryInfoTitle: {
        fontSize: '16px',
        textAlign: 'left'
    },
    LandingEstimateQueryInfoText: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left'
    },
    EstimateCostRoot: {
        margin: '50px auto'
    },
    EstimateCostPackageRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px 60px',
        borderRadius: '10px',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    EstimateCostHeading: {
        textAlign: 'left',
        // fontSize: '14px',
        padding: '20px',
        fontWeight: '600',
        '@media (max-width: 480px)': {
            padding: '0 5px',
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostText: {
        textAlign: 'left',
        // fontSize: '14px',
        padding: '20px',
        '@media (max-width: 480px)': {
            padding: 0,
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostText2: {
        textAlign: 'left',
        // fontSize: '14px',
        '@media (max-width: 480px)': {
            padding: 0,
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostPallet: {
        height: '100px',
        padding: '20px 60px 60px',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    EstimateCostImageBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 20px',
        height: '100%',
        '@media (max-width: 480px)': {
            padding: '0 5px',
        },
    },
    EstimateCostBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 20px',
        gap: '5px',
        height: '100%',
        '@media (max-width: 480px)': {
            padding: '5px 0',
        },
    },
    EstimateCostLiner: {
        '--LinearProgress-radius': '6px',
        '--LinearProgress-progressThickness': '12px',
        color: 'green',
        '@media (max-width: 480px)': {
            '--LinearProgress-progressThickness': '6px',
        },
    },
    EstimateCostButtonBox: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: '20px',
        '@media (max-width: 480px)': {
            paddingLeft: 0,
        },
    },
    EstimateCostButtonText: {
        // fontSize: '14px',
        '@media (max-width: 480px)': {
            fontSize: '10px'// for phone and above
        },
    }
}

export const LandingEstimateCost = () => {
    const isMobile = useMediaQuery('(max-width: 480px)');
    const history = useHistory();

    const [estimatePackageCost, setEstimatePackageCost] = useState([]);
    const [estimatePalletCost, setEstimatePalletCost] = useState();
    const [estimatePackageCostList, setEstimatePackageCostList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [queryInfo, setQueryInfo] = useState({});
    const [hideOptions, setHideOptions] = useState(false);
    const [packageData, setPackageData] = useState();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const queryObject = {};
    for (let [key, value] of searchParams.entries()) {
        queryObject[key] = value;
    }

    const googleMapsPlaceAutocomplete = async (inputValue) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: 'postal_code'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleQueryFromPallet = () => {
        const quantity = queryObject?.quantity < 1 ? '1' : queryObject?.quantity > 10 ? '10' : queryObject?.quantity;
        const shipFromProvince = queryObject.pickupProvince;
        const shipFromCity = queryObject.pickupCity;
        const shipToProvince = queryObject.deliverProvince;
        const shipToCity = queryObject.deliverCity;
        const type = queryObject.shipmentTypeId === 1 ? '48"x40"' : '48"x48"';
        setQueryInfo({
            shipFromProvince,
            shipFromCity,
            shipToProvince,
            shipToCity,
            quantity,
            type,
            email: queryObject.contactEmail
        })
    }

    const handleQueryFromPackage = () => {
        const shipFromProvince = queryObject.pickupProvince;
        const shipFromCountry = getCountryFromProvince(queryObject.pickupProvince);
        const shipFromPostalCode = queryObject.pickupPostalCode.replace(/\s+/g, '').toUpperCase();
        const shipToProvince = queryObject.deliverProvince;
        const shipToCountry = getCountryFromProvince(queryObject.deliverProvince);
        const shipToPostalCode = queryObject?.deliverPostalCode.replace(/\s+/g, '').toUpperCase();
        const length = queryObject?.length < 1 ? '1' : queryObject?.length;
        const width = queryObject?.width < 1 ? '1' : queryObject?.width;
        const height = queryObject?.height < 1 ? '1' : queryObject?.height;
        const weight = queryObject?.weight < 1 ? '1' : queryObject?.weight;
        const quantity = queryObject?.quantity < 1 ? '1' : queryObject?.quantity > 10 ? '10' : queryObject?.quantity;
        const lengthUnit = queryObject?.dimensionUnit;
        const weightUnit = queryObject?.weightUnit;

        setQueryInfo({
            shipFromProvince,
            shipFromCountry,
            shipFromPostalCode,
            shipToProvince,
            shipToCountry,
            shipToPostalCode,
            length,
            width,
            height,
            weight,
            quantity,
            lengthUnit,
            weightUnit,
            email: queryObject.contactEmail
        })
    }

    useEffect(() => {
        const sortedEstimateCost = estimatePackageCost.sort((current, next) => current?.totalCharge?.value - next?.totalCharge?.value);
        setEstimatePackageCostList(sortedEstimateCost);
        estimatePackageCost.length > 3 ? setHideOptions(true) : setHideOptions(false);
    }, [estimatePackageCost])

    const handleEstimateCost = estimateCostResult => {
        console.log('handle estimate', estimateCostResult)
        estimateCostResult.forEach(item => {
            if (!item.hasOwnProperty('errorMessage')) {
                setEstimatePackageCost(prevState => ([
                    ...prevState, item
                ]))
            }
        })
    }

    const partnerGetRatingByAccountBaseWithoutToken = async (accountBase, pickupAddress, deliverAddress) => {
        setIsLoading(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/rates/landing`;
        const shipFromPostalCode = queryObject.pickupPostalCode.replace(/\s+/g, '').toUpperCase();
        const shipToPostalCode = queryObject?.deliverPostalCode.replace(/\s+/g, '').toUpperCase();

        const data = {
            rateData: {
                shipFromAddress: "",
                shipFromAddressTwo: "",
                shipFromCity: "",
                shipFromProvince: pickupAddress?.state?.code,
                shipFromPostalCode: shipFromPostalCode,
                shipFromCountry: pickupAddress?.country?.code,
                shipFromName: "",
                shipFromPhone: "",
                shipToAddress: "",
                shipToAddressTwo: "",
                shipToCity: "",
                shipToPostalCode: shipToPostalCode,
                shipToProvince: deliverAddress?.state?.code,
                shipToCountry: deliverAddress?.country?.code,
                shipToName: "",
                shipToPhone: "",
                packageData: [
                    {
                        length: queryObject?.length < 1 ? '1' : queryObject?.length,
                        width: queryObject?.width < 1 ? '1' : queryObject?.width,
                        height: queryObject?.height < 1 ? '1' : queryObject?.height,
                        weight: queryObject?.weight < 1 ? '1' : queryObject?.weight,
                        quantity: queryObject?.quantity < 1 ? '1' : queryObject?.quantity > 10 ? '10' : queryObject?.quantity,
                        lengthUnit: queryObject?.dimensionUnit,
                        weightUnit: queryObject?.weightUnit
                    }
                ]
            },
            selectedAccountBase: accountBase
        }

        console.log('api data', data);

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            setIsLoading(false);

            const ratingResult = result.data.result;
            console.log(`package rate ${accountBase}`, ratingResult);
            ratingResult.length > 0 && handleEstimateCost(ratingResult);

        } catch (e) {
            setIsLoading(false);
            console.log(e.response);
        }
    }

    const partnerGetRatingByPallet = async () => {
        setIsLoading(true);
        let requestURL = `${PARTNER_URI}/shippingLTL/LTLShippingRatingForLanding`;
        const data = {
            shipperAddress: "",
            shipperAddressType: "warehouse",
            shipperCity: queryObject?.pickupCity,
            shipperProvince: queryObject?.pickupProvince,
            shipperPostalCode: "",
            shipperCountry: "",
            consigneeAddress: "",
            consigneeAddressType: "warehouse",
            consigneeCity: queryObject?.deliverCity,
            consigneeProvince: queryObject?.deliverProvince,
            consigneePostalCode: "",
            consigneeCountry: "",
            packageData: [
                {
                    length: '40',
                    height: '55',
                    width: '48',
                    quantity: queryObject?.quantity,
                    weight: "400",
                    description: "",
                    lengthUnit: "in",
                    weightUnit: "lb",
                    hscode: "",
                    originCountryCode: "",
                    currency: "",
                    priceEach: "",
                    UOM: ""
                }
            ]
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log(result.data);
            const lowestCostElement = result?.data?.reduce((minObj, obj) => {
                return obj.finalTotal < minObj.finalTotal ? obj : minObj;
            }, {finalTotal: Infinity})
            setIsLoading(false);
            setEstimatePalletCost(lowestCostElement);
            setError(false);
        } catch (e) {
            console.log(e.response);
            setIsLoading(false);
            setError(true);
        }
    }

    const addEmail = () => {
        let requestURL = `${PARTNER_URI}/collectEmail/addEmail`;
        axios.request({
            method: 'post',
            url: requestURL,
            data: {
                senderEmail: queryObject.contactEmail
            }
        }).then((response) => {
            console.log(JSON.stringify(response.data))
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(async () => {
        addEmail();
        if (queryObject?.shipmentTypeGroupId === "1") {
            await handleQueryFromPallet();
            await partnerGetRatingByPallet();
        } else {
            handleQueryFromPackage();

            const pickupPredictions = await googleMapsPlaceAutocomplete(queryObject?.pickupPostalCode);
            const deliverPredictions = await googleMapsPlaceAutocomplete(queryObject?.deliverPostalCode);
            const pickupPlaceDetails = await googleMapsPlaceDetails(pickupPredictions?.[0]?.placeId);
            const deliverPlaceDetails = await googleMapsPlaceDetails(deliverPredictions?.[0]?.placeId);
            const pickupAddress = extractFullAddress(pickupPlaceDetails);
            const deliverAddress = extractFullAddress(deliverPlaceDetails);

            setPackageData(prevState => ({
                ...prevState,
                pickupAddress,
                deliverAddress
            }))

            if (getCountryFromProvince(queryObject.pickupProvince) === getCountryFromProvince(queryObject.deliverProvince)) {
                partnerGetRatingByAccountBaseWithoutToken('ups', pickupAddress, deliverAddress);
                partnerGetRatingByAccountBaseWithoutToken('canadapost', pickupAddress, deliverAddress);
                partnerGetRatingByAccountBaseWithoutToken('fedex', pickupAddress, deliverAddress);
                partnerGetRatingByAccountBaseWithoutToken('canpar', pickupAddress, deliverAddress);
            } else {
                partnerGetRatingByAccountBaseWithoutToken('ups', pickupAddress, deliverAddress)
            }
        }
    }, [])

    return (
        <Box sx={styles.LandingEstimateCostContainer}>
            <Box sx={styles.LandingEstimateCostContentRoot}>
                <Box>
                    {
                        isLoading ? <LoadingContainer/> : (
                            queryObject?.shipmentTypeGroupId === "5" ? (
                                estimatePackageCostList.length > 0 ?
                                    <>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography sx={{fontSize: '18px', fontWeight: '600'}}>
                                                    Shipping Information
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    Dimension: {queryInfo?.length} x {queryInfo?.width} x {queryInfo?.height} {queryInfo?.dimensionUnit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>
                                                    Weight: {queryInfo?.weight} {queryInfo?.weightUnit}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>
                                                    Quantity: {queryInfo?.quantity}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={5} xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item md={6} xs={12}>
                                                        <Typography>
                                                            Pickup: {packageData?.pickupAddress?.state?.code}, {queryInfo?.shipFromPostalCode}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Typography>
                                                            Deliver: {packageData?.deliverAddress?.state?.code}, {queryInfo?.shipToPostalCode}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    {intl.get('LANDING_PAGE.CALCULATOR.CARRIER')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={isMobile ? 5 : 4}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    {intl.get('LANDING_PAGE.CALCULATOR.SERVICE_TYPE')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    {intl.get('LANDING_PAGE.CALCULATOR.DELIVERY_TIME')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    {intl.get('LANDING_PAGE.CALCULATOR.TOTAL_COST')}
                                                </Typography>
                                            </Grid>
                                            {!isMobile &&
                                                <Grid item xs={1}>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Divider/>
                                        {
                                            estimatePackageCostList?.map((item, index) => {
                                                return (
                                                    <Box key={index}
                                                         sx={{
                                                             display: `${index > 3 && hideOptions && 'none'}`,
                                                         }}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={2}>
                                                                <Box sx={styles.EstimateCostImageBox}>
                                                                    <img src={mapServiceLogo(item?.serviceName)}
                                                                         width={isMobile ? '20px' : '40px'}/>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={isMobile ? 5 : 4}>
                                                                <Box sx={styles.EstimateCostBox}>
                                                                    <Typography sx={styles.EstimateCostText}>
                                                                        {item?.serviceName}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Box sx={styles.EstimateCostBox}>
                                                                    {/*{!isMobile && <LinearProgress*/}
                                                                    {/*    determinate*/}
                                                                    {/*    value={item?.serviceType === 'express' ? '20' : getDeliverTime(item?.expectedDate) === 0 ? '30' : '50'}*/}
                                                                    {/*    sx={styles.EstimateCostLiner}*/}
                                                                    {/*/>}*/}
                                                                    <Typography sx={styles.EstimateCostText2}>
                                                                        {item?.shipRange} business day(s)
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Box sx={styles.EstimateCostBox}>
                                                                    <Typography sx={styles.EstimateCostText}>
                                                                        $ {item?.totalChargeWithTaxes.value}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            {!isMobile &&
                                                                <Grid item xs={1}>
                                                                    <Box sx={styles.EstimateCostButtonBox}>
                                                                        <Button
                                                                            variant='contained'
                                                                            onClick={() => history.push('/sign-in')}
                                                                        >
                                                                            <Typography
                                                                                sx={styles.EstimateCostButtonText}>
                                                                                {intl.get('LANDING_PAGE.CALCULATOR.SHIP')}
                                                                            </Typography>
                                                                        </Button>
                                                                    </Box>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        <Divider/>
                                                    </Box>
                                                )
                                            })
                                        }
                                        <Box>
                                            {hideOptions ? <Button onClick={() => setHideOptions(false)}>
                                                    {intl.get('LANDING_PAGE.CALCULATOR.MORE')}
                                                </Button> :
                                                <Button onClick={() => setHideOptions(true)}>
                                                    {intl.get('LANDING_PAGE.CALCULATOR.HIDE')}
                                                </Button>
                                            }
                                        </Box>
                                    </>
                                    :
                                    <Box>
                                        <Typography>
                                            The feature for estimating shipping costs is limited for guests.
                                        </Typography>
                                        <Typography>
                                            Please sign in to unlock the full range of features.
                                        </Typography>
                                    </Box>
                            ) : ((!error && estimatePalletCost?.finalTotal !== Infinity) ?
                                    <>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography sx={{fontSize: '18px', fontWeight: '600'}}>
                                                    Shipping Information
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>
                                                    Dimension: 48 x 48 x 60 in
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>
                                                    Weight: 400 lb
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>
                                                    Quantity: 1
                                                </Typography>
                                            </Grid>
                                            <Grid item md={5} xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item md={6} xs={12}>
                                                        <Typography>
                                                            Pickup: {queryInfo?.shipFromCity}, {queryInfo?.shipFromProvince}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Typography>
                                                            Deliver: {queryInfo?.shipToCity}, {queryInfo?.shipToProvince}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={isMobile ? 6 : 4}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    {intl.get('LANDING_PAGE.CALCULATOR.SERVICE_TYPE')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={isMobile ? 6 : 4}>
                                                <Typography sx={styles.EstimateCostHeading}>
                                                    {intl.get('LANDING_PAGE.CALCULATOR.TOTAL_COST')}
                                                </Typography>
                                            </Grid>
                                            {!isMobile &&
                                                <Grid item xs={4}>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Divider/>
                                        <Box>
                                            <Grid container spacing={1}>
                                                <Grid item xs={isMobile ? 6 : 4}>
                                                    <Typography sx={styles.EstimateCostText}>
                                                        Standard
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={isMobile ? 6 : 4}>
                                                    <Typography sx={styles.EstimateCostText}>
                                                        $ {estimatePalletCost?.finalTotal}
                                                    </Typography>
                                                </Grid>
                                                {!isMobile &&
                                                    <Grid item xs={4}>
                                                        <Box sx={styles.EstimateCostButtonBox}>
                                                            <Button
                                                                variant='contained'
                                                                onClick={() => history.push('/sign-in')}
                                                            >
                                                                <Typography sx={styles.EstimateCostButtonText}>
                                                                    {intl.get('LANDING_PAGE.CALCULATOR.SHIP')}
                                                                </Typography>
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Divider/>
                                        </Box>
                                    </>
                                    : <Box>
                                        <Typography>
                                            The feature for estimating shipping costs is limited for guests.
                                        </Typography>
                                        <Typography>
                                            Please sign in to unlock the full range of features.
                                        </Typography>
                                    </Box>
                            )
                        )
                    }
                </Box>
                <Typography style={{textAlign: 'right', fontSize: '12px', fontStyle: 'italic'}}>
                    {intl.get('LANDING_PAGE.CALCULATOR.TEXT_3')}
                </Typography>
            </Box>
        </Box>
    )
}