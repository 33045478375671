import React, {useState, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    AppBar,
    IconButton,
    Toolbar,
    MenuItem,
    Menu,
    Divider,
    Typography,
    makeStyles,
    Box,
    useMediaQuery,
} from "@material-ui/core";
import {AccountCircle, CloudUploadOutlined} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ListAltIcon from "@material-ui/icons/ListAlt";
import "../styles.css";
import axios from "axios";

import {getAccessToken, deleteToken} from "../utils/doToken";
import {useHistory} from "react-router-dom";
import {deleteUserInfo, updateUserInfo} from "../slices/userSlice";

import ScheduleIcon from "@mui/icons-material/Schedule";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import Button from "@mui/material/Button";
import {
    clearLandingEstimateTypeService,
} from "./Actions/landingEstimateAction";
import {
    selectShippingLTLQuickQuoteRate,
    updateShippingLTLQuickQuotePlaceOrderValidate,
} from "./Actions/shippingLTLAction";
import {alpha, useTheme} from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {ReactComponent as AccountLogo} from "../images/homepageIcon/Account.svg";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Select, styled} from "@mui/material";
import intl, {getInitOptions} from "react-intl-universal";
import Cookies from 'js-cookie';
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {PARTNER_URI} from "../utils/apiUrl";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    // logo: {
    //   flexGrow: 1,
    // },
    button: {
        borderRadius: 20,
        borderColor: "green",
        color: "green",
        fontSize: "14pt",
        flexGrow: 1,
    },
    userButton: {
        borderRadius: 20,
        borderColor: "green",
        color: "green",
        fontSize: "14pt",
        marginRight: "2vw",
    },
    hideGoBackArrow: {
        display: "none",
    },
    displayGoBackArrow: {
        display: "block",
    },
    buttonSection: {
        display: 'flex',
        gap: '10px'
    },
    '@media (min-height: 600px)': {
        '.MuiToolbar-regular': {
            minHeight: '50px',
            height: '50px'
        },
    },
}));

const styles = {
    buttonSignUp: {
        color: '#FFFFFF',
        backgroundColor: '#FDB915',
        borderRadius: '25px',
        '&:hover': {
            backgroundColor: '#FDB915',
            filter: 'brightness(0.9)'
        }
    },
    buttonSignIn: {
        color: '#1D8B45',
        backgroundColor: '#FFFFFF',
        borderRadius: '25px',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            filter: 'brightness(0.9)'
        }
    },
    buttonShippingCalculator: {
        color: '#1D8B45',
        backgroundColor: '#FFFFFF',
        borderRadius: '25px',
        '&:hover': {
            backgroundColor: '#FFFFFF',
            filter: 'brightness(0.9)'
        }
    },
}

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(5),
        minWidth: 200,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '10px 0',
        },
        '& .MuiMenuItem-root': {
            padding: '5px 30px',
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const MenuBar = ({deferredPrompt, setDeferredPrompt, openMenu, cbHandleOpen}) => {

    const theme = useTheme();

    const userInfo = useSelector((state) => state.user);

    const currentLocation = useLocation();
    const [showClassName, setShowClassName] = useState("hideGoBackArrow");
    const [anchorEl, setAnchorEl] = useState(null);
    const [oceanAnchorEl, setOceanAnchorEl] = useState(null);
    const [billingCenterEl, setBillingCenterEl] = useState(null);
    const [brokerageEl, setBrokerageEl] = useState(null);
    const [shippingLTLEL, setShippingLTLEL] = useState(null);

    const [accountAnchorEl, setAccountAnchorEl] = useState(null);
    const openAccount = Boolean(accountAnchorEl);
    const handleClickAccount = (event) => {
        setAccountAnchorEl(event.currentTarget);
    };
    const handleCloseAccount = () => {
        setAccountAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const oceanOpen = Boolean(oceanAnchorEl);
    const shippingLTLOpen = Boolean(shippingLTLEL);
    const billingCenterOpen = Boolean(billingCenterEl);
    const brokerageOpen = Boolean(brokerageEl);
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const {signIn, isPartner} = useSelector((state) => state.user);

    const matches = useMediaQuery("(max-width:800px)");
    const location = useLocation();
    const pathName = location.pathname;

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [openLanguage, setOpenLanguage] = useState(false);

    const handleLanguageDialogClose = () => {
        setOpenLanguage(false);
    };

    const handleLanguageDialogOpen = () => {
        setOpenLanguage(true);
    };

    const savePreferredLanguage = (language) => {
        const token = getAccessToken("access_token");
        let requestURI = `${PARTNER_URI}/login_middleware/savePreferredLanguage`;
        const data = {
            preferredLanguage: language
        }
        axios
            .post(requestURI, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(r => console.log('response', r))
            .catch(err => console.log('error', err))
    }

    let selectedLanguage = getInitOptions().currentLocale;

    const onLocaleChange = (e) => {
        Cookies.set('lang', e.target.value, {expires: 30});
        savePreferredLanguage(e?.target?.value);
        handleLanguageDialogOpen();
    }

    const installApp = () => {
        // Hide the app provided install promotion
        // hideInstallPromotion();
        // Show the install prompt
        if (deferredPrompt) {
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            // const { outcome } = await deferredPrompt.userChoice;
            // Optionally, send analytics event with outcome of user choice
            // console.log(`User response to the install prompt: ${outcome}`);
            // We've used the prompt, and can't use it again, throw it away
            setDeferredPrompt(null);
            handleClose();
        }
    };

    useEffect(() => {
        handleCloseAccount();
    }, [currentLocation])

    useEffect(() => {
        if (
            currentLocation.pathname === "/driver/available_shipment_tasks" ||
            currentLocation.pathname === "/driver/claimed_shipment_tasks" ||
            currentLocation.pathname === "/driver/pickup_shipment_tasks" ||
            currentLocation.pathname === "/driver/deliver_shipment_tasks" ||
            currentLocation.pathname === "/driver" ||
            currentLocation.pathname === "/sign-in" ||
            currentLocation.pathname === "/" ||
            currentLocation.pathname === "/guest-checkout" ||
            currentLocation.pathname === "/guest-order-details"
        ) {
            setShowClassName("hideGoBackArrow");
        } else {
            setShowClassName("displayGoBackArrow");
        }
    }, [currentLocation]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOceanMenu = (event) => {
        setOceanAnchorEl(event.currentTarget);
    }

    const handleShippingLTLMenu = (event) => {
        setShippingLTLEL(event.currentTarget);
    }

    const handleBillingCenterMenu = (event) => {
        setBillingCenterEl(event.currentTarget);
    }

    const handleBrokerageMenu = (event) => {
        setBrokerageEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOceanMenuClose = () => {
        setOceanAnchorEl(null);
    }

    const handleShippingLTLMenuClose = () => {
        setShippingLTLEL(null);
    }

    const handleBillingCenterMenuClose = () => {
        setBillingCenterEl(null);
    }

    const handleBrokerageMenuClose = () => {
        setBrokerageEl(null);
    }

    const redirectToProfile = () => {
        handleClose();
        let path = "/profile";
        history.push(path);
    };

    const storedToken = getAccessToken("access_token");

    // let login = {
    //     email: null,
    //     password: null
    // }

    const signOut = (e) => {
        const storedToken = getAccessToken("access_token");
        e.preventDefault();
        try {
            axios.get(`https://admin.uucargo.ca/api/logout`, {
                headers: {Authorization: `Bearer ${storedToken}`},
            });
            //setHide(true)
        } finally {
            deleteToken("access_token");
            dispatch(deleteUserInfo());
            dispatch(updateUserInfo({field: "login", value: false}));
            dispatch(updateUserInfo({field: "signIn", value: false}));
            handleClose();
            history.push("/sign-in");
        }
    };

    const handleGoBack = (event) => {
        history.goBack();
    };

    let brokerageMenu = (
        <Menu
            id="menu-appbar"
            anchorEl={brokerageEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={brokerageOpen}
            onClose={handleBrokerageMenuClose}
            style={{width: "500px", textAlign: "center", zIndex: 5001}}
        >
            {matches && (
                <div>
                    <MenuItem
                        onClick={() => {
                            history.replace("/brokerage/estimate");
                            handleBrokerageMenuClose();
                        }}
                    >
                        Estimate Cost
                    </MenuItem>
                </div>
            )}
            <MenuItem
                onClick={() => {
                    history.replace("/brokerage/estimate");
                    handleBrokerageMenuClose();
                }}
            >
                Estimate Cost
            </MenuItem>
        </Menu>
    )

    let billingCenterMenu = (
        <Menu
            id="menu-appbar"
            anchorEl={billingCenterEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={billingCenterOpen}
            onClose={handleBillingCenterMenuClose}
            style={{width: "500px", textAlign: "center", zIndex: 5001}}
        >
            {matches && (
                <div>
                    <MenuItem
                        onClick={() => {
                            history.replace("/billing-center");
                            handleBillingCenterMenuClose();
                        }}
                    >
                        My Billing Center
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/billing-center/top-up");
                            handleBillingCenterMenuClose();
                        }}
                    >
                        Top Up
                    </MenuItem>
                </div>
            )}
            <MenuItem
                onClick={() => {
                    history.replace("/billing-center");
                    handleBillingCenterMenuClose();
                }}
            >
                My Billing Center
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.replace("/billing-center/top-up");
                    handleBillingCenterMenuClose();
                }}
            >
                Top Up
            </MenuItem>
        </Menu>
    )

    let shippingLTLMenu = (
        <Menu
            id="menu-appbar"
            anchorEl={shippingLTLEL}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={shippingLTLOpen}
            onClose={handleShippingLTLMenuClose}
            style={{width: "500px", textAlign: "center", zIndex: 5001}}
        >
            {matches && (
                <div>
                    {/*<MenuItem*/}
                    {/*    onClick={() => {*/}
                    {/*        history.replace("/shippingLTL/shipping-item");*/}
                    {/*        handleShippingLTLMenuClose();*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Shipping LTL*/}
                    {/*</MenuItem>*/}
                    <MenuItem
                        onClick={() => {
                            dispatch(updateShippingLTLQuickQuotePlaceOrderValidate(null));
                            dispatch(selectShippingLTLQuickQuoteRate(null));
                            history.replace("/LTL-quick-quote");
                            handleShippingLTLMenuClose();

                        }}
                    >
                        LTL Quick-Quote
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/myLTLQuote")
                        }}
                    >
                        My LTL Quote
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/myLTLs")
                        }}
                    >
                        My LTL Orders
                    </MenuItem>
                </div>
            )}
            {/*<MenuItem*/}
            {/*    onClick={() => {*/}
            {/*        history.replace("/shippingLTL/shipping-item");*/}
            {/*        handleShippingLTLMenuClose();*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Shipping LTL*/}
            {/*</MenuItem>*/}
            <MenuItem
                onClick={() => {
                    dispatch(updateShippingLTLQuickQuotePlaceOrderValidate(null));
                    dispatch(selectShippingLTLQuickQuoteRate(null));
                    history.replace("/LTL-quick-quote");
                    handleShippingLTLMenuClose();
                }}
            >
                LTL Quick-Quote
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.replace("/myLTLQuote")
                }}
            >
                My LTL Quote
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.replace("/myLTLs")
                }}
            >
                My LTL Orders
            </MenuItem>
        </Menu>
    )

    let oceanMenu = (
        <Menu
            id="menu-appbar"
            anchorEl={oceanAnchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={oceanOpen}
            onClose={handleOceanMenuClose}
            style={{width: "500px", textAlign: "center", zIndex: 5001}}
        >
            {matches && (
                <div>
                    <MenuItem
                        onClick={() => {
                            history.replace("/ocean-shipping");
                            handleOceanMenuClose();
                        }}
                    >
                        Ocean Shipping
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/ocean-bookings");
                            handleOceanMenuClose();
                        }}
                    >
                        My Bookings
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/freight-forwarding/bol-list/created");
                            handleOceanMenuClose();
                        }}
                    >
                        My BOLs
                    </MenuItem>
                </div>
            )}
            <MenuItem
                onClick={() => {
                    history.replace("/ocean-shipping");
                    handleOceanMenuClose();
                }}
            >
                Ocean Shipping
            </MenuItem>
            {/*<MenuItem*/}
            {/*    onClick={() => {*/}
            {/*        history.replace("/ocean-bookings");*/}
            {/*        handleOceanMenuClose();*/}
            {/*    }}*/}
            {/*>*/}
            {/*    My Bookings*/}
            {/*</MenuItem>*/}
            {/*<MenuItem*/}
            {/*    onClick={() => {*/}
            {/*        history.replace("/freight-forwarding/bol-list/created");*/}
            {/*        handleOceanMenuClose();*/}
            {/*    }}*/}
            {/*>*/}
            {/*    My BOLs*/}
            {/*</MenuItem>*/}
        </Menu>
    )

    let menu = (
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            style={{width: "500px", textAlign: "center", zIndex: 5001}}
        >
            {matches && (
                <div>
                    <MenuItem
                        onClick={() => {
                            history.replace("/shipping-package/quick-quote");
                            handleClose();
                        }}
                    >
                        Quick Quote
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/shipping-package/create-label");
                            handleClose();
                        }}
                    >
                        Create Label
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/shipping-package/rating-buy/excel");
                            handleClose();
                        }}
                    >
                        Upload Excel
                    </MenuItem>
                    {/*<MenuItem*/}
                    {/*    onClick={() => {*/}
                    {/*      history.replace("/loose-item/3rd-party/shipping/list/product/");*/}
                    {/*      handleClose();*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*  Tracking Numbers*/}
                    {/*</MenuItem>*/}
                    <MenuItem
                        onClick={() => {
                            history.replace("/loose-item/3rd-party/shipping/searchTransactions");
                            handleClose();
                        }}
                    >
                        Search Tracking
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/loose-item/3rd-party/shipping/order");
                            handleClose();
                        }}
                    >
                        My Shipping Order
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/loose-item/3rd-party/shipping/exportsTransactions");
                            handleClose();
                        }}
                    >
                        Exports Transactions
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/schedulepickup");
                            handleClose();
                        }}
                    >
                        Schedule Pickup Appointment
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/MyScheduledPickups");
                            handleClose();
                        }}
                    >
                        Schedule List
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.replace("/tracking");
                            handleClose();
                        }}
                    >
                        Tracking Package
                    </MenuItem>
                    <Divider/>
                </div>
            )}
            {/*<MenuItem onClick={signOut}>Sign Out</MenuItem>*/}
            <MenuItem
                onClick={() => {
                    history.replace("/shipping-package/quick-quote");
                    handleClose();
                }}
            >
                Quick Quote
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.replace("/shipping-package/create-label");
                    handleClose();
                }}
            >
                Create Label
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.replace("/shipping-package/rating-buy/excel");
                    handleClose();
                }}
            >
                Upload Excel
            </MenuItem>
            {/*<MenuItem*/}
            {/*    onClick={() => {*/}
            {/*      history.replace("/loose-item/3rd-party/shipping/list/product/");*/}
            {/*      handleClose();*/}
            {/*    }}*/}
            {/*>*/}
            {/*  Tracking Numbers*/}
            {/*</MenuItem>*/}
            <MenuItem
                onClick={() => {
                    history.replace("/loose-item/3rd-party/shipping/searchTransactions");
                    handleClose();
                }}
            >
                Search Tracking
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.replace("/loose-item/3rd-party/shipping/order");
                    handleClose();
                }}
            >
                My Shipping Order
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.replace("/loose-item/3rd-party/shipping/exportsTransactions");
                    handleClose();
                }}
            >
                Exports Transactions
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.replace("/schedulepickup");
                    handleClose();
                }}
            >
                Schedule Pickup Appointment
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.replace("/MyScheduledPickups");
                    handleClose();
                }}
            >
                Schedule List
            </MenuItem>
            <MenuItem
                onClick={() => {
                    history.replace("/tracking");
                    handleClose();
                }}
            >
                Tracking Package
            </MenuItem>
        </Menu>
    );

    if (!storedToken)
        menu = (
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
                style={{width: "500px", textAlign: "center"}}
            >
                {/*{matches && (*/}
                {/*    <MenuItem*/}
                {/*        onClick={() => {*/}
                {/*          window.open("https://app.uucargo.ca/sign-up");*/}
                {/*          handleClose();*/}
                {/*        }}*/}
                {/*    >*/}
                {/*      Sign Up*/}
                {/*    </MenuItem>*/}
                {/*)}*/}
                {/*{matches && (*/}
                {/*    <MenuItem*/}
                {/*        onClick={() => {*/}
                {/*          history.push("/sign-in");*/}
                {/*          handleClose();*/}
                {/*        }}*/}
                {/*    >*/}
                {/*      Sign In*/}
                {/*    </MenuItem>*/}
                {/*)}*/}
                {matches && <Divider/>}
            </Menu>
        );

    let linkList = (
        <>
            <IconButton
                aria-label="Load Shipments"
                aria-haspopup="true"
                onClick={() => history.replace("/shipping/shipping-item")}
                color="inherit"
                className={classes.button}
            >
                <LocalShippingIcon/>
                <Typography variant="subtitle2">Rating & Shipping</Typography>
            </IconButton>
            <IconButton
                aria-label="Load Shipments"
                aria-haspopup="true"
                onClick={() => history.replace("/shipping-package/rating-buy/excel")}
                color="inherit"
                className={classes.button}
            >
                <CloudUploadOutlined/>
                <Typography variant="subtitle2">Upload Excel</Typography>
            </IconButton>
            {/*<IconButton*/}
            {/*    aria-label="Search Shipping Tasks"*/}
            {/*    aria-haspopup="true"*/}
            {/*    onClick={() => history.replace("/loose-item/3rd-party/shipping/list/product/")}*/}
            {/*    color="inherit"*/}
            {/*    className={classes.button}*/}
            {/*>*/}
            {/*  <SearchIcon />*/}
            {/*  <Typography variant="subtitle2">Tracking Numbers</Typography>*/}
            {/*</IconButton>*/}
            <IconButton
                aria-label="Shipments Overview"
                aria-haspopup="true"
                onClick={() => history.replace("/loose-item/3rd-party/shipping/searchTransactions")}
                color="inherit"
                className={classes.button}
            >
                <SearchIcon/>
                <Typography variant="subtitle2">Search Tracking</Typography>
            </IconButton>
            <IconButton
                aria-label="Shipments Overview"
                aria-haspopup="true"
                onClick={() => history.replace("/loose-item/3rd-party/shipping/order")}
                color="inherit"
                className={classes.button}
            >
                <ListAltIcon/>
                <Typography variant="subtitle2">My Shipping Order</Typography>
            </IconButton>
            <IconButton
                aria-label="Shipments Overview"
                aria-haspopup="true"
                onClick={() => history.replace("/loose-item/3rd-party/shipping/exportsTransactions")}
                color="inherit"
                className={classes.button}
            >
                <ListAltIcon/>
                <Typography variant="subtitle2">Exports Transactions</Typography>
            </IconButton>
            <IconButton
                aria-label="Shipments Overview"
                aria-haspopup="true"
                onClick={() => history.replace("/loose-item/3rd-party/shipping/exportsTransactions")}
                color="inherit"
                className={classes.button}
            >
                <ListAltIcon/>
                <Typography variant="subtitle2"> Exports Transactions</Typography>
            </IconButton>
            <IconButton
                aria-label="Shipments Overview"
                aria-haspopup="true"
                onClick={() => history.replace("/schedulepickup")}
                color="inherit"
                className={classes.button}
            >
                <ScheduleIcon/>
                <Typography variant="subtitle2">Schedule Pickup Appointment</Typography>
            </IconButton>
            <IconButton
                aria-label="Shipments Overview"
                aria-haspopup="true"
                onClick={() => history.replace("/MyScheduledPickups")}
                color="inherit"
                className={classes.button}
            >
                <ScheduleSendIcon/>
                <Typography variant="subtitle2">Schedule List</Typography>
            </IconButton>
            <IconButton
                aria-label="Shipments Overview"
                aria-haspopup="true"
                onClick={() => history.replace("/tracking")}
                color="inherit"
                className={classes.button}
            >
                <LocationSearchingIcon/>
                <Typography variant="subtitle2">Tracking Package</Typography>
            </IconButton>
        </>
    );

    if (!storedToken)
        linkList = (
            <Box>
                {/*<IconButton*/}
                {/*    aria-label="Sign Up"*/}
                {/*    aria-haspopup="true"*/}
                {/*    onClick={() => {*/}
                {/*      window.open("https://app.uucargo.ca/sign-up");*/}
                {/*    }}*/}
                {/*    color="inherit"*/}
                {/*    className={classes.userButton}*/}
                {/*>*/}
                {/*  <Typography variant="subtitle2">Sign Up</Typography>*/}
                {/*</IconButton>*/}
                {/*<IconButton*/}
                {/*    aria-label="Sign In"*/}
                {/*    aria-haspopup="true"*/}
                {/*    onClick={() => history.push("/sign-in")}*/}
                {/*    color="inherit"*/}
                {/*    className={classes.userButton}*/}
                {/*>*/}
                {/*  <Typography variant="subtitle2">Sign In</Typography>*/}
                {/*</IconButton>*/}
            </Box>
        );

    console.log('is sign in', signIn);
    console.log('is partner', isPartner);
    console.log('[MenuBar] pathname', pathName);

    return (
        <AppBar
            position="fixed"
            style={{background: "#1D8B45", display: "flex", bottom: "auto", top: "0", margin: "0",}}
        >
            <Toolbar
                style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    {
                        isMobile && <IconButton onClick={cbHandleOpen}>
                            {openMenu ? <CloseIcon sx={{color: '#FFFFFF'}}/> : <MenuIcon sx={{color: '#FFFFFF'}}/>}
                        </IconButton>
                    }
                    <Link className={classes.logo} to="/">
                        <img
                            style={{height: "40px", padding: "5px 5px"}}
                            src={isMobile ? 'https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoIcon.png' : 'https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.svg'}
                            // src={logo}
                            onClick={() => {
                                console.log("Hello!")
                            }}
                            alt="useAvatar"
                        />
                    </Link>
                </Box>
                <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <FormControl>
                        {/*<InputLabel sx={{color: '#FFFFFF'}}>{intl.get('LANGUAGE')}</InputLabel>*/}
                        <Select
                            value={selectedLanguage}
                            size='small'
                            sx={{
                                fontSize: 14,
                                // backgroundColor: '#FFFFFF'
                                color: '#FFFFFF'
                            }}
                            onChange={onLocaleChange}
                            // label="Language"
                            // label={intl.get('LANGUAGE')}
                        >
                            <MenuItem value='en-US'>English</MenuItem>
                            <MenuItem value='zh-CN'>中文(简体)</MenuItem>
                            <MenuItem value='zh-TW'>中文(繁体)</MenuItem>
                        </Select>
                    </FormControl>
                    {(signIn && isPartner) ?
                        <Box display="flex" justifyContent="center" alignItems="center"
                             sx={{
                                 gap: "0.3rem", marginRight: "2rem", position: 'relative'
                             }}>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                // onClick={redirectToProfile}
                                onClick={handleClickAccount}
                            >
                                <AccountLogo width="30" height="30"/>
                                {openAccount ? <ExpandLessIcon sx={{color: '#FFFFFF'}}/> :
                                    <ExpandMoreIcon sx={{color: '#FFFFFF'}}/>}
                            </IconButton>
                            <StyledMenu
                                anchorEl={accountAnchorEl}
                                open={openAccount}
                                onClose={handleCloseAccount}
                                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                PaperProps={{
                                    sx: {
                                        top: '50px'
                                    }
                                }}
                            >
                                {/*<MenuItem>*/}
                                <Typography style={{
                                    color: '#454545',
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    textAlign: 'left',
                                    padding: '0 30px',
                                }}>
                                    {userInfo?.firstname} {userInfo?.lastname}
                                </Typography>
                                {/*</MenuItem>*/}
                                {/*<MenuItem>*/}
                                <Typography style={{
                                    color: '#B2B2B2',
                                    fontSize: '14px',
                                    textAlign: 'left',
                                    padding: '0 30px',
                                }}>
                                    {userInfo?.email}
                                </Typography>
                                {/*</MenuItem>*/}
                                <Box sx={{
                                    width: '95%',
                                    margin: '10px auto'
                                }}>
                                    <Divider/>
                                </Box>
                                {/*<MenuItem*/}
                                {/*    onClick={() => {*/}
                                {/*        history.replace("/profile");*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <Typography style={{*/}
                                {/*        color: '#454545',*/}
                                {/*        fontSize: '14px',*/}
                                {/*        // fontWeight: '600'*/}
                                {/*    }}>*/}
                                {/*        {intl.get('NAVBAR_PROFILE')}*/}
                                {/*    </Typography>*/}
                                {/*</MenuItem>*/}
                                {/*<MenuItem*/}
                                {/*    onClick={() => {*/}
                                {/*        history.replace("/settings/address-list");*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <Typography style={{*/}
                                {/*        color: '#454545',*/}
                                {/*        fontSize: '14px',*/}
                                {/*        // fontWeight: '600'*/}
                                {/*    }}>*/}
                                {/*        {intl.get('NAVBAR_ADDRESS_BOOK')}*/}
                                {/*    </Typography>*/}
                                {/*</MenuItem>*/}
                                {/*<MenuItem*/}
                                {/*    onClick={() => {*/}
                                {/*        history.replace("/billing-center");*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <Typography style={{*/}
                                {/*        color: '#454545',*/}
                                {/*        fontSize: '14px',*/}
                                {/*        // fontWeight: '600'*/}
                                {/*    }}>*/}
                                {/*        {intl.get('NAVBAR_BILLING')}*/}
                                {/*    </Typography>*/}
                                {/*</MenuItem>*/}
                                <MenuItem
                                    onClick={installApp}
                                >
                                    <Typography style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                    }}>
                                        Add To Home Screen
                                    </Typography>
                                </MenuItem>
                                <Box sx={{
                                    width: '95%',
                                    margin: '10px auto'
                                }}>
                                    <Divider/>
                                </Box>
                                <MenuItem
                                    onClick={signOut}
                                >
                                    <Typography style={{
                                        color: '#454545',
                                        fontSize: '14px',
                                        // fontWeight: '600'
                                    }}>
                                        {intl.get('NAVBAR_LOGOUT')}
                                    </Typography>
                                </MenuItem>
                            </StyledMenu>
                        </Box> :
                        <Box display="flex" justifyContent="center" alignItems="center"
                             sx={{gap: "0.3rem", marginRight: "2rem"}}>
                            {(pathName === '/shipping-rate-calculator' || pathName === '/landing-estimate/') ?
                                <Box className={classes.buttonSection}>
                                    <Button
                                        variant='contained'
                                        sx={styles.buttonSignUp}
                                        onClick={() => history.push('/sign-up')}
                                    >
                                        <Typography style={{fontSize: isMobile ? '10px' : '16px'}}>
                                            {intl.get('NAVBAR_SIGN_UP')}
                                        </Typography>
                                    </Button>
                                    <Button
                                        variant='contained'
                                        sx={styles.buttonSignIn}
                                        onClick={() => history.push('/sign-in')}
                                    >
                                        <Typography style={{fontSize: isMobile ? '10px' : '16px'}}>
                                            {intl.get('NAVBAR_SIGN_IN')}
                                        </Typography>
                                    </Button>
                                </Box>

                                : <Button
                                    variant='contained'
                                    sx={styles.buttonShippingCalculator}
                                    onClick={() => {
                                        history.push('/shipping-rate-calculator');
                                        dispatch(clearLandingEstimateTypeService());
                                    }}
                                >
                                    <Typography style={{fontSize: isMobile ? '10px' : '16px'}}>
                                        {intl.get('NAVBAR_SHIPPING_RATE_CALCULATOR')}
                                    </Typography>
                                </Button>
                            }
                        </Box>
                    }
                </Box>
            </Toolbar>
            <Dialog
                open={openLanguage}
                onClose={handleLanguageDialogClose}
            >
                <DialogTitle>
                    {intl.get('NAVBAR_LANGUAGE_TITLE')}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography>
                            {intl.get('NAVBAR_LANGUAGE_CONTENT')}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleLanguageDialogClose}
                    >
                        {intl.get('NAVBAR_LANGUAGE_NO')}
                    </Button>
                    <Button
                        onClick={() => window.location.reload()}
                    >
                        {intl.get('NAVBAR_LANGUAGE_CONFIRM')}
                    </Button>
                </DialogActions>
            </Dialog>
        </AppBar>
    );
};

export default MenuBar;
