import React from "react";
import { makeStyles } from "@material-ui/core";
import {Link} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function BolListTableLanding({ bolId, bolNumber, shipperName, shipperAddress, etd, eta, voyage, consigneeName, consigneeAddress }) {
  const classes = useStyles();
  return (
      <>
        <tr className={classes.listItemWrapper}>
          <td>
            <span>
              <Link to={`/freight-forwarding/hs-code?bol_id=${bolId}`}>{bolId} / {bolNumber}</Link>
            </span>
          </td>
          <td>
            {shipperName}
            <br />
            {shipperAddress}
          </td>
          <td>
            {consigneeName}
            <br />
            {consigneeAddress}
          </td>
          <td>
            {etd} / {eta}
          </td>
          <td>{voyage} days</td>
        </tr>
      </>
  );
}
