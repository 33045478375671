import React, {useEffect} from "react";
import {Box, Button, Typography} from "@material-ui/core";

import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import {format} from "date-fns";

import {ordinal_suffix_of} from "../../../utils/addOrdinalSuffix";
// import { shipmentTypesDetails } from "../../shared/constInfo";
import {shipmentTypesDetails} from "../../../shared/constInfo";
import CurrencyFormat from "react-currency-format";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//getPickUpEmptyStatus(pickUpDetails)

const findUnit = (labelId, fieldName) => {
    const itemUnit = shipmentTypesDetails[labelId];
    return itemUnit ? itemUnit[fieldName] : "";
};

const ReviewOrderHeader = ({
                               pickupEmpty,
                               deliverEmpty,
                               toFirst,
                               toFourth,
                               pickUpDetails,
                               pallets,
                               accountBaseCombinedName,
                               priceText,
                               priceInfo,
                               selectedLooseItem,
                               toFifth,
                               serviceName
                           }) => {
    const classes = useStyles();

    useEffect(() => {
        console.log("price info", priceInfo);
    }, [priceInfo]);

    return (
        <>
            <div className="subtitle">
                <Box pt={1} width="100%">
                    {pickupEmpty ? (
                        <Alert severity="error" classes={{root: classes.alertRoot}}>
                            Please enter all the pick up address information.
                        </Alert>
                    ) : (
                        ""
                    )}
                </Box>
                {/*<Box display="flex" justifyContent="space-between">*/}
                {/*  <Box className={classes.summaryHeader}>Account</Box>*/}
                {/*  <Box fontWeight="400" className="bodytext">*/}
                {/*    <Button*/}
                {/*        color="primary"*/}
                {/*        onClick={toFourth}*/}
                {/*        style={{ color: "seagreen" }}*/}
                {/*    >*/}
                {/*      Edit*/}
                {/*    </Button>*/}
                {/*  </Box>*/}
                {/*</Box>*/}
                {/*<Box className={classes.summaryBodyText} mb={2}>{accountBaseCombinedName}</Box>*/}
                <Box display="flex" justifyContent="space-between">
                    <Box className={classes.summaryHeader}>Pickup</Box>
                    <Box fontWeight="400" className="bodytext">
                        <Button
                            color="primary"
                            onClick={toFourth}
                            style={{color: "seagreen"}}
                        >
                            Edit
                        </Button>
                    </Box>
                </Box>
                <Box className={classes.summaryBodyText} mb={2}>
                    Name: {pickUpDetails.pickUp.name}
                    <br/>
                    Phone number: {pickUpDetails.pickUp.phone}
                    <br/>
                    Email address: {pickUpDetails.pickUp.email}
                    <br/>
                    {`${pickUpDetails.pickUp.address}`}, {pickUpDetails.pickUp?.aptInfo} <br/>
                    {`${pickUpDetails.pickUp.city}, ${pickUpDetails.pickUp.province} ${pickUpDetails.pickUp.zipCode}`}{" "}
                    <br/>
                    Requested for {format(pickUpDetails.selectedDate, "dd/MM/yyyy")}
                </Box>
            </div>
            <div className="subtitle">
                <Box pt={1} width="100%">
                    {deliverEmpty ? (
                        <Alert severity="error" classes={{root: classes.alertRoot}}>
                            Please enter all the deliver address information.
                        </Alert>
                    ) : (
                        ""
                    )}
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Box className={classes.summaryHeader}>Delivery</Box>
                    <Box fontWeight="400" className="bodytext">
                        <Button
                            color="primary"
                            onClick={toFourth}
                            style={{color: "seagreen"}}
                        >
                            Edit
                        </Button>
                    </Box>
                </Box>
                <Box className={classes.summaryBodyText} mb={2}>
                    Name: {pickUpDetails.deliver.name}
                    <br/>
                    Phone number: {pickUpDetails.deliver.phone}
                    <br/>
                    Email address: {pickUpDetails.deliver.email}
                    <br/>
                    {`${pickUpDetails.deliver.address}`}, {pickUpDetails.deliver.aptInfo} <br/>
                    {`${pickUpDetails.deliver.city}, ${pickUpDetails.deliver.province} ${pickUpDetails.deliver.zipCode}`}
                </Box>
            </div>
            <div className="bodytext"></div>
            <div className="subtitle">
                <Box display="flex" justifyContent="space-between">
                    <Box className={classes.summaryHeader}>Items</Box>
                    <Box fontWeight="400" className="bodytext">
                        <Button
                            color="primary"
                            onClick={toFirst}
                            style={{color: "seagreen"}}
                        >
                            Edit
                        </Button>
                    </Box>
                </Box>
                <Box className={classes.summaryBodyText} mb={1}>
                    {pallets.map((item, key) => {
                        return (
                            <div key={key}>
                                <p>
                                    <Box component="span" fontWeight="700">
                                        {ordinal_suffix_of(key + 1)} Package:
                                    </Box>{" "}
                                    <Box component="span" fontWeight="400">
                                        {/*{item.description} - {item.quantity}{" "}*/}
                                        {/*{findUnit(item.shipmentType, "label")}(s) &times;{" "}*/}
                                        {/*{item.weight} {findUnit(item.shipmentType, "weightUnit")}*/}
                                        {item.description} - {item.weight}{item.weightUnit} &times; {item.looseUnit}
                                    </Box>
                                </p>
                            </div>
                        );
                    })}
                </Box>
            </div>
            <Box>
                {selectedLooseItem ? (
                    <Box>
                        <Box>
                            <Box display="flex" justifyContent="space-between">
                                <Box className={classes.summaryHeader}>
                                    <b>Service</b>
                                </Box>
                                <Button
                                    color="primary"
                                    onClick={toFifth}
                                    style={{color: "seagreen"}}
                                >
                                    Edit
                                </Button>
                            </Box>
                        </Box>
                        <Box className={classes.summaryBodyText}>
                            {serviceName?.toUpperCase()}
                            {/*{companyName}*/}
                            {/*<ul>*/}
                            {/*  <li>*/}
                            {/*    <h6>{serviceName?.toUpperCase()}</h6>*/}
                            {/*  </li>*/}
                            {/*</ul>*/}
                        </Box>
                    </Box>
                ) : (
                    <Box></Box>
                )}
            </Box>
            <br/>
            <Box display="flex" justifyContent="space-between">
                <Box className={classes.summaryHeader}>Estimate Shipping Fee</Box>
            </Box>
            <Box className={classes.summaryBodyText} mb={2} mt={1} textAlign="right">
                {/*{priceText ? <CurrencyFormat value={(priceText)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : "N/A"}*/}
                Shipping Fee: <CurrencyFormat value={(priceInfo?.totalCharge?.value)} displayType={'text'}
                                              thousandSeparator={true} prefix={'$'}/>
                <Accordion className={classes.accordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            backgroundColor: "gray"
                        }}
                    >
                        <Typography>
                            Price Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        textAlign: "right"
                    }}>
                        <Typography sx={{textAlign: "right"}}>
                            <ul style={{"textAlign": "right",}}>
                                {
                                    priceInfo.shipmentCharges.filter(v => v?.name || v?.description)?.map(v => {
                                            return (
                                                <li style={{
                                                    "textAlign": "right",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    padding: 0,
                                                    marginTop: "0.2rem"
                                                }}>
                                                    <span>{v.description}: <b><CurrencyFormat value={+v.value || 0}
                                                                                              displayType={'text'}
                                                                                              thousandSeparator={true}
                                                                                              prefix={'$'}/></b></span>
                                                </li>
                                            )
                                        }
                                    )
                                }
                                {
                                    priceInfo.taxes?.map(v => {
                                            return (
                                                <li style={{
                                                    "textAlign": "right",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    padding: 0,
                                                    marginTop: "0.2rem"
                                                }}>
                                                    <span>{v.name}: <b><CurrencyFormat value={+v.value || 0}
                                                                                       displayType={'text'}
                                                                                       thousandSeparator={true}
                                                                                       prefix={'$'}/></b></span>
                                                </li>
                                            )
                                        }
                                    )
                                }
                                <li style={{
                                    "textAlign": "right",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: 0,
                                    marginTop: "0.2rem"
                                }}>
                                    <span>Service Fee: <b><CurrencyFormat value={+priceInfo.serviceFee || 0}
                                                                          displayType={'text'} thousandSeparator={true}
                                                                          prefix={'$'}/></b></span>
                                </li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
            {
                priceInfo?.crossBoarder?.grandTotal !== 0 ? (
                    <>
                        {/*<Box display="flex" justifyContent="space-between">*/}
                        {/*  <Box className={classes.summaryHeader}>Cross Boarder Fee</Box>*/}
                        {/*</Box>*/}
                        {/*<Box className={classes.summaryBodyText} mb={2} mt={1}>*/}
                        {/*  {priceText ? <CurrencyFormat value={(priceText)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : "N/A"}*/}
                        {/*</Box>*/}
                        <Box display="flex" justifyContent="space-between">
                            <Box className={classes.summaryHeader}>
                                <b>Cross Boarder Fee</b>
                            </Box>
                        </Box>
                        <Box className={classes.summaryBodyText} textAlign="right">
                            Total Cross Boarder Fee: <CurrencyFormat value={priceInfo.crossBoarder?.grandTotal || 0}
                                                                     displayType={'text'} thousandSeparator={true}
                                                                     prefix={'$'}/>
                            <ul>
                                <li style={{
                                    "textAlign": "right",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: 0,
                                    marginTop: "0.2rem"
                                }}>
                                    <span>Brokerage Fees: <b><CurrencyFormat
                                        value={priceInfo.crossBoarder?.totalBrokerageFees || 0} displayType={'text'}
                                        thousandSeparator={true} prefix={'$'}/></b></span>
                                </li>
                                <li style={{
                                    "textAlign": "right",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: 0,
                                    marginTop: "0.2rem"
                                }}>
                                    <span>Total Duty and Tax: <b><CurrencyFormat
                                        value={priceInfo.crossBoarder?.totalDutyandTax || 0} displayType={'text'}
                                        thousandSeparator={true} prefix={'$'}/></b></span>
                                </li>
                            </ul>
                        </Box>
                    </>
                ) : <></>
            }
            <Box display="flex" justifyContent="space-between">
                <Box className={classes.summaryHeader}>Total</Box>
            </Box>
            <Box className={classes.summaryBodyText} mb={2} mt={1} textAlign="right">
                Total Charge: <b><CurrencyFormat
                value={(+(priceInfo?.totalChargeWithTaxes?.value || priceInfo?.totalCharge?.value) + +priceInfo.crossBoarder?.grandTotal + +priceInfo.serviceFee).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={'$'}/></b>
            </Box>

        </>
    );
};

const useStyles = makeStyles((theme) => ({
    alertRoot: {
        width: "80%",
        minWidth: "200px",
        margin: "10px auto",
    },
    summaryBodyText: {
        fontSize: "1.1rem",
        color: "rgba(0,0,0,0.8)",
        fontFamily: "Montserrat",
        fontWeight: "400",
    },
    summaryHeader: {
        fontSize: "16pt",
        color: "rgb(141, 173, 155)",
        fontFamily: "Montserrat",
        // borderBottom: '1px, solid rgb(141, 173, 155)',
    },
    accordion: {
        width: "100%",
        backgroundColor: "inherit"
    },
}));

ReviewOrderHeader.propTypes = {
    pickupEmpty: PropTypes.bool.isRequired,
    toFirst: PropTypes.func.isRequired,
    pickUpDetails: PropTypes.object.isRequired,
    toFourth: PropTypes.func.isRequired,
    pallets: PropTypes.array.isRequired,
};

export default ReviewOrderHeader;
