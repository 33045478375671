import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {movingOrderStatus, movingShippingStatus} from "../../utils/Helper";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";

export const TransportationAvailableTasksSummary = ({taskDetails}) => {

    const [pickupDistance, setPickupDistance] = useState(0);
    const [deliverDistance, setDeliverDistance] = useState(0);

    const getPickupDistanceBetweenTwoPlace = async (shipFromAddress, shipToAddress) => {
        let requestURL = `${PARTNER_URI}/googleMaps/getDistanceBetweenTwoPlace`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    shipFromAddress: shipFromAddress,
                    shipToAddress: shipToAddress
                }
            });
            console.log('[TransportationClaimTasksSummary] getDistanceBetweenTwoPlace api - result', result?.data?.rows?.[0]?.elements?.[0]);
            setPickupDistance(result?.data?.rows?.[0]?.elements?.[0]?.distance?.text);
            const distance = result;
        } catch (e) {
            console.log('error', e?.response);
        }
    }

    const getDeliverDistanceBetweenTwoPlace = async (shipFromAddress, shipToAddress) => {
        let requestURL = `${PARTNER_URI}/googleMaps/getDistanceBetweenTwoPlace`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    shipFromAddress: shipFromAddress,
                    shipToAddress: shipToAddress
                }
            });
            console.log('[TransportationClaimTasksSummary] getDistanceBetweenTwoPlace api - result', result);
            setDeliverDistance(result?.data?.rows?.[0]?.elements?.[0]?.distance?.text);
        } catch (e) {
            console.log('error', e?.response);
        }
    }

    useEffect(() => {
        if (taskDetails) {
            const pickupAddress = `${taskDetails?.pickup_address}, ${taskDetails?.pickup_area}, ${taskDetails?.pickup_postal_code}`;
            const pickupWarehouseAddress = `${taskDetails?.pickup_warehouse?.address}, ${taskDetails?.pickup_warehouse?.city}, ${taskDetails?.pickup_warehouse?.province}, ${taskDetails?.pickup_warehouse?.postal_code}`;
            const deliverAddress = `${taskDetails?.deliver_address}, ${taskDetails?.deliver_area}, ${taskDetails?.deliver_postal_code}`
            const deliverWarehouseAddress = `${taskDetails?.deliver_warehouse?.address}, ${taskDetails?.deliver_warehouse?.city}, ${taskDetails?.deliver_warehouse?.province}, ${taskDetails?.deliver_warehouse?.postal_code}`;
            getPickupDistanceBetweenTwoPlace(pickupAddress, pickupWarehouseAddress);
            getDeliverDistanceBetweenTwoPlace(deliverAddress, deliverWarehouseAddress);
        }
    }, [taskDetails])

    return (
        <Box sx={{
            border: '1px solid #B2B2B2',
            borderRadius: '10px',
            padding: '10px 0'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Order Summary
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={4} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Order Number
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{
                                            textAlign: 'left'
                                        }}>
                                            {taskDetails?.order_number}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Order Status
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{
                                            textAlign: 'left',
                                            color: taskDetails?.status_id === 100 ? '#F2BE22' :
                                                taskDetails?.status_id === 1 ? '#1D8B45' : '#454545'
                                        }}>
                                            {movingOrderStatus(taskDetails?.status_id)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Shipping Status
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{
                                            textAlign: 'left',
                                            color: taskDetails?.shipping_status_id === 2 ? '#FB8B24' :
                                                taskDetails?.shipping_status_id === 20 ? '#F2BE22' :
                                                    taskDetails?.shipping_status_id === 25 ? '#1D8B45' : '#454545'
                                        }}>
                                            {movingShippingStatus(taskDetails?.shipping_status_id)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                +taskDetails?.pickup_warehouse?.id !== +taskDetails?.deliver_warehouse?.id ?
                                    <>
                                        {
                                            taskDetails?.pickup_addr_type?.toUpperCase() === 'SCHEDULE' ?
                                                <Grid item md={6} xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                            Schedule Pickup Distance
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{
                                                            textAlign: 'left',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            Customer Home <ArrowRightAltIcon/> Warehouse
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{textAlign: 'left'}}>
                                                            {pickupDistance}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid item md={6} xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                            Self Drop-Off
                                                        </Typography>
                                                    </Grid>
                                                    {/*<Grid item xs={12}>*/}
                                                    {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                    {/*        {pickupDistance}*/}
                                                    {/*    </Typography>*/}
                                                    {/*</Grid>*/}
                                                </Grid>
                                        }
                                        {
                                            taskDetails?.deliver_addr_type?.toUpperCase() === 'SCHEDULE' ?
                                                <Grid item md={6} xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                            Schedule Delivery Distance
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            style={{
                                                                textAlign: 'left',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}>
                                                            Warehouse <ArrowRightAltIcon/> Customer Home
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography style={{textAlign: 'left'}}>
                                                            {deliverDistance}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Grid item md={6} xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                            Self Pickup
                                                        </Typography>
                                                    </Grid>
                                                    {/*<Grid item xs={12}>*/}
                                                    {/*    <Typography style={{textAlign: 'left'}}>*/}
                                                    {/*        {deliverDistance}*/}
                                                    {/*    </Typography>*/}
                                                    {/*</Grid>*/}
                                                </Grid>
                                        }
                                    </>
                                    :
                                    <Grid item md={12} xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                    Delivery Distance
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{textAlign: 'left'}}>
                                                    {taskDetails?.google_map_distance} km
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
