import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {MyBoxItemEdit} from "./MyBoxItemEdit";
import {Modal} from "@mui/joy";
import Grid from "@material-ui/core/Grid";
import {Divider} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 250,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const MyBoxesItem = ({boxItem}) => {

    const [boxItemDetail, setBoxItemDetail] = useState({});

    const [open, setOpen] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);

    const handleOpenDeleteModal = () => setDeleteModal(true);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const deleteMyBoxById = async () => {
        let requestURL = `${PARTNER_URI}/myboxes/deleteMyBoxById`;
        let data = {boxId: boxItemDetail?.boxId}
        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            })
            window.location.reload();
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        setBoxItemDetail(prevState => ({
            ...prevState,
            boxId: boxItem.box_id,
            boxName: boxItem.box_name,
            partnerId: boxItem.partner_id,
            itemName: boxItem.item_name,
            weightUnit: boxItem.weight_unit,
            dimensionUnit: boxItem.dimension_unit,
            length: boxItem.length,
            width: boxItem.width,
            height: boxItem.height,
            weight: boxItem.weight,
            originalCountry: boxItem.origi_country,
            uom: boxItem.uom,
            currency: boxItem.currency_code,
            hsCode: boxItem.hs_code,
            unitPrice: boxItem.unit_price
        }))
    }, [])

    console.log(boxItem)

    console.log(boxItemDetail)

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    return (

        <>{open ?
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <MyBoxItemEdit openEdit={open} editBoxItem={boxItemDetail} closeEdit={handleClose}/>
                </Box>
            </Grid>
            :
            <>
                <Grid item xs={3}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}>
                        <Typography sx={{textAlign: 'left'}}>
                            {boxItemDetail?.boxName}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}>
                        <Typography sx={{textAlign: 'left'}}>
                            {boxItemDetail?.itemName}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}>
                        <Typography sx={{textAlign: 'left'}}>
                            {boxItemDetail?.length} X {boxItemDetail?.width} X {boxItemDetail?.height} {boxItemDetail?.dimensionUnit}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    }}>
                        <Typography sx={{textAlign: 'left'}}>
                            {boxItemDetail?.weight} {boxItemDetail?.weightUnit}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: '10px'
                    }}>
                        <Button
                            variant="contained"
                            onClick={handleOpen}>
                            <Typography style={{textTransform: 'none'}}>
                                Edit
                            </Typography>
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleOpenDeleteModal}>
                            <Typography style={{textTransform: 'none'}}>
                                Delete
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Modal
                    open={deleteModal}
                    onClose={handleCloseDeleteModal}
                >
                    <Box
                        sx={style}
                    >
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
                            <Typography variant='h5'>
                                Confirmation
                            </Typography>
                            <Typography>
                                Are you sure you want to delete this item?
                            </Typography>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '10px'}}>
                                <Button variant="contained" onClick={deleteMyBoxById}>Confirm</Button>
                                <Button variant="contained" color="error"
                                        onClick={handleCloseDeleteModal}>Cancel</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </>
        }
        </>

    )
}