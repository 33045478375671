import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {FormLabel, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import duplicate from "../../images/Duplicate.png";
import remove from "../../images/Delete.png";
import Typography from "@mui/material/Typography";
import {InputAdornment} from "@material-ui/core";
import {getCountryCode} from "../../utils/getCountryCode";
import {UOMList} from "../../shared/constInfo";
import axios from "axios";
import {HSCODE_SEARCH} from "../../utils/apiUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {
    duplicateShippingPackageCreateLabelPackageList, duplicateShippingPackageCrossBorderItemList,
    removeShippingPackageCreateLabelPackageList, removeShippingPackageCrossBorderItemList,
    updateShippingPackageCreateLabelPackageList,
    updateShippingPackageCrossBorderItemList
} from "../Actions/shippingPackageAction";
import {generateRandomString} from "../../utils/Helper";
import FormHelperText from "@mui/material/FormHelperText";
import intl from "react-intl-universal";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "0px", // Adjust the height as per your preference
            padding: "0px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const ShippingPackageCrossBorderItem = ({itemInfo, index, metric, validation}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [itemDetail, setItemDetail] = useState(itemInfo);
    const [HSCodeList, setHSCodeList] = useState([]);

    const handleName = event => {
        if (event.target.value) {
            setItemDetail(prevState => ({
                ...prevState,
                description: event.target.value
            }))
        }
    }

    const handleSku = event => {
        if (event.target.value) {
            setItemDetail(prevState => ({
                ...prevState,
                sku: event.target.value
            }))
        }
    }

    const handleWeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemDetail(prevState => ({
                ...prevState,
                weight: value
            }))
        }
    }

    const handleQuantity = event => {
        setItemDetail(prevState => ({
            ...prevState,
            quantity: event.target.value < 1 ? '1' : event.target.value
        }))
    }

    const handlePrice = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemDetail(prevState => ({
                ...prevState,
                priceEach: value
            }))
        }
    }

    const handleCurrency = event => {
        setItemDetail(prevState => ({
            ...prevState,
            currency: event.target.value
        }))
    }

    const handleCountryCode = event => {
        setItemDetail(prevState => ({
            ...prevState,
            originCountryCode: event.target.value
        }))
    }

    const handleUOM = event => {
        setItemDetail(prevState => ({
            ...prevState,
            UOM: event.target.value
        }))
    }

    const handleOnInputChangeHSCode = async (event, value) => {
        await searchHSCode(value);
        setItemDetail(prevState => ({
            ...prevState,
            hscode: value
        }))
    }

    const searchHSCode = async (value) => {

        try {
            const {data} = await axios.get(`${HSCODE_SEARCH}?query_string=${value}`);
            console.log('data', data);
            const hits = data.hits;
            const levelSixRecords = hits.filter(hit => {
                return hit["_source"].level === "6";
            })
            const filterResult = levelSixRecords?.map(record => {
                return record["_source"]
            })
            setHSCodeList(filterResult);

        } catch (e) {
            console.log(e);
        }
    }

    const handleRemove = () => {
        dispatch(removeShippingPackageCrossBorderItemList(itemDetail));
    }

    const handleDuplicate = () => {
        const itemId = generateRandomString(8);
        const duplicatedItem = {...itemDetail};
        duplicatedItem.itemId = itemId;
        duplicatedItem.description = `${duplicatedItem.description}-copy`;
        dispatch(duplicateShippingPackageCrossBorderItemList(duplicatedItem));
    }

    useEffect(() => {
        setItemDetail(itemInfo);
    }, [itemInfo?.itemId]);

    useEffect(() => {
        setItemDetail(prevState => ({
            ...prevState,
            weightUnit: metric ? 'kg' : 'lb'
        }))
    }, [metric]);

    useEffect(() => {
        dispatch(updateShippingPackageCrossBorderItemList(itemDetail));
    }, [itemDetail])

    console.log('item detail', itemDetail);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography style={{textAlign: 'left', color: '#1D8B45'}}>
                    # {index + 1}
                </Typography>
            </Grid>
            <Grid item md={11} xs={12}>
                <Grid container spacing={1}>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('CROSS_BORDER.NAME')}
                            </FormLabel>
                            <TextField
                                value={itemDetail?.description}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder={intl.get('CROSS_BORDER.NAME')}
                                onInput={handleName}
                                // error={pickupInformationMissingKeys?.includes('city')}
                                // helperText={
                                //     pickupInformationMissingKeys?.includes('city') && "City Required"
                                // }
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('CROSS_BORDER.SKU')}
                            </FormLabel>
                            <TextField
                                value={itemDetail?.sku}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder={intl.get('CROSS_BORDER.SKU')}
                                onInput={handleSku}
                                // error={pickupInformationMissingKeys?.includes('city')}
                                // helperText={
                                //     pickupInformationMissingKeys?.includes('city') && "City Required"
                                // }
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('CROSS_BORDER.HS_CODE')}
                            </FormLabel>
                            <Autocomplete
                                disablePortal
                                freeSolo
                                options={HSCodeList}
                                getOptionLabel={option => option.hscode}
                                filterOptions={(options, state) => options}
                                fullWidth
                                onInputChange={handleOnInputChangeHSCode}
                                renderInput={(params) => <TextField
                                    {...params}
                                    // multiline
                                    placeholder={itemDetail?.hscode}
                                    className={classes.smallInput}
                                    error={!validation && !itemDetail?.hscode}
                                    helperText={
                                        (!validation && !itemDetail?.hscode) && intl.get('CROSS_BORDER.REQUIRED')
                                    }
                                />}
                                renderOption={(option) => (
                                    <Typography
                                        style={{fontSize: '12px'}}
                                    >
                                        {/*<Typography>*/}
                                        {option.hscode} | {option.desc}
                                        {/*</Typography>*/}
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl required fullWidth>
                            <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('CROSS_BORDER.CURRENCY')}
                            </FormLabel>
                            <Select
                                value={itemDetail?.currency}
                                size='small'
                                style={{fontSize: 14}} // font size of input text
                                onChange={handleCurrency}
                                // error={pickupInformationMissingKeys?.includes('country')}
                            >
                                <MenuItem value='CAD'>CAD</MenuItem>
                                <MenuItem value='USD'>USD</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('CROSS_BORDER.UOM')}
                            </FormLabel>
                            <Select
                                value={itemDetail?.UOM}
                                size='small'
                                style={{fontSize: 14}} // font size of input text
                                onChange={handleUOM}
                                error={!itemDetail?.UOM}
                            >
                                {
                                    UOMList?.map(v => {
                                        return <MenuItem value={v}>{v}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                }}
                            >
                                {!itemDetail?.UOM && intl.get('CROSS_BORDER.REQUIRED')}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('CROSS_BORDER.COUNTRY')}
                            </FormLabel>
                            <Select
                                value={itemDetail?.originCountryCode}
                                size='small'
                                style={{fontSize: 14}} // font size of input text
                                onChange={handleCountryCode}
                                error={!itemDetail?.originCountryCode}
                            >
                                {
                                    getCountryCode()?.map(v => {
                                        return <MenuItem value={v.code}>{v.name}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                }}
                            >
                                {!itemDetail?.originCountryCode && intl.get('CROSS_BORDER.REQUIRED')}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                {intl.get('CROSS_BORDER.QUANTITY')}
                            </FormLabel>
                            <TextField
                                value={itemDetail?.quantity}
                                onInput={handleQuantity}
                                size='small'
                                inputProps={{style: {fontSize: 14}}} // font size of input text
                                placeholder='quantity'
                                type='number'
                                InputProps={{
                                    inputProps: {
                                        min: 1
                                    },
                                    style: {fontSize: 14}
                                }}
                                error={itemDetail?.quantity <= 0}
                                helperText={
                                    itemDetail?.quantity <= 0 && intl.get('CROSS_BORDER.REQUIRED')
                                }
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item md={3} xs={3}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                        {intl.get('CROSS_BORDER.UNIT_WEIGHT')}
                                    </FormLabel>
                                    <TextField
                                        value={itemDetail?.weight}
                                        onInput={handleWeight}
                                        size='small'
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        placeholder={intl.get('CROSS_BORDER.UNIT_WEIGHT')}
                                        type='number'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {itemDetail?.weightUnit}
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                min: 1
                                            },
                                            style: {fontSize: 14}
                                        }}
                                        error={!validation && itemDetail?.weight <= 0}
                                        helperText={
                                            (!validation && itemDetail?.weight <= 0) && intl.get('CROSS_BORDER.MISSING_INVALID')
                                        }
                                    >
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item md={1} xs={1}>
                                <Box sx={{
                                    display: 'flex',
                                    height: '100%',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center'
                                }}>
                                    <CloseIcon sx={{
                                        fontSize: '35px',
                                        color: '#1D8B45'
                                    }}/>
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={3}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                        {intl.get('CROSS_BORDER.QUANTITY')}
                                    </FormLabel>
                                    <TextField
                                        value={itemDetail?.quantity}
                                        onInput={handleQuantity}
                                        size='small'
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        placeholder='quantity'
                                        type='number'
                                        disabled={true}
                                        InputProps={{
                                            inputProps: {
                                                min: 1
                                            },
                                            style: {fontSize: 14}
                                        }}
                                        error={itemDetail?.quantity <= 0}
                                        helperText={
                                            itemDetail?.quantity <= 0 && intl.get('CROSS_BORDER.REQUIRED')
                                        }
                                    >
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item md={1} xs={1}>
                                <Box sx={{
                                    display: 'flex',
                                    height: '100%',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                    fontSize: '35px',
                                    color: '#1D8B45',
                                    fontWeight: '600'
                                }}>
                                    =
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                        {intl.get('CROSS_BORDER.TOTAL_WEIGHT')}
                                    </FormLabel>
                                    <TextField
                                        value={itemDetail?.weight * itemDetail?.quantity}
                                        size='small'
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        placeholder='total Weight'
                                        type='number'
                                        disabled={true}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {itemDetail?.weightUnit}
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                min: 1
                                            },
                                            style: {fontSize: 14}
                                        }}
                                        // error={pickupInformationMissingKeys?.includes('city')}
                                        // helperText={
                                        //     pickupInformationMissingKeys?.includes('city') && "City Required"
                                        // }
                                    >
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item md={3} xs={3}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                                        {intl.get('CROSS_BORDER.UNIT_PRICE')}
                                    </FormLabel>
                                    <TextField
                                        value={itemDetail?.priceEach}
                                        size='small'
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        placeholder={intl.get('CROSS_BORDER.UNIT_PRICE')}
                                        type='number'
                                        onInput={handlePrice}
                                        error={!validation && itemDetail?.priceEach <= 0}
                                        helperText={
                                            (!validation && itemDetail?.priceEach <= 0) && intl.get('CROSS_BORDER.MISSING_INVALID')
                                        }
                                    >
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item md={1} xs={1}>
                                <Box sx={{
                                    display: 'flex',
                                    height: '100%',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center'
                                }}>
                                    <CloseIcon sx={{
                                        fontSize: '35px',
                                        color: '#1D8B45'
                                    }}/>
                                </Box>
                            </Grid>
                            <Grid item md={3} xs={3}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                        {intl.get('CROSS_BORDER.QUANTITY')}
                                    </FormLabel>
                                    <TextField
                                        value={itemDetail?.quantity}
                                        onInput={handleQuantity}
                                        size='small'
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        placeholder='quantity'
                                        type='number'
                                        disabled={true}
                                        InputProps={{
                                            inputProps: {
                                                min: 1
                                            },
                                            style: {fontSize: 14}
                                        }}
                                        error={itemDetail?.quantity <= 0}
                                        helperText={
                                            itemDetail?.quantity <= 0 && intl.get('CROSS_BORDER.REQUIRED')
                                        }
                                    >
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item md={1} xs={1}>
                                <Box sx={{
                                    display: 'flex',
                                    height: '100%',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                    fontSize: '35px',
                                    color: '#1D8B45',
                                    fontWeight: '600'
                                }}>
                                    =
                                </Box>
                            </Grid>
                            <Grid item md={4} xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                                        {intl.get('CROSS_BORDER.TOTAL_PRICE')}
                                    </FormLabel>
                                    <TextField
                                        value={itemDetail?.priceEach * itemDetail?.quantity}
                                        size='small'
                                        inputProps={{style: {fontSize: 14}}} // font size of input text
                                        placeholder='total price'
                                        type='number'
                                        disabled={true}
                                        // error={pickupInformationMissingKeys?.includes('city')}
                                        // helperText={
                                        //     pickupInformationMissingKeys?.includes('city') && "City Required"
                                        // }
                                    >
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1} xs={12}>
                <Box sx={{
                    display: 'flex',
                    // flexDirection: isMobile ? 'row' : 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: '100%',
                    width: '100%',
                    gap: '10px'
                }}>
                    <IconButton
                        color='success'
                        onClick={handleDuplicate}
                        sx={{padding: 0}}
                    >
                        <img src={duplicate} width='30px'/>
                    </IconButton>
                    <IconButton
                        color='error'
                        onClick={handleRemove}
                        sx={{padding: 0}}
                    >
                        <img src={remove} width='30px'/>
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}