import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import {visuallyHidden} from "@mui/utils";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import {Tooltip} from "@material-ui/core";
import ShippingTransactionTable from "./ShippingTransactionTable";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";

const headCells = [
    {
        id: "icon",
        numeric: false,
        // disablePadding: true,
        label: "",
    },
    {
        id: "tranId",
        numeric: false,
        // disablePadding: true,
        label: "Tran Id",
    },
    // {
    //   id: "refOrderNumber",
    //   numeric: false,
    //   // disablePadding: true,
    //   label: "Ref Order #",
    // },
    {
        id: "sendTo",
        numeric: false,
        // disablePadding: false,
        label: "Send To Info",
    },
    {
        id: "price",
        numeric: false,
        // disablePadding: false,
        label: "Price",
    },
    {
        id: "trackingNumbers",
        numeric: false,
        // disablePadding: false,
        label: "Tracking #",
    },
    {
        id: "status",
        numeric: false,
        // disablePadding: false,
        label: "Status",
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{fontWeight: "bold"}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function ShippingTransactions({transactionList}) {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    return (
        <Box sx={{width: "100%", height: "100%"}}>
            <Paper sx={{width: "100%", mb: 2, height: "100%",}}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer style={{maxHeight: 700}}>
                    <Table
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                    >
                        <EnhancedTableHead
                            numSelected={transactionList.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={transactionList.length}
                        />
                        <TableBody>
                            {
                                transactionList.map(v => {
                                    return (
                                        <ShippingTransactionTable
                                            account_base={v.account_base}
                                            date={v.tranacted_at}
                                            gross_price={v?.amount_gross}
                                            net_price={v?.amount}
                                            shipping_fee_exclude_tax={v?.shipping_fee_exclude_tax}
                                            service_fee_exclude_tax={v?.service_fee_exclude_tax}
                                            shipping_fee_include_tax={v?.shipping_fee_include_tax}
                                            service_fee_include_gst={v?.service_fee_include_gst}
                                            order_id={v?.order_id}
                                            ref_product_no={v.ref_product_no}
                                            ref_other_no={v?.ref_other_no}
                                            ref_order_no={v?.ref_order_no}
                                            tran_id={v.tran_id}
                                            label_pdf_uri={v?.label_pdf_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_pdf_uri}` : ""}
                                            zip_label_uri={v?.label_zip_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_zip_uri}` : ""}
                                            send_to_addr={v.send_to_addr}
                                            send_to_city={v.send_to_city}
                                            send_to_province={v.send_to_province}
                                            send_to_country={v.send_to_country}
                                            send_to_postal_code={v.send_to_postal_code}
                                            send_to_name={v.send_to_name}
                                            send_to_tel={v.send_to_tel}
                                            send_to_email={v.send_to_email}
                                            currency_code={v?.currency_code || "CAD"}
                                            transactionNumbers={v.tran_tracking_nos}
                                            tran_status={v.tran_status}
                                            shipping_id_no={v.shipping_id_no}/>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {/*<TablePagination*/}
                {/*  // rowsPerPageOptions={[5, 10, 25]}*/}
                {/*    rowsPerPageOptions={[]}*/}
                {/*  component="div"*/}
                {/*  count={10}*/}
                {/*  rowsPerPage={rowsPerPage}*/}
                {/*  page={page}*/}
                {/*  labelRowsPerPage=""*/}
                {/*  onPageChange={handleChangePage}*/}
                {/*  // onRowsPerPageChange={handleChangeRowsPerPage}*/}
                {/*/>*/}
            </Paper>
        </Box>
    );
}
