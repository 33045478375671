import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Box,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import PropTypes from "prop-types";
import LooseItemServices from "./components/looseItemServices";
import axios from "axios";
import getProvinceCodeByName, {getUSProvinceCodeByName} from "../../utils/getProvinceCode";
import loadingContainer from "../../shared/loadingContainer";
import LoadingContainer from "../../shared/loadingContainer";
import { PARTNER_URI } from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";

const LooseItemRates = ({ setCompanyName, setServiceName, toSeventh, deliverLocation, userLocation, shippingItems, accountBaseCombinedName, setPriceText }) => {
  const CURRENT_COMPANY = "UUCARGO";

  const [serviceData, setServiceData] = useState({
    info: []
  });

  const classes = useStyles();

  const setAndLoadNext = () => {
    setCompanyName(CURRENT_COMPANY);
    toSeventh();
  };
  const [isLoading, setIsLoading] = useState(false);

  const getAvailableService = async ({ rateData }) => {
    const { data } = await axios.post(`${PARTNER_URI}/loose-item/3rd-party/partner/rates`, {
      accountBaseCombinedName,
      rateData
    });
    return data.result;
  }

  useEffect(() => {
    try {
      console.log("User Location", userLocation);
      console.log("Shipping Item:", shippingItems);
      console.log("Account Based Combined Name:", accountBaseCombinedName);
      // The semicolon in this request body is required, otherwise page would crash

      const requestBody = {
        accountBaseCombinedName,
        rateData: {
          shipFromAddress: userLocation.address,
          shipFromAddressTwo: userLocation?.aptInfo || "",
          shipFromCity: userLocation.city,
          shipFromProvince: userLocation.country === "CA" ? getProvinceCodeByName(userLocation.province) : getUSProvinceCodeByName(userLocation.province),
          shipFromPostalCode: userLocation.zipCode,
          shipFromCountry: userLocation.country?.trim().toLowerCase() === "canada" ? "CA" : userLocation.country,
          shipFromName: userLocation.name,
          shipFromPhone: userLocation.phone,
          shipToAddress: deliverLocation.address,
          shipToAddressTwo: deliverLocation.aptInfo,
          shipToCity: deliverLocation.city,
          shipToPostalCode: deliverLocation.zipCode,
          shipToProvince: deliverLocation.country === "CA" ? getProvinceCodeByName(deliverLocation.province) : getUSProvinceCodeByName(deliverLocation.province),
          shipToCountry: deliverLocation.country?.trim().toLowerCase() === "canada" ? "CA" : deliverLocation.country,
          shipToName: deliverLocation.name,
          shipToPhone: deliverLocation.phone,
          packageData: shippingItems.map(v => {
            return {
              length: v.length,
              height: v.height,
              width: v.width,
              quantity: v.looseUnit,
              weight: v.weight,
              lengthUnit: v.lengthUnit,
              weightUnit: v.weightUnit
            }
          })
        }
      };
      console.log(requestBody);
      (async () => {
        try {
          setIsLoading(true);
          const result = await getAvailableService(requestBody);
          console.log("Available Services Result:", result);
          setServiceData({
            info: result?.map(v => {
              return {
                companyName: v?.accountBase || "Error",
                service: v?.serviceName || "UUC Standard",
                charge: v?.totalChargeWithTaxes?.value ? `$${v?.totalChargeWithTaxes?.value}` : `$${v?.totalCharge?.value}`
              }
            })
          });
        } catch (e) {
          setServiceData({
            info: []
          })
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      })();
    } catch (e) {
      console.log(e);
    }

  }, [deliverLocation, shippingItems, userLocation, accountBaseCombinedName])

  if (isLoading) {
    return (
        <Container className={classes.root} >
          <Box className={classes.title} mb={2}>
            SHIPPING SERVICE
          </Box>
          <Box display="flex" alignItems="center">
            <TableContainer>
              <LoadingContainer />
            </TableContainer>
          </Box>
        </Container>
    )
  }

  return (
    <Container className={classes.root}>
      <Box className={classes.title} mb={2}>
        SHIPPING SERVICE
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="center" className={classes.smSubTitle}>
                  Carrier
                </TableCell>
                <TableCell align="center" className={classes.smSubTitle}>
                  Shipping Service
                </TableCell>
                <TableCell align="right" className={classes.smSubTitle}>
                  Shipping Fee
                </TableCell>
              </TableRow>
            </TableHead>
            {serviceData.info.filter(v => v.companyName !== "Error").map((info, index) => {
              return (
                <LooseItemServices
                  setCompanyName={setCompanyName}
                  setServiceName={setServiceName}
                  toSeventh={toSeventh}
                  companyName={info?.companyName || "N/A"}
                  shippingService={info?.service || "N/A"}
                  chargeAmount={info?.charge || "0"}
                  setPriceText={setPriceText}
                  key={index}
                />
              );
            })}
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: "orange",
    mb: "1vh",
    fontSize: "x-large"
  },
  input: {
    color: "grey",
    width: "90%",
  },
  smSubTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Montserrat",
  },
});

LooseItemRates.propTypes = {
  toSeventh: PropTypes.func.isRequired,
};

export default LooseItemRates;
