import Box from "@mui/material/Box";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@material-ui/core/Grid";
import wechatPay from "../../images/wechatPay.svg";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";

const styles = {
    TopUpWeChatPayRoot: {
        border: '1px solid #000000',
        display: 'flex',
        borderRadius: '10px'
    }
}

export const TopUpWeChatPay = ({crmInvoiceInfo, crmInvoiceNumber, coupon}) => {

    const [qrCodeImage, setQrCodeImage] = useState("");
    const [amount, setAmount] = useState(0);
    const [payCheckLink, setPayCheckLink] = useState("#");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (crmInvoiceNumber) {
            (async () => {
                try {
                    setIsLoading(true);
                    const storedToken = getAccessToken("access_token");
                    const {data} = await axios.post(`${PARTNER_URI}/generalWechatAlipay/initPartnerTopUp`, {
                        crmInvoiceNumber,
                        payType: "ALPHAPAY-WECHATPAY",
                        coupon: coupon?.coupon
                    }, {
                        headers: {
                            Authorization: `Bearer ${storedToken}`,
                        },
                    });
                    console.log("init pay check by crm invoice number:", data);
                    const {qrcode_image, amount, pay_url} = data;
                    setQrCodeImage(qrcode_image);
                    setAmount((+amount / 100));
                    setPayCheckLink(pay_url);
                } catch (e) {
                    setQrCodeImage("");
                    setAmount(0);
                    setPayCheckLink("#");
                    console.log(e);
                } finally {
                    setIsLoading(false);
                }
            })();
        } else {
            setIsLoading(false);
        }
    }, [crmInvoiceNumber]);

    if (isLoading) {
        return <>
            <CircularProgress/>
        </>
    }

    if (qrCodeImage === "" && !isLoading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    console.log('crm info', crmInvoiceInfo);

    return (

        <Box sx={styles.TopUpWeChatPayRoot}>
            <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                    <Box sx={{padding: '5px 15px'}}>
                        <img src={qrCodeImage} alt="qrCodeLogo" width={170}/>
                    </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Box sx={{
                        padding: '5px 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        gap: '5px'
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                            <img src={wechatPay} alt="wechatLogo" width={35}/>
                            <Typography style={{fontSize: '14px'}}>
                                {intl.get('BILLING_CENTER.PAYMENT.WECHAT.WECHAT_PAY')}
                            </Typography>
                        </Box>
                        <Grid container spacing={0}>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.PAYMENT.WECHAT.TOTAL_AMOUNT')}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                    $ {amount.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.PAYMENT.WECHAT.RECEIVER')}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    UU CARGO LTD.
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.TOP_UP_PAGE.ORDER_ID')}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {crmInvoiceNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {intl.get('BILLING_CENTER.TOP_UP_PAGE.ORDER_NUMBER')}:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                    {crmInvoiceInfo?.invoiceRefNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}