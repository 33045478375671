import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Divider} from "@mui/material";
import {Fragment, useState} from "react";
import Button from "@mui/material/Button";

const style = {
    PackageOrderDashboardTransactionErrorRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const PackageOrderDashboardTransactionError = ({failedTransactionList}) => {

    console.log('[PackageOrderDashboardTransactionError] failedTransactionList', failedTransactionList);

    const [viewDetails, setViewDetails] = useState(false);

    const handleOpenView = () => {
        setViewDetails(true);
    }

    const handleCloseView = () => {
        setViewDetails(false);
    }

    return (
        <Box sx={style.PackageOrderDashboardTransactionErrorRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Failed Transactions
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {
                        viewDetails ?
                            <Box sx={{
                                display: 'flex',
                                padding: '5px 10px',
                                flexDirection: 'column',
                                gap: '10px'
                            }}>
                                {
                                    failedTransactionList?.map((tran, index) => (
                                        <Fragment key={index}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '16px',
                                                            textAlign: 'left',
                                                            fontWeight: '600'
                                                        }}>
                                                        Package Information
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        Description:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        {tran?.item_description || 'N/A'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        Dimension:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        {tran?.length} x {tran?.width} x {tran?.height} {tran?.dimension_unit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        Weight:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        {tran?.weight} {tran?.weight_unit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '16px',
                                                            textAlign: 'left',
                                                            fontWeight: '600'
                                                        }}>
                                                        Shipping To
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        Name:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        {tran?.transaction?.send_to_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        Phone:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        {tran?.transaction?.send_to_tel}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        Address:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                        {tran?.transaction?.send_to_addr}, {tran?.transaction?.send_to_city}, {tran?.transaction?.send_to_province}, {tran?.transaction?.send_to_country}, {tran?.transaction?.send_to_postal_code}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider/>
                                        </Fragment>
                                    ))
                                }
                                <Box>
                                    <Button
                                        variant='outlined'
                                        sx={{
                                            border: '2px solid #454545',
                                            "&:hover": {
                                                border: '2px solid #454545',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleCloseView}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                            color: '#454545',
                                        }}>
                                            Close
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box> :
                            <Box sx={{
                                display: 'flex',
                                padding: '5px 10px',
                                flexDirection: 'column',
                                gap: '10px'
                            }}>
                                <Typography
                                    style={{
                                        fontSize: '16px',
                                        textAlign: 'center',
                                    }}>
                                    # Failed Transactions: {failedTransactionList?.length}
                                </Typography>
                                <Box>
                                    <Button
                                        variant='outlined'
                                        sx={{
                                            border: '2px solid #1D8B45',
                                            "&:hover": {
                                                border: '2px solid #1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleOpenView}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                            color: '#1D8B45',
                                        }}>
                                            View Details
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}