export const provinces = [
  { label: "Alberta", value: "AB" },
  { label: "British Columbia", value: "BC" },
  { label: "Manitoba", value: "MB" },
  { label: "New Brunswick", value: "NB" },
  { label: "Newfoundland and Labrador", value: "NF" },
  { label: "Northwest Territories", value: "NT" },
  { label: "Nova Scotia", value: "NS" },
  { label: "Nunavut", value: "NU" },
  { label: "Ontario", value: "ON" },
  { label: "Prince Edward Island", value: "PE" },
  { label: "Quebec", value: "QC" },
  { label: "Saskatchewan", value: "SK" },
  { label: "Yukon", value: "YT" },
];

export const canadaProvincesList = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
];

export const americaProvinceList = ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming']

export const canadaProvinceCodeToNameMap = {
  "AB": "Alberta",
  "BC": "British Columbia",
  "MB": "Manitoba",
  "NB": "New Brunswick",
  "NF": "Northwest Territories",
  "NT": "Northwest Territories",
  "NS": "Nova Scotia",
  "NU": "Nunavut",
  "ON": "Ontario",
  "PE": "Prince Edward Island",
  "QC": "Quebec",
  "SK": "Saskatchewan",
  "YT": "Yukon"
}
export const canadaProvincNameToCodeMap = {
  "Alberta": "AB",
  "British Columbia": "BC",
  "Manitoba": "MB",
  "New Brunswick": "NB",
  "Newfoundland and Labrador": "NF",
  "Northwest Territories": "NT",
  "Nova Scotia": "NS",
  "Nunavut": "NU",
  "Ontario": "ON",
  "Prince Edward Island": "PE",
  "Quebec": "QC",
  "Saskatchewan": "SK",
  "Yukon": "YT",
}


export const americaProvinceCodeToNameMap = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FM": "Federated States Of Micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin Islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
}

export const americaProvinceNameToCodeMap = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY"
}

export const countryList = [
    "CA",
    "US",
];

export const sendToCountryList = [
  "CA",
  "US",
  "AU",
  "CN",
  "KH",
  "HK",
  "IN",
  "JP",
  "MY",
  "ID",
  "MX",
  "TW",
  "LK",
  "KR",
  "TH",
  "GB",
  "VN",
  "FR",
  "DE",
  "IT",
  "LA"
];



export const ADDRESS_TYPE_FBA = "FBA"
export const ADDRESS_TYPE_WAREHOUSE = "WAREHOUSE"
export const ADDRESS_TYPE_RESIDENTIAL = "RESIDENTIAL"
export const serviceTypeOptions = [
  ADDRESS_TYPE_FBA,
  ADDRESS_TYPE_WAREHOUSE,
  ADDRESS_TYPE_RESIDENTIAL
]

export const shipmentTypes = {
  pallet: [
    { label: '48" x 40"', value: "1" },
    { label: '48" x 48"', value: "2" },
    { label: "Oversize", value: "3" },
    { label: "Overweight", value: "4" },
    // { label: "5-ton Truck(10 Pallets)", value: "6" },
    // { label: "Full Trailer(24-26 Pallets)", value: "7" },
  ],
  container: [
    { label: "20'", value: "8", group: "container" },
    { label: "40'", value: "10" },
    { label: "20'HC", value: "9" },
    { label: "40'HC", value: "11" },
    { label: "45'HC", value: "13" },
    { label: "48'HC", value: "14" },
    { label: "53'HC", value: "15" },
  ],
  smallContainer: [
    { label: "6'", value: "16" },
    { label: "8'", value: "17" },
    { label: "10'", value: "18" },
  ],
  flatbed: "12",
};

export const shipmentTypesDetails = {
  1: {
    unit: "1 Pallet",
    unit_number: 1,
    length: 48,
    width: 40,
    max_height: 72,
    max_weight: 1200,
    unit_price: 20,
    type_name: '48" x 40"',
    id: 1,
    label: "pallet",
    weightUnit: "pounds",
    sizeUnit: '"',
  },
  2: {
    unit: "1 Pallet",
    unit_number: 1,
    length: 48,
    width: 48,
    max_height: 72,
    max_weight: 1200,
    unit_price: 20,
    type_name: '48" x 48"',
    id: 2,
    label: "pallet",
    weightUnit: "pounds",
    sizeUnit: '"',
  },
  3: {
    unit: "1 Pallet",
    unit_number: 1,
    length: 48,
    width: 48,
    max_height: 150,
    max_weight: 1200,
    unit_price: 25,
    type_name: "Oversize Pallet",
    id: 3,
    label: "pallet",
    weightUnit: "pounds",
    sizeUnit: '"',
  },
  4: {
    unit: "1 Pallet",
    unit_number: 1,
    length: 48,
    width: 48,
    max_height: 72,
    max_weight: 3000,
    unit_price: 25,
    type_name: "Overweight",
    id: 4,
    label: "pallet",
    weightUnit: "pounds",
    sizeUnit: '"',
  },
  6: {
    unit: "FTL(10 Pallets)",
    unit_number: 10,
    length: null,
    width: null,
    height: null,
    weight: null,
    max_weight: 3000,
    unit_price: 180,
    type_name: "5-ton Truck(10 Pallets)",
    id: 6,
    label: "unit",
    weightUnit: "pounds",
    sizeUnit: '"',
  },
  7: {
    unit: "FTL(25 Pallets)",
    unit_number: 25,
    length: null,
    width: null,
    height: null,
    weight: null,
    max_weight: 3000,
    unit_price: 400,
    type_name: "Full Trailer(24-26 Pallets)",
    id: 7,
    label: "unit",
    weightUnit: "pounds",
    sizeUnit: '"',
  },
  8: {
    unit: "1 container",
    unit_number: 1,
    length: 20,
    width: 8,
    height: 8,
    max_weight: 30,
    unit_price: null,
    type_name: "20'",
    id: 8,
    label: "container",
    weightUnit: "tons",
    sizeUnit: "'",
  },
  9: {
    unit: "1 container",
    unit_number: 1,
    length: 20,
    width: 8,
    height: 9,
    max_weight: 30,
    unit_price: null,
    type_name: "20'HC",
    id: 9,
    label: "container",
    weightUnit: "tons",
    sizeUnit: "'",
  },
  10: {
    unit: "1 container",
    unit_number: 1,
    length: 40,
    width: 8,
    height: 8,
    max_weight: 30,
    unit_price: null,
    type_name: "40'",
    id: 10,
    label: "container",
    weightUnit: "tons",
    sizeUnit: "'",
  },
  11: {
    unit: "1 container",
    unit_number: 1,
    length: 40,
    width: 8,
    height: 9,
    max_weight: 30,
    unit_price: null,
    type_name: "40'HC",
    id: 11,
    label: "container",
    weightUnit: "tons",
    sizeUnit: "'",
  },
  12: {
    unit: "1 container",
    unit_number: 1,
    length: 53,
    width: 8.5,
    max_height: 9,
    max_weight: 22,
    unit_price: null,
    type_name: "Flatbed",
    id: 12,
    label: "flatbed",
    weightUnit: "tons",
    sizeUnit: "'",
  },
  13: {
    unit: "1 container",
    unit_number: 1,
    length: 45,
    width: 8,
    height: 9,
    max_weight: 30,
    unit_price: null,
    type_name: "45'HC",
    id: 13,
    label: "container",
    weightUnit: "tons",
    sizeUnit: "'",
  },
  14: {
    unit: "1 container",
    unit_number: 1,
    length: 48,
    width: 8,
    height: 9,
    max_weight: 30,
    unit_price: null,
    type_name: "48'HC",
    id: 14,
    label: "container",
    weightUnit: "tons",
    sizeUnit: "'",
  },
  15: {
    unit: "1 container",
    unit_number: 1,
    length: 53,
    width: 8,
    max_height: 9,
    max_weight: 30,
    unit_price: null,
    type_name: "53'HC",
    id: 15,
    label: "container",
    weightUnit: "tons",
    sizeUnit: "'",
  },
  16: {
    unit: "1 container",
    unit_number: 1,
    length: 6,
    width: 6,
    height: 6,
    max_weight: 992,
    unit_price: null,
    type_name: "6'",
    id: 16,
    label: "smallContainer",
    weightUnit: "pounds",
    sizeUnit: "'",
  },
  17: {
    unit: "1 container",
    unit_number: 1,
    length: 8,
    width: 7,
    height: 7,
    max_weight: 992,
    unit_price: null,
    type_name: "8'",
    id: 17,
    label: "smallContainer",
    weightUnit: "pounds",
    sizeUnit: "'",
  },
  18: {
    unit: "1 container",
    unit_number: 1,
    length: 10,
    width: 8,
    height: 8,
    max_weight: 992,
    unit_price: null,
    type_name: "Small Container 10ft",
    id: 18,
    label: "smallContainer",
    weightUnit: "pounds",
    sizeUnit: "'",
  },
  21: {},
};

export const UOMList = [
  "BOX",
  "BAG",
  "CASE",
  "PALLET",
  "PACKAGE",
  "PAIRS",
  "EACH",
  "ARS",
  "BARREL",
  "BOLT",
  "BUNCH",
  "BUNDLE",
  "BUTT",
  "CANISTER",
  "CARTON",
  "CENTIMETER",
  "CONTAINER",
  "CRATE",
  "CYLINDER",
  "DOZEN",
  "ENVELOPE",
  "FEET",
  "KILOGRAM",
  "LITER",
  "METER",
  "NUMBER",
  "PACKET",
  "PIECES",
  "POUNDS",
  "PROOFLITER"
]

// export const CountryList = [
//     "US",
//     "CA",
// ]

export const internationalShipmentTypeList = [
    "GIFT",
    "COMMERCIAL",
    "SALE",
    "SAMPLE",
    "REPAIR",
    "RETURN",
    "OTHER"
]

export const getTransportationCode = (transportationName) => {
  switch (transportationName) {
    case "International Truck":
      return "INT_TRUCK";
    case "International Air":
      return "INT_AIR";
    case "International Ocean":
      return "INT_OCEAN";
    case "International Rail":
      return "INT_RAIL";
    default:
      return "INT_TRUCK";
  }
}
export const transportationList = [
    "International Truck",
    // "International Air",
    // "International Ocean",
    // "International Rail"
]

export const transportationListToCodeMap = {
  "INT_TRUCK":"International Truck",
  "INT_AIR":"International Air",
  "INT_OCEAN":"International Ocean",
  "INT_RAIL":"International Rail"
}

export const domain = 'https://storage.googleapis.com/';

export const REGISTER_USER_PASSWORD_WRONG = 401;

export const REGISTER_COMPANY_ALREADY_EXISTS = 403;

export const REGISTER_COMPANY_SUCCESS = 200;


export const SHIPPING_LTL_GENERAL_APPOINTMENT_DELIVERY_CODE = "APTFGT";
export const SHIPPING_LTL_GENERAL_DANGEROUS_GOODS_CODE = "DANGEROUS";
export const SHIPPING_LTL_GENERAL_INSIDE_DELIVERY_CODE = "INSDLY";
export const SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE = "PRESDL";
export const SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE = "PRESPU";
export const SHIPPING_LTL_GENERAL_PROTECTED_SERVICE_CODE = "PROTEC";
export const SHIPPING_LTL_GENERAL_TAILGATE_DELIVERY_CODE = "TLGDEL";
export const SHIPPING_LTL_GENERAL_TAILGATE_PICKUP_CODE = "TLGPU";
export const SHIPPING_LTL_GENERAL_INSURANCE_CLAIM_CODE = "INSFEE";