import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BusinessIcon from "@material-ui/icons/Business";
import {Estimate} from "./Estimate";
import UserSignIn from "../SignIn/userSignIn";
import {BecomePartner} from "../BecomePartner/BecomePartner";
import {LandingEstimateCost} from "./LandingEstimateCost";
import {Container} from "@mui/material";
import {LandingIntro} from "./LandingIntro";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import LoadingContainer from "../../shared/loadingContainer";
import {EstimateCost} from "./EstimateCost";

const styles = {
    LandingEstimateRoot: {
        display: 'flex',
        margin: 'auto',
        '@media (max-width: 480px)': {
            flexDirection: "column", // for phone and above
        },
    },
    LandingEstimateHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    LandingEstimateHeaderHeading: {
        color: "green",
        fontFamily: "Montserrat",
        fontSize: "40px",
        letterSpacing: "2px",
        fontWeight: '600',
        textAlign: 'center',
        '@media (max-width: 480px)': {
            fontSize: "30px", // for phone and above
        },
    },
    LandingEstimateHeaderSubHeading: {
        display: 'flex',
        gap: '10px'
    },
    LandingEstimateContent: {
        display: 'flex',
        marginTop: '20px',
        '@media (max-width: 1024px)': {
            flexDirection: 'column', // for tablets and above
        },
    },
}

export const LandingEstimate = () => {

    let isLoading = useSelector(state => state?.landingEstimate?.landingEstimateCostLoading);
    let isClickEstimate = useSelector(state => state?.landingEstimate?.landingEstimateIsClickEstimate);
    let estimatePackageCost = useSelector(state => state?.landingEstimate?.landingEstimatePackageCost);

    const dispatch = useDispatch();

    const estimateSection = useRef(null);

    const scrollDown = (ref) => {
        console.log(ref.current.offsetTop)
        window.scrollTo({
            top: ref.current.offsetTop + 1000,
            behavior: 'smooth',
        });
    };

    console.log('is click', isClickEstimate);
    // useEffect(() => {
    //
    //     isClickEstimate && scrollDown(estimateSection);
    //
    //     return () => {
    //         dispatch(updateLandingClickEstimate(false));
    //     }
    // }, [isClickEstimate])

    useEffect(() => {
        if (estimatePackageCost.length > 0 && estimatePackageCost.length < 5) {
            scrollDown(estimateSection);
        }
    }, [estimatePackageCost])

    useEffect(() => {
        document.body.classList.add('white-background');

        return () => {
            document.body.classList.remove('white-background');
        };
    }, []);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.LandingEstimateRoot}>
                <LandingIntro/>
                <Estimate/>
            </Box>
            <Box ref={estimateSection}>
                {
                    !isClickEstimate ? <LandingEstimateCost/> :
                        isLoading ? <LoadingContainer/> : <EstimateCost/>
                }
            </Box>
        </Container>
    )
}