import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {Divider, Tooltip, tooltipClasses} from "@mui/material";
import {Fragment} from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {styled} from "@mui/material/styles";

const styles = {
    ShippingPackageCreateLabelShipFromRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelShipFromSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingPackageCreateLabelShipFromHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelShipFromSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelShipFromSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    ShippingPackageCreateLabelShipFromText: {
        fontSize: '14px',
        textAlign: 'left',
        color: '#609966',
        fontWeight: '600',
    },
    ShippingPackageCreateLabelShipFromWarningText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingPackageCreateLabelShipFromModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelShipFromSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelShipFromAddressText: {
        fontSize: '14px'
    }
}

const ErrorTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FF0303',
        color: '#FFFFFF',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const ShippingPackageImportShipmentQuoteDetails = ({shipmentItems}) => {

    console.log('[ShippingPackageImportShipmentQuoteDetails] shipmentItems', shipmentItems);

    return (
        <Box sx={styles.ShippingPackageCreateLabelShipFromRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipFromSection}>
                        <Typography style={styles.ShippingPackageCreateLabelShipFromHeading}>
                            {intl.get('IMPORT_SHIPMENT.BATCH_SHIPMENT_QUOTE_DETAILS')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styles.ShippingPackageCreateLabelShipFromSection}>
                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                            Review your shipping rates here. Successful rows are marked with a green checkmark. Rows
                            that failed validation are marked and need corrections.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Divider/>
                </Grid>
                <>
                    <Grid item md={1} xs={12}>
                        <Typography style={{textAlign: 'left'}}>
                            Row
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Typography style={{textAlign: 'left'}}>
                            Package
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography style={{textAlign: 'left'}}>
                            Shipping To
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Typography style={{textAlign: 'left'}}>
                            Charge (Include Tax)
                        </Typography>
                    </Grid>
                    <Grid item md={1} xs={12}>
                        <Typography style={{textAlign: 'right'}}>
                            Status
                        </Typography>
                    </Grid>
                </>
                <Grid item md={12} xs={12}>
                    <Divider/>
                </Grid>
                {
                    shipmentItems?.length > 0 && shipmentItems?.map((each, index) => (
                            <Fragment key={index}>
                                <Grid item md={1}>
                                    <Typography style={{textAlign: 'left'}}>
                                        # {each?.index + 1}
                                    </Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Box>
                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                            {each?.packageData[0]?.description}
                                        </Typography>
                                        <Typography style={{textAlign: 'left'}}>
                                            {each?.packageData[0]?.length} x {each?.packageData[0]?.width} x {each?.packageData[0]?.height} {each?.packageData[0]?.lengthUnit} | {(+each?.packageData[0]?.weight)?.toFixed(2)} {each?.packageData[0]?.weightUnit} |
                                            x {each?.packageData[0]?.quantity}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={4}>
                                    <Box>
                                        <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                            {each?.shipToName}
                                        </Typography>
                                        <Typography style={{textAlign: 'left'}}>
                                            {each?.shipToCity}, {each?.shipToProvince}, {each?.shipToCountry}, {each?.shipToPostalCode}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography style={{textAlign: 'left'}}>
                                        {
                                            each.hasOwnProperty('message') ? 'N/A' : `$ ${(+each?.finalTotalAmount?.value)?.toFixed(2)}`
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item md={1}>
                                    {
                                        each.hasOwnProperty('message') ?
                                            <ErrorTooltip title={each?.message} placement="left">
                                                <ErrorIcon sx={{
                                                    color: '#FF0303',
                                                    fontSize: '30px'
                                                }}/>
                                            </ErrorTooltip> :
                                            <CheckCircleIcon sx={{
                                                color: '#1D8B45', fontSize: '30px'
                                            }}/>
                                    }
                                </Grid>
                            </Fragment>
                        )
                    )
                }
            </Grid>
        </Box>
    )
}