import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import axios from "axios";
import {Snackbar} from "@mui/material";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {useHistory} from "react-router-dom";
import intl from "react-intl-universal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    BillingPaymentEmailRoot: {
        backgroundColor: '#FFFFFF',
        borderRadius: '0 0 8px 8px',
        padding: '15px 30px 40px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    }
}

export const BillingPaymentEmail = ({cbResetPaymentMethod, totalAmount, selectInvoice}) => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");

    const [referenceNumber, setReferenceNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [paidSuccess, setPaidSuccess] = useState(false);
    const [paidInvoiceList, setPaidInvoiceList] = useState([]);
    const [notPaidInvoiceList, setNotPaidInvoiceList] = useState([]);
    const [remainAmount, setRemainAmount] = useState(0);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleReferenceNumber = (event) => {
        setReferenceNumber(event.target.value);
    }

    const findInvoiceNumbersByInvoiceIds = (invoiceIds) => {
        const invoiceNumbers = invoiceIds.map(id => {
            const foundInvoiceNumber = selectInvoice.find(item => item.id === id);
            return foundInvoiceNumber?.invoiceRefNumber
        })

        return invoiceNumbers;

    }

    const payInvoicesByEmailReferenceNumber = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/billingCenter/partnerPayInvoicesByEmailReferenceNumber`;

        const invoiceList = selectInvoice.map(item => {
            return {
                invoiceId: item.id
            }
        })

        const data = {
            referenceNumber: referenceNumber,
            invoiceList: invoiceList
        }
        console.log('request data', data);

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: data
            })
            console.log('data', result?.data);
            setErrorMessage('');

            if (result?.data?.paidInvoiceList?.length > 0) {
                const paidInvoiceNumbers = findInvoiceNumbersByInvoiceIds(result?.data?.paidInvoiceList);
                setPaidInvoiceList(paidInvoiceNumbers);
            }

            if (result?.data?.notPaidInvoiceList?.length > 0) {
                const notPaidInvoiceNumbers = findInvoiceNumbersByInvoiceIds(result?.data?.notPaidInvoiceList);
                setNotPaidInvoiceList(notPaidInvoiceNumbers);
            }
            setRemainAmount(result?.data?.emailRemainingAmount);
            setPaidSuccess(true);
            setLoading(false);
        } catch (e) {
            console.log('error', e.response);
            setLoading(false);
            setErrorMessage(`Fail to Pay the Invoice. ${e?.response?.data?.error}`);
            handleErrorMessage();
            setPaidSuccess(false);
        }
    }

    const handleConfirm = () => {
        payInvoicesByEmailReferenceNumber();
    }

    console.log('select invoice', selectInvoice);

    if (paidSuccess) {
        return (
            <Box sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '0 0 8px 8px',
                padding: '15px 30px 40px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                width: '480px'
            }}>
                {
                    paidInvoiceList?.length === 0 ?
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}>
                            <Typography>
                                ${remainAmount?.toFixed(2)} is not sufficient to cover any
                                invoices. However, it has already been topped up into your account.
                            </Typography>
                            <Typography>
                                Notice: If you have any questions or need further assistance, our support team is here
                                to help. Happy shipping with UUCargo!
                            </Typography>
                        </Box>
                        :
                        notPaidInvoiceList?.length === 0 ?
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}>
                                <Typography>
                                    Thank you for making your payment via email transfer! The following invoices have
                                    been
                                    successfully paid:
                                </Typography>
                                {
                                    paidInvoiceList?.length > 0 && paidInvoiceList?.map((each, index) => {
                                        return (
                                            <Typography key={index}>
                                                {each}
                                            </Typography>
                                        )
                                    })
                                }
                                {remainAmount > 0 &&
                                    <Typography>
                                        Notice: as your email transfer amount exceeded the total of your invoices, the
                                        surplus ${remainAmount.toFixed(2)} will
                                        be transferred directly into your account as credit. This credit can be
                                        conveniently used for future
                                        shipments and transactions with UUCargo. If you have any questions or need
                                        further assistance, our
                                        support team is here to help. Happy shipping with UUCargo!
                                    </Typography>
                                }
                            </Box> :
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}>
                                <Typography>
                                    Thank you for making your payment via email transfer! However, not all invoices has
                                    been successfully paid. The following invoices have
                                    been successfully paid:
                                </Typography>
                                {
                                    paidInvoiceList?.length > 0 && paidInvoiceList?.map((each, index) => {
                                        return (
                                            <Typography key={index}>
                                                {each}
                                            </Typography>
                                        )
                                    })
                                }
                                <Typography>
                                    The following invoices does not have been successfully paid:
                                </Typography>
                                {
                                    notPaidInvoiceList?.length > 0 && notPaidInvoiceList?.map((each, index) => {
                                        return (
                                            <Typography key={index}>
                                                {each}
                                            </Typography>
                                        )
                                    })
                                }
                                {remainAmount > 0 &&
                                    <Typography>
                                        Notice: the remaining ${remainAmount.toFixed(2)} will
                                        be transferred directly into your account as credit. This credit can be
                                        conveniently used for future
                                        shipments and transactions with UUCargo. If you have any questions or need
                                        further assistance, our
                                        support team is here to help. Happy shipping with UUCargo!
                                    </Typography>
                                }
                            </Box>
                }
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#F2BE22',
                            "&:hover": {
                                backgroundColor: '#F2BE22',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={() => window.location.reload()}
                    >
                        <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                            Back To Billing Center
                        </Typography>
                    </Button>
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={styles.BillingPaymentEmailRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px'
            }}>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Typography style={{textAlign: 'left', fontSize: '16px'}}>
                        {intl.get('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.STEP_0')}
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '16px'}}>
                        {intl.getHTML('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.E_TRANSFER', {totalAmount: totalAmount?.toFixed(2)})}
                        {/*e-Transfer <span*/}
                        {/*style={{color: '#1D8B45', fontWeight: '600'}}>${totalAmount.toFixed(2)}</span> to <span*/}
                        {/*style={{color: '#1D8B45', fontWeight: '600'}}>pay@uucargo.ca</span>*/}
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '16px'}}>
                        {intl.get('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.MESSAGE')}
                    </Typography>
                    {
                        selectInvoice?.map((each, index) => {
                            return (
                                <Typography key={index} style={{textAlign: 'left', fontSize: '16px'}}>
                                    {each?.invoiceRefNumber}
                                </Typography>
                            )
                        })
                    }
                </Box>
                {/*<Box sx={{display: 'flex', flexDirection: 'column'}}>*/}
                {/*    <Typography style={{textAlign: 'left', fontSize: '16px'}}>*/}
                {/*        {intl.getHTML('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.STEP_2')}*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                {/*<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>*/}
                {/*    <InputLabel style={{textAlign: 'left', fontSize: '16px'}}>*/}
                {/*        {intl.getHTML('BILLING_CENTER.PAYMENT.EMAIL_TRANSFER.REFERENCE_NUMBER')}*/}
                {/*    </InputLabel>*/}
                {/*    <TextField*/}
                {/*        size='small'*/}
                {/*        onChange={handleReferenceNumber}*/}
                {/*        sx={{width: '300px'}}*/}
                {/*    />*/}
                {/*</Box>*/}
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                <Button
                    variant='contained'
                    sx={{
                        backgroundColor: '#F2BE22',
                        "&:hover": {
                            backgroundColor: '#F2BE22',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    onClick={cbResetPaymentMethod}
                >
                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                        {intl.get('BILLING_CENTER.PAYMENT.BACK')}
                    </Typography>
                </Button>
                {/*<LoadingButton*/}
                {/*    variant='contained'*/}
                {/*    sx={{*/}
                {/*        backgroundColor: '#1D8B45',*/}
                {/*        "&:hover": {*/}
                {/*            backgroundColor: '#1D8B45',*/}
                {/*            filter: 'brightness(0.9)'*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onClick={handleConfirm}*/}
                {/*    loading={loading}*/}
                {/*    disabled={referenceNumber ? false : true}*/}
                {/*>*/}
                {/*    <Typography style={{textTransform: 'none', fontSize: '14px'}}>*/}
                {/*        {intl.get('BILLING_CENTER.PAYMENT.CONFIRM')}*/}
                {/*    </Typography>*/}
                {/*</LoadingButton>*/}
            </Box>
        </Box>
    )
}