import {useSelector} from "react-redux";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {MyBoxesItem} from './MyBoxesItem';
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import {Divider} from "@mui/material";

export const MyBoxesList = () => {

    const id = useSelector((state) => state.user.id);

    const [myBoxesList, setMyBoxesList] = useState([]);

    const getMyBoxesListForPartner = async () => {

        let requestURL = `${PARTNER_URI}/myboxes/getMyBoxesListForPartner`

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {partnerId: id},
            })
            const {data} = result;
            setMyBoxesList(data);
            console.log(data);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        id && getMyBoxesListForPartner()
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Typography>
                    Name
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>
                    Description
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography>
                    Dimension
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography>
                    Weight
                </Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                myBoxesList?.length > 0 ? myBoxesList.map((item, index) => {
                        return <MyBoxesItem key={index} boxItem={item}/>
                    })
                    : 'No boxes'
            }
        </Grid>
    )
}