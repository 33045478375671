import React, {useEffect, useState} from "react";
// import { useSelector } from "react-redux";
import {Box, makeStyles, TextField} from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { Alert } from '@material-ui/lab';

import PropTypes from "prop-types";

// import nextId from "react-id-generator";
import CustomInput from "../../../../shared/customInput";
import HscodeInputField from "../bolInputField";
import axios from "axios";
import {PARTNER_URI} from "../../../../utils/apiUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

const HscodeAddressInput = ({ shippingInfo, emptyError, handleItemChange, handleItemChangeByValue }) => {
  // const matches = useMediaQuery("(max-width:600px)");
  // const shippingItems = useSelector((state) => state.shipping.shippingItems);

  // const [showError, setShowErrorState] = useState(false);
  const classes = useStyles();

  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const {
    shipperName,
    shipperAddress,
    carrierAgent,
    consigneeName,
    consigneeAddress,
    deliveryAgent,
    placeOfAcceptance,
    preCarriage,
    numberOfOriginal,
    payableAt,
    excessValueDeclaration,
    vessel,
    portOfLoading,
    portOfDischarge,
    placeOfDelivery,
    onCarriageto,
    onBoardDate,
    issueDate,
    issuePlace,
    notifyPartyName,
    notifyPartyAddress,
    startPortCode,
    endPortCode,
    routeCode,
    deliveryAgentAddress,
    deliveryAgentPhone,
    deliveryAgentEmail,
    consigneePhone,
    shipperPhone,
    forwardingAgentName,
    forwardingAgentAddress,
    forwardingAgentPhone,
    forwardingAgentEmail,
  } = shippingInfo;

  useEffect(() => {
    console.log("Shipping Info:", shippingInfo)
  }, [shippingInfo]);

  const [startPortList, setStartPortList] = useState([]);
  const [endPortList, setEndPortList] = useState([]);
  const [startPortInputValue, setStartPortInputValue] = useState(portOfLoading || "");
  const [endPortInputValue, setEndPortInputValue] = useState(portOfDischarge || "");
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    setStartPortInputValue(portOfLoading);
  }, [portOfLoading])

  useEffect(() => {
    setEndPortInputValue(portOfDischarge);
  }, [portOfDischarge])


  const getPortSearchResult = async (portName) => {
    try {
      const { data } = await axios.get(`${PARTNER_URI}/elasticsearch/oceanPorts?query_string=${portName}`);
      const result = [];
      for (const hit of data?.hits) {
        const source = hit._source;
        const portData = {
          label: `${source?.port_en_name} | ${source?.port_code}`,
          value: source?.port_en_name,
          portCode: source?.port_code
        }
        // if (isStart) {
        //   // setStartPortCode(source?.port_code);
        //   handleItemChangeByValue("startPortCode")(source?.port_code);
        // } else {
        //   handleItemChangeByValue("endPortCode")(source?.port_code);
        // }

        result.push(portData);
      }
      return result;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Box>
      <Box color="orange" mb="1vh" textAlign="center">
        <h1>Where are you shipping?</h1>
      </Box>

      <Box display="flex" className={classes.addressComponent}>
        <Box pr={1} flexGrow="1" className={classes.leftAddress}>
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && shipperName === ""}
              value={shipperName}
              title="Shipper Name"
              mandatory={true}
              onChange={handleItemChange("shipperName")}
            />
          </Box>
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && shipperAddress === ""}
              value={shipperAddress}
              title="Shipper Address"
              mandatory={true}
              onChange={handleItemChange("shipperAddress")}
              placeholderValue="Full Address"
              rows={3}
              multiline
            />
          </Box>
          <Box mr={1}>
            <HscodeInputField
                error={emptyError && shipperPhone === ""}
                value={shipperPhone}
                title="Shipper Phone"
                mandatory={true}
                onChange={handleItemChange("shipperPhone")}
                placeholderValue="778-123-1234"
            />
          </Box>
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && carrierAgent === ""}
              value={carrierAgent}
              title="Carrier Agent"
              mandatory={true}
              onChange={handleItemChange("carrierAgent")}
            />
          </Box>
        </Box>

        <Box pl={2} flexGrow="1">
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && consigneeName === ""}
              value={consigneeName}
              title="Consignee Name"
              mandatory={true}
              onChange={handleItemChange("consigneeName")}
            />
          </Box>
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && consigneeAddress === ""}
              value={consigneeAddress}
              title="Consignee Address"
              mandatory={true}
              onChange={handleItemChange("consigneeAddress")}
              placeholderValue="Full Address"
              rows={3}
              multiline
            />
          </Box>
          <Box mr={1}>
            <HscodeInputField
                error={emptyError && consigneeName === ""}
                value={consigneePhone}
                title="Consignee Phone"
                mandatory={true}
                onChange={handleItemChange("consigneePhone")}
            />
          </Box>
          <Box mr={1}>
            <HscodeInputField
              error={emptyError && deliveryAgent === ""}
              value={deliveryAgent}
              title="Delivery Agent"
              mandatory={true}
              onChange={handleItemChange("deliveryAgent")}
            />
          </Box>
        </Box>
      </Box>

      <Box display="flex" mt={3} sx={{ gap: "0.8rem" }}  className={classes.addressComponent}>
        <Box flexBasis="33%" >
          <HscodeInputField
              value={deliveryAgentPhone}
              title="Delivery Agent Phone"
              onChange={handleItemChange("deliveryAgentPhone")}
              placeholderValue="778-123-1234"
          />
        </Box>
        <Box flexBasis="33%" >
          <HscodeInputField
              value={deliveryAgentEmail}
              title="Delivery Agent Email"
              onChange={handleItemChange("deliveryAgentEmail")}
              placeholderValue="delivery@uucargo.ca"
          />
        </Box>
        <Box flexBasis="33%">
          <HscodeInputField
              value={deliveryAgentAddress}
              title="Delivery Agent Address"
              onChange={handleItemChange("deliveryAgentAddress")}
              placeholderValue="Full Address"
              rows={3}
              multiline
          />
        </Box>
      </Box>

      <Box display="flex" mt={3} className={classes.addressComponent}>
        <Box flexBasis="50%">
          <HscodeInputField
            value={notifyPartyName}
            title="Notify Party Name"
            onChange={handleItemChange("notifyPartyName")}
          />
        </Box>
        <Box ml={4} mr={1} flexBasis="50%">
          <HscodeInputField
            value={notifyPartyAddress}
            title="Notify Party Address"
            onChange={handleItemChange("notifyPartyAddress")}
            placeholderValue="Full Address"
            rows={3}
            multiline
          />
        </Box>
      </Box>

      <Box display="flex" mt={3}>
        <Box flexBasis="50%">
          <HscodeInputField
              value={forwardingAgentName}
              title="Forwarding Agent Name"
              onChange={handleItemChange("forwardingAgentName")}
          />
        </Box>
        <Box ml={4} mr={1} flexBasis="50%">
          <HscodeInputField
              value={forwardingAgentAddress}
              title="Forwarding Agent Address"
              onChange={handleItemChange("forwardingAgentAddress")}
              placeholderValue="Full Address"
              rows={3}
              multiline
          />
        </Box>
      </Box>

      <Box display="flex" mt={3} className={classes.addressComponent}>
        <Box flexBasis="50%">
          <HscodeInputField
              value={forwardingAgentPhone}
              title="Forwarding Agent Phone"
              onChange={handleItemChange("forwardingAgentPhone")}
          />
        </Box>
        <Box ml={4} mr={1} flexBasis="50%">
          <HscodeInputField
              value={forwardingAgentEmail}
              title="Forwarding Agent Email"
              onChange={handleItemChange("forwardingAgentEmail")}
              placeholderValue="forwarding@uucargo.ca"
          />
        </Box>
      </Box>

      <Box display="flex" mt={3}>
        <Box flexBasis="50%">
          <HscodeInputField
            value={preCarriage}
            title="Pre-carriage by"
            onChange={handleItemChange("preCarriage")}
          />
        </Box>
        <Box ml={4} mr={1} flexBasis="50%">
          <div>Place of Acceptance</div>
          <CustomInput
            value={placeOfAcceptance}
            onChange={handleItemChange("placeOfAcceptance")}
          />
        </Box>
      </Box>

      <Box display="flex" mt={2}>
        <Box flexBasis="50%" mr={4}>
          <HscodeInputField
            value={numberOfOriginal}
            title="Number of original"
            onChange={handleItemChange("numberOfOriginal")}
          />
        </Box>
        <Box mr={1} flexBasis="50%">
          <div>Freight & charges payable at</div>
          <CustomInput
            value={payableAt}
            onChange={handleItemChange("payableAt")}
            // error={userInputError.emtpyError && payableAt === ""}
          />
        </Box>
      </Box>

      <Box display="flex" mt={2}>
        <Box mr={1} flexBasis="50%">
          <div>Excess Value Declaration</div>
          <CustomInput
            value={excessValueDeclaration}
            onChange={handleItemChange("excessValueDeclaration")}
          />
        </Box>

        <Box mr={1} ml={4} flexGrow="1" flexBasis="50%">
          <div>Vessel / Voyage</div>
          <CustomInput
            value={vessel}
            onChange={handleItemChange("vessel")}
            type="number"
            // error={userInputError.emtpyError && vessel === ""}
          />
        </Box>
      </Box>

      <Box display="flex" mt={2}>
        <Box flexBasis="50%" mr={4}>
          <div>Port of Loading</div>
          <Autocomplete
              disablePortal
              options={startPortList}
              fullWidth
              inputValue={startPortInputValue}
              filterOptions={(options, state) => options}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => {
                console.log("New Port of Loading:", value);
                handleItemChangeByValue("portOfLoading")(value?.value)
                handleItemChangeByValue("startPortCode")(value.portCode);
              }}
              onInputChange={async (e, value) => {
                // setStartPort("");
                setStartPortInputValue(value);
                // handleItemChangeByValue("portOfLoading")("")
                const result = await getPortSearchResult(value);
                console.log(result);
                setStartPortList(result);
              }}
              renderInput={(params) => <TextField id="outlined-basic" className={classes.textfield} label="e.g. Shanghai | 上海" variant="outlined" fullWidth {...params} required />}
          />
          {/*<CustomInput*/}
          {/*  value={portOfLoading}*/}
          {/*  onChange={handleItemChange("portOfLoading")}*/}
          {/*  // error={userInputError.emtpyError && portOfLoading === ""}*/}
          {/*  placeholderValue="E.g., Yantian, China"*/}
          {/*/>*/}
        </Box>

        <Box mr={1} flexBasis="50%">
          <div>Port of Discharge</div>
          <Autocomplete
              disablePortal
              options={endPortList}
              fullWidth
              inputValue={endPortInputValue}
              filterOptions={(options, state) => options}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => {
                handleItemChangeByValue("portOfDischarge")(value?.value)
                handleItemChangeByValue("endPortCode")(value.portCode);
              }}
              onInputChange={async (e, value) => {
                // setStartPort("");
                setEndPortInputValue(value);
                // handleItemChangeByValue("portOfDischarge")("")
                const result = await getPortSearchResult(value);
                console.log(result);
                setEndPortList(result);
                // handleItemChangeByValue("endPortCode")(result.portCode);
              }}
              renderInput={(params) => <TextField defaultValue={portOfDischarge} id="outlined-basic" className={classes.textfield} label="e.g. Singapore | 新加坡" variant="outlined" fullWidth {...params} required />}
          />
          {/*<CustomInput*/}
          {/*  value={portOfDischarge}*/}
          {/*  onChange={handleItemChange("portOfDischarge")}*/}
          {/*  // error={userInputError.emtpyError && portOfDischarge === ""}*/}
          {/*  placeholderValue="E.g., Vancouver, BC"*/}
          {/*/>*/}
        </Box>
      </Box>

      <Box display="flex" mt={2}>
        <Box flexBasis="50%" mr={4}>
          <div>Start Port Code</div>
          <CustomInput
              value={startPortCode}
              onChange={handleItemChange("startPortCode")}
              // error={userInputError.emtpyError && portOfLoading === ""}
              placeholderValue=""
          />
        </Box>

        <Box mr={1} flexBasis="50%">
          <div>End Port Code</div>
          <CustomInput
              value={endPortCode}
              onChange={handleItemChange("endPortCode")}
              // error={userInputError.emtpyError && portOfDischarge === ""}
              placeholderValue=""
          />
        </Box>
      </Box>

      <Box display="flex" mt={2}>

        <Box flexBasis="50%" mr={4}>
          <div>Route Code</div>
          <CustomInput
              value={routeCode}
              onChange={handleItemChange("routeCode")}
              placeholderValue=""
          />
        </Box>

        <Box mr={1} flexBasis="50%">
          <div>Place of Delivery</div>
          <CustomInput
            value={placeOfDelivery}
            onChange={handleItemChange("placeOfDelivery")}
            // error={userInputError.emtpyError && placeOfDelivery === ""}
            placeholderValue="E.g., Vancouver, BC"
          />
        </Box>
      </Box>

      <Box display="flex" mt={2}>
        <Box mr={4} flexBasis="50%">
          <div>On Carriage to</div>
          <CustomInput
              value={onCarriageto}
              onChange={handleItemChange("onCarriageto")}
              // error={userInputError.emtpyError && onCarriageto === ""}
              placeholderValue="E.g., Vancouver, BC"
          />
        </Box>
        <Box  flexBasis="50%">
          <div>Date of On Board</div>
          <Box  display="flex" justifyContent="center" alignContent="center" flexDirection="row" mt={1} sx={{ width: "420px", fontSize: "22px" }} >
            <DatePicker selected={dayjs(onBoardDate || new Date()).toDate()} className={classes.myDatePicker} onChange={(date) => {
              handleItemChangeByValue("onBoardDate")(dayjs(date).format("YYYY-MM-DD"))
            }} />
          </Box>

          {/*<CustomInput*/}
          {/*  value={onBoardDate}*/}
          {/*  onChange={handleItemChange("onBoardDate")}*/}
          {/*  // error={userInputError.emtpyError && onBoardDate === ""}*/}
          {/*/>*/}
        </Box>
        {/* <Box mr={1} flexBasis="50%">
          <div>Freight Details, Charges etc *</div>
          <CustomInput
            value={onBoardDate}
            onChange={handleItemChange("freightDetails")}
            error={userInputError.emtpyError && freightDetails === ""}
            multiline
            rows={3}
          />
          <Box
            pl={1}
            color="red"
            visibility={
              userInputError.emtpyError && freightDetails === ""
                ? "visible"
                : "hidden"
            }
          >
            Cannot be emtpy
          </Box>
        </Box> */}
      </Box>
      <Box display="flex" mt={2}>
        <Box mr={4} flexBasis="50%">
          <div>Date of Issue</div>
          <CustomInput
              value={issueDate}
              onChange={handleItemChange("issueDate")}
              // error={userInputError.emtpyError && issueDate === ""}
          />
        </Box>
        <Box mr={1} flexBasis="50%">
          <div>Place of Issue</div>
          <CustomInput
              value={issuePlace}
              onChange={handleItemChange("issuePlace")}
              // error={userInputError.emtpyError && issuePlace === ""}
          />
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  leftAddress: {
    borderRight: "1px solid rgba(0,0,0,0.3)",
  },
  addressComponent: {
    borderBottom: "1px solid rgba(0,0,0,0.3)",
  },
  textfield: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    // paddingTop: "0.4vw",
    // paddingBottom: "0.4vw",
  },
  myDatePicker: {
    width: "100%",
    height: "1.1876em",
    padding: "8px",
    fontSize: "15px"
  }
}));

HscodeAddressInput.propTypes = {
  shippingInfo: PropTypes.object.isRequired,
  emptyError: PropTypes.bool.isRequired,
  handleItemChange: PropTypes.func.isRequired,
};

export default HscodeAddressInput;
