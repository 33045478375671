import React, {useState, useEffect} from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {
    AppBar,
    Tabs,
    Tab,
    Box,
    useMediaQuery,
    Container,
    Button,
} from "@material-ui/core";
import {Alert, Pagination} from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
// import store from '../../store/index'
import {useHistory, useLocation, useParams} from "react-router-dom";
// import { useDispatch } from 'react-redux';
import axios from "axios";

import LoadingContainer from "../../shared/loadingContainer";
import {BOL_LIST, PARTNER_URI} from "../../utils/apiUrl";
import {getHeaders, getURLParams, errHandling} from "../../utils/fetchUtil";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {getAccessToken} from "../../utils/doToken";

const tabNumber = ["Initial", "Paid", "Cancelled"];

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2} pt={1}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const ShippingLTLListPage = () => {
    const history = useHistory();
    // const dispatch = useDispatch();
    const {tab: defaultTab} = useParams();

    const [page, setPage] = useState(1);

    const [lastPage, setLastPage] = useState(0);

    // const [userType, setuserType] = useState('')
    // const [isLoading, setLoadingState] = useState(true)
    const [errorMsg, setErrorMsg] = useState("");
    const [userBillData, setUserBillData] = useState([]);
    const [shippingLTLOrderList, setShippingLTLOrderList] = useState([]);
    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const classes = useStyles({matches});
    const theme = useTheme();
    const value =
        tabNumber.indexOf(defaultTab || "created") === -1
            ? 0
            : tabNumber.indexOf(defaultTab || "created");

    const [pageLoading, setPageLoading] = useState(false);

    const [tabClick, setTabClick] = useState(false);

    const getShippingLTLOrderListByStatus = async (status) => {
        const storedToken = getAccessToken("access_token");
        return await axios.post(`${PARTNER_URI}/shippingLTL/getLTLOrderListBySenderIdAndStatusIds`, {
            "statusIds": status,
            "page": page - 1
        }, {
            headers: {
                Authorization: `Bearer ${storedToken}`,
            }
        });
    }

    const getInitRequestData = async () => {
        const {data} = await getShippingLTLOrderListByStatus([0]);
        return data
    }
    const getPaidRequestData = async () => {
        const {data} = await getShippingLTLOrderListByStatus([1]);
        return data
    }
    const getCancelledRequestData = async () => {
        const {data} = await getShippingLTLOrderListByStatus([3, 4]);
        return data
    }

    useEffect(() => {
        (async () => {
            try {
                setPageLoading(true);
                let listData = [];
                let pagination = null;
                if (value === 0) {
                    const result = await getInitRequestData();
                    listData = result.data;
                    pagination = result.pagination;
                } else if (value === 1) {
                    const result = await getPaidRequestData();
                    listData = result.data;
                    pagination = result.pagination;
                } else if (value === 2) {
                    const result = await getCancelledRequestData();
                    listData = result.data;
                    pagination = result.pagination;
                }
                setLastPage(pagination.lastPage - 1 <= 0 ? 1 : pagination.lastPage - 1);
                setShippingLTLOrderList(listData);

            } catch (e) {
                console.log(e);
            } finally {
                setPageLoading(false);
            }
        })()
    }, [value, page]);

    const handleChange = (event, newValue) => {
        if (newValue !== value) {
            setPage(1);
            setTabClick(true);
        }
        history.replace(`/myLTLs/${tabNumber[newValue]}`);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const goRequestDetailsPage = (requestId) => {
        const path = `/shippingLTLDetails?orderId=${requestId}`;
        history.push(path);
    };

    useEffect(() => {
        console.log("Pallets Data: ", shippingLTLOrderList);
    }, [shippingLTLOrderList])

    const panelContent =
        shippingLTLOrderList?.length === 0 ? (
            <Box>
                <br/>
                <br/>
                There is no record for this type.
            </Box>
        ) : (
            <>
                {shippingLTLOrderList.map((backendOrderInfo, key) => {
                    return (
                        <Box
                            key={backendOrderInfo.id}
                            // borderBottom="1px solid rgba(61, 21, 21, 0.3)"
                            pt="1.5vh"
                            className={classes.itemWrapper}
                        >
                            <Box
                                className="order-list-text"
                                display="flex"
                                justifyContent="space-between"
                                flexDirection={matches ? "column" : "row"}
                            >
                                {/* <Box pr={1}><img alt={`order-${order.id}`} /></Box> */}
                                <Box
                                    display={matches ? "flex" : "block"}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box
                                        content="span"
                                        fontWeight="700"
                                        // className={matches ? classes.textTitle : ""}
                                    >
                                        Order #
                                    </Box>
                                    {" "}
                                    {/* {matches ? "" : <br />} */}
                                    <Box flexGrow="1">
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                goRequestDetailsPage(backendOrderInfo.id)
                                            }
                                            }
                                            className={classes.linkText}
                                        >
                                            {/*{bol.bol_id} / {bol.bol_no}*/}
                                            {backendOrderInfo.order_number}
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    textAlign="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{gap: "0.4rem"}}
                                >
                                    <Box
                                        content="span"
                                        className={matches ? classes.textTitle : ""}
                                        display="flex"
                                    >
                                        {/*Shipper*/}
                                        Sender
                                    </Box>
                                    <Box style={{fontStyle: "italic"}} flexGrow="1">
                                        {/*{getDate(bol.created_at)}*/}
                                        {/*{bol.shipper_name}*/}
                                        {backendOrderInfo.sender}
                                        <br/>
                                        {/*{bol.shipper_full_address}*/}
                                        {backendOrderInfo.pickup_address}
                                        <br/>
                                        {backendOrderInfo.pickup_area}
                                        <br/>
                                        {backendOrderInfo.pickup_postal_code}
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    textAlign="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{gap: "0.4rem"}}
                                >
                                    <Box
                                        content="span"
                                        className={matches ? classes.textTitle : ""}
                                    >
                                        {/*Consignee*/}
                                        Deliver
                                    </Box>{" "}
                                    {/* {matches ? "" : <br />} */}
                                    <Box style={{fontStyle: "italic"}} flexGrow="1">
                                        {backendOrderInfo.deliver_name}
                                        <br/>
                                        {/*{bol.shipper_full_address}*/}
                                        {backendOrderInfo.deliver_address}
                                        <br/>
                                        {backendOrderInfo.deliver_area}
                                        <br/>
                                        {backendOrderInfo.deliver_postal_code}
                                    </Box>
                                </Box>
                                <Box
                                    display={matches ? "flex" : "block"}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box
                                        content="span"
                                        fontWeight="700"
                                    >
                                        Schedule Date
                                    </Box>
                                    <Box flexGrow="1">
                                        {/*<span  className={classes.linkText}>*/}
                                        {/*  {(bol?.etd_date)}*/}
                                        {/*</span>*/}
                                        <h3>
                                            {/*{(bol?.etd_date)}*/}
                                            {backendOrderInfo.scheduled_pickup_date ? dayjs(backendOrderInfo.scheduled_pickup_date).format("YYYY-MM-DD") : (
                                                <b style={{visibility: "hidden"}}>YYYY-MM-DD</b>
                                            )}
                                        </h3>
                                    </Box>
                                </Box>
                            </Box>
                            <hr/>
                        </Box>
                    );
                })}
            </>
        );

    const pageSelection = (
        <div className={classes.pagination}>
            <Pagination
                className={classes.pagination}
                count={lastPage}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
            />
        </div>
    );

    return (
        <Container>
            {/* <div className="order-list-container"> */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={3}
                mb={3}
            >
            </Box>
            <h1>My Shipping LTL Orders</h1>
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        // centered
                        // scrollButtons="auto"
                    >
                        <Tab label="Initial" {...a11yProps(0)} />
                        <Tab label="Paid" {...a11yProps(1)} />
                        <Tab label="Cancelled" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                {/* {isLoading ? <LoadingContainer /> : */}
                {/* (<> */}
                <TabPanel
                    value={value}
                    index={0}
                    dir={theme.direction}
                    classes={{root: classes.tabRoot}}
                >
                    <Box>
                        {pageLoading ? <LoadingContainer/> : panelContent}
                        {pageSelection}
                    </Box>
                </TabPanel>
                <TabPanel
                    value={value}
                    index={1}
                    dir={theme.direction}
                    classes={{root: classes.tabRoot}}
                >
                    <Box>
                        {pageLoading ? <LoadingContainer/> : panelContent}
                        {pageSelection}
                    </Box>
                </TabPanel>
                {/*<TabPanel*/}
                {/*  value={value}*/}
                {/*  index={2}*/}
                {/*  dir={theme.direction}*/}
                {/*  classes={{ root: classes.tabRoot }}*/}
                {/*>*/}
                {/*  <Box>*/}
                {/*    {pageLoading ? <LoadingContainer /> : panelContent}*/}
                {/*    {pageSelection}*/}
                {/*  </Box>*/}
                {/*</TabPanel>*/}
                <TabPanel
                    value={value}
                    index={2}
                    dir={theme.direction}
                    classes={{root: classes.tabRoot}}
                >
                    <Box>
                        {pageLoading ? <LoadingContainer/> : panelContent}
                        {pageSelection}
                    </Box>
                </TabPanel>
                {/* </>)} */}
                <Box pt="1vh" width="100%">
                    {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : ""}
                </Box>
            </div>
            {/* </div> */}
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.paper,
        // backgroundColor: 'white',
        // border: "2px solid #000",
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 2),
        // width: props => props.matches ? '90vw': '80vw',
        width: "100%",
        // height: '80vh',
        // borderRadius: '20px',
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        // overflow: "scroll",
        // margin: '0 3vw'
    },
    // panel: {
    //   backgroundColor: "white",
    // },
    tabRoot: {
        // height: '100%',
        overflow: "none",
        // height: "65vh",
    },
    // headerRoot: {
    //     marginTop: '0',
    // },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
    linkText: {
        fontSize: "1rem !important",
        color: "blue",
    },
    pkgImg: {
        margin: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    pkgWrapper: {
        backgroundColor: "#fdf5e8",
        padding: "3px",
        cursor: "pointer",
        "&:hover": {
            // border: '1px solid rgba(0,0,0,0.3)',
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: "5px",
        },
    },
    textTitle: {
        flexBasis: "40%",
        textAlign: "left",
    },
    activeWrapper: {
        backgroundColor: "grey",
        color: "white",
        borderRadius: 5,
        padding: theme.spacing(0.5),
        height: "40px",
    },
    cardContent: {
        backgroundColor: "#fbfbf8",
        border: "1px solid #ded47c",
        borderRadius: "5px",
    },
    itemWrapper: {
        "& hr": {
            // border: '1px solid rgba(0,0,0,0.3)',
            marginLeft: -theme.spacing(2),
            marginRight: -theme.spacing(2),
            borderTop: "3px solid rgba(0,0,0,.1)",
        },
    },
}));

// const mapStateToProps = state => ({
//     orders: state.orders,
//     packages: state.packages
// })

// export default connect(mapStateToProps, {getOrders, getPackages, getOrderShipments})(OrderList)
export default ShippingLTLListPage;
